/** @jsxImportSource @emotion/react */
import { QueryResult } from "@apollo/client";
import { css } from "@emotion/react";
import { startCase } from "lodash";
import { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { SelectField } from "@rewards-web/shared/components/select-field";
import { SwitchField } from "@rewards-web/shared/components/switch";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  RewardsUser,
  Maybe,
  RewardsAdminUser,
  RewardsOrganizationBranch,
} from "@rewards-web/shared/graphql-types";
import { isEmail } from "@rewards-web/shared/lib/is-email";
import { isPhoneNumber } from "@rewards-web/shared/lib/is-phone-number";
import { stripNonNumericCharacters } from "@rewards-web/shared/lib/strip-non-numeric-characters";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import {
  RightDrawerActions,
  RightDrawerContent,
} from "../../../../../../components/right-drawer";
import { RightDrawerBaseCard } from "../../../../../../components/right-drawer/right-drawer-base-card";
import { useMyBranches } from "../../../../../branches/use-my-branches";
import { useHasPermissionQuery } from "../../../../../permissions/hooks/use-has-permission-query";
import { useRole } from "../../../../../role";
import {
  AddEditEmployeeAdminConflictError,
  AddEditEmployeeConflictError,
  AddEditEmployeeInvalidWorkDomainError,
  UserCannotAssignManagerAsThemselvesError,
} from "../../../../errors/errors";
import { EmployeeBranchField } from "../../../../fields/employee-branch";
import { EmployeeDateOfBirthField } from "../../../../fields/employee-date-of-birth";
import { EmployeeEmploymentStartDateField } from "../../../../fields/employee-employment-start-date";
import { EmployeeManagerField } from "../../../../fields/employee-manager";
import { ActiveValue } from "../../../../types/typings";
import { AlayaCareIntegrationEnabledAlert } from "../../alayacare-integration-enabled-alert";
import { EmployeeDrawerStatsQuery } from "../employee-drawer-stats.generated";
import { EmployeeStats } from "./employee-stats";

interface EditEmployeeFormValues {
  workPhoneNumber: string;
  workEmail: string;
  personalPhoneNumber: string;
  personalEmail: string;
  branchId: string;
  active: ActiveValue;
  managerId: string;
  disableReferral: boolean;
  employmentStartDate: string;
  dateOfBirth: string;
}

interface EditEmployeeFormProps {
  onClose(): void;
  onSubmit(values: EditEmployeeFormValues): Promise<void>;
  usesWorkDevices: boolean;
  integratedWithAlayaCare: boolean;
  managersEnabled: boolean;
  statsQuery: QueryResult<EmployeeDrawerStatsQuery>;

  readOnly?: boolean;
  user: Pick<
    RewardsUser,
    | "id"
    | "firstName"
    | "lastName"
    | "active"
    | "workEmail"
    | "workPhoneNumber"
    | "personalContactInfo"
    | "active"
    | "externalEmployeeId"
    | "userReferralLinkDisabled"
    | "employmentStartDate"
    | "dateOfBirth"
  > & {
    manager?: Maybe<
      { __typename?: "RewardsAdminUser" } & Pick<
        RewardsAdminUser,
        "id" | "firstName" | "lastName"
      >
    >;
  } & {
    branch?: Maybe<
      { __typename?: "RewardsOrganizationBranch" } & Pick<
        RewardsOrganizationBranch,
        "id" | "name"
      >
    >;
  };
}

export function EditEmployeeForm({
  onClose,
  onSubmit,
  user,
  usesWorkDevices,
  integratedWithAlayaCare,
  managersEnabled,
  statsQuery,
  readOnly = false,
}: EditEmployeeFormProps) {
  const branchesQuery = useMyBranches();
  const roleQuery = useRole();
  const fullEmployeePermissionsQuery = useHasPermissionQuery(
    "full",
    "employees"
  );
  const employeeDetailPageEnabled = useFeatureFlag(
    "admin-app-employee-level-insights-detail-view-temp"
  );

  const [
    alayaCareIntegratedPromptOpen,
    setAlayaCareIntegratedPromptOpen,
  ] = useState(false);
  const [editingManager, setEditingManager] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<EditEmployeeFormValues>({
    defaultValues: {
      workPhoneNumber: user.workPhoneNumber
        ? user.workPhoneNumber.substring(2)
        : "",
      workEmail: user.workEmail ?? "",
      personalPhoneNumber: (() => {
        if (
          user.personalContactInfo?.__typename ===
          "RewardsUserPersonalContactInfoSuppressed"
        ) {
          return "(Suppressed)";
        }

        if (
          user.personalContactInfo?.__typename ===
          "RewardsUserPersonalContactInfoData"
        ) {
          return user.personalContactInfo.phoneNumber.substring(2) || "";
        }

        return "";
      })(),
      personalEmail: (() => {
        if (
          user.personalContactInfo?.__typename ===
          "RewardsUserPersonalContactInfoSuppressed"
        ) {
          return "(Suppressed)";
        }

        if (
          user.personalContactInfo?.__typename ===
          "RewardsUserPersonalContactInfoData"
        ) {
          return user.personalContactInfo.email || "";
        }

        return "";
      })(),
      branchId: user.branch?.id ?? "",
      active: user.active
        ? ActiveValue.EMPLOYEE_ACTIVE
        : ActiveValue.EMPLOYEE_DE_ACTIVATED,
      managerId: user.manager?.id ?? "",
      disableReferral: user.userReferralLinkDisabled,
      employmentStartDate: user.employmentStartDate ?? "",
      dateOfBirth: user.dateOfBirth ?? "",
    },
  });

  const personalPhoneNumber = useWatch({
    control,
    name: "personalPhoneNumber",
  });
  const personalEmail = useWatch({ control, name: "personalEmail" });

  const employeeManagedBySchedulingSystem = Boolean(user.externalEmployeeId);

  return (
    <Form
      readOnly={readOnly || !fullEmployeePermissionsQuery.hasPermission}
      css={css`
        display: contents; // Modal child components must be direct DOM descendents of Modal
      `}
      onSubmit={handleSubmit(async (values) => {
        try {
          await onSubmit(values);
        } catch (error) {
          if (error instanceof AddEditEmployeeConflictError) {
            for (const { name, userActive } of error.fieldsAndUsers) {
              setError(name, {
                type: "unique",
                message: `${
                  userActive ? "An active" : "A de-activated"
                } user exists with this ${startCase(name).toLowerCase()}`,
              });
            }
          } else if (error instanceof AddEditEmployeeAdminConflictError) {
            if (error.fields.includes("email")) {
              setError("personalEmail", {
                type: "unique",
                message: "An admin exists with this email",
              });
            }
          } else if (error instanceof AddEditEmployeeInvalidWorkDomainError) {
            setError("workEmail", {
              type: "invalidWorkDomain",
              message: `Work emails must match domain @${error.requiredDomain}`,
            });
          } else if (
            error instanceof UserCannotAssignManagerAsThemselvesError
          ) {
            setError("managerId", {
              type: "cantAssignToThemselves",
              message: "A user's manager can not be assigned to themself",
            });
          }
        }
      })}
      submitting={isSubmitting}
    >
      <RightDrawerContent>
        <div
          css={css`
            max-width: 470px;
          `}
        >
          {/* If external ID, user _should_ not be able to edit the employee */}
          {employeeManagedBySchedulingSystem &&
            !readOnly &&
            roleQuery.data?.role === "superuser" && (
              <Alert
                severity="warning"
                message={
                  "This organization is set-up with automated syncs. The employee information is pulled from the scheduling or payroll systems. As a superuser, you can make changes to the employee, but please exercise caution since these changes may be overwritten by the next sync."
                }
                messageTypographyVariant="footnote"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(2)};
                `}
              />
            )}

          {employeeManagedBySchedulingSystem &&
            readOnly &&
            roleQuery.data?.role !== "superuser" && (
              <Alert
                severity="info"
                message={
                  "Your organization is set-up with automated syncs. The employee information is pulled from your scheduling or payroll systems. To make changes to an employee profile, please change them at the source."
                }
                messageTypographyVariant="footnote"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(2)};
                `}
              />
            )}
          <RightDrawerBaseCard>
            {/* Superusers can see the external employee ID, for convenience */}
            {roleQuery.data?.role === "superuser" && (
              <TextField
                value={user.externalEmployeeId}
                label="External employee ID"
                readOnly
                greyReadOnly
              />
            )}

            <TextField
              label="Employee name"
              value={`${user.firstName} ${user.lastName}`}
              readOnly
              greyReadOnly
            />
            {managersEnabled && (
              <>
                {editingManager || user.manager === null ? (
                  <EmployeeManagerField
                    control={control}
                    name="managerId"
                    workEmailFieldName="workEmail"
                    personalEmailFieldName="personalEmail"
                    existingEmployeeId={user.id}
                  />
                ) : (
                  <div
                    css={(theme: AppTheme) => css`
                      display: flex;
                      padding: ${theme.spacing(1)};
                      gap: ${theme.spacing(2)};
                    `}
                  >
                    <TextField
                      label="Manager"
                      value={`${user.manager?.firstName} ${user.manager?.lastName}`}
                      readOnly
                      greyReadOnly
                    />
                    <Button
                      css={(theme: AppTheme) => css`
                        margin-bottom: ${theme.spacing(4)};
                      `}
                      color="primary"
                      label="Change Manager"
                      size="small"
                      onClick={() => {
                        setEditingManager(true);
                      }}
                    />
                  </div>
                )}
              </>
            )}

            <Controller
              control={control}
              name="active"
              render={({ field }) => (
                <>
                  <Modal
                    width="700px"
                    open={alayaCareIntegratedPromptOpen}
                    onClose={() => {
                      setAlayaCareIntegratedPromptOpen(false);
                    }}
                  >
                    <AlayaCareIntegrationEnabledAlert
                      onClose={() => {
                        setAlayaCareIntegratedPromptOpen(false);
                      }}
                      attemptedAction={`Change status (prev status = ${
                        user.active ? "active" : "de-deactivated"
                      })`}
                    />
                  </Modal>
                  <SelectField
                    {...field}
                    label="Employee status"
                    disabled={isSubmitting}
                    greyReadOnly
                    readOnly={integratedWithAlayaCare}
                    onClick={
                      integratedWithAlayaCare
                        ? () => {
                            setAlayaCareIntegratedPromptOpen(true);
                          }
                        : undefined
                    }
                    width="full"
                    options={[
                      { label: "Active", value: ActiveValue.EMPLOYEE_ACTIVE },
                      {
                        label: "De-activated",
                        value: ActiveValue.EMPLOYEE_DE_ACTIVATED,
                      },
                    ]}
                  />
                </>
              )}
            />

            {!employeeDetailPageEnabled && (
              <TextField
                label="External ID"
                value={user.externalEmployeeId}
                readOnly
                greyReadOnly
              />
            )}
            {!employeeDetailPageEnabled && (
              <div
                css={(theme: AppTheme) => css`
                  padding-left: ${theme.spacing(2)};
                  border: 1px solid ${theme.palette.grey[400]};
                  border-radius: ${theme.spacing(1)};
                  margin-bottom: ${theme.spacing(4)};
                `}
              >
                <div
                  css={(theme: AppTheme) => css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: ${theme.spacing(1)};
                    padding-top: ${theme.spacing(1.5)};
                  `}
                >
                  <Controller
                    control={control}
                    name={"disableReferral"}
                    render={({ field }) => (
                      <SwitchField
                        label="referral disabled"
                        hideLabel
                        color="error"
                        disabled={!fullEmployeePermissionsQuery.hasPermission}
                        {...field}
                        value={field.value}
                      />
                    )}
                  />
                  <Typography variant="subtitle">Disable Referrals</Typography>
                </div>

                <Typography color="grey.800" variant="footnote">
                  <div
                    css={(theme: AppTheme) => css`
                      margin-top: ${theme.spacing(1)};
                      padding-bottom: ${theme.spacing(1.5)};
                    `}
                  >
                    Disabling this employee's referrals stops new applications
                    from coming in & prevents job sharing. Before re-enabling,
                    we recommend educating them on referring high-quality
                    candidates.
                  </div>
                </Typography>
              </div>
            )}

            {usesWorkDevices && (
              <>
                <TextField
                  type="tel"
                  label="Work cell phone"
                  disableAutocomplete
                  greyReadOnly
                  error={errors.workPhoneNumber}
                  {...register("workPhoneNumber", {
                    validate: (value: string) => {
                      if (
                        value &&
                        !isPhoneNumber(stripNonNumericCharacters(value))
                      ) {
                        return "This is not a valid phone number";
                      }
                    },
                  })}
                />
                <TextField
                  type="email"
                  label="Work email"
                  disableAutocomplete
                  greyReadOnly
                  error={errors.workEmail}
                  {...register("workEmail", {
                    required: {
                      value: !!user.workEmail,
                      message: "Work email is required",
                    },
                    validate: (value: string) => {
                      if (value && !!user.workEmail && !isEmail(value)) {
                        return "This is not a valid email";
                      }
                    },
                  })}
                />
              </>
            )}

            {(branchesQuery.data?.getMyRewardsOrganization.branches.length ??
              0) > 0 && (
              <EmployeeBranchField
                control={control}
                name="branchId"
                greyReadOnly
              />
            )}

            <EmployeeEmploymentStartDateField
              control={control}
              name="employmentStartDate"
              greyReadOnly
            />
            <EmployeeDateOfBirthField
              control={control}
              name="dateOfBirth"
              greyReadOnly
            />
          </RightDrawerBaseCard>
          <Typography variant="h5">
            <div
              css={(theme: AppTheme) => css`
                margin-top: ${theme.spacing(2)};
                margin-left: ${theme.spacing(2)};
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              Sign-up details
            </div>
          </Typography>
          <RightDrawerBaseCard>
            <TextField
              type={
                user.personalContactInfo?.__typename ===
                "RewardsUserPersonalContactInfoSuppressed"
                  ? "text"
                  : "tel"
              }
              label="Personal cell phone"
              disableAutocomplete
              disabled={
                user.personalContactInfo?.__typename ===
                "RewardsUserPersonalContactInfoSuppressed"
              }
              greyReadOnly
              error={errors.personalPhoneNumber}
              {...register("personalPhoneNumber", {
                required: {
                  value:
                    user.personalContactInfo?.__typename ===
                    "RewardsUserPersonalContactInfoData",
                  message: "Personal cell phone is required",
                },
                validate: (value: string) => {
                  if (
                    value &&
                    user.personalContactInfo?.__typename ===
                      "RewardsUserPersonalContactInfoData" &&
                    !isPhoneNumber(stripNonNumericCharacters(value))
                  ) {
                    return "This is not a valid phone number";
                  }

                  if (!value && personalEmail) {
                    return "Personal cell phone is required if email is set";
                  }
                },
              })}
            />
            <TextField
              type="email"
              label="Personal email"
              disableAutocomplete
              error={errors.personalEmail}
              greyReadOnly
              disabled={
                user.personalContactInfo?.__typename ===
                "RewardsUserPersonalContactInfoSuppressed"
              }
              {...register("personalEmail", {
                required: {
                  value:
                    user.personalContactInfo?.__typename ===
                    "RewardsUserPersonalContactInfoData",
                  message: "Personal email is required",
                },
                validate: (value: string) => {
                  if (value && !isEmail(value)) {
                    return "This is not a valid email";
                  }

                  if (!value && personalPhoneNumber) {
                    return "Personal email is required if cell phone is set";
                  }
                },
              })}
            />
          </RightDrawerBaseCard>
          {!employeeDetailPageEnabled && <EmployeeStats query={statsQuery} />}
        </div>
      </RightDrawerContent>
      <RightDrawerActions>
        <Button variant="outlined" onClick={onClose} label="Cancel" />
        <Button
          type="submit"
          color="primary"
          label="Save"
          loading={isSubmitting}
        />
      </RightDrawerActions>
    </Form>
  );
}
