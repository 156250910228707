/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { DataTable } from "../../../../../../shared/components/data-table";
import {
  formatTransactionCategory,
  formatTransactionDescription,
} from "../lib";
import { TransactionDescriptionText } from "../transaction-description-text";
import { TransactionMetadataFragmentFragment } from "../transaction-metadata-fragment.generated";

interface EmployeeDetailPointsTransactionsTableProps {
  items:
    | Array<{
        occurredAt: number;
        signedPointValue: number;
        metadata: TransactionMetadataFragmentFragment;
      }>
    | undefined;
  total: number | undefined;
  itemsPerPage: number;
  loading: boolean;
  rewardsProgramShortName: string | undefined;
  launched: boolean | undefined;
}

export function EmployeeDetailPointsTransactionsTable({
  items,
  total,
  itemsPerPage,
  loading,
  rewardsProgramShortName,
  launched,
}: EmployeeDetailPointsTransactionsTableProps) {
  return (
    <DataTable
      paddingDisabled
      items={items}
      total={total}
      itemsPerPage={itemsPerPage}
      emptyStateHeight="150px"
      emptyText={
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: ${theme.spacing(2)};
            height: 100%;
            max-width: 700px;
            margin: 0 auto;
            text-align: center;
          `}
        >
          <Typography variant="subtitle" color="textPrimary">
            No points earned yet
          </Typography>
          <Typography variant="body" color="grey.800">
            {launched ? (
              <>
                Remind this employee to use{" "}
                {rewardsProgramShortName ?? "Caribou Rewards"} and earn rewards
                for achieving goals, hitting milestones, completing surveys, and
                more.
              </>
            ) : (
              <>
                This employee can start earning after the rewards program is
                launched.
              </>
            )}
          </Typography>
        </div>
      }
      loading={loading}
      tableHeaders={
        <TableRow>
          <TableHeader
            divider
            css={css`
              width: 15%;
              min-width: 156px;
            `}
          >
            <Typography variant="subtitle" color="textPrimary" fontSize="16px">
              Transaction date
            </Typography>
          </TableHeader>
          <TableHeader
            divider
            css={css`
              width: 12%;
            `}
          >
            <Typography variant="subtitle" color="textPrimary" fontSize="16px">
              Points
            </Typography>
          </TableHeader>
          <TableHeader
            divider
            css={css`
              width: 15%;
            `}
          >
            <Typography variant="subtitle" color="textPrimary" fontSize="16px">
              Category
            </Typography>
          </TableHeader>
          <TableHeader
            divider
            css={css`
              width: 58%;
            `}
          >
            <Typography variant="subtitle" color="textPrimary" fontSize="16px">
              Description
            </Typography>
          </TableHeader>
        </TableRow>
      }
      tableBody={items?.map((transaction, index) => (
        <TableRow>
          <TableCell divider={index < items.length - 1}>
            <Typography variant="body" color="grey.800">
              {new Date(transaction.occurredAt).toLocaleDateString(undefined, {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </Typography>
          </TableCell>
          <TableCell divider>
            <Typography
              variant="body"
              color={
                transaction.signedPointValue > 0 ? "success.main" : "grey.800"
              }
            >
              {numberWithCommas(transaction.signedPointValue)}
            </Typography>
          </TableCell>
          <TableCell divider>
            <Typography variant="body" color="grey.800">
              {formatTransactionCategory(transaction.metadata.category)}
            </Typography>
          </TableCell>
          <TableCell divider>
            <Typography variant="body" color="grey.800">
              <TransactionDescriptionText
                description={formatTransactionDescription(
                  transaction.metadata,
                  transaction.signedPointValue
                )}
              />
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    />
  );
}
