import { CandidateRecruitmentStepName } from "@rewards-web/shared/graphql-types";

import { CandidateListDetailsFragment } from "../../candidate-fragment.generated";

export function shouldDisplayAppliedAtDisclaimer(
  candidate: CandidateListDetailsFragment
) {
  return (
    !candidate.archived &&
    // organization must be able to mark candidates as 'contacted'
    candidate.nextSteps.some(
      (step) =>
        step.__typename === "CandidateRecruitmentStep" &&
        step.stepName === CandidateRecruitmentStepName.Contacted
    ) &&
    // candidate must be at the first step
    candidate.completedStepsV2.filter(
      (step) =>
        step.__typename === "RecordedCandidateRecruitmentStep" &&
        step.stepName !== CandidateRecruitmentStepName.ApplicationSubmitted
    ).length === 0
  );
}
