import {
  EvvRules,
  EvvRulesClockMethod,
} from "@rewards-web/shared/graphql-types";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";

import { EVVRulesClockMethods, EVVRulesFormValues } from ".";

export const isEVVRulesEnabled = (rules: EvvRules) => {
  if (!rules) {
    return false;
  }

  if (
    !(
      rules.minutesGreaterThanScheduledDuration ||
      rules.minutesGreaterThanScheduledEnd ||
      rules.minutesGreaterThanScheduledStart ||
      rules.minutesLessThanScheduledDuration ||
      rules.minutesLessThanScheduledEnd ||
      rules.minutesLessThanScheduledStart ||
      rules.validClockMethods
    )
  ) {
    return false;
  }

  return true;
};

export const convertValidTimeToNumber = (timeInMinutes: string | null) => {
  return timeInMinutes ? Number(extractNumber(timeInMinutes)) : null;
};

export const mapSelectedClockMethods = (
  selectedClockMethods: EVVRulesFormValues["selectedClockMethods"]
): EvvRulesClockMethod[] | null => {
  const selectedClockMethodsArray = Object.keys(selectedClockMethods).filter(
    (clockMethod): clockMethod is EVVRulesClockMethods =>
      selectedClockMethods[clockMethod as EVVRulesClockMethods]
  );

  if (selectedClockMethodsArray.length === 0) {
    return null;
  }

  return [...selectedClockMethodsArray, EvvRulesClockMethod.SystemAutoClock];
};
