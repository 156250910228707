import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type JobPostingSearchOptionsQueryVariables = Types.Exact<{
  searchQuery: Types.Scalars['String'];
  limit: Types.Scalars['Int'];
  includeFromSiblingOrganizations?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type JobPostingSearchOptionsQuery = (
  { __typename?: 'Query' }
  & { listJobPostings: (
    { __typename?: 'JobPostingListResponse' }
    & { items: Array<(
      { __typename?: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title' | 'geography' | 'closedForSubmission' | 'candidateAlertEmail'>
      & { branch?: Types.Maybe<(
        { __typename?: 'RewardsOrganizationBranch' }
        & Pick<Types.RewardsOrganizationBranch, 'id'>
      )> }
    )> }
  ) }
);


export const JobPostingSearchOptionsDocument = gql`
    query JobPostingSearchOptions($searchQuery: String!, $limit: Int!, $includeFromSiblingOrganizations: Boolean) {
  listJobPostings(
    limit: $limit
    offset: 0
    filter: {searchQuery: $searchQuery, sort: [{property: CLOSED_FOR_SUBMISSION, direction: ASC}, {property: TITLE, direction: ASC}]}
    includeFromSiblingOrganizations: $includeFromSiblingOrganizations
  ) {
    items {
      id
      title
      geography
      closedForSubmission
      candidateAlertEmail
      branch {
        id
      }
    }
  }
}
    `;

/**
 * __useJobPostingSearchOptionsQuery__
 *
 * To run a query within a React component, call `useJobPostingSearchOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingSearchOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingSearchOptionsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      limit: // value for 'limit'
 *      includeFromSiblingOrganizations: // value for 'includeFromSiblingOrganizations'
 *   },
 * });
 */
export function useJobPostingSearchOptionsQuery(baseOptions: Apollo.QueryHookOptions<JobPostingSearchOptionsQuery, JobPostingSearchOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobPostingSearchOptionsQuery, JobPostingSearchOptionsQueryVariables>(JobPostingSearchOptionsDocument, options);
      }
export function useJobPostingSearchOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobPostingSearchOptionsQuery, JobPostingSearchOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobPostingSearchOptionsQuery, JobPostingSearchOptionsQueryVariables>(JobPostingSearchOptionsDocument, options);
        }
export type JobPostingSearchOptionsQueryHookResult = ReturnType<typeof useJobPostingSearchOptionsQuery>;
export type JobPostingSearchOptionsLazyQueryHookResult = ReturnType<typeof useJobPostingSearchOptionsLazyQuery>;
export type JobPostingSearchOptionsQueryResult = Apollo.QueryResult<JobPostingSearchOptionsQuery, JobPostingSearchOptionsQueryVariables>;