/** @jsxImportSource @emotion/react */
import {
  FieldValues,
  Control,
  Controller,
  Path,
  useWatch,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";

import { SurveySettingsRewardType } from "../reward-type";

interface SurveySettingsRewardValueProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  disabled?: boolean;
  pointsPerDollar: number;
  getRewardType: () => SurveySettingsRewardType;
}

export function SurveySettingsRewardValueField<T extends FieldValues>({
  control,
  name,
  disabled,
  pointsPerDollar,
  getRewardType,
}: SurveySettingsRewardValueProps<T>) {
  const rewardValue = useWatch({ control, name });

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          if (disabled) {
            return;
          }
          if (!value) {
            return "Reward value is required";
          }
          const numericValue = Number(value);
          const rewardType = getRewardType();
          if (rewardType === null) {
            return;
          }
          // min and max should match GoalRewards schema in backend
          const { min, max, label } = (() => {
            switch (rewardType) {
              case "points":
                return {
                  min: 1,
                  /**
                   * $20 reward
                   * At time of writing, this is less than the max amount in the backend (100,000 pts)
                   */
                  max: 20 * 100,
                  label: `Point reward`,
                };
              case "tickets":
                return { min: 1, max: 100, label: `Ticket reward` };
              default:
                assertNever(rewardType);
            }
          })();
          if (numericValue < min) {
            return `${label} must be at least ${min.toLocaleString()}`;
          }
          if (numericValue > max) {
            return `${label} must not exceed ${max.toLocaleString()}`;
          }
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            label="Reward value"
            error={fieldState.error}
            fixedHeight={59}
            type="number"
            disabled={disabled}
            endAdornment={
              getRewardType() === "points" && (
                <Typography fontWeight={700}>
                  {formatDollars((rewardValue ?? 0) / pointsPerDollar)}
                </Typography>
              )
            }
            inputProps={{
              // ensure they can't scroll to negative values
              min: 1,
            }}
          />
        );
      }}
    />
  );
}
