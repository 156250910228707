import {
  CandidateHourlyRetentionStepFilterOption,
  CandidateMonthlyRetentionStepFilterOption,
  CandidateRecruitmentStepFilterOption,
  CandidateRecruitmentStepName,
  ListCandidatesV2FilterInput,
  ListCandidateV2FilterStatusInput,
} from "@rewards-web/shared/graphql-types";

import {
  getInitialCandidateFilterNextStepOptionsForOrganizationSteps,
  serializeCandidateFilterStepOptionIntoGraphQLInput,
} from "./candidate-filter/lib";

export interface OrganizationSteps {
  recruitmentSteps: CandidateRecruitmentStepName[];
  retentionMonthSteps: number[];
  retentionHoursSteps: number[];
}

export function getOrganizationSteps(
  filterOptions: Array<
    | ({ __typename?: "CandidateRecruitmentStepFilterOption" } & Pick<
        CandidateRecruitmentStepFilterOption,
        "stepName"
      >)
    | ({ __typename?: "CandidateMonthlyRetentionStepFilterOption" } & Pick<
        CandidateMonthlyRetentionStepFilterOption,
        "durationMonths"
      >)
    | ({ __typename?: "CandidateHourlyRetentionStepFilterOption" } & Pick<
        CandidateHourlyRetentionStepFilterOption,
        "durationHours"
      >)
  >
): OrganizationSteps {
  return {
    recruitmentSteps: filterOptions
      .filter(
        (option): option is CandidateRecruitmentStepFilterOption =>
          option.__typename === "CandidateRecruitmentStepFilterOption"
      )
      .map((option) => option.stepName),
    retentionMonthSteps: filterOptions
      .filter(
        (option): option is CandidateMonthlyRetentionStepFilterOption =>
          option.__typename === "CandidateMonthlyRetentionStepFilterOption"
      )
      .map((option) => option.durationMonths),
    retentionHoursSteps: filterOptions
      .filter(
        (option): option is CandidateHourlyRetentionStepFilterOption =>
          option.__typename === "CandidateHourlyRetentionStepFilterOption"
      )
      .map((option) => option.durationHours),
  };
}

export function allCandidatesShowingExceptArchived(
  organizationSteps: OrganizationSteps,
  filters: ListCandidatesV2FilterInput
): boolean {
  return (
    filters.archived === false &&
    allFiltersExceptArchivedDisabled(organizationSteps, filters)
  );
}

function allFiltersExceptArchivedDisabled(
  organizationSteps: OrganizationSteps,
  filters: ListCandidatesV2FilterInput
): boolean {
  return (
    searchQueryEmpty(filters) &&
    resumeFilterDisabled(filters) &&
    stepFilterDisabled(organizationSteps, filters)
  );
}

function searchQueryEmpty(filters: ListCandidatesV2FilterInput): boolean {
  return !filters.searchQuery;
}

function resumeFilterDisabled(filters: ListCandidatesV2FilterInput): boolean {
  return typeof filters.hasUploadedResume !== "boolean";
}

function stepFilterDisabled(
  organizationSteps: OrganizationSteps,
  filters: ListCandidatesV2FilterInput
): boolean {
  if (!Array.isArray(filters.statuses)) {
    return true;
  }

  const statusFilterOptions = getInitialCandidateFilterNextStepOptionsForOrganizationSteps(
    organizationSteps
  ).map(serializeCandidateFilterStepOptionIntoGraphQLInput);

  if (statusFiltersEqual(statusFilterOptions, filters.statuses!)) {
    return true;
  }

  return false;
}

function statusFiltersEqual(
  filters1: ListCandidateV2FilterStatusInput[],
  filters2: ListCandidateV2FilterStatusInput[]
): boolean {
  return filters1.every((filter1) =>
    filters2.some(
      (filter2) =>
        (filter1.recruitmentStep ?? undefined) ===
          (filter2.recruitmentStep ?? undefined) &&
        (filter1.retentionDurationMonths ?? undefined) ===
          (filter2.retentionDurationMonths ?? undefined)
    )
  );
}
