import { ClassNames } from "@emotion/react";
import { FormControlLabel, lighten, Switch } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { forwardRef } from "react";

import { assertNever } from "@rewards-web/shared/lib/assert-never";
import {
  ControlledFormFieldProps,
  StylableProps,
} from "@rewards-web/shared/types";

import { useFormControlContext } from "../form/form-control";
import { Typography } from "../typography";

export interface SwitchFieldProps
  extends ControlledFormFieldProps,
    StylableProps {
  label: string | { onLabel: string; offLabel: string };
  hideLabel?: boolean;
  color?: "primary" | "error";
  labelVariant?: "standard" | "highlight";
  disabled?: boolean;
}

export const SwitchField = forwardRef(
  (
    {
      value,
      label,
      hideLabel,
      onChange,
      className,
      color = "primary",
      disabled,
      labelVariant = "standard",
    }: SwitchFieldProps,
    ref
  ): JSX.Element => {
    const { submitting } = useFormControlContext();
    const theme = useTheme();

    const isDisabled = disabled || submitting;

    const colorValue = (() => {
      const rawColorValue = (() => {
        switch (color) {
          case "error":
            return theme.palette.error.main;
          case "primary":
            return theme.palette.primary.main;
          default:
            assertNever(color);
        }
      })();
      return isDisabled ? lighten(rawColorValue, 0.38) : rawColorValue;
    })();

    const switchedOn = value === true;

    const currentLabel = (() => {
      if (typeof label === "string") {
        return label;
      }
      return switchedOn ? label.onLabel : label.offLabel;
    })();

    return (
      <ClassNames>
        {({ css }) => (
          <FormControlLabel
            className={className}
            classes={{
              root: css`
                margin: 0;
              `,
              label: css`
                ${hideLabel &&
                css`
                  display: none;
                `}
                margin-left: ${theme.spacing(1)}px;
              `,
            }}
            control={
              <Switch
                disabled={isDisabled}
                color={color === "error" ? undefined : "primary"}
                checked={value}
                onChange={onChange}
                classes={{
                  root: css`
                    width: 35px;
                    height: 22px;
                    padding: 0;
                    display: flex;
                    *,
                    *::before,
                    *::after {
                      box-sizing: inherit;
                    }
                  `,
                  switchBase: "custom-switch-base",
                  disabled: "custom-switch-disabled",
                  checked: "custom-switch-checked",
                  thumb: css`
                    width: 12px;
                    height: 12px;
                    margin: 3px;
                    box-shadow: none;
                  `,
                  track: "custom-switch-track",
                }}
                className={css`
                  .custom-switch-base {
                    padding: 2px;
                    color: ${theme.palette.grey[500]};
                    &.custom-switch-checked {
                      transform: translateX(12px);
                      color: ${theme.palette.common.white};
                    }
                  }
                  .custom-switch-base + .custom-switch-track {
                    opacity: 1;
                  }
                  .custom-switch-disabled + .custom-switch-track {
                    background-color: ${theme.palette.grey[300]};
                    border-color: ${theme.palette.grey[300]};
                  }
                  .custom-switch-checked + .custom-switch-track {
                    background-color: ${colorValue};
                    border-color: ${colorValue};
                  }
                  .custom-switch-track {
                    opacity: 1;
                    border: 1px solid ${theme.palette.grey[500]};
                    border-radius: 35px;
                    background-color: ${theme.palette.common.white};
                  }
                `}
              />
            }
            ref={ref}
            label={(() => {
              switch (labelVariant) {
                case "standard":
                  return currentLabel;
                case "highlight":
                  return (
                    <Typography
                      variant="subtitle"
                      color={
                        switchedOn
                          ? colorValue
                          : theme.palette.grey[isDisabled ? 600 : 800]
                      }
                    >
                      {currentLabel}
                    </Typography>
                  );
              }
            })()}
          />
        )}
      </ClassNames>
    );
  }
);
