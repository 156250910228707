import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DisableAdminGoalConfigMutationVariables = Types.Exact<{
  configId: Types.Scalars['ID'];
}>;


export type DisableAdminGoalConfigMutation = (
  { __typename?: 'Mutation' }
  & { disableOrganizationAdminGoalConfig: (
    { __typename?: 'AdminGoalConfig' }
    & Pick<Types.AdminGoalConfig, 'id'>
  ) }
);


export const DisableAdminGoalConfigDocument = gql`
    mutation DisableAdminGoalConfig($configId: ID!) {
  disableOrganizationAdminGoalConfig(configId: $configId) {
    id
  }
}
    `;
export type DisableAdminGoalConfigMutationFn = Apollo.MutationFunction<DisableAdminGoalConfigMutation, DisableAdminGoalConfigMutationVariables>;

/**
 * __useDisableAdminGoalConfigMutation__
 *
 * To run a mutation, you first call `useDisableAdminGoalConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAdminGoalConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAdminGoalConfigMutation, { data, loading, error }] = useDisableAdminGoalConfigMutation({
 *   variables: {
 *      configId: // value for 'configId'
 *   },
 * });
 */
export function useDisableAdminGoalConfigMutation(baseOptions?: Apollo.MutationHookOptions<DisableAdminGoalConfigMutation, DisableAdminGoalConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableAdminGoalConfigMutation, DisableAdminGoalConfigMutationVariables>(DisableAdminGoalConfigDocument, options);
      }
export type DisableAdminGoalConfigMutationHookResult = ReturnType<typeof useDisableAdminGoalConfigMutation>;
export type DisableAdminGoalConfigMutationResult = Apollo.MutationResult<DisableAdminGoalConfigMutation>;
export type DisableAdminGoalConfigMutationOptions = Apollo.BaseMutationOptions<DisableAdminGoalConfigMutation, DisableAdminGoalConfigMutationVariables>;