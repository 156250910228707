import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PulseSurveyFormDisableMutationVariables = Types.Exact<{
  disableDistributionGoalUpdatePolicy?: Types.Maybe<Types.GoalDistributionUpdatePolicy>;
}>;


export type PulseSurveyFormDisableMutation = (
  { __typename?: 'Mutation' }
  & { disableMyOrganizationPulseCheckSurveyGoalsConfig: (
    { __typename?: 'PulseCheckSurveyGoalDistributionConfig' }
    & Pick<Types.PulseCheckSurveyGoalDistributionConfig, 'id'>
  ) }
);


export const PulseSurveyFormDisableDocument = gql`
    mutation PulseSurveyFormDisable($disableDistributionGoalUpdatePolicy: GoalDistributionUpdatePolicy) {
  disableMyOrganizationPulseCheckSurveyGoalsConfig(
    disableDistributionGoalUpdatePolicy: $disableDistributionGoalUpdatePolicy
  ) {
    id
  }
}
    `;
export type PulseSurveyFormDisableMutationFn = Apollo.MutationFunction<PulseSurveyFormDisableMutation, PulseSurveyFormDisableMutationVariables>;

/**
 * __usePulseSurveyFormDisableMutation__
 *
 * To run a mutation, you first call `usePulseSurveyFormDisableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePulseSurveyFormDisableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pulseSurveyFormDisableMutation, { data, loading, error }] = usePulseSurveyFormDisableMutation({
 *   variables: {
 *      disableDistributionGoalUpdatePolicy: // value for 'disableDistributionGoalUpdatePolicy'
 *   },
 * });
 */
export function usePulseSurveyFormDisableMutation(baseOptions?: Apollo.MutationHookOptions<PulseSurveyFormDisableMutation, PulseSurveyFormDisableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PulseSurveyFormDisableMutation, PulseSurveyFormDisableMutationVariables>(PulseSurveyFormDisableDocument, options);
      }
export type PulseSurveyFormDisableMutationHookResult = ReturnType<typeof usePulseSurveyFormDisableMutation>;
export type PulseSurveyFormDisableMutationResult = Apollo.MutationResult<PulseSurveyFormDisableMutation>;
export type PulseSurveyFormDisableMutationOptions = Apollo.BaseMutationOptions<PulseSurveyFormDisableMutation, PulseSurveyFormDisableMutationVariables>;