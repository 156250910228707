import { reportError } from "@rewards-web/shared/modules/error";

import { useGetBranchLimitingPermissionSettingsQuery } from "./get-privacy-settings.generated";

export function usePermissionLimitedBranchIds() {
  const privacySettings = useGetBranchLimitingPermissionSettingsQuery({
    onError: reportError,
  });

  const data = (() => {
    if (!privacySettings.data) {
      return null; // null while loading permissions
    }

    const limitingAdminPermissionsByBranch =
      privacySettings.data.getMyRewardsOrganization
        .limitingAdminPermissionsByBranch;

    const branchIds =
      privacySettings.data.getMyRewardsAdminUser?.branches ?? [];
    const canOnlySeeBranchIds = (() => {
      if (limitingAdminPermissionsByBranch) {
        if (branchIds && branchIds.length > 0) {
          return branchIds.map((branch) => branch.id);
        }
      }

      return undefined;
    })();
    /*
  Determines whether the branch filter should be displayed to the user.
    The filter should be shown in the following cases:
    1. If branch-based admin permissions are not enforced (`limitingAdminPermissionsByBranch` is false).
    2. If the admin has more than one branch assigned.
    3. If the admin has no branches assigned (to allow selection).
    The filter is hidden when the admin has exactly one assigned branch.
   */
    const showBranchFilter =
      !limitingAdminPermissionsByBranch || !branchIds || branchIds.length !== 1;

    return {
      showBranchFilter,
      canOnlySeeBranchIds,
    };
  })();

  return {
    error: privacySettings.error,
    loading: privacySettings.loading,
    data,
  };
}
