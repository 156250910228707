/** @jsxImportSource @emotion/react */
import { FieldValues, Control, Controller, Path } from "react-hook-form";

import { SelectField } from "@rewards-web/shared/components/select-field";

interface CheckInSurveyLengthFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function CheckInSurveyLengthField<T extends FieldValues>({
  control,
  name,
}: CheckInSurveyLengthFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const options: {
          label: string;
          value: string;
        }[] = [
          {
            label: "4 questions",
            value: "4",
          },
        ];

        return (
          <SelectField
            {...field}
            label="Survey length"
            hideLabel
            error={fieldState.error}
            width="full"
            options={options}
            // Not allowed to change the survey length
            readOnly
            greyReadOnly
          />
        );
      }}
    />
  );
}
