/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { SurveyType } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

interface SurveySettingsErrorModalProps {
  title: string;
  subtitle: string;
  surveyType: SurveyType;
  open: boolean;
  onClose: () => void;
  onExited: () => void;
}

export function SurveySettingsErrorModal({
  title,
  subtitle,
  surveyType,
  open,
  onClose,
  onExited,
}: SurveySettingsErrorModalProps) {
  const track = useTrack();
  useEffect(() => {
    if (open) {
      track("Survey settings - shown error modal", {
        errorTitle: title,
        surveyType,
      });
    }
  }, [open, title, surveyType, track]);

  return (
    <Modal
      width="640px"
      open={open}
      onClose={onClose}
      onExited={onExited}
      showCloseButton
    >
      <ModalTitle>
        <div
          css={(theme: AppTheme) => css`
            width: 100%;
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          <Typography
            align="center"
            variant="h5"
            css={(theme: AppTheme) => css`
              text-align: left;
              margin-top: ${theme.spacing(2.5)};
              margin-bottom: -${theme.spacing(2)};
            `}
          >
            {title}
          </Typography>
        </div>
      </ModalTitle>
      <ModalContent>
        <Typography
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(3)};
            `
          }
          variant="body"
          color="grey.800"
        >
          {subtitle}
        </Typography>
      </ModalContent>
    </Modal>
  );
}
