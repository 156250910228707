import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OnboardingEditReferralStructureMutationVariables = Types.Exact<{
  referralStructureId: Types.Scalars['ID'];
  structure?: Types.Maybe<Types.ReferralStructureInput>;
}>;


export type OnboardingEditReferralStructureMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateReferralStructure: (
    { __typename?: 'ReferralStructure' }
    & Pick<Types.ReferralStructure, 'id'>
    & { items: Array<{ __typename?: 'ReferralRewardStructureJobShareItem' } | (
      { __typename?: 'ReferralRewardStructureReferralRecruitmentItem' }
      & Pick<Types.ReferralRewardStructureReferralRecruitmentItem, 'step'>
      & { reward: (
        { __typename?: 'ReferralRewardStructurePointReward' }
        & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
      ) | { __typename?: 'ReferralRewardStructureDrawTicketReward' } | { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' } }
    ) | (
      { __typename?: 'ReferralRewardStructureReferralRetentionItem' }
      & { duration: (
        { __typename?: 'ReferralRewardStructureReferralRetentionItemDuration' }
        & Pick<Types.ReferralRewardStructureReferralRetentionItemDuration, 'unit' | 'amount'>
      ), reward: (
        { __typename?: 'ReferralRewardStructurePointReward' }
        & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
      ) | { __typename?: 'ReferralRewardStructureDrawTicketReward' } | { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' } }
    )> }
  ) }
);


export const OnboardingEditReferralStructureDocument = gql`
    mutation OnboardingEditReferralStructure($referralStructureId: ID!, $structure: ReferralStructureInput) {
  partialUpdateReferralStructure(
    referralStructureId: $referralStructureId
    structure: $structure
  ) {
    id
    items {
      ... on ReferralRewardStructureReferralRecruitmentItem {
        step
        reward {
          ... on ReferralRewardStructurePointReward {
            pointValue
          }
        }
      }
      ... on ReferralRewardStructureReferralRetentionItem {
        duration {
          unit
          amount
        }
        reward {
          ... on ReferralRewardStructurePointReward {
            pointValue
          }
        }
      }
    }
  }
}
    `;
export type OnboardingEditReferralStructureMutationFn = Apollo.MutationFunction<OnboardingEditReferralStructureMutation, OnboardingEditReferralStructureMutationVariables>;

/**
 * __useOnboardingEditReferralStructureMutation__
 *
 * To run a mutation, you first call `useOnboardingEditReferralStructureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingEditReferralStructureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingEditReferralStructureMutation, { data, loading, error }] = useOnboardingEditReferralStructureMutation({
 *   variables: {
 *      referralStructureId: // value for 'referralStructureId'
 *      structure: // value for 'structure'
 *   },
 * });
 */
export function useOnboardingEditReferralStructureMutation(baseOptions?: Apollo.MutationHookOptions<OnboardingEditReferralStructureMutation, OnboardingEditReferralStructureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardingEditReferralStructureMutation, OnboardingEditReferralStructureMutationVariables>(OnboardingEditReferralStructureDocument, options);
      }
export type OnboardingEditReferralStructureMutationHookResult = ReturnType<typeof useOnboardingEditReferralStructureMutation>;
export type OnboardingEditReferralStructureMutationResult = Apollo.MutationResult<OnboardingEditReferralStructureMutation>;
export type OnboardingEditReferralStructureMutationOptions = Apollo.BaseMutationOptions<OnboardingEditReferralStructureMutation, OnboardingEditReferralStructureMutationVariables>;