import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ViewReferralStructureDataQueryVariables = Types.Exact<{
  referralStructureId: Types.Scalars['ID'];
}>;


export type ViewReferralStructureDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar' | 'launched'>
    & { referralRewardStructure: (
      { __typename?: 'ReferralRewardStructure' }
      & Pick<Types.ReferralRewardStructure, 'id'>
      & { jobShareStructure: (
        { __typename?: 'ReferralRewardStructureJobShareItem' }
        & Pick<Types.ReferralRewardStructureJobShareItem, 'maxAwardableJobSharesPerMonth'>
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' } }
      ) }
    ) }
  ), getReferralStructureById?: Types.Maybe<(
    { __typename?: 'ReferralStructure' }
    & Pick<Types.ReferralStructure, 'id' | 'name'>
    & { items: Array<{ __typename?: 'ReferralRewardStructureJobShareItem' } | (
      { __typename?: 'ReferralRewardStructureReferralRecruitmentItem' }
      & Pick<Types.ReferralRewardStructureReferralRecruitmentItem, 'step'>
      & { reward: (
        { __typename?: 'ReferralRewardStructurePointReward' }
        & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
      ) | { __typename?: 'ReferralRewardStructureDrawTicketReward' } | { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' } }
    ) | (
      { __typename?: 'ReferralRewardStructureReferralRetentionItem' }
      & { duration: (
        { __typename?: 'ReferralRewardStructureReferralRetentionItemDuration' }
        & Pick<Types.ReferralRewardStructureReferralRetentionItemDuration, 'unit' | 'amount'>
      ), reward: (
        { __typename?: 'ReferralRewardStructurePointReward' }
        & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
      ) | { __typename?: 'ReferralRewardStructureDrawTicketReward' } | { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' } }
    )> }
  )> }
);


export const ViewReferralStructureDataDocument = gql`
    query ViewReferralStructureData($referralStructureId: ID!) {
  getMyRewardsOrganization {
    id
    pointsPerDollar
    launched
    referralRewardStructure {
      id
      jobShareStructure {
        maxAwardableJobSharesPerMonth
        reward {
          ... on ReferralRewardStructurePointReward {
            pointValue
          }
          ... on ReferralRewardStructureDrawTicketReward {
            numTickets
          }
        }
      }
    }
  }
  getReferralStructureById(referralStructureId: $referralStructureId) {
    id
    name
    items {
      ... on ReferralRewardStructureReferralRecruitmentItem {
        step
        reward {
          ... on ReferralRewardStructurePointReward {
            pointValue
          }
        }
      }
      ... on ReferralRewardStructureReferralRetentionItem {
        duration {
          unit
          amount
        }
        reward {
          ... on ReferralRewardStructurePointReward {
            pointValue
          }
        }
      }
    }
  }
}
    `;

/**
 * __useViewReferralStructureDataQuery__
 *
 * To run a query within a React component, call `useViewReferralStructureDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewReferralStructureDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewReferralStructureDataQuery({
 *   variables: {
 *      referralStructureId: // value for 'referralStructureId'
 *   },
 * });
 */
export function useViewReferralStructureDataQuery(baseOptions: Apollo.QueryHookOptions<ViewReferralStructureDataQuery, ViewReferralStructureDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewReferralStructureDataQuery, ViewReferralStructureDataQueryVariables>(ViewReferralStructureDataDocument, options);
      }
export function useViewReferralStructureDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewReferralStructureDataQuery, ViewReferralStructureDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewReferralStructureDataQuery, ViewReferralStructureDataQueryVariables>(ViewReferralStructureDataDocument, options);
        }
export type ViewReferralStructureDataQueryHookResult = ReturnType<typeof useViewReferralStructureDataQuery>;
export type ViewReferralStructureDataLazyQueryHookResult = ReturnType<typeof useViewReferralStructureDataLazyQuery>;
export type ViewReferralStructureDataQueryResult = Apollo.QueryResult<ViewReferralStructureDataQuery, ViewReferralStructureDataQueryVariables>;