/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { FormControlProvider } from "@rewards-web/shared/components/form/form-control";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { FileInput } from "../../../../../components/file-input";
import { useUploadCandidateResumeMutation } from "./upload-resume.generated";

interface UploadResumeModalProps {
  candidateId: string;
  open: boolean;
  hasUploadedResume: boolean;
  onClose(): void;
  onUploadedResume(): void;
}

export function UploadResumeModal({
  candidateId,
  open,
  hasUploadedResume,
  onClose,
  onUploadedResume,
}: UploadResumeModalProps): JSX.Element {
  const snackbar = useSnackbar();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [error, setError] = useState<FieldError | undefined>(undefined);
  const [
    uploadFile,
    { loading: uploading },
  ] = useUploadCandidateResumeMutation();

  useEffect(() => {
    if (!open) {
      setUploadedFile(null);
      setError(undefined);
    }
  }, [open]);

  const handleFileUpload = async () => {
    if (!uploadedFile) {
      setError({
        type: "required",
        message: "Please select a file before uploading.",
      });
    } else {
      try {
        await uploadFile({
          variables: {
            candidateId,
            resume: uploadedFile,
          },
        });
        onUploadedResume();
        onClose();
        snackbar.show({
          severity: "success",
          message: `Resume successfully ${
            hasUploadedResume ? "updated" : "uploaded"
          }!`,
        });
      } catch (error) {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  return (
    <FormControlProvider value={{ submitting: uploading }}>
      <Modal
        width="690px"
        open={open}
        onClose={onClose}
        disableBackdropClick={uploading}
      >
        <ModalTitle
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(-3)};
            `
          }
        >{`${hasUploadedResume ? "Update" : "Upload"} Resume`}</ModalTitle>
        <ModalContent>
          <FileInput
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
            isDisabled={uploading}
            error={error}
            setError={setError}
            acceptedFileTypes=".pdf,.docx,.doc,.jpeg,.jpg"
            maxSizeMB={20}
          />
          <Typography
            variant="body"
            color="textPrimary"
            css={(theme: AppTheme) =>
              css`
                padding-bottom: ${theme.spacing(2)};
              `
            }
          >
            Select a .pdf, .docx, .doc, .jpeg or .jpg as file format no larger
            than 20 MB. If you have any questions contact us at{" "}
            <a href={`mailto:${encodeURI("help+admin@caribou.care")}`}>
              help+admin@caribou.care
            </a>
            .
          </Typography>
        </ModalContent>
        <ModalActions>
          <Button
            size="large"
            label="Cancel"
            width="auto"
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          />
          <Button
            size="large"
            label="Upload"
            width="auto"
            color="primary"
            loading={uploading}
            onClick={handleFileUpload}
          />
        </ModalActions>
      </Modal>
    </FormControlProvider>
  );
}
