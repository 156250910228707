/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";
import { Fragment, ReactNode, useEffect } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { FormControlProvider } from "@rewards-web/shared/components/form/form-control";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalErrorState } from "@rewards-web/shared/components/modal/modal-error-state";
import { ModalLoadingState } from "@rewards-web/shared/components/modal/modal-loading-state";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useConfirmChangeJobDataQuery } from "./confirm-change-job-data.generated";

interface ConfirmChangeJobModalProps {
  open: boolean;
  confirming: boolean;
  onConfirm(details: { movedOutOfOrganization: boolean }): void;
  onCancel(): void;
  currentJobPostingId: string | null;
  nextJobPostingId: string | null;
}

export function ConfirmChangeJobModal({
  open,
  confirming,
  onConfirm,
  onCancel,
  currentJobPostingId,
  nextJobPostingId,
}: ConfirmChangeJobModalProps): JSX.Element {
  const confirmChangeJobDataQuery = useConfirmChangeJobDataQuery({
    onError: reportError,
    skip: !currentJobPostingId || !nextJobPostingId,
    variables: {
      currentJobPostingId: currentJobPostingId!,
      nextJobPostingId: nextJobPostingId!,
    },
  });

  useEffect(() => {
    if (
      confirmChangeJobDataQuery.data &&
      (!confirmChangeJobDataQuery.data.currentJobPosting ||
        !confirmChangeJobDataQuery.data.nextJobPosting)
    ) {
      reportError(new Error(`Current or next job posting could not be found`));
    }
  }, [confirmChangeJobDataQuery.data]);

  const content = (() => {
    if (confirmChangeJobDataQuery.error) {
      return <ModalErrorState onClose={onCancel} />;
    }

    if (!confirmChangeJobDataQuery.data) {
      return <ModalLoadingState />;
    }

    if (
      !confirmChangeJobDataQuery.data.currentJobPosting ||
      !confirmChangeJobDataQuery.data.nextJobPosting
    ) {
      return <ModalErrorState onClose={onCancel} />;
    }

    const {
      data: { currentJobPosting, nextJobPosting },
    } = confirmChangeJobDataQuery;

    const messages: ReactNode[] = compact([
      nextJobPosting.referralStructure.id !==
        currentJobPosting.referralStructure.id && (
        <Alert
          messageTypographyVariant="footnote"
          severity="warning"
          title="Updated referral structure"
          message={
            <>
              The candidate's referral structure will be changed from{" "}
              <strong>
                {currentJobPosting.referralStructure.name} (
                {formatDollars(
                  currentJobPosting.referralStructure.totalAwardablePointValue /
                    currentJobPosting.organization.pointsPerDollar
                )}
                )
              </strong>{" "}
              to{" "}
              <strong>
                {nextJobPosting.referralStructure.name} (
                {formatDollars(
                  nextJobPosting.referralStructure.totalAwardablePointValue /
                    nextJobPosting.organization.pointsPerDollar
                )}
                )
              </strong>
              . When the candidate completes more steps, the referring employee
              may receive a different referral reward than originally
              communicated.
            </>
          }
        />
      ),

      <Typography variant="body" color="textPrimary">
        This candidate's job will be changed.
        <br />
        <strong>Current job:</strong> {currentJobPosting.title} (
        {currentJobPosting.geography})
        <br />
        <strong>New job:</strong> {nextJobPosting.title} (
        {nextJobPosting.geography})
        <br />
      </Typography>,

      nextJobPosting.candidateAlertEmail &&
        currentJobPosting.candidateAlertEmail !==
          nextJobPosting.candidateAlertEmail && (
          <Typography variant="body" color="textPrimary">
            {nextJobPosting.candidateAlertEmail}
            {nextJobPosting.organization.branchName && (
              <>
                {" "}
                at {nextJobPosting.organization.shortName} (
                {nextJobPosting.organization.branchName})
              </>
            )}{" "}
            will be notified of the new candidate.
          </Typography>
        ),

      nextJobPosting.organization.branchName &&
        nextJobPosting.organization.branchName !==
          currentJobPosting.organization.branchName && (
          <Typography variant="body" color="textPrimary">
            The candidate be will sent to{" "}
            {nextJobPosting.organization.shortName} (
            {nextJobPosting.organization.branchName})
          </Typography>
        ),
      <Typography variant="body" color="textPrimary">
        Email{" "}
        <a
          css={css`
            font-weight: bold;
          `}
          href={`mailto:${encodeURI("help+admin@caribou.care")}`}
        >
          help+admin@caribou.care
        </a>{" "}
        for further help.
      </Typography>,
    ]);

    return (
      <>
        <ModalTitle>Confirm</ModalTitle>
        <ModalContent>
          {messages.map((message, idx) => (
            <Fragment key={idx}>
              {message}
              {idx < messages.length - 1 && (
                <Divider
                  css={(theme: AppTheme) =>
                    css`
                      margin: ${theme.spacing(2)} 0;
                    `
                  }
                />
              )}
            </Fragment>
          ))}
        </ModalContent>
        <ModalActions>
          <Button
            width="auto"
            size="large"
            label="Cancel"
            variant="outlined"
            onClick={() => {
              onCancel();
            }}
          />
          <Button
            width="auto"
            size="large"
            label="Confirm"
            color="primary"
            onClick={() => {
              onConfirm({
                movedOutOfOrganization:
                  currentJobPosting.organization.id !==
                  nextJobPosting.organization.id,
              });
            }}
          />
        </ModalActions>
      </>
    );
  })();

  return (
    <FormControlProvider value={{ submitting: confirming }}>
      <Modal
        width="650px"
        open={open}
        onClose={onCancel}
        disableBackdropClick={confirming}
      >
        {content}
      </Modal>
    </FormControlProvider>
  );
}
