/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { usePathParamToggle } from "@rewards-web/shared/hooks/use-path-param-toggle";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";

import { BranchFilter } from "../../../shared/components/branch-filter";
import { HeaderWithButton } from "../../../shared/components/header-with-button";
import { usePermissionLimitedBranchIds } from "../../../shared/modules/branches/use-permission-limited-branch-ids";
import { AddNewJobModal } from "../../../shared/modules/jobs/list/components/add-new-job-modal";
import { AdminJobsDataTable } from "../../../shared/modules/jobs/list/components/jobs-data-table";
import { useAdminJobsTableQuery } from "../../../shared/modules/jobs/list/hooks/use-jobs-table-query";
import { useHasPermissionQuery } from "../../../shared/modules/permissions/hooks/use-has-permission-query";
import { CreateJobButton } from "./create-job-button";
import { JobsHeader } from "./jobs-header";
import { useJobsPageDataQuery } from "./jobs-page-data.generated";

const ITEMS_PER_PAGE = 10;

export function JobsPageContents() {
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const track = useTrack();
  const {
    data: permissionLimitedBranchIds,
    loading: branchIdsLoading,
    error: branchIdsError,
  } = usePermissionLimitedBranchIds();

  const fullJobsPermissionsQuery = useHasPermissionQuery("full", "jobs");
  const jobsPageData = useJobsPageDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
    skip: !permissionLimitedBranchIds,
  });

  const readOnly =
    !fullJobsPermissionsQuery.hasPermission ||
    jobsPageData.data?.getMyRewardsOrganization.referralsEnabled === false;

  const showBranchFilter =
    (jobsPageData.data?.getMyRewardsOrganization.branches ?? []).length > 0 &&
    permissionLimitedBranchIds?.showBranchFilter;

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [addNewJobModalOpen, setAddNewJobModalOpen] = useState(false);
  const [selectedBranchIds, setSelectedBranchIds] = useState<(string | null)[]>(
    []
  );

  const jobsQueryVariables = (() => {
    if (branchIdsLoading) {
      return null;
    }

    // If branch-based admin permissions are enforced, we only want to show the branches the admin has access to
    // If the admin has no branches assigned, it means they have access to all branches

    return {
      branchIds:
        selectedBranchIds.length > 0
          ? selectedBranchIds
          : permissionLimitedBranchIds?.canOnlySeeBranchIds ?? undefined,
      currentPageIndex,
      perPage: ITEMS_PER_PAGE,
    };
  })();

  const { items, total, loading, refetch } = useAdminJobsTableQuery(
    jobsQueryVariables
  );

  const [editingJobId, editingJob] = usePathParamToggle({
    param: "id",
    onPath: "/jobs/:id/edit",
    offPath: "/jobs",
  });
  if (jobsPageData.error || branchIdsError) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  return (
    <>
      {showBranchFilter ? (
        <>
          <JobsHeader />

          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            `}
          >
            <BranchFilter
              permissionLimitedBranchIds={permissionLimitedBranchIds}
              value={selectedBranchIds ?? [null]}
              onChange={setSelectedBranchIds}
              resourceName="Jobs"
              onOpen={() => {
                trackScreenRecordingEvent("jobs_branch_filter_opened");
                track("Opened jobs branch filter");
              }}
            />

            <CreateJobButton
              disabled={readOnly}
              onClick={() => {
                setAddNewJobModalOpen(true);
              }}
            />
          </div>
        </>
      ) : (
        <HeaderWithButton
          header={<JobsHeader />}
          button={
            <CreateJobButton
              disabled={readOnly}
              onClick={() => {
                setAddNewJobModalOpen(true);
              }}
            />
          }
        />
      )}

      <AdminJobsDataTable
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        perPage={ITEMS_PER_PAGE}
        loading={loading || branchIdsLoading}
        items={items}
        total={total}
        editingJobId={editingJobId}
        onCloseEditJob={editingJob.navigateToOffPath}
        onOpenEditJob={editingJob.navigateToOnPath}
        refreshList={refetch}
        readOnly={readOnly}
        duplicateJobConfirmationDescription="You'll be directed to a duplicate of the job posting you selected. You can edit this duplicate before publishing it."
        duplicateJobSubmitButtonLabel="Publish"
        editJobDescription="Press “Save” to publish these changes on the active job posting."
        getCloseJobConfirmationDescription={(jobPostingTitle) =>
          `The job posting "${jobPostingTitle}" will no longer be visible by employees or future candidates.`
        }
        duplicateJobSuccessSnackbarMessage="The job posting was published"
        pointsPerDollar={
          jobsPageData.data?.getMyRewardsOrganization.pointsPerDollar
        }
      />

      <AddNewJobModal
        open={addNewJobModalOpen}
        onClose={() => {
          setAddNewJobModalOpen(false);
        }}
        onAdded={() => refetch()}
        duplicatingJobPostingId={null}
        submitButtonLabel="Publish"
        successSnackbarMessage="The job posting was published"
      />
    </>
  );
}
