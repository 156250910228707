import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateJobPostingMutationVariables = Types.Exact<{
  branchId?: Types.Maybe<Types.Scalars['ID']>;
  referralStructureId?: Types.Maybe<Types.Scalars['ID']>;
  title: Types.Scalars['String'];
  hoursRequired: Types.Scalars['String'];
  ratesOfPay?: Types.Maybe<Types.Scalars['String']>;
  geography: Types.Scalars['String'];
  descriptionHTML: Types.Scalars['String'];
  candidateAlertEmail?: Types.Maybe<Types.Scalars['String']>;
  wasDuplicatedFromJobPostingId?: Types.Maybe<Types.Scalars['ID']>;
  customizedApplicantEmailMessage?: Types.Maybe<Types.Scalars['String']>;
  customizedApplicantSMSMessage?: Types.Maybe<Types.Scalars['String']>;
  interviewBookingLink?: Types.Maybe<Types.InterviewBookingLink>;
  screenerQuestions: Array<Types.JobPostingScreenerQuestionInput> | Types.JobPostingScreenerQuestionInput;
}>;


export type CreateJobPostingMutation = (
  { __typename?: 'Mutation' }
  & { createJobPosting: (
    { __typename?: 'JobPosting' }
    & Pick<Types.JobPosting, 'id'>
  ) }
);


export const CreateJobPostingDocument = gql`
    mutation CreateJobPosting($branchId: ID, $referralStructureId: ID, $title: String!, $hoursRequired: String!, $ratesOfPay: String, $geography: String!, $descriptionHTML: String!, $candidateAlertEmail: String, $wasDuplicatedFromJobPostingId: ID, $customizedApplicantEmailMessage: String, $customizedApplicantSMSMessage: String, $interviewBookingLink: InterviewBookingLink, $screenerQuestions: [JobPostingScreenerQuestionInput!]!) {
  createJobPosting(
    branchId: $branchId
    referralStructureId: $referralStructureId
    title: $title
    hoursRequired: $hoursRequired
    ratesOfPay: $ratesOfPay
    geography: $geography
    descriptionHTML: $descriptionHTML
    screenerQuestions: $screenerQuestions
    candidateAlertEmail: $candidateAlertEmail
    wasDuplicatedFromJobPostingId: $wasDuplicatedFromJobPostingId
    customizedApplicantEmailMessage: $customizedApplicantEmailMessage
    customizedApplicantSMSMessage: $customizedApplicantSMSMessage
    interviewBookingLink: $interviewBookingLink
  ) {
    id
  }
}
    `;
export type CreateJobPostingMutationFn = Apollo.MutationFunction<CreateJobPostingMutation, CreateJobPostingMutationVariables>;

/**
 * __useCreateJobPostingMutation__
 *
 * To run a mutation, you first call `useCreateJobPostingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobPostingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobPostingMutation, { data, loading, error }] = useCreateJobPostingMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      referralStructureId: // value for 'referralStructureId'
 *      title: // value for 'title'
 *      hoursRequired: // value for 'hoursRequired'
 *      ratesOfPay: // value for 'ratesOfPay'
 *      geography: // value for 'geography'
 *      descriptionHTML: // value for 'descriptionHTML'
 *      candidateAlertEmail: // value for 'candidateAlertEmail'
 *      wasDuplicatedFromJobPostingId: // value for 'wasDuplicatedFromJobPostingId'
 *      customizedApplicantEmailMessage: // value for 'customizedApplicantEmailMessage'
 *      customizedApplicantSMSMessage: // value for 'customizedApplicantSMSMessage'
 *      interviewBookingLink: // value for 'interviewBookingLink'
 *      screenerQuestions: // value for 'screenerQuestions'
 *   },
 * });
 */
export function useCreateJobPostingMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobPostingMutation, CreateJobPostingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobPostingMutation, CreateJobPostingMutationVariables>(CreateJobPostingDocument, options);
      }
export type CreateJobPostingMutationHookResult = ReturnType<typeof useCreateJobPostingMutation>;
export type CreateJobPostingMutationResult = Apollo.MutationResult<CreateJobPostingMutation>;
export type CreateJobPostingMutationOptions = Apollo.BaseMutationOptions<CreateJobPostingMutation, CreateJobPostingMutationVariables>;