import { compact } from "lodash";
import { useCallback, useMemo } from "react";

import {
  CandidateRecruitmentStepName,
  ListCandidateV2NextStepInInput,
  Maybe,
  Scalars,
} from "@rewards-web/shared/graphql-types";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import {
  serializeBooleanQueryParam,
  parseBooleanQueryParam,
} from "@rewards-web/shared/lib/query-param-utils";

interface CandidatesFilterState {
  searchQuery?: string | null;
  archived?: boolean | null;
  hasUploadedResume?: boolean | null;
  nextStepIn?: ListCandidateV2NextStepInInput[] | null;
  assignedToJobPostingIds?: string[] | null;
  branchIds?: string[] | null;
}

type UpdateFilterInput = (
  nextFilters:
    | CandidatesFilterState
    | ((prev: CandidatesFilterState) => CandidatesFilterState)
) => void;

export function useCandidatesFilterState(): [
  null | CandidatesFilterState,
  UpdateFilterInput
] {
  const [searchQueryQueryParam, setSearchQueryQueryParam] = useQueryParam("q");
  const [nextStepInQueryParam, setNextStepInQueryParam] = useQueryParam(
    "nextStepIn"
  );
  const [branchesQueryParam, setBranchesQueryParam] = useQueryParam("branches");
  const [jobsQueryParam, setJobsQueryParam] = useQueryParam("jobs");
  const [resumeQueryParam, setResumeQueryParam] = useQueryParam("resume");
  const [archivedQueryParam, setArchivedQueryParam] = useQueryParam("archived");

  const value = useMemo((): null | CandidatesFilterState => {
    return {
      searchQuery: searchQueryQueryParam,
      archived: parseBooleanQueryParam(archivedQueryParam),
      hasUploadedResume: parseBooleanQueryParam(resumeQueryParam),
      nextStepIn: parseNextSteps(nextStepInQueryParam),
      branchIds: parseBranches(branchesQueryParam),
      assignedToJobPostingIds: parseJobs(jobsQueryParam),
    };
  }, [
    searchQueryQueryParam,
    nextStepInQueryParam,
    resumeQueryParam,
    archivedQueryParam,
    branchesQueryParam,
    jobsQueryParam,
  ]);

  const handleUpdate = useCallback<UpdateFilterInput>(
    (updaterOrNextValue) => {
      const nextValue =
        typeof updaterOrNextValue === "function"
          ? updaterOrNextValue(value ?? {})
          : updaterOrNextValue;

      setSearchQueryQueryParam(nextValue.searchQuery || null);
      setNextStepInQueryParam(serializeNextSteps(nextValue.nextStepIn ?? null));
      setResumeQueryParam(
        serializeBooleanQueryParam(nextValue.hasUploadedResume)
      );
      setArchivedQueryParam(serializeBooleanQueryParam(nextValue.archived));
      setBranchesQueryParam(serializeBranches(nextValue.branchIds));
      setJobsQueryParam(serializeJobs(nextValue.assignedToJobPostingIds));
    },
    [
      value,
      setSearchQueryQueryParam,
      setNextStepInQueryParam,
      setResumeQueryParam,
      setArchivedQueryParam,
      setBranchesQueryParam,
      setJobsQueryParam,
    ]
  );

  return [value, handleUpdate];
}

type SerializedStatus =
  | Lowercase<`${CandidateRecruitmentStepName}`>
  | `${number}_months`
  | `${number}_hours`;

function serializeNextSteps(
  value: Maybe<Array<ListCandidateV2NextStepInInput>>
): string | null {
  if (!value) {
    return null;
  }

  return value
    .map(
      (status): SerializedStatus => {
        if (typeof status.recruitmentStep === "string") {
          return status.recruitmentStep.toLowerCase() as Lowercase<`${CandidateRecruitmentStepName}`>;
        }

        if (typeof status.retentionDurationMonths === "number") {
          return `${status.retentionDurationMonths}_months`;
        }

        return `${status.retentionDurationHours!}_hours`;
      }
    )
    .join(",");
}

function parseNextSteps(
  value: string | null
): Maybe<Array<ListCandidateV2NextStepInInput>> {
  if (!value) {
    return null;
  }

  return compact(
    value.split(",").map((status):
      | ListCandidateV2NextStepInInput
      | undefined => {
      if (
        Object.values(CandidateRecruitmentStepName).includes(
          status.toUpperCase() as any
        )
      ) {
        return {
          recruitmentStep: status.toUpperCase() as CandidateRecruitmentStepName,
        };
      }

      const months = Number(status.split("_months")[0]);

      if (!isNaN(months)) {
        return {
          retentionDurationMonths: months,
        };
      }

      const hours = Number(status.split("_hours")[0]);

      if (!isNaN(hours)) {
        return {
          retentionDurationHours: hours,
        };
      }

      return undefined;
    })
  );
}

function serializeBranches(
  ids: Maybe<Scalars["ID"][]> | undefined
): string | null {
  if (!ids || ids.length === 0) {
    return null;
  }

  return ids.join(",");
}

function parseBranches(
  branchesQueryParam: string | null
): Maybe<Scalars["ID"][]> {
  if (!branchesQueryParam) {
    return null;
  }

  const parsedValues = compact(branchesQueryParam.split(","));

  if (parsedValues.length === 0) {
    return null;
  }

  return parsedValues;
}

function serializeJobs(ids: Maybe<Scalars["ID"][]> | undefined): string | null {
  if (!ids || ids.length === 0) {
    return null;
  }

  return ids.join(",");
}

function parseJobs(jobsQueryParam: string | null): Maybe<Scalars["ID"][]> {
  if (!jobsQueryParam) {
    return null;
  }

  const parsedValues = compact(jobsQueryParam.split(","));

  if (parsedValues.length === 0) {
    return null;
  }

  return parsedValues;
}
