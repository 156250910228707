import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateBranchMutationVariables = Types.Exact<{
  branchId: Types.Scalars['String'];
  name: Types.Scalars['String'];
  tagConfig?: Types.Maybe<Types.RewardsGroupTagsConfigSchemaV1Input>;
  timezone?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UpdateBranchMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateOrganizationBranch: (
    { __typename?: 'RewardsOrganizationBranch' }
    & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
  ) }
);


export const UpdateBranchDocument = gql`
    mutation UpdateBranch($branchId: String!, $name: String!, $tagConfig: RewardsGroupTagsConfigSchemaV1Input, $timezone: String) {
  partialUpdateOrganizationBranch(
    branchId: $branchId
    name: $name
    autoMembershipTagCriteriaV1: $tagConfig
    timezone: $timezone
  ) {
    id
    name
  }
}
    `;
export type UpdateBranchMutationFn = Apollo.MutationFunction<UpdateBranchMutation, UpdateBranchMutationVariables>;

/**
 * __useUpdateBranchMutation__
 *
 * To run a mutation, you first call `useUpdateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBranchMutation, { data, loading, error }] = useUpdateBranchMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      name: // value for 'name'
 *      tagConfig: // value for 'tagConfig'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useUpdateBranchMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBranchMutation, UpdateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBranchMutation, UpdateBranchMutationVariables>(UpdateBranchDocument, options);
      }
export type UpdateBranchMutationHookResult = ReturnType<typeof useUpdateBranchMutation>;
export type UpdateBranchMutationResult = Apollo.MutationResult<UpdateBranchMutation>;
export type UpdateBranchMutationOptions = Apollo.BaseMutationOptions<UpdateBranchMutation, UpdateBranchMutationVariables>;