/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useWatch,
} from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { RadioGroup } from "@rewards-web/shared/components/radio-buttons/radio-group";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ModalRadioOption } from "../../../../../../../shared/components/modal-radio-option";

export type SurveySettingsUpdatePolicy = "availableAndLocked" | "lockedOnly";

interface SurveySettingsUpdateModalProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export function SurveySettingsUpdateModal<T extends FieldValues>({
  control,
  name,
  open,
  onClose,
  onSubmit,
}: SurveySettingsUpdateModalProps<T>) {
  const policy = useWatch({ control, name });
  return (
    <Modal width="700px" open={open} onClose={onClose} showCloseButton>
      <ModalTitle>
        <div
          css={(theme: AppTheme) => css`
            width: 100%;
            text-align: left;
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          <Typography
            align="center"
            variant="h5"
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2.5)};
              margin-bottom: -${theme.spacing(2)};
            `}
          >
            When do you want these changes to take effect?
          </Typography>
        </div>
      </ModalTitle>
      <ModalContent>
        <Controller
          control={control}
          name={name}
          rules={{
            required: "Update policy required",
          }}
          render={({ field, fieldState }) => (
            <RadioGroup
              row
              ariaLabel="update policy"
              error={fieldState.error}
              {...field}
            >
              <div
                css={(theme: AppTheme) =>
                  css`
                    display: flex;
                    flex-direction: column;
                  `
                }
              >
                <ModalRadioOption
                  value="availableAndLocked"
                  title="Apply changes immediately"
                  description="The updates will take effect right away for the current survey"
                  selected={policy === "availableAndLocked"}
                  onClick={field.onChange}
                />
                <ModalRadioOption
                  value="lockedOnly"
                  title="Apply to next Survey"
                  description="The current survey will remain unchanged, and updates will apply to the next one."
                  selected={policy === "lockedOnly"}
                  onClick={field.onChange}
                />
              </div>
            </RadioGroup>
          )}
        />
      </ModalContent>
      <ModalActions>
        <div
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(1)};
            text-align: center;
            width: 100%;
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            css={css`
              width: 140px;
            `}
            label="Cancel"
            variant="outlined"
            onClick={onClose}
          />
          <Button
            css={(theme: AppTheme) => css`
              white-space: nowrap;
              width: 200px;
              margin-left: ${theme.spacing(2)};
            `}
            label="Apply changes"
            color="primary"
            onClick={onSubmit}
            disabled={!policy}
          />
        </div>
      </ModalActions>
    </Modal>
  );
}
