import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAdminGoalConfigQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetAdminGoalConfigQuery = (
  { __typename?: 'Query' }
  & { getAdminGoalConfig?: Types.Maybe<(
    { __typename?: 'AdminGoalConfig' }
    & Pick<Types.AdminGoalConfig, 'id' | 'goalType' | 'startDate' | 'endDate' | 'enabled' | 'launched' | 'recurring'>
    & { admins: Array<(
      { __typename?: 'RewardsAdminUser' }
      & Pick<Types.RewardsAdminUser, 'id' | 'firstName' | 'lastName' | 'role' | 'active'>
    )>, rewards: (
      { __typename: 'AdminGoalRewardsGiftCard' }
      & Pick<Types.AdminGoalRewardsGiftCard, 'dollarValue'>
    ) | (
      { __typename: 'AdminGoalRewardsPayroll' }
      & Pick<Types.AdminGoalRewardsPayroll, 'dollarValue'>
    ), target: (
      { __typename: 'AdminGoalTargetPercentage' }
      & Pick<Types.AdminGoalTargetPercentage, 'percentage'>
    ) | { __typename: 'AdminGoalTargetTimeToFirstShift' }, recurringAdmins?: Types.Maybe<Array<(
      { __typename?: 'RewardsAdminUser' }
      & Pick<Types.RewardsAdminUser, 'id' | 'firstName' | 'lastName' | 'role' | 'active'>
    )>>, recurringRewards?: Types.Maybe<(
      { __typename: 'AdminGoalRewardsGiftCard' }
      & Pick<Types.AdminGoalRewardsGiftCard, 'dollarValue'>
    ) | (
      { __typename: 'AdminGoalRewardsPayroll' }
      & Pick<Types.AdminGoalRewardsPayroll, 'dollarValue'>
    )>, recurringTarget?: Types.Maybe<(
      { __typename: 'AdminGoalTargetPercentage' }
      & Pick<Types.AdminGoalTargetPercentage, 'percentage'>
    ) | { __typename: 'AdminGoalTargetTimeToFirstShift' }> }
  )> }
);


export const GetAdminGoalConfigDocument = gql`
    query GetAdminGoalConfig($id: ID!) {
  getAdminGoalConfig(id: $id) {
    id
    goalType
    admins {
      id
      firstName
      lastName
      role
      active
    }
    rewards {
      __typename
      ... on AdminGoalRewardsGiftCard {
        dollarValue
      }
      ... on AdminGoalRewardsPayroll {
        dollarValue
      }
    }
    target {
      __typename
      ... on AdminGoalTargetPercentage {
        percentage
      }
    }
    startDate
    endDate
    enabled
    launched
    recurring
    recurringAdmins {
      id
      firstName
      lastName
      role
      active
    }
    recurringRewards {
      __typename
      ... on AdminGoalRewardsGiftCard {
        dollarValue
      }
      ... on AdminGoalRewardsPayroll {
        dollarValue
      }
    }
    recurringTarget {
      __typename
      ... on AdminGoalTargetPercentage {
        percentage
      }
    }
  }
}
    `;

/**
 * __useGetAdminGoalConfigQuery__
 *
 * To run a query within a React component, call `useGetAdminGoalConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminGoalConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminGoalConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminGoalConfigQuery(baseOptions: Apollo.QueryHookOptions<GetAdminGoalConfigQuery, GetAdminGoalConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminGoalConfigQuery, GetAdminGoalConfigQueryVariables>(GetAdminGoalConfigDocument, options);
      }
export function useGetAdminGoalConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminGoalConfigQuery, GetAdminGoalConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminGoalConfigQuery, GetAdminGoalConfigQueryVariables>(GetAdminGoalConfigDocument, options);
        }
export type GetAdminGoalConfigQueryHookResult = ReturnType<typeof useGetAdminGoalConfigQuery>;
export type GetAdminGoalConfigLazyQueryHookResult = ReturnType<typeof useGetAdminGoalConfigLazyQuery>;
export type GetAdminGoalConfigQueryResult = Apollo.QueryResult<GetAdminGoalConfigQuery, GetAdminGoalConfigQueryVariables>;