import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubmitPulseSurveyReportRequestMutationVariables = Types.Exact<{
  dataSets: Array<Types.RewardReportDataSetConfigInput> | Types.RewardReportDataSetConfigInput;
}>;


export type SubmitPulseSurveyReportRequestMutation = (
  { __typename?: 'Mutation' }
  & { submitReportRequest: (
    { __typename?: 'RewardReport' }
    & Pick<Types.RewardReport, 'id'>
  ) }
);


export const SubmitPulseSurveyReportRequestDocument = gql`
    mutation SubmitPulseSurveyReportRequest($dataSets: [RewardReportDataSetConfigInput!]!) {
  submitReportRequest(dataSets: $dataSets) {
    id
  }
}
    `;
export type SubmitPulseSurveyReportRequestMutationFn = Apollo.MutationFunction<SubmitPulseSurveyReportRequestMutation, SubmitPulseSurveyReportRequestMutationVariables>;

/**
 * __useSubmitPulseSurveyReportRequestMutation__
 *
 * To run a mutation, you first call `useSubmitPulseSurveyReportRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPulseSurveyReportRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPulseSurveyReportRequestMutation, { data, loading, error }] = useSubmitPulseSurveyReportRequestMutation({
 *   variables: {
 *      dataSets: // value for 'dataSets'
 *   },
 * });
 */
export function useSubmitPulseSurveyReportRequestMutation(baseOptions?: Apollo.MutationHookOptions<SubmitPulseSurveyReportRequestMutation, SubmitPulseSurveyReportRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitPulseSurveyReportRequestMutation, SubmitPulseSurveyReportRequestMutationVariables>(SubmitPulseSurveyReportRequestDocument, options);
      }
export type SubmitPulseSurveyReportRequestMutationHookResult = ReturnType<typeof useSubmitPulseSurveyReportRequestMutation>;
export type SubmitPulseSurveyReportRequestMutationResult = Apollo.MutationResult<SubmitPulseSurveyReportRequestMutation>;
export type SubmitPulseSurveyReportRequestMutationOptions = Apollo.BaseMutationOptions<SubmitPulseSurveyReportRequestMutation, SubmitPulseSurveyReportRequestMutationVariables>;