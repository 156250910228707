import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EmployeeDetailDataQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type EmployeeDetailDataQuery = (
  { __typename?: 'Query' }
  & { user?: Types.Maybe<(
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'externalEmployeeId' | 'active' | 'firstName' | 'lastName' | 'workEmail' | 'workPhoneNumber' | 'dateOfBirth' | 'employmentStartDate' | 'lastLoginDate' | 'lastRecognitionPointsReceivedDate'>
    & { personalContactInfo?: Types.Maybe<(
      { __typename: 'RewardsUserPersonalContactInfoData' }
      & Pick<Types.RewardsUserPersonalContactInfoData, 'id' | 'email' | 'phoneNumber'>
    ) | (
      { __typename: 'RewardsUserPersonalContactInfoSuppressed' }
      & Pick<Types.RewardsUserPersonalContactInfoSuppressed, 'id'>
    )>, branch?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationBranch' }
      & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
    )> }
  )>, organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'launched' | 'usesWorkDevices' | 'integratedWithAlayaCare' | 'managersEnabled' | 'referralsEnabled'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id' | 'rewardsProgramShortName'>
    )> }
  ) }
);


export const EmployeeDetailDataDocument = gql`
    query EmployeeDetailData($userId: ID!) {
  user: getRewardsUserById(userId: $userId) {
    id
    externalEmployeeId
    active
    firstName
    lastName
    workEmail
    workPhoneNumber
    personalContactInfo {
      __typename
      ... on RewardsUserPersonalContactInfoData {
        id
        email
        phoneNumber
      }
      ... on RewardsUserPersonalContactInfoSuppressed {
        id
      }
    }
    branch {
      id
      name
    }
    dateOfBirth
    employmentStartDate
    lastLoginDate
    lastRecognitionPointsReceivedDate
  }
  organization: getMyRewardsOrganization {
    id
    launched
    usesWorkDevices
    integratedWithAlayaCare
    managersEnabled
    referralsEnabled
    whiteLabelConfig {
      id
      rewardsProgramShortName
    }
  }
}
    `;

/**
 * __useEmployeeDetailDataQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEmployeeDetailDataQuery(baseOptions: Apollo.QueryHookOptions<EmployeeDetailDataQuery, EmployeeDetailDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeDetailDataQuery, EmployeeDetailDataQueryVariables>(EmployeeDetailDataDocument, options);
      }
export function useEmployeeDetailDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDetailDataQuery, EmployeeDetailDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeDetailDataQuery, EmployeeDetailDataQueryVariables>(EmployeeDetailDataDocument, options);
        }
export type EmployeeDetailDataQueryHookResult = ReturnType<typeof useEmployeeDetailDataQuery>;
export type EmployeeDetailDataLazyQueryHookResult = ReturnType<typeof useEmployeeDetailDataLazyQuery>;
export type EmployeeDetailDataQueryResult = Apollo.QueryResult<EmployeeDetailDataQuery, EmployeeDetailDataQueryVariables>;