/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Card, CardContent } from "@rewards-web/shared/components/card";
import {
  BarChartProps,
  BarChart,
} from "@rewards-web/shared/components/chart/bar-chart";
import {
  Legend,
  LegendItem,
} from "@rewards-web/shared/components/chart/legend";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RecognitionBudgetPeriod } from "@rewards-web/shared/graphql-types";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useHasManagedEmployeesQuery } from "../has-managed-employees.generated";
import { formatBudgetPeriodRange } from "../lib";
import { useRecognitionBudgetEnabledQuery } from "../recognition-budget-enabled.generated";
import { RecognitionBudgetIcon } from "../recognition-budget-icon";
import { useRecognitionPointsSentSummaryQuery } from "../recognition-points-sent-summary.generated";

const OVERBUDGET_COLOR = "#EE3C3C";

interface PointsSentSummaryCardProps {
  period?: Pick<RecognitionBudgetPeriod, "startDate" | "endDate">;
}

export function PointsSentSummaryCard({
  period,
}: PointsSentSummaryCardProps): JSX.Element | null {
  const theme = useTheme();

  const recognitionBudgetEnabledQuery = useRecognitionBudgetEnabledQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const recognitionBudgetEnabled =
    recognitionBudgetEnabledQuery.data?.getMyRewardsOrganization
      .recognitionBudgetsEnabled ?? false;

  const recognitionPointsSentSummaryQuery = useRecognitionPointsSentSummaryQuery(
    {
      onError: reportError,
      variables: {
        startDate: period?.startDate,
        endDate: period?.endDate,
      },
      skip: !period,
    }
  );

  const hasManagedEmployeesQuery = useHasManagedEmployeesQuery({
    onError: reportError,
  });

  const hasManagedEmployees =
    (hasManagedEmployeesQuery.data?.listMyManagedEmployeesRecognitionSummaryV2
      .total ?? 0) > 0;

  const budgetDefined = Boolean(period);

  const overBudget =
    budgetDefined &&
    (recognitionPointsSentSummaryQuery.data?.getMyRecognitionPointsSentSummaryV2
      .pointsSentTotal ?? 0) >
      (recognitionPointsSentSummaryQuery.data
        ?.getMyRecognitionPointsSentSummaryV2.pointsBudget ?? 0);

  function BudgetSubheading(): JSX.Element {
    if (!budgetDefined) {
      return (
        <Typography
          css={css`
            padding-top: ${theme.spacing(1)};
            padding-bottom: ${theme.spacing(1)};
          `}
        >
          You do not have budget to award employees. If this is incorrect,
          please contact your Supervisor.
        </Typography>
      );
    }
    if (overBudget) {
      return (
        <Typography
          css={css`
            padding-top: ${theme.spacing(1)};
            padding-bottom: ${theme.spacing(1)};
          `}
        >
          {"You are over budget by "}
          <strong
            css={css`
              color: ${OVERBUDGET_COLOR};
            `}
          >
            {`${numberWithCommas(
              recognitionPointsSentSummaryQuery.data!
                .getMyRecognitionPointsSentSummaryV2.pointsSentTotal -
                recognitionPointsSentSummaryQuery.data!
                  .getMyRecognitionPointsSentSummaryV2!.pointsBudget!
            )} points.`}
          </strong>
        </Typography>
      );
    }
    return (
      <Typography
        css={css`
          padding-top: ${theme.spacing(1)};
          padding-bottom: ${theme.spacing(1)};
        `}
      >
        {`You have ${numberWithCommas(
          recognitionPointsSentSummaryQuery.data
            ?.getMyRecognitionPointsSentSummaryV2.pointsAvailableInBudget ?? 0
        )} points left for the next ${
          recognitionPointsSentSummaryQuery.data
            ?.getMyRecognitionPointsSentSummaryV2.daysLeftToAward ?? 0
        } days to award.`}
      </Typography>
    );
  }

  const budgetColor = (() => {
    if (recognitionBudgetEnabled && budgetDefined && !overBudget) {
      return theme.palette.primary.main;
    }

    if (recognitionBudgetEnabled && budgetDefined && overBudget) {
      return OVERBUDGET_COLOR;
    }

    return "#444444";
  })();

  const budgetLegendItems: LegendItem[] = (() => {
    return recognitionBudgetEnabled && budgetDefined
      ? [
          {
            id: "1",
            color: overBudget ? OVERBUDGET_COLOR : theme.palette.primary.main,
            label: (
              <div style={{ marginTop: "16px" }}>
                <Typography>To my team</Typography>
                <Typography variant="caption">
                  <strong>
                    {numberWithCommas(
                      recognitionPointsSentSummaryQuery.data
                        ?.getMyRecognitionPointsSentSummaryV2
                        .pointsSentToManagedEmployees ?? 0
                    )}
                  </strong>
                  {" points"}
                </Typography>
              </div>
            ),
          },
          {
            id: "2",
            color: overBudget ? OVERBUDGET_COLOR : theme.palette.secondary.main,
            label: (
              <div style={{ marginTop: "16px" }}>
                <Typography>To other teams</Typography>
                <Typography variant="caption">
                  <strong>
                    {numberWithCommas(
                      recognitionPointsSentSummaryQuery.data
                        ?.getMyRecognitionPointsSentSummaryV2
                        .pointsSentToOtherEmployees ?? 0
                    )}
                  </strong>
                  {" points"}
                </Typography>
              </div>
            ),
            pattern: "lines",
          },
          {
            id: "3",
            color: "#F2F2F2",
            label: (
              <div style={{ marginTop: "16px" }}>
                <Typography>Available</Typography>
                <Typography variant="caption">
                  <strong>
                    {numberWithCommas(
                      recognitionPointsSentSummaryQuery.data
                        ?.getMyRecognitionPointsSentSummaryV2
                        .pointsAvailableInBudget ?? 0
                    )}
                  </strong>
                  {" points"}
                </Typography>
              </div>
            ),
          },
        ]
      : [
          {
            id: "1",
            color: "#F2F2F2",
            label: (
              <div style={{ marginTop: "16px" }}>
                <Typography color="textSecondary">To my team</Typography>
                <Typography variant="caption">0 points</Typography>
              </div>
            ),
          },
          {
            id: "2",
            color: "#F2F2F2",
            label: (
              <div style={{ marginTop: "16px" }}>
                <Typography color="textSecondary">To other teams</Typography>
                <Typography variant="caption">0 points</Typography>
              </div>
            ),
          },
          {
            id: "3",
            color: "#F2F2F2",
            label: (
              <div style={{ marginTop: "16px" }}>
                <Typography color="textSecondary">Available</Typography>
                <Typography variant="caption">0 points</Typography>
              </div>
            ),
          },
        ];
  })();

  const budgetChartProps: BarChartProps<string> = (() => {
    return recognitionBudgetEnabled && budgetDefined
      ? {
          keys: ["toMyTeam", "toOtherTeams", "available"],
          data: [
            {
              toMyTeam:
                recognitionPointsSentSummaryQuery.data
                  ?.getMyRecognitionPointsSentSummaryV2
                  .pointsSentToManagedEmployees ?? 0,
              toOtherTeams:
                recognitionPointsSentSummaryQuery.data
                  ?.getMyRecognitionPointsSentSummaryV2
                  .pointsSentToOtherEmployees ?? 0,
              available:
                recognitionPointsSentSummaryQuery.data
                  ?.getMyRecognitionPointsSentSummaryV2
                  .pointsAvailableInBudget ?? 0,
            },
          ],
          minValue: 0,
          maxValue: overBudget
            ? recognitionPointsSentSummaryQuery.data
                ?.getMyRecognitionPointsSentSummaryV2.pointsSentTotal
            : recognitionPointsSentSummaryQuery.data
                ?.getMyRecognitionPointsSentSummaryV2.pointsBudget,
          labels: {
            toMyTeam: "To my team",
            toOtherTeams: hasManagedEmployees
              ? "To other teams"
              : "Points awarded",
            available: "Available",
          },
          style: {
            toMyTeam: {
              color: overBudget ? OVERBUDGET_COLOR : theme.palette.primary.main,
            },
            toOtherTeams: {
              color: overBudget
                ? OVERBUDGET_COLOR
                : theme.palette.secondary.main,
              pattern: "lines",
            },
            available: {
              color: "#F2F2F2",
            },
          },
        }
      : ({
          keys: ["notAvailable"],
          data: [
            {
              notAvailable: 1,
            },
          ],
          labels: {
            notAvailable: "Not available",
          },
          style: {
            notAvailable: {
              color: "#F2F2F2",
            },
          },
          enableTooltips: false,
        } as BarChartProps<string>);
  })();

  if (
    [recognitionBudgetEnabledQuery, recognitionPointsSentSummaryQuery].some(
      (query) => query.error
    )
  ) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (
    [recognitionBudgetEnabledQuery, hasManagedEmployeesQuery].some(
      (query) => !query.data
    ) ||
    [
      recognitionBudgetEnabledQuery,
      recognitionPointsSentSummaryQuery,
      hasManagedEmployeesQuery,
    ].some((query) => query.loading)
  ) {
    return <PageLoadingState />;
  }

  if (!recognitionBudgetEnabled) {
    return null;
  }

  return (
    <Card
      variant="flat"
      css={css`
        height: 100%;
      `}
    >
      <CardContent>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <Typography variant="h5">
            Budget for Period
            {period && (
              <>
                {" "}
                <sub
                  css={(theme: AppTheme) =>
                    css`
                      color: ${theme.palette.primary.main};
                      vertical-align: middle;
                      white-space: nowrap;
                    `
                  }
                >
                  ({formatBudgetPeriodRange(period.startDate, period.endDate)})
                </sub>
              </>
            )}
          </Typography>
          <div
            css={css`
              display: flex;
              align-items: center;
              color: ${budgetColor};
            `}
          >
            <RecognitionBudgetIcon
              fill={budgetColor}
              css={css`
                width: 32px;
              `}
            />
            <Typography
              css={css`
                padding-left: 4px;
              `}
              variant="h5"
            >
              {`${numberWithCommas(
                recognitionPointsSentSummaryQuery.data
                  ?.getMyRecognitionPointsSentSummaryV2.pointsBudget ?? 0
              )} points`}
            </Typography>
          </div>
        </div>

        <BudgetSubheading />

        <div
          css={css`
            padding-top: ${theme.spacing(1)};
            padding-bottom: ${theme.spacing(1)};
          `}
        >
          <div
            css={css`
              height: 40px;
            `}
          >
            <BarChart {...budgetChartProps} />
          </div>

          {/**
           * This the custom scale for the chart, to implement to custom design requirements.
           */}
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              padding-top: 0;
            `}
          >
            <Typography>
              {numberWithCommas(
                recognitionPointsSentSummaryQuery.data
                  ?.getMyRecognitionPointsSentSummaryV2.pointsSentTotal ?? 0
              )}
              {" points awarded"}
            </Typography>
            <Typography
              css={css`
                color: ${overBudget ? OVERBUDGET_COLOR : "inherit"};
              `}
            >
              {numberWithCommas(
                Math.max(
                  recognitionPointsSentSummaryQuery.data
                    ?.getMyRecognitionPointsSentSummaryV2.pointsBudget ?? 0,
                  recognitionPointsSentSummaryQuery.data
                    ?.getMyRecognitionPointsSentSummaryV2.pointsSentTotal ?? 0
                )
              )}
            </Typography>
          </div>
        </div>

        {hasManagedEmployees && (
          <div
            css={css`
              padding-top: ${theme.spacing(1)};
              padding-bottom: ${theme.spacing(1)};
            `}
          >
            <Legend variant="horizontal" items={budgetLegendItems} />
          </div>
        )}
      </CardContent>
    </Card>
  );
}
