/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface SummaryGroupProps {
  title: string;
  total: string;
  icon?: JSX.Element;
}

export function SummaryGroup({
  title,
  total,
  icon,
}: SummaryGroupProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        width: 130px;
        height: 180px;
        border-width: 1px;
        border-color: ${theme.palette.divider};
        margin-right: ${theme.spacing(1.5)};
        border-style: solid;
        padding: ${theme.spacing(2.5)};
        border-radius: 5px;
        justify-content: center;
        text-align: center;
      `}
    >
      <Typography
        variant="h4"
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(2)};
            color: ${theme.palette.text.secondary};
          `
        }
      >
        {total}
      </Typography>
      {icon && (
        <div
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(2)};
            `
          }
        >
          {icon}
        </div>
      )}
      <Typography
        css={css`
          line-height: 1;
          font-family: "Montserrat";
        `}
        variant="body"
      >
        {title}
      </Typography>
    </div>
  );
}
