import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateReferralStructureDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateReferralStructureDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar' | 'launched'>
    & { referralRewardStructure: (
      { __typename?: 'ReferralRewardStructure' }
      & Pick<Types.ReferralRewardStructure, 'id'>
      & { jobShareStructure: (
        { __typename?: 'ReferralRewardStructureJobShareItem' }
        & Pick<Types.ReferralRewardStructureJobShareItem, 'maxAwardableJobSharesPerMonth'>
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' } }
      ) }
    ) }
  ) }
);


export const CreateReferralStructureDataDocument = gql`
    query CreateReferralStructureData {
  getMyRewardsOrganization {
    id
    pointsPerDollar
    launched
    referralRewardStructure {
      id
      jobShareStructure {
        maxAwardableJobSharesPerMonth
        reward {
          ... on ReferralRewardStructurePointReward {
            pointValue
          }
          ... on ReferralRewardStructureDrawTicketReward {
            numTickets
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCreateReferralStructureDataQuery__
 *
 * To run a query within a React component, call `useCreateReferralStructureDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralStructureDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateReferralStructureDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateReferralStructureDataQuery(baseOptions?: Apollo.QueryHookOptions<CreateReferralStructureDataQuery, CreateReferralStructureDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateReferralStructureDataQuery, CreateReferralStructureDataQueryVariables>(CreateReferralStructureDataDocument, options);
      }
export function useCreateReferralStructureDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateReferralStructureDataQuery, CreateReferralStructureDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateReferralStructureDataQuery, CreateReferralStructureDataQueryVariables>(CreateReferralStructureDataDocument, options);
        }
export type CreateReferralStructureDataQueryHookResult = ReturnType<typeof useCreateReferralStructureDataQuery>;
export type CreateReferralStructureDataLazyQueryHookResult = ReturnType<typeof useCreateReferralStructureDataLazyQuery>;
export type CreateReferralStructureDataQueryResult = Apollo.QueryResult<CreateReferralStructureDataQuery, CreateReferralStructureDataQueryVariables>;