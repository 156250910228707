/** @jsxImportSource @emotion/react */
import { ApolloError } from "@apollo/client";
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageCard } from "../../../../../../shared/components/page-card";
import { SettingsOrganizationTimezoneField } from "../../../../../../shared/modules/settings/fields/settings-organization-timezone-field";
import {
  TAG_CONFIG_RIGHT_ADORNMENT_WIDTH,
  TagsConfigField,
} from "../../../../employees/groups/tags-config-field";
import { BranchNameField } from "../fields/branch-name-field";
import { BranchFormValues, tagConfigFormValueToGraphQLInput } from "../utils";
import { useCreateBranchMutation } from "./create-branch-mutation.generated";
import { useCreateBranchDataQuery } from "./create-branch-query.generated";
import { CreateBranchSuccessModal } from "./success-modal";

const MAX_WIDTH = 900;

const DEFAULT_VALUES = {
  name: "",
  tagsCombinations: [],
  timezone: "",
};

export function CreateBranchPageContents() {
  const {
    control,
    handleSubmit,
    resetField,
    formState: { isSubmitting },
    setError,
    watch,
  } = useForm<BranchFormValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const { name: branchName, tagsCombinations } = watch();
  const snackbar = useSnackbar();
  const track = useTrack();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [createBranch] = useCreateBranchMutation();

  const { data, error } = useCreateBranchDataQuery({
    onError: reportError,

    onCompleted: (data) => {
      resetField("timezone", {
        defaultValue: data.getMyRewardsOrganization.timezone,
      });
    },
  });

  const trackingProperties = useMemo(
    () => ({
      organizationId: data?.getMyRewardsOrganization.id,
      branchName,
      tagsCombinations: JSON.stringify(
        tagConfigFormValueToGraphQLInput(tagsCombinations)
      ),
    }),
    [branchName, tagsCombinations, data]
  );

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!data) {
    return <PageLoadingState />;
  }

  const onSubmit = async (values: BranchFormValues) => {
    try {
      await createBranch({
        variables: {
          name: values.name,
          tagConfig: tagConfigFormValueToGraphQLInput(values.tagsCombinations),
          timezone: values.timezone,
        },
      });
      setShowSuccessModal(true);
      track("Created branch successfully", trackingProperties);
    } catch (error) {
      reportError(error);
      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.message === "Branch name already exists"
      ) {
        setError("name", {
          type: "unique",
          message: "Branch name already exists",
        });
        return;
      }
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    }
  };

  return (
    <div css={{ width: `${MAX_WIDTH}px` }}>
      <CreateBranchSuccessModal open={showSuccessModal} />
      <Form
        onSubmit={handleSubmit(onSubmit, (errors) =>
          track("Create branch submitted but user input had errors", {
            errors,
            ...trackingProperties,
          })
        )}
        submitting={isSubmitting}
      >
        <Button
          variant="text"
          startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          label="Back"
          width="auto"
          typographyVariant="body"
          linkTo="/settings/branches"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        />
        <Typography variant="h5">Create a branch</Typography>
        <Typography
          color="textSecondary"
          variant="footnote"
          css={(theme: AppTheme) =>
            css`
              margin-top: ${theme.spacing(1)};
            `
          }
        >
          Select user tags to create a branch. Tagged users will be added to
          this branch in {data.getMyRewardsOrganization.shortName}.<br />
          Branches can be edited anytime but not deleted.
        </Typography>
        <PageCard
          css={(theme: AppTheme) => css`
            margin: ${theme.spacing(3.75)} 0;
            padding: ${theme.spacing(3.75)};
          `}
        >
          <Typography
            variant="h6"
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(2)};
              `
            }
          >
            Branch details
          </Typography>
          <BranchNameField control={control} name="name" autoFocus />
          <div
            css={css`
              margin-right: ${TAG_CONFIG_RIGHT_ADORNMENT_WIDTH}px;
            `}
          >
            <SettingsOrganizationTimezoneField
              control={control}
              name="timezone"
            />
          </div>
          <TagsConfigField control={control} name="tagsCombinations" />
        </PageCard>
        <div
          css={(theme: AppTheme) => css`
            float: right;
            display: flex;
            gap: ${theme.spacing(1)};
            padding-bottom: ${theme.spacing(3)};
          `}
        >
          <Button
            label="Cancel"
            css={css`
              width: 150px;
            `}
            variant="outlined"
            linkTo="/settings/branches"
          />
          <Button
            label="Save"
            css={css`
              width: 150px;
            `}
            color="primary"
            type="submit"
            loading={isSubmitting}
          />
        </div>
      </Form>
    </div>
  );
}
