/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { AdminGoalType } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { NotFoundPage } from "../../../../../../shared/components/not-found-page";
import { BaseGoalConfigurationForm } from "../base-goal-configuration-form";
import { BaseGoalConfigurationPage } from "../base-goal-configuration-page";
import { formatMonthPeriod } from "../lib";
import { useGetAdminGoalConfigQuery } from "./get-admin-goal-config.generated";

export function EditAdminGoalConfigPage(): JSX.Element {
  const params = useParams();
  const { id, updateTarget } = params;
  const track = useTrack();

  const {
    data: goalConfigData,
    loading: goalConfigLoading,
    error: goalConfigError,
  } = useGetAdminGoalConfigQuery({
    variables: {
      id: id!,
    },
    fetchPolicy: "network-only",
    skip: !id,
    onError: reportError,
  });

  const goalConfig = goalConfigData?.getAdminGoalConfig;

  useEffect(() => {
    if (goalConfig) {
      track("Viewed edit admin goal page", {
        goalType: goalConfig.goalType,
        updateTarget,
      });
    }
  }, [track, goalConfig, updateTarget]);

  if (
    !updateTarget ||
    !["current", "current-and-recurring", "recurring"].includes(updateTarget)
  ) {
    return <NotFoundPage />;
  }

  if (goalConfigError) {
    return (
      <div
        css={css`
          max-width: 500px;
        `}
      >
        <Alert
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
        <Button
          color="primary"
          label="Go Back"
          linkTo="/settings/office-goals"
        />
      </div>
    );
  }

  if (goalConfigLoading || !goalConfig) {
    return <PageLoadingState />;
  }

  if (
    goalConfig.target?.__typename === "AdminGoalTargetTimeToFirstShift" ||
    goalConfig.recurringTarget?.__typename === "AdminGoalTargetTimeToFirstShift"
  ) {
    // TODO: handle time to first shift
    return (
      <div
        css={css`
          max-width: 500px;
        `}
      >
        <Alert
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
        <Button
          color="primary"
          label="Go Back"
          linkTo="/settings/office-goals"
        />
      </div>
    );
  }

  return (
    <BaseGoalConfigurationPage>
      <BaseGoalConfigurationForm
        goalType={goalConfig.goalType}
        goalTitle={
          goalConfig.goalType === AdminGoalType.BudgetUtilization
            ? "Recognition budget utilization goal"
            : "First 30-day login goal"
        }
        goalPeriod={
          updateTarget === "recurring"
            ? formatMonthPeriod(new Date(goalConfig.endDate))
            : formatMonthPeriod(new Date(goalConfig.startDate))
        }
        defaultValues={{
          adminIds: (() => {
            if (updateTarget === "recurring" && goalConfig.recurringAdmins) {
              return goalConfig.recurringAdmins.map((admin) => admin.id);
            }
            return goalConfig.admins.map((admin) => admin.id);
          })(),
          rewardAmount: (() => {
            if (updateTarget === "recurring" && goalConfig.recurringRewards) {
              return goalConfig.recurringRewards.dollarValue;
            }
            return goalConfig.rewards.dollarValue;
          })(),
          targetPercentage: (() => {
            if (updateTarget === "recurring" && goalConfig.recurringTarget) {
              return goalConfig.recurringTarget.percentage;
            }
            return goalConfig.target.percentage;
          })(),
          startDate: new Date(goalConfig.startDate),
          endDate: new Date(goalConfig.endDate),
          recurring: goalConfig.recurring ? "true" : "false",
        }}
        editMode={true}
        editTarget={(() => {
          switch (updateTarget) {
            case "recurring":
              return "recurring";
            case "current":
              return "current";
            case "current-and-recurring":
              return "current-and-recurring";
            default:
              return undefined;
          }
        })()}
        goalConfigId={goalConfig.id}
      />
    </BaseGoalConfigurationPage>
  );
}
