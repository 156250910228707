/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import { CandidateDetailsTable } from "../candidate-details-table";
import { ChangeCandidateJobForm } from "./change-candidate-job-form";

interface CandidateAssignedJobProps {
  hasEditPermission: boolean;
  onReassignedJob(details: { movedOutOfOrganization: boolean }): void;
  candidate: Pick<GraphQLTypes.Candidate, "id"> & {
    assignedToJobPosting?: GraphQLTypes.Maybe<
      { __typename?: "JobPosting" } & Pick<
        GraphQLTypes.JobPosting,
        "id" | "title" | "geography"
      >
    >;
  };
}

export function CandidateAssignedJob({
  candidate,
  onReassignedJob,
  hasEditPermission,
}: CandidateAssignedJobProps): JSX.Element {
  const track = useTrack();
  const [editingAssignedJob, setEditingAssignedJob] = useState(
    !candidate.assignedToJobPosting
  );

  return (
    <>
      {editingAssignedJob ? (
        <ChangeCandidateJobForm
          candidateId={candidate.id}
          currentJobPostingId={candidate.assignedToJobPosting?.id}
          onCancel={() => {
            setEditingAssignedJob(false);
          }}
          onChanged={(details) => {
            setEditingAssignedJob(false);
            onReassignedJob(details);
          }}
        />
      ) : (
        <>
          <CandidateDetailsTable
            items={[
              {
                label: "Job title",
                value: candidate.assignedToJobPosting?.title,
              },
              {
                label: "Region",
                value: candidate.assignedToJobPosting?.geography,
              },
            ]}
          />
          <Button
            css={css`
              display: block;
              margin-left: auto;
            `}
            color="primary"
            label="Change job"
            disabled={!hasEditPermission}
            onClick={() => {
              setEditingAssignedJob(true);
              track("Candidate change job clicked", {
                candidateId: candidate.id,
              });
            }}
          />
        </>
      )}
    </>
  );
}
