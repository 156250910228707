/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faInfoCircle,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleMinus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDays, intervalToDuration } from "date-fns";
import { ReactNode } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { ProgressSpinner } from "@rewards-web/shared/components/progress-spinner";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { shouldBeNever } from "@rewards-web/shared/lib/should-be-never";

export interface InsightMetricProps {
  label: string;
  icon: JSX.Element;
  labelStyle?: "textPrimary" | "grey";
  tooltipText?: NonNullable<ReactNode>;
  topRightPillText?: string;
  valueVariant?: "h2" | "h5";
  value:
    | { type: "loading" }
    | {
        type: "number";
        number: number;
      }
    | {
        type: "dollar";
        points: number;
        pointsPerDollar: number;
      }
    | {
        type: "percent";
        numerator: number;
        denominator: number;
      }
    | {
        type: "date_only";
        date: Date;
      }
    | {
        type: "duration_in_days";
        days: number;
      }
    | {
        type: "empty";
        content: ReactNode;

        /**
         * @default "disabled"
         */
        style?: "disabled" | "active";
      };
  // null if no previous value. Out of 100, not 1.
  percentPointChange?: number | null;
  onTooltipOpen?: (props: { label: string; tooltipText: ReactNode }) => void;
  onPercentPointChangeTooltipOpen?: (props: { label: string }) => void;
  action?: {
    icon: JSX.Element;
    ariaLabel: string;
    tooltipText: string;
    onClick: () => void;
    loading: boolean;
  };
}
export function InsightMetric(props: InsightMetricProps) {
  const theme = useTheme();

  const valueDisplay = (() => {
    const variant = props.valueVariant ?? "h2";

    switch (props.value.type) {
      case "loading":
        return <Skeleton width={100} height={35} />;
      case "number":
        return (
          <Typography variant={variant} fontWeight={700}>
            {numberWithCommas(props.value.number)}
          </Typography>
        );
      case "dollar":
        return (
          <Typography variant={variant} fontWeight={700}>
            {formatDollars(props.value.points / props.value.pointsPerDollar)}
          </Typography>
        );
      case "percent":
        return (
          <Typography variant={variant} fontWeight={800}>
            {props.value.denominator === 0
              ? 0
              : Math.round(
                  (props.value.numerator / props.value.denominator) * 100
                )}
            <Typography variant={variant} component="span" fontWeight={700}>
              %
            </Typography>
          </Typography>
        );
      case "date_only":
        return (
          <Typography variant={variant} fontWeight={700}>
            {props.value.date.toLocaleDateString(undefined, {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </Typography>
        );
      case "duration_in_days":
        return (
          <Typography variant={variant} fontWeight={700}>
            {(() => {
              const days = props.value.days;
              const duration = intervalToDuration({
                start: new Date(),
                end: addDays(new Date(), days),
              });

              if (days <= 0) {
                return "0 days";
              }

              if (duration.years && duration.years > 0) {
                return `${duration.years} year${duration.years > 1 ? "s" : ""}${
                  duration.months
                    ? `, ${duration.months} month${
                        duration.months > 1 ? "s" : ""
                      }`
                    : ""
                }`;
              }

              if (duration.months && duration.months > 0) {
                return `${duration.months} month${
                  duration.months > 1 ? "s" : ""
                }`;
              }

              return `${days} day${days > 1 ? "s" : ""}`;
            })()}
          </Typography>
        );
      case "empty":
        return null;
      default:
        shouldBeNever(props.value);
    }
  })();

  const diffBadge = (() => {
    if (typeof props.percentPointChange !== "number") {
      return null;
    }
    const { icon, color } = (() => {
      if (props.percentPointChange > 0) {
        return {
          icon: faCircleArrowUp,
          color: theme.palette.success.main,
        };
      } else if (props.percentPointChange < 0) {
        return {
          icon: faCircleArrowDown,
          color: theme.palette.grey[800],
        };
      }
      return {
        icon: faCircleMinus,
        color: theme.palette.grey[600],
      };
    })();

    return (
      <Tooltip
        title="This percentage shows the change from the previous period. If the current period isn't yet complete, the comparison uses the same number of days from the previous period for accuracy."
        placement="bottom"
        onOpen={() =>
          props.onPercentPointChangeTooltipOpen?.({ label: props.label })
        }
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${theme.spacing(0.4)};
            margin-bottom: ${theme.spacing(-0.5)};
            height: 20px;
          `}
        >
          <FontAwesomeIcon icon={icon} color={color} width={13} />
          {
            Math.abs(props.percentPointChange) !== Infinity && (
              <Typography variant="footnote" color={color} fontWeight={700}>
                {Math.abs(Math.round(props.percentPointChange))}%
              </Typography>
            ) /* We only show the arrow if the percent point change is infinity */
          }
        </div>
      </Tooltip>
    );
  })();

  const action = (() => {
    if (!props.action) {
      return null;
    }

    return (
      <Tooltip
        title={props.action.tooltipText}
        disabled={props.action.loading}
        css={css`
          align-self: center;
        `}
      >
        <IconButton
          aria-label={props.action.ariaLabel}
          onClick={props.action.onClick}
          disabled={props.action.loading}
          css={css`
            margin-left: ${theme.spacing(-0.5)};
            padding: ${theme.spacing(1)};
            & svg {
              color: ${theme.palette.grey[800]};
            }
          `}
        >
          <span
            css={css`
              display: contents;
              ${props.action.loading &&
              css`
                visibility: hidden;
              `}
            `}
          >
            {props.action.icon}
          </span>

          {props.action.loading && (
            <ProgressSpinner
              size={16}
              css={css`
                position: absolute;
                align-self: center;
                justify-self: center;
              `}
            />
          )}
        </IconButton>
      </Tooltip>
    );
  })();

  return (
    <Card
      css={css`
        padding: ${theme.spacing(2)};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        ${props.value.type === "empty" &&
        (props.value.style ?? "disabled") === "disabled"
          ? css`
              background-color: ${theme.palette.grey[200]};
            `
          : ""}
      `}
      variant="outlined"
    >
      <div
        css={css`
          display: flex;
          margin-bottom: ${theme.spacing(2)};
          height: 18px;
          align-items: center;
        `}
      >
        <div
          css={css`
            margin-right: ${theme.spacing(1)};
            color: ${props.labelStyle === "grey"
              ? theme.palette.grey[800]
              : theme.palette.text.primary};
          `}
        >
          {props.icon}
        </div>
        <Typography
          color={props.labelStyle === "grey" ? "grey.800" : "textPrimary"}
          variant={props.labelStyle === "grey" ? "body" : "subtitle"}
          fontWeight={props.labelStyle === "grey" ? undefined : 600}
          css={css`
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          `}
          title={props.label}
        >
          {props.label}
        </Typography>
        {props.tooltipText && (
          <div
            css={css`
              margin-left: auto;
              padding-left: ${theme.spacing(1)};
            `}
          >
            <Tooltip
              title={props.tooltipText}
              placement="top"
              onOpen={() =>
                props.onTooltipOpen?.({
                  label: props.label,
                  tooltipText: props.tooltipText!,
                })
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </div>
        )}
        {props.topRightPillText && (
          <div
            css={css`
              margin-left: auto;
              background-color: ${theme.palette.grey[200]};
              padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
              border-radius: 10px;
            `}
          >
            <Typography
              variant="caption"
              color="textPrimary"
              textTransform="uppercase"
            >
              {props.topRightPillText}
            </Typography>
          </div>
        )}
      </div>
      {props.value.type === "empty" ? (
        <div
          css={css`
            flex: 1;
          `}
        >
          {props.value.content}
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            gap: ${theme.spacing(1)};
            align-items: baseline;
          `}
        >
          {valueDisplay}
          {diffBadge}
          {action}
        </div>
      )}
    </Card>
  );
}
