import { compact } from "lodash";

import {
  CandidateRecruitmentStepName,
  ListCandidatesV2FilterInput,
  ListCandidateV2NextStepInInput,
} from "@rewards-web/shared/graphql-types";
import { isNullOrUndefined } from "@rewards-web/shared/lib/is-null-or-undefined";

import { OrganizationSteps } from "../lib";

type StatusFilterOption =
  | {
      type: "RECRUITMENT";
      step: CandidateRecruitmentStepName;
    }
  | {
      type: "RETENTION";
      durationMonths?: number;
      durationHours?: number;
    };

export type SerializedCandidateFilter = Pick<
  ListCandidatesV2FilterInput,
  "hasUploadedResume" | "archived" | "nextStepIn"
>;

/**
 * Top-level filter values, which enable the child filter values.
 *
 * No filter is applied if none of its children are selected.
 */
type CandidateFilterEnabledValue =
  | "RESUME_FILTER_ENABLED"
  | "STATUS_FILTER_ENABLED"
  | "STEP_FILTER_ENABLED";

/**
 * The child values apply filter values, if the parent "enabled" value is selected as well.
 */
type CandidateFilterChildValue =
  | "WITH_RESUME"
  | "WITHOUT_RESUME"
  | "NOT_ARCHIVED"
  | "ARCHIVED"
  | `STEP_FILTER_RECRUITMENT_${CandidateRecruitmentStepName}`
  | `STEP_FILTER_RETENTION_${number}_MONTHS`
  | `STEP_FILTER_RETENTION_${number}_HOURS`;

export type CandidateFilterValue =
  | CandidateFilterEnabledValue
  | CandidateFilterChildValue;

export function getCandidateFilterCheckboxOptions(
  organizationSteps: OrganizationSteps
): Array<{
  label: string;
  value: CandidateFilterEnabledValue;
  children: Array<{ label: string; value: CandidateFilterChildValue }>;
}> {
  return [
    {
      label: "Resume",
      value: "RESUME_FILTER_ENABLED",
      children: [
        {
          label: "With Resume",
          value: "WITH_RESUME",
        },
        {
          label: "Without Resume",
          value: "WITHOUT_RESUME",
        },
      ],
    },
    {
      label: "Status",
      value: "STATUS_FILTER_ENABLED",
      children: [
        {
          label: "Active",
          value: "NOT_ARCHIVED",
        },
        {
          label: "Archived",
          value: "ARCHIVED",
        },
      ],
    },
    {
      label: "By Steps",
      value: "STEP_FILTER_ENABLED",
      children: (() => {
        const steps = getCandidateFilterStepOptionsForOrganizationSteps(
          organizationSteps
        );

        return steps.map((option): {
          label: string;
          value: CandidateFilterChildValue;
        } => ({
          // displayed step should be one step ahead of the actual step
          label: getStatusFilterLabel(option),
          value: getStepFilterValueFromStepOption(option),
        }));
      })(),
    },
  ];
}

/**
 * Deserializes the GraphQL Input candidate filter into the format needed
 * for the localized filter state.
 *
 * If previous filters are passed in, it will take them into account
 * so the local state is partially preserved.
 */
export function deserializeCandidateFilter(
  organizationSteps: OrganizationSteps,
  value: SerializedCandidateFilter,
  previousFilters = new Set<CandidateFilterValue>()
): Set<CandidateFilterValue> {
  if (
    previousFilters &&
    Object.entries(
      serializeCandidateFilter(organizationSteps, previousFilters)
    ).every(
      ([key, previousValue]) =>
        value[key as keyof SerializedCandidateFilter] === previousValue
    )
  ) {
    // if it's functionally equivalent, keep the filter currently in state.
    // that way, we can keep the checkboxes the user previously had if
    // they re-enable the section.
    return previousFilters;
  }

  const applyFilterGroup = (
    filterEnabledValue: CandidateFilterEnabledValue,
    serializedValueEnabled: boolean,
    children: Array<{
      value: CandidateFilterChildValue;
      active: boolean;
    }>
  ): CandidateFilterValue[] => {
    const sectionEnabled =
      previousFilters.has(filterEnabledValue) || serializedValueEnabled;

    return compact([
      sectionEnabled && filterEnabledValue,
      ...children.map(
        (child) =>
          (child.active ||
            (sectionEnabled &&
              children.every((child) => previousFilters.has(child.value)))) &&
          child.value
      ),
    ]);
  };

  return new Set(
    compact<CandidateFilterValue>([
      ...applyFilterGroup(
        "RESUME_FILTER_ENABLED",
        !isNullOrUndefined(value.hasUploadedResume),
        [
          { value: "WITH_RESUME", active: value.hasUploadedResume === true },
          {
            value: "WITHOUT_RESUME",
            active: value.hasUploadedResume === false,
          },
        ]
      ),

      ...applyFilterGroup(
        "STATUS_FILTER_ENABLED",
        !isNullOrUndefined(value.archived),
        [
          {
            value: "ARCHIVED",
            active: value.archived === true,
          },
          {
            value: "NOT_ARCHIVED",
            active: value.archived === false,
          },
        ]
      ),

      ...applyFilterGroup(
        "STEP_FILTER_ENABLED",
        !isNullOrUndefined(value.nextStepIn),
        getInitialCandidateFilterNextStepOptionsForOrganizationSteps(
          organizationSteps
        ).map<{
          value: CandidateFilterChildValue;
          active: boolean;
        }>((stepOption) => {
          return {
            value: getStepFilterValueFromStepOption(stepOption),
            active: (value.nextStepIn ?? []).some((status) => {
              if (status.recruitmentStep) {
                return (
                  stepOption.type === "RECRUITMENT" &&
                  stepOption.step === status.recruitmentStep
                );
              }

              return (
                stepOption.type === "RETENTION" &&
                ((typeof stepOption.durationMonths === "number" &&
                  stepOption.durationMonths ===
                    status.retentionDurationMonths) ||
                  (typeof stepOption.durationHours === "number" &&
                    stepOption.durationHours ===
                      status.retentionDurationHours!))
              );
            }),
          };
        })
      ),
    ])
  );
}

/**
 * Serializes the local candidate filter into the format needed
 * by the GraphQL input candidate filter
 */
export function serializeCandidateFilter(
  organizationSteps: OrganizationSteps,
  filterValue: Set<CandidateFilterValue>
): SerializedCandidateFilter {
  return {
    hasUploadedResume: (() => {
      if (filterValue.has("RESUME_FILTER_ENABLED")) {
        if (
          filterValue.has("WITH_RESUME") &&
          !filterValue.has("WITHOUT_RESUME")
        ) {
          return true;
        }

        if (
          filterValue.has("WITHOUT_RESUME") &&
          !filterValue.has("WITH_RESUME")
        ) {
          return false;
        }
      }

      return null;
    })(),

    archived: (() => {
      if (filterValue.has("STATUS_FILTER_ENABLED")) {
        if (filterValue.has("ARCHIVED") && !filterValue.has("NOT_ARCHIVED")) {
          return true;
        }

        if (filterValue.has("NOT_ARCHIVED") && !filterValue.has("ARCHIVED")) {
          return false;
        }
      }

      return null;
    })(),

    nextStepIn: (() => {
      if (filterValue.has("STEP_FILTER_ENABLED")) {
        const statuses = getInitialCandidateFilterNextStepOptionsForOrganizationSteps(
          organizationSteps
        )
          .filter((step) =>
            filterValue.has(getStepFilterValueFromStepOption(step))
          )
          .map(serializeCandidateFilterStepOptionIntoGraphQLInput);

        if (statuses.length > 0) {
          return statuses;
        }
      }

      return null;
    })(),
  };
}

export function getInitialCandidateFilterNextStepOptionsForOrganizationSteps(
  organizationSteps: OrganizationSteps
): StatusFilterOption[] {
  return getCandidateFilterStepOptionsForOrganizationSteps(organizationSteps);
}

export function serializeCandidateFilterStepOptionIntoGraphQLInput(
  step: StatusFilterOption
): ListCandidateV2NextStepInInput {
  return {
    recruitmentStep: step.type === "RECRUITMENT" ? step.step : undefined,
    retentionDurationMonths:
      step.type === "RETENTION" ? step.durationMonths : undefined,
    retentionDurationHours:
      step.type === "RETENTION" ? step.durationHours : undefined,
  };
}

function getStatusFilterLabel(option: StatusFilterOption): string {
  switch (option.type) {
    case "RECRUITMENT": {
      switch (option.step) {
        case CandidateRecruitmentStepName.ApplicationSubmitted:
          return "Applied?";
        case CandidateRecruitmentStepName.Contacted:
          return "Contacted?";
        case CandidateRecruitmentStepName.InterviewScheduled:
          return "Scheduled?";
        case CandidateRecruitmentStepName.InterviewSuccessful:
          return "Offer extended?";
        case CandidateRecruitmentStepName.Hired:
          return "Hired?";
        case CandidateRecruitmentStepName.StartedWork:
          return "Started working?";
        case CandidateRecruitmentStepName.StartedOrientation:
          return "Started orientation?";
        case CandidateRecruitmentStepName.CompletedOrientation:
          return "Completed orientation?";
        case CandidateRecruitmentStepName.CompletedFirstShift:
          return "Completed first shift?";
        default:
          return `${option.step}?`;
      }
    }
    case "RETENTION":
      if (typeof option.durationMonths === "number") {
        return `Worked for ${option.durationMonths} months?`;
      }

      return `Worked for ${option.durationHours} hours?`;
    default:
      return "N/A";
  }
}

function getStepFilterValueFromStepOption(
  option: StatusFilterOption
): CandidateFilterChildValue {
  return option.type === "RECRUITMENT"
    ? `STEP_FILTER_RECRUITMENT_${option.step}`
    : typeof option.durationMonths === "number"
    ? `STEP_FILTER_RETENTION_${option.durationMonths}_MONTHS`
    : `STEP_FILTER_RETENTION_${option.durationHours!}_HOURS`;
}

function getCandidateFilterStepOptionsForOrganizationSteps(
  organizationSteps: OrganizationSteps
): StatusFilterOption[] {
  return [
    ...organizationSteps.recruitmentSteps.map((step) => ({
      type: "RECRUITMENT" as const,
      step,
    })),
    ...organizationSteps.retentionMonthSteps.map((durationMonths) => ({
      type: "RETENTION" as const,
      durationMonths,
    })),
    ...organizationSteps.retentionHoursSteps.map((durationHours) => ({
      type: "RETENTION" as const,
      durationHours,
    })),
  ];
}
