import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  TypePolicies,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { GraphQLError, print } from "graphql";

import { reportError, TransformBreadcrumbFn } from "../modules/error";
import possibleTypes from "../possibleTypes.generated";
import { ApolloGraphQLResponseContext } from "../types/apollo-response-context";

export const enhanceFetchBreadcrumbs: TransformBreadcrumbFn = (
  breadcrumb,
  hint
) => {
  if (
    breadcrumb.category === "fetch" &&
    breadcrumb?.data?.url?.endsWith("/graphql")
  ) {
    let graphQLOperationName: string | undefined = undefined;

    try {
      graphQLOperationName = JSON.parse(hint?.input?.[1]?.body)?.operationName;
    } catch (error) {
      // it's fine if we can't parse the request body
    }

    return {
      ...breadcrumb,
      data: {
        ...(breadcrumb.data ?? {}),
        apiRequestId: hint?.response?.headers?.get("x-request-id"),
        graphQLOperationName,
      },
    };
  }

  return breadcrumb;
};

export function createApolloClient(params: {
  graphQLURI: string;
  appNameHeaderValue: string;
  typePolicies?: TypePolicies;
  disableAuth?: boolean;
  onError?(error: ErrorResponse): void;
  isAuthenticated?(): Promise<boolean> | boolean;
  getAccessToken?(): Promise<string> | string;
  initialCacheState?: NormalizedCacheObject;
  getHeaders?(): Record<string, string>;
}) {
  /**
   * Enhances the response with requestId populated form the response header
   */
  const requestIdLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context: ApolloGraphQLResponseContext = {
        requestId: (operation.getContext().response.headers as Headers).get(
          "x-request-id"
        )!,
      };

      response.context = {
        ...(response.context ?? {}),
        ...context,
      };

      return response;
    });
  });

  const uploadableHttpLink = createUploadLink({
    uri: params.graphQLURI,
  });

  const authLink = setContext(async (_, { headers: prevHeaders }) => {
    const headers = {
      ...prevHeaders,
      ...(params.getHeaders?.() ?? {}),
      "x-app-name": params.appNameHeaderValue,
      // TODO add version & device info context
    };

    if (await params.isAuthenticated?.()) {
      const accessToken = await params.getAccessToken?.();

      if (accessToken) {
        headers["id-token"] = `Bearer ${accessToken}`;
      } else {
        reportError(
          new Error("Could not get access token while supposedly authenticated")
        );
      }
    }

    return {
      headers,
    };
  });

  /**
   * This link is responsible for enhancing the error with more context
   * so we can report the error with more context.
   */
  const errorTransformerLink = new ApolloLink((operation, forward) => {
    const extraInfo = {
      operationName: operation.operationName,
      query: print(operation.query),
      requestId: (operation.getContext() as ApolloGraphQLResponseContext)
        ?.requestId,
    };

    function buildApolloError({
      graphQLErrors,
      networkError,
    }: {
      graphQLErrors?: readonly GraphQLError[];
      networkError?: any;
    }) {
      const error = new ApolloError({
        graphQLErrors,
        networkError,
        extraInfo,
      });

      // Apollo error doesn't properly set the name of the `Error` instance,
      // so we are setting it manually here
      error.name = "ApolloError";

      return error;
    }

    return new Observable((observer) => {
      const observable = forward(operation);
      const subscription = observable.subscribe({
        next(data) {
          if ((data.errors ?? []).length > 0) {
            observer.error(buildApolloError({ graphQLErrors: data.errors }));
          } else {
            observer.next(data);
          }
        },
        error(networkError) {
          // `networkError` contains any error thrown in an upstream link.
          // often, this is an instance of `ServerError` from apollo lib.

          // there is an apollo client quirk, where it will wrap the network error
          // using `new AplloError({ networkError })` after this link.
          // however - if the backend responds with non-200 status codes,
          // it will not always populate `{ graphqlErrors }` on the error.
          // if this is the case, we are manually throwing `ApolloError` here
          // with both `{ graphqlErrors }` & `{ networkError }` populated.

          observer.error(
            buildApolloError({
              networkError,
              graphQLErrors:
                (networkError &&
                  networkError?.name === "ServerError" &&
                  networkError.result &&
                  // NOTE: errors does not instantiate `GraphQLError`,
                  // so we may need to construct it manually here
                  networkError.result.errors) ||
                void 0,
            })
          );
        },
        complete() {
          observer.complete();
        },
      });

      return () => subscription.unsubscribe();
    });
  });

  const cache = new InMemoryCache({
    typePolicies: params.typePolicies,
    possibleTypes: possibleTypes.possibleTypes,
  });

  if (params.initialCacheState) {
    cache.restore(params.initialCacheState);
  }

  const links = errorTransformerLink
    .concat(
      // NOTE: onError reports the error before hitting the transformer,
      // to avoid breaking `onError` (which doesn't expect to receive `ApolloError`s)
      onError((error) => {
        params.onError?.(error);
      })
    )
    .concat(requestIdLink)
    .concat(uploadableHttpLink);

  return new ApolloClient({
    link: params.disableAuth ? links : authLink.concat(links),
    cache,
    defaultOptions: {
      watchQuery: {
        // by default, `useQuery` will return cached data (if any),
        // but will make a request and refresh with any new data.
        // this is the 'stale-while-revalidate' pattern:
        // https://web.dev/stale-while-revalidate/
        fetchPolicy: "cache-and-network",
      },
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
}
