/** @jsxImportSource @emotion/react */
import { Typography } from "@rewards-web/shared/components/typography";

import { getSurveyQuestionMetadata } from "../../../../shared/constants/survey-question-metadata";
import { SurveyQuestionTypeSupportedByUI } from "../constants";

export function QuestionText({
  questionType,
  variant,
}: {
  questionType: SurveyQuestionTypeSupportedByUI;
  variant?: Parameters<typeof Typography>[0]["variant"];
}) {
  const { questionText } = getSurveyQuestionMetadata(questionType);
  return (
    <Typography variant={variant ?? "subtitle"} fontWeight={700}>
      {questionText}
    </Typography>
  );
}
