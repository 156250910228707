import { PageLayout } from "../../../../../../shared/components/page-layout";
import { SettingsViewReferralStructurePageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1060;

export function SettingsViewReferralStructurePage() {
  return (
    <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
      <SettingsViewReferralStructurePageContents />
    </PageLayout>
  );
}
