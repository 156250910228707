/** @jsxImportSource @emotion/react */
import { FieldValues, Control, Controller, Path } from "react-hook-form";

import { SwitchField } from "@rewards-web/shared/components/switch";

interface SurveySettingsActiveFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function SurveySettingsActiveField<T extends FieldValues>({
  control,
  name,
}: SurveySettingsActiveFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <SwitchField
          label={{ onLabel: "Active", offLabel: "Inactive" }}
          {...field}
          value={field.value ?? false}
          labelVariant="highlight"
        />
      )}
    />
  );
}
