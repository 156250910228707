import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";

import { useMyBranches } from "../../../shared/modules/branches/use-my-branches";
import { HiringPipeline } from "./hiring-pipeline";
import { useHiringJobPostingListQuery } from "./job-posting-list-query.generated";
import { OrganizationStats } from "./organization-stats";
import { useHiringOrganizationStatsQuery } from "./organization-stats-query.generated";

export function HiringPageContents() {
  const myBranchesQuery = useMyBranches();

  const jobPostingListQuery = useHiringJobPostingListQuery({
    fetchPolicy: "cache-and-network",
    onError: reportError,
    skip: !myBranchesQuery.data,
    variables: {
      branchIds:
        (myBranchesQuery.data?.getMyRewardsAdminUser?.branches ?? []).length > 0
          ? myBranchesQuery.data!.getMyRewardsAdminUser!.branches.map(
              (branch) => branch.id
            )
          : null,
    },
  });

  const organizationStatsQuery = useHiringOrganizationStatsQuery({
    fetchPolicy: "cache-and-network",
    onError: reportError,
  });

  if (
    [jobPostingListQuery, organizationStatsQuery, myBranchesQuery].some(
      (query) => query.error
    )
  ) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }
  if (
    [jobPostingListQuery, organizationStatsQuery, myBranchesQuery].some(
      (query) => !query.data || query.loading
    )
  ) {
    return <PageLoadingState />;
  }

  const jobPostings =
    jobPostingListQuery.data?.getNumActiveCandidatesByJobPostingAndNextStep
      .items;
  const candidateNextStepFilterOptions =
    jobPostingListQuery.data?.getCandidateNextStepFilterOptions;

  const organizationStats =
    organizationStatsQuery.data?.getRewardsOrganizationStats;

  return (
    <>
      <OrganizationStats organizationStats={organizationStats!} />
      <HiringPipeline
        jobPostings={jobPostings!}
        candidateNextSteps={candidateNextStepFilterOptions!}
      />
    </>
  );
}
