import { createContext, useContext } from "react";

import { Sort } from "@rewards-web/shared/graphql-types";

export const SortableTableContext = createContext<
  SortableTableContextType<string>
>({
  state: {
    field: "",
    direction: Sort.Asc,
  },
  setState: () => {},
});

export type SortableTableContextType<TField extends string> = {
  state: TableSortState<TField>;
  setState: (sort: TableSortState<TField>) => void;
};

export type TableSortState<TField extends string> = {
  field: TField;
  direction: Sort;
};

export function SortableTableHeadersProvider<TField extends string>({
  children,
  value,
  onChange,
}: {
  children: React.ReactNode;
  value: TableSortState<TField>;
  onChange: (sort: TableSortState<TField>) => void;
}) {
  return (
    <SortableTableContext.Provider
      value={{
        state: value,
        setState: onChange as any,
      }}
    >
      {children}
    </SortableTableContext.Provider>
  );
}

export function useSortableTableContext<TField extends string>(): [
  TableSortState<TField>,
  (sort: TableSortState<TField>) => void
] {
  const context = useContext(SortableTableContext);

  if (!context.state.field) {
    throw new Error(
      "SortableTableProvider must be rendered as a parent with an initial sort context provided"
    );
  }

  return [context.state as TableSortState<TField>, context.setState];
}
