/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";
import { StylableProps } from "@rewards-web/shared/types";

import profileIcon from "./profile.png";

interface HHAXAutoSyncCaregiverTitleTotalProps extends StylableProps {
  integrationId: string;
  total: number | undefined;
  hideIcon?: boolean;
  hideActiveCaregiverInfoTooltip?: boolean;
}

export function HHAXAutoSyncCaregiverTitleTotal({
  integrationId,
  total,
  className,
  hideIcon,
  hideActiveCaregiverInfoTooltip,
}: HHAXAutoSyncCaregiverTitleTotalProps) {
  const track = useTrack();

  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {!hideIcon && (
        <img
          src={profileIcon}
          alt="profile"
          width={24}
          css={(theme: AppTheme) => css`
            margin-right: ${theme.spacing(1.5)};
          `}
        />
      )}
      <Typography variant="h4" color="textPrimary" fontWeight={700}>
        Active caregiver contacts ({total})
      </Typography>
      {!hideActiveCaregiverInfoTooltip && (
        <Tooltip
          title="We are showing you active caregivers only, please contact CSM if you have other preferences."
          onOpen={() =>
            track("HHAX Auto Sync viewed active caregivers tooltip", {
              integrationId,
            })
          }
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="lg"
            css={(theme: AppTheme) => css`
              margin-left: ${theme.spacing(1.5)};
              color: ${theme.palette.grey[800]};
            `}
          />
        </Tooltip>
      )}
    </div>
  );
}
