/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableHeaders } from "@rewards-web/shared/components/table-components/table-headers";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

type PeriodSectionProps = {
  type: "current" | "next";
  startDate: string;
  endDate: string;
  rewardAmount: number;
  targetPercentage: number;
};

export function PeriodSection({
  type,
  startDate,
  endDate,
  rewardAmount,
  targetPercentage,
}: PeriodSectionProps): JSX.Element {
  const cellStyles = css`
    padding: 0;
  `;

  const period = `${startDate} - ${endDate}`;
  const reward = `$${rewardAmount}`;
  const target = `${targetPercentage}%`;

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          align-items: center;
          margin-top: ${theme.spacing(2)};
        `}
      >
        <FontAwesomeIcon
          icon={faCircle}
          color={type === "current" ? "green" : "blue"}
          css={(theme: AppTheme) => css`
            margin-right: ${theme.spacing(1)};
          `}
        />
        <Typography variant="subtitle" color="grey.800">
          {type === "current"
            ? "Current period: (live)"
            : "Next period (scheduled)"}
        </Typography>
      </div>

      <Table
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(1)};
          width: 400px;
        `}
      >
        <TableHeaders>
          <TableRow>
            {["Period", "Reward", "Target"].map((header) => (
              <TableHeader key={header} divider={false} css={cellStyles}>
                <Typography
                  variant="footnote"
                  color="grey.800"
                  fontWeight="bold"
                >
                  {header}
                </Typography>
              </TableHeader>
            ))}
          </TableRow>
        </TableHeaders>
        <TableBody>
          <TableRow>
            {[period, reward, target].map((cell, index) => (
              <TableCell key={index} divider={false} css={cellStyles}>
                <Typography variant="footnote" color="grey.800">
                  {cell}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
