/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import assert from "assert";
import { addDays, weeksToDays } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  ErrorCode,
  GoalDistributionUpdatePolicy,
  GoalRewardUpdatePolicy,
  PulseCheckSurveyCadenceFrequency,
  PulseCheckSurveyGoalDistributionConfigAdminInput,
  SurveyType,
} from "@rewards-web/shared/graphql-types";
import { useDrawerControl } from "@rewards-web/shared/hooks/use-drawer-control";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { hasGraphqlError } from "@rewards-web/shared/lib/has-graphql-error";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageCard } from "../../../../../../shared/components/page-card";
import { PULSE_SURVEY_QUESTIONS } from "../../../constants";
import { SurveySettingsErrorModal } from "../../shared/components/error-modal";
import { SurveySettingsActiveField } from "../../shared/components/fields/active";
import { SurveySettingsFrequencyField } from "../../shared/components/fields/frequency";
import { SurveySettingsResponseTypeField } from "../../shared/components/fields/response-type";
import {
  SurveySettingsRewardType,
  SurveySettingsRewardTypeField,
} from "../../shared/components/fields/reward-type";
import { SurveySettingsRewardValueField } from "../../shared/components/fields/reward-value";
import { SurveySettingsStartDateField } from "../../shared/components/fields/start-date";
import { SurveySettingsDurationField } from "../../shared/components/fields/survey-duration";
import { SurveyFormRow } from "../../shared/components/survey-form-row";
import {
  SurveySettingsUpdateModal,
  SurveySettingsUpdatePolicy,
} from "../../shared/components/update-modal";
import { PulseSurveySendInitialSurveyField } from "../components/fields/initial-survey";
import {
  PulseSurveyFormQuestions,
  PulseSurveyQuestionsField,
} from "../components/fields/questions";
import {
  PulseSurveyFormCreateUpdateMutationVariables,
  usePulseSurveyFormCreateUpdateMutation,
} from "./pulse-survey-form-create-update.generated";
import { usePulseSurveyFormDataQuery } from "./pulse-survey-form-data.generated";
import { usePulseSurveyFormDisableMutation } from "./pulse-survey-form-disable.generated";

const MAX_WIDTH = 1400; //px

export type PulseSurveyFormType = {
  active: boolean;
  frequency: PulseCheckSurveyCadenceFrequency;
  startDate: string | null;
  sendInitialSurvey: boolean | undefined;
  duration: number;
  rewardType: SurveySettingsRewardType | "";
  rewardValue: string;
  questions: PulseSurveyFormQuestions;
  // use this for both the reward update policy and cancellation policy
  updatePolicy: SurveySettingsUpdatePolicy | undefined;
  anonymousSubmission: boolean;
};

const DEFAULT_VALUES = {
  active: false,
  frequency: PulseCheckSurveyCadenceFrequency.Quarterly,
  startDate: null,
  sendInitialSurvey: false,
  duration: 14,
  rewardType: "" as const,
  rewardValue: "",
  questions: Object.fromEntries(
    PULSE_SURVEY_QUESTIONS.map((question) => [question, true])
  ),
  updatePolicy: undefined,
  anonymousSubmission: false,
}; // satisfies PulseSurveyFormType;

export function PulseSurveyForm(): JSX.Element {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    clearErrors,
    formState: { isSubmitting, isDirty },
    trigger: triggerValidation,
  } = useForm<PulseSurveyFormType>({
    defaultValues: DEFAULT_VALUES,
  });
  const { active: isActive } = watch();

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    isDirty
  );

  const anonymousSubmissionEnabled = useFeatureFlag(
    "admin-app-anonymous-survey-submission-temp"
  );
  const { state } = useLocation();
  // ensure this is only set when the page is loaded
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const backTo = useMemo(() => state?.backTo ?? "/survey-settings", []);

  /**
   * Clear field validation errors when isActive changes,
   * those errors probably no longer apply when isActive is changed
   */
  useEffect(() => {
    clearErrors();
  }, [clearErrors, isActive]);

  const [policyModalOpen, setPolicyModalOpen] = useState(false);
  const [_errorModalState, setErrorModalState] = useState<
    { title: string; subtitle: string } | undefined
  >(undefined);
  const [errorModalState, errorModalStateControl] = useDrawerControl(
    _errorModalState
  );

  const { data: queryData, error } = usePulseSurveyFormDataQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const isLaunched = data.getMyRewardsOrganization.launched;
      const goalsEnabled = !!data.getMyOrganizationGoalConfig?.enabled;
      const config =
        data.getMyOrganizationGoalConfig
          ?.pulseCheckSurveyGoalDistributionConfig;

      if (config) {
        const formValues: PulseSurveyFormType = {
          active: goalsEnabled && config.enabled,
          /**
           * If the organization is launched, omit this value.
           * Otherwise, provide the existing value (or false as a fallback)
           */
          sendInitialSurvey: isLaunched
            ? undefined
            : config.sendInitialSurveyOnLaunch ?? false,
          frequency:
            config.surveyCadenceOverride?.frequency ?? DEFAULT_VALUES.frequency,
          startDate: config.surveyCadenceOverride?.startDate
            ? formatInTimeZone(
                config.surveyCadenceOverride.startDate,
                "UTC",
                "yyyy-MM-dd"
              )
            : null,
          anonymousSubmission:
            config?.anonymousSubmission ?? DEFAULT_VALUES.anonymousSubmission,
          duration:
            config.surveyDaysUntilExpirationOverride ?? DEFAULT_VALUES.duration,
          /**
           * Get reward type & reward value if populated, otherwise return default
           */
          ...((): Pick<PulseSurveyFormType, "rewardType" | "rewardValue"> => {
            const { numOrganizationDrawTickets: numTickets, numPoints } =
              config.surveyRewardOverride ?? {};

            if (numTickets) {
              return {
                rewardType: "tickets",
                rewardValue: numTickets.toString(),
              };
            }
            if (numPoints) {
              return {
                rewardType: "points",
                rewardValue: numPoints.toString(),
              };
            }
            const { rewardType, rewardValue } = DEFAULT_VALUES;
            return { rewardType, rewardValue };
          })(),
          questions: Object.fromEntries(
            PULSE_SURVEY_QUESTIONS.map((question) => [
              question,
              config.selectedSurveyQuestions.includes(question),
            ])
          ) as PulseSurveyFormQuestions,
          // make users explicitly select update policy, also to avoid backfilling unselectable values like NO_UPDATE
          updatePolicy: undefined,
        };

        reset(formValues);
      }
    },
    onError: reportError,
  });

  const theme = useTheme();
  const track = useTrack();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [createUpdateMutation] = usePulseSurveyFormCreateUpdateMutation();
  const [deactivateMutation] = usePulseSurveyFormDisableMutation();

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!queryData) {
    return <PageLoadingState />;
  }

  const isPulseSurveyAlreadyEnabled = Boolean(
    queryData.getMyOrganizationGoalConfig?.enabled &&
      queryData.getMyOrganizationGoalConfig
        ?.pulseCheckSurveyGoalDistributionConfig?.enabled
  );

  const hasAvailablePulseSurveyGoals = Boolean(
    queryData.getMyOrganizationHasAvailableGoals.hasAvailableGoals
  );

  const isLaunched = queryData.getMyRewardsOrganization.launched;

  /**
   * To be called when submitting an active pulse survey config
   */
  const onActiveSubmit = async (data: PulseSurveyFormType) => {
    assert(data.active === true);

    try {
      await createUpdateMutation({
        variables: mapFormDataToUpdateMutationVariables({
          formData: data,
          isPulseSurveyAlreadyEnabled,
        }),
      });
      track("Pulse survey settings updated", { values: JSON.stringify(data) });
      snackbar.show({
        severity: "success",
        message: `Success! Pulse survey settings ${
          isPulseSurveyAlreadyEnabled ? "updated" : "enabled"
        }`,
      });
      reset(data);
      navigate(backTo);
    } catch (e) {
      const errorModalProps = ((): {
        title: string;
        subtitle: string;
      } | null => {
        if (hasGraphqlError(e, ErrorCode.PulseSurveyConfigOverlappingPeriods)) {
          return {
            title: "Survey duration overlap",
            subtitle:
              "The survey duration extends into the next quarter. Pulse surveys must be contained within a single quarter. Please adjust the start date or duration.",
          };
        } else if (
          hasGraphqlError(e, ErrorCode.GoalConfigRewardTicketWithoutDraws)
        ) {
          return {
            title: "Draws aren't set up yet",
            subtitle:
              "You've selected draw tickets as a reward, but there are no active draws set up yet. Please set up a draw or adjust the reward type.",
          };
        } else if (hasGraphqlError(e, ErrorCode.GoalConfigUpdateConcurrency)) {
          return {
            title: "Update currently in progress",
            subtitle:
              "We're in the process of applying other updates at the moment. Please wait and try again.",
          };
        }
        return null;
      })();

      // show an error modal for handled errors, otherwise default to unexpected error alert
      if (errorModalProps) {
        setErrorModalState({
          title: errorModalProps.title,
          subtitle: errorModalProps.subtitle,
        });
      } else {
        // only report unexpected errors
        reportError(e);

        snackbar.show({
          message: "An unexpected error occurred. Please try again later.",
          severity: "error",
        });
      }
    }
  };

  /**
   * To be called when submitting an active pulse survey config
   */
  const onInactiveSubmit = async (data: PulseSurveyFormType) => {
    assert(data.active === false);

    try {
      await deactivateMutation({
        variables: {
          disableDistributionGoalUpdatePolicy: (() => {
            switch (data.updatePolicy) {
              case "availableAndLocked":
                return GoalDistributionUpdatePolicy.CancelLockedAndAvailableGoals;
              case "lockedOnly":
              case undefined:
                return GoalDistributionUpdatePolicy.CancelLockedGoals;
              default:
                assertNever(data.updatePolicy);
            }
          })(),
        },
      });
      track("Pulse survey settings deactivated");
      snackbar.show({
        severity: "success",
        message: "Success! Pulse survey settings deactivated",
      });
      reset(data);
      navigate(backTo);
    } catch (e) {
      reportError(e);
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    }
  };

  const onSubmitValid = async (data: PulseSurveyFormType) => {
    setPolicyModalOpen(false);

    if (hasAvailablePulseSurveyGoals) {
      assert(
        data.updatePolicy,
        "Expected update policy when organization has available survey goals"
      );
    }

    if (data.active) {
      return onActiveSubmit(data);
    }
    return onInactiveSubmit(data);
  };

  const onSubmitInvalid: Parameters<typeof handleSubmit>[1] = async (
    errors
  ) => {
    track("Pulse survey setting form submitted but user input had errors", {
      errors,
    });
    snackbar.show({
      message: "Oops! There are errors with your pulse survey configuration.",
      severity: "error",
    });
    setPolicyModalOpen(false);
  };

  const onSubmit = handleSubmit(onSubmitValid, onSubmitInvalid);

  return (
    <Form onSubmit={onSubmit} submitting={isSubmitting}>
      <SurveySettingsUpdateModal
        control={control}
        name="updatePolicy"
        open={policyModalOpen}
        onClose={() => setPolicyModalOpen(false)}
        onSubmit={onSubmit}
      />
      <SurveySettingsErrorModal
        open={errorModalState.open}
        title={errorModalState.state?.title ?? ""}
        subtitle={errorModalState.state?.subtitle ?? ""}
        onClose={() => setErrorModalState(undefined)}
        onExited={errorModalStateControl.onExited}
        surveyType={SurveyType.PulseCheck}
      />
      <div
        css={css`
          max-width: ${MAX_WIDTH}px;
        `}
      >
        <Button
          variant="text"
          startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          label="Back"
          width="auto"
          typographyVariant="body"
          linkTo={backTo}
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        />
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>
            <Typography variant="h3">Pulse survey</Typography>
            <Typography
              color="textSecondary"
              variant="footnote"
              css={(theme: AppTheme) =>
                css`
                  margin-top: ${theme.spacing(0.5)};
                `
              }
            >
              Customize survey settings to fit your agency's needs.
            </Typography>
          </div>
          <div
            css={css`
              margin-top: ${theme.spacing(3)};
            `}
          >
            <SurveySettingsActiveField control={control} name="active" />
          </div>
        </div>

        <PageCard
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(3)};
            padding: ${theme.spacing(3)};
            display: ${isActive
              ? "flex"
              : "none"}; // Only show form when survey is active, but do not unmount
            flex-direction: column;
            gap: ${theme.spacing(4)};
          `}
        >
          <SurveyFormRow
            title="Survey frequency"
            subtitle="Choose how often surveys are sent out."
            right={
              <SurveySettingsFrequencyField
                control={control}
                name="frequency"
                options={[
                  {
                    label: "Quarterly",
                    value: PulseCheckSurveyCadenceFrequency.Quarterly,
                  },
                ]}
                disabled={!isActive}
              />
            }
            divider
          />
          <SurveyFormRow
            title={
              <>
                Survey start date{" "}
                <Typography
                  variant="footnote"
                  color={theme.palette.grey[800]}
                  component="span"
                >
                  (optional)
                </Typography>
              </>
            }
            subtitle="Set a start date for your quarterly survey. If no start date is set, the survey will run on March 15, June 15, September 15, and December 15. "
            right={
              /** Disabled when pulse check surveys are already enabled */
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: ${theme.spacing(1.5)};
                  margin-bottom: ${theme.spacing(1)};
                `}
              >
                {isLaunched ? (
                  <SurveySettingsStartDateField
                    control={control}
                    name="startDate"
                    // for pulse check surveys, the start date must be tomorrow or later (distributor logic does not support today)
                    minDate={addDays(new Date(), 1)}
                    disabled={!isActive || isPulseSurveyAlreadyEnabled}
                  />
                ) : (
                  <PulseSurveySendInitialSurveyField
                    control={control}
                    name="sendInitialSurvey"
                    disabled={!isActive || isPulseSurveyAlreadyEnabled}
                  />
                )}
              </div>
            }
            divider
          />
          <SurveyFormRow
            title="Survey duration"
            subtitle="Choose how long your survey will be open for responses."
            right={
              /** Disabled when pulse check surveys are already enabled */
              <SurveySettingsDurationField
                control={control}
                name="duration"
                options={[
                  {
                    label: "1 week",
                    value: weeksToDays(1).toString(),
                  },
                  {
                    label: "2 weeks",
                    value: weeksToDays(2).toString(),
                  },
                  {
                    label: "30 days",
                    value: "30",
                  },
                  {
                    label: "60 days",
                    value: "60",
                  },
                ]}
                disabled={!isActive || isPulseSurveyAlreadyEnabled}
              />
            }
            divider
          />
          {anonymousSubmissionEnabled && (
            <SurveyFormRow
              title="Responses"
              subtitle="Choose whether responses are anonymous or identiable."
              right={
                <SurveySettingsResponseTypeField
                  control={control}
                  name="anonymousSubmission"
                  disabled={!isActive}
                />
              }
              divider
            />
          )}
          <SurveyFormRow
            title="Reward Type"
            subtitle="Choose the reward type and value when a survey is completed"
            right={
              <div
                css={css`
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  column-gap: ${theme.spacing(3)};
                `}
              >
                <SurveySettingsRewardTypeField
                  control={control}
                  name="rewardType"
                  disabled={!isActive}
                />
                <SurveySettingsRewardValueField
                  control={control}
                  name="rewardValue"
                  pointsPerDollar={
                    queryData.getMyRewardsOrganization.pointsPerDollar
                  }
                  getRewardType={() => getValues("rewardType") || null}
                  disabled={!isActive}
                />
              </div>
            }
            divider
          />
          <SurveyFormRow
            title="Questions"
            subtitle="Enable or disable questions to tailor the survey to your agency's needs. At least one question must be enabled for the survey to launch"
            right={null}
          />
          <PulseSurveyQuestionsField control={control} disabled={!isActive} />
        </PageCard>
        <div
          css={(theme: AppTheme) => css`
            float: right;
            display: flex;
            gap: ${theme.spacing(1)};
            margin-left: auto;
            padding: ${theme.spacing(3, 0)};
          `}
        >
          <Button
            label="Cancel"
            css={css`
              width: 150px;
            `}
            variant="outlined"
            linkTo={backTo}
          />
          <Button
            label="Save"
            css={css`
              width: 150px;
            `}
            disabled={!isDirty}
            color="primary"
            {...(hasAvailablePulseSurveyGoals
              ? {
                  type: "button",
                  onClick: async () => {
                    const isFormValid = await triggerValidation(undefined, {
                      shouldFocus: true,
                    });
                    if (isFormValid) {
                      setPolicyModalOpen(true);
                    } else {
                      // Call form submission logic to get all form errors
                      handleSubmit(
                        // no validation errors callback (should not occur)
                        () => {
                          track(
                            "no validation errors when submitting errors despite `triggerValidation` failure"
                          );
                          onSubmitInvalid({}); // invoke the invalid callback anyway
                        },
                        // validation errors callback (expected)
                        onSubmitInvalid
                      )();
                    }
                  },
                }
              : { type: "submit" })}
            loading={isSubmitting}
          />
        </div>
      </div>
    </Form>
  );
}

const mapFormDataToUpdateMutationVariables = ({
  formData: data,
  isPulseSurveyAlreadyEnabled,
}: {
  formData: PulseSurveyFormType;
  isPulseSurveyAlreadyEnabled: boolean;
}): PulseSurveyFormCreateUpdateMutationVariables => {
  // Add type assertion to ensure anonymousSubmission is boolean
  if (typeof data.anonymousSubmission !== "boolean") {
    throw new Error("anonymousSubmission must be a boolean value");
  }

  const enableOnlyFields: Pick<
    PulseCheckSurveyGoalDistributionConfigAdminInput,
    "surveyCadenceOverride" | "surveyDaysUntilExpirationOverride"
  > = {
    surveyCadenceOverride: data.startDate
      ? {
          frequency: data.frequency,
          startDate: data.startDate,
        }
      : undefined, // NOTE: you can only update frequency with start date currently
    surveyDaysUntilExpirationOverride: Number(data.duration),
  };

  return {
    updateRewardOverrideGoalUpdatePolicy: (() => {
      switch (data.updatePolicy) {
        case "availableAndLocked":
          return GoalRewardUpdatePolicy.UpdateLockedAndAvailableGoals;
        case "lockedOnly":
        case undefined:
          return GoalRewardUpdatePolicy.UpdateLockedGoals;
        default:
          assertNever(data.updatePolicy);
      }
    })(),
    pulseCheckSurveyGoalDistributionConfig: {
      surveyRewardOverride: (() => {
        assert(
          !!data.rewardType,
          "Expected reward type to be defined when submitting form"
        );
        switch (data.rewardType) {
          case "points":
            return { numPoints: Number(data.rewardValue) };
          case "tickets":
            return {
              numOrganizationDrawTickets: Number(data.rewardValue),
            };
          default:
            assertNever(data.rewardType);
        }
      })(),
      selectedSurveyQuestions: Object.entries(data.questions).reduce(
        (prev, [question, enabled]) => {
          if (enabled) {
            prev.push(question as keyof PulseSurveyFormQuestions);
          }
          return prev;
        },
        new Array<keyof PulseSurveyFormQuestions>()
      ),
      sendInitialSurveyOnLaunch: data.sendInitialSurvey,
      anonymousSubmission: data.anonymousSubmission,
      ...(isPulseSurveyAlreadyEnabled ? {} : enableOnlyFields), // only include these fields if disabled
    },
  };
};
