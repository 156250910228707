/** @jsxImportSource @emotion/react */
import { ApolloError } from "@apollo/client";
import { css } from "@emotion/react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { Form } from "@rewards-web/shared/components/form";
import {
  RewardsAdminPermissionsType,
  RewardsAdminRestrictedAccessPermissionLevel,
  RewardsAdminRoleType,
  RewardsAdminUser,
  RewardsOrganizationAutomaticRecognitionBudgetFrequency,
  RewardsOrganizationBranch,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { AdminPermissionsFormValue } from "../../../../../shared/components/permissions-table";
import { useCognitoAuth } from "../../../../../shared/modules/cognito-auth";
import { AdminEditedConfirmationModal } from "../admin-edited-confirmation-modal";
import { EditAdminNavigationTabs } from "../edit-admin-navigation-tabs";
import { useUpdateRewardsAdminInfoMutation } from "./update-rewards-admin.generated";

interface EditAdminFormProps {
  admin: Pick<
    RewardsAdminUser,
    | "id"
    | "firstName"
    | "lastName"
    | "active"
    | "email"
    | "jobTitle"
    | "role"
    | "permissions"
    | "automaticRecognitionBudgetConfig"
  > & { branches: Pick<RewardsOrganizationBranch, "id">[] };
  hasFullAccessLevel: boolean;
  recognitionBudgetsEnabled?: boolean;
  pointsPerDollar?: number;
  recognitionBudgetsFrequency?: RewardsOrganizationAutomaticRecognitionBudgetFrequency;
}

export interface EditAdminFormValues {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string | null;
  active: string;
  branchIds: string[];
  permissions: AdminPermissionsFormValue;
  budgetPointsAccessEnabled: boolean;
  defaultBudgetPoints: string;
  // TODO: remove null after rollout
  role: RewardsAdminRoleType | null;
}

export function EditAdminForm({
  admin,
  recognitionBudgetsEnabled,
  pointsPerDollar,
  recognitionBudgetsFrequency,
  hasFullAccessLevel,
}: EditAdminFormProps): JSX.Element {
  const roleSectionEnabled = useFeatureFlag("admin-app-admin-role-temp");

  const [currentTab, setCurrentTab] = useState(0);
  const [accessLevelRestricted, setAccessLevelRestricted] = useState(
    admin.permissions.type === RewardsAdminPermissionsType.RestrictedAccess
  );
  const [
    adminEditedConfirmationModalOpen,
    setAdminEditedConfirmationModalOpen,
  ] = useState<boolean>(false);

  const snackbar = useSnackbar();
  const [updateRewardsAdminInfo] = useUpdateRewardsAdminInfoMutation();

  const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
    event.stopPropagation();
    setCurrentTab(tab);
  };

  const form = useForm<EditAdminFormValues>({
    defaultValues: {
      firstName: admin.firstName,
      lastName: admin.lastName,
      email: admin.email,
      jobTitle: admin.jobTitle ?? "",
      role: admin.role ?? null,
      active: admin.active ? "true" : "false",
      branchIds: admin.branches.map((branch) => branch.id),
      permissions:
        admin.permissions.__typename ===
        "RewardsAdminRestrictedAccessPermissions"
          ? {
              jobs:
                admin.permissions.permissionsV2!.jobs?.permissionLevel ?? null,
              candidates:
                admin.permissions.permissionsV2!.candidates?.permissionLevel ??
                null,
              redemptions:
                admin.permissions.permissionsV2!.redemptions?.permissionLevel ??
                null,
              recognitionPoints:
                admin.permissions.permissionsV2!.recognitionPoints
                  ?.permissionLevel ?? null,
              canOverrideRecognitionCategoryDefaultAmounts:
                admin.permissions.permissionsV2?.recognitionPoints
                  ?.canOverrideRecognitionCategoryDefaultAmounts ?? undefined,
              canOverrideBudget: recognitionBudgetsEnabled
                ? admin.permissions.permissionsV2?.recognitionPoints
                    ?.canOverrideBudget ?? false
                : undefined,
              employees:
                admin.permissions.permissionsV2!.employees?.permissionLevel ??
                null,
              administrators:
                admin.permissions.permissionsV2!.administrators
                  ?.permissionLevel ?? null,
              surveys:
                admin.permissions.permissionsV2!.surveys?.permissionLevel ??
                null,
            }
          : {
              jobs: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
              candidates: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
              redemptions: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
              recognitionPoints:
                RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
              canOverrideRecognitionCategoryDefaultAmounts: null,
              canOverrideBudget: recognitionBudgetsEnabled ? false : undefined,
              employees: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
              administrators:
                RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
              surveys: null,
            },
      budgetPointsAccessEnabled:
        admin.automaticRecognitionBudgetConfig?.enabled,
      defaultBudgetPoints: admin.automaticRecognitionBudgetConfig?.defaultAmount?.toString(),
    },
  });

  const { control, handleSubmit, formState, setValue, setError } = form;

  const { userId } = useCognitoAuth();
  const currentAdminId = userId!;

  function serializeStatusValueForSubmission(value: string) {
    if (admin.id === currentAdminId) {
      return undefined;
    } else {
      return value === "true";
    }
  }

  const onSubmit = async (values: EditAdminFormValues) => {
    try {
      await updateRewardsAdminInfo({
        variables: {
          userId: admin.id,
          firstName: values.firstName,
          lastName: values.lastName,
          branchIds: values.branchIds,
          jobTitle: values.jobTitle ? values.jobTitle : undefined,
          email: values.email,
          ...(roleSectionEnabled
            ? {
                role: values.role ? values.role : undefined,
              }
            : {}),
          active: serializeStatusValueForSubmission(values.active),
          permissions:
            admin.id !== currentAdminId
              ? {
                  type: accessLevelRestricted
                    ? RewardsAdminPermissionsType.RestrictedAccess
                    : RewardsAdminPermissionsType.FullAccess,
                  permissionsV2: accessLevelRestricted
                    ? {
                        jobs: values.permissions.jobs
                          ? { permissionLevel: values.permissions.jobs }
                          : null,
                        candidates: values.permissions.candidates
                          ? {
                              permissionLevel: values.permissions.candidates,
                            }
                          : null,
                        redemptions: values.permissions.redemptions
                          ? {
                              permissionLevel: values.permissions.redemptions,
                            }
                          : null,
                        recognitionPoints: values.permissions.recognitionPoints
                          ? {
                              permissionLevel:
                                values.permissions.recognitionPoints,
                              canOverrideRecognitionCategoryDefaultAmounts:
                                values.permissions.recognitionPoints ===
                                RewardsAdminRestrictedAccessPermissionLevel.FullAccess
                                  ? values.permissions
                                      .canOverrideRecognitionCategoryDefaultAmounts
                                  : null,
                              canOverrideBudget:
                                recognitionBudgetsEnabled &&
                                values.permissions.recognitionPoints ===
                                  RewardsAdminRestrictedAccessPermissionLevel.FullAccess
                                  ? values.permissions.canOverrideBudget
                                  : undefined,
                            }
                          : null,
                        employees: values.permissions.employees
                          ? {
                              permissionLevel: values.permissions.employees,
                            }
                          : null,
                        administrators: values.permissions.administrators
                          ? {
                              permissionLevel:
                                values.permissions.administrators,
                            }
                          : null,
                        surveys: values.permissions.surveys
                          ? { permissionLevel: values.permissions.surveys }
                          : null,
                      }
                    : undefined,
                }
              : undefined,
          automaticRecognitionBudgetConfig:
            !accessLevelRestricted ||
            values.permissions.recognitionPoints ===
              RewardsAdminRestrictedAccessPermissionLevel.FullAccess
              ? {
                  enabled: values.budgetPointsAccessEnabled,
                  ...(values.budgetPointsAccessEnabled &&
                    values.defaultBudgetPoints && {
                      defaultAmount: Number(values.defaultBudgetPoints),
                    }),
                }
              : {
                  enabled: false,
                },
        },
      });

      snackbar.show({
        severity: "success",
        message: `Success! The admin ${
          formState.dirtyFields.budgetPointsAccessEnabled ||
          formState.dirtyFields.defaultBudgetPoints
            ? "including budget settings"
            : ""
        } has been updated.`,
      });
    } catch (error) {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.message ===
          "An existing admin exists with the given email"
      ) {
        setError("email", {
          type: "unique",
          message: "An existing admin exists with this email",
        });
        snackbar.show({
          severity: "error",
          message:
            "A validation error occurred. Please try again with valid details.",
        });
      } else if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.message ===
          "An existing user exists with the given email"
      ) {
        setError("email", {
          type: "unique",
          message: "An user exists with this email",
        });
        snackbar.show({
          severity: "error",
          message:
            "A validation error occurred. Please try again with valid details.",
        });
      } else if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.message ===
          "Cannot set recurring budgets due to feature unavailability or insufficient permissions"
      ) {
        setError("defaultBudgetPoints", {
          message: error.graphQLErrors[0]?.message,
        });
        snackbar.show({
          severity: "error",
          message:
            "A validation error occurred. Please ensure budgets feature is enabled and you have sufficient permissions.",
        });
      } else if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.extensions?.code === "FORBIDDEN" &&
        error.message ===
          "Only full-access admins are allowed to edit other admins."
      ) {
        snackbar.show({
          severity: "error",
          message: error.message,
        });
      } else {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
      window.scrollTo(0, 0);
    } finally {
      setAdminEditedConfirmationModalOpen(false);
    }
  };

  const handleOnConfirm = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Form
      css={css`
        display: contents;
      `}
      submitting={formState.isSubmitting}
      onSubmit={() => {}} // Note: The form is being submitted on clicking confirm from the modal below. Adding an empty handler for the typescript error as opposed to making onSubmit optional for a safe-guard.
    >
      <EditAdminNavigationTabs
        recognitionBudgetsEnabled={recognitionBudgetsEnabled}
        pointsPerDollar={pointsPerDollar}
        recognitionBudgetsFrequency={recognitionBudgetsFrequency}
        currentTab={currentTab}
        handleTabChange={handleTabChange}
        control={control}
        setValue={setValue}
        accessLevelRestricted={accessLevelRestricted}
        setAccessLevelRestricted={setAccessLevelRestricted}
        setAdminEditedConfirmationModalOpen={
          setAdminEditedConfirmationModalOpen
        }
        currentAdminId={currentAdminId}
        editingAdminId={admin.id}
        hasFullAccessLevel={hasFullAccessLevel}
      />

      <AdminEditedConfirmationModal
        open={adminEditedConfirmationModalOpen}
        formState={formState}
        setAdminEditedConfirmationModalOpen={
          setAdminEditedConfirmationModalOpen
        }
        onConfirm={handleOnConfirm}
      />
    </Form>
  );
}
