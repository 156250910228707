/** @jsxImportSource @emotion/react */
import {
  SurveyInsightsDateRangeInput,
  SurveyQuestionType,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import {
  getCurrentScoreForComparison,
  getPreviousScoresForComparison,
} from "../../utils";
import { BaseQuestionScoreCard } from "../base-question-score-card";
import { usePulseSurveyNpsScoreCardDataQuery } from "./pulse-survey-nps-score-card-data.generated";

export interface NpsQuestionScoreCardProps {
  dateRangeInput?: SurveyInsightsDateRangeInput;
}

/**
 * Score card component for NPS question, scored -100 to 100.
 */
export function NpsQuestionScoreCard({
  dateRangeInput,
}: NpsQuestionScoreCardProps) {
  const { data, error } = usePulseSurveyNpsScoreCardDataQuery({
    variables: {
      dateRange: dateRangeInput,
    },
    onError: reportError,
  });

  if (error) {
    return null;
  }

  /**
   * Hide if NPS score is null.
   *
   * This scenario is likely due to the omission of the NPS question in
   * a custom survey goal configuration.
   */
  if (!data || data.getSurveyNPSScore.npsScore === null) {
    return null;
  }

  const npsScore = data.getSurveyNPSScore.npsScore;

  const previousScores = getPreviousScoresForComparison(
    data.getSurveyNPSScore.previousScores ?? []
  );

  const currentScoreWithDateRange = getCurrentScoreForComparison({
    npsScore,
    dateRange: dateRangeInput,
  });

  return (
    <BaseQuestionScoreCard
      questionType={SurveyQuestionType.Nps}
      dateRangeInput={dateRangeInput}
      score={npsScore}
      previousScores={previousScores}
      currentScoreWithDateRange={currentScoreWithDateRange}
    />
  );
}
