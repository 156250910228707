import { reportError } from "@rewards-web/shared/modules/error";

import { useJobsListQuery } from "../../graphql/jobs-list.generated";

export function useAdminJobsTableQuery(
  params: {
    currentPageIndex: number;
    perPage: number;
    includeStats?: boolean;
    branchIds?: Array<string | null>;
  } | null
) {
  const safeParams = params ?? {
    currentPageIndex: 0,
    perPage: 10,
    branchIds: [],
  };

  const { data, loading, refetch } = useJobsListQuery({
    variables: {
      branchIds: safeParams.branchIds,
      limit: safeParams.perPage,
      offset: safeParams.currentPageIndex * safeParams.perPage,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onError: reportError,
    skip: params == null, // Ensures the query is not executed when params is null
  });
  const items = data?.listJobPostings!.items;
  const total = data?.listJobPostings!.total;

  return {
    items,
    total,
    loading,
    refetch,
  };
}
