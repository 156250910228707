/** @jsxImportSource @emotion/react */
import { useSurveySettingsEnabled } from "../survey-settings/shared/hooks/use-survey-settings-enabled";
import { PulseSurveysPageContents } from "./page-contents";

export function PulseSurveysPage() {
  const { surveySettingsEnabled } = useSurveySettingsEnabled();

  return (
    <PulseSurveysPageContents
      surveySettingsEnabled={Boolean(surveySettingsEnabled)}
    />
  );
}
