/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { ReferredByEmployeeSearchField } from "../../../../../../pages/authenticated/candidates/referred-by-employee-search-field";
import { ConfirmChangeReferredByEmployeeModal } from "./confirm-change-referred-by-employee-modal";
import { useCorrectReferringEmployeeMutation } from "./correct-referring-employee.generated";

interface ReferringEmployeeFormValues {
  referringEmployeeId: string | null;
}

interface ChangeCandidateReferringEmployeeFormProps {
  candidate: Pick<GraphQLTypes.Candidate, "id"> & {
    referredByUser: Pick<
      GraphQLTypes.RewardsUser,
      "id" | "firstName" | "lastName"
    >;
  };
  onChangedReferringEmployee(): void;
  onCancel(): void;
}

export function ChangeCandidateReferringEmployeeForm({
  candidate,
  onChangedReferringEmployee,
  onCancel,
}: ChangeCandidateReferringEmployeeFormProps) {
  const snackbar = useSnackbar();
  const track = useTrack();
  const [
    correctReferringEmployee,
    { loading: correctingReferringEmployee },
  ] = useCorrectReferringEmployeeMutation();
  const form = useForm<ReferringEmployeeFormValues>({
    defaultValues: {
      referringEmployeeId: null,
    },
  });

  const [confirmPromptOpen, setConfirmPromptOpen] = useState(false);
  const nextEmployeeId = useWatch({
    control: form.control,
    name: "referringEmployeeId",
  });

  const handleSubmit = () => {
    if (nextEmployeeId === candidate.referredByUser.id) {
      // referring employee hasn't changed, so just close the form and don't execute mutation
      onCancel();
    } else {
      setConfirmPromptOpen(true);
    }
  };

  const handleConfirm = async () => {
    try {
      const res = await correctReferringEmployee({
        variables: {
          candidateId: candidate.id,
          referredByUserId: nextEmployeeId!,
        },
      });

      setConfirmPromptOpen(false);
      onChangedReferringEmployee();

      snackbar.show({
        severity: "success",
        message: "Referring employee has been updated",
      });
      track("Referring employee updated", {
        candidateId: candidate.id,
        requestId: (res.context as ApolloGraphQLResponseContext | undefined)
          ?.requestId,
      });

      form.reset({ referringEmployeeId: nextEmployeeId! });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  return (
    <>
      <ConfirmChangeReferredByEmployeeModal
        open={confirmPromptOpen}
        confirming={correctingReferringEmployee}
        onConfirm={handleConfirm}
        onCancel={() => {
          setConfirmPromptOpen(false);
        }}
        candidateId={candidate.id}
        currentReferringUserId={candidate.referredByUser.id}
        nextReferringUserId={nextEmployeeId}
      />

      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <ReferredByEmployeeSearchField
          control={form.control}
          name="referringEmployeeId"
        />
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            css={(theme: AppTheme) => css`
              margin-right: ${theme.spacing(1)};
            `}
            variant="outlined"
            width="auto"
            label="Cancel"
            onClick={onCancel}
          />
          <Button color="primary" width="auto" label="Confirm" type="submit" />
        </div>
      </Form>
    </>
  );
}
