import { PageLayout } from "../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { EmployeeDetailPageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1400;

export function EmployeeDetailPage(): JSX.Element {
  return (
    <AuthorizedAdminPageWrapper resource="employees">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <EmployeeDetailPageContents />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
