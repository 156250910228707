/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "@rewards-web/shared/components/icon-button";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { AppTheme } from "@rewards-web/shared/style/types";

import { RewardsUserPartial } from ".";
import { useSendLoginLinkToEmployee } from "../../../hooks/use-send-login-links";

interface SendLinkToEmployeeButtonProps {
  employee: RewardsUserPartial;
  rewardsProgramShortName: string;
  usesWorkDevices: boolean;
}

export function SendLinkToEmployeeButton({
  employee,
  rewardsProgramShortName,
  usesWorkDevices,
}: SendLinkToEmployeeButtonProps) {
  const theme = useTheme();

  const [
    sendLoginLink,
    { loading: sendLoginLinkLoading },
  ] = useSendLoginLinkToEmployee({
    rewardsProgramShortName,
  });

  if (!employee.active) {
    return null;
  }

  const shouldSendInvitation = usesWorkDevices && !employee.personalContactInfo;
  const canSendLink = shouldSendInvitation || employee.personalContactInfo;

  if (!canSendLink) {
    // Missing personal contact info, cannot send link
    return null;
  }

  const tooltipTitle = `Send ${shouldSendInvitation ? "invite" : "link"} to ${
    employee.firstName
  }`;

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        aria-label={tooltipTitle}
        onClick={() =>
          sendLoginLink({
            id: employee.id,
            shouldSendInvitation: shouldSendInvitation,
          })
        }
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.grey[200]};
          border-radius: 4px;
          padding: 7px;
          cursor: pointer;
          &:disabled {
            pointer-events: auto;
            cursor: not-allowed;
            background-color: ${theme.palette.grey[200]};
            opacity: 0.5;
          }
        `}
        disabled={sendLoginLinkLoading}
      >
        <FontAwesomeIcon
          icon={faPaperPlane}
          color={theme.palette.primary.main}
          css={css`
            width: 16px;
            height: auto;
          `}
        />
      </IconButton>
    </Tooltip>
  );
}
