import { ClassNames } from "@emotion/react";
import { Tab, Tabs, TabsOwnProps } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { Typography } from "@rewards-web/shared/components/typography";

import { Badge } from "./badge";

interface NavigationTabsProps<TValue extends string = string> {
  ariaLabel: string;
  tabsVariant?: TabsOwnProps["variant"];
  /**
   * If provided, the tabs are controlled (and not based on location path)
   */
  value?: TValue;
  /**
   * Invoked when the selected tab changes
   */
  onChange?(value: TValue): void;
  tabs: Array<{
    label: string;
    /**
     * If provided, the tab is controlled (and not based on location path)
     *
     * Do not provide if `path` is set.
     */
    value?: TValue;

    /**
     * If provided, the tab is rendered as a link to the given path
     *
     * Do not provide if `value` is set.
     */
    path?: string;
    badge?: number;
    minWidth?: string;
  }>;
  disableBottomMargin?: boolean;
}

export function NavigationTabs<TValue extends string = string>({
  ariaLabel,
  tabsVariant,
  value,
  onChange,
  tabs,
  disableBottomMargin = false,
}: NavigationTabsProps<TValue>) {
  const location = useLocation();

  return (
    <ClassNames>
      {({ css, theme }) => {
        const tabClasses = {
          root: css`
            font-weight: 500;
          `,
          selected: css`
            color: ${theme.palette.primary.main};
          `,
        };

        const tabProps = (props: {
          label: string;
          value?: string;
          to?: string;
          badge?: number;
          minWidth?: string;
        }) => ({
          ...props,
          label: (
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                text-transform: none;
                min-width: ${props.minWidth ?? "auto"};
              `}
            >
              <Typography>{props.label} </Typography>
              {typeof props.badge === "number" && props.badge > 0 && (
                <Badge
                  value={props.badge}
                  className={css`
                    margin-left: ${theme.spacing(1)};
                  `}
                />
              )}
            </div>
          ),
          component: props.to ? Link : "div",
          value: props.value ?? props.to,
          classes: tabClasses,
        });

        return (
          <Tabs
            value={value ?? location.pathname}
            aria-label={ariaLabel}
            classes={{
              root: css`
                margin-bottom: ${disableBottomMargin ? 0 : theme.spacing(2)};
              `,
              indicator: css`
                background-color: ${theme.palette.primary.main};
                height: 5px;
              `,
              flexContainer: css`
                border-bottom: 1px solid #e5e5e5;
              `,
            }}
            variant={tabsVariant ?? undefined}
            onChange={(_, value) => onChange?.(value)}
          >
            {tabs.map(({ label, path, value, badge, minWidth }) => (
              <Tab
                {...tabProps({
                  label,
                  to: path,
                  value,
                  badge,
                  minWidth,
                })}
              />
            ))}
          </Tabs>
        );
      }}
    </ClassNames>
  );
}
