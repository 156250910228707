import { sortBy } from "lodash";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { SelectField } from "@rewards-web/shared/components/select-field";

import { useMyBranches } from "../../../branches/use-my-branches";
import { usePermissionLimitedBranchIds } from "../../../branches/use-permission-limited-branch-ids";

interface JobBranchFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function JobBranchField<T extends FieldValues>({
  control,
  name,
}: JobBranchFieldProps<T>) {
  const branchesQuery = useMyBranches();
  const { data: permissionLimitedBranchIds } = usePermissionLimitedBranchIds();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <SelectField
          {...field}
          width="full"
          label="Branch"
          name="branchId"
          options={sortBy(
            (branchesQuery.data?.getMyRewardsOrganization.branches ?? [])
              .filter((branch) => {
                if (permissionLimitedBranchIds?.canOnlySeeBranchIds) {
                  return permissionLimitedBranchIds?.canOnlySeeBranchIds.includes(
                    branch.id
                  );
                }

                return true;
              })
              .map((branch) => ({
                value: branch.id,
                label: branch.name,
              })),
            (branch) => branch.label
          )}
        />
      )}
    />
  );
}
