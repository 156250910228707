import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { useSendInvitationLinkToEmployeeMutation } from "./send-invitation-link-to-employee.generated";
import { useSendLoginLinkToEmployeeMutation } from "./send-login-link-to-employee.generated";

export function useSendLoginLinkToEmployee({
  rewardsProgramShortName,
  sentFrom,
}: {
  rewardsProgramShortName: string;
  sentFrom?: string;
}): [
  (user: { id: string; shouldSendInvitation: boolean }) => void,
  { loading: boolean }
] {
  const track = useTrack();
  const snackbar = useSnackbar();

  const snackbarMessage = `A link to open ${rewardsProgramShortName} has been texted and/or emailed to the user's phone number and email.`;

  const [
    sendLoginLinkToEmployee,
    { loading: sendLoginLinkLoading },
  ] = useSendLoginLinkToEmployeeMutation();
  const [
    sendInvitationLinkToEmployee,
    { loading: sendInvitationLinkLoading },
  ] = useSendInvitationLinkToEmployeeMutation();

  return [
    async (user: {
      id: string;

      /**
       * This should be true if the user has no personal contact info
       */
      shouldSendInvitation: boolean;
    }) => {
      const mutation = user.shouldSendInvitation
        ? sendInvitationLinkToEmployee
        : sendLoginLinkToEmployee;

      try {
        const res = await mutation({
          variables: { employeeId: user.id },
        });

        track(
          `Sent ${
            user.shouldSendInvitation ? "invitation" : "login"
          } link to employee`,
          {
            requestId: (res.context as ApolloGraphQLResponseContext).requestId,
            employeeId: user.id,
            sentFrom,
          }
        );
        snackbar.show({ severity: "success", message: snackbarMessage });
      } catch (error) {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An unexpected error has occurred. Please try again later.",
        });
      }
    },
    { loading: sendLoginLinkLoading || sendInvitationLinkLoading },
  ];
}
