import * as Types from '@rewards-web/shared/graphql-types';

import { TransactionMetadataFragment_TransactionMetadataUnknown_Fragment, TransactionMetadataFragment_TransactionMetadataReferralJobShare_Fragment, TransactionMetadataFragment_TransactionMetadataReferralRecruitmentStep_Fragment, TransactionMetadataFragment_TransactionMetadataReferralRetentionMilestone_Fragment, TransactionMetadataFragment_TransactionMetadataReferralAssignedAsReferringEmployee_Fragment, TransactionMetadataFragment_TransactionMetadataReferralCorrection_Fragment, TransactionMetadataFragment_TransactionMetadataRecognition_Fragment, TransactionMetadataFragment_TransactionMetadataEvvStreakGoal_Fragment, TransactionMetadataFragment_TransactionMetadataEvvPunchCard_Fragment, TransactionMetadataFragment_TransactionMetadataMobileAppAdoption_Fragment, TransactionMetadataFragment_TransactionMetadataMilestoneRewards_Fragment, TransactionMetadataFragment_TransactionMetadataSurvey_Fragment, TransactionMetadataFragment_TransactionMetadataWeekendShift_Fragment, TransactionMetadataFragment_TransactionMetadataLastMinuteShift_Fragment, TransactionMetadataFragment_TransactionMetadataWatchedVideo_Fragment, TransactionMetadataFragment_TransactionMetadataTrainingProgram_Fragment, TransactionMetadataFragment_TransactionMetadataBillableHoursIncrease_Fragment, TransactionMetadataFragment_TransactionMetadataRedemption_Fragment, TransactionMetadataFragment_TransactionMetadataRedemptionRejected_Fragment, TransactionMetadataFragment_TransactionMetadataDrawWinner_Fragment } from './transaction-metadata-fragment.generated';
import { gql } from '@apollo/client';
import { TransactionMetadataFragmentFragmentDoc } from './transaction-metadata-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EmployeeDetailPointTransactionsQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
}>;


export type EmployeeDetailPointTransactionsQuery = (
  { __typename?: 'Query' }
  & { transactions: (
    { __typename?: 'ListTransactionsForRewardsAccountResponse' }
    & Pick<Types.ListTransactionsForRewardsAccountResponse, 'total'>
    & { items: Array<(
      { __typename?: 'RewardsAccountTransaction' }
      & Pick<Types.RewardsAccountTransaction, 'id' | 'occurredAt' | 'unsignedPointValue' | 'signedPointValue'>
      & { metadata: (
        { __typename?: 'TransactionMetadataUnknown' }
        & TransactionMetadataFragment_TransactionMetadataUnknown_Fragment
      ) | (
        { __typename?: 'TransactionMetadataReferralJobShare' }
        & TransactionMetadataFragment_TransactionMetadataReferralJobShare_Fragment
      ) | (
        { __typename?: 'TransactionMetadataReferralRecruitmentStep' }
        & TransactionMetadataFragment_TransactionMetadataReferralRecruitmentStep_Fragment
      ) | (
        { __typename?: 'TransactionMetadataReferralRetentionMilestone' }
        & TransactionMetadataFragment_TransactionMetadataReferralRetentionMilestone_Fragment
      ) | (
        { __typename?: 'TransactionMetadataReferralAssignedAsReferringEmployee' }
        & TransactionMetadataFragment_TransactionMetadataReferralAssignedAsReferringEmployee_Fragment
      ) | (
        { __typename?: 'TransactionMetadataReferralCorrection' }
        & TransactionMetadataFragment_TransactionMetadataReferralCorrection_Fragment
      ) | (
        { __typename?: 'TransactionMetadataRecognition' }
        & TransactionMetadataFragment_TransactionMetadataRecognition_Fragment
      ) | (
        { __typename?: 'TransactionMetadataEVVStreakGoal' }
        & TransactionMetadataFragment_TransactionMetadataEvvStreakGoal_Fragment
      ) | (
        { __typename?: 'TransactionMetadataEVVPunchCard' }
        & TransactionMetadataFragment_TransactionMetadataEvvPunchCard_Fragment
      ) | (
        { __typename?: 'TransactionMetadataMobileAppAdoption' }
        & TransactionMetadataFragment_TransactionMetadataMobileAppAdoption_Fragment
      ) | (
        { __typename?: 'TransactionMetadataMilestoneRewards' }
        & TransactionMetadataFragment_TransactionMetadataMilestoneRewards_Fragment
      ) | (
        { __typename?: 'TransactionMetadataSurvey' }
        & TransactionMetadataFragment_TransactionMetadataSurvey_Fragment
      ) | (
        { __typename?: 'TransactionMetadataWeekendShift' }
        & TransactionMetadataFragment_TransactionMetadataWeekendShift_Fragment
      ) | (
        { __typename?: 'TransactionMetadataLastMinuteShift' }
        & TransactionMetadataFragment_TransactionMetadataLastMinuteShift_Fragment
      ) | (
        { __typename?: 'TransactionMetadataWatchedVideo' }
        & TransactionMetadataFragment_TransactionMetadataWatchedVideo_Fragment
      ) | (
        { __typename?: 'TransactionMetadataTrainingProgram' }
        & TransactionMetadataFragment_TransactionMetadataTrainingProgram_Fragment
      ) | (
        { __typename?: 'TransactionMetadataBillableHoursIncrease' }
        & TransactionMetadataFragment_TransactionMetadataBillableHoursIncrease_Fragment
      ) | (
        { __typename?: 'TransactionMetadataRedemption' }
        & TransactionMetadataFragment_TransactionMetadataRedemption_Fragment
      ) | (
        { __typename?: 'TransactionMetadataRedemptionRejected' }
        & TransactionMetadataFragment_TransactionMetadataRedemptionRejected_Fragment
      ) | (
        { __typename?: 'TransactionMetadataDrawWinner' }
        & TransactionMetadataFragment_TransactionMetadataDrawWinner_Fragment
      ) }
    )> }
  ) }
);


export const EmployeeDetailPointTransactionsDocument = gql`
    query EmployeeDetailPointTransactions($userId: ID!, $limit: Int!, $offset: Int!) {
  transactions: listTransactionsForRewardsAccount(
    userId: $userId
    limit: $limit
    offset: $offset
  ) {
    total
    items {
      id
      occurredAt
      unsignedPointValue
      signedPointValue
      metadata {
        ...TransactionMetadataFragment
      }
    }
  }
}
    ${TransactionMetadataFragmentFragmentDoc}`;

/**
 * __useEmployeeDetailPointTransactionsQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailPointTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailPointTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailPointTransactionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useEmployeeDetailPointTransactionsQuery(baseOptions: Apollo.QueryHookOptions<EmployeeDetailPointTransactionsQuery, EmployeeDetailPointTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeDetailPointTransactionsQuery, EmployeeDetailPointTransactionsQueryVariables>(EmployeeDetailPointTransactionsDocument, options);
      }
export function useEmployeeDetailPointTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDetailPointTransactionsQuery, EmployeeDetailPointTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeDetailPointTransactionsQuery, EmployeeDetailPointTransactionsQueryVariables>(EmployeeDetailPointTransactionsDocument, options);
        }
export type EmployeeDetailPointTransactionsQueryHookResult = ReturnType<typeof useEmployeeDetailPointTransactionsQuery>;
export type EmployeeDetailPointTransactionsLazyQueryHookResult = ReturnType<typeof useEmployeeDetailPointTransactionsLazyQuery>;
export type EmployeeDetailPointTransactionsQueryResult = Apollo.QueryResult<EmployeeDetailPointTransactionsQuery, EmployeeDetailPointTransactionsQueryVariables>;