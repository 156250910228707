/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/client";
import { css } from "@emotion/react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { compact } from "lodash";
import { useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Form } from "@rewards-web/shared/components/form";
import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { SelectField } from "@rewards-web/shared/components/select-field";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { Role } from "@rewards-web/shared/graphql-types";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { InfoIcon } from "../../../../../shared/components/info-icon";
import { usePermissionLimitedBranchIds } from "../../../../../shared/modules/branches/use-permission-limited-branch-ids";
import { RecognitionListDocument } from "../../awarded-recognition-points-page/recognition-points-list.generated";
import { useMyBudgetsDataQuery } from "../../my-budgets-data.generated";
import { ManagedEmployeesRecognitionDataTableDocument } from "../../my-recognition-summary-page/managed-employees-recognition-data-table/managed-employees-recognition-data-table-query.generated";
import { RecognitionPointsReceivedSummaryDocument } from "../../my-recognition-summary-page/recognition-points-received-summary.generated";
import { RecognitionPointsSentSummaryDocument } from "../../my-recognition-summary-page/recognition-points-sent-summary.generated";
import { useRecognitionPointsModalBudgetSummaryQuery } from "../../recognition-points-modal-budget-summary.generated";
import { useBonusPointsUserSearchQuery } from "../../recognition-points-modal/recognition-points-user-search.generated";
import {
  RecognitionPageTab,
  RecognitionTabPathByTab,
} from "../../use-enabled-recognition-page-tabs-query";
import { GiveRecognitionPageDataQuery } from "../give-recognition-page-data.generated";
import { GiveRecognitionTabs } from "../give-recognition-tabs";
import { RecognitionCategoriesDataQuery } from "../recognition-categories-data.generated";
import { useAwardBonusPointsMutation } from "./award-recognition-points.generated";
import { useGetPreselectedUserForRecognitionQuery } from "./get-preselected-user-for-recognition.generated";
import { OverBudgetConfirmationModal } from "./overbudget-confirmation-modal";

const SEARCH_DEBOUNCE_MS = 300;
const MAX_MESSAGE_LENGTH = 300;
const MAX_SENDER_NAME_LENGTH = 50;
const MIN_AWARDABLE_POINTS = 1;
const MAX_AWARDABLE_POINTS = 100_000; // $1,000.00
const INTEGERS_REGEX = /^([+-]?[1-9]\d*|0)$/;

interface SearchEmployeesFormValues {
  userId: string | null;
  numberOfPoints: string;
  messageToUser: string;
  categoryId: string | undefined;
  from: string;
}

const DEFAULT_FORM_VALUES: SearchEmployeesFormValues = {
  userId: null,
  numberOfPoints: "",
  messageToUser: "",
  categoryId: "",
  from: "",
};

interface SearchEmployeesRecognitionFormProps {
  data: GiveRecognitionPageDataQuery;
  categoriesData: RecognitionCategoriesDataQuery;
  isFullAccessAdmin: boolean;
  isSuperuser: boolean;
}

export function SearchEmployeesRecognitionForm({
  data,
  categoriesData,
  isFullAccessAdmin,
  isSuperuser,
}: SearchEmployeesRecognitionFormProps): JSX.Element {
  const track = useTrack();
  const snackbar = useSnackbar();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [preselectedUserId, setPreselectedUserId] = useQueryParam("userId");
  const { data: permissionLimitedBranchIds } = usePermissionLimitedBranchIds();

  const [awardRecognitionPoints] = useAwardBonusPointsMutation();
  const [referringUserSearchQuery, setReferringUserSearchQuery] = useState("");
  const [debouncedUserSearchQuery] = useDebounce(
    referringUserSearchQuery,
    SEARCH_DEBOUNCE_MS
  );

  const myBudgetsQuery = useMyBudgetsDataQuery({
    skip: !data.myOrganization.recognitionBudgetsEnabled || isSuperuser,
    onError: reportError,
  });

  const currentBudgetPeriod = myBudgetsQuery.data?.listMyRecognitionBudgetPeriods?.items.find(
    (period) => period.current
  );

  const currentBudgetSummaryQuery = useRecognitionPointsModalBudgetSummaryQuery(
    {
      skip: !currentBudgetPeriod,
      onError: reportError,
      variables: {
        startDate: currentBudgetPeriod?.startDate,
        endDate: currentBudgetPeriod?.endDate,
      },
    }
  );

  const userSearchQuery = useBonusPointsUserSearchQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
    skip: !permissionLimitedBranchIds,
    variables: {
      filter: {
        branchIds: permissionLimitedBranchIds?.canOnlySeeBranchIds,
      },
      searchQuery: debouncedUserSearchQuery || null,
    },
  });

  const selectedUserQuery = useGetPreselectedUserForRecognitionQuery({
    skip: !preselectedUserId,
    onError: reportError,
    variables: {
      userId: preselectedUserId!,
    },
  });

  const employeeSearchOptions = (() => {
    // if user has been preselected in the search params,
    // return the selected user
    if (preselectedUserId && selectedUserQuery.data?.selectedUser) {
      return [selectedUserQuery.data.selectedUser];
    }

    return (
      (userSearchQuery.data ?? userSearchQuery.previousData)
        ?.searchForRewardsUsers ?? []
    );
  })();

  const form = useForm<SearchEmployeesFormValues>({
    mode: "onChange",
    defaultValues: { ...DEFAULT_FORM_VALUES, userId: preselectedUserId },
  });

  const {
    control,
    register,
    formState: { isSubmitting, errors },
  } = form;

  const numberOfPoints = useWatch({ control, name: "numberOfPoints" });
  const messageToUser = useWatch({ control, name: "messageToUser" });
  const categoryIdValue = useWatch({ control, name: "categoryId" });

  const [
    budgetConfirmationModalOpen,
    setBudgetConfirmationModalOpen,
  ] = useState(false);

  const canOverrideBudget =
    isFullAccessAdmin ||
    (data.myAdmin?.permissions.__typename ===
      "RewardsAdminRestrictedAccessPermissions" &&
      data.myAdmin.permissions.permissionsV2?.recognitionPoints
        ?.canOverrideBudget === true);

  const pointsAvailableInBudget =
    currentBudgetSummaryQuery.data?.getMyRecognitionPointsSentSummaryV2
      .pointsAvailableInBudget ?? 0;

  const selectedPointValueIsOverBudget =
    data.myOrganization.recognitionBudgetsEnabled &&
    parseInt(numberOfPoints, 10) > pointsAvailableInBudget;

  const activeRewardsUserRole = data.myIdentity?.roles.filter(
    (role: { roleName: Role }) => role.roleName === Role.RewardsUser
  )[0];

  useEffect(() => {
    // automatically set the point value based on the category
    // if the user hasn't changed it themselves

    if (categoryIdValue && !form.formState.dirtyFields.numberOfPoints) {
      const category = categoriesData.usableCategories.find(
        (category: { id: string }) => category.id === categoryIdValue
      );

      if (category?.defaultPointAmount) {
        form.setValue("numberOfPoints", String(category.defaultPointAmount), {
          shouldDirty: false,
          shouldValidate: true,
        });
      } else {
        form.setValue("numberOfPoints", "", {
          shouldDirty: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIdValue]);

  const handleSubmitSendPoints = async (values: SearchEmployeesFormValues) => {
    try {
      const response = await awardRecognitionPoints({
        variables: {
          amount: Number(values.numberOfPoints),
          userId: values.userId!,
          messageToUser: values.messageToUser,
          categoryId: values.categoryId,
          from: values.from ?? "",
        },
      });
      track("Awarded bonus points", {
        bonusPointsId: response.data?.awardUserBonusPoints?.id,
        categoryDefaultAmount: (() => {
          if (!values.categoryId) {
            return null;
          }

          const category = categoriesData.usableCategories.find(
            (category: { id: string }) => category.id === values.categoryId
          );

          if (category?.defaultPointAmount) {
            return category.defaultPointAmount;
          }
        })(),
      });
      navigate(RecognitionTabPathByTab[RecognitionPageTab.AwardedPoints]);

      // refetch various queries
      apolloClient.refetchQueries({
        include: [
          RecognitionListDocument,
          RecognitionPointsReceivedSummaryDocument,
          RecognitionPointsSentSummaryDocument,
          ManagedEmployeesRecognitionDataTableDocument,
        ],
      });

      snackbar.show({
        severity: "success",
        message: "Recognition points have been sent!",
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    }
  };

  const handleOverBudgetConfirmationModalConfirm = () => {
    setBudgetConfirmationModalOpen(false);
    return handleSubmitSendPoints(form.getValues());
  };

  const handleOverBudgetConfirmationModalCancel = () => {
    setBudgetConfirmationModalOpen(false);
  };

  const handleSubmit = (values: SearchEmployeesFormValues) => {
    if (canOverrideBudget && selectedPointValueIsOverBudget) {
      setBudgetConfirmationModalOpen(true);
    } else {
      return handleSubmitSendPoints(values);
    }
  };

  const cannotManuallySetPointAmount =
    data.myAdmin?.permissions.__typename ===
      "RewardsAdminRestrictedAccessPermissions" &&
    data.myAdmin.permissions.permissionsV2?.recognitionPoints
      ?.canOverrideRecognitionCategoryDefaultAmounts === false;

  const usableCategories = (() => {
    const allCategories = categoriesData.usableCategories;

    if (cannotManuallySetPointAmount) {
      // if admin cannot overwrite default point amount,
      // only include those categories
      return allCategories.filter((category) => category.defaultPointAmount);
    }

    return allCategories;
  })();

  const categoryFieldValues = usableCategories.map((category) => {
    return {
      label: category.name,
      value: category.id,
    };
  });

  const isSelf = (userId: string) => {
    return activeRewardsUserRole && userId === activeRewardsUserRole.id;
  };

  const pointsPerDollar = data.myOrganization.pointsPerDollar;

  const steps = [
    {
      title: "Recognition recipient(s)",
      content: (
        <>
          {(isFullAccessAdmin || isSuperuser) && <GiveRecognitionTabs />}
          <Controller
            control={control}
            name="userId"
            rules={{
              required: "Employee name is required",
              validate: (userId) => {
                if (userId && isSelf(userId)) {
                  return "You can not give recognition points to yourself";
                } else if (
                  userId &&
                  userSearchQuery.data?.searchForRewardsUsers.find(
                    (user) => user.id === userId
                  )?.active === false
                ) {
                  return "De-activated users can not receive recognition points";
                }
              },
            }}
            render={({ field, fieldState }) => (
              <SearchTextField
                {...field}
                error={fieldState.error}
                label="Employee name"
                options={employeeSearchOptions.map((user) => {
                  return {
                    value: user.id,
                    label: `${user.firstName} ${user.lastName}`,
                    subLabels: compact([
                      (user.personalContactInfo?.__typename ===
                        "RewardsUserPersonalContactInfoData" &&
                        user.personalContactInfo.email) ||
                        user.workEmail,
                      (user.personalContactInfo?.__typename ===
                        "RewardsUserPersonalContactInfoData" &&
                        user.personalContactInfo.phoneNumber) ||
                        user.workPhoneNumber,
                      user.branch?.name,
                      !user.active && "Deactivated",
                    ]),
                  };
                })}
                loadingOptions={userSearchQuery.loading}
                onInputChange={(text) => {
                  // clear out pre-selected user, if one exists
                  if (preselectedUserId) {
                    setPreselectedUserId(null);
                  }
                  setReferringUserSearchQuery(text);
                }}
                onChange={(userId) => {
                  if (userId && isSelf(userId)) {
                    track("Attempted awarding bonus points to self", {
                      userId,
                    });
                  }
                  field.onChange(userId);
                }}
                debouncedInputValue={debouncedUserSearchQuery}
                closedWhenInputTextEmpty
                placeholder="Type to search..."
              />
            )}
          />
        </>
      ),
    },
    {
      title: "Sender details",
      content: (
        <TextField
          label="Your name / who this message is from"
          error={errors.from}
          {...register("from", {
            required: "Sender's name is required",
            validate: (from) => {
              if (from && from.length > MAX_SENDER_NAME_LENGTH) {
                return getCharactersRemainingText(from, MAX_SENDER_NAME_LENGTH);
              }
            },
          })}
        />
      ),
    },
    {
      title: "Points details",
      content: (
        <>
          {usableCategories.length > 0 && (
            <Controller
              control={control}
              name="categoryId"
              rules={{
                required: "Recognition point category is required",
              }}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Select category"
                  hideLabel={!!categoryIdValue}
                  error={fieldState.error}
                  width="full"
                  options={categoryFieldValues.sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )}
                />
              )}
            />
          )}

          <Controller
            control={control}
            name="numberOfPoints"
            rules={{
              required: "Number of points is required",
              validate: (value) => {
                if (!value.match(INTEGERS_REGEX)) {
                  return "You can only enter numbers";
                }
                if (parseInt(value, 10) < MIN_AWARDABLE_POINTS) {
                  return "You must award a positive number of points";
                }
                if (parseInt(value, 10) > MAX_AWARDABLE_POINTS) {
                  return `You cannot award more than ${numberWithCommas(
                    MAX_AWARDABLE_POINTS
                  )} points`;
                }
                if (
                  data.myOrganization.recognitionBudgetsEnabled &&
                  currentBudgetSummaryQuery.data
                    ?.getMyRecognitionPointsSentSummaryV2
                    .pointsAvailableInBudget &&
                  parseInt(value, 10) >
                    currentBudgetSummaryQuery.data
                      .getMyRecognitionPointsSentSummaryV2
                      .pointsAvailableInBudget &&
                  !canOverrideBudget
                ) {
                  return "You must stay within your budget.";
                }
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                hideEndAdornment={false}
                endAdornment={
                  parseInt(numberOfPoints, 10) && pointsPerDollar ? (
                    <Typography
                      variant="subtitle"
                      css={(theme: AppTheme) =>
                        css`
                          font-weight: bolder;
                          color: ${theme.palette.grey[800]};
                        `
                      }
                    >
                      {formatDollars(
                        parseInt(numberOfPoints, 10) / pointsPerDollar
                      )}
                    </Typography>
                  ) : null
                }
                readOnly={cannotManuallySetPointAmount}
                greyReadOnly
                notchedLabelBackgroundColor={
                  cannotManuallySetPointAmount ? "#f9f9fb" : undefined
                }
                disableAutocomplete
                label="Number of points"
                type="text"
                error={fieldState.error}
                helperText={
                  <div
                    css={css`
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      flex-wrap: wrap;
                    `}
                  >
                    {data.myOrganization.recognitionRubricFileUrl && (
                      <Button
                        css={css`
                          font-size: 0.9em;
                          height: 100%;

                          // increase clickable area:
                          padding: 6px 6px 6px 6px;
                          margin: -4px -4px -8px 0px;
                        `}
                        width="auto"
                        size="small"
                        startIcon={
                          <OpenInNewIcon
                            css={css`
                              height: 16px;
                            `}
                            color="primary"
                          />
                        }
                        color="primary"
                        label={
                          cannotManuallySetPointAmount
                            ? "See Recognition Criteria"
                            : "See Suggested Recognition Criteria"
                        }
                        variant="text"
                        linkTo={data.myOrganization.recognitionRubricFileUrl}
                        externalLink
                        target="_blank"
                      />
                    )}
                  </div>
                }
              />
            )}
          />
          {data.myOrganization.recognitionBudgetsEnabled &&
            currentBudgetSummaryQuery.data?.getMyRecognitionPointsSentSummaryV2
              .pointsBudget && (
              <Tooltip
                title="This is how much you have spent out of your budget for the period."
                css={(theme: AppTheme) => css`
                  margin-left: ${theme.spacing(2.5)};
                  align-items: center;
                  justify-content: center;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <InfoIcon
                    css={css`
                      opacity: 0.1;
                      display: flex;
                    `}
                  />
                  <Typography
                    css={(theme: AppTheme) =>
                      css`
                        margin-left: ${theme.spacing(1)};
                        color: ${theme.palette.grey[800]};
                      `
                    }
                  >
                    <Typography component="span">{`Your budget: `}</Typography>
                    <Typography
                      component="span"
                      color={
                        currentBudgetSummaryQuery.data
                          ?.getMyRecognitionPointsSentSummaryV2
                          .pointsAvailableInBudget === 0
                          ? "error"
                          : undefined
                      }
                    >{`${numberWithCommas(
                      currentBudgetSummaryQuery.data
                        .getMyRecognitionPointsSentSummaryV2.pointsSentTotal
                    )}`}</Typography>
                    <Typography component="span">
                      {`/${numberWithCommas(
                        currentBudgetSummaryQuery.data
                          .getMyRecognitionPointsSentSummaryV2.pointsBudget
                      )} points`}
                    </Typography>
                  </Typography>
                </div>
              </Tooltip>
            )}
        </>
      ),
    },
    {
      title: "Message",
      content: (
        <Controller
          control={control}
          name="messageToUser"
          rules={{
            required: "The message is required",
            validate: (message) => {
              if (message && message.length > MAX_MESSAGE_LENGTH) {
                return getCharactersRemainingText(message, MAX_MESSAGE_LENGTH);
              }
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Add a message for your employee"
              type="textarea"
              error={fieldState.error}
              helperText={getCharactersRemainingText(
                messageToUser,
                MAX_MESSAGE_LENGTH
              )}
              minRows={6}
              disableAutocomplete
            />
          )}
        />
      ),
    },
  ];

  return (
    <>
      <Form
        onSubmit={form.handleSubmit(handleSubmit)}
        submitting={isSubmitting}
        css={css`
          display: contents;
        `}
      >
        {steps.map((step, idx) => (
          <Card
            key={idx}
            css={css`
              margin-bottom: 24px;
              padding: 30px;
            `}
          >
            <Typography
              variant="h5"
              fontWeight={700}
              css={css`
                margin-bottom: 30px;
              `}
            >
              {step.title}
            </Typography>
            {step.content}
          </Card>
        ))}
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            variant="outlined"
            label="Cancel"
            linkTo="/recognition"
            width="auto"
            minWidthPx={200}
            size="large"
            css={css`
              margin-right: 20px;
            `}
          />
          <Button
            type="submit"
            loading={isSubmitting}
            label="Send"
            color="primary"
            width="auto"
            minWidthPx={200}
            size="large"
          />
        </div>
      </Form>

      <OverBudgetConfirmationModal
        open={budgetConfirmationModalOpen}
        points={parseInt(numberOfPoints, 10)}
        overBudget={
          pointsAvailableInBudget === 0
            ? parseInt(numberOfPoints, 10) +
              (currentBudgetSummaryQuery.data
                ?.getMyRecognitionPointsSentSummaryV2.pointsSentTotal! -
                currentBudgetSummaryQuery.data
                  ?.getMyRecognitionPointsSentSummaryV2.pointsBudget!)
            : parseInt(numberOfPoints, 10) -
              currentBudgetSummaryQuery.data
                ?.getMyRecognitionPointsSentSummaryV2.pointsAvailableInBudget!
        }
        firstName={
          userSearchQuery.data?.searchForRewardsUsers.find(
            (user) => user.id === form.getValues().userId
          )?.firstName!
        }
        onCancel={handleOverBudgetConfirmationModalCancel}
        onConfirm={handleOverBudgetConfirmationModalConfirm}
      />
    </>
  );
}
