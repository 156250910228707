/** @jsxImportSource @emotion/react */
import { ClassNames, useTheme } from "@emotion/react";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { formatInTimeZone } from "date-fns-tz";

import { Typography } from "@rewards-web/shared/components/typography";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import {
  DateRangeOptions,
  DateRangeSelection,
  JSONSerializableDateRange,
} from "./types";
import {
  getDateRangeSelectionFromDateRange,
  optionToValueStr,
  strToDateRange,
} from "./utils";

interface DateRangeSelectFieldProps {
  value: JSONSerializableDateRange | undefined;
  options: DateRangeOptions;
  setDateRange: (props: DateRangeSelection) => void;
  timezone: string;
  trackingProperties: { dateRangeName: "quarterly" | "quarterly-and-monthly" };
}

/**
 * Convert a date range into a human-readable string shown in the date range picker.
 */
export const dateRangeToLabel = (
  option: JSONSerializableDateRange,
  timezone: string
) => {
  switch (option.type) {
    case "monthly":
      return formatInTimeZone(option.startDate, timezone, "MMMM yyyy");
    case "quarterly": {
      return formatInTimeZone(option.startDate, timezone, "QQQ - yyyy");
    }
    default:
      assertNever(option.type);
  }
};

export function DateRangeSelectField({
  value,
  options,
  setDateRange,
  timezone,
  trackingProperties,
}: DateRangeSelectFieldProps) {
  const label = "Date range";
  const track = useTrack();
  const theme = useTheme();

  const optionsChildren = (() => {
    const quarterlyOptions = options.quarterly.map((option) => {
      const value = optionToValueStr(option);
      return (
        <MenuItem key={value} value={value}>
          <Typography component="span">
            {dateRangeToLabel(option, timezone)}
          </Typography>
        </MenuItem>
      );
    });
    const monthlyOptions = options.monthly.map((option) => {
      const value = optionToValueStr(option);
      return (
        <MenuItem key={value} value={value}>
          {dateRangeToLabel(option, timezone)}
        </MenuItem>
      );
    });
    const allOptions = []; // includes quarterly and monthly options
    if (quarterlyOptions.length > 0) {
      allOptions.push(
        <ListSubheader disableSticky>
          <Typography
            component="span"
            fontWeight={700}
            color={theme.palette.text.primary}
          >
            Quarterly
          </Typography>
        </ListSubheader>,
        ...quarterlyOptions
      );
    }
    if (monthlyOptions.length > 0) {
      allOptions.push(
        <ListSubheader disableSticky>
          <Typography
            component="span"
            fontWeight={700}
            color={theme.palette.text.primary}
          >
            Monthly
          </Typography>
        </ListSubheader>,
        ...monthlyOptions
      );
    }
    return allOptions;
  })();

  return (
    <ClassNames>
      {({ css, theme }) => (
        <Select
          css={css`
            .custom-notched-outline {
              border-color: ${theme.palette.divider};
            }
            align-items: baseline;
          `}
          input={
            <OutlinedInput
              label={label}
              notched={false}
              classes={{
                notchedOutline: "custom-notched-outline",
                root: css`
                  background-color: #fff;
                  border-radius: 8px;
                `,
              }}
            />
          }
          classes={{
            root: css`
              background-color: #fff;
              border-radius: 8px;
              padding: ${theme.spacing(1.5)} 0;
              height: 0.8em;
              width: 160px;

              &:focus {
                background-color: #fff;
                border-radius: 8px;
              }
            `,
          }}
          renderValue={(value) => (
            <Typography
              fontWeight={700}
              fontSize="1.125rem"
              lineHeight="1.25rem"
            >
              {value
                ? dateRangeToLabel(strToDateRange(value as string), timezone)
                : ""}
            </Typography>
          )}
          value={value ? optionToValueStr(value) : ""}
          startAdornment={
            <FontAwesomeIcon
              icon={faCalendar}
              color={theme.palette.grey[800]}
              css={css`
                margin-right: ${theme.spacing(1)};
              `}
            />
          }
          onChange={(event) => {
            const value = event.target.value;
            if (!value) {
              return;
            }
            const selectedOption = strToDateRange(value as string);
            track("Selected insight metrics date range", {
              selectedOption,
              ...trackingProperties,
            });
            setDateRange(
              getDateRangeSelectionFromDateRange({ selectedOption, options })
            );
          }}
        >
          {optionsChildren.length > 0 ? (
            optionsChildren
          ) : (
            <MenuItem disabled>No date ranges available</MenuItem>
          )}
        </Select>
      )}
    </ClassNames>
  );
}
