import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { PageLayout } from "../../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { ForbiddenErrorPage } from "../../../../../shared/modules/permissions/components/forbidden-error-page";
import { useSurveySettingsEnabled } from "../shared/hooks/use-survey-settings-enabled";
import { CheckInSurveyForm } from "./check-in-survey-form";

const MAX_CONTENT_WIDTH = 1400;

export function CheckInSurveySettingsPage() {
  const {
    surveySettingsEnabled,
    surveySettingsLoading,
  } = useSurveySettingsEnabled();

  const checkInSurveySettingsEnabled = useFeatureFlag(
    "admin-app-check-in-survey-form-temp"
  );

  if (surveySettingsLoading) {
    return <PageLoadingState />;
  }

  if (!surveySettingsEnabled || !checkInSurveySettingsEnabled) {
    return <ForbiddenErrorPage />;
  }

  return (
    <AuthorizedAdminPageWrapper resource="surveys" level="full">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <CheckInSurveyForm />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
