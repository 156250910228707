/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

interface TableCellProps extends StylableProps {
  children?: ReactNode;
  divider: boolean;
  dividerStyle?: "dashed" | "solid";
  rightDivider?: boolean;
  align?: "left" | "right" | "center";
  shrink?: boolean;
  minWidth?: string;
  disableStartAndEndPadding?: boolean;
}

export function TableCell({
  divider,
  dividerStyle = "solid",
  rightDivider = false,
  align = "left",
  children,
  className,
  shrink = false,
  minWidth,
  disableStartAndEndPadding = false,
}: TableCellProps): JSX.Element {
  return (
    <td
      className={className}
      css={(theme: AppTheme) => css`
        padding: ${theme.spacing(2)};
        ${!disableStartAndEndPadding &&
        css`
          &:first-of-type {
            padding-left: 0px;
          }
          &:last-of-type {
            padding-right: 0px;
          }
        `}
        text-align: ${align};
        ${divider &&
        css`
          border-bottom: 1px solid ${theme.palette.grey[200]};
          border-bottom-style: ${dividerStyle === "dashed"
            ? "dashed"
            : "solid"};
        `}
        ${rightDivider &&
        css`
          border-right: 1px solid ${theme.palette.grey[200]};
        `}
        ${shrink &&
        css`
          width: 0.1%;
        `}
        ${minWidth &&
        css`
          min-width: ${minWidth};
        `}
      `}
    >
      {children}
    </td>
  );
}
