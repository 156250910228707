import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EmployeeDetailRewardsCardDataQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type EmployeeDetailRewardsCardDataQuery = (
  { __typename?: 'Query' }
  & { getRewardsUserById?: Types.Maybe<(
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'pointsAvailableToRedeem' | 'pointsRedeemed' | 'currentDrawTicketCount'>
  )>, getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'launched' | 'drawsEnabled'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id' | 'rewardsProgramShortName'>
    )> }
  ) }
);


export const EmployeeDetailRewardsCardDataDocument = gql`
    query EmployeeDetailRewardsCardData($userId: ID!) {
  getRewardsUserById(userId: $userId) {
    id
    pointsAvailableToRedeem
    pointsRedeemed
    currentDrawTicketCount
  }
  getMyRewardsOrganization {
    id
    launched
    drawsEnabled
    whiteLabelConfig {
      id
      rewardsProgramShortName
    }
  }
}
    `;

/**
 * __useEmployeeDetailRewardsCardDataQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailRewardsCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailRewardsCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailRewardsCardDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEmployeeDetailRewardsCardDataQuery(baseOptions: Apollo.QueryHookOptions<EmployeeDetailRewardsCardDataQuery, EmployeeDetailRewardsCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeDetailRewardsCardDataQuery, EmployeeDetailRewardsCardDataQueryVariables>(EmployeeDetailRewardsCardDataDocument, options);
      }
export function useEmployeeDetailRewardsCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDetailRewardsCardDataQuery, EmployeeDetailRewardsCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeDetailRewardsCardDataQuery, EmployeeDetailRewardsCardDataQueryVariables>(EmployeeDetailRewardsCardDataDocument, options);
        }
export type EmployeeDetailRewardsCardDataQueryHookResult = ReturnType<typeof useEmployeeDetailRewardsCardDataQuery>;
export type EmployeeDetailRewardsCardDataLazyQueryHookResult = ReturnType<typeof useEmployeeDetailRewardsCardDataLazyQuery>;
export type EmployeeDetailRewardsCardDataQueryResult = Apollo.QueryResult<EmployeeDetailRewardsCardDataQuery, EmployeeDetailRewardsCardDataQueryVariables>;