/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { ScrollToTopOnMount } from "../../../../shared/components/scroll-to-top-on-mount";
import { useEmployeeDetailDataQuery } from "./employee-detail-data.generated";
import { EmployeeDetailReferralCard } from "./employee-detail-referral-card";
import { EmployeeDetailRewardsCard } from "./employee-detail-rewards-card";
import { EmployeeDetailTopCard } from "./employee-detail-top-card";

export function EmployeeDetailPageContents(): JSX.Element {
  const params = useParams<"id">();
  const userId = params.id!;

  const query = useEmployeeDetailDataQuery({
    variables: { userId },
    onError: reportError,
  });

  const userHasLoaded = Boolean(query.data?.user);
  const userDoesNotExist = query.data && !query.data.user;

  return (
    <>
      <ScrollToTopOnMount />
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        linkTo="/employees/accounts"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />
      <div
        css={(theme: AppTheme) => css`
          & > *:not(:last-child) {
            margin-bottom: ${theme.spacing(3)};
          }
        `}
      >
        <EmployeeDetailTopCard userId={userId} query={query} />

        {/* Pass userHasLoaded so cards show loading state and don't query until user loads */}
        {/* Skip rendering cards if user does not exist to prevent query failures */}
        {!userDoesNotExist && (
          <EmployeeDetailRewardsCard
            userId={userId}
            userHasLoaded={userHasLoaded}
          />
        )}
        {!userDoesNotExist && (
          <EmployeeDetailReferralCard
            userId={userId}
            userHasLoaded={userHasLoaded}
          />
        )}
      </div>
    </>
  );
}
