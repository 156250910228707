/** @jsxImportSource @emotion/react */
import { ApolloError } from "@apollo/client";
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  RewardsAdminPermissionsType,
  RewardsAdminRestrictedAccessPermissionLevel,
  RewardsAdminRoleType,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { AdminPermissionsFormValue } from "../../../../shared/components/permissions-table";
import { useHasPermissionsQuery } from "../../../../shared/modules/permissions/hooks/use-has-permissions-query";
import { usePermissionsQuery } from "../../../../shared/modules/permissions/hooks/use-permissions-query";
import { AdminAccountDetails } from "../admin-account-details";
import { AdminBudget } from "../admin-budget";
import { AdminPermissions } from "../admin-permissions";
import { AdminAddedConfirmationModal } from "./admin-added-confirmation-modal";
import { useCreateRewardsAdminMutation } from "./create-rewards-admin.generated";
import { useAddAdminRecognitionBudgetsQuery } from "./recognition-budgets.generated";

export interface AddAdminFormValues {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string | null;
  role: RewardsAdminRoleType;
  permissions: AdminPermissionsFormValue;
  branchIds: string[];
  budgetPointsAccessEnabled: boolean;
  defaultBudgetPoints: string;
}

export function AddAdminsPageContents(): JSX.Element {
  const [accessLevelRestricted, setAccessLevelRestricted] = useState(true);
  const [addedAdminDetails, setAddedAdminDetails] = useState<null | {
    fullName: string;
    email: string;
  }>(null);
  const [
    adminAddedConfirmationModalOpen,
    setAdminAddedConfirmationModalOpen,
  ] = useState(false);

  const budgetSectionEnabled = useFeatureFlag(
    "admin-app-add-edit-admin-page-budget-enabled-temp"
  );
  const roleSectionEnabled = useFeatureFlag("admin-app-admin-role-temp");

  const {
    data: fullAccessAdminQueryData,
    loading: fullAccessAdminQueryLoading,
    error: fullAccessAdminQueryError,
  } = usePermissionsQuery();
  const hasAdminFullAccess =
    fullAccessAdminQueryData?.getMyRewardsAdminUser.permissions.type ===
    RewardsAdminPermissionsType.FullAccess;

  const permissionsRef = useRef<null | HTMLDivElement>(null);
  const track = useTrack();
  const snackbar = useSnackbar();
  const form = useForm<AddAdminFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      jobTitle: "",
      role: RewardsAdminRoleType.ProgramManager,
      branchIds: [],
      permissions: {
        jobs: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
        candidates: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
        redemptions: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
        recognitionPoints: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
        employees: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
        administrators: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
        surveys: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
      },
      budgetPointsAccessEnabled: false,
      defaultBudgetPoints: "",
    },
  });

  const { control, handleSubmit, reset, formState, setValue, setError } = form;

  const firstName = useWatch({ control, name: "firstName" });
  const addedPermissions = useWatch({
    name: "permissions",
    control: form.control,
  });

  const {
    permissionResults,
    loading: hasPermissionsQueryLoading,
    error: hasPermissionsQueryError,
  } = useHasPermissionsQuery({
    recognitionPoints: "read",
  });
  const {
    data: recognitionBudgetsQueryData,
    loading: recognitionBudgetsQueryLoading,
    error: recognitionBudgetsQueryError,
  } = useAddAdminRecognitionBudgetsQuery({
    skip: permissionResults?.recognitionPoints !== true,
    onError: reportError,
  });
  const [createAdmin] = useCreateRewardsAdminMutation();

  const recognitionBudgetsEnabled =
    recognitionBudgetsQueryData?.getMyRewardsOrganization
      ?.recognitionBudgetsEnabled;
  const recognitionBudgetsFrequency =
    recognitionBudgetsQueryData?.getMyRewardsOrganization
      ?.automaticRecognitionBudgetConfig.frequency;
  const pointsPerDollar =
    recognitionBudgetsQueryData?.getMyRewardsOrganization?.pointsPerDollar;

  const handleGoToPermissionsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    permissionsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  const navigate = useNavigate();
  const handleNavigationToList = () => {
    navigate("/admins");
  };

  const onSubmit = async (values: AddAdminFormValues) => {
    try {
      const response = await createAdmin({
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: values.jobTitle ? values.jobTitle : undefined,
          ...(roleSectionEnabled
            ? {
                role: values.role ? values.role : undefined,
              }
            : {}),
          email: values.email,
          branchIds: values.branchIds,
          permissions: {
            type: accessLevelRestricted
              ? RewardsAdminPermissionsType.RestrictedAccess
              : RewardsAdminPermissionsType.FullAccess,
            permissionsV2: accessLevelRestricted
              ? {
                  jobs: values.permissions.jobs
                    ? { permissionLevel: values.permissions.jobs }
                    : null,
                  candidates: values.permissions.candidates
                    ? { permissionLevel: values.permissions.candidates }
                    : null,
                  redemptions: values.permissions.redemptions
                    ? { permissionLevel: values.permissions.redemptions }
                    : null,
                  recognitionPoints: values.permissions.recognitionPoints
                    ? {
                        permissionLevel: values.permissions.recognitionPoints,
                        canOverrideRecognitionCategoryDefaultAmounts:
                          values.permissions.recognitionPoints ===
                          RewardsAdminRestrictedAccessPermissionLevel.FullAccess
                            ? values.permissions
                                .canOverrideRecognitionCategoryDefaultAmounts
                            : undefined,
                        canOverrideBudget:
                          recognitionBudgetsEnabled &&
                          values.permissions.recognitionPoints ===
                            RewardsAdminRestrictedAccessPermissionLevel.FullAccess
                            ? values.permissions.canOverrideBudget
                            : undefined,
                      }
                    : null,
                  employees: values.permissions.employees
                    ? { permissionLevel: values.permissions.employees }
                    : null,
                  administrators: values.permissions.administrators
                    ? { permissionLevel: values.permissions.administrators }
                    : null,
                  surveys: values.permissions.surveys
                    ? { permissionLevel: values.permissions.surveys }
                    : null,
                }
              : undefined,
          },
          automaticRecognitionBudgetConfig:
            !accessLevelRestricted ||
            addedPermissions.recognitionPoints ===
              RewardsAdminRestrictedAccessPermissionLevel.FullAccess
              ? {
                  enabled: values.budgetPointsAccessEnabled,
                  ...(values.budgetPointsAccessEnabled &&
                    values.defaultBudgetPoints && {
                      defaultAmount: Number(values.defaultBudgetPoints),
                    }),
                }
              : undefined,
        },
      });
      setAddedAdminDetails({
        fullName: `${values.firstName} ${values.lastName}`,
        email: values.email,
      });
      setAdminAddedConfirmationModalOpen(true);
      setAccessLevelRestricted(true);
      reset();
      track("Admin created", {
        adminId: response.data?.createRewardsAdmin.id,
      });
    } catch (error) {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.message ===
          "An existing admin exists with the given email"
      ) {
        setError("email", {
          type: "unique",
          message: "An existing admin exists with this email",
        });
        snackbar.show({
          severity: "error",
          message:
            "A validation error occurred. Please try again with valid details.",
        });
      } else if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.message ===
          "An existing user exists with the given email"
      ) {
        setError("email", {
          type: "unique",
          message: "An user exists with this email",
        });
        snackbar.show({
          severity: "error",
          message:
            "A validation error occurred. Please try again with valid details.",
        });
      } else if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.message ===
          "Cannot set recurring budgets due to feature unavailability or insufficient permissions"
      ) {
        setError("defaultBudgetPoints", {
          message: error.graphQLErrors[0]?.message,
        });
        snackbar.show({
          severity: "error",
          message:
            "A validation error occurred. Please ensure budgets feature is enabled and you have sufficient permissions.",
        });
      } else if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.extensions?.code === "FORBIDDEN" &&
        error.message ===
          "Only full-access admins are allowed to create other admins."
      ) {
        snackbar.show({
          severity: "error",
          message: error.message,
        });
      } else {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
      window.scrollTo(0, 0);
    }
  };

  if (
    fullAccessAdminQueryError ||
    hasPermissionsQueryError ||
    recognitionBudgetsQueryError
  ) {
    return (
      <div
        css={css`
          max-width: 500px;
        `}
      >
        <Alert
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
        <Button color="primary" label="Go Back" linkTo="/admins" />
      </div>
    );
  }

  if (
    fullAccessAdminQueryLoading ||
    hasPermissionsQueryLoading ||
    recognitionBudgetsQueryLoading
  ) {
    return <PageLoadingState />;
  }

  return (
    <Form
      css={css`
        display: contents;
      `}
      submitting={formState.isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        linkTo="/admins"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />

      <Typography
        variant="h3"
        color="textPrimary"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        Add administrator
      </Typography>

      <AdminAccountDetails
        control={control}
        fieldNames={{
          firstName: "firstName",
          lastName: "lastName",
          branchIds: "branchIds",
          jobTitle: "jobTitle",
          role: "role",
          email: "email",
        }}
        readOnly={!hasAdminFullAccess}
      />

      <AdminPermissions
        control={control}
        fieldNames={{
          permissions: "permissions",
        }}
        accessLevelRestricted={accessLevelRestricted}
        setAccessLevelRestricted={setAccessLevelRestricted}
        readOnly={!hasAdminFullAccess}
        permissionsRef={permissionsRef}
      />

      {budgetSectionEnabled && hasAdminFullAccess && (
        <AdminBudget
          firstName={firstName}
          control={control}
          fieldNames={{
            defaultBudgetPoints: "defaultBudgetPoints",
            budgetPointsAccessEnabled: "budgetPointsAccessEnabled",
          }}
          setValue={setValue}
          canSendRecognition={
            !accessLevelRestricted ||
            addedPermissions.recognitionPoints ===
              RewardsAdminRestrictedAccessPermissionLevel.FullAccess
          }
          canOverrideBudget={
            !accessLevelRestricted ||
            addedPermissions["canOverrideBudget"] === true
          }
          handleGoToPermissionsClick={handleGoToPermissionsClick}
          recognitionBudgetsEnabled={recognitionBudgetsEnabled}
          pointsPerDollar={pointsPerDollar}
          recognitionBudgetsFrequency={recognitionBudgetsFrequency}
        />
      )}

      {hasAdminFullAccess && (
        <div
          css={css`
            max-width: 861px;
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              float: right;
              display: flex;
              gap: ${theme.spacing(1)};
              padding-bottom: ${theme.spacing(3)};
            `}
          >
            <Button
              label="Cancel"
              css={css`
                width: 150px;
              `}
              variant="outlined"
              linkTo="/admins"
            />
            <Button
              label="Save"
              css={css`
                width: 150px;
              `}
              color="primary"
              type="submit"
            />
          </div>
        </div>
      )}

      <AdminAddedConfirmationModal
        open={adminAddedConfirmationModalOpen}
        onClose={handleNavigationToList}
        adminEmail={addedAdminDetails?.email ?? ""}
        adminFullName={addedAdminDetails?.fullName ?? ""}
      />
    </Form>
  );
}
