/** @jsxImportSource @emotion/react */
import { Checkbox } from "@material-ui/core";
import { chunk } from "lodash";
import { useState } from "react";

import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsAdminUser } from "@rewards-web/shared/graphql-types";

import { DataTable } from "../../../../../../shared/components/data-table";
import { adminRolesMap } from "../../../../admins/lib";

interface AdminGoalConfigurationAdminListProps {
  loading: boolean;
  allAdmins: Pick<
    RewardsAdminUser,
    "id" | "firstName" | "lastName" | "role" | "active"
  >[];
  selectedAdminIds: Set<string>;
  onAdminSelectionChange: (selectedAdminIds: Set<string>) => void;
}

const ITEMS_PER_PAGE = 10;

export function AdminGoalConfigurationAdminList({
  loading,
  allAdmins,
  selectedAdminIds,
  onAdminSelectionChange,
}: AdminGoalConfigurationAdminListProps) {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  // Handling pagination on the front-end here, since all admins are selected
  // by default so we must load all eligible admins from the API.
  const adminPages = chunk(allAdmins, ITEMS_PER_PAGE);
  const items = adminPages[currentPageIndex] ?? [];

  const selectedAll = allAdmins.every((admin) =>
    selectedAdminIds.has(admin.id)
  );

  return (
    <DataTable
      itemsPerPage={ITEMS_PER_PAGE}
      currentPageIndex={currentPageIndex}
      onCurrentPageIndexChange={setCurrentPageIndex}
      emptyText="No eligible admins found"
      items={items}
      total={allAdmins.length}
      loading={loading}
      tableHeaders={
        <TableRow>
          <TableHeader>
            <Checkbox
              checked={selectedAll}
              disabled={allAdmins.length === 0}
              color="primary"
              onChange={() => {
                const newSelectedAdminIds = selectedAll
                  ? new Set([])
                  : new Set(allAdmins.map((admin) => admin.id));
                onAdminSelectionChange(newSelectedAdminIds);
              }}
            />
          </TableHeader>
          <TableHeader>
            <Typography variant="subtitle" fontWeight={700}>
              Employee
            </Typography>
          </TableHeader>
          <TableHeader>
            <Typography variant="subtitle" fontWeight={700}>
              Role
            </Typography>
          </TableHeader>
          <TableHeader>
            <Typography variant="subtitle" fontWeight={700}>
              Status
            </Typography>
          </TableHeader>
        </TableRow>
      }
      tableBody={items.map((admin) => (
        <TableRow key={admin.id}>
          <TableCell divider shrink>
            <Checkbox
              checked={selectedAdminIds.has(admin.id)}
              color="primary"
              onChange={(_, checked) => {
                const newSelectedAdminIds = new Set(selectedAdminIds);
                if (checked) {
                  newSelectedAdminIds.add(admin.id);
                } else {
                  newSelectedAdminIds.delete(admin.id);
                }
                onAdminSelectionChange(newSelectedAdminIds);
              }}
            />
          </TableCell>
          <TableCell divider>
            {admin.firstName} {admin.lastName}
          </TableCell>
          <TableCell divider>
            {admin.role ? adminRolesMap[admin.role] : "-"}
          </TableCell>
          <TableCell divider>
            {admin.active ? "Active" : "De-activated"}
          </TableCell>
        </TableRow>
      ))}
    />
  );
}
