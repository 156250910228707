/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faCalendar,
  faChevronLeft,
  faGift,
  faTrash,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { compact } from "lodash";
import { ReactNode, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableHeaders } from "@rewards-web/shared/components/table-components/table-headers";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DataTable } from "../../../../shared/components/data-table";
import { ConfirmWinnersModal } from "./confirm-winners-modal";
import { isIndex } from "./lib";
import { RedrawWinnersModal } from "./redraw-winners-modal";
import { RemoveWinnersModal } from "./remove-winners-modal";
import { useWinnersToApproveQuery } from "./winners-to-approve.generated";

const ITEMS_PER_PAGE = 10;
export type RemoveWinner = {
  userId: string;
  firstName: string;
  lastName: string;
  prizeValue: string;
  prizeTierIndex?: number;
};
export function ApproveDrawWinnersPageContents() {
  const params = useParams();
  const track = useTrack();
  const drawId = params.id!;
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const query = useWinnersToApproveQuery({
    onError: reportError,
    variables: { drawId },

    // after draw is approved, we want the previous state
    // to keep displaying under the final modal
    // before navigating away
    nextFetchPolicy: "cache-first",
  });

  const [redrawWinnersModalOpen, setRedrawWinnersModalOpen] = useState(false);

  const [confirmWinnersModalOpen, setConfirmWinnersModalOpen] = useState(false);
  const [removeWinnerModalOpen, setremoveWinnerModalOpen] = useState(false);
  const [removeWinner, setRemoveWinner] = useState<RemoveWinner | undefined>();

  if (query.error) {
    return (
      <Alert
        message="An unexpected error occurred. Please try again later."
        severity="error"
      />
    );
  }

  if (!query.data) {
    return <PageLoadingState />;
  }

  const { draw, organization } = query.data;

  const getTierIcon = (prizeTierIndex: number) => {
    switch (prizeTierIndex) {
      case 0:
        return "🏆";
      case 1:
        return "🥈";
      case 2:
        return "🥉";
      default:
        return "🏆";
    }
  };
  const getPrizeValue = (
    pointsPrizePerWinner: number | undefined,
    pointsPerDollar: number,
    prizeTierIndex?: number
  ): ReactNode => {
    if (typeof pointsPrizePerWinner === "number") {
      return (
        <>
          {isIndex(prizeTierIndex) && getTierIcon(prizeTierIndex!)}{" "}
          {numberWithCommas(pointsPrizePerWinner)} points{" "}
          <strong>
            ({formatDollars(pointsPrizePerWinner / pointsPerDollar)})
          </strong>
        </>
      );
    }

    return "(Prize cannot be displayed)";
  };

  const pageContents = (() => {
    if (!draw) {
      return (
        <Alert
          message={
            <>
              This draw no longer exists. <Link to="/draw">Click here</Link> to
              go back to Draws.
            </>
          }
          severity="warning"
        />
      );
    }

    if (!draw.winnersToApprove) {
      return (
        <Alert
          message={
            <>
              Winners can no longer be approved for this draw.{" "}
              <Link to="/draw">Click here</Link> to go back to Draws.
            </>
          }
          severity="warning"
        />
      );
    }
    if (
      draw.prizeStructure.__typename !==
        "DrawPrizeStructureSinglePrizeMultipleWinners" &&
      draw.prizeStructure.__typename !==
        "DrawPrizeStructureTieredPrizesMultipleWinners"
    ) {
      throw new Error(
        `Prize structure does not currently support winner approval`
      );
    }

    const numWinnersForPrizeStructure = draw.prizeStructure.numWinners;
    const numWinnersToApprove = draw.winnersToApprove.length;
    const enoughWinnersDrawn =
      numWinnersToApprove >= numWinnersForPrizeStructure;
    const canRedrawWinners = enoughWinnersDrawn;

    const pointsPrizePerWinner = (():
      | (number | undefined)[]
      | undefined
      | number => {
      switch (draw.prizeStructure.__typename) {
        case "DrawPrizeStructureSinglePrizeMultipleWinners": {
          switch (draw.prizeStructure.prize.__typename) {
            case "DrawPrizePoints":
              return draw.prizeStructure.prize.pointValue;
            default:
              reportError(new Error("Unexpected prize type"));
              break;
          }
          break;
        }
        case "DrawPrizeStructureTieredPrizesMultipleWinners": {
          let result = [];
          switch (draw.prizeStructure.prizes[0].__typename) {
            case "DrawTieredPrizePoints":
              for (let prize of draw.prizeStructure.prizes) {
                switch (prize.__typename) {
                  case "DrawTieredPrizePoints":
                    result.push(prize.pointValue);
                    break;
                  default:
                    reportError(new Error("Unexpected prize type"));
                }
              }
              break;
            default:
              reportError(new Error("Unexpected prize structure type"));
          }

          return result;
        }
        default:
          // can't display other prize structure types here
          reportError(new Error("Unexpected prize structure type"));
          break;
      }
    })();

    const getTotalPointValue = (
      pointsPrizePerWinner: number | (number | undefined)[] | undefined,
      numWinnersToApprove: number,
      index?: number
    ) => {
      if (pointsPrizePerWinner === undefined) {
        return 0;
      }

      switch (typeof pointsPrizePerWinner) {
        case "number": {
          return pointsPrizePerWinner * numWinnersToApprove;
        }
        case "object": {
          if (isIndex(index)) {
            return (pointsPrizePerWinner[index!] || 0) * numWinnersToApprove;
          }

          return 0;
        }
        default:
          return 0;
      }
    };

    if (
      draw.prizeStructure.__typename ===
      "DrawPrizeStructureSinglePrizeMultipleWinners"
    ) {
      return (
        <>
          <Typography
            variant="body"
            color="grey.800"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            Review the randomly selected winner(s) from participants in this
            draw. Approval is required before winner(s) are announced in-app.
          </Typography>
          <Card
            css={(theme: AppTheme) => css`
              padding: ${theme.spacing(3)};
            `}
          >
            {draw.name && (
              <Typography
                variant="h4"
                color="textPrimary"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(1)};
                `}
              >
                {draw.name}
              </Typography>
            )}
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                > *:not(:last-child)::after {
                  content: "•";
                  padding: 0 ${theme.spacing(2)};
                }
                margin-bottom: ${theme.spacing(3)};
              `}
            >
              {compact([
                {
                  key: "date",
                  icon: <FontAwesomeIcon icon={faCalendar} />,
                  text: [draw.scheduledStartDate, draw.scheduledEndDate]
                    .map((date) => format(new Date(date), "LLLL d, yyyy"))
                    .join(" - "),
                },
                (() => {
                  if (typeof pointsPrizePerWinner === "number") {
                    const totalPointValue = getTotalPointValue(
                      pointsPrizePerWinner,
                      numWinnersToApprove
                    );

                    return {
                      key: "prize",
                      icon: <FontAwesomeIcon icon={faGift} />,
                      text: (
                        <span>
                          Total prize(s) {numberWithCommas(totalPointValue)}{" "}
                          points{" "}
                          <strong>
                            (
                            {formatDollars(
                              totalPointValue / organization.pointsPerDollar
                            )}
                            )
                          </strong>
                        </span>
                      ),
                    };
                  } else {
                    const totalPointValue = 0;
                    return {
                      key: "prize",
                      icon: <FontAwesomeIcon icon={faGift} />,
                      text: (
                        <span>
                          Total prize(s) {numberWithCommas(totalPointValue)}{" "}
                          points{" "}
                          <strong>
                            (
                            {formatDollars(
                              totalPointValue / organization.pointsPerDollar
                            )}
                            )
                          </strong>
                        </span>
                      ),
                    };
                  }
                })(),
                {
                  key: "num winners",
                  icon: <FontAwesomeIcon icon={faUser} />,
                  text: `${numberWithCommas(numWinnersToApprove)} winner(s)`,
                },
              ]).map(({ key, icon, text }) => (
                <Typography
                  key={key}
                  variant="body"
                  color="textPrimary"
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={(theme: AppTheme) => css`
                      margin-right: ${theme.spacing(1)};
                    `}
                  >
                    {icon}
                  </div>{" "}
                  {text}
                </Typography>
              ))}
            </div>

            {!enoughWinnersDrawn && (
              <Alert
                severity="info"
                variant="filled"
                message={`Heads up! Only ${draw.winnersToApprove.length} caregiver(s) will be awarded a prize as they were the only ones to complete tickets for this draw.`}
                css={(theme: AppTheme) => css`
                  background-color: ${theme.palette.text.primary};
                  color: white;
                `}
              />
            )}

            <Table
              css={(theme: AppTheme) =>
                css`
                  margin-bottom: ${theme.spacing(4)};
                `
              }
            >
              <TableHeaders>
                <TableRow>
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Name
                    </Typography>
                  </TableHeader>
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Email
                    </Typography>
                  </TableHeader>
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Phone number
                    </Typography>
                  </TableHeader>
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Status
                    </Typography>
                  </TableHeader>
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Prize
                    </Typography>
                  </TableHeader>
                </TableRow>
              </TableHeaders>
              <TableBody>
                {compact(draw.winnersToApprove).map((winner) => {
                  return (
                    <TableRow key={winner.id}>
                      <TableCell divider>
                        <Link
                          to={`/employees/${winner.user.id}/edit`}
                          target="_blank"
                          css={(theme: AppTheme) => css`
                            color: ${theme.palette.primary.main};
                            text-decoration: none;
                          `}
                        >
                          <Typography variant="body" color="primary">
                            {winner.user.firstName} {winner.user.lastName}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell divider>
                        <Typography variant="body" color="grey.800">
                          {(() => {
                            if (
                              winner.user.personalContactInfo?.__typename ===
                              "RewardsUserPersonalContactInfoSuppressed"
                            ) {
                              return "(Suppressed)";
                            }

                            return (
                              winner.user.personalContactInfo?.email ??
                              winner.user.workEmail ??
                              "-"
                            );
                          })()}
                        </Typography>
                      </TableCell>
                      <TableCell divider>
                        <Typography
                          variant="body"
                          color="grey.800"
                          css={css`
                            white-space: nowrap;
                          `}
                        >
                          {(() => {
                            if (
                              winner.user.personalContactInfo?.__typename ===
                              "RewardsUserPersonalContactInfoSuppressed"
                            ) {
                              return "(Suppressed)";
                            }

                            return formatPhoneNumber(
                              winner.user.personalContactInfo?.phoneNumber ??
                                winner.user.workPhoneNumber ??
                                "-"
                            );
                          })()}
                        </Typography>
                      </TableCell>
                      <TableCell divider>
                        <Typography variant="body" color="grey.800">
                          {winner.user.active ? "Active" : "De-activated"}
                        </Typography>
                      </TableCell>
                      <TableCell divider>
                        <Typography variant="body" color="grey.800">
                          {getPrizeValue(
                            Number(pointsPrizePerWinner),
                            organization.pointsPerDollar
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <div
              css={(theme: AppTheme) => css`
                float: right;
                display: flex;
                gap: ${theme.spacing(2)};
              `}
            >
              {canRedrawWinners && (
                <Button
                  size="large"
                  width="auto"
                  variant="outlined"
                  label="Redraw winner(s)"
                  onClick={() => {
                    setRedrawWinnersModalOpen(true);
                    track("Clicked redraw winners button for draw", {
                      drawId,
                    });
                  }}
                />
              )}
              <Button
                size="large"
                width="auto"
                color="primary"
                label="Approve winner(s)"
                onClick={() => {
                  setConfirmWinnersModalOpen(true);
                  track("Opened approve draw winners confirmation modal", {
                    drawId,
                  });
                }}
              />
            </div>
          </Card>
        </>
      );
    } else if (
      draw.prizeStructure.__typename ===
      "DrawPrizeStructureTieredPrizesMultipleWinners"
    ) {
      const total = draw!.prizeStructure.numWinners;
      // Calculate the items to display for the current page
      const paginatedItems = draw!.winnersToApprove.slice(
        currentPageIndex * ITEMS_PER_PAGE,
        (currentPageIndex + 1) * ITEMS_PER_PAGE
      );
      const lastWinner =
        draw!.winnersToApprove.length === 1 && !enoughWinnersDrawn;

      return (
        <>
          <Typography
            variant="body"
            color="grey.800"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            Review the randomly selected winner(s) from participants in this
            draw. Approval is required before winner(s) are announced in-app.
            <br />
            <strong>Important Note:</strong> Removing a winner will
            automatically adjust the rankings of subsequent winners.
          </Typography>
          <Card
            css={(theme: AppTheme) => css`
              padding: ${theme.spacing(3)};
            `}
          >
            {draw.name && (
              <div
                css={(theme: AppTheme) => css`
                  > *:not(:last-child)::after {
                    padding: 0 ${theme.spacing(2)};
                  }

                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                <Typography
                  variant="h4"
                  component="h2"
                  color="textPrimary"
                  css={(theme: AppTheme) => css`
                    margin-bottom: ${theme.spacing(1)};
                    margin-right: ${theme.spacing(1)};
                  `}
                >
                  {draw.name}
                </Typography>
                <Typography
                  variant="body"
                  color="textPrimary"
                  css={(theme: AppTheme) => css`
                    display: flex;
                    margin-top: ${theme.spacing(0.5)};
                  `}
                >
                  <div
                    css={(theme: AppTheme) => css`
                      margin-right: ${theme.spacing(1)};
                    `}
                  >
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>{" "}
                  {[draw.scheduledStartDate, draw.scheduledEndDate]
                    .map((date) => format(new Date(date), "LLLL d, yyyy"))
                    .join(" - ")}
                </Typography>
              </div>
            )}
            {!enoughWinnersDrawn && (
              <Alert
                severity="info"
                variant="filled"
                message={`Heads up! Only ${draw.winnersToApprove.length} caregiver(s) will be awarded a prize as they were the only ones to complete tickets for this draw.`}
                css={(theme: AppTheme) => css`
                  background-color: ${theme.palette.text.primary};
                  color: white;
                  margin-bottom: ${theme.spacing(3)};
                `}
              />
            )}

            <DataTable
              currentPageIndex={currentPageIndex}
              onCurrentPageIndexChange={setCurrentPageIndex}
              items={paginatedItems}
              total={total}
              itemsPerPage={ITEMS_PER_PAGE}
              loading={query.loading}
              css={(theme: AppTheme) =>
                css`
                  margin-bottom: ${theme.spacing(4)};
                `
              }
              paddingDisabled={true}
              tableHeaders={
                <TableRow
                  css={(theme: AppTheme) => css`
                    padding-left: ${theme.spacing(1)};
                  `}
                >
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Name
                    </Typography>
                  </TableHeader>
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Email
                    </Typography>
                  </TableHeader>
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Phone number
                    </Typography>
                  </TableHeader>
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Status
                    </Typography>
                  </TableHeader>
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Prize
                    </Typography>
                  </TableHeader>
                  <TableHeader>
                    <Typography
                      css={css`
                        font-weight: inherit;
                      `}
                      variant="subtitle"
                      color="textPrimary"
                    >
                      Action
                    </Typography>
                  </TableHeader>
                </TableRow>
              }
              tableBody={paginatedItems.map((winner) => {
                return (
                  <TableRow key={winner.id}>
                    <TableCell divider>
                      <Link
                        to={`/employees/${winner.user.id}/edit`}
                        target="_blank"
                        css={(theme: AppTheme) => css`
                          color: ${theme.palette.primary.main};
                          text-decoration: none;
                        `}
                      >
                        <Typography
                          variant="body"
                          color="primary"
                          css={(theme: AppTheme) => css`
                            padding-left: ${theme.spacing(1)};
                            display: flex;
                            align-items: center;
                          `}
                        >
                          {winner.user.firstName} {winner.user.lastName}{" "}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell divider>
                      <Typography variant="body" color="grey.800">
                        {(() => {
                          if (
                            winner.user.personalContactInfo?.__typename ===
                            "RewardsUserPersonalContactInfoSuppressed"
                          ) {
                            return "(Suppressed)";
                          }

                          return (
                            winner.user.personalContactInfo?.email ??
                            winner.user.workEmail ??
                            "-"
                          );
                        })()}
                      </Typography>
                    </TableCell>
                    <TableCell divider>
                      <Typography
                        variant="body"
                        color="grey.800"
                        css={css`
                          white-space: nowrap;
                        `}
                      >
                        {(() => {
                          if (
                            winner.user.personalContactInfo?.__typename ===
                            "RewardsUserPersonalContactInfoSuppressed"
                          ) {
                            return "(Suppressed)";
                          }

                          return formatPhoneNumber(
                            winner.user.personalContactInfo?.phoneNumber ??
                              winner.user.workPhoneNumber ??
                              "-"
                          );
                        })()}
                      </Typography>
                    </TableCell>
                    <TableCell divider>
                      <Typography variant="body" color="grey.800">
                        {winner.user.active ? "Active" : "De-activated"}
                      </Typography>
                    </TableCell>
                    <TableCell divider>
                      <Typography variant="body" color="grey.800">
                        {winner && isIndex(winner.prizeTierIndex)
                          ? getPrizeValue(
                              (pointsPrizePerWinner as number[])[
                                winner.prizeTierIndex!
                              ],
                              organization.pointsPerDollar,
                              winner.prizeTierIndex!
                            )
                          : "(Prize cannot be displayed)"}
                      </Typography>
                    </TableCell>
                    <TableCell divider>
                      <Tooltip
                        title="You cannot remove the last winner. A draw must have at least one winner."
                        placement="top"
                        disabled={!lastWinner}
                      >
                        <IconButton
                          disabled={lastWinner}
                          aria-label={`remove-winner`}
                          onClick={() => {
                            setremoveWinnerModalOpen(true);
                            setRemoveWinner({
                              userId: winner.user.id,
                              firstName: winner.user.firstName,
                              ...(isIndex(winner.prizeTierIndex)
                                ? { prizeTierIndex: winner.prizeTierIndex! }
                                : {}),
                              lastName: winner.user.lastName,
                              prizeValue: formatDollars(
                                (pointsPrizePerWinner as number[])[
                                  winner.prizeTierIndex!
                                ] / organization.pointsPerDollar
                              ),
                            });
                            track("Opened remove winner modal", {
                              drawId,
                              userId: winner.id,
                            });
                          }}
                          css={css`
                            ${lastWinner &&
                            css`
                              opacity: 0.4;
                            `}
                          `}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            />

            <div
              css={(theme: AppTheme) => css`
                float: right;
                display: flex;
                gap: ${theme.spacing(2)};
                margin-top: ${theme.spacing(1)};
              `}
            >
              <Button
                size="large"
                width="auto"
                color="primary"
                label="Approve winner(s)"
                onClick={() => {
                  setConfirmWinnersModalOpen(true);
                  track("Opened approve draw winners confirmation modal", {
                    drawId,
                  });
                }}
              />
            </div>
          </Card>
        </>
      );
    }
  })();

  const prizeTierIndexes =
    draw &&
    draw.prizeStructure &&
    draw.prizeStructure.__typename ===
      "DrawPrizeStructureTieredPrizesMultipleWinners"
      ? draw?.winnersToApprove
          ?.map((winner) => ({
            prizeTierIndex:
              winner.prizeTierIndex !== null &&
              winner.prizeTierIndex !== undefined
                ? winner.prizeTierIndex
                : undefined,
          }))
          .filter((winner) => winner.prizeTierIndex !== undefined)
      : undefined;

  return (
    <>
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        linkTo="/draw"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />
      <Typography
        variant="h3"
        component="h1"
        color="textPrimary"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        Approve Winners
      </Typography>

      {pageContents}

      <RedrawWinnersModal
        open={redrawWinnersModalOpen}
        onClose={() => {
          setRedrawWinnersModalOpen(false);
        }}
      />
      <ConfirmWinnersModal
        drawId={drawId}
        winnerIds={
          draw?.winnersToApprove?.map((winner) => winner.user.id) ?? []
        }
        prizeTierIndexes={
          prizeTierIndexes && prizeTierIndexes.length > 0
            ? prizeTierIndexes
                .map((item) => item.prizeTierIndex)
                .filter((index): index is number => index !== undefined)
            : []
        }
        open={confirmWinnersModalOpen}
        onClose={() => {
          setConfirmWinnersModalOpen(false);
        }}
      />
      <RemoveWinnersModal
        drawId={drawId}
        removeWinner={removeWinner}
        open={removeWinnerModalOpen}
        winnersToApproveRefetch={query.refetch}
        onClose={() => {
          setremoveWinnerModalOpen(false);
        }}
      />
    </>
  );
}
