import {
  AppThemeProperties,
  fontFamily,
} from "@rewards-web/shared/style/theme";
import { DeepPartial } from "@rewards-web/shared/types";

const defaultFont = {
  fontFamily,
  fontWeight: 400,
  fontSize: "1rem", // 16px
  lineHeight: "1.563", // 25px,
};

const defaultHeaderFont = "Lexend, sans-serif";

export const adminAppTheme: DeepPartial<AppThemeProperties> = {
  typography: {
    allVariants: defaultFont,
    body: defaultFont,
    buttonLarge: {
      fontFamily: defaultHeaderFont,
      fontWeight: 600,
      fontSize: "1rem", // 16px
      lineHeight: "1.563rem", // 25px
      textTransform: "none",
    },
  },
};
