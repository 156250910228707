import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TotalHiresInsightMetric as TotalHiresInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../../../../shared/components/insight-metric";
import {
  useTrackInsightMetricComparisonTooltip,
  useTrackInsightMetricTooltip,
} from "../../../../hooks";
import { getPercentDifference } from "../../../../utils";

export interface NewHiresInsightMetricProps {
  currentNewHiresInsightMetric: TotalHiresInsightMetricType | undefined;
  previousNewHiresInsightMetric: TotalHiresInsightMetricType | undefined;
}

export function NewHiresInsightMetric({
  currentNewHiresInsightMetric,
  previousNewHiresInsightMetric,
}: NewHiresInsightMetricProps) {
  const trackInsightMetricGroupTooltip = useTrackInsightMetricTooltip();
  const trackInsightMetricComparisonTooltip = useTrackInsightMetricComparisonTooltip();

  const currentNewHires = currentNewHiresInsightMetric?.hiredCandidateCount;
  const previousNewHires = previousNewHiresInsightMetric?.hiredCandidateCount;

  const percentPointChange = (() => {
    if (currentNewHires === undefined || previousNewHires === undefined) {
      return null;
    }
    return getPercentDifference(previousNewHires, currentNewHires);
  })();

  return (
    <InsightMetric
      label="New hires"
      icon={<FontAwesomeIcon icon={faUsers} />}
      tooltipText="The total new hires brought in through referrals. "
      value={
        currentNewHires !== undefined
          ? { type: "number", number: currentNewHires }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
      onTooltipOpen={trackInsightMetricGroupTooltip}
      onPercentPointChangeTooltipOpen={trackInsightMetricComparisonTooltip}
    />
  );
}
