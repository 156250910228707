import { PageLayout } from "../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { SurveysPageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1400;

export function SurveysPage(): JSX.Element {
  return (
    <AuthorizedAdminPageWrapper resource="surveys">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <SurveysPageContents />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
