/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faArrowRight,
  faCircleStop,
  faRepeat,
} from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addMonths, differenceInDays, subDays } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { xor } from "lodash";
import { useEffect, useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Divider } from "@rewards-web/shared/components/divider";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { AdminGoalType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useAdminGoalsSettingsPageDataQuery } from "./admin-goals-settings-page-data.generated";
import {
  ADMIN_GOAL_TYPES_SUPPORTED_BY_UI,
  AdminGoalTypeSupportedByUI,
} from "./constants";
import coinsIcon from "./icons/coins.png";
import loginIcon from "./icons/login.png";
import { PeriodSection } from "./period-section";
import { RemoveGoalConfirmationModal } from "./remove-goal-confirmation-modal";

const GOAL_CONFIG_CONTENT = {
  [AdminGoalType.BudgetUtilization]: {
    label: "Recognition budget utilization",
    description:
      "Set recognition goals to make the most of your monthly budget, fostering a culture of appreciation where employees feel valued and supported.",
    icon: coinsIcon,
    iconAltText: "coins",
    linkTo: "/settings/office-goals/recognition-budget",
  },
  [AdminGoalType.LoginAfterLaunch]: {
    label: "First 30-day login goal",
    description:
      "Set a login target to encourage engagement and keep your team active and connected.",
    icon: loginIcon,
    iconAltText: "login",
    linkTo: "/settings/office-goals/first-30-day-login",
  },
};

export function AdminGoalsSettingsPageContents(): JSX.Element {
  const [
    removeGoalConfirmationModalOpen,
    setRemoveGoalConfirmationModalOpen,
  ] = useState(false);
  const track = useTrack();

  const budgetUtilizationGoalEnabled = useFeatureFlag(
    "admin-app-budget-utilization-goal-available-temp"
  );

  const firstMonthLoginGoalEnabled = useFeatureFlag(
    "admin-app-first-month-login-goal-available-temp"
  );

  const recurringGoalConfigEnabled = useFeatureFlag(
    "admin-app-recurring-goal-config-temp"
  );

  const adminGoalsSettingsQuery = useAdminGoalsSettingsPageDataQuery({
    onError: reportError,
  });

  const availableGoalTypes: AdminGoalTypeSupportedByUI[] = ADMIN_GOAL_TYPES_SUPPORTED_BY_UI.filter(
    // eslint-disable-next-line array-callback-return
    (goalType) => {
      switch (goalType) {
        case AdminGoalType.BudgetUtilization:
          return budgetUtilizationGoalEnabled;
        case AdminGoalType.LoginAfterLaunch: {
          // For this goal type, the feature flag needs to be enabled
          // and the organization needs to have launched less than 30 days ago
          const organizationLaunchedAt =
            adminGoalsSettingsQuery?.data?.organization?.launchedAt;

          return (
            firstMonthLoginGoalEnabled &&
            organizationLaunchedAt &&
            differenceInDays(new Date(), organizationLaunchedAt) < 30
          );
        }
        // add other goal types here
        default:
          assertNever(goalType);
      }
    }
  );

  useEffect(() => {
    track("Viewed admin goals settings page", {
      availableGoalTypes: availableGoalTypes,
    });
  }, [track, availableGoalTypes]);

  if (adminGoalsSettingsQuery.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (adminGoalsSettingsQuery.loading || !adminGoalsSettingsQuery.data) {
    return (
      <div
        css={css`
          height: 200px;
        `}
      >
        <PageLoadingState />
      </div>
    );
  }

  if (availableGoalTypes.length === 0) {
    // The page should only be visible if they have available goal types,
    // so this would only happen if they navigate to the route directly.
    return (
      <Alert
        severity="info"
        message="There are no goals available to configure at this time."
      />
    );
  }

  const {
    getCurrentAdminGoalConfigs: existingConfigs,
  } = adminGoalsSettingsQuery.data;

  return (
    <>
      <Typography
        variant="h5"
        fontWeight={700}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        Office goals
      </Typography>
      <Typography
        variant="footnote"
        color="grey.800"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        Set-up goals for your office staff around key metrics for your agency.
        This helps ensure your office staff is aligned to your company
        strategies and driving key outcomes with the frontline staff.
      </Typography>
      <Typography
        variant="h6"
        fontWeight={700}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        Select one goal to start with
      </Typography>
      <div>
        {availableGoalTypes.map((goalType, idx) => {
          const goalConfigContent = GOAL_CONFIG_CONTENT[goalType];
          const existingConfig = existingConfigs.find(
            (c) => c.goalType === goalType
          );
          const startDate = existingConfig
            ? formatInTimeZone(existingConfig.startDate, "UTC", "MMM d")
            : null;
          const endDate = existingConfig
            ? formatInTimeZone(
                subDays(new Date(existingConfig.endDate), 1),
                "UTC",
                "MMM d"
              )
            : null;

          if (
            existingConfig?.target?.__typename ===
              "AdminGoalTargetTimeToFirstShift" ||
            existingConfig?.recurringTarget?.__typename ===
              "AdminGoalTargetTimeToFirstShift"
          ) {
            // TODO: handle time to first shift
            return null;
          }

          const hasCustomRecurringSettings =
            recurringGoalConfigEnabled &&
            existingConfig &&
            existingConfig.recurring &&
            (xor(
              existingConfig.admins.map((a) => a.id),
              existingConfig.recurringAdmins?.map((a) => a.id) ?? []
            ).length > 0 ||
              existingConfig.rewards.dollarValue !==
                existingConfig?.recurringRewards?.dollarValue ||
              existingConfig?.target?.percentage !==
                existingConfig?.recurringTarget?.percentage) &&
            startDate &&
            endDate;

          const getEditMode = () => {
            if (!existingConfig?.recurring) {
              return "current";
            }

            return hasCustomRecurringSettings
              ? "current"
              : "current-and-recurring";
          };

          return (
            <Card
              key={`${goalType}-${idx}`}
              variant="flat"
              css={(theme: AppTheme) => css`
                padding: ${theme.spacing(3)};
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <div>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <img
                      width={30}
                      src={goalConfigContent.icon}
                      alt={goalConfigContent.iconAltText}
                    />
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      color="textPrimary"
                      css={(theme: AppTheme) => css`
                        margin-left: ${theme.spacing(2)};
                      `}
                    >
                      {goalConfigContent.label}
                    </Typography>

                    {recurringGoalConfigEnabled && existingConfig?.enabled && (
                      <Tooltip title={goalConfigContent.description}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="lg"
                          css={(theme: AppTheme) => css`
                            margin-left: ${theme.spacing(1)};
                            padding-top: 5px;
                          `}
                        />
                      </Tooltip>
                    )}

                    {recurringGoalConfigEnabled &&
                      existingConfig?.enabled &&
                      existingConfig?.recurring && (
                        <div
                          css={(theme: AppTheme) => css`
                            display: flex;
                            align-items: center;
                            background-color: ${theme.palette.success.main};
                            padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
                            border-radius: 100px;
                            margin-left: ${theme.spacing(1)};
                          `}
                        >
                          <FontAwesomeIcon
                            icon={faRepeat}
                            width={20}
                            color="white"
                          />
                          <Typography
                            variant="caption"
                            color="white"
                            textTransform="uppercase"
                            css={(theme: AppTheme) => css`
                              margin-left: ${theme.spacing(0.5)};
                            `}
                          >
                            REPEATS MONTHLY
                          </Typography>
                        </div>
                      )}

                    {recurringGoalConfigEnabled &&
                      existingConfig?.enabled &&
                      !existingConfig?.recurring && (
                        <div
                          css={(theme: AppTheme) => css`
                            display: flex;
                            align-items: center;
                            background-color: ${theme.palette.success.main};
                            padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
                            border-radius: 100px;
                            margin-left: ${theme.spacing(1)};
                          `}
                        >
                          <FontAwesomeIcon
                            icon={faCircleStop}
                            width={20}
                            color="white"
                          />
                          <Typography
                            variant="caption"
                            color="white"
                            textTransform="uppercase"
                            css={(theme: AppTheme) => css`
                              margin-left: ${theme.spacing(0.5)};
                            `}
                          >
                            ENDS ON {endDate}
                          </Typography>
                        </div>
                      )}
                  </div>
                  {(!recurringGoalConfigEnabled ||
                    !existingConfig?.enabled) && (
                    <Typography
                      variant="footnote"
                      color="grey.800"
                      maxWidth={543}
                      css={(theme: AppTheme) => css`
                        margin-top: ${theme.spacing(1)};
                      `}
                    >
                      {goalConfigContent.description}
                    </Typography>
                  )}

                  {recurringGoalConfigEnabled && existingConfig?.launched && (
                    <PeriodSection
                      type="current"
                      startDate={startDate ?? ""}
                      endDate={endDate ?? ""}
                      rewardAmount={existingConfig?.rewards.dollarValue}
                      targetPercentage={existingConfig?.target.percentage}
                    />
                  )}

                  {recurringGoalConfigEnabled &&
                    existingConfig?.enabled &&
                    !existingConfig?.launched && (
                      <PeriodSection
                        type="next"
                        startDate={startDate ?? ""}
                        endDate={endDate ?? ""}
                        rewardAmount={existingConfig?.rewards.dollarValue}
                        targetPercentage={existingConfig?.target.percentage}
                      />
                    )}
                </div>
                {!recurringGoalConfigEnabled && (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    {existingConfig && (
                      <Typography
                        variant="footnote"
                        color="grey.800"
                        css={(theme: AppTheme) => css`
                          margin-right: ${theme.spacing(1.5)};
                        `}
                      >
                        Goal period: {startDate} - {endDate}
                      </Typography>
                    )}
                    <Button
                      variant="outlined"
                      size="medium"
                      width="auto"
                      disabled={existingConfig ? true : false}
                      label={
                        existingConfig
                          ? existingConfig.launched
                            ? "Launched"
                            : "Scheduled"
                          : "Start"
                      }
                      endIcon={
                        existingConfig ? undefined : (
                          <FontAwesomeIcon icon={faArrowRight} width={13} />
                        )
                      }
                      linkTo={goalConfigContent.linkTo}
                      onClick={() =>
                        track("Clicked to start configuring admin goal", {
                          goalType,
                        })
                      }
                    />
                  </div>
                )}

                {recurringGoalConfigEnabled && (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    {!existingConfig && (
                      <Button
                        variant="outlined"
                        size="medium"
                        width="auto"
                        label="Start"
                        endIcon={
                          <FontAwesomeIcon icon={faArrowRight} width={13} />
                        }
                        linkTo={goalConfigContent.linkTo}
                        onClick={() =>
                          track("Clicked to start configuring admin goal", {
                            goalType,
                          })
                        }
                      />
                    )}

                    {existingConfig && (
                      <>
                        <Button
                          variant="outlined"
                          size="medium"
                          width="auto"
                          label="Remove"
                          onClick={() => {
                            track("Clicked to remove admin goal", {
                              goalType,
                              recurring: existingConfig?.recurring ?? false,
                            });
                            setRemoveGoalConfirmationModalOpen(true);
                          }}
                          css={(theme: AppTheme) => css`
                            margin-right: ${theme.spacing(1.5)};
                          `}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          width="auto"
                          label="Edit"
                          linkTo={`/settings/office-goals/recognition-budget/edit/${
                            existingConfig.id
                          }/${getEditMode()}`}
                          onClick={() =>
                            track("Clicked to edit admin goal", {
                              goalType,
                              recurring: existingConfig.recurring,
                              editMode: getEditMode(),
                            })
                          }
                        />
                      </>
                    )}
                  </div>
                )}
              </div>

              {hasCustomRecurringSettings && (
                <>
                  <Divider
                    css={(theme: AppTheme) => css`
                      margin-top: ${theme.spacing(2)};
                    `}
                  />
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    `}
                  >
                    <div>
                      <PeriodSection
                        type="next"
                        startDate={`${formatInTimeZone(
                          addMonths(new Date(startDate), 1),
                          "UTC",
                          "MMM d"
                        )}`}
                        endDate={`${formatInTimeZone(
                          subDays(addMonths(new Date(startDate), 2), 1),
                          "UTC",
                          "MMM d"
                        )}`}
                        rewardAmount={
                          existingConfig?.recurringRewards?.dollarValue ??
                          existingConfig?.rewards?.dollarValue
                        }
                        targetPercentage={
                          existingConfig?.recurringTarget?.percentage ??
                          existingConfig?.target?.percentage
                        }
                      />
                    </div>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        width="auto"
                        label="Edit"
                        linkTo={`/settings/office-goals/recognition-budget/edit/${existingConfig.id}/recurring`}
                        onClick={() =>
                          track("Clicked to edit recurring admin goal", {
                            goalType,
                            recurring: existingConfig.recurring,
                            editMode: "recurring",
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              <RemoveGoalConfirmationModal
                open={removeGoalConfirmationModalOpen}
                configId={existingConfig?.id ?? ""}
                onClose={() => {
                  track("Closed remove goal confirmation modal", {
                    goalType: existingConfig?.goalType,
                    recurring: existingConfig?.recurring ?? false,
                  });
                  setRemoveGoalConfirmationModalOpen(false);
                }}
                onConfirm={() => {
                  adminGoalsSettingsQuery.refetch();
                  setRemoveGoalConfirmationModalOpen(false);
                }}
                recurring={existingConfig?.recurring ?? false}
              />
            </Card>
          );
        })}
      </div>
    </>
  );
}
