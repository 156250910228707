import { ModalErrorState } from "@rewards-web/shared/components/modal/modal-error-state";
import { ModalLoadingState } from "@rewards-web/shared/components/modal/modal-loading-state";
import { serializePhoneNumber } from "@rewards-web/shared/lib/serialize-phone-number";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { RightDrawer } from "../../../../../components/right-drawer";
import { useHasPermissionQuery } from "../../../../permissions/hooks/use-has-permission-query";
import { ActiveValue } from "../../../types/typings";
import { useEmployeeErrorHandler } from "../../hooks/use-employee-error-handler";
import { EditEmployeeForm } from "./edit-employee-form";
import { useEmployeeDrawerStatsQuery } from "./employee-drawer-stats.generated";
import { useRewardsUserToEditQuery } from "./rewards-user-to-edit.generated";
import { useUpdateRewardsUserInfoMutation } from "./update-rewards-user-info.generated";

export interface EditEmployeeDrawerProps {
  open: boolean;
  userId?: string;
  onClose(): void;
  onUpdated(): void;
  onExited(): void;
  usesWorkDevices: boolean;
  integratedWithAlayaCare: boolean;
  managersEnabled: boolean;
  referralsEnabled: boolean;
  onDeactivateEmployee?(): void;
  onActivateEmployee?(): void;
  readOnly?: boolean;
}

export function EditEmployeeDrawer({
  open,
  userId,
  onClose,
  onUpdated,
  onExited,
  usesWorkDevices,
  integratedWithAlayaCare,
  managersEnabled,
  referralsEnabled,
  onDeactivateEmployee,
  onActivateEmployee,
  readOnly: readOnlyProp = false,
}: EditEmployeeDrawerProps): JSX.Element {
  const fullEmployeePermissionsQuery = useHasPermissionQuery(
    "full",
    "employees"
  );

  const readOnly = readOnlyProp || !fullEmployeePermissionsQuery.hasPermission;

  const track = useTrack();
  const snackbar = useSnackbar();
  const handleEmployeeError = useEmployeeErrorHandler();

  const rewardsUserToEditQuery = useRewardsUserToEditQuery({
    variables: {
      userId: userId!,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !userId,
    onError: reportError,
  });

  const statsQuery = useEmployeeDrawerStatsQuery({
    variables: {
      userId: userId!,
      referralsEnabled,
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    skip: !userId,
    onError: reportError,
  });

  const [
    updateRewardsUserInfo,
    { loading: updatingRewardsUserInfo },
  ] = useUpdateRewardsUserInfoMutation();

  const user = rewardsUserToEditQuery.data?.getRewardsUserById;
  const previousUserActive =
    rewardsUserToEditQuery.data?.getRewardsUserById?.active;

  const handleClose = () => {
    onClose();
  };

  const modalChildren = (() => {
    if (rewardsUserToEditQuery.loading) {
      return <ModalLoadingState width="470px" />;
    }

    if (rewardsUserToEditQuery.error || !user) {
      return <ModalErrorState onClose={onClose} />;
    }

    return (
      <EditEmployeeForm
        usesWorkDevices={usesWorkDevices}
        integratedWithAlayaCare={integratedWithAlayaCare}
        managersEnabled={managersEnabled}
        statsQuery={statsQuery}
        onClose={handleClose}
        readOnly={readOnly}
        onSubmit={async (values) => {
          try {
            const res = await updateRewardsUserInfo({
              variables: {
                userId: user.id,
                workEmail: values.workEmail || undefined,
                workPhoneNumber: values.workPhoneNumber
                  ? serializePhoneNumber(values.workPhoneNumber)
                  : null,
                email:
                  user.personalContactInfo?.__typename ===
                  "RewardsUserPersonalContactInfoSuppressed"
                    ? undefined
                    : values.personalEmail || undefined,
                phoneNumber:
                  user.personalContactInfo?.__typename ===
                  "RewardsUserPersonalContactInfoSuppressed"
                    ? undefined
                    : values.personalPhoneNumber
                    ? serializePhoneNumber(values.personalPhoneNumber)
                    : undefined,
                branchId: values.branchId || null,
                active: integratedWithAlayaCare
                  ? undefined // it will fail if `active` is passed while integrated with alayacare
                  : values.active === ActiveValue.EMPLOYEE_ACTIVE
                  ? true
                  : false,
                managerId: values.managerId || null,
                referralLinkDisabled: values.disableReferral,
                employmentStartDate: values.employmentStartDate || null,
                dateOfBirth: values.dateOfBirth || null,
              },
            });
            snackbar.show({
              severity: "success",
              message: "The employee's info was successfully updated.",
            });
            handleClose();
            onUpdated();
            track("Rewards user edited", {
              requestId: (res.context as ApolloGraphQLResponseContext)
                .requestId,
              rewardsUserId: res.data?.updateRewardsUserInformation.id,
            });

            if (
              previousUserActive &&
              values.active === ActiveValue.EMPLOYEE_DE_ACTIVATED
            ) {
              onDeactivateEmployee?.();
            } else if (
              !previousUserActive &&
              values.active === ActiveValue.EMPLOYEE_ACTIVE
            ) {
              onActivateEmployee?.();
            }
          } catch (error) {
            handleEmployeeError(error);
          }
        }}
        user={user}
      />
    );
  })();

  return (
    <RightDrawer
      open={open}
      title={readOnly ? "View Details" : "Edit Details"}
      onClose={handleClose}
      onExited={onExited}
      disableClose={updatingRewardsUserInfo}
    >
      {modalChildren}
    </RightDrawer>
  );
}
