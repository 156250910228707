/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { DataTable } from "../../../../../../shared/components/data-table";
import {
  formatTransactionCategory,
  formatTransactionDescription,
} from "../lib";
import { TransactionDescriptionText } from "../transaction-description-text";
import { TransactionMetadataFragmentFragment } from "../transaction-metadata-fragment.generated";

interface EmployeeDetailTicketsTransactionsTableProps {
  items:
    | Array<{
        awardedAt: number;
        numTickets: number;
        metadata: TransactionMetadataFragmentFragment;
      }>
    | undefined;
  total: number | undefined;
  itemsPerPage: number;
  loading: boolean;
  drawsEnabled: boolean | undefined;
  rewardsProgramShortName: string | undefined;
  launched: boolean | undefined;
}

export function EmployeeDetailTicketsTransactionsTable({
  items,
  total,
  itemsPerPage,
  loading,
  drawsEnabled,
  rewardsProgramShortName,
  launched,
}: EmployeeDetailTicketsTransactionsTableProps) {
  return (
    <DataTable
      paddingDisabled
      items={items}
      total={total}
      itemsPerPage={itemsPerPage}
      emptyStateHeight="150px"
      emptyText={
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: ${theme.spacing(2)};
            height: 100%;
            max-width: 700px;
            margin: 0 auto;
            text-align: center;
          `}
        >
          {drawsEnabled ? (
            <>
              <Typography variant="subtitle" color="textPrimary">
                No tickets earned yet
              </Typography>
              {launched ? (
                <Typography variant="body" color="grey.800">
                  Remind this employee to use{" "}
                  {rewardsProgramShortName ?? "Caribou Rewards"} and earn
                  rewards for achieving goals, hitting milestones, completing
                  surveys, and more.
                </Typography>
              ) : (
                <Typography variant="body" color="grey.800">
                  This employee can start earning after the rewards program is
                  launched.
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography variant="subtitle" color="textPrimary">
                Draws are not enabled for your agency
              </Typography>
              <Typography variant="body" color="grey.800">
                Reward at scale with draws—easily create company-wide giveaways
                with multi-tier prizes for multiple winners. Want to learn more?
                Contact your Customer Success Manager.
              </Typography>
            </>
          )}
        </div>
      }
      loading={loading}
      tableHeaders={
        <TableRow>
          <TableHeader
            divider
            css={css`
              width: 15%;
              min-width: 156px;
            `}
          >
            <Typography variant="subtitle" color="textPrimary" fontSize="16px">
              Transaction date
            </Typography>
          </TableHeader>
          <TableHeader
            divider
            css={css`
              width: 12%;
            `}
          >
            <Typography variant="subtitle" color="textPrimary" fontSize="16px">
              Tickets
            </Typography>
          </TableHeader>
          <TableHeader
            divider
            css={css`
              width: 15%;
            `}
          >
            <Typography variant="subtitle" color="textPrimary" fontSize="16px">
              Category
            </Typography>
          </TableHeader>
          <TableHeader
            divider
            css={css`
              width: 58%;
            `}
          >
            <Typography variant="subtitle" color="textPrimary" fontSize="16px">
              Description
            </Typography>
          </TableHeader>
        </TableRow>
      }
      tableBody={items?.map((transaction, index) => (
        <TableRow>
          <TableCell divider={index < items.length - 1}>
            <Typography variant="body" color="grey.800">
              {new Date(transaction.awardedAt).toLocaleDateString(undefined, {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </Typography>
          </TableCell>
          <TableCell divider>
            <Typography variant="body" color={"grey.800"}>
              {numberWithCommas(transaction.numTickets)}
            </Typography>
          </TableCell>
          <TableCell divider>
            <Typography variant="body" color="grey.800">
              {formatTransactionCategory(transaction.metadata.category)}
            </Typography>
          </TableCell>
          <TableCell divider>
            <Typography variant="body" color="grey.800">
              <TransactionDescriptionText
                description={formatTransactionDescription(transaction.metadata)}
              />
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    />
  );
}
