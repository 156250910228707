import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { EvvRulesClockMethod } from "@rewards-web/shared/graphql-types";

import { EVVRulesClockMethods, EVVRulesFormValues } from "..";

export type EvvRules = {
  minutesLessThanScheduledStart?: number | null;
  minutesGreaterThanScheduledStart?: number | null;
  minutesLessThanScheduledEnd?: number | null;
  minutesGreaterThanScheduledEnd?: number | null;
  minutesLessThanScheduledDuration?: number | null;
  minutesGreaterThanScheduledDuration?: number | null;
  selectedClockMethods: EVVRulesFormValues["selectedClockMethods"];
};

export interface EVVTrackPageEVVRulesProps {
  evvRulesLoading: boolean;
  evvRules?: EvvRules;
}

export function EVVTrackPageEVVRules({
  evvRulesLoading,
  evvRules,
}: EVVTrackPageEVVRulesProps) {
  if (evvRulesLoading || !evvRules) {
    return (
      <ul>
        <li>
          <Skeleton width="80%" height={28} />
        </li>
        <li>
          <Skeleton width="75%" height={28} />
        </li>
        <li>
          <Skeleton width="90%" height={28} />
        </li>
        <li>
          <Skeleton width="60%" height={28} />
        </li>
      </ul>
    );
  }

  const {
    minutesLessThanScheduledStart,
    minutesGreaterThanScheduledStart,
    minutesLessThanScheduledEnd,
    minutesGreaterThanScheduledEnd,
    minutesLessThanScheduledDuration,
    minutesGreaterThanScheduledDuration,
    selectedClockMethods,
  } = evvRules || {};

  const clockInContent = (() => {
    if (
      typeof minutesLessThanScheduledStart === "number" &&
      typeof minutesGreaterThanScheduledStart === "number" &&
      minutesLessThanScheduledStart === minutesGreaterThanScheduledStart
    ) {
      return (
        <li>
          <strong>Clock in</strong> within {minutesGreaterThanScheduledStart}
          {minutesGreaterThanScheduledStart > 1 ? " minutes" : " minute"} of the
          scheduled start time.
        </li>
      );
    } else if (
      typeof minutesLessThanScheduledStart !== "number" &&
      typeof minutesGreaterThanScheduledStart === "number"
    ) {
      return (
        <li>
          <strong>Clock in</strong> no more than{" "}
          {minutesGreaterThanScheduledStart}
          {minutesGreaterThanScheduledStart > 1 ? " minutes" : " minute"} after
          the scheduled start time.
        </li>
      );
    } else if (
      typeof minutesLessThanScheduledStart === "number" &&
      typeof minutesGreaterThanScheduledStart !== "number"
    ) {
      return (
        <li>
          <strong>Clock in</strong> no more than {minutesLessThanScheduledStart}
          {minutesLessThanScheduledStart > 1 ? " minutes" : " minute"} before
          the scheduled start time.
        </li>
      );
    } else if (
      typeof minutesLessThanScheduledStart === "number" &&
      typeof minutesGreaterThanScheduledStart === "number" &&
      minutesLessThanScheduledStart !== minutesGreaterThanScheduledStart
    ) {
      return (
        <li>
          <strong>Clock in</strong> no more than {minutesLessThanScheduledStart}
          {minutesLessThanScheduledStart > 1 ? " minutes" : " minute"} before
          the scheduled start time or more than{" "}
          {minutesGreaterThanScheduledStart}
          {minutesGreaterThanScheduledStart > 1 ? " minutes" : " minute"} after
          the scheduled start time.
        </li>
      );
    }
  })();

  const clockOutContent = (() => {
    if (
      typeof minutesLessThanScheduledEnd === "number" &&
      typeof minutesGreaterThanScheduledEnd === "number" &&
      minutesLessThanScheduledEnd === minutesGreaterThanScheduledEnd
    ) {
      return (
        <li>
          <strong>Clock out</strong> within {minutesGreaterThanScheduledEnd}
          {minutesGreaterThanScheduledEnd > 1 ? " minutes" : " minute"} of the
          scheduled end time.
        </li>
      );
    } else if (
      typeof minutesLessThanScheduledEnd !== "number" &&
      typeof minutesGreaterThanScheduledEnd === "number"
    ) {
      return (
        <li>
          <strong>Clock out</strong> no more than{" "}
          {minutesGreaterThanScheduledEnd}
          {minutesGreaterThanScheduledEnd > 1 ? " minutes" : " minute"} after
          the scheduled end time.
        </li>
      );
    } else if (
      typeof minutesLessThanScheduledEnd === "number" &&
      typeof minutesGreaterThanScheduledEnd !== "number"
    ) {
      return (
        <li>
          <strong>Clock out</strong> no more than {minutesLessThanScheduledEnd}
          {minutesLessThanScheduledEnd > 1 ? " minutes" : " minute"} before the
          scheduled end time.
        </li>
      );
    } else if (
      typeof minutesLessThanScheduledEnd === "number" &&
      typeof minutesGreaterThanScheduledEnd === "number" &&
      minutesLessThanScheduledEnd !== minutesGreaterThanScheduledEnd
    ) {
      return (
        <li>
          <strong>Clock out</strong> no more than {minutesLessThanScheduledEnd}
          {minutesLessThanScheduledEnd > 1 ? " minutes" : " minute"} before the
          scheduled end time or more than {minutesGreaterThanScheduledEnd}
          {minutesGreaterThanScheduledEnd > 1 ? " minutes" : " minute"} after
          the scheduled end time.
        </li>
      );
    }
  })();
  const visitDurationContent = (() => {
    if (
      typeof minutesLessThanScheduledDuration === "number" &&
      typeof minutesGreaterThanScheduledDuration === "number" &&
      minutesLessThanScheduledDuration === 0 &&
      minutesGreaterThanScheduledDuration === 0
    ) {
      return (
        <li>
          <strong>Visit duration</strong> must exactly match the scheduled visit
          duration
        </li>
      );
    } else if (
      typeof minutesLessThanScheduledDuration === "number" &&
      typeof minutesGreaterThanScheduledDuration === "number" &&
      minutesLessThanScheduledDuration ===
        minutesGreaterThanScheduledDuration &&
      minutesLessThanScheduledDuration !== 0
    ) {
      return (
        <li>
          <strong>Visit duration</strong> must be within{" "}
          {minutesGreaterThanScheduledDuration}
          {minutesGreaterThanScheduledDuration > 1 ? " minutes" : " minute"} of
          the scheduled visit duration
        </li>
      );
    } else if (
      minutesLessThanScheduledDuration === 0 &&
      minutesGreaterThanScheduledDuration !== 0
    ) {
      // Case: Lower bound is 0
      return (
        <li>
          <strong>Visit duration</strong> cannot be less than the scheduled
          visit duration.
        </li>
      );
    } else if (
      minutesGreaterThanScheduledDuration === 0 &&
      minutesLessThanScheduledDuration !== 0
    ) {
      /* Case: Upper bound is 0 */
      return (
        <li>
          <strong>Visit duration</strong> cannot exceed the scheduled visit
          duration.
        </li>
      );
    } else if (
      typeof minutesLessThanScheduledDuration === "number" &&
      typeof minutesGreaterThanScheduledDuration === "number" &&
      minutesLessThanScheduledDuration !==
        minutesGreaterThanScheduledDuration &&
      minutesLessThanScheduledDuration !== 0 &&
      minutesGreaterThanScheduledDuration !== 0
    ) {
      return (
        <li>
          <strong>Visit duration</strong> cannot be less than{" "}
          {minutesLessThanScheduledDuration}
          {minutesLessThanScheduledDuration > 1 ? " minutes" : " minute"} of the
          scheduled visit duration and cannot exceed{" "}
          {minutesGreaterThanScheduledDuration}
          {minutesGreaterThanScheduledDuration > 1
            ? " minutes"
            : " minute"}{" "}
          beyond the scheduled duration.
        </li>
      );
    } else if (
      typeof minutesLessThanScheduledDuration !== "number" &&
      typeof minutesGreaterThanScheduledDuration === "number"
    ) {
      /* Case: Visit can only be longer */
      return (
        <li>
          <strong>Visit duration</strong> cannot exceed{" "}
          {minutesGreaterThanScheduledDuration}
          {minutesGreaterThanScheduledDuration > 1
            ? " minutes"
            : " minute"}{" "}
          beyond the scheduled visit duration.
        </li>
      );
    } else if (
      typeof minutesLessThanScheduledDuration === "number" &&
      typeof minutesGreaterThanScheduledDuration !== "number"
    ) {
      /* Case: Visit can only be shorter */

      return (
        <li>
          <strong>Visit duration</strong> cannot be less than{" "}
          {minutesLessThanScheduledDuration}
          {minutesLessThanScheduledDuration > 1 ? " minutes" : " minute"} of the
          scheduled visit duration.
        </li>
      );
    }
  })();
  const validClockMethodsContent = (() => {
    const clockMethodString = getClockInMethodString(selectedClockMethods);

    if (clockMethodString) {
      return (
        <li>
          Clock in and out using the <strong>{clockMethodString}</strong>.
        </li>
      );
    }
  })();
  return (
    <ul>
      {/* Clock-In Timeliness */}
      {clockInContent}
      {/* Clock-Out Timeliness */}
      {clockOutContent}
      {/* Visit Duration */}
      {visitDurationContent}
      {/* Valid Clock Methods */}
      {validClockMethodsContent}
    </ul>
  );
}

function getClockInMethodString(
  selectedClockMethods: EVVRulesFormValues["selectedClockMethods"]
) {
  const clockMethodToStringMapping = {
    [EvvRulesClockMethod.MobileApp]: "mobile app",
    [EvvRulesClockMethod.Ivr]: "client's home phone",
    [EvvRulesClockMethod.Fob]: "FOB",
  };

  const selectedClockMethodStrings = Object.keys(selectedClockMethods)
    .filter(
      (clockMethod): clockMethod is EVVRulesClockMethods =>
        selectedClockMethods[clockMethod as EVVRulesClockMethods]
    )
    .map((method) => clockMethodToStringMapping[method]);

  if (selectedClockMethodStrings.length === 0) {
    return null;
  }

  if (selectedClockMethodStrings.length === 1) {
    return selectedClockMethodStrings[0];
  }

  return `${selectedClockMethodStrings.slice(0, -1).join(", ")} or ${
    selectedClockMethodStrings[selectedClockMethodStrings.length - 1]
  }`;
}
