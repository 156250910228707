/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { format } from "date-fns";
import { useState } from "react";
import { Controller, get, useForm, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { CardContent } from "@rewards-web/shared/components/card";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import {
  SelectField,
  ValueLabel,
} from "@rewards-web/shared/components/select-field";
import { BaseSocialPostCard } from "@rewards-web/shared/components/social-post-card/base-social-post-card";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { ReactionType } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag/hooks";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PageCard } from "../../../shared/components/page-card";
import { useMomentsPageDataQuery } from "./moments-page-data.generated";
import { usePublishCustomSocialPostMutation } from "./publish-custom-social-post.generated";

const MAX_POST_CHARACTERS_LIMIT = 5_000;

export interface CustomSocialPostFormValues {
  messageText: string;
  branchId: string;
}

export function MomentsPageContents() {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const query = useMomentsPageDataQuery({
    onError: reportError,
  });
  const [
    publishCustomSocialPost,
    { loading: publishCustomSocialPostLoading },
  ] = usePublishCustomSocialPostMutation();

  const momentsByBranchEnabled = useFeatureFlag(
    "admin-app-moments-by-branch-temp"
  );

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { isSubmitting, isValid, isDirty, errors },
    control,
  } = useForm<CustomSocialPostFormValues>({
    mode: "onChange",
    defaultValues: {
      messageText: "",
      branchId: "org-wide",
    },
  });

  const messageTextValue = useWatch({
    control,
    name: "messageText",
  });

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const currentTime = new Date();

  const branchOptions = (
    query.data?.getMyRewardsOrganization?.branches ?? []
  ).map(
    (branch): ValueLabel => ({
      value: branch.id,
      label: `${branch.name} (${branch.activeUserCount})`,
    })
  );

  return (
    <>
      <Typography variant="h3" color="textPrimary" gutterBottom>
        Moments
      </Typography>

      <Modal
        width="550px"
        open={confirmationModalOpen}
        onClose={closeConfirmationModal}
      >
        <ModalTitle>{"Share this post?"}</ModalTitle>

        <ModalContent>
          <Typography>
            {
              "Once posted, this content will be live on the social feed and cannot be removed. Would you like to continue?"
            }
          </Typography>
        </ModalContent>

        <ModalActions>
          <Button
            label="Cancel"
            variant="outlined"
            onClick={closeConfirmationModal}
          />

          <Button
            color={"primary"}
            label="Share post"
            type="submit"
            loading={publishCustomSocialPostLoading}
            onClick={async () => {
              const formBranchId = getValues("branchId");
              const branchId =
                formBranchId === "org-wide" ? null : formBranchId;

              try {
                const response = await publishCustomSocialPost({
                  variables: {
                    messageText: messageTextValue,
                    branchId: momentsByBranchEnabled ? branchId : undefined,
                  },
                });
                snackbar.show({
                  severity: "success",
                  message: "You post has been successfully shared.",
                });
                track("Admin published custom social post", {
                  postId: response.data?.publishCustomSocialPost.id,
                });
                reset(); // reset the form for next post
              } catch (e) {
                reportError(e);
                snackbar.show({
                  severity: "error",
                  message:
                    "Your post could not be shared. Please try again later.",
                });
              } finally {
                closeConfirmationModal();
              }
            }}
          />
        </ModalActions>
      </Modal>

      <Form
        submitting={isSubmitting}
        onSubmit={handleSubmit(() => setConfirmationModalOpen(true))}
      >
        <PageCard>
          <CardContent
            css={(theme: AppTheme) => css`
              padding-left: ${theme.spacing(3)};
            `}
          >
            <Typography
              css={(theme: AppTheme) => css`
                padding-bottom: ${theme.spacing(2)};
              `}
              color="textPrimary"
              variant="h5"
            >
              Create a post
            </Typography>

            <Typography
              css={(theme: AppTheme) => css`
                padding-bottom: ${theme.spacing(2)};
              `}
              color="grey.800"
              variant="body"
            >
              {
                "Share updates, celebrate achievements, or encourage your team! Once posted, your message will appear on the employee feed for everyone to see. Posts cannot be deleted after they are shared."
              }
            </Typography>

            {momentsByBranchEnabled &&
              (query.data?.getMyRewardsOrganization?.branches.length ?? 0) >
                0 && (
                <Controller
                  control={control}
                  name={"branchId"}
                  render={({ field }) => (
                    <SelectField
                      label="Audience"
                      options={[
                        {
                          value: "org-wide",
                          label: "Everyone",
                        },
                        ...branchOptions,
                      ]}
                      width="full"
                      css={css`
                        max-width: 200px;
                      `}
                      {...field}
                    />
                  )}
                />
              )}

            <TextField
              type="textarea"
              label="Post"
              placeholder="Type something..."
              size="large"
              autoFocus
              hideLabel
              disableAutocomplete
              minRows={3}
              error={get(errors, "messageText")}
              {...register("messageText", {
                required: true,
                validate: (value) => {
                  if (value.trim().length > MAX_POST_CHARACTERS_LIMIT) {
                    return `Post message must be less than ${MAX_POST_CHARACTERS_LIMIT} characters`;
                  }
                },
              })}
            />

            <div
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <Button
                size="large"
                color="primary"
                type="submit"
                label="Post on feed"
                width="auto"
                disabled={!isDirty || isSubmitting || !isValid}
              />
            </div>
          </CardContent>
        </PageCard>
      </Form>

      <div
        css={(theme: AppTheme) => css`
          margin: ${theme.spacing(3)} auto;
        `}
      >
        <Typography variant="h4" color="textPrimary" gutterBottom>
          Post preview
        </Typography>
      </div>

      <BaseSocialPostCard
        data={
          messageTextValue.length > 0
            ? {
                logoUrl:
                  query.data?.getMyRewardsOrganization?.logoImageUrl ?? null,
                publishedByText:
                  query.data?.getMyRewardsOrganization?.shortName ?? "",
                publishedAtText: `Today at ${format(
                  currentTime,
                  "h:mma"
                ).toLowerCase()}`,
                tagText: "Announcement",
                tagColor: "primary",
                messageText: messageTextValue,
                image: null,
                postReactions: [
                  {
                    type: ReactionType.Love,
                    numReactions: 25,
                  },
                  {
                    type: ReactionType.Applaud,
                    numReactions: 37,
                  },
                  {
                    type: ReactionType.Celebrate,
                    numReactions: 12,
                  },
                ],
                myReactions: [
                  {
                    type: ReactionType.Love,
                    numReactions: 0,
                  },
                  {
                    type: ReactionType.Applaud,
                    numReactions: 0,
                  },
                  {
                    type: ReactionType.Celebrate,
                    numReactions: 0,
                  },
                ],
                onMyReactionChange: () => {},
                alwaysExpanded: true,
                showMoreText: "Show more",
              }
            : null
        }
        skeletonProps={{
          animated: false,
        }}
      />
    </>
  );
}
