/** @jsxImportSource @emotion/react */
import { ApolloError } from "@apollo/client";
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageCard } from "../../../../../../shared/components/page-card";
import { SettingsOrganizationTimezoneField } from "../../../../../../shared/modules/settings/fields/settings-organization-timezone-field";
import {
  TAG_CONFIG_RIGHT_ADORNMENT_WIDTH,
  TagsConfigField,
} from "../../../../employees/groups/tags-config-field";
import { getTagOption } from "../../../../employees/groups/tags-selector-field";
import { BranchNameField } from "../fields/branch-name-field";
import { BranchFormValues, tagConfigFormValueToGraphQLInput } from "../utils";
import { useUpdateBranchMutation } from "./update-branch-mutation.generated";
import {
  UpdateBranchDataQuery,
  useUpdateBranchDataQuery,
} from "./update-branch-query.generated";

const MAX_WIDTH = 900;

interface UpdateBranchPageContentsProps {
  branchId: string;
}

const DEFAULT_VALUES = {
  name: "",
  tagsCombinations: [],
  timezone: "",
};
type BranchType = UpdateBranchDataQuery["getMyRewardsOrganizationBranch"];

export function UpdateBranchPageContents({
  branchId,
}: UpdateBranchPageContentsProps) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setError,
    watch,
  } = useForm<BranchFormValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const { name: branchName, tagsCombinations } = watch();
  const snackbar = useSnackbar();
  const track = useTrack();

  const navigate = useNavigate();

  const trackingProperties = useMemo(
    () => ({
      branchId,
      branchName,
      tagCombinations: tagConfigFormValueToGraphQLInput(tagsCombinations),
    }),
    [branchId, branchName, tagsCombinations]
  );

  const [updateBranch] = useUpdateBranchMutation();

  // Reset the form to use branch values once query loads
  const { data, loading, error } = useUpdateBranchDataQuery({
    variables: { branchId },
    onError: reportError,
    onCompleted: (result) => {
      const branch = result.getMyRewardsOrganizationBranch;
      reset({
        name: branch.name,
        tagsCombinations: getTagCombinations(branch.autoMembershipTagCriteria),
        timezone: branch.timezone ?? "",
      });
    },
  });

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!data) {
    return <PageLoadingState />;
  }

  const onSubmit = async (values: BranchFormValues) => {
    try {
      await updateBranch({
        variables: {
          branchId,
          name: values.name,
          tagConfig: tagConfigFormValueToGraphQLInput(values.tagsCombinations),
          timezone: values.timezone,
        },
      });
      snackbar.show({
        severity: "success",
        message: `Success! "${branchName}" has been saved`,
      });
      track("Updated branch successfully", trackingProperties);
      navigate("/settings/branches");
    } catch (error) {
      reportError(error);
      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.message === "Branch name already exists"
      ) {
        setError("name", {
          type: "unique",
          message: "Branch name already exists",
        });
        return;
      }
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    }
  };

  return (
    <div css={{ width: `${MAX_WIDTH}px` }}>
      <Form
        onSubmit={handleSubmit(onSubmit, (errors) =>
          track("Update branch submitted but user input had errors", {
            errors,
            ...trackingProperties,
          })
        )}
        submitting={isSubmitting}
      >
        <Button
          variant="text"
          startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          label="Back"
          width="auto"
          typographyVariant="body"
          linkTo="/settings/branches"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        />
        <Typography variant="h5">Edit branch</Typography>
        <Typography
          color="textSecondary"
          variant="footnote"
          css={(theme: AppTheme) =>
            css`
              margin-top: ${theme.spacing(1)};
            `
          }
        >
          Select user tags to include within this branch. Tagged users will be
          added to this branch in {data.getMyRewardsOrganization.shortName}.
          <br />
          Branches can be edited anytime but not deleted.
        </Typography>
        <PageCard
          css={(theme: AppTheme) => css`
            margin: ${theme.spacing(3.75)} 0;
            padding: ${theme.spacing(3.75)};
          `}
        >
          <Typography
            variant="h6"
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(2)};
              `
            }
          >
            Branch details
          </Typography>
          <BranchNameField control={control} name="name" />
          <div
            css={css`
              margin-right: ${TAG_CONFIG_RIGHT_ADORNMENT_WIDTH}px;
            `}
          >
            <SettingsOrganizationTimezoneField
              control={control}
              name="timezone"
              loading={loading}
            />
          </div>
          <TagsConfigField control={control} name="tagsCombinations" />
        </PageCard>
        <div
          css={(theme: AppTheme) => css`
            float: right;
            display: flex;
            gap: ${theme.spacing(1)};
            padding-bottom: ${theme.spacing(3)};
          `}
        >
          <Button
            label="Cancel"
            css={css`
              width: 150px;
            `}
            variant="outlined"
            linkTo="/settings/branches"
          />
          <Button
            label="Save"
            css={css`
              width: 150px;
            `}
            color="primary"
            type="submit"
            loading={isSubmitting}
          />
        </div>
      </Form>
    </div>
  );
}

const getTagCombinations = (
  tagsConfigSchemaV1: BranchType["autoMembershipTagCriteria"]
): BranchFormValues["tagsCombinations"] =>
  tagsConfigSchemaV1?.or.map(({ and }) =>
    and.map((tag) => getTagOption(tag))
  ) ?? [];
