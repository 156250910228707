/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faNotebook } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

import { Typography } from "@rewards-web/shared/components/typography";

import { PageCard } from "../../../../../shared/components/page-card";

export interface SurveyInfoCardProps {
  title: string;
  content: ReactNode;
}

export function SurveyInfoCard({ title, content }: SurveyInfoCardProps) {
  const theme = useTheme();
  return (
    <PageCard>
      <div
        css={css`
          max-width: 525px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          gap: ${theme.spacing(2)};
          padding: ${theme.spacing(3.5)};
        `}
      >
        <FontAwesomeIcon
          icon={faNotebook}
          size="3x"
          color={theme.palette.grey[800]}
        />
        <Typography variant="subtitle">{title}</Typography>
        {content}
      </div>
    </PageCard>
  );
}
