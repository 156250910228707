/** @jsxImportSource @emotion/react */
import { addMonths, startOfDay, startOfMonth } from "date-fns";

import { AdminGoalType } from "@rewards-web/shared/graphql-types";

import {
  AdminGoalFormValues,
  BaseGoalConfigurationForm,
} from "../../base-goal-configuration-form";
import { formatMonthPeriod } from "../../lib";

const DEFAULT_REWARD_AMOUNT = 20;
const DEFAULT_TARGET_PERCENTAGE = 100;

export function BudgetUtilizationGoalForm(): JSX.Element {
  const startOfMonthDate = startOfMonth(startOfDay(new Date()));

  const currentMonthPeriod = formatMonthPeriod(startOfMonthDate);
  const nextMonthPeriod = formatMonthPeriod(addMonths(startOfMonthDate, 1));

  const defaultValues: AdminGoalFormValues = {
    adminIds: [],
    rewardAmount: DEFAULT_REWARD_AMOUNT,
    targetPercentage: DEFAULT_TARGET_PERCENTAGE,
    startDate: startOfMonthDate,
    endDate: addMonths(startOfMonthDate, 1),
    recurring: "true",
  };

  return (
    <BaseGoalConfigurationForm
      goalType={AdminGoalType.BudgetUtilization}
      goalTitle="Recognition budget utilization goal"
      goalPeriod={currentMonthPeriod}
      nextMonthGoalPeriod={nextMonthPeriod}
      defaultValues={defaultValues}
    />
  );
}
