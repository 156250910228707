/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useNextOnboardingStepPath } from "../../hooks/use-next-step-path";
import { usePrevOnboardingStepPath } from "../../hooks/use-prev-step-path";

interface AdminLaunchStepActionsProps {
  submitButtonLabel: string;
  disableSkip?: boolean;
  disableContinue?: boolean;
  onSubmit?: () => void;
  /**
   * If defined, disables the default "Previous" button functionality
   * and invokes this function on click
   */
  onPrevious?: () => void;
}

export function AdminOnboardingStepActions({
  disableSkip,
  disableContinue,
  submitButtonLabel,
  onSubmit,
  onPrevious,
}: AdminLaunchStepActionsProps): JSX.Element {
  const prevOnboardingStepPath = usePrevOnboardingStepPath();
  const nextOnboardingStepPath = useNextOnboardingStepPath();

  return (
    <div
      css={(theme: AppTheme) => css`
        margin-top: ${theme.spacing(2)};
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          & > *:not(:last-child) {
            margin-right: ${theme.spacing(2)};
          }
        `}
      >
        <Button
          variant="outlined"
          width="auto"
          whiteBackground
          label="Previous"
          linkTo={!onPrevious ? prevOnboardingStepPath : undefined}
          onClick={onPrevious}
        />
        {!disableSkip && (
          <Button
            variant="outlined"
            width="auto"
            whiteBackground
            label="Skip for now"
            linkTo={nextOnboardingStepPath}
          />
        )}
      </div>
      <div>
        <Button
          color="primary"
          type="submit"
          label={submitButtonLabel}
          disabled={disableContinue}
          onClick={onSubmit}
          css={css`
            min-width: 100px;
          `}
        />
      </div>
    </div>
  );
}
