import { Alert } from "@rewards-web/shared/components/alert";
import { reportError } from "@rewards-web/shared/modules/error";

import { InsightMetricGroup } from "../../components/insight-metric-group";
import coinsIconUrl from "../../graphics/coins-icon.png";
import { AdminsUsingRecognitionInsightMetric } from "./insight-metrics/admins-using-recognition";
import { AwardedRecognitionInsightMetric } from "./insight-metrics/awarded-recognition";
import { BudgetUsageInsightMetric } from "./insight-metrics/budget-usage";
import { RecognizedEmployeesInsightMetric } from "./insight-metrics/recognized-employees";
import { useRecognitionInsightMetricGroupDataQuery } from "./recognition-metric-group-query.generated";

export interface RecognitionInsightMetricGroupProps {
  currentRange: {
    startDate: Date;
    endDate: Date;
  };
  previousRange: {
    startDate: Date;
    endDate: Date;
  } | null;
  pointsPerDollar: number;
}

export function RecognitionInsightMetricGroup({
  currentRange,
  previousRange,
  pointsPerDollar,
}: RecognitionInsightMetricGroupProps) {
  const skipPreviousRangeQuery = !previousRange;

  const currentRecognitionQuery = useRecognitionInsightMetricGroupDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      startDate: currentRange.startDate.valueOf(),
      endDate: currentRange.endDate.valueOf(),
    },
    onError: reportError,
  });

  const previousRecognitionQuery = useRecognitionInsightMetricGroupDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      startDate: previousRange?.startDate.valueOf(),
      endDate: previousRange?.endDate.valueOf(),
    },
    skip: skipPreviousRangeQuery,
    onError: reportError,
  });

  const queriesToValidate = [
    currentRecognitionQuery,
    ...(skipPreviousRangeQuery ? [] : [previousRecognitionQuery]),
    // no need to validate previous range queries if we skip them
  ];

  if (queriesToValidate.some((query) => query.error)) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  return (
    <InsightMetricGroup
      label="Recognition"
      icon={<img src={coinsIconUrl} width={24} alt="coins" />}
      trackingProperties={{
        startDate: currentRange.startDate,
        endDate: currentRange.endDate,
      }}
    >
      <RecognizedEmployeesInsightMetric
        currentRecognizedEmployeesInsightMetric={
          currentRecognitionQuery.data?.getRecognizedEmployeesInsightMetric
        }
        previousRecognizedEmployeesInsightMetric={
          previousRecognitionQuery.data?.getRecognizedEmployeesInsightMetric
        }
      />
      <AdminsUsingRecognitionInsightMetric
        currentAdminsUsingRecognitionInsightMetric={
          currentRecognitionQuery.data?.getAdminsUsingRecognitionInsightMetric
        }
        previousAdminsUsingRecognitionInsightMetric={
          previousRecognitionQuery.data?.getAdminsUsingRecognitionInsightMetric
        }
      />
      <AwardedRecognitionInsightMetric
        pointsPerDollar={pointsPerDollar}
        currentRecognitionPointsInsightMetric={
          currentRecognitionQuery.data?.getRecognitionAwardedInsightMetric
        }
        previousRecognitionPointsInsightMetric={
          previousRecognitionQuery.data?.getRecognitionAwardedInsightMetric
        }
      />
      <BudgetUsageInsightMetric
        currentBudgetUsageInsightMetric={
          currentRecognitionQuery.data?.getBudgetUsageInsightMetric
        }
        previousBudgetUsageInsightMetric={
          previousRecognitionQuery.data?.getBudgetUsageInsightMetric
        }
      />
    </InsightMetricGroup>
  );
}
