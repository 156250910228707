import { ReactNode } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { StylableProps } from "@rewards-web/shared/types";

interface PageCardProps extends StylableProps {
  children: ReactNode;
}

export function PageCard({ children, className }: PageCardProps) {
  return (
    <Card className={className} borderRadius={10} variant="flat">
      {children}
    </Card>
  );
}
