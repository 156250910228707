/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faEye,
  faPlus,
  faPenToSquare,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { CardContent } from "@rewards-web/shared/components/card/card-content";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsAdminPermissionsType } from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { DataTable } from "../../../../../shared/components/data-table";
import { PageCard } from "../../../../../shared/components/page-card";
import { usePermissionsQuery } from "../../../../../shared/modules/permissions/hooks/use-permissions-query";
import { useReferralStructureSettingsPageDataQuery } from "./referral-structure-settings-page-data.generated";

const NUM_ITEMS_PER_PAGE = 10;

export function ReferralStructureSettingsPageContents() {
  const navigate = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const query = useReferralStructureSettingsPageDataQuery({
    onError: reportError,
    fetchPolicy: "network-only", // don't show stale data after creating a new referral structure
  });

  const permissionsQuery = usePermissionsQuery();

  const hasFullAccessLevel =
    permissionsQuery.data?.getMyRewardsAdminUser.permissions.type ===
    RewardsAdminPermissionsType.FullAccess;

  const track = useTrack();

  const content = (() => {
    if (query.error) {
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred while loading referral structures."
        />
      );
    }

    const pointsForSharingJob = (() => {
      if (
        query.data?.getMyRewardsOrganization.referralRewardStructure
          .jobShareStructure.reward.__typename ===
        "ReferralRewardStructurePointReward"
      ) {
        return query.data?.getMyRewardsOrganization.referralRewardStructure
          .jobShareStructure.reward.pointValue;
      }

      return 0;
    })();

    const canEdit =
      hasFullAccessLevel && !query.data?.getMyRewardsOrganization.launched;

    return (
      <DataTable
        items={query.data?.getAllReferralStructures}
        total={query.data?.getAllReferralStructures.length}
        loading={!query.data}
        itemsPerPage={NUM_ITEMS_PER_PAGE}
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={setCurrentPageIndex}
        paddingDisabled
        tableHeaders={[
          <TableRow>
            <TableHeader>
              <Typography variant="subtitle">Referral name</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle">Total rewards</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle">Open jobs</Typography>
            </TableHeader>
            <TableHeader align="right">
              <Typography variant="subtitle">Action</Typography>
            </TableHeader>
          </TableRow>,
        ]}
        tableBody={query.data?.getAllReferralStructures
          ?.slice(
            currentPageIndex * NUM_ITEMS_PER_PAGE,
            (currentPageIndex + 1) * NUM_ITEMS_PER_PAGE
          )
          .map((structure, index) => {
            const showDivider =
              index !== (query.data?.getAllReferralStructures.length ?? 0) - 1;

            const totalPointsIncludingJobShares =
              structure.totalAwardablePointValue + pointsForSharingJob;

            return (
              <TableRow key={structure.id}>
                <TableCell divider={showDivider}>
                  <Typography variant="body" color="textSecondary">
                    {structure.name}
                  </Typography>
                </TableCell>
                <TableCell divider={showDivider}>
                  <Typography variant="body" color="textSecondary">
                    {numberWithCommas(totalPointsIncludingJobShares)} (
                    {typeof query.data?.getMyRewardsOrganization
                      .pointsPerDollar === "number" &&
                      formatDollars(
                        totalPointsIncludingJobShares /
                          query.data?.getMyRewardsOrganization.pointsPerDollar
                      )}
                    )
                  </Typography>
                </TableCell>
                <TableCell divider={showDivider}>
                  <Typography variant="body" color="textSecondary">
                    {structure.numberOfOpenJobsWithStructure}
                  </Typography>
                </TableCell>
                <TableCell divider={showDivider} align="right">
                  <Tooltip
                    title={
                      canEdit
                        ? "Edit referral structure"
                        : "View referral structure"
                    }
                  >
                    <IconButton
                      aria-label="View referral structure"
                      onClick={() => {
                        track("Clicked view referral structure", {
                          referralStructureId: structure.id,
                        });
                        navigate(
                          `/settings/referral-structure/view/${structure.id}`
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={canEdit ? faPenToSquare : faEye}
                        size="xs"
                        css={(theme: AppTheme) => css`
                          color: ${theme.palette.grey[800]};
                        `}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
      />
    );
  })();

  return (
    <PageCard>
      <CardContent
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
          padding-bottom: ${theme.spacing(1)};
        `}
      >
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Typography
            css={css`
              font-weight: 700;
            `}
            color="textPrimary"
            variant={"h5"}
            component="h2"
          >
            Referral structure
          </Typography>

          {hasFullAccessLevel && (
            <Button
              label="Create referral structure"
              color="primary"
              size="small"
              width="auto"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              linkTo="/settings/referral-structure/create"
              onClick={() => track("Clicked create referral structure")}
            />
          )}
        </div>
      </CardContent>

      <CardContent
        css={(theme: AppTheme) => css`
          padding: 0 ${theme.spacing(4)};
          margin-bottom: -${theme.spacing(2)};
        `}
      >
        {content}
      </CardContent>
    </PageCard>
  );
}
