/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useStartJobPromotionMutation } from "./start-job-promotion.generated";
import { useStopJobPromotionMutation } from "./stop-job-promotion.generated";

interface ConfirmPromoteModalProps {
  jobPosting: {
    jobPostingId: string;
    promoted: boolean;
    jobPostingTitle: string;
    promotionId?: string;
  } | null;
  onClose(): void;
  onSuccess(): void;
}

export function ConfirmPromoteModal({
  jobPosting,
  onClose,
  onSuccess,
}: ConfirmPromoteModalProps): JSX.Element {
  const snackbar = useSnackbar();
  const track = useTrack();
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const open = !!jobPosting;

  useEffect(() => {
    if (open) {
      trackScreenRecordingEvent("job_posting_promotion_modal_opened");
    }
  }, [open, trackScreenRecordingEvent]);

  const [
    startPromotion,
    { loading: promoting },
  ] = useStartJobPromotionMutation();
  const [
    stopPromotion,
    { loading: stoppingPromotion },
  ] = useStopJobPromotionMutation();

  const handleConfirm = async () => {
    try {
      if (jobPosting) {
        if (jobPosting?.promoted) {
          await stopPromotion({
            variables: {
              jobPostingId: jobPosting.jobPostingId,
            },
          });
          snackbar.show({
            severity: "success",
            message: "Stopped job posting promotion",
          });
          track("Stopped job posting promotion", {
            jobPostingId: jobPosting.jobPostingId,
            promotionId: jobPosting.promotionId,
          });
        } else {
          const promotionId = await startPromotion({
            variables: {
              jobPostingId: jobPosting.jobPostingId,
            },
          });
          snackbar.show({
            severity: "success",
            message: "Job successfully promoted",
          });
          track("Job posting promoted", {
            jobPostingId: jobPosting.jobPostingId,
            promotionId:
              promotionId.data?.startJobPostingPromotion.activePromotion?.id,
          });
        }

        onClose();
        onSuccess();
      } else {
        throw new Error("No job posting exists");
      }
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const submitting = promoting || stoppingPromotion;

  return (
    <Modal
      width="650px"
      open={open}
      onClose={onClose}
      disableBackdropClick={submitting}
    >
      <ModalTitle>
        {jobPosting?.promoted ? "Stop Promotion" : "Promote Job"}
      </ModalTitle>
      <ModalContent>
        <Divider
          css={(theme: AppTheme) =>
            css`
              margin-top: -${theme.spacing(1)};
              margin-bottom: ${theme.spacing(2)};
            `
          }
        />

        <Typography variant="h4">
          {jobPosting?.promoted
            ? "You are stopping promotion for "
            : "You are promoting "}
          {jobPosting?.jobPostingTitle}
        </Typography>

        <Typography
          variant="body"
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(1.5)};
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          You can draw more attention to a specific job posting by promoting it
          to staff. Promoted jobs are highlighted as a purple banner in the
          rewards employee app. If you would also like to issue recognition
          points for this promoted job referral, please contact{" "}
          <a href={`mailto:${encodeURI("help+admin@caribou.care")}`}>
            help+admin@caribou.care
          </a>
        </Typography>
      </ModalContent>

      <ModalActions>
        <Button
          width="auto"
          variant="outlined"
          disabled={submitting}
          onClick={onClose}
          label="Cancel"
        />
        <Button
          width="auto"
          loading={submitting}
          color="primary"
          onClick={handleConfirm}
          label={jobPosting?.promoted ? "Stop Promotion" : "Confirm & Promote"}
        />
      </ModalActions>
    </Modal>
  );
}
