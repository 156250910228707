/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { SwitchField } from "@rewards-web/shared/components/switch";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { useUpdateReferralsEnabledForUserMutation } from "./update-referrals-enabled-for-user.generated";

interface EmployeeDetailReferralsEnabledSwitchProps {
  userId: string;
  referralLinkDisabled: boolean;
}

export function EmployeeDetailReferralsEnabledSwitch({
  userId,
  referralLinkDisabled,
}: EmployeeDetailReferralsEnabledSwitchProps) {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [
    disableConfirmationModalOpen,
    setDisabledConfirmationModalOpen,
  ] = useState(false);
  const [
    updateReferralsEnabledForUser,
    { loading },
  ] = useUpdateReferralsEnabledForUserMutation();

  const handleEnableReferrals = async () => {
    try {
      const res = await updateReferralsEnabledForUser({
        variables: {
          userId: userId,
          referralLinkDisabled: false,
        },
      });
      snackbar.show({
        severity: "success",
        message: "You've enabled the referral link for this employee.",
      });
      track("Enabled referral link for employee", {
        userId,
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message:
          "An unexpected error occurred while updating the referral link status. Please try again later.",
      });
    }
  };

  const handleDisableReferrals = async () => {
    try {
      const res = await updateReferralsEnabledForUser({
        variables: {
          userId: userId,
          referralLinkDisabled: true,
        },
      });
      snackbar.show({
        severity: "success",
        message: "You've disabled the referral link for this employee.",
      });
      setDisabledConfirmationModalOpen(false);
      track("Disabled referral link for employee", {
        userId,
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message:
          "An unexpected error occurred while updating the referral link status. Please try again later.",
      });
    }
  };

  const label = referralLinkDisabled
    ? "Referrals disabled"
    : "Referrals enabled";

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1)};
        `}
      >
        <SwitchField
          label={label}
          hideLabel
          value={!referralLinkDisabled}
          disabled={loading}
          onChange={(_, enabled) => {
            if (enabled) {
              handleEnableReferrals();
            } else {
              setDisabledConfirmationModalOpen(true);
            }
          }}
        />
        <Typography
          variant="subtitle"
          color={referralLinkDisabled ? "grey.800" : "primary"}
        >
          {label}
        </Typography>
      </div>

      <Modal
        open={disableConfirmationModalOpen}
        onClose={() => setDisabledConfirmationModalOpen(false)}
        width="690px"
      >
        <Form onSubmit={handleDisableReferrals} submitting={loading}>
          <ModalTitle>Disable referrals for this employee?</ModalTitle>
          <ModalContent>
            <Typography variant="body" color="grey.800">
              Disabling this employee's referrals stops new applications from
              coming in & prevents job sharing. Before re-enabling, we recommend
              educating them on referring high-quality candidates.
            </Typography>
          </ModalContent>
          <ModalActions>
            <Button
              variant="outlined"
              label="Cancel"
              width="auto"
              size="large"
              onClick={() => setDisabledConfirmationModalOpen(false)}
            />
            <Button
              color="primary"
              label="Confirm"
              width="auto"
              size="large"
              loading={loading}
              onClick={handleDisableReferrals}
            />
          </ModalActions>
        </Form>
      </Modal>
    </>
  );
}
