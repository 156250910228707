import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { BranchList } from "./branch-list";

export function BranchesPage(): JSX.Element {
  return (
    <AuthorizedAdminPageWrapper resource="branches">
      <BranchList />
    </AuthorizedAdminPageWrapper>
  );
}
