import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ReferralStructureSettingsPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ReferralStructureSettingsPageDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'launched' | 'pointsPerDollar'>
    & { referralRewardStructure: (
      { __typename?: 'ReferralRewardStructure' }
      & Pick<Types.ReferralRewardStructure, 'id'>
      & { jobShareStructure: (
        { __typename?: 'ReferralRewardStructureJobShareItem' }
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' } }
      ) }
    ) }
  ), getAllReferralStructures: Array<(
    { __typename?: 'ReferralStructure' }
    & Pick<Types.ReferralStructure, 'id' | 'name' | 'totalAwardablePointValue' | 'numberOfOpenJobsWithStructure'>
  )> }
);


export const ReferralStructureSettingsPageDataDocument = gql`
    query ReferralStructureSettingsPageData {
  getMyRewardsOrganization {
    id
    launched
    pointsPerDollar
    referralRewardStructure {
      id
      jobShareStructure {
        reward {
          ... on ReferralRewardStructurePointReward {
            pointValue
          }
          ... on ReferralRewardStructureDrawTicketReward {
            numTickets
          }
        }
      }
    }
  }
  getAllReferralStructures {
    id
    name
    totalAwardablePointValue
    numberOfOpenJobsWithStructure
  }
}
    `;

/**
 * __useReferralStructureSettingsPageDataQuery__
 *
 * To run a query within a React component, call `useReferralStructureSettingsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralStructureSettingsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralStructureSettingsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralStructureSettingsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<ReferralStructureSettingsPageDataQuery, ReferralStructureSettingsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralStructureSettingsPageDataQuery, ReferralStructureSettingsPageDataQueryVariables>(ReferralStructureSettingsPageDataDocument, options);
      }
export function useReferralStructureSettingsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralStructureSettingsPageDataQuery, ReferralStructureSettingsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralStructureSettingsPageDataQuery, ReferralStructureSettingsPageDataQueryVariables>(ReferralStructureSettingsPageDataDocument, options);
        }
export type ReferralStructureSettingsPageDataQueryHookResult = ReturnType<typeof useReferralStructureSettingsPageDataQuery>;
export type ReferralStructureSettingsPageDataLazyQueryHookResult = ReturnType<typeof useReferralStructureSettingsPageDataLazyQuery>;
export type ReferralStructureSettingsPageDataQueryResult = Apollo.QueryResult<ReferralStructureSettingsPageDataQuery, ReferralStructureSettingsPageDataQueryVariables>;