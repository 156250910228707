/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  SurveyInsightsDateRange,
  SurveyInsightsDateRangeLabel,
} from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/types";

import { shortenDateRangeLabel } from "../../utils";

export interface SurveyEngagementCardProps {
  dateRange: SurveyInsightsDateRange | undefined;
  numSent: number;
  numResponses: number;
  responseRatePercentage: number;
  reward: {
    numPoints?: number | null;
    numDrawTickets?: number | null;
  };
}

export function SurveyEngagementCard({
  dateRange,
  numSent,
  numResponses,
  responseRatePercentage,
  reward,
}: SurveyEngagementCardProps) {
  const runDate = (date: string): string => {
    return new Date(date).toLocaleString("en-us", {
      timeZone: "UTC",
      month: "short",
      day: "numeric",
    });
  };

  const surveyPeriodLabel = (() => {
    if (!dateRange) {
      return "";
    }
    if (dateRange.label === SurveyInsightsDateRangeLabel.Launch) {
      return "Launch";
    }
    return `${shortenDateRangeLabel(
      dateRange.label
    )}/${dateRange.year.toString().slice(-2)}`;
  })();

  return (
    <Card
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(3)};
          flex-grow: 1;
        `
      }
    >
      <div
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(2)};
            display: flex;
            gap: ${theme.spacing(1)};
            align-items: center;
          `
        }
      >
        <Typography variant="h6" fontWeight={700}>
          Response Rate
        </Typography>
      </div>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: space-between;
          gap: ${theme.spacing(2)};
        `}
      >
        {dateRange && (
          <div>
            <Typography variant="caption">SURVEY PERIOD</Typography>
            <Typography variant="body">
              {surveyPeriodLabel} ({runDate(dateRange.startDate)}-
              {runDate(dateRange.endDate)})
            </Typography>
          </div>
        )}
        <div>
          <Typography variant="caption">SENT</Typography>
          <Typography variant="body">{numSent}</Typography>
        </div>
        <div>
          <Typography variant="caption">RESPONSES</Typography>
          <Typography variant="body">{numResponses}</Typography>
        </div>
        <div>
          <Typography variant="caption">RESPONSE RATE</Typography>
          <Typography variant="body">{responseRatePercentage}%</Typography>
        </div>
        <div>
          <Typography variant="caption">REWARD</Typography>
          <div
            css={css`
              display: flex;
              gap: 4px;
            `}
          >
            <Typography variant="body">
              {compact([
                reward.numPoints && `${reward.numPoints} points`,
                reward.numDrawTickets &&
                  `${reward.numDrawTickets} ticket${
                    reward.numDrawTickets > 1 ? "s" : ""
                  }`,
              ]).join(" & ")}
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  );
}
