import { faBriefcase } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { JobSharesInsightMetric as JobSharesInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../../../../shared/components/insight-metric";
import {
  useTrackInsightMetricComparisonTooltip,
  useTrackInsightMetricTooltip,
} from "../../../../hooks";
import { getPercentDifference } from "../../../../utils";

export interface JobSharesInsightMetricProps {
  currentJobSharesInsightMetric: JobSharesInsightMetricType | undefined;
  previousJobSharesInsightMetric: JobSharesInsightMetricType | undefined;
}

export function JobSharesInsightMetric({
  currentJobSharesInsightMetric,
  previousJobSharesInsightMetric,
}: JobSharesInsightMetricProps) {
  const trackInsightMetricGroupTooltip = useTrackInsightMetricTooltip();
  const trackInsightMetricComparisonTooltip = useTrackInsightMetricComparisonTooltip();

  const currentJobShares = currentJobSharesInsightMetric?.jobShareCount;
  const percentPointChange = (() => {
    const previousJobShares = previousJobSharesInsightMetric?.jobShareCount;
    if (currentJobShares === undefined || previousJobShares === undefined) {
      return null;
    }
    return getPercentDifference(previousJobShares, currentJobShares);
  })();

  return (
    <InsightMetric
      label="Job shares"
      icon={<FontAwesomeIcon icon={faBriefcase} />}
      tooltipText="The total number of jobs shared by caregivers through the platform."
      value={
        currentJobShares !== undefined
          ? {
              type: "number",
              number: currentJobShares,
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
      onTooltipOpen={trackInsightMetricGroupTooltip}
      onPercentPointChangeTooltipOpen={trackInsightMetricComparisonTooltip}
    />
  );
}
