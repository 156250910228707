/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CheckIcon from "@material-ui/icons/Check";
import CopyIcon from "@material-ui/icons/FileCopy";
import copyTextToClipboard from "copy-to-clipboard";
import { ReactNode, useEffect, useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { CandidateDetailsTable } from "../candidate-details-table";

export interface CandidateApplicationDetailsProps {
  drawerOpen: boolean;
  name: string;
  phone: string | null;
  email: string | null;
  location: { label: string; value: ReactNode } | null;
  appliedOnDate: string | null;
  reappliedDate: string | null;
}

type TableSections = Array<
  Array<{
    label: string;
    value: ReactNode;
  } | null>
>;

export function CandidateApplicationDetails({
  drawerOpen,
  name,
  phone,
  email,
  location,
  appliedOnDate,
  reappliedDate,
}: CandidateApplicationDetailsProps): JSX.Element {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (drawerOpen) {
      setCopied(false);
    }
  }, [drawerOpen, setCopied]);

  const mainInfoSections: TableSections = [
    [
      {
        label: `Name`,
        value: name,
      },

      phone
        ? {
            label: `Phone`,
            value: phone,
          }
        : null,
      email
        ? {
            label: `Email`,
            value: (
              <div
                css={css`
                  max-width: 100%;
                `}
              >
                <Tooltip placement="bottom" title={email}>
                  <Typography
                    variant="body"
                    component="a"
                    align="right"
                    css={(theme: AppTheme) => css`
                      margin-bottom: ${theme.spacing(0.5)};
                      display: block;
                      max-width: 250px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      vertical-align: middle;
                    `}
                    href={`mailto:${email}`}
                  >
                    {email}
                  </Typography>
                </Tooltip>
                <Button
                  css={css`
                    width: 90px;
                    float: right;
                  `}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    copyTextToClipboard(email);
                    setCopied(true);
                  }}
                  startIcon={copied ? <CheckIcon /> : <CopyIcon />}
                  label={copied ? "Copied" : "Copy"}
                />
              </div>
            ),
          }
        : null,

      location,
    ],
    [
      appliedOnDate
        ? {
            label: "Applied on",
            value: appliedOnDate,
          }
        : null,
      reappliedDate
        ? {
            label: "Re-applied",
            value: reappliedDate,
          }
        : null,
    ],
  ];

  return (
    <div
      css={css`
        background-color: #fff;
      `}
    >
      <Divider />

      <div
        css={(theme: AppTheme) => css`
          padding: 0 ${theme.spacing(3)};
        `}
      >
        <Typography
          variant="h3"
          css={(theme: AppTheme) => css`
            flex-grow: 1;
            font-size: 1.2em;
            font-weight: bold;
            margin: ${theme.spacing(3)} 0;
          `}
        >
          Application details
        </Typography>
        <ObscureRecordedText>
          <CandidateDetailsTable items={mainInfoSections} />
        </ObscureRecordedText>
      </div>
      <Divider
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(2.5)};
        `}
      />
    </div>
  );
}
