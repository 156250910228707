/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { format, parseISO } from "date-fns";
import { compact, last } from "lodash";
import { Fragment, useEffect } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { ModalErrorState } from "@rewards-web/shared/components/modal/modal-error-state";
import { ModalLoadingState } from "@rewards-web/shared/components/modal/modal-loading-state";
import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme, headerFontFamily } from "@rewards-web/shared/style/theme";

import { formatCandidateActionDate } from "../../../../pages/authenticated/candidates/candidate-list/lib";
import { Accordion } from "../../../components/accordion";
import {
  RightDrawer,
  RightDrawerActions,
  RightDrawerContent,
} from "../../../components/right-drawer";
import { useHasPermissionQuery } from "../../permissions/hooks/use-has-permission-query";
import { CandidateApplicationDetails } from "./candidate-application-details";
import { CandidateAssignedJob } from "./candidate-assigned-job";
import { useCandidateDetailsQuery } from "./candidate-details.generated";
import { CandidateEmployeeLink } from "./candidate-employee-link";
import { InternalNotesForm } from "./candidate-internal-notes-form";
import { CandidateReferringEmployee } from "./candidate-referring-employee";
import { CandidateResumeDetails } from "./candidate-resume-details";
import { CandidateScreenerQuestions } from "./candidate-screener-questions";
import { getLocationFieldFromSubmission, isHired } from "./lib";
import { SetCandidateEmployeeLinkForm } from "./set-candidate-employee-link-form";
import { CandidateDrawerAccordionState } from "./use-accordion-state";

export interface CandidateDetailsDrawerProps {
  open: boolean;
  candidateId: string | null;
  onClose(): void;
  onExited(): void;
  onCandidateRemoved(): void;
  accordionState: CandidateDrawerAccordionState;
  onChangeAccordionState: (
    setter: (
      prev: CandidateDrawerAccordionState
    ) => CandidateDrawerAccordionState
  ) => void;
  resumesEnabled: boolean;
}

export function CandidateDetailsDrawer({
  open,
  candidateId,
  onClose,
  onExited,
  onCandidateRemoved,
  accordionState,
  onChangeAccordionState,
  resumesEnabled,
}: CandidateDetailsDrawerProps) {
  const track = useTrack();

  useEffect(() => {
    if (open) {
      track("Candidate detail drawer opened", {
        accordionState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, open]);

  const fullCandidatesPermissionQuery = useHasPermissionQuery(
    "full",
    "candidates"
  );

  const candidateDetails = useCandidateDetailsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: { candidateId: candidateId! },
    skip: !candidateId,
    onError: reportError,
  });

  if (candidateDetails.error) {
    return (
      <RightDrawer
        open={open}
        onClose={onClose}
        onExited={onExited}
        title="Error"
      >
        <ModalErrorState onClose={onClose} />;
      </RightDrawer>
    );
  }
  if (candidateDetails.loading || !candidateDetails.data) {
    return (
      <RightDrawer open={open} onClose={onClose} onExited={onExited} title="">
        <ModalLoadingState />
      </RightDrawer>
    );
  }
  if (!candidateDetails.data.getCandidateById) {
    return (
      <RightDrawer
        open={open}
        onClose={onClose}
        onExited={onExited}
        title="Error"
      >
        <RightDrawerContent>
          <Alert severity="info" message="This candidate no longer exists" />
        </RightDrawerContent>
        <RightDrawerActions>
          <Button variant="outlined" onClick={onClose} label="Close" />
        </RightDrawerActions>
      </RightDrawer>
    );
  }

  const toggleSection = (
    sectionName:
      | "candidateEmployeeLink"
      | "screenerQuestions"
      | "resume"
      | "referring"
      | "internalNotes"
      | "job"
  ) => {
    onChangeAccordionState((prev) => {
      const stateKey: keyof typeof accordionState = `${sectionName}SectionOpen`;
      const nextAccordionState = {
        ...prev,
        [stateKey]: !prev[stateKey],
      };

      track("Candidate drawer section accordion state changed", {
        sectionChanged: sectionName,
        nextAccordionState,
      });

      return nextAccordionState;
    });
  };

  const candidate = candidateDetails.data.getCandidateById;
  const candidateApplicationDates = candidate.appliedOnDates.sort();

  const candidateIsFromSiblingOrganization =
    candidateDetails.data.organization.id !== candidate.organization.id;

  const hasEditPermission =
    !!fullCandidatesPermissionQuery.hasPermission &&
    !candidateIsFromSiblingOrganization;

  const candidateDisplayName = `${candidate.firstName} ${
    candidate.preferredName ? `(${candidate.preferredName}) ` : ""
  }${candidate.lastName}`;

  const content = (() => {
    const applicationContent: JSX.Element = (
      // TODO(@shailscript): Refactor this to use the Accordion component
      <div
        css={(theme: AppTheme) => css`
          margin-top: ${isHired(candidate) ? 0 : theme.spacing(-1)};
        `}
      >
        <CandidateApplicationDetails
          drawerOpen={!!candidateId}
          name={candidateDisplayName}
          phone={
            candidate.phoneNumber
              ? formatPhoneNumber(candidate.phoneNumber)
              : null
          }
          email={candidate.email ?? null}
          appliedOnDate={formatCandidateActionDate(
            parseISO(candidateApplicationDates[0])
          )}
          location={
            candidate.applicationSubmissions.length === 0
              ? null
              : getLocationFieldFromSubmission(
                  last(candidate.applicationSubmissions)!
                )
          }
          reappliedDate={
            candidateApplicationDates.length > 1
              ? format(
                  new Date(
                    candidateApplicationDates[
                      candidateApplicationDates.length - 1
                    ]
                  ),
                  "MMMM d yyyy"
                )
              : null
          }
        />
      </div>
    );

    const content: (JSX.Element | null)[] = [
      candidateIsFromSiblingOrganization ? (
        <Alert
          key="sibling-org-alert"
          message={`This candidate belongs to ${candidate.organization.shortName} (${candidate.organization.branchName}). Please contact ${candidate.assignedToJobPosting?.candidateAlertEmail} to edit this candidate.`}
          severity="warning"
        />
      ) : null,
      isHired(candidate) ? (
        <Fragment key="candidate-employee-link">
          <Accordion
            open={accordionState.candidateEmployeeLinkSectionOpen}
            title={
              candidate.linkedEmployee
                ? "Hired candidate's linked employee account"
                : "Link Candidate to Employee Account"
            }
            onChange={() => toggleSection("candidateEmployeeLink")}
          >
            {candidate.linkedEmployee ? (
              <CandidateEmployeeLink
                linkedEmployee={candidate.linkedEmployee!}
                jobTitle={candidate.assignedToJobPosting?.title}
              />
            ) : (
              <SetCandidateEmployeeLinkForm candidate={candidate} />
            )}
          </Accordion>
        </Fragment>
      ) : null,
      applicationContent,
      <Fragment key="screener-questions">
        <Accordion
          open={accordionState.screenerQuestionsSectionOpen}
          title="Screening questions"
          onChange={() => toggleSection("screenerQuestions")}
        >
          <CandidateScreenerQuestions
            submissions={candidate.applicationSubmissions}
          />
        </Accordion>
      </Fragment>,
      resumesEnabled ? (
        <Fragment key="resume">
          <Accordion
            open={accordionState.resumeSectionOpen}
            title="Resume details"
            onChange={() => toggleSection("resume")}
          >
            <CandidateResumeDetails
              candidate={candidate}
              onSentResume={() => {
                candidateDetails.refetch();
              }}
              onUploadedResume={() => {
                candidateDetails.refetch();
              }}
              hasEditPermission={hasEditPermission}
            />
          </Accordion>
        </Fragment>
      ) : null,
      <Fragment key="internal-notes">
        <Accordion
          open={accordionState.internalNotesSectionOpen}
          title="Internal notes"
          onChange={() => toggleSection("internalNotes")}
        >
          <InternalNotesForm
            candidateId={candidateId!}
            adminPreviousNotes={
              candidateDetails.data?.getCandidateById?.adminNotes ?? ""
            }
            hasEditPermission={hasEditPermission}
          />
        </Accordion>
      </Fragment>,
      <Fragment key="referrer">
        <Accordion
          open={accordionState.referringSectionOpen}
          title="Referring employee"
          onChange={() => toggleSection("referring")}
        >
          <CandidateReferringEmployee
            candidate={candidate}
            hasEditPermission={hasEditPermission}
            onChangedReferringEmployee={() => {
              candidateDetails.refetch();
            }}
          />
        </Accordion>
      </Fragment>,
      <Fragment key="assigned-job">
        <Accordion
          open={accordionState.jobSectionOpen}
          title="Job"
          onChange={() => toggleSection("job")}
        >
          <CandidateAssignedJob
            candidate={candidate}
            hasEditPermission={hasEditPermission}
            onReassignedJob={({ movedOutOfOrganization }) => {
              if (movedOutOfOrganization) {
                onCandidateRemoved();
                onClose();
              } else {
                candidateDetails.refetch();
              }
            }}
          />
        </Accordion>
      </Fragment>,
    ];

    return (
      <RightDrawerContent
        disablePadding
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(1)};
          margin-top: ${theme.spacing(1)};
        `}
      >
        {compact(content).map((node) => (
          <div
            key={node.key}
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(1)};
              margin-top: ${theme.spacing(1)};
            `}
          >
            {node}
          </div>
        ))}
      </RightDrawerContent>
    );
  })();

  const assignedAdmin: JSX.Element | undefined = candidate.assignedToAdmin ? (
    <div
      key="assigned-to-admin"
      css={(theme: AppTheme) => css`
        margin-top: ${theme.spacing(0.5)};
      `}
    >
      <Typography
        color="textSecondary"
        display="block"
        component="sub"
        css={css`
          font-family: ${headerFontFamily};
          font-size: 1.05em;
        `}
      >
        Assigned to:{" "}
        <ObscureRecordedText>
          <span
            css={(theme: AppTheme) => css`
              font-weight: 600;
              color: ${theme.palette.primary.main};
              font-family: ${headerFontFamily};
            `}
          >
            {`${candidate.assignedToAdmin.firstName} ${candidate.assignedToAdmin.lastName}`}
          </span>
        </ObscureRecordedText>
      </Typography>
    </div>
  ) : undefined;

  return (
    <RightDrawer
      open={open}
      onClose={onClose}
      title={candidateDisplayName}
      maxWidth="400px"
      backgroundColor="#f9f9fb"
      headerBackgroundColor="#fff"
      drawerDescription={assignedAdmin}
      obscureTitleFromRecordedText
    >
      {content}
    </RightDrawer>
  );
}
