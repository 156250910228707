import { Alert } from "@rewards-web/shared/components/alert";
import { reportError } from "@rewards-web/shared/modules/error";

import { InsightMetricGroup } from "../../components/insight-metric-group";
import starIconUrl from "../../graphics/star-icon.png";
import { useEngagementInsightMetricGroupDataQuery } from "./engagement-metric-group-query.generated";
import { ActiveUsersInsightMetric } from "./insight-metrics/active-users";
import { GoalsAchievedInsightMetric } from "./insight-metrics/goals-achieved";
import { ParticipationInsightMetric } from "./insight-metrics/participation";

export interface EngagementInsightMetricGroupProps {
  currentRange: {
    startDate: Date;
    endDate: Date;
  };
  previousRange: {
    startDate: Date;
    endDate: Date;
  } | null;
}

export function EngagementInsightMetricGroup({
  currentRange,
  previousRange,
}: EngagementInsightMetricGroupProps) {
  const skipPreviousRangeQuery = !previousRange;

  const currentEngagementQuery = useEngagementInsightMetricGroupDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      startDate: currentRange.startDate.valueOf(),
      endDate: currentRange.endDate.valueOf(),
    },
    onError: reportError,
  });

  const previousEngagementQuery = useEngagementInsightMetricGroupDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      startDate: previousRange?.startDate.valueOf(),
      endDate: previousRange?.endDate.valueOf(),
    },
    skip: skipPreviousRangeQuery,
    onError: reportError,
  });

  const queriesToValidate = [
    currentEngagementQuery,
    ...(skipPreviousRangeQuery ? [] : [previousEngagementQuery]),
    // no need to validate previous range queries if we skip them
  ];

  if (queriesToValidate.some((query) => query.error)) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  return (
    <InsightMetricGroup
      label="Engagement"
      icon={<img src={starIconUrl} width={24} alt="star" />}
      trackingProperties={{
        startDate: currentRange.startDate,
        endDate: currentRange.endDate,
      }}
    >
      <ActiveUsersInsightMetric
        currentActiveUsersInsightMetric={
          currentEngagementQuery.data?.getActiveUsersInsightMetric
        }
        previousActiveUsersInsightMetric={
          previousEngagementQuery.data?.getActiveUsersInsightMetric
        }
      />
      <GoalsAchievedInsightMetric
        currentGoalsAchievedInsightMetric={
          currentEngagementQuery.data?.getGoalsAchievedInsightMetric
        }
        previousGoalsAchievedInsightMetric={
          previousEngagementQuery.data?.getGoalsAchievedInsightMetric
        }
      />
      <ParticipationInsightMetric
        currentParticipationInsightMetric={
          currentEngagementQuery.data?.getParticipationInsightMetric
        }
        previousParticipationInsightMetric={
          previousEngagementQuery.data?.getParticipationInsightMetric
        }
      />
    </InsightMetricGroup>
  );
}
