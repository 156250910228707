/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import caribouRewardsLogo from "../../../shared/lib/caribou-rewards-logo.png";

interface LoginPageContainerProps {
  children: ReactNode;
}

export function LoginPageContainer({
  children,
}: LoginPageContainerProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        background: #f9f9fb;
        height: 100%;
        width: 100%;
        & > * {
          padding-top: 15vh;
        }
        ${theme.breakpoints.up("md")} {
          display: flex;
          justify-content: space-evenly;
        }
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          ${theme.breakpoints.up("md")} {
            width: 46%;
          }
          display: block;
          height: 100%;
          background-color: #f9f9fb;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: ${theme.spacing(7)};
          `}
        >
          <img height="82px" src={caribouRewardsLogo} alt="dollar coin" />
        </div>

        {children}
        <div
          css={(theme: AppTheme) => css`
            max-width: 370px;
            margin: 0 auto;
            margin-top: ${theme.spacing(4)};
            text-align: center;
          `}
        >
          <Typography display="inline" variant="body" color="textSecondary">
            By using this product, you agree to the{" "}
            <a
              href="https://www.caribou.care/privacy-policy-rewards"
              target="_blank"
              rel="noreferrer"
              css={(theme: AppTheme) => css`
                color: ${theme.palette.primary.main};
              `}
            >
              Privacy Policy
            </a>{" "}
            as well as the{" "}
            <a
              href="https://www.caribou.care/terms-rewards"
              target="_blank"
              rel="noreferrer"
              css={(theme: AppTheme) => css`
                color: ${theme.palette.primary.main};
              `}
            >
              Terms and Conditions
            </a>
            .
          </Typography>
        </div>
      </div>
    </div>
  );
}
