import {
  faAward,
  faCalendar,
  faComments,
  faFaceSmile,
  faHouseHeart,
  faLocationDot,
  IconDefinition as FAIconDefinition,
} from "@fortawesome/pro-regular-svg-icons";

import { SurveyQuestionType } from "@rewards-web/shared/graphql-types";

import { SurveyQuestionTypeSupportedByUI } from "../../pages/authenticated/surveys/constants";

enum SurveyQuestionCategory {
  Recognition = "Recognition",
  Schedule = "Schedule",
  Location = "Location",
  JobSatisfaction = "Job Satisfaction",
  NPS = "NPS",
  Support = "Support",
}

const SURVEY_QUESTION_CATEGORY_ICON_MAP: Record<
  SurveyQuestionCategory,
  FAIconDefinition
> = {
  [SurveyQuestionCategory.Recognition]: faAward,
  [SurveyQuestionCategory.Schedule]: faCalendar,
  [SurveyQuestionCategory.Location]: faLocationDot,
  [SurveyQuestionCategory.JobSatisfaction]: faHouseHeart,
  [SurveyQuestionCategory.NPS]: faFaceSmile,
  [SurveyQuestionCategory.Support]: faComments,
};

const SURVEY_QUESTION_METADATA: {
  [question in SurveyQuestionTypeSupportedByUI[0]]: {
    category: SurveyQuestionCategory;
    questionText: string;
  };
} = {
  // Pulse Check
  [SurveyQuestionType.MeaningfulRecognition]: {
    category: SurveyQuestionCategory.Recognition,
    questionText: "I receive meaningful recognition for the work I do.",
  },
  [SurveyQuestionType.ManageableWorkload]: {
    category: SurveyQuestionCategory.Schedule,
    questionText: "I feel I am assigned a manageable workload each week.",
  },
  [SurveyQuestionType.ReliableTransportation]: {
    category: SurveyQuestionCategory.Location,
    questionText:
      "I have reliable transportation to reach my clients' locations.",
  },
  [SurveyQuestionType.LeadershipSupport]: {
    category: SurveyQuestionCategory.Support,
    questionText:
      "I'm satisfied with the support I receive from my supervisors.",
  },
  [SurveyQuestionType.Resources]: {
    category: SurveyQuestionCategory.JobSatisfaction,
    questionText: "I have access to the resources I need to succeed in my job.",
  },
  [SurveyQuestionType.Nps]: {
    category: SurveyQuestionCategory.NPS,
    questionText: "I would recommend working here to my friends.",
  },
  // Quick Check
  [SurveyQuestionType.QuickCheckWeeklySentiment]: {
    category: SurveyQuestionCategory.JobSatisfaction,
    questionText: "I feel good about work this week.",
  },
  [SurveyQuestionType.QuickCheckEmployeeValue]: {
    category: SurveyQuestionCategory.JobSatisfaction,
    questionText: "[Company name] values me as an employee.",
  },
  [SurveyQuestionType.QuickCheckClientSatisfaction]: {
    category: SurveyQuestionCategory.JobSatisfaction,
    questionText:
      "I am satisfied with my overall experience with the clients I care for.",
  },
  [SurveyQuestionType.QuickCheckClientImpact]: {
    category: SurveyQuestionCategory.JobSatisfaction,
    questionText:
      "Describe a situation or a moment with a client this month that you are proud of. [open text]",
  },
  [SurveyQuestionType.QuickCheckSupervisorCommunication]: {
    category: SurveyQuestionCategory.Support,
    questionText: "Communication with my supervisor is effective.",
  },
  [SurveyQuestionType.QuickCheckFeedbackEffectiveness]: {
    category: SurveyQuestionCategory.Support,
    questionText: "My feedback is generally heard and addressed effectively.",
  },
  [SurveyQuestionType.QuickCheckSupervisorReachability]: {
    category: SurveyQuestionCategory.Support,
    questionText:
      "I can easily reach my supervisor when I need help or have a concern.",
  },
  [SurveyQuestionType.QuickCheckManageableWorkload]: {
    category: SurveyQuestionCategory.Schedule,
    questionText: "My workload is manageable this week.",
  },
  [SurveyQuestionType.QuickCheckScheduleBalance]: {
    category: SurveyQuestionCategory.Schedule,
    questionText: "I have a good work-life balance with my current schedule.",
  },
  [SurveyQuestionType.QuickCheckScheduleSatisfaction]: {
    category: SurveyQuestionCategory.Schedule,
    questionText:
      "I am satisfied with the number of hours in my current schedule.",
  },
  [SurveyQuestionType.QuickCheckRecognitionRegularity]: {
    category: SurveyQuestionCategory.Recognition,
    questionText: "I receive recognition regularly.",
  },
  [SurveyQuestionType.QuickCheckRecognitionImpact]: {
    category: SurveyQuestionCategory.Recognition,
    questionText:
      "The recognition I receive has a positive impact on my workday.",
  },
  [SurveyQuestionType.QuickCheckNps]: {
    category: SurveyQuestionCategory.NPS,
    questionText:
      "How likely are you to recommend working at [Company name] to a friend?",
  },
};

export const getSurveyQuestionMetadata = (
  question: SurveyQuestionTypeSupportedByUI[0],
  agencyName?: string
): {
  category: string;
  questionText: string;
  icon: FAIconDefinition;
} => {
  const { category, questionText } = SURVEY_QUESTION_METADATA[question];

  return {
    category,
    questionText: agencyName
      ? questionText.replace("[Company name]", agencyName)
      : questionText,
    icon: SURVEY_QUESTION_CATEGORY_ICON_MAP[category],
  };
};
