/** @jsxImportSource @emotion/react */
import { FieldValues, Control, Controller, Path } from "react-hook-form";

import { SelectField } from "@rewards-web/shared/components/select-field";
import {
  PulseCheckSurveyCadenceFrequency,
  QuickCheckSurveyCadenceFrequency,
} from "@rewards-web/shared/graphql-types";

interface SurveySettingsFrequencyFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  options: {
    label: string;
    value: PulseCheckSurveyCadenceFrequency | QuickCheckSurveyCadenceFrequency;
  }[];
  disabled?: boolean;
}

export function SurveySettingsFrequencyField<T extends FieldValues>({
  control,
  name,
  options,
  disabled: disabledProp,
}: SurveySettingsFrequencyFieldProps<T>) {
  const disabled = !!disabledProp || options.length <= 1;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <SelectField
          {...field}
          disabled={disabled}
          readOnly={disabled}
          greyReadOnly
          label="Select frequency"
          hideLabel
          error={fieldState.error}
          width="full"
          options={options}
        />
      )}
    />
  );
}
