/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compact } from "lodash";
import { Outlet } from "react-router";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { NavigationTabs } from "../../../shared/components/navigation-tabs";
import { useSurveySettingsEnabled } from "./survey-settings/shared/hooks/use-survey-settings-enabled";

export function SurveysPageContents() {
  const checkInSurveySettingsEnabled = useFeatureFlag(
    "admin-app-check-in-survey-form-temp"
  );

  const navTabs = compact([
    {
      label: "Pulse",
      path: "/surveys/pulse",
    },
    checkInSurveySettingsEnabled && {
      label: "Check-in",
      path: "/surveys/check-in",
    },
  ]);

  const {
    surveySettingsEnabled: pulseSurveySettingsEnabled,
  } = useSurveySettingsEnabled();

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Surveys
        </Typography>
        {pulseSurveySettingsEnabled && (
          <Button
            label="Edit settings"
            startIcon={<FontAwesomeIcon icon={faPen} width={12} />}
            width="auto"
            variant="text"
            color="primary"
            linkTo="/survey-settings"
            css={(theme: AppTheme) => css`
              padding: ${theme.spacing(2, 3)};
            `}
          />
        )}
      </div>
      <NavigationTabs ariaLabel="Surveys navigation" tabs={navTabs} />
      <div
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(4)};
        `}
      >
        <Outlet />
      </div>
    </>
  );
}
