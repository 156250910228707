/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faBriefcase,
  faShare,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  ListCandidatesV2OrderField,
  Sort,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { InsightMetric } from "../../../../../shared/components/insight-metric";
import { Pagination } from "../../../../../shared/components/pagination";
import { TableSortState } from "../../../../../shared/components/sortable-table-header/context";
import { useEmployeeDetailReferralCardDataQuery } from "./employee-detail-referral-card-data.generated";
import { EmployeeDetailReferralsEnabledSwitch } from "./employee-detail-referrals-enabled-switch";
import { EmployeeDetailReferralsTable } from "./employee-detail-referrals-table";
import { useEmployeeDetailReferralsTableDataQuery } from "./employee-details-referral-table-data.generated";

const ITEMS_PER_PAGE = 6;

export interface EmployeeDetailReferralCardProps {
  userId: string;
  userHasLoaded: boolean;
}

export function EmployeeDetailReferralCard({
  userId,
  userHasLoaded,
}: EmployeeDetailReferralCardProps) {
  const track = useTrack();
  const query = useEmployeeDetailReferralCardDataQuery({
    onError: reportError,
    variables: {
      userId,
    },
    skip: !userHasLoaded,
  });

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [sortValue, setSortValue] = useState<
    TableSortState<
      | ListCandidatesV2OrderField.FirstNameLastName
      | ListCandidatesV2OrderField.LastStepOrArchivedDate
    >
  >({
    field: ListCandidatesV2OrderField.FirstNameLastName,
    direction: Sort.Asc,
  });

  const candidateListQuery = useEmployeeDetailReferralsTableDataQuery({
    variables: {
      filter: {
        referredByUserId: userId,
      },

      offset: currentPageIndex * ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,

      order: {
        field: sortValue.field,
        direction: sortValue.direction,
      },
    },
    onError: reportError,
    skip: !userHasLoaded,
  });

  const listData =
    candidateListQuery.data?.listCandidatesV2 ??
    candidateListQuery.previousData?.listCandidatesV2;

  const items = listData?.items;
  const total = listData?.total;

  if (
    query.data &&
    (!query.data.getRewardsUserById ||
      !query.data.getRewardsUserById.referralsEnabled)
  ) {
    // if the user doesn't exist, or referrals are not enabled for them (e.g. in this org or the user's branch), don't display the card
    return null;
  }

  const content = (() => {
    if (query.error || candidateListQuery.error) {
      return (
        <Alert
          severity="error"
          message="An error occurred while loading referral data. Please try again later."
        />
      );
    }

    return (
      <>
        <div
          css={(theme: AppTheme) =>
            css`
              gap: ${theme.spacing(2)};
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
              margin-bottom: ${theme.spacing(2.5)};
            `
          }
        >
          <InsightMetric
            labelStyle="grey"
            label="Job shares"
            valueVariant="h5"
            icon={<FontAwesomeIcon icon={faShare} />}
            value={
              typeof query.data?.getReferralStatsForUser
                ?.totalNumberOfShares === "number"
                ? {
                    type: "number",
                    number:
                      query.data.getReferralStatsForUser.totalNumberOfShares,
                  }
                : {
                    type: "loading",
                  }
            }
          />
          <InsightMetric
            labelStyle="grey"
            label="Applications"
            valueVariant="h5"
            icon={<FontAwesomeIcon icon={faUsers} />}
            value={
              typeof query.data?.getReferralStatsForUser
                ?.totalNumberOfApplicants === "number"
                ? {
                    type: "number",
                    number:
                      query.data.getReferralStatsForUser
                        .totalNumberOfApplicants,
                  }
                : {
                    type: "loading",
                  }
            }
          />
          <InsightMetric
            labelStyle="grey"
            label="Hires"
            valueVariant="h5"
            icon={<FontAwesomeIcon icon={faBriefcase} />}
            value={
              typeof query.data?.getReferralStatsForUser
                ?.totalNumberOfCandidatesHired === "number"
                ? {
                    type: "number",
                    number:
                      query.data.getReferralStatsForUser
                        .totalNumberOfCandidatesHired,
                  }
                : {
                    type: "loading",
                  }
            }
          />
        </div>

        <div
          css={(theme: AppTheme) => css`
            border: 1px solid ${theme.palette.grey[400]};
            border-radius: 10px;
            padding: 0 ${theme.spacing(2)};
          `}
        >
          <EmployeeDetailReferralsTable
            userId={userId}
            items={items}
            sortValue={sortValue}
            onSortValueChange={(sortValue) => {
              track("Updated employee detail referrals table sort", {
                sortField: sortValue.field,
                sortDirection: sortValue.direction,
              });

              setSortValue(sortValue);
              setCurrentPageIndex(0);
            }}
            total={total}
            loading={candidateListQuery.loading}
            rewardsProgramShortName={
              query.data?.getMyRewardsOrganization.whiteLabelConfig
                ?.rewardsProgramShortName
            }
            referralLinkDisabled={
              query.data?.getRewardsUserById?.userReferralLinkDisabled
            }
            resumesEnabled={
              query.data?.getMyRewardsOrganization.referralResumesEnabled
            }
            onRefreshList={() => {
              candidateListQuery.refetch();
            }}
            maxPointsEarnedPerCandidate={
              query.data?.getMyRewardsOrganization.maxPointsEarnedPerCandidate
            }
            pointsPerDollar={
              query.data?.getMyRewardsOrganization.pointsPerDollar
            }
            launched={query.data?.getMyRewardsOrganization.launched}
          />
        </div>

        {typeof total === "number" && total > 0 && (
          <Pagination
            disableMargin
            total={total}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPageIndex={currentPageIndex}
            onChange={(_, page) => {
              track("Updated employee detail referrals table pagination", {
                employeeId: userId,
                pageIndex: page,
              });

              setCurrentPageIndex(page - 1);
            }}
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2.5)};
            `}
          />
        )}
      </>
    );
  })();

  return (
    <div>
      <Card>
        <CardContent
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(4)};
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              width: 100%;
            `}
          >
            <Typography
              component="h2"
              variant="h5"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              Referrals
            </Typography>
            {query.data?.getRewardsUserById && (
              <EmployeeDetailReferralsEnabledSwitch
                userId={userId}
                referralLinkDisabled={
                  query.data.getRewardsUserById.userReferralLinkDisabled
                }
              />
            )}
          </div>

          {content}
        </CardContent>
      </Card>
    </div>
  );
}
