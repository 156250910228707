import { sortBy } from "lodash";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { SelectField } from "@rewards-web/shared/components/select-field";

import { useMyBranches } from "../../../branches/use-my-branches";
import { usePermissionLimitedBranchIds } from "../../../branches/use-permission-limited-branch-ids";

interface EmployeeBranchFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  greyReadOnly?: boolean;
}

export function EmployeeBranchField<T extends FieldValues>({
  control,
  name,
  greyReadOnly = false,
}: EmployeeBranchFieldProps<T>) {
  const branchesQuery = useMyBranches();
  const { data: permissionLimitedBranchIds } = usePermissionLimitedBranchIds();

  const branches =
    permissionLimitedBranchIds && permissionLimitedBranchIds.canOnlySeeBranchIds
      ? branchesQuery.data?.getMyRewardsOrganization.branches.filter((branch) =>
          permissionLimitedBranchIds.canOnlySeeBranchIds?.includes(branch.id)
        )
      : branchesQuery.data?.getMyRewardsOrganization.branches;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <SelectField
          {...field}
          width="full"
          label="Branch"
          options={sortBy(
            (branches ?? []).map((branch) => ({
              value: branch.id,
              label: branch.name,
            })),
            (branch) => branch.label
          )}
          greyReadOnly={greyReadOnly}
        />
      )}
    />
  );
}
