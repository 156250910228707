import { useCallback, useMemo } from "react";

import {
  ListCandidatesV2OrderField,
  ListCandidatesV2OrderInput,
  Sort,
} from "@rewards-web/shared/graphql-types";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

type SupportedCandidateSortField =
  | ListCandidatesV2OrderField.MostRecentApplicationDate
  | ListCandidatesV2OrderField.LastUpdateDate;

type SupportedCandidateOrderInput = ListCandidatesV2OrderInput & {
  field: SupportedCandidateSortField;
};

export const DEFAULT_ORDER: SupportedCandidateOrderInput = {
  field: ListCandidatesV2OrderField.MostRecentApplicationDate,
  direction: Sort.Desc,
};

export function useCandidatesSortState(): [
  SupportedCandidateOrderInput,
  (nextValue: SupportedCandidateOrderInput) => void
] {
  const [sortParam, setSortParam] = useQueryParam("sort");
  const [directionParam, setDirectionParam] = useQueryParam("direction");

  const value = useMemo((): SupportedCandidateOrderInput => {
    return {
      field: deserializeSortField(sortParam),
      direction: deserializeSortDirection(directionParam),
    };
  }, [sortParam, directionParam]);

  const handleSetValue = useCallback(
    (nextValue: SupportedCandidateOrderInput) => {
      setSortParam(serializeSortField(nextValue.field));
      setDirectionParam(serializeSortDirection(nextValue.direction));
    },
    [setSortParam, setDirectionParam]
  );

  return [value, handleSetValue];
}

type CandidateSortField = "application" | "updated";

function serializeSortField(
  value: SupportedCandidateOrderInput["field"]
): CandidateSortField | null {
  switch (value) {
    case ListCandidatesV2OrderField.MostRecentApplicationDate:
      return "application";
    case ListCandidatesV2OrderField.LastUpdateDate:
      return "updated";
    default:
      assertNever(value);
  }
}

function deserializeSortField(
  value: string | null
): SupportedCandidateOrderInput["field"] {
  switch (value as CandidateSortField | null) {
    case "application":
      return ListCandidatesV2OrderField.MostRecentApplicationDate;
    case "updated":
      return ListCandidatesV2OrderField.LastUpdateDate;
    default:
      return DEFAULT_ORDER.field;
  }
}

type CandidateSortDirection = "asc" | "desc";

function serializeSortDirection(
  value: SupportedCandidateOrderInput["direction"]
): CandidateSortDirection | null {
  switch (value) {
    case Sort.Asc:
      return "asc";
    case Sort.Desc:
      return "desc";
    default:
      assertNever(value);
  }
}

function deserializeSortDirection(
  value: string | null
): SupportedCandidateOrderInput["direction"] {
  switch (value as CandidateSortDirection | null) {
    case "asc":
      return Sort.Asc;
    case "desc":
      return Sort.Desc;
    default:
      return DEFAULT_ORDER.direction;
  }
}
