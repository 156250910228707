/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faChevronLeft,
  faPenToSquare,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compact } from "lodash";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DataTable } from "../../../../shared/components/data-table";
import { PageCard } from "../../../../shared/components/page-card";
import { useHasPermissionQuery } from "../../../../shared/modules/permissions/hooks/use-has-permission-query";
import { useSurveySettingsDataQuery } from "./survey-settings-data.generated";

const MAX_WIDTH = 1400;

export function SurveySettingsPageContents() {
  const { data, error } = useSurveySettingsDataQuery({
    onError: reportError,
  });

  const theme = useTheme();
  const navigate = useNavigate();

  const { hasPermission: hasFullSurveyPermission } = useHasPermissionQuery(
    "full",
    "surveys"
  );

  const checkInSurveySettingsEnabled = useFeatureFlag(
    "admin-app-check-in-survey-form-temp"
  );

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const goalsEnabled = !!data?.getMyOrganizationGoalConfig?.enabled;

  const surveyItems: {
    title: string;
    subtitle: string;
    isActive: boolean;
    linkTo: string;
  }[] = compact([
    {
      title: "Pulse survey",
      subtitle:
        "The pulse check survey enables caregivers to rate their experiences from a selection of configurable questions.",
      isActive:
        goalsEnabled &&
        (data?.getMyOrganizationGoalConfig
          ?.pulseCheckSurveyGoalDistributionConfig?.enabled ??
          false),
      linkTo: "/survey-settings/pulse",
    },
    checkInSurveySettingsEnabled && {
      title: "Check-in survey",
      subtitle:
        "This quick check-in survey enables caregivers to give feedback on a more frequent basis.",
      isActive:
        goalsEnabled &&
        (data?.getMyOrganizationGoalConfig
          ?.quickCheckSurveyGoalDistributionConfig?.enabled ??
          false),
      linkTo: "/survey-settings/check-in",
    },
  ]);

  return (
    <div
      css={css`
        max-width: ${MAX_WIDTH}px;
      `}
    >
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        linkTo="/surveys"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />
      <Typography variant="h3">Survey settings</Typography>
      <PageCard
        css={(theme: AppTheme) => css`
          margin: ${theme.spacing(3.75)} 0;
          padding: ${theme.spacing(3.75)};
        `}
      >
        <div
          css={css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          <Typography variant="h5">Survey settings</Typography>
        </div>
        <DataTable
          paddingDisabled
          itemsPerPage={surveyItems.length}
          loading={!data}
          items={surveyItems}
          total={surveyItems.length}
          tableHeaders={
            <TableRow>
              {
                <TableHeader size="normal">
                  <Typography fontWeight={700}>Name</Typography>
                </TableHeader>
              }

              <TableHeader>
                <Typography fontWeight={700}>Status</Typography>
              </TableHeader>
              <TableHeader width={170}>
                <Typography align="right" fontWeight={700}>
                  Action
                </Typography>
              </TableHeader>
            </TableRow>
          }
          tableBody={surveyItems.map((survey) => {
            return (
              <TableRow key={survey.title}>
                <TableCell divider={false}>
                  <div
                    css={css`
                      max-width: 600px;
                    `}
                  >
                    <Typography
                      css={css`
                        margin-bottom: ${theme.spacing(1)};
                      `}
                      variant="h6"
                    >
                      {survey.title}
                    </Typography>
                    <Typography variant="body" color={theme.palette.grey[800]}>
                      {survey.subtitle}
                    </Typography>
                  </div>
                </TableCell>

                <TableCell divider={false}>
                  <Typography>
                    {survey.isActive ? "Active" : "Inactive"}
                  </Typography>
                </TableCell>
                <TableCell divider={false}>
                  <div
                    css={css`
                      margin-left: auto;
                      width: fit-content;
                    `}
                  >
                    <Tooltip
                      title={
                        hasFullSurveyPermission
                          ? ""
                          : "You do not have edit access to surveys"
                      }
                    >
                      <IconButton
                        onClick={() => navigate(survey.linkTo)}
                        aria-label="Configure pulse survey"
                        disabled={!hasFullSurveyPermission}
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          size="sm"
                          color={hasFullSurveyPermission ? "black" : "grey.800"}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        />
      </PageCard>
    </div>
  );
}
