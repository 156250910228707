/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Checkbox } from "@material-ui/core";
import { compact } from "lodash";
import { Fragment } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { useFormControlContext } from "@rewards-web/shared/components/form/form-control";
import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableHeaders } from "@rewards-web/shared/components/table-components/table-headers";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsAdminRestrictedAccessPermissionLevel } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { ControlledFormFieldProps } from "@rewards-web/shared/types";

import { useAdminAppAuthenticatedOrganizationConfigQuery } from "../../../pages/authenticated/admin-app-authenticated-organization-config.generated";
import { useHasPermissionsQuery } from "../../modules/permissions/hooks/use-has-permissions-query";
import { usePermissionsTableRecognitionBudgetsEnabledQuery } from "./budgets-enabled.generated";

interface AdminPermission {
  permissionName: string;
  permissionKey: keyof AdminPermissionsFormValue;
  permissionDescription: string;
  view: boolean;
  edit: boolean;
  editPermissionDisabled?: boolean;
  additionalPermissions?: Array<{
    permissionName: string;
    permissionKey: keyof AdminPermissionsFormValue;
    permissionDescription: string;
    edit: boolean;
  }>;
}

export interface AdminPermissionsFormValue {
  jobs: RewardsAdminRestrictedAccessPermissionLevel | null;
  candidates: RewardsAdminRestrictedAccessPermissionLevel | null;
  redemptions: RewardsAdminRestrictedAccessPermissionLevel | null;
  recognitionPoints: RewardsAdminRestrictedAccessPermissionLevel | null;
  canOverrideRecognitionCategoryDefaultAmounts: boolean | null;
  canOverrideBudget: boolean | null;
  employees: RewardsAdminRestrictedAccessPermissionLevel | null;
  administrators: RewardsAdminRestrictedAccessPermissionLevel | null;
  surveys: RewardsAdminRestrictedAccessPermissionLevel | null;
}

function getCheckboxesState(
  value: RewardsAdminRestrictedAccessPermissionLevel | null = null
) {
  return {
    view:
      value === RewardsAdminRestrictedAccessPermissionLevel.FullAccess ||
      value === RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
    edit: value === RewardsAdminRestrictedAccessPermissionLevel.FullAccess,
  };
}

interface PermissionsTableProps extends ControlledFormFieldProps {
  disabled?: boolean;
}

export function PermissionsTable({
  value,
  disabled: disabledProp = false,
  onChange,
  error,
}: PermissionsTableProps): JSX.Element {
  const { readOnly } = useFormControlContext();
  const disabled = disabledProp || readOnly;

  const organizationConfigQuery = useAdminAppAuthenticatedOrganizationConfigQuery();
  const hasPermissionsQuery = useHasPermissionsQuery({
    recognitionPoints: "read",
  });

  const recognitionBudgetsEnabledQuery = usePermissionsTableRecognitionBudgetsEnabledQuery(
    {
      skip: hasPermissionsQuery.permissionResults?.recognitionPoints !== true,
      onError: reportError,
    }
  );
  const recognitionBudgetsEnabled =
    recognitionBudgetsEnabledQuery.data?.getMyRewardsOrganization
      ?.recognitionBudgetsEnabled;
  const rewardsProgramShortName =
    organizationConfigQuery.data?.getMyRewardsOrganization?.whiteLabelConfig
      ?.rewardsProgramShortName ?? "Caribou Rewards";
  function handleEditPermissionChange(
    permission: keyof AdminPermissionsFormValue
  ): void {
    if (permission === "canOverrideRecognitionCategoryDefaultAmounts") {
      onChange?.({
        ...value,
        canOverrideRecognitionCategoryDefaultAmounts: !value.canOverrideRecognitionCategoryDefaultAmounts,
      });
      return;
    }

    if (permission === "canOverrideBudget") {
      if (!recognitionBudgetsEnabled) {
        onChange?.({
          ...value,
          canOverrideBudget: null,
        });
        return;
      }
      onChange?.({
        ...value,
        canOverrideBudget: !value.canOverrideBudget,
      });
      return;
    }

    const previouslyChecked =
      value?.[permission] ===
      RewardsAdminRestrictedAccessPermissionLevel.FullAccess;

    if (previouslyChecked) {
      // leave `ReadOnly` checked for that permission when `FullAccess` is unchecked
      onChange?.({
        ...value,
        [permission]: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
        canOverrideRecognitionCategoryDefaultAmounts:
          permission === "recognitionPoints"
            ? null
            : value.canOverrideRecognitionCategoryDefaultAmounts,
        canOverrideBudget:
          permission === "recognitionPoints" ? null : value.canOverrideBudget,
      });
    } else {
      onChange?.({
        ...value,
        [permission]: RewardsAdminRestrictedAccessPermissionLevel.FullAccess,
        canOverrideRecognitionCategoryDefaultAmounts:
          permission === "recognitionPoints"
            ? true // default value
            : value.canOverrideRecognitionCategoryDefaultAmounts,
        canOverrideBudget:
          permission === "recognitionPoints"
            ? false // default value
            : value.canOverrideBudget,
      });
    }
  }

  function handleViewPermissionChange(
    permission: keyof AdminPermissionsFormValue
  ): void {
    if (
      value?.[permission] ===
        RewardsAdminRestrictedAccessPermissionLevel.ReadOnly ||
      value?.[permission] ===
        RewardsAdminRestrictedAccessPermissionLevel.FullAccess
    ) {
      onChange?.({
        ...value,
        [permission]: null,
        canOverrideRecognitionCategoryDefaultAmounts:
          permission === "recognitionPoints"
            ? null
            : value.canOverrideRecognitionCategoryDefaultAmounts,
        canOverrideBudget:
          permission === "recognitionPoints" ? null : value.canOverrideBudget,
      });
    } else {
      onChange?.({
        ...value,
        [permission]: RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
        canOverrideRecognitionCategoryDefaultAmounts:
          permission === "recognitionPoints"
            ? null
            : value.canOverrideRecognitionCategoryDefaultAmounts,
        canOverrideBudget:
          permission === "recognitionPoints" ? null : value.canOverrideBudget,
      });
    }
  }

  const permissions: AdminPermission[] = [
    {
      permissionName: "Jobs",
      permissionKey: "jobs",
      permissionDescription: `Add and edit job postings, so that employees can share open job postings through ${rewardsProgramShortName}`,
      ...getCheckboxesState(value.jobs ?? null),
    },
    {
      permissionName: "Candidates",
      permissionKey: "candidates",
      permissionDescription:
        "Track a candidate's progress through the hiring process, so that employees that have shared job postings get points where appropriate",
      ...getCheckboxesState(value.candidates ?? null),
    },
    {
      permissionName: "Redemptions",
      permissionKey: "redemptions",
      permissionDescription: "Mark a redemption request as sent to payroll",
      ...getCheckboxesState(value.redemptions ?? null),
    },
    {
      permissionName: "Recognition Points",
      permissionKey: "recognitionPoints",
      permissionDescription:
        "Send a tailored text message and recognition points to an employee, so that they are rewarded for doing something special",
      ...getCheckboxesState(value.recognitionPoints ?? null),
      additionalPermissions: compact([
        typeof value.canOverrideRecognitionCategoryDefaultAmounts ===
          "boolean" && {
          permissionKey: "canOverrideRecognitionCategoryDefaultAmounts",
          permissionName: "User can override default category amounts",
          permissionDescription:
            "Override and edit the the default points amount for a given category. Only this permission can view and give out any recognition amount for categories that do not have a default point amount.",
          edit: value.canOverrideRecognitionCategoryDefaultAmounts,
        },
        recognitionBudgetsEnabled &&
          typeof value.canOverrideRecognitionCategoryDefaultAmounts ===
            "boolean" && {
            permissionKey: "canOverrideBudget",
            permissionName: "User can override budget",
            permissionDescription: "Override their recognition budget.",
            edit: value.canOverrideBudget ?? false,
          },
      ]),
    },
    {
      permissionName: "Employees",
      permissionKey: "employees",
      permissionDescription:
        "Add new employees so that they can start sharing jobs and earning points",
      ...getCheckboxesState(value.employees ?? null),
    },
    {
      permissionName: "Surveys",
      permissionKey: "surveys",
      permissionDescription: "Configure survey questions, rewards, and more",
      ...getCheckboxesState(value.surveys ?? null),
    },
    {
      permissionName: "Administrators",
      permissionKey: "administrators",
      permissionDescription:
        "Only full-access admins are allowed to create/edit other admins",
      editPermissionDisabled: true,
      ...getCheckboxesState(value.administrators ?? null),
    },
  ];

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      {error && (
        <Alert
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
          severity="error"
          message={error.message ?? "You have not specified valid permissions."}
        />
      )}
      <Table>
        <TableHeaders>
          <TableRow>
            <TableHeader topDivider rightDivider>
              <Typography
                css={css`
                  font-weight: bold;
                `}
                variant="subtitle"
              >
                Permissions
              </Typography>
            </TableHeader>
            <TableHeader topDivider rightDivider align="center">
              <Typography
                css={css`
                  font-weight: bold;
                `}
                variant="subtitle"
              >
                View
              </Typography>
            </TableHeader>
            <TableHeader topDivider align="center">
              <Typography
                css={css`
                  font-weight: bold;
                `}
                variant="subtitle"
              >
                Edit
              </Typography>
            </TableHeader>
          </TableRow>
        </TableHeaders>
        <TableBody>
          {permissions.map((permission) => {
            return (
              <Fragment key={permission.permissionName}>
                <TableRow highlightOnHover={!disabled}>
                  <TableCell
                    divider
                    rightDivider
                    dividerStyle={
                      (permission.additionalPermissions ?? []).length > 0
                        ? "dashed"
                        : "solid"
                    }
                  >
                    <Typography variant="body" color="textSecondary">
                      {permission.permissionName}
                    </Typography>
                  </TableCell>
                  <TableCell
                    divider
                    rightDivider
                    align="center"
                    shrink
                    dividerStyle={
                      (permission.additionalPermissions ?? []).length > 0
                        ? "dashed"
                        : "solid"
                    }
                  >
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      css={css`
                        width: 40px;
                        max-height: 20px;
                      `}
                      checked={permission.view}
                      onChange={() =>
                        handleViewPermissionChange(permission.permissionKey)
                      }
                    />
                  </TableCell>
                  <TableCell
                    divider
                    align="center"
                    shrink
                    dividerStyle={
                      (permission.additionalPermissions ?? []).length > 0
                        ? "dashed"
                        : "solid"
                    }
                  >
                    <Tooltip
                      placement="top"
                      title={permission.permissionDescription}
                      disabled={readOnly}
                    >
                      <Checkbox
                        disabled={disabled || permission.editPermissionDisabled}
                        color="primary"
                        css={css`
                          width: 40px;
                          max-height: 20px;
                        `}
                        checked={
                          permission.editPermissionDisabled
                            ? false
                            : permission.edit
                        }
                        onChange={() =>
                          handleEditPermissionChange(permission.permissionKey)
                        }
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
                {permission.additionalPermissions?.map(
                  (additionalPermission) => (
                    <TableRow
                      key={additionalPermission.permissionKey}
                      highlightOnHover={!disabled}
                    >
                      <TableCell divider rightDivider>
                        <ul
                          css={(theme: AppTheme) => css`
                            margin: 0;
                            padding-left: ${theme.spacing(3)};
                          `}
                        >
                          <li>
                            <Typography variant="body" color="textSecondary">
                              {additionalPermission.permissionName}
                            </Typography>
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell divider rightDivider align="center" shrink />
                      <TableCell divider align="center" shrink>
                        <Tooltip
                          placement="top"
                          title={additionalPermission.permissionDescription}
                          disabled={readOnly}
                        >
                          <Checkbox
                            disabled={disabled}
                            color="primary"
                            css={css`
                              width: 40px;
                              max-height: 20px;
                            `}
                            checked={additionalPermission.edit}
                            onChange={() =>
                              handleEditPermissionChange(
                                additionalPermission.permissionKey
                              )
                            }
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
