/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { RadioGroup } from "@rewards-web/shared/components/radio-buttons/radio-group";
import { Typography } from "@rewards-web/shared/components/typography";
import { AdminGoalUpdateTarget } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { ModalRadioOption } from "../../../../../../shared/components/modal-radio-option";
import { usePartialUpdateAdminGoalConfigMutation } from "../partial-update-admin-goal-config.generated";
import { useDisableAdminGoalConfigMutation } from "./disable-admin-goal-config.generated";

export interface RemoveGoalConfirmationModalProps {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  configId: string;
  recurring: boolean;
}

interface RemoveGoalConfirmationModalFormValues {
  stopOrRemoveGoal:
    | "stopRecurring"
    | "removeRecurring"
    | "stopCurrent"
    | "removeCurrent";
}

export function RemoveGoalConfirmationModal({
  open,
  onClose,
  onConfirm,
  configId,
  recurring,
}: RemoveGoalConfirmationModalProps) {
  const track = useTrack();
  const snackbar = useSnackbar();
  const form = useForm<RemoveGoalConfirmationModalFormValues>({
    defaultValues: {
      stopOrRemoveGoal: recurring ? "stopRecurring" : "stopCurrent",
    },
  });
  const { control, handleSubmit, formState } = form;
  const [
    partialUpdateOrganizationAdminGoalConfig,
  ] = usePartialUpdateAdminGoalConfigMutation();
  const [disableAdminGoalConfig] = useDisableAdminGoalConfigMutation();
  useEffect(() => {
    if (open) {
      form.reset({
        stopOrRemoveGoal: recurring ? "stopRecurring" : "stopCurrent",
      });
    }
  }, [open, form, recurring]);

  const onSubmit = async (values: RemoveGoalConfirmationModalFormValues) => {
    switch (values.stopOrRemoveGoal) {
      case "stopRecurring":
        await partialUpdateOrganizationAdminGoalConfig({
          variables: {
            configId,
            recurring: false,
            updateTargets: [AdminGoalUpdateTarget.Current],
            props: {},
          },
          onError: (error) => {
            reportError(error);
            snackbar.show({
              severity: "error",
              message: "Your goal could not be updated. Please try again.",
            });
          },
          onCompleted: () => {
            snackbar.show({
              severity: "success",
              message: "Your goal will continue until the end of the period.",
            });
            onConfirm();
          },
        });
        break;
      case "removeRecurring":
      case "removeCurrent":
        await disableAdminGoalConfig({
          variables: {
            configId,
          },
          onError: (error) => {
            reportError(error);
            snackbar.show({
              severity: "error",
              message: "Your goal could not be removed. Please try again.",
            });
          },
          onCompleted: () => {
            snackbar.show({
              severity: "success",
              message: "Your goal has been removed successfully.",
            });
            onConfirm();
          },
        });
        break;
      case "stopCurrent":
        snackbar.show({
          severity: "success",
          message: "Your goal will continue until the end of the period.",
        });
        onConfirm();
        break;
      default:
        break;
    }
  };

  return (
    <Modal width="714px" open={open} onClose={onClose}>
      <ModalTitle>
        <Typography variant="h5">
          {recurring ? "Remove Recurring Goal" : "Remove Goal"}
        </Typography>
      </ModalTitle>
      <ModalContent>
        <Form
          css={css`
            display: grid;
          `}
          submitting={formState.isSubmitting}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="stopOrRemoveGoal"
            rules={{
              required:
                "You must select one option to either stop or remove the goal",
            }}
            render={({ field, fieldState }) => (
              <>
                {recurring && (
                  <RadioGroup
                    ariaLabel="stop or remove admin goal"
                    {...field}
                    error={fieldState.error}
                  >
                    <ModalRadioOption
                      value="stopRecurring"
                      title="Continue current goal until the end of the period (recommended)"
                      description="Let the current goal continue until its scheduled end, but prevent future ones from running."
                      selected={field.value === "stopRecurring"}
                    />
                    <ModalRadioOption
                      value="removeRecurring"
                      title="Remove goal immediately"
                      description="Remove the current goal immediately and prevent future ones from running. Rewards committed to the office staff will no longer be sent out."
                      selected={field.value === "removeRecurring"}
                    />
                  </RadioGroup>
                )}

                {!recurring && (
                  <RadioGroup
                    ariaLabel="stop or remove admin goal"
                    {...field}
                    error={fieldState.error}
                  >
                    <ModalRadioOption
                      value="removeCurrent"
                      title="Remove goal immediately"
                      description="Remove the current goal immediately. No reward will be sent out."
                      selected={field.value === "removeCurrent"}
                    />
                    <ModalRadioOption
                      value="stopCurrent"
                      title="Continue current goal until the end of the period"
                      description="Let the current goal continue until its scheduled end."
                      selected={field.value === "stopCurrent"}
                    />
                  </RadioGroup>
                )}
              </>
            )}
          />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button
          variant="outlined"
          onClick={() => {
            track("Clicked to cancel remove admin goal", {
              recurring,
            });
            onClose();
          }}
          label="Cancel"
          size="medium"
          width="auto"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            track("Clicked confirm to remove admin goal", {
              recurring,
            });
            handleSubmit(onSubmit)();
          }}
          label="Confirm"
          size="medium"
          width="auto"
          loading={formState.isSubmitting}
        />
      </ModalActions>
    </Modal>
  );
}
