/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { CardContent } from "@rewards-web/shared/components/card";
import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

interface PageCardTitleProps extends StylableProps {
  title: string;
  noDivider?: boolean;
}

export function PageCardTitle({
  title,
  noDivider,
  className,
}: PageCardTitleProps) {
  return (
    <>
      <CardContent
        className={className}
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
        `}
      >
        <Typography
          css={css`
            font-weight: 800;
          `}
          color="textPrimary"
          variant="h5"
          component="h2"
        >
          {title}
        </Typography>
      </CardContent>
      {!noDivider && <Divider />}
    </>
  );
}
