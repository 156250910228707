/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  RewardReportDataSetType,
  SurveyInsightsDateRange,
  SurveyInsightsDateRangeInput,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DownloadReportSuccessModal } from "../../../../shared/components/download-report-success-modal";
import { DateRangeSelectField } from "../components/date-range-select-field";
import { SurveyAgencyAverageCard } from "../components/survey-agency-average-card";
import { SurveyEngagementCard } from "../components/survey-engagement-card";
import { SurveyInfoCard } from "../components/survey-info-card";
import { PULSE_SURVEY_QUESTIONS } from "../constants";
import { convertValueToDateRange } from "../utils";
import { usePulseSurveyAvailableDateRangesQuery } from "./pulse-survey-available-date-ranges.generated";
import { usePulseSurveyDataQuery } from "./pulse-survey-data.generated";
import { usePulseSurveyOverviewDataQuery } from "./pulse-survey-overview-data.generated";
import { useSubmitPulseSurveyReportRequestMutation } from "./submit-pulse-survey-report-request.generated";
import { SurveyQuestionScoreCard } from "./survey-question-score-card";

export interface PulseSurveysPageContentsProps {
  surveySettingsEnabled: boolean;
}

export function PulseSurveysPageContents(props: PulseSurveysPageContentsProps) {
  const downloadReportEnabled = useFeatureFlag(
    "admin-app-download-pulse-survey-report-enabled-temp"
  );
  const snackbar = useSnackbar();
  const track = useTrack();
  const [searchParams] = useSearchParams();

  const { data } = usePulseSurveyDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const isLaunched = Boolean(data?.getMyRewardsOrganization.launched);

  const {
    data: dateRangeData,
    error: dateRangeError,
  } = usePulseSurveyAvailableDateRangesQuery({
    onError: reportError,
    skip: !isLaunched,
    fetchPolicy: "cache-first",
  });
  const [
    submitReportRequest,
    { loading: isSubmittingReport },
  ] = useSubmitPulseSurveyReportRequestMutation();
  const [
    downloadReportSuccessModalOpen,
    setDownloadReportSuccessModalOpen,
  ] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState<
    SurveyInsightsDateRange | undefined
  >();

  const dateRangeInput = ((): SurveyInsightsDateRangeInput | undefined => {
    if (!selectedDateRange) {
      return undefined;
    }

    return {
      label: selectedDateRange.label,
      year: selectedDateRange.year,
    };
  })();

  const { data: overviewData, error } = usePulseSurveyOverviewDataQuery({
    onError: reportError,
    skip: !dateRangeInput,
    variables: {
      dateRange: dateRangeInput,
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    // set initial date range on load
    if (dateRangeData && !selectedDateRange) {
      const searchedDateRange = searchParams.get("dateRange");

      // initialize with searched date range, or else most recent
      const dateRange = searchedDateRange
        ? convertValueToDateRange(
            searchedDateRange,
            dateRangeData.getAvailableSurveyDateRanges
          )
        : dateRangeData.getAvailableSurveyDateRanges[0];

      return setSelectedDateRange(dateRange);
    }
  }, [dateRangeData, selectedDateRange, searchParams]);

  const theme = useTheme();

  if (error || dateRangeError) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!data || (!dateRangeData && isLaunched)) {
    return <PageLoadingState />;
  }
  const pulseCheckSurveysEnabled =
    data?.getMyOrganizationGoalConfig?.enabled &&
    data?.getMyOrganizationGoalConfig?.pulseCheckSurveyGoalDistributionConfig
      ?.enabled;
  const noSurveysSentAllTime = data?.allTimeSurveyEnagement.numSent === 0;
  const noSurveysSentForPeriod =
    overviewData?.getSurveyEngagement.numSent === 0;
  const noResponsesForPeriod =
    overviewData?.getSurveyEngagement.numResponses === 0;

  const handleDownloadReportClicked = async () => {
    try {
      const timezone =
        data?.getMyRewardsOrganization.timezone ?? "America/New_York"; // fallback shouldn't be needed, just being defensive

      const res = await submitReportRequest({
        variables: {
          dataSets: [
            {
              type: RewardReportDataSetType.SurveyReportByCaregiver,
              surveyReportByCaregiverParameters: {
                // convert date-only strings to date objects
                // in the given timezone
                dateRangeStart: selectedDateRange?.startDate
                  ? zonedTimeToUtc(
                      parseISO(selectedDateRange.startDate),
                      timezone
                    )
                  : undefined,
                dateRangeEnd: selectedDateRange?.endDate
                  ? zonedTimeToUtc(
                      parseISO(selectedDateRange.endDate),
                      timezone
                    )
                  : undefined,
              },
            },
          ],
        },
      });

      track("Generated pulse survey report", {
        reportId: res.data?.submitReportRequest.id,
      });
      setDownloadReportSuccessModalOpen(true);
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "Failed to download report. Please try again later.",
      });
    }
  };

  if (noSurveysSentAllTime) {
    if (pulseCheckSurveysEnabled) {
      return (
        <SurveyInfoCard
          title="Pulse survey is set to launch on start date"
          content={
            <Typography variant="footnote" color="grey.800" textAlign="center">
              Insights will start appearing here as responses come in.
            </Typography>
          }
        />
      );
    } else {
      if (props.surveySettingsEnabled) {
        return (
          <SurveyInfoCard
            title="Get started with pulse surveys"
            content={
              <>
                <Typography
                  variant="footnote"
                  color="grey.800"
                  textAlign="center"
                >
                  Looks like there aren’t any surveys yet. Head over to the
                  settings page to create your first survey and start gathering
                  employee feedback.
                </Typography>
                <Button
                  label="Set up a survey"
                  variant="contained"
                  width="auto"
                  color="primary"
                  linkTo="/survey-settings/pulse"
                  linkState={{ backTo: "/surveys/pulse" }}
                />
              </>
            }
          />
        );
      } else {
        return (
          <SurveyInfoCard
            title="Get started with pulse surveys"
            content={
              <Typography
                variant="footnote"
                color="grey.800"
                textAlign="center"
              >
                Looks like there aren’t any surveys yet. Create your first
                survey and start gathering employee feedback.
              </Typography>
            }
          />
        );
      }
    }
  }

  const hasResponsesForPeriod = !noResponsesForPeriod;

  const content = (() => {
    if (!overviewData) {
      return <PageLoadingState />;
    }
    if (noSurveysSentForPeriod) {
      return (
        <Alert
          severity="info"
          message="No surveys sent during this time period."
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        />
      );
    } else if (noResponsesForPeriod) {
      return (
        <Alert
          severity="info"
          message="No responses recieved during this time period."
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        />
      );
    }
    return (
      <>
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            flex-wrap: wrap;
            gap: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          <SurveyEngagementCard
            dateRange={selectedDateRange}
            numSent={overviewData.getSurveyEngagement.numSent}
            numResponses={overviewData.getSurveyEngagement.numResponses}
            responseRatePercentage={Math.round(
              overviewData.getSurveyEngagement.responseRate * 100
            )} // round to closest percentage
            reward={overviewData.getSurveyEngagement.reward}
          />

          <SurveyAgencyAverageCard
            averageScore={
              Math.round(overviewData.getSurveyAverageScore.averageScore * 10) /
              10
            } // round to 1 decimal place
            previousScore={
              overviewData.getSurveyAverageScore.previousAverageScore
                ? Math.round(
                    overviewData.getSurveyAverageScore.previousAverageScore * 10
                  ) / 10
                : undefined
            }
          />
        </div>
        <Card
          css={(theme: AppTheme) =>
            css`
              padding: ${theme.spacing(3)};
            `
          }
        >
          <Typography
            variant="h5"
            color="textPrimary"
            fontWeight={700}
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(3)};
              `
            }
          >
            Results
          </Typography>
          <div
            css={css`
              display: grid;
              gap: ${theme.spacing(3)};
              grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            `}
          >
            {PULSE_SURVEY_QUESTIONS.map((questionType) => (
              <SurveyQuestionScoreCard
                key={questionType}
                questionType={questionType}
                dateRangeInput={dateRangeInput}
              />
            ))}
          </div>
        </Card>
      </>
    );
  })();

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <Typography variant="h5" color="textPrimary" fontWeight={700}>
          Overview
        </Typography>
        <div
          css={css`
            display: flex;
            gap: ${theme.spacing(1)};
            align-items: center;
          `}
        >
          <DateRangeSelectField
            value={selectedDateRange}
            options={dateRangeData?.getAvailableSurveyDateRanges ?? []}
            onChange={(dateRange: SurveyInsightsDateRange) =>
              setSelectedDateRange(dateRange)
            }
          />
          {downloadReportEnabled && hasResponsesForPeriod && (
            <>
              <Button
                variant="contained"
                color="primary"
                label="Download report"
                size="small"
                minWidthPx={160}
                onClick={handleDownloadReportClicked}
                loading={isSubmittingReport}
              />
              <DownloadReportSuccessModal
                open={downloadReportSuccessModalOpen}
                onClose={() => setDownloadReportSuccessModalOpen(false)}
                minutesToDownloadEstimation={5}
              />
            </>
          )}
        </div>
      </div>
      {content}
    </>
  );
}
