/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { Radio } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { forwardRef } from "react";

interface RadioButtonProps {
  value: string;
  label: string | React.ReactElement;
  checked?: boolean;
  hideLabel?: boolean;
  disabled?: boolean;
}

export const RadioButton = forwardRef(
  (
    { value, label, checked, hideLabel, disabled }: RadioButtonProps,
    ref
  ): JSX.Element => {
    return (
      <ClassNames>
        {({ css, theme }) => (
          <FormControlLabel
            ref={ref}
            value={value}
            control={
              <Radio
                checked={checked}
                classes={{
                  root: css`
                    &.Mui-checked {
                      color: ${theme.palette.primary.main};
                    }
                    stroke: white;
                    stroke-width: 0.5px;
                    &.Mui-disabled {
                      color: ${theme.palette.grey[400]};
                    }
                  `,
                }}
                color="primary"
                disabled={disabled}
              />
            }
            label={hideLabel ? null : label}
          />
        )}
      </ClassNames>
    );
  }
);
