/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/client";
import { css } from "@emotion/react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { compact } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useDebounce } from "use-debounce";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button, ButtonProps } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { SelectField } from "@rewards-web/shared/components/select-field";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { Role } from "@rewards-web/shared/graphql-types";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { FormStepper } from "../../../../shared/components/form-stepper";
import { InfoIcon } from "../../../../shared/components/info-icon";
import { SideMenuDataDocument } from "../../../../shared/components/page-layout/side-menu/side-menu-data.generated";
import { RecognitionListDocument } from "../awarded-recognition-points-page/recognition-points-list.generated";
import { useMyBudgetsDataQuery } from "../my-budgets-data.generated";
import { ManagedEmployeesRecognitionDataTableDocument } from "../my-recognition-summary-page/managed-employees-recognition-data-table/managed-employees-recognition-data-table-query.generated";
import { RecognitionPointsReceivedSummaryDocument } from "../my-recognition-summary-page/recognition-points-received-summary.generated";
import { RecognitionPointsSentSummaryDocument } from "../my-recognition-summary-page/recognition-points-sent-summary.generated";
import { useRecognitionPointsModalBudgetSummaryQuery } from "../recognition-points-modal-budget-summary.generated";
import { EnabledRecognitionPageTabsDataDocument } from "../use-enabled-recognition-page-tabs-data.generated";
import { useAwardBonusPointsMutation } from "./award-recognition-points.generated";
import { OverBudgetConfirmationModal } from "./overbudget-confirmation-modal";
import { useRecognitionModalDataQuery } from "./recognition-modal-data.generated";
import { useBonusPointsUserSearchQuery } from "./recognition-points-user-search.generated";
import { useUpdateRecognitionPointsMessageMutation } from "./update-recognition-points-message.generated";

const SEARCH_DEBOUNCE_MS = 300;
const MAX_MESSAGE_LENGTH = 300;
const MAX_SENDER_NAME_LENGTH = 50;
const MIN_AWARDABLE_POINTS = 1;
const MAX_AWARDABLE_POINTS = 100_000; // $1,000.00
const INTEGERS_REGEX = /^([+-]?[1-9]\d*|0)$/;

export interface RecognitionPointsModalProps {
  open: boolean;
  onClose(): void;
  onExited?(): void;

  /**
   * If provided, puts the modal into 'message editing mode',
   * only allowing the admin to update the message.
   */
  editingMessageForRecognitionPoints?: {
    recognitionPointsId: string;
    userId: string;
    pointValue: number;
    message: string | null;
    defaultMessage: string | null;
    categoryId: string | null;
    from: string | null;
  };
}

interface RecognitionPointsFormValues {
  userId: string | null;
  numberOfPoints: string;
  messageToUser: string;
  categoryId: string | undefined;
  from: string | null;
}

const DEFAULT_FORM_VALUES: RecognitionPointsFormValues = {
  userId: null,
  numberOfPoints: "",
  messageToUser: "",
  categoryId: "",
  from: "",
};

export function RecognitionPointsModal({
  open,
  onClose,
  onExited,
  editingMessageForRecognitionPoints,
}: RecognitionPointsModalProps): JSX.Element {
  const track = useTrack();
  const snackbar = useSnackbar();
  const apolloClient = useApolloClient();

  const [awardRecognitionPoints] = useAwardBonusPointsMutation();
  const [
    updateRecognitionPointsMessage,
  ] = useUpdateRecognitionPointsMessageMutation();
  const [referringUserSearchQuery, setReferringUserSearchQuery] = useState("");
  const [debouncedUserSearchQuery] = useDebounce(
    referringUserSearchQuery,
    SEARCH_DEBOUNCE_MS
  );
  const query = useRecognitionModalDataQuery({
    onError: reportError,
  });

  const isSuperuser = query.data?.myIdentity?.roles.some(
    (role) => role.roleName === Role.Superuser
  );
  const myBudgetsQuery = useMyBudgetsDataQuery({
    skip: !query.data?.myOrganization.recognitionBudgetsEnabled || isSuperuser,
    onError: reportError,
  });

  const currentBudgetPeriod = myBudgetsQuery.data?.listMyRecognitionBudgetPeriods?.items.find(
    (period) => period.current
  );

  const currentBudgetSummaryQuery = useRecognitionPointsModalBudgetSummaryQuery(
    {
      skip: !currentBudgetPeriod,
      onError: reportError,
      variables: {
        startDate: currentBudgetPeriod?.startDate,
        endDate: currentBudgetPeriod?.endDate,
      },
    }
  );

  const userSearchQuery = useBonusPointsUserSearchQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
    variables: {
      searchQuery: debouncedUserSearchQuery || null,
    },
  });

  const activeRewardsUserRole = query.data?.myIdentity?.roles.filter(
    (role) => role.roleName === Role.RewardsUser
  )[0];

  const form = useForm<RecognitionPointsFormValues>({
    mode: "onChange",
    defaultValues: { ...DEFAULT_FORM_VALUES },
  });

  const {
    control,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = form;

  const [
    budgetConfirmationModalOpen,
    setBudgetConfirmationModalOpen,
  ] = useState(false);

  // reset form when modal opens
  useEffect(() => {
    if (open) {
      const defaultValues: RecognitionPointsFormValues = editingMessageForRecognitionPoints
        ? {
            userId: editingMessageForRecognitionPoints.userId,
            numberOfPoints: String(
              editingMessageForRecognitionPoints.pointValue
            ),
            messageToUser:
              editingMessageForRecognitionPoints.message ||
              editingMessageForRecognitionPoints.defaultMessage ||
              "",
            categoryId:
              editingMessageForRecognitionPoints.categoryId ?? undefined,
            from: editingMessageForRecognitionPoints.from ?? "",
          }
        : {
            ...DEFAULT_FORM_VALUES,
          };
      reset(defaultValues);
    }
  }, [editingMessageForRecognitionPoints, open, reset]);

  const numberOfPoints = useWatch({ control, name: "numberOfPoints" });
  const messageToUser = useWatch({ control, name: "messageToUser" });
  const categoryIdValue = useWatch({ control, name: "categoryId" });

  useEffect(() => {
    // automatically set the point value based on the category
    // if the user hasn't changed it themselves

    if (
      !editingMessageForRecognitionPoints &&
      categoryIdValue &&
      !form.formState.dirtyFields.numberOfPoints
    ) {
      const category = query.data?.usableCategories.find(
        (category) => category.id === categoryIdValue
      );

      if (category?.defaultPointAmount) {
        form.setValue("numberOfPoints", String(category.defaultPointAmount), {
          shouldDirty: false,
          shouldValidate: true,
        });
      } else {
        form.setValue("numberOfPoints", "", {
          shouldDirty: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIdValue]);

  const handleSubmitSendPoints = async (
    values: RecognitionPointsFormValues
  ) => {
    try {
      const response = await awardRecognitionPoints({
        variables: {
          amount: Number(values.numberOfPoints),
          userId: values.userId!,
          messageToUser: values.messageToUser,
          categoryId: values.categoryId,
          from: values.from ?? "",
        },
      });
      track("Awarded bonus points", {
        bonusPointsId: response.data?.awardUserBonusPoints?.id,
        categoryDefaultAmount: (() => {
          if (!values.categoryId) {
            return null;
          }

          const category = query.data?.usableCategories.find(
            (category) => category.id === values.categoryId
          );

          if (category?.defaultPointAmount) {
            return category.defaultPointAmount;
          }
        })(),
      });
      onClose();

      // refetch various queries
      apolloClient.refetchQueries({
        include: [
          RecognitionListDocument,
          RecognitionPointsReceivedSummaryDocument,
          RecognitionPointsSentSummaryDocument,
          ManagedEmployeesRecognitionDataTableDocument,
        ],
      });

      snackbar.show({
        severity: "success",
        message: "Recognition points have been sent!",
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    }
  };

  const handleSubmitUpdateMessage = async (
    values: RecognitionPointsFormValues
  ) => {
    try {
      const res = await updateRecognitionPointsMessage({
        variables: {
          recognitionPointsId: editingMessageForRecognitionPoints!
            .recognitionPointsId,
          message: values.messageToUser,
        },
      });

      track("Updated recognition points message", {
        recognitionPointsId: editingMessageForRecognitionPoints!
          .recognitionPointsId,
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
      });
      onClose();

      snackbar.show({
        severity: "success",
        message: editingMessageForRecognitionPoints!.message
          ? "Your message has been updated"
          : "Your message has been added",
      });

      apolloClient.refetchQueries({
        include: [EnabledRecognitionPageTabsDataDocument, SideMenuDataDocument],
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    }
  };

  const canOverrideBudget =
    query.data?.myAdmin?.permissions.__typename ===
      "RewardsAdminFullAccessPermissions" ||
    (query.data?.myAdmin?.permissions.__typename ===
      "RewardsAdminRestrictedAccessPermissions" &&
      query.data?.myAdmin.permissions.permissionsV2?.recognitionPoints
        ?.canOverrideBudget === true);

  const pointsAvailableInBudget =
    currentBudgetSummaryQuery.data?.getMyRecognitionPointsSentSummaryV2
      .pointsAvailableInBudget ?? 0;

  const selectedPointValueIsOverBudget =
    query.data?.myOrganization.recognitionBudgetsEnabled &&
    parseInt(numberOfPoints, 10) > pointsAvailableInBudget;

  const handleSubmit = (values: RecognitionPointsFormValues) => {
    if (editingMessageForRecognitionPoints) {
      return handleSubmitUpdateMessage(values);
    } else if (canOverrideBudget && selectedPointValueIsOverBudget) {
      setBudgetConfirmationModalOpen(true);
    } else {
      return handleSubmitSendPoints(values);
    }
  };

  const handleOverBudgetConfirmationModalConfirm = () => {
    setBudgetConfirmationModalOpen(false);
    return handleSubmitSendPoints(form.getValues());
  };

  const handleOverBudgetConfirmationModalCancel = () => {
    setBudgetConfirmationModalOpen(false);
  };

  const content = (() => {
    if (query.error) {
      return (
        <Alert
          severity="error"
          message="Something went wrong. Please try again later."
        />
      );
    }

    if (!query.data || query.loading) {
      return <PageLoadingState />;
    }

    const cannotManuallySetPointAmount =
      query.data.myAdmin?.permissions.__typename ===
        "RewardsAdminRestrictedAccessPermissions" &&
      query.data.myAdmin.permissions.permissionsV2?.recognitionPoints
        ?.canOverrideRecognitionCategoryDefaultAmounts === false;

    const usableCategories = (() => {
      const allCategories = query.data!.usableCategories;

      if (cannotManuallySetPointAmount) {
        // if admin cannot overwrite default point amount,
        // only include those categories
        return allCategories.filter((category) => category.defaultPointAmount);
      }

      return allCategories;
    })();

    const categoryFieldValues = usableCategories.map((category) => {
      return {
        label: category.name,
        value: category.id,
      };
    });

    const isSelf = (userId: string) => {
      return activeRewardsUserRole && userId === activeRewardsUserRole.id;
    };

    const submitButtonProps = ((): ButtonProps => {
      const commonButtonProps = {
        type: "submit" as const,
        loading: isSubmitting,
      };

      if (editingMessageForRecognitionPoints) {
        if (editingMessageForRecognitionPoints.message) {
          return {
            ...commonButtonProps,
            label: "Edit Message",
            color: "primary",
          };
        } else {
          return {
            ...commonButtonProps,
            label: "Add Message",
            color: "secondary",
          };
        }
      } else {
        return {
          ...commonButtonProps,
          label: "Send Message",
          color: "primary",
        };
      }
    })();

    const pointsPerDollar = query.data.myOrganization.pointsPerDollar;
    const numberOfPointsHelperText = ((): string => {
      if (
        !pointsPerDollar ||
        !Boolean(parseInt(numberOfPoints, 10)) ||
        parseInt(numberOfPoints, 10) < MIN_AWARDABLE_POINTS ||
        parseInt(numberOfPoints, 10) > MAX_AWARDABLE_POINTS
      ) {
        return "";
      }

      const pointsToUseForNote = numberOfPoints
        ? parseInt(numberOfPoints, 10)
        : pointsPerDollar;

      return `Note: ${numberWithCommas(
        pointsToUseForNote
      )} points are worth ${formatDollars(
        pointsToUseForNote / pointsPerDollar
      )}`;
    })();

    const confirmStepDescription = (() => {
      if (editingMessageForRecognitionPoints) {
        return `Press "${submitButtonProps.label}" to set a personalized message.`;
      }

      return parseInt(numberOfPoints, 10) && referringUserSearchQuery
        ? `Press "Send Message" to send ${numberWithCommas(
            parseInt(numberOfPoints, 10)
          )} points and this
  text message to ${referringUserSearchQuery}.`
        : `Send a personalized text message and recognition points to a
  superstar! ${numberOfPointsHelperText}`;
    })();

    return (
      <Form
        onSubmit={form.handleSubmit(handleSubmit)}
        submitting={isSubmitting}
        css={css`
          display: contents;
        `}
      >
        <ModalContent>
          <FormStepper
            steps={[
              {
                title: "Sender details",
                content: (
                  <>
                    <Controller
                      control={control}
                      name="userId"
                      rules={{
                        required: "Employee name is required",
                        validate: (userId) => {
                          if (editingMessageForRecognitionPoints) {
                            return; // disable validation on this field when editing
                          }

                          if (userId && isSelf(userId)) {
                            return "You can not give recognition points to yourself";
                          } else if (
                            userId &&
                            userSearchQuery.data?.searchForRewardsUsers.find(
                              (user) => user.id === userId
                            )?.active === false
                          ) {
                            return "De-activated users can not receive recognition points";
                          }
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <SearchTextField
                          {...field}
                          disabled={Boolean(editingMessageForRecognitionPoints)}
                          error={fieldState.error}
                          label="Employee name"
                          options={(
                            (
                              userSearchQuery.data ??
                              userSearchQuery.previousData
                            )?.searchForRewardsUsers ?? []
                          ).map((user) => {
                            return {
                              value: user.id,
                              label: `${user.firstName} ${user.lastName}`,
                              subLabels: compact([
                                (user.personalContactInfo?.__typename ===
                                  "RewardsUserPersonalContactInfoData" &&
                                  user.personalContactInfo.email) ||
                                  user.workEmail,
                                (user.personalContactInfo?.__typename ===
                                  "RewardsUserPersonalContactInfoData" &&
                                  user.personalContactInfo.phoneNumber) ||
                                  user.workPhoneNumber,
                                user.branch?.name,
                                !user.active && "Deactivated",
                              ]),
                            };
                          })}
                          loadingOptions={userSearchQuery.loading}
                          onInputChange={(text) =>
                            setReferringUserSearchQuery(text)
                          }
                          onChange={(userId) => {
                            if (userId && isSelf(userId)) {
                              track("Attempted awarding bonus points to self", {
                                userId,
                              });
                            }
                            field.onChange(userId);
                          }}
                          debouncedInputValue={debouncedUserSearchQuery}
                          closedWhenInputTextEmpty
                          placeholder="Type to search..."
                        />
                      )}
                    />

                    <TextField
                      label="Your name / who this message is from"
                      error={errors.from}
                      disabled={Boolean(editingMessageForRecognitionPoints)}
                      {...register("from", {
                        required: editingMessageForRecognitionPoints
                          ? undefined
                          : "Sender's name is required",
                        validate: (from) => {
                          if (from && from.length > MAX_SENDER_NAME_LENGTH) {
                            return getCharactersRemainingText(
                              from,
                              MAX_SENDER_NAME_LENGTH
                            );
                          }
                        },
                      })}
                    />
                  </>
                ),
              },
              {
                title: "Point details",
                content: (
                  <>
                    {((!editingMessageForRecognitionPoints &&
                      usableCategories.length > 0) ||
                      (editingMessageForRecognitionPoints &&
                        editingMessageForRecognitionPoints.categoryId)) && (
                      <Controller
                        control={control}
                        name="categoryId"
                        rules={{
                          required: "Recognition point category is required",
                        }}
                        render={({ field, fieldState }) => (
                          <SelectField
                            {...field}
                            disabled={Boolean(
                              editingMessageForRecognitionPoints
                            )}
                            label="Select category"
                            hideLabel={!!categoryIdValue}
                            error={fieldState.error}
                            width="full"
                            options={categoryFieldValues.sort((a, b) =>
                              a.label.localeCompare(b.label)
                            )}
                          />
                        )}
                      />
                    )}

                    <Controller
                      control={control}
                      name="numberOfPoints"
                      rules={{
                        required: "Number of points is required",
                        validate: (value) => {
                          if (!value.match(INTEGERS_REGEX)) {
                            return "You can only enter numbers";
                          }
                          if (parseInt(value, 10) < MIN_AWARDABLE_POINTS) {
                            return "You must award a positive number of points";
                          }
                          if (parseInt(value, 10) > MAX_AWARDABLE_POINTS) {
                            return `You cannot award more than ${numberWithCommas(
                              MAX_AWARDABLE_POINTS
                            )} points`;
                          }
                          if (
                            query.data?.myOrganization
                              .recognitionBudgetsEnabled &&
                            currentBudgetSummaryQuery.data
                              ?.getMyRecognitionPointsSentSummaryV2
                              .pointsAvailableInBudget &&
                            parseInt(value, 10) >
                              currentBudgetSummaryQuery.data
                                .getMyRecognitionPointsSentSummaryV2
                                .pointsAvailableInBudget &&
                            !canOverrideBudget
                          ) {
                            return "You must stay within your budget.";
                          }
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          hideEndAdornment={false}
                          endAdornment={
                            parseInt(numberOfPoints, 10) && pointsPerDollar ? (
                              <Typography
                                variant="subtitle"
                                css={(theme: AppTheme) =>
                                  css`
                                    font-weight: bolder;
                                    color: ${theme.palette.grey[800]};
                                  `
                                }
                              >
                                {formatDollars(
                                  parseInt(numberOfPoints, 10) / pointsPerDollar
                                )}
                              </Typography>
                            ) : null
                          }
                          disabled={Boolean(editingMessageForRecognitionPoints)}
                          readOnly={cannotManuallySetPointAmount}
                          greyReadOnly
                          notchedLabelBackgroundColor={
                            cannotManuallySetPointAmount ? "#f9f9fb" : undefined
                          }
                          disableAutocomplete
                          label="Number of points"
                          type="text"
                          error={fieldState.error}
                          helperText={
                            <div
                              css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                flex-wrap: wrap;
                              `}
                            >
                              {!editingMessageForRecognitionPoints &&
                                query.data?.myOrganization
                                  .recognitionRubricFileUrl && (
                                  <Button
                                    css={css`
                                      font-size: 0.9em;
                                      height: 100%;

                                      // increase clickable area:
                                      padding: 6px 6px 6px 6px;
                                      margin: -4px -4px -8px 0px;
                                    `}
                                    width="auto"
                                    size="small"
                                    startIcon={
                                      <OpenInNewIcon
                                        css={css`
                                          height: 16px;
                                        `}
                                        color="primary"
                                      />
                                    }
                                    color="primary"
                                    label={
                                      cannotManuallySetPointAmount
                                        ? "See Recognition Criteria"
                                        : "See Suggested Recognition Criteria"
                                    }
                                    variant="text"
                                    linkTo={
                                      query.data.myOrganization
                                        .recognitionRubricFileUrl
                                    }
                                    externalLink
                                    target="_blank"
                                  />
                                )}
                            </div>
                          }
                        />
                      )}
                    />
                    {query.data?.myOrganization.recognitionBudgetsEnabled &&
                      currentBudgetSummaryQuery.data
                        ?.getMyRecognitionPointsSentSummaryV2.pointsBudget && (
                        <Tooltip
                          title="This is how much you have spent out of your budget for the period."
                          css={(theme: AppTheme) => css`
                            margin-left: ${theme.spacing(2.5)};
                            align-items: center;
                            justify-content: center;
                          `}
                        >
                          <div
                            css={css`
                              display: flex;
                              align-items: center;
                            `}
                          >
                            <InfoIcon
                              css={css`
                                opacity: 0.1;
                                display: flex;
                              `}
                            />
                            <Typography
                              css={(theme: AppTheme) =>
                                css`
                                  margin-left: ${theme.spacing(1)};
                                  color: ${theme.palette.grey[800]};
                                `
                              }
                            >
                              <Typography component="span">{`Your budget: `}</Typography>
                              <Typography
                                component="span"
                                color={
                                  currentBudgetSummaryQuery.data
                                    ?.getMyRecognitionPointsSentSummaryV2
                                    .pointsAvailableInBudget === 0
                                    ? "error"
                                    : undefined
                                }
                              >{`${numberWithCommas(
                                currentBudgetSummaryQuery.data
                                  .getMyRecognitionPointsSentSummaryV2
                                  .pointsSentTotal
                              )}`}</Typography>
                              <Typography component="span">
                                {`/${numberWithCommas(
                                  currentBudgetSummaryQuery.data
                                    .getMyRecognitionPointsSentSummaryV2
                                    .pointsBudget
                                )} points`}
                              </Typography>
                            </Typography>
                          </div>
                        </Tooltip>
                      )}
                  </>
                ),
              },
              {
                title: "Personalized message",
                content: (
                  <Controller
                    control={control}
                    name="messageToUser"
                    rules={{
                      required: "The message is required",
                      validate: (message) => {
                        if (message && message.length > MAX_MESSAGE_LENGTH) {
                          return getCharactersRemainingText(
                            message,
                            MAX_MESSAGE_LENGTH
                          );
                        }

                        if (
                          editingMessageForRecognitionPoints?.defaultMessage &&
                          editingMessageForRecognitionPoints.defaultMessage ===
                            message
                        ) {
                          return "The default message has not been personalized";
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onFocus={(e) => {
                          if (
                            editingMessageForRecognitionPoints &&
                            field.value ===
                              editingMessageForRecognitionPoints.defaultMessage
                          ) {
                            // auto-select the text if it's the default message
                            e.target.select();
                          }
                        }}
                        autoFocus={Boolean(editingMessageForRecognitionPoints)}
                        label="Personalized message"
                        type="textarea"
                        error={fieldState.error}
                        helperText={getCharactersRemainingText(
                          messageToUser,
                          MAX_MESSAGE_LENGTH
                        )}
                        minRows={6}
                        disableAutocomplete
                      />
                    )}
                  />
                ),
              },
              {
                title: "Confirm",
                content: (
                  <Typography variant="body" color="textPrimary">
                    {confirmStepDescription}
                  </Typography>
                ),
              },
            ]}
          />
        </ModalContent>
        <ModalActions>
          <Button variant="outlined" onClick={onClose} label="Cancel" />
          <Button {...submitButtonProps} />
        </ModalActions>
      </Form>
    );
  })();

  const title = (() => {
    if (editingMessageForRecognitionPoints) {
      if (editingMessageForRecognitionPoints.message) {
        return "Edit message";
      } else {
        return "Add message";
      }
    } else {
      return "Give recognition points";
    }
  })();

  return (
    <Fragment>
      <Modal width="550px" open={open} onClose={onClose} onExited={onExited}>
        <ModalTitle>{title}</ModalTitle>
        {content}
      </Modal>

      <OverBudgetConfirmationModal
        open={budgetConfirmationModalOpen}
        points={parseInt(numberOfPoints, 10)}
        overBudget={
          pointsAvailableInBudget === 0
            ? parseInt(numberOfPoints, 10) +
              (currentBudgetSummaryQuery.data
                ?.getMyRecognitionPointsSentSummaryV2.pointsSentTotal! -
                currentBudgetSummaryQuery.data
                  ?.getMyRecognitionPointsSentSummaryV2.pointsBudget!)
            : parseInt(numberOfPoints, 10) -
              currentBudgetSummaryQuery.data
                ?.getMyRecognitionPointsSentSummaryV2.pointsAvailableInBudget!
        }
        firstName={
          userSearchQuery.data?.searchForRewardsUsers.find(
            (user) => user.id === form.getValues().userId
          )?.firstName!
        }
        onCancel={handleOverBudgetConfirmationModalCancel}
        onConfirm={handleOverBudgetConfirmationModalConfirm}
      />
    </Fragment>
  );
}
