/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  AdminGoal,
  RewardsAdminPermissionsType,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { usePermissionsQuery } from "../../../shared/modules/permissions/hooks/use-permissions-query";
import { useAdminGoalsPageDataQuery } from "./admin-goals-page-data.generated";
import { NoActiveGoalsCard } from "./goal-cards/no-active-goals-card";
import { getGoalCards } from "./utils";

export function GoalsPageContents(): JSX.Element {
  const adminGoalsQuery = useAdminGoalsPageDataQuery({
    onError: reportError,
  });
  const permissionsQuery = usePermissionsQuery();

  if (adminGoalsQuery.error || permissionsQuery.error) {
    return (
      <Alert
        message="An unexpected error occurred. Please try again later."
        severity="error"
      />
    );
  }

  if (!adminGoalsQuery.data || !permissionsQuery.data) {
    return <PageLoadingState />;
  }

  const { adminGoals, organization } = adminGoalsQuery.data;

  const { availableGoalCards, pastGoalCards } = getGoalCards(
    adminGoals as AdminGoal[],
    organization.pointsPerDollar
  );

  const isFullAccessAdmin =
    permissionsQuery.data.getMyRewardsAdminUser.permissions.type ===
    RewardsAdminPermissionsType.FullAccess;

  return (
    <>
      <div
        css={(theme: AppTheme) =>
          css`
            display: flex;
            justify-content: space-between;
            margin-bottom: ${theme.spacing(3)};
          `
        }
      >
        <div>
          <Typography
            variant="h3"
            fontWeight={700}
            color="textPrimary"
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(1)};
              `
            }
          >
            Office goals
          </Typography>
          <Typography variant="body" color="grey.800">
            Track your monthly office goals, earn rewards, and view your
            progress at a glance.
          </Typography>
        </div>
        {isFullAccessAdmin && (
          <Button
            label="Edit settings"
            startIcon={<FontAwesomeIcon icon={faPen} width={12} />}
            width="auto"
            variant="text"
            color="primary"
            linkTo="/settings/office-goals"
            css={(theme: AppTheme) => css`
              padding: ${theme.spacing(2, 3)};
            `}
          />
        )}
      </div>
      <Typography
        variant="h6"
        fontWeight={700}
        color="textPrimary"
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(3)};
          `
        }
      >
        Active goals
      </Typography>
      {availableGoalCards.length > 0 ? (
        availableGoalCards
      ) : (
        <div
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(3)};
            `
          }
        >
          <NoActiveGoalsCard />
        </div>
      )}
      {pastGoalCards.length > 0 && (
        <div
          css={(theme: AppTheme) =>
            css`
              margin-top: ${theme.spacing(1)};
            `
          }
        >
          <Typography
            variant="h6"
            fontWeight={700}
            color="textPrimary"
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(3)};
              `
            }
          >
            Past goals
          </Typography>
          {pastGoalCards}
        </div>
      )}
    </>
  );
}
