/** @jsxImportSource @emotion/react */
import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

export interface LaunchGoalConfirmationModalProps {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  daysLeftInPeriod: number;
  lateLaunch: boolean;
  loading?: boolean;
}

export function LaunchGoalConfirmationModal({
  open,
  onClose,
  onConfirm,
  daysLeftInPeriod,
  lateLaunch,
  loading,
}: LaunchGoalConfirmationModalProps): JSX.Element {
  const recurringGoalConfigEnabled = useFeatureFlag(
    "admin-app-recurring-goal-config-temp"
  );

  return (
    <Modal width="500px" open={open} onClose={onClose}>
      <ModalTitle>
        <Typography variant="h5" fontWeight={700}>
          {lateLaunch ? "Ready to launch goal?" : "You're almost done!"}
        </Typography>
      </ModalTitle>
      <ModalContent>
        <Typography variant="body">
          {lateLaunch
            ? `Heads up! There ${
                daysLeftInPeriod > 1 ? "are" : "is"
              } ${daysLeftInPeriod} day${
                daysLeftInPeriod > 1 ? "s" : ""
              } left in the goal period. Giving it a bit more time can help boost office staff
              engagement and make it easier to complete. If you prefer, you can always ${
                recurringGoalConfigEnabled ? "start" : "launch"
              }
              this goal at the start of next month.`
            : `Once launched, this goal will go live for your office staff to complete${
                recurringGoalConfigEnabled
                  ? ""
                  : " and cannot be reversed or deleted"
              }. Confirm ${
                recurringGoalConfigEnabled ? "" : "launch"
              } to make it available now.`}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          variant="outlined"
          onClick={onClose}
          label="Cancel"
          size="medium"
          width="auto"
          disabled={loading}
        />
        <Button
          color="primary"
          onClick={onConfirm}
          label="Confirm launch"
          size="medium"
          width="auto"
          loading={loading}
        />
      </ModalActions>
    </Modal>
  );
}
