/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useEffect } from "react";

import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreenV2 } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface InsightMetricGroupProps {
  label: string;
  icon: JSX.Element;
  tooltipText?: string;
  children: ReactNode;
  trackingProperties: {
    startDate?: Date;
    endDate?: Date;
  };
}
export function InsightMetricGroup(props: InsightMetricGroupProps) {
  const theme = useTheme();
  const track = useTrack();

  const [onScreen, ref] = useOnScreenV2<HTMLDivElement>({ threshold: 0.5 });

  useEffect(() => {
    if (onScreen) {
      track("Viewed insight metric group", {
        label: props.label,
        ...props.trackingProperties,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen, props.label, props.trackingProperties]);

  return (
    <div ref={ref}>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-top: ${theme.spacing(1.25)};
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-right: ${theme.spacing(1.25)};
          `}
        >
          {props.icon}
        </div>
        <Typography variant="h5" component="h2" fontWeight={700}>
          {props.label}
        </Typography>
        {props.tooltipText && (
          <div
            css={css`
              margin-left: ${theme.spacing(2)};
              margin-bottom: -2px;
            `}
          >
            <Tooltip
              title={props.tooltipText}
              placement="top"
              onOpen={() =>
                track("Viewed insight metric group tooltip", {
                  insightMetricGroup: props.label,
                  tooltipText: props.tooltipText,
                })
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </div>
        )}
      </div>
      <div
        css={(theme: AppTheme) =>
          css`
            display: flex;
            gap: ${theme.spacing(2)};
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          `
        }
      >
        {props.children}
      </div>
    </div>
  );
}
