import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateBranchMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  tagConfig?: Types.Maybe<Types.RewardsGroupTagsConfigSchemaV1Input>;
  timezone?: Types.Maybe<Types.Scalars['String']>;
}>;


export type CreateBranchMutation = (
  { __typename?: 'Mutation' }
  & { createOrganizationBranch: (
    { __typename?: 'RewardsOrganizationBranch' }
    & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
  ) }
);


export const CreateBranchDocument = gql`
    mutation CreateBranch($name: String!, $tagConfig: RewardsGroupTagsConfigSchemaV1Input, $timezone: String) {
  createOrganizationBranch(
    name: $name
    autoMembershipTagCriteriaV1: $tagConfig
    timezone: $timezone
  ) {
    id
    name
  }
}
    `;
export type CreateBranchMutationFn = Apollo.MutationFunction<CreateBranchMutation, CreateBranchMutationVariables>;

/**
 * __useCreateBranchMutation__
 *
 * To run a mutation, you first call `useCreateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchMutation, { data, loading, error }] = useCreateBranchMutation({
 *   variables: {
 *      name: // value for 'name'
 *      tagConfig: // value for 'tagConfig'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCreateBranchMutation(baseOptions?: Apollo.MutationHookOptions<CreateBranchMutation, CreateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBranchMutation, CreateBranchMutationVariables>(CreateBranchDocument, options);
      }
export type CreateBranchMutationHookResult = ReturnType<typeof useCreateBranchMutation>;
export type CreateBranchMutationResult = Apollo.MutationResult<CreateBranchMutation>;
export type CreateBranchMutationOptions = Apollo.BaseMutationOptions<CreateBranchMutation, CreateBranchMutationVariables>;