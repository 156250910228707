/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHelperText, RadioGroup } from "@material-ui/core";
import { useMemo } from "react";
import { Control, Controller, Path, useWatch } from "react-hook-form";

import { RadioButton } from "@rewards-web/shared/components/radio-buttons";
import { Typography } from "@rewards-web/shared/components/typography";

import { getSurveyQuestionMetadata } from "../../../../../../../../shared/constants/survey-question-metadata";
import { CHECK_IN_SURVEY_QUESTIONS } from "../../../../../constants";

export type CheckInSurveyFormQuestions = {
  [key in typeof CHECK_IN_SURVEY_QUESTIONS[0]]: "required" | "rotating" | "off";
};

const NUM_REQUIRED_QUESTIONS = 1; // enforce 1 required question per survey

type FieldValuesWithQuestions = {
  questions: CheckInSurveyFormQuestions;
};

interface CheckInSurveyQuestionsFieldProps<T extends FieldValuesWithQuestions> {
  control: Control<T, object>;
  numQuestionsPerSurvey: number;
  disabled?: boolean;
  agencyName?: string;
}

const formatList = (list: string[]) => {
  const [last = "", ...rest] = [...list].reverse();
  return rest.length ? [rest.reverse().join(", "), last].join(" or ") : last;
};

export function CheckInSurveyQuestionsField<
  T extends FieldValuesWithQuestions
>({
  control,
  numQuestionsPerSurvey,
  disabled,
  agencyName,
}: CheckInSurveyQuestionsFieldProps<T>) {
  const theme = useTheme();
  const name = "questions" as Path<T>;
  const questionsValue = useWatch({ control, name });

  const numRotatingQuestionsPerSurvey =
    numQuestionsPerSurvey - NUM_REQUIRED_QUESTIONS;

  // Returns the valid numbers of rotating questions that can be selected,
  // which must be evenly divisible and fill at least 2 survey rotations.
  // For example with 13 questions, 1 required and 3 rotating per survey,
  // the valid options are [6,9,12].
  const validNumSelectedRotatingQuestions = useMemo(() => {
    const options: number[] = [];
    for (
      let i = 2 * numRotatingQuestionsPerSurvey; // minimum number of rotating questions
      i <= CHECK_IN_SURVEY_QUESTIONS.length - NUM_REQUIRED_QUESTIONS; // max number of rotating questions
      i += numRotatingQuestionsPerSurvey
    ) {
      options.push(i);
    }
    return options;
  }, [numRotatingQuestionsPerSurvey]);

  const validateNumSelectedQuestions = (value: CheckInSurveyFormQuestions) => {
    const numRequiredQuestions = Object.values(value).filter(
      (question) => question === "required"
    ).length;
    const numRotatingQuestions = Object.values(value).filter(
      (question) => question === "rotating"
    ).length;

    const numRequiredQuestionsIsValid =
      numRequiredQuestions === NUM_REQUIRED_QUESTIONS;

    const numRotatingQuestionsIsValid = validNumSelectedRotatingQuestions.includes(
      numRotatingQuestions
    );

    return {
      numRequiredQuestions,
      numRotatingQuestions,
      numRequiredQuestionsIsValid,
      numRotatingQuestionsIsValid,
      listOfValidNumRotatingQuestions: formatList(
        validNumSelectedRotatingQuestions.map(String)
      ),
    };
  };

  const {
    numRequiredQuestions,
    numRotatingQuestions,
    numRequiredQuestionsIsValid,
    numRotatingQuestionsIsValid,
    listOfValidNumRotatingQuestions,
  } = validateNumSelectedQuestions(questionsValue);

  const getQuestionHelperIcon = (isValid: boolean) => {
    return isValid ? (
      <FontAwesomeIcon
        icon={faCheckCircle}
        color={theme.palette.success.main}
        css={css`
          margin-right: ${theme.spacing(0.5)};
        `}
      />
    ) : (
      <FontAwesomeIcon
        icon={faWarning}
        color={theme.palette.warning.main}
        css={css`
          margin-right: ${theme.spacing(0.5)};
        `}
      />
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (questions) => {
          if (disabled) {
            return;
          }

          const {
            numRequiredQuestionsIsValid,
            numRotatingQuestionsIsValid,
            listOfValidNumRotatingQuestions,
          } = validateNumSelectedQuestions(questions);

          if (!numRequiredQuestionsIsValid) {
            return `You have selected an invalid number of questions. Please select ${NUM_REQUIRED_QUESTIONS} required question.`;
          }

          if (!numRotatingQuestionsIsValid) {
            return `You have selected an invalid number of questions. Please select ${listOfValidNumRotatingQuestions} rotating questions.`;
          }
        },
      }}
      render={({ fieldState: { error } }) => {
        return (
          <div>
            <div
              css={css`
                background-color: #f5f9fa;
                ${error &&
                css`
                  outline: solid 1px ${theme.palette.error.main};
                `}
                padding: ${theme.spacing(2, 3)};
                border-radius: 8px;
                width: 100%;
                margin-top: ${theme.spacing(-4)};
              `}
            >
              <div
                css={css`
                  background-color: ${theme.palette.grey[200]};
                  border-radius: 8px;
                  padding: ${theme.spacing(2, 3)};
                  margin-bottom: ${theme.spacing(3)};
                  width: fit-content;
                `}
              >
                <Typography
                  variant="subtitle"
                  css={css`
                    margin-bottom: ${theme.spacing(1)};
                  `}
                >
                  <FontAwesomeIcon icon={faBell} /> Based on your survey length
                  of: {numQuestionsPerSurvey} questions
                </Typography>
                <Typography variant="body" color={theme.palette.grey[800]}>
                  Select <b>{NUM_REQUIRED_QUESTIONS} required question</b>.
                </Typography>
                <Typography variant="body" color={theme.palette.grey[800]}>
                  Select at least <b>6 other questions</b> that will rotate each
                  time the survey is sent out.
                </Typography>
                <Typography variant="body" color={theme.palette.grey[800]}>
                  Choose from {listOfValidNumRotatingQuestions} total questions
                  for balanced rotation.
                </Typography>
              </div>
              <div
                css={css`
                  width: 100%;
                  display: grid;
                  grid-template-columns: 180px 1.3fr 1fr;
                  > * {
                    padding: ${theme.spacing(2.5, 1)};
                    /* exclude first grid row */
                    &:nth-child(n + 4) {
                      border-top: solid 1px ${theme.palette.divider};
                    }
                    /* last column (radio buttons) should be a grid of 3 */
                    &:nth-child(3n + 3) {
                      display: grid;
                      grid-template-columns: repeat(2, minmax(0, 1fr)) 90px;
                    }
                  }
                `}
              >
                <Typography variant="subtitle">Questions</Typography>
                {/* No header for the second column */}
                <div />
                <div>
                  <div>
                    <Typography variant="subtitle">Required</Typography>
                    <Typography variant="body">
                      {getQuestionHelperIcon(numRequiredQuestionsIsValid)}
                      {numRequiredQuestions} selected
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle">Rotating</Typography>
                    <Typography variant="body">
                      {getQuestionHelperIcon(numRotatingQuestionsIsValid)}
                      {numRotatingQuestions} selected
                    </Typography>
                  </div>
                  <Typography variant="subtitle">Excluded</Typography>
                </div>

                {CHECK_IN_SURVEY_QUESTIONS.map((question) => {
                  const {
                    category,
                    questionText,
                    icon,
                  } = getSurveyQuestionMetadata(question, agencyName);

                  return [
                    <div
                      key={`category:${question}`}
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: ${theme.spacing(1)};
                        width: 180px;
                        color: ${theme.palette.grey[800]};
                      `}
                    >
                      <FontAwesomeIcon icon={icon} />
                      <Typography variant="caption">
                        {category.toUpperCase()}
                      </Typography>
                    </div>,
                    <Typography
                      key={`questionText:${question}`}
                      css={css`
                        display: flex;
                        align-items: center;
                        padding-right: ${theme.spacing(4)};
                      `}
                    >
                      {questionText}
                    </Typography>,
                    <Controller
                      key={`form:${question}`}
                      control={control}
                      name={`questions.${question}` as Path<T>}
                      render={({ field }) => {
                        return (
                          <RadioGroup
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          >
                            <RadioButton
                              value="required"
                              label="Required"
                              hideLabel
                              disabled={disabled}
                            />
                            <RadioButton
                              value="rotating"
                              label="Rotating"
                              hideLabel
                              disabled={disabled}
                            />
                            <RadioButton
                              value="off"
                              label="Off"
                              hideLabel
                              disabled={disabled}
                            />
                          </RadioGroup>
                        );
                      }}
                    />,
                  ];
                })}
              </div>
              <FormHelperText
                error={!!error}
                css={css`
                  font-size: 16px;
                `}
              >
                {error?.message ?? " "}
              </FormHelperText>
            </div>
          </div>
        );
      }}
    />
  );
}
