/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { last, startCase } from "lodash";
import { useState } from "react";

import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { TextButton } from "@rewards-web/shared/components/text-button";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  CandidateRecruitmentStepName,
  ListCandidatesV2OrderField,
  Sort,
} from "@rewards-web/shared/graphql-types";
import { useDrawerControl } from "@rewards-web/shared/hooks/use-drawer-control";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { DataTable } from "../../../../../../shared/components/data-table";
import { SortableTableHeader } from "../../../../../../shared/components/sortable-table-header";
import {
  SortableTableHeadersProvider,
  TableSortState,
} from "../../../../../../shared/components/sortable-table-header/context";
import { CandidateDetailsDrawer } from "../../../../../../shared/modules/candidates/candidate-details-drawer";
import { useCandidateDrawerAccordionState } from "../../../../../../shared/modules/candidates/candidate-details-drawer/use-accordion-state";
import { EmployeeDetailReferralsTableDataQuery } from "../employee-details-referral-table-data.generated";

const ITEMS_PER_PAGE = 6;

type TableSortValue = TableSortState<
  | ListCandidatesV2OrderField.FirstNameLastName
  | ListCandidatesV2OrderField.LastStepOrArchivedDate
>;

interface EmployeeDetailReferralsTableProps {
  userId: string;
  items:
    | EmployeeDetailReferralsTableDataQuery["listCandidatesV2"]["items"]
    | undefined;
  sortValue: TableSortValue;
  onSortValueChange: (sortValue: TableSortValue) => void;
  total: number | undefined;
  loading: boolean;
  rewardsProgramShortName: string | undefined;
  referralLinkDisabled: boolean | undefined;
  resumesEnabled: boolean | undefined;
  onRefreshList: () => void;
  maxPointsEarnedPerCandidate: number | undefined;
  pointsPerDollar: number | undefined;
  launched: boolean | undefined;
}

export function EmployeeDetailReferralsTable({
  userId,
  items,
  sortValue,
  onSortValueChange,
  total,
  loading,
  rewardsProgramShortName,
  referralLinkDisabled,
  resumesEnabled,
  onRefreshList,
  maxPointsEarnedPerCandidate,
  pointsPerDollar,
  launched,
}: EmployeeDetailReferralsTableProps) {
  const track = useTrack();
  const [viewingCandidateId, setViewingCandidateId] = useState<string | null>(
    null
  );
  const [
    candidateDetailsDrawer,
    candidateDetailsDrawerActions,
  ] = useDrawerControl(viewingCandidateId);

  const [
    candidateDrawerAccordionState,
    setCandidateDrawerAccordionState,
  ] = useCandidateDrawerAccordionState();

  return (
    <>
      <DataTable
        paddingDisabled
        items={items}
        total={total}
        itemsPerPage={ITEMS_PER_PAGE}
        emptyStateHeight="150px"
        emptyText={
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: ${theme.spacing(2)};
              height: 100%;
              max-width: 700px;
              margin: 0 auto;
              text-align: center;
            `}
          >
            <Typography variant="subtitle" color="textPrimary">
              {referralLinkDisabled
                ? "No referrals"
                : "No referrals shared yet"}
            </Typography>
            <Typography variant="body" color="grey.800">
              {(() => {
                if (referralLinkDisabled) {
                  return "The referral link is disabled for this employee.";
                }

                if (!launched) {
                  return "This employee can start earning for referrals after the rewards program is launched.";
                }

                if (
                  typeof maxPointsEarnedPerCandidate !== "number" ||
                  typeof pointsPerDollar !== "number"
                ) {
                  return "";
                }

                return `Remind this employee to use ${
                  rewardsProgramShortName ?? "Caribou Rewards"
                } and earn up to ${formatDollars(
                  maxPointsEarnedPerCandidate / pointsPerDollar
                )} per referral by sharing job opportunities with friends and family.`;
              })()}
            </Typography>
          </div>
        }
        loading={loading}
        tableHeaders={
          <SortableTableHeadersProvider
            value={sortValue}
            onChange={onSortValueChange}
          >
            <TableRow>
              <TableHeader
                divider
                css={css`
                  width: 20%;
                  min-width: 120px;
                `}
              >
                <SortableTableHeader
                  text="Candidate"
                  sortField={ListCandidatesV2OrderField.FirstNameLastName}
                  getActiveTooltipText={(direction) =>
                    direction === Sort.Asc ? "Sorted A to Z" : "Sorted Z to A"
                  }
                  smallerFont
                />
              </TableHeader>
              <TableHeader
                divider
                css={css`
                  width: 30%;
                  min-width: 84px;
                `}
              >
                <Typography
                  variant="subtitle"
                  color="textPrimary"
                  fontSize="16px"
                >
                  Job type
                </Typography>
              </TableHeader>
              <TableHeader
                divider
                css={css`
                  width: 20%;
                  min-width: 168px;
                `}
              >
                <SortableTableHeader
                  text="Date of activity"
                  sortField={ListCandidatesV2OrderField.LastStepOrArchivedDate}
                  getActiveTooltipText={(direction) =>
                    direction === Sort.Asc
                      ? "Sorted oldest to newest"
                      : "Sorted newest to oldest"
                  }
                  smallerFont
                />
              </TableHeader>
              <TableHeader
                divider
                css={css`
                  width: 30%;
                  min-width: 114px;
                `}
              >
                <Typography
                  variant="subtitle"
                  color="textPrimary"
                  fontSize="16px"
                >
                  Last activity
                </Typography>
              </TableHeader>
            </TableRow>
          </SortableTableHeadersProvider>
        }
        tableBody={items?.map((candidate, index) => (
          <TableRow>
            <TableCell divider={index < items.length - 1}>
              <Typography variant="body" color="grey.800">
                <TextButton
                  aria-label={`View ${candidate.firstName} ${candidate.lastName} details`}
                  onClick={() => {
                    setViewingCandidateId(candidate.id);
                    track("Clicked view employee detail candidate row", {
                      candidateId: candidate.id,
                      employeeId: userId,
                    });
                  }}
                >
                  {candidate.firstName}{" "}
                  {candidate.preferredName
                    ? ` (${candidate.preferredName})`
                    : ""}{" "}
                  {candidate.lastName}
                </TextButton>
              </Typography>
            </TableCell>
            <TableCell divider={index < items.length - 1}>
              <Typography variant="body" color="grey.800">
                {candidate.assignedToJobPosting?.title ?? "-"}
              </Typography>
            </TableCell>
            <TableCell divider={index < items.length - 1}>
              <Typography variant="body" color="grey.800">
                {candidate.lastStepOrArchivedDate
                  ? new Date(
                      candidate.lastStepOrArchivedDate
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : "-"}
              </Typography>
            </TableCell>
            <TableCell divider={index < items.length - 1}>
              <Typography variant="body" color="grey.800">
                {getCandidateLastActivity(candidate)}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      />

      <CandidateDetailsDrawer
        open={candidateDetailsDrawer.open}
        candidateId={candidateDetailsDrawer.state ?? null}
        onClose={() => setViewingCandidateId(null)}
        onExited={candidateDetailsDrawerActions.onExited}
        onCandidateRemoved={onRefreshList}
        accordionState={candidateDrawerAccordionState}
        onChangeAccordionState={setCandidateDrawerAccordionState}
        resumesEnabled={resumesEnabled ?? true}
      />
    </>
  );
}

function getCandidateLastActivity(
  candidate: EmployeeDetailReferralsTableDataQuery["listCandidatesV2"]["items"][number]
) {
  if (candidate.archived && !candidate.completed) {
    return "Archived";
  }

  const lastStep = last(candidate.completedStepsV2);

  if (!lastStep) {
    return "-"; // shouldn't happen
  }

  switch (lastStep.__typename) {
    case "RecordedCandidateRecruitmentStep": {
      switch (lastStep.stepName) {
        case CandidateRecruitmentStepName.ApplicationSubmitted:
          return "Application submitted";
        case CandidateRecruitmentStepName.Contacted:
          return "Contacted";
        case CandidateRecruitmentStepName.InterviewScheduled:
          return "Interview scheduled";
        case CandidateRecruitmentStepName.InterviewSuccessful:
          return "Interview successful";
        case CandidateRecruitmentStepName.Hired:
          return "Hired";
        case CandidateRecruitmentStepName.StartedWork:
          return "Started work";
        case CandidateRecruitmentStepName.StartedOrientation:
          return "Started orientation";
        case CandidateRecruitmentStepName.CompletedOrientation:
          return "Completed orientation";
        case CandidateRecruitmentStepName.CompletedFirstShift:
          return "Completed first shift";
        default:
          return startCase(lastStep.stepName);
      }
    }
    case "RecordedCandidateMonthlyRetentionStep":
      return `Worked ${lastStep.durationMonths} month${
        lastStep.durationMonths === 1 ? "" : "s"
      }`;
    case "RecordedCandidateHourlyRetentionStep":
      return `Worked ${lastStep.durationHours} hour${
        lastStep.durationHours === 1 ? "" : "s"
      }`;
    default:
      return "-"; // shouldn't happen
  }
}
