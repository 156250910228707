import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type TransactionMetadataFragment_TransactionMetadataUnknown_Fragment = (
  { __typename: 'TransactionMetadataUnknown' }
  & Pick<Types.TransactionMetadataUnknown, 'category'>
);

export type TransactionMetadataFragment_TransactionMetadataReferralJobShare_Fragment = (
  { __typename: 'TransactionMetadataReferralJobShare' }
  & Pick<Types.TransactionMetadataReferralJobShare, 'category'>
  & { jobPosting: (
    { __typename: 'JobPosting' }
    & Pick<Types.JobPosting, 'title'>
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataReferralRecruitmentStep_Fragment = (
  { __typename: 'TransactionMetadataReferralRecruitmentStep' }
  & Pick<Types.TransactionMetadataReferralRecruitmentStep, 'category' | 'stepName'>
  & { candidate: (
    { __typename: 'Candidate' }
    & Pick<Types.Candidate, 'id' | 'firstName' | 'lastName'>
    & { assignedToJobPosting?: Types.Maybe<(
      { __typename: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title'>
    )> }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataReferralRetentionMilestone_Fragment = (
  { __typename: 'TransactionMetadataReferralRetentionMilestone' }
  & Pick<Types.TransactionMetadataReferralRetentionMilestone, 'category' | 'durationMonths' | 'durationHours'>
  & { candidate: (
    { __typename: 'Candidate' }
    & Pick<Types.Candidate, 'id' | 'firstName' | 'lastName'>
    & { assignedToJobPosting?: Types.Maybe<(
      { __typename: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title'>
    )> }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataReferralAssignedAsReferringEmployee_Fragment = (
  { __typename: 'TransactionMetadataReferralAssignedAsReferringEmployee' }
  & Pick<Types.TransactionMetadataReferralAssignedAsReferringEmployee, 'category'>
  & { candidate: (
    { __typename: 'Candidate' }
    & Pick<Types.Candidate, 'id' | 'firstName' | 'lastName'>
    & { assignedToJobPosting?: Types.Maybe<(
      { __typename: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title'>
    )> }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataReferralCorrection_Fragment = (
  { __typename: 'TransactionMetadataReferralCorrection' }
  & Pick<Types.TransactionMetadataReferralCorrection, 'category'>
  & { candidate: (
    { __typename: 'Candidate' }
    & Pick<Types.Candidate, 'id' | 'firstName' | 'lastName'>
    & { assignedToJobPosting?: Types.Maybe<(
      { __typename: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title'>
    )> }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataRecognition_Fragment = (
  { __typename: 'TransactionMetadataRecognition' }
  & Pick<Types.TransactionMetadataRecognition, 'category'>
  & { recognitionPoints: (
    { __typename: 'RecognitionPoints' }
    & Pick<Types.RecognitionPoints, 'id' | 'from' | 'message' | 'sentAt'>
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataEvvStreakGoal_Fragment = (
  { __typename: 'TransactionMetadataEVVStreakGoal' }
  & Pick<Types.TransactionMetadataEvvStreakGoal, 'category'>
  & { goal: (
    { __typename: 'Goal' }
    & Pick<Types.Goal, 'id'>
    & { goalTracker: { __typename: 'JobShareGoalTracker' } | { __typename: 'SurveyGoalTracker' } | (
      { __typename: 'EVVStreakGoalTracker' }
      & Pick<Types.EvvStreakGoalTracker, 'targetStreak' | 'currentStreak'>
      & { trackedVisits: Array<(
        { __typename: 'EVVStreakGoalTrackerVisitDetail' }
        & Pick<Types.EvvStreakGoalTrackerVisitDetail, 'id' | 'rangeStart'>
      )> }
    ) | { __typename: 'WatchVideoGoalTracker' } | { __typename: 'HHAXLoginGoalTracker' } | { __typename: 'LastMinuteShiftGoalTracker' } | { __typename: 'CompleteTrainingGoalTracker' } | { __typename: 'WeekendShiftGoalTracker' } | { __typename: 'BillableHoursGoalTracker' } }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataEvvPunchCard_Fragment = (
  { __typename: 'TransactionMetadataEVVPunchCard' }
  & Pick<Types.TransactionMetadataEvvPunchCard, 'category'>
  & { punchCardOffer: (
    { __typename: 'RewardOffer' }
    & Pick<Types.RewardOffer, 'id'>
    & { structure: { __typename: 'RewardOfferStructureUnlimited' } | { __typename: 'RewardOfferStructureOneTime' } | { __typename: 'RewardOfferStructurePeriodic' } | { __typename: 'RewardOfferStructureDiscreteProgress' } | (
      { __typename: 'RewardOfferStructurePunchCard' }
      & Pick<Types.RewardOfferStructurePunchCard, 'id' | 'numPunchesPerCard'>
    ) }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataMobileAppAdoption_Fragment = (
  { __typename: 'TransactionMetadataMobileAppAdoption' }
  & Pick<Types.TransactionMetadataMobileAppAdoption, 'category'>
);

export type TransactionMetadataFragment_TransactionMetadataMilestoneRewards_Fragment = (
  { __typename: 'TransactionMetadataMilestoneRewards' }
  & Pick<Types.TransactionMetadataMilestoneRewards, 'category'>
  & { milestoneRewardStep: (
    { __typename: 'UserMilestoneRewardStep' }
    & Pick<Types.UserMilestoneRewardStep, 'id'>
    & { goal: (
      { __typename: 'VisitMilestoneGoal' }
      & Pick<Types.VisitMilestoneGoal, 'type' | 'visitNumber'>
    ) | (
      { __typename: 'HoursMilestoneGoal' }
      & Pick<Types.HoursMilestoneGoal, 'type' | 'numHours'>
    ) }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataSurvey_Fragment = (
  { __typename: 'TransactionMetadataSurvey' }
  & Pick<Types.TransactionMetadataSurvey, 'category' | 'surveyType'>
);

export type TransactionMetadataFragment_TransactionMetadataWeekendShift_Fragment = (
  { __typename: 'TransactionMetadataWeekendShift' }
  & Pick<Types.TransactionMetadataWeekendShift, 'category'>
  & { goal: (
    { __typename: 'Goal' }
    & Pick<Types.Goal, 'id'>
    & { goalTracker: { __typename: 'JobShareGoalTracker' } | { __typename: 'SurveyGoalTracker' } | { __typename: 'EVVStreakGoalTracker' } | { __typename: 'WatchVideoGoalTracker' } | { __typename: 'HHAXLoginGoalTracker' } | { __typename: 'LastMinuteShiftGoalTracker' } | { __typename: 'CompleteTrainingGoalTracker' } | (
      { __typename: 'WeekendShiftGoalTracker' }
      & { trackedVisit?: Types.Maybe<(
        { __typename: 'WeekendShiftGoalTrackerVisitDetail' }
        & Pick<Types.WeekendShiftGoalTrackerVisitDetail, 'id' | 'rangeStart'>
      )> }
    ) | { __typename: 'BillableHoursGoalTracker' } }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataLastMinuteShift_Fragment = (
  { __typename: 'TransactionMetadataLastMinuteShift' }
  & Pick<Types.TransactionMetadataLastMinuteShift, 'category'>
  & { goal: (
    { __typename: 'Goal' }
    & Pick<Types.Goal, 'id'>
    & { goalTracker: { __typename: 'JobShareGoalTracker' } | { __typename: 'SurveyGoalTracker' } | { __typename: 'EVVStreakGoalTracker' } | { __typename: 'WatchVideoGoalTracker' } | { __typename: 'HHAXLoginGoalTracker' } | (
      { __typename: 'LastMinuteShiftGoalTracker' }
      & { trackedVisit?: Types.Maybe<(
        { __typename: 'LastMinuteShiftGoalTrackerVisitDetail' }
        & Pick<Types.LastMinuteShiftGoalTrackerVisitDetail, 'id' | 'rangeStart'>
      )> }
    ) | { __typename: 'CompleteTrainingGoalTracker' } | { __typename: 'WeekendShiftGoalTracker' } | { __typename: 'BillableHoursGoalTracker' } }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataWatchedVideo_Fragment = (
  { __typename: 'TransactionMetadataWatchedVideo' }
  & Pick<Types.TransactionMetadataWatchedVideo, 'category'>
  & { video: (
    { __typename: 'RewardVideo' }
    & Pick<Types.RewardVideo, 'id' | 'title'>
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataTrainingProgram_Fragment = (
  { __typename: 'TransactionMetadataTrainingProgram' }
  & Pick<Types.TransactionMetadataTrainingProgram, 'category'>
  & { goal: (
    { __typename: 'Goal' }
    & Pick<Types.Goal, 'id'>
    & { goalDetails?: Types.Maybe<{ __typename: 'SurveyGoalDetails' } | { __typename: 'WatchVideoGoalDetails' } | (
      { __typename: 'CompleteTrainingGoalDetails' }
      & Pick<Types.CompleteTrainingGoalDetails, 'trainingProgramId' | 'trainingProgramName' | 'trainingProgramDurationSeconds'>
    )> }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataBillableHoursIncrease_Fragment = (
  { __typename: 'TransactionMetadataBillableHoursIncrease' }
  & Pick<Types.TransactionMetadataBillableHoursIncrease, 'category'>
  & { goal: (
    { __typename: 'Goal' }
    & Pick<Types.Goal, 'id'>
    & { goalTracker: { __typename: 'JobShareGoalTracker' } | { __typename: 'SurveyGoalTracker' } | { __typename: 'EVVStreakGoalTracker' } | { __typename: 'WatchVideoGoalTracker' } | { __typename: 'HHAXLoginGoalTracker' } | { __typename: 'LastMinuteShiftGoalTracker' } | { __typename: 'CompleteTrainingGoalTracker' } | { __typename: 'WeekendShiftGoalTracker' } | (
      { __typename: 'BillableHoursGoalTracker' }
      & Pick<Types.BillableHoursGoalTracker, 'targetBillableHours' | 'trackedBillableHours'>
      & { trackedVisits: Array<(
        { __typename: 'BillableHoursGoalTrackerVisitDetail' }
        & Pick<Types.BillableHoursGoalTrackerVisitDetail, 'rangeStart'>
      )> }
    ) }
  ) }
);

export type TransactionMetadataFragment_TransactionMetadataRedemption_Fragment = (
  { __typename: 'TransactionMetadataRedemption' }
  & Pick<Types.TransactionMetadataRedemption, 'category'>
  & { redemption?: Types.Maybe<(
    { __typename: 'Redemption' }
    & Pick<Types.Redemption, 'id' | 'method' | 'dollars'>
    & { redeemedItems?: Types.Maybe<Array<(
      { __typename: 'RedemptionItem' }
      & Pick<Types.RedemptionItem, 'id'>
      & { catalogItem: (
        { __typename: 'RedemptionCatalogItem' }
        & Pick<Types.RedemptionCatalogItem, 'id' | 'displayName'>
      ) }
    )>> }
  )> }
);

export type TransactionMetadataFragment_TransactionMetadataRedemptionRejected_Fragment = (
  { __typename: 'TransactionMetadataRedemptionRejected' }
  & Pick<Types.TransactionMetadataRedemptionRejected, 'category'>
);

export type TransactionMetadataFragment_TransactionMetadataDrawWinner_Fragment = (
  { __typename: 'TransactionMetadataDrawWinner' }
  & Pick<Types.TransactionMetadataDrawWinner, 'category'>
  & { drawWinner: (
    { __typename: 'DrawWinner' }
    & Pick<Types.DrawWinner, 'id' | 'prizeTierIndex' | 'wonAt'>
  ) }
);

export type TransactionMetadataFragmentFragment = TransactionMetadataFragment_TransactionMetadataUnknown_Fragment | TransactionMetadataFragment_TransactionMetadataReferralJobShare_Fragment | TransactionMetadataFragment_TransactionMetadataReferralRecruitmentStep_Fragment | TransactionMetadataFragment_TransactionMetadataReferralRetentionMilestone_Fragment | TransactionMetadataFragment_TransactionMetadataReferralAssignedAsReferringEmployee_Fragment | TransactionMetadataFragment_TransactionMetadataReferralCorrection_Fragment | TransactionMetadataFragment_TransactionMetadataRecognition_Fragment | TransactionMetadataFragment_TransactionMetadataEvvStreakGoal_Fragment | TransactionMetadataFragment_TransactionMetadataEvvPunchCard_Fragment | TransactionMetadataFragment_TransactionMetadataMobileAppAdoption_Fragment | TransactionMetadataFragment_TransactionMetadataMilestoneRewards_Fragment | TransactionMetadataFragment_TransactionMetadataSurvey_Fragment | TransactionMetadataFragment_TransactionMetadataWeekendShift_Fragment | TransactionMetadataFragment_TransactionMetadataLastMinuteShift_Fragment | TransactionMetadataFragment_TransactionMetadataWatchedVideo_Fragment | TransactionMetadataFragment_TransactionMetadataTrainingProgram_Fragment | TransactionMetadataFragment_TransactionMetadataBillableHoursIncrease_Fragment | TransactionMetadataFragment_TransactionMetadataRedemption_Fragment | TransactionMetadataFragment_TransactionMetadataRedemptionRejected_Fragment | TransactionMetadataFragment_TransactionMetadataDrawWinner_Fragment;

export const TransactionMetadataFragmentFragmentDoc = gql`
    fragment TransactionMetadataFragment on TransactionMetadata {
  __typename
  ... on CategorizedTransactionMetadata {
    category
  }
  ... on TransactionMetadataReferralJobShare {
    __typename
    jobPosting {
      __typename
      title
    }
  }
  ... on TransactionMetadataReferralRecruitmentStep {
    __typename
    candidate {
      __typename
      id
      firstName
      lastName
      assignedToJobPosting {
        __typename
        id
        title
      }
    }
    stepName
  }
  ... on TransactionMetadataReferralRetentionMilestone {
    __typename
    candidate {
      __typename
      id
      firstName
      lastName
      assignedToJobPosting {
        __typename
        id
        title
      }
    }
    durationMonths
    durationHours
  }
  ... on TransactionMetadataReferralAssignedAsReferringEmployee {
    __typename
    candidate {
      __typename
      id
      firstName
      lastName
      assignedToJobPosting {
        __typename
        id
        title
      }
    }
  }
  ... on TransactionMetadataReferralCorrection {
    __typename
    candidate {
      __typename
      id
      firstName
      lastName
      assignedToJobPosting {
        __typename
        id
        title
      }
    }
  }
  ... on TransactionMetadataRecognition {
    __typename
    recognitionPoints {
      __typename
      id
      from
      message
      sentAt
    }
  }
  ... on TransactionMetadataEVVStreakGoal {
    __typename
    goal {
      __typename
      id
      goalTracker {
        __typename
        ... on EVVStreakGoalTracker {
          __typename
          targetStreak
          currentStreak
          trackedVisits {
            __typename
            id
            rangeStart
          }
        }
      }
    }
  }
  ... on TransactionMetadataEVVPunchCard {
    __typename
    punchCardOffer {
      __typename
      id
      structure {
        __typename
        ... on RewardOfferStructurePunchCard {
          __typename
          id
          numPunchesPerCard
        }
      }
    }
  }
  ... on TransactionMetadataMilestoneRewards {
    __typename
    milestoneRewardStep {
      __typename
      id
      goal {
        __typename
        ... on VisitMilestoneGoal {
          __typename
          type
          visitNumber
        }
        ... on HoursMilestoneGoal {
          __typename
          type
          numHours
        }
      }
    }
  }
  ... on TransactionMetadataSurvey {
    __typename
    surveyType
  }
  ... on TransactionMetadataWeekendShift {
    __typename
    goal {
      __typename
      id
      goalTracker {
        __typename
        ... on WeekendShiftGoalTracker {
          __typename
          trackedVisit {
            __typename
            id
            rangeStart
          }
        }
      }
    }
  }
  ... on TransactionMetadataLastMinuteShift {
    __typename
    goal {
      __typename
      id
      goalTracker {
        __typename
        ... on LastMinuteShiftGoalTracker {
          __typename
          trackedVisit {
            __typename
            id
            rangeStart
          }
        }
      }
    }
  }
  ... on TransactionMetadataBillableHoursIncrease {
    __typename
    goal {
      __typename
      id
      goalTracker {
        __typename
        ... on BillableHoursGoalTracker {
          __typename
          targetBillableHours
          trackedBillableHours
          trackedVisits {
            __typename
            rangeStart
          }
        }
      }
    }
  }
  ... on TransactionMetadataWatchedVideo {
    __typename
    video {
      __typename
      id
      title
    }
  }
  ... on TransactionMetadataTrainingProgram {
    __typename
    goal {
      __typename
      id
      goalDetails {
        __typename
        ... on CompleteTrainingGoalDetails {
          __typename
          trainingProgramId
          trainingProgramName
          trainingProgramDurationSeconds
        }
      }
    }
  }
  ... on TransactionMetadataRedemption {
    __typename
    redemption {
      __typename
      id
      method
      dollars
      redeemedItems {
        __typename
        id
        catalogItem {
          __typename
          id
          displayName
        }
      }
    }
  }
  ... on TransactionMetadataDrawWinner {
    __typename
    drawWinner {
      __typename
      id
      prizeTierIndex
      wonAt
    }
  }
}
    `;