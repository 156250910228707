/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface ExportRedemptionModalProps {
  open: boolean;
  redemptionExportId: string | null;
  downloadLink: string | null;
  onClose(): void;
}

export function ExportRedemptionModal({
  open,
  redemptionExportId,
  downloadLink,
  onClose,
}: ExportRedemptionModalProps): JSX.Element {
  const track = useTrack();

  return (
    <Modal width="500px" open={open} onClose={onClose}>
      <ModalTitle>Export to CSV</ModalTitle>
      <ModalContent>
        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {downloadLink ? "Your redemption export is ready!" : "Exporting..."}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          size="large"
          whiteBackground
          variant="outlined"
          onClick={onClose}
          label="Cancel"
        />
        <Button
          size="large"
          type="submit"
          color="primary"
          label="Download .CSV"
          loading={!downloadLink}
          download
          linkTo={downloadLink ?? undefined}
          onClick={() => {
            onClose();
            track("Downloaded redemption export", { redemptionExportId });
          }}
        />
      </ModalActions>
    </Modal>
  );
}
