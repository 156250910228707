import { Auth } from "aws-amplify";
import { useContext, useState } from "react";

import { stripNonNumericCharacters } from "@rewards-web/shared/lib/strip-non-numeric-characters";

import { CognitoErrorCode } from "./constants";
import { CognitoAuthContext } from "./context";
import {
  AuthError,
  NewPasswordRequiredError,
  SmsMfaRequiredError,
} from "./errors";
import { isCognitoError } from "./lib";
import { useSmsMfaStatus } from "./sms-mfa/hooks";

let broadcastChannel: BroadcastChannel | undefined = undefined;

if ("BroadcastChannel" in window) {
  // only create the broadcast channel if it's supported by the browser
  broadcastChannel = new BroadcastChannel("cognito_auth_channel");
}

if (broadcastChannel) {
  // listen for to other browser tabs that have signed out
  broadcastChannel.addEventListener("message", (event) => {
    if (event.data.type === "SIGNED_OUT") {
      // redirect to home page because the current page may be able to load queries still
      // (and may trigger an ACL check error).
      // this avoids cases where the user signs out in one tab,
      window.location.href = "/";
    }
  });
}

/**
 * Hook to abstract various auth needs
 */
export function useCognitoAuth() {
  const { userId } = useContext(CognitoAuthContext);
  const signedIn = !!userId;
  const [newPasswordRequiredUser, setNewPasswordRequiredUser] = useState<any>(
    null
  );
  const [smsMfaUser, setSmsMfaUser] = useState<any>(null);
  const {
    mustEnableSmsMfa,
    setMfaStatusPostLogin,
    setMfaPhoneNumber,
    verifyNewMfaPhoneNumber,
    resendNewMfaVerificationCode,
  } = useSmsMfaStatus();

  return {
    userId,
    signedIn,
    mustEnableSmsMfa,

    /**
     * Attempts to sign in with email/password.
     */
    async signInWithPassword(email: string, password: string) {
      try {
        const result = await Auth.signIn(email, password);

        if (result.challengeName) {
          switch (result.challengeName) {
            case "NEW_PASSWORD_REQUIRED": {
              setNewPasswordRequiredUser(result);
              throw new NewPasswordRequiredError();
            }

            case "SMS_MFA":
              setSmsMfaUser(result);
              throw new SmsMfaRequiredError(
                result.challengeParam.CODE_DELIVERY_DESTINATION
              );

            default:
              throw new Error(
                `Unrecognized challenge name ${result.challengeName}`
              );
          }
        }
      } catch (error) {
        if (
          isCognitoError(error) &&
          Object.values(CognitoErrorCode).includes(error.code)
        ) {
          throw AuthError.fromCognitoError(
            "An error occurred signing in",
            error
          );
        }

        throw error;
      }
    },

    /**
     * If the user logs in, but must set a new password,
     * this allows the new password to be submitted.
     */
    async completeNewPassword(password: string) {
      if (!newPasswordRequiredUser) {
        throw new Error("The user is not in a state to set a new password");
      }

      try {
        await Auth.completeNewPassword(newPasswordRequiredUser, password);
      } catch (error) {
        if (
          isCognitoError(error) &&
          Object.values(CognitoErrorCode).includes(error.code)
        ) {
          throw AuthError.fromCognitoError(
            "An error occurred setting new password",
            error
          );
        }

        throw error;
      }
    },

    /**
     * After logging in with email/password, a user who is prompted
     * to submit an MFA verification code can invoke this to
     * submit their MFA code.
     */
    async submitMfaCode(code: string) {
      if (!smsMfaUser) {
        throw new Error("The user is not in a state to send an MFA code");
      }

      try {
        await Auth.confirmSignIn(
          smsMfaUser,
          stripNonNumericCharacters(code),
          "SMS_MFA"
        );
      } catch (error) {
        if (isCognitoError(error)) {
          throw AuthError.fromCognitoError(
            "An error occurred submitting the MFA code",
            error
          );
        }

        throw error;
      }
    },

    async resendMfaCode(email: string, password: string) {
      if (!smsMfaUser) {
        throw new Error("The user is not in a state to send an MFA code");
      }

      try {
        // in order to resend the MFA code, we need to re-initiate
        // the sign-up flow, since there is no other way to do this
        // with amplify.
        // see https://github.com/aws-amplify/amplify-js/issues/6676
        const result = await Auth.signIn(email, password);
        if (result.challengeName !== "SMS_MFA") {
          throw new Error(
            "Sign in for resending MFA code did not produce SMS_MFA challenge again"
          );
        }
        setSmsMfaUser(result);
      } catch (error) {
        if (isCognitoError(error)) {
          throw AuthError.fromCognitoError(
            "An error occurred submitting the MFA code",
            error
          );
        }

        throw error;
      }
    },

    /**
     * Signs out of Cognito and clears tokens.
     */
    async signOut() {
      await Auth.signOut();

      if (broadcastChannel) {
        // broadcast to other browser tabs that the user signed out,
        // so the they can be redirected to the login page on those tabs too
        broadcastChannel.postMessage({ type: "SIGNED_OUT" });
      }
    },

    /**
     * Initiates the "forgot password" flow
     */
    async forgotPassword(email: string) {
      try {
        await Auth.forgotPassword(email);
      } catch (error) {
        if (isCognitoError(error)) {
          throw AuthError.fromCognitoError(
            "An error occurred sending the password reset code",
            error
          );
        }

        throw error;
      }
    },

    /**
     * Submits the forgot password verification code & password
     */
    async forgotPasswordSubmit(email: string, code: string, password: string) {
      try {
        await Auth.forgotPasswordSubmit(
          email,
          stripNonNumericCharacters(code),
          password
        );
      } catch (error) {
        if (isCognitoError(error)) {
          throw AuthError.fromCognitoError(
            "An error occurred submitting the new password",
            error
          );
        }

        throw error;
      }
    },

    setMfaStatusPostLogin,
    setMfaPhoneNumber,
    verifyNewMfaPhoneNumber,
    resendNewMfaVerificationCode,
  };
}
