import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type JobPostingListQueryVariables = Types.Exact<{
  branchIds?: Types.Maybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type JobPostingListQuery = (
  { __typename?: 'Query' }
  & { getCandidateNextStepFilterOptions: Array<(
    { __typename?: 'CandidateRecruitmentStepFilterOption' }
    & Pick<Types.CandidateRecruitmentStepFilterOption, 'stepName'>
  ) | (
    { __typename?: 'CandidateMonthlyRetentionStepFilterOption' }
    & Pick<Types.CandidateMonthlyRetentionStepFilterOption, 'durationMonths'>
  ) | (
    { __typename?: 'CandidateHourlyRetentionStepFilterOption' }
    & Pick<Types.CandidateHourlyRetentionStepFilterOption, 'durationHours'>
  )>, getNumActiveCandidatesByJobPostingAndNextStep: (
    { __typename?: 'NumActiveCandidatesByJobPostingAndNextStep' }
    & { items: Array<(
      { __typename?: 'NumActiveCandidatesByJobPostingAndNextStepItem' }
      & Pick<Types.NumActiveCandidatesByJobPostingAndNextStepItem, 'total'>
      & { jobPosting: (
        { __typename?: 'JobPosting' }
        & Pick<Types.JobPosting, 'id' | 'title' | 'geography' | 'closedForSubmission'>
      ), totalsByNextStep: Array<(
        { __typename: 'NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment' }
        & Pick<Types.NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment, 'numActiveCandidates' | 'recruitmentStepName'>
      ) | (
        { __typename: 'NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention' }
        & Pick<Types.NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention, 'numActiveCandidates' | 'retentionDurationMonths' | 'retentionDurationHours'>
      )> }
    )> }
  ) }
);


export const JobPostingListDocument = gql`
    query JobPostingList($branchIds: [ID!]) {
  getCandidateNextStepFilterOptions {
    ... on CandidateRecruitmentStepFilterOption {
      stepName
    }
    ... on CandidateMonthlyRetentionStepFilterOption {
      durationMonths
    }
    ... on CandidateHourlyRetentionStepFilterOption {
      durationHours
    }
  }
  getNumActiveCandidatesByJobPostingAndNextStep(branchIds: $branchIds) {
    items {
      jobPosting {
        id
        title
        geography
        closedForSubmission
      }
      total
      totalsByNextStep {
        ... on NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment {
          __typename
          numActiveCandidates
          recruitmentStepName
        }
        ... on NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention {
          __typename
          numActiveCandidates
          retentionDurationMonths
          retentionDurationHours
        }
      }
    }
  }
}
    `;

/**
 * __useJobPostingListQuery__
 *
 * To run a query within a React component, call `useJobPostingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingListQuery({
 *   variables: {
 *      branchIds: // value for 'branchIds'
 *   },
 * });
 */
export function useJobPostingListQuery(baseOptions?: Apollo.QueryHookOptions<JobPostingListQuery, JobPostingListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobPostingListQuery, JobPostingListQueryVariables>(JobPostingListDocument, options);
      }
export function useJobPostingListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobPostingListQuery, JobPostingListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobPostingListQuery, JobPostingListQueryVariables>(JobPostingListDocument, options);
        }
export type JobPostingListQueryHookResult = ReturnType<typeof useJobPostingListQuery>;
export type JobPostingListLazyQueryHookResult = ReturnType<typeof useJobPostingListLazyQuery>;
export type JobPostingListQueryResult = Apollo.QueryResult<JobPostingListQuery, JobPostingListQueryVariables>;