/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { HhaxCaregiverToSync } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DataTable } from "../../../../../components/data-table";

interface HHAXAutoSyncCaregiverPreviewTableProps {
  integrationId: string;
  itemsPerPage: number;
  currentPageIndex: number;
  onCurrentPageIndexChange(index: number): void;
  loading: boolean;
  items: ({
    __typename?: "HHAXCaregiverToSync";
  } & Pick<
    HhaxCaregiverToSync,
    | "id"
    | "disciplines"
    | "firstName"
    | "lastName"
    | "personalEmail"
    | "personalPhone"
    | "branchId"
    | "officeId"
    | "teamId"
    | "firstWorkedDate"
  >)[];
  total: number | undefined;
  title: ReactNode;
}

export function HHAXAutoSyncCaregiverPreviewTable({
  integrationId,
  itemsPerPage,
  currentPageIndex,
  onCurrentPageIndexChange,
  loading,
  items,
  total,
  title,
}: HHAXAutoSyncCaregiverPreviewTableProps) {
  const track = useTrack();

  const setAndTrackCurrentPageIndex = (index: number) => {
    track("HHAX Auto Sync caregiver list page changed", {
      integrationId,
      pageIndex: index,
    });
    onCurrentPageIndexChange(index);
  };

  return (
    <DataTable
      itemsPerPage={itemsPerPage}
      currentPageIndex={currentPageIndex}
      onCurrentPageIndexChange={setAndTrackCurrentPageIndex}
      loading={loading}
      items={items}
      total={total}
      emptyText="No caregivers found"
      title={title}
      tableHeaders={
        <TableRow>
          <TableHeader noWrap>
            <Typography variant="subtitle">First name</Typography>
          </TableHeader>
          <TableHeader noWrap>
            <Typography variant="subtitle">Last name</Typography>
          </TableHeader>
          <TableHeader noWrap>
            <Typography variant="subtitle">Personal email</Typography>
          </TableHeader>
          <TableHeader noWrap>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Typography variant="subtitle">Pref. phone</Typography>
              <Tooltip
                title="We use preferred phone number to communicate with employees."
                onOpen={() =>
                  track("HHAX Auto Sync viewed preferred phone tooltip", {
                    integrationId,
                  })
                }
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  css={(theme: AppTheme) => css`
                    margin-left: ${theme.spacing(1)};
                    color: ${theme.palette.text.primary};
                  `}
                />
              </Tooltip>
            </div>
          </TableHeader>
          <TableHeader noWrap>
            <Typography variant="subtitle">First work date</Typography>
          </TableHeader>
          <TableHeader noWrap>
            <Typography variant="subtitle" minWidth="150px">
              Discipline
            </Typography>
          </TableHeader>
          <TableHeader noWrap>
            <Typography variant="subtitle">Branch</Typography>
          </TableHeader>
          <TableHeader noWrap>
            <Typography variant="subtitle">Office</Typography>
          </TableHeader>
          <TableHeader noWrap>
            <Typography variant="subtitle">Team</Typography>
          </TableHeader>
        </TableRow>
      }
      tableBody={items.map((caregiver) => {
        return (
          <TableRow key={caregiver.id}>
            <TableCell divider>
              <Typography variant="body" color="grey.800">
                {caregiver.firstName}
              </Typography>
            </TableCell>
            <TableCell divider>
              <Typography variant="body" color="grey.800">
                {caregiver.lastName}
              </Typography>
            </TableCell>
            <TableCell divider>
              <Typography variant="body" color="grey.800">
                {caregiver.personalEmail ?? "-"}
              </Typography>
            </TableCell>
            <TableCell divider>
              <Typography variant="body" color="grey.800">
                {caregiver.personalPhone ?? "-"}
              </Typography>
            </TableCell>
            <TableCell divider>
              <Typography variant="body" color="grey.800">
                {caregiver.firstWorkedDate ?? "-"}
              </Typography>
            </TableCell>
            <TableCell divider>
              <Typography variant="body" color="grey.800">
                {caregiver.disciplines
                  ?.map((disc) => disc || "-")
                  ?.join(", ") || "-"}
              </Typography>
            </TableCell>
            <TableCell divider>
              <Typography variant="body" color="grey.800">
                {caregiver.branchId || "-"}
              </Typography>
            </TableCell>
            <TableCell divider>
              <Typography variant="body" color="grey.800">
                {caregiver.officeId || "-"}
              </Typography>
            </TableCell>
            <TableCell divider>
              <Typography variant="body" color="grey.800">
                {caregiver.teamId || "-"}
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
    />
  );
}
