import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminGoalsSettingsPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminGoalsSettingsPageDataQuery = (
  { __typename?: 'Query' }
  & { getCurrentAdminGoalConfigs: Array<(
    { __typename?: 'AdminGoalConfig' }
    & Pick<Types.AdminGoalConfig, 'id' | 'goalType' | 'startDate' | 'endDate' | 'enabled' | 'launched' | 'recurring'>
    & { admins: Array<(
      { __typename?: 'RewardsAdminUser' }
      & Pick<Types.RewardsAdminUser, 'id'>
    )>, rewards: (
      { __typename: 'AdminGoalRewardsGiftCard' }
      & Pick<Types.AdminGoalRewardsGiftCard, 'dollarValue'>
    ) | (
      { __typename: 'AdminGoalRewardsPayroll' }
      & Pick<Types.AdminGoalRewardsPayroll, 'dollarValue'>
    ), target: (
      { __typename: 'AdminGoalTargetPercentage' }
      & Pick<Types.AdminGoalTargetPercentage, 'percentage'>
    ) | { __typename: 'AdminGoalTargetTimeToFirstShift' }, recurringAdmins?: Types.Maybe<Array<(
      { __typename?: 'RewardsAdminUser' }
      & Pick<Types.RewardsAdminUser, 'id'>
    )>>, recurringRewards?: Types.Maybe<(
      { __typename: 'AdminGoalRewardsGiftCard' }
      & Pick<Types.AdminGoalRewardsGiftCard, 'dollarValue'>
    ) | (
      { __typename: 'AdminGoalRewardsPayroll' }
      & Pick<Types.AdminGoalRewardsPayroll, 'dollarValue'>
    )>, recurringTarget?: Types.Maybe<(
      { __typename: 'AdminGoalTargetPercentage' }
      & Pick<Types.AdminGoalTargetPercentage, 'percentage'>
    ) | { __typename: 'AdminGoalTargetTimeToFirstShift' }> }
  )>, organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'launchedAt'>
  ) }
);


export const AdminGoalsSettingsPageDataDocument = gql`
    query AdminGoalsSettingsPageData {
  getCurrentAdminGoalConfigs {
    id
    goalType
    admins {
      id
    }
    rewards {
      __typename
      ... on AdminGoalRewardsGiftCard {
        dollarValue
      }
      ... on AdminGoalRewardsPayroll {
        dollarValue
      }
    }
    target {
      __typename
      ... on AdminGoalTargetPercentage {
        percentage
      }
    }
    startDate
    endDate
    enabled
    launched
    recurring
    recurringAdmins {
      id
    }
    recurringRewards {
      __typename
      ... on AdminGoalRewardsGiftCard {
        dollarValue
      }
      ... on AdminGoalRewardsPayroll {
        dollarValue
      }
    }
    recurringTarget {
      __typename
      ... on AdminGoalTargetPercentage {
        percentage
      }
    }
  }
  organization: getMyRewardsOrganization {
    id
    launchedAt
  }
}
    `;

/**
 * __useAdminGoalsSettingsPageDataQuery__
 *
 * To run a query within a React component, call `useAdminGoalsSettingsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGoalsSettingsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGoalsSettingsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGoalsSettingsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<AdminGoalsSettingsPageDataQuery, AdminGoalsSettingsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGoalsSettingsPageDataQuery, AdminGoalsSettingsPageDataQueryVariables>(AdminGoalsSettingsPageDataDocument, options);
      }
export function useAdminGoalsSettingsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGoalsSettingsPageDataQuery, AdminGoalsSettingsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGoalsSettingsPageDataQuery, AdminGoalsSettingsPageDataQueryVariables>(AdminGoalsSettingsPageDataDocument, options);
        }
export type AdminGoalsSettingsPageDataQueryHookResult = ReturnType<typeof useAdminGoalsSettingsPageDataQuery>;
export type AdminGoalsSettingsPageDataLazyQueryHookResult = ReturnType<typeof useAdminGoalsSettingsPageDataLazyQuery>;
export type AdminGoalsSettingsPageDataQueryResult = Apollo.QueryResult<AdminGoalsSettingsPageDataQuery, AdminGoalsSettingsPageDataQueryVariables>;