import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type JobsListQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  branchIds?: Types.Maybe<Array<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
}>;


export type JobsListQuery = (
  { __typename?: 'Query' }
  & { listJobPostings: (
    { __typename?: 'JobPostingListResponse' }
    & Pick<Types.JobPostingListResponse, 'total'>
    & { items: Array<(
      { __typename?: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title' | 'geography' | 'closedForSubmission'>
      & { activePromotion?: Types.Maybe<(
        { __typename?: 'JobPostingPromotion' }
        & Pick<Types.JobPostingPromotion, 'id'>
      )>, branch?: Types.Maybe<(
        { __typename?: 'RewardsOrganizationBranch' }
        & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
      )>, stats: (
        { __typename?: 'JobPostingStats' }
        & Pick<Types.JobPostingStats, 'id' | 'numShares' | 'numCandidates' | 'numInterviewsScheduled' | 'numHires'>
      ), referralStructure: (
        { __typename?: 'ReferralStructure' }
        & Pick<Types.ReferralStructure, 'id' | 'name' | 'totalAwardablePointValue'>
      ) }
    )> }
  ) }
);


export const JobsListDocument = gql`
    query JobsList($limit: Int!, $offset: Int!, $branchIds: [ID]) {
  listJobPostings(limit: $limit, offset: $offset, filter: {branchIds: $branchIds}) {
    total
    items {
      id
      title
      geography
      closedForSubmission
      activePromotion {
        id
      }
      branch {
        id
        name
      }
      stats {
        id
        numShares
        numCandidates
        numInterviewsScheduled
        numHires
      }
      referralStructure {
        id
        name
        totalAwardablePointValue
      }
    }
  }
}
    `;

/**
 * __useJobsListQuery__
 *
 * To run a query within a React component, call `useJobsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      branchIds: // value for 'branchIds'
 *   },
 * });
 */
export function useJobsListQuery(baseOptions: Apollo.QueryHookOptions<JobsListQuery, JobsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsListQuery, JobsListQueryVariables>(JobsListDocument, options);
      }
export function useJobsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsListQuery, JobsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsListQuery, JobsListQueryVariables>(JobsListDocument, options);
        }
export type JobsListQueryHookResult = ReturnType<typeof useJobsListQuery>;
export type JobsListLazyQueryHookResult = ReturnType<typeof useJobsListLazyQuery>;
export type JobsListQueryResult = Apollo.QueryResult<JobsListQuery, JobsListQueryVariables>;