/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

interface TableBodyProps extends StylableProps {
  children?: ReactNode;
  disabled?: boolean;
}

export function TableBody({
  className,
  children,
  disabled = false,
}: TableBodyProps): JSX.Element {
  return (
    <tbody
      className={className}
      css={(theme: AppTheme) => css`
        ${disabled &&
        css`
          border-radius: 4px;
          background-color: ${theme.palette.grey[100]};
          opacity: 0.4;
          pointer-events: none;
        `}
      `}
    >
      {children}
    </tbody>
  );
}
