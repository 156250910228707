/** @jsxImportSource @emotion/react */
import { ClassNames, css, useTheme } from "@emotion/react";
import {
  alpha,
  CircularProgress,
  darken,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ComponentProps, forwardRef, ReactNode, useState } from "react";

import { AppTheme, headerFontFamily } from "@rewards-web/shared/style/theme";

import {
  ControlledFormFieldProps,
  StylableProps,
  TestProps,
} from "../../types";
import { useFormControlContext } from "../form/form-control";
import { TelMask } from "./masks/tel";

export interface TextFieldProps
  extends StylableProps,
    ControlledFormFieldProps,
    TestProps {
  /**
   * The label of the input, displayed at the top of the input
   */
  label: string;

  /**
   * Hides the label (with the label still needed for accessibility)
   */
  hideLabel?: boolean;

  /**
   * Type of the `input` element
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types
   *
   * This is passed to `<input type={type}>` under the hood, but is also used
   * to apply text masks where appropriate.
   */
  type?:
    | "text"
    | "textarea"
    | "number"
    | "password"
    | "email"
    | "tel"
    | "search";

  /**
   * If a text area, the min number of rows to show.
   */
  minRows?: number;

  /**
   * Max rows to show.
   */
  maxRows?: number;

  /**
   * Changes the height of the text field.
   */
  size?: "small" | "medium" | "large";

  fixedHeight?: number;

  /**
   * Disables the input
   */
  disabled?: boolean;

  /**
   * Applies a grey background when read-only
   */
  greyReadOnly?: boolean;

  /**
   * Overrides the background color of the label
   */
  notchedLabelBackgroundColor?: string;

  /**
   * Changes how the textfield is displayed
   */
  styleType?: "outlined" | "filled";

  /**
   * Adds space on the top between the cursor and the top border
   */
  topPadding?: number;

  /**
   * Specify the margin-bottom for the text field. Defaults to 1 space.
   */
  marginBottom?: string;

  /**
   * Marks the input as read-only.
   * This is different from disabled in that the form can be submitted with this value.
   *
   *
   */
  readOnly?: boolean;

  /**
   * Disables browser autocomplete
   */
  disableAutocomplete?: boolean;

  /**
   * Shows below the text field (when there is no error)
   */
  helperText?: ReactNode;

  /**
   * Hides the space below the input
   */
  hideSpaceForErrorText?: boolean;

  /**
   * Placeholder text to show in the input.
   * This is cleared when the user starts typing.
   */
  placeholder?: string;

  /**
   * Sets the default value of the text field.
   */
  defaultValue?: string;

  /**
   * Fixes the label to the top of the input (so it doesn't jump around)
   */
  fixLabelToTop?: boolean;

  /**
   * Auto-focuses this text field on mount.
   * Useful for the for the first field in a form.
   */
  autoFocus?: boolean;

  /**
   * If true, shows a loading spinner as an end adornment
   */
  loading?: boolean;

  /**
   * If true, shows text field's content on hotjar recordings
   */
  exposeToHotjar?: boolean;

  // the following are required by SearchTextField to work properly
  InputLabelProps?: Omit<
    NonNullable<ComponentProps<typeof MuiTextField>["InputLabelProps"]>,
    "shrink"
  >;
  FormHelperTextProps?: object;
  InputProps?: Partial<{
    ref: React.Ref<any>;
    className: string;
    startAdornment: React.ReactNode;
    endAdornment: React.ReactNode;
  }>;
  inputProps?: object;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  hideEndAdornment?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: () => void;
  onPaste?: React.ClipboardEventHandler<HTMLDivElement>;
  onKeyDown?: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  autocomplete?: string;
}

/**
 * Text fields let users enter and edit text
 */
export const TextField = forwardRef(
  (
    {
      className,
      name,
      defaultValue,
      onChange,
      onBlur,
      onFocus,
      onClick,
      value,
      label,
      hideLabel,
      error,
      styleType = "filled",
      disabled,
      greyReadOnly,
      notchedLabelBackgroundColor,
      readOnly: readOnlyProp,
      disableAutocomplete,
      loading,
      type = "text",
      minRows,
      maxRows,
      size: sizeFromProps,
      placeholder,
      helperText,
      hideSpaceForErrorText,
      fixLabelToTop,
      autoFocus,
      InputLabelProps,
      FormHelperTextProps,
      InputProps,
      inputProps,
      exposeToHotjar,
      startAdornment: startAdornmentProp,
      cypressId,
      topPadding,
      fixedHeight,
      testId,
      onPaste,
      onKeyDown,
      endAdornment: endAdornmentProp,
      hideEndAdornment,
      autocomplete,
      marginBottom,
    }: TextFieldProps,
    ref?: React.Ref<HTMLTextAreaElement | HTMLInputElement>
  ): JSX.Element => {
    const theme = useTheme();
    const { submitting, readOnly: contextReadOnly } = useFormControlContext();

    const size = sizeFromProps ?? theme.defaultTextFieldSize ?? "medium";

    const [passwordHidden, setPasswordHidden] = useState<boolean>(true);

    const handleClickShowPassword = () => {
      setPasswordHidden(!passwordHidden);
    };
    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault();
    };

    const inputType = (() => {
      if (type !== "password") {
        return type;
      }

      if (passwordHidden) {
        return "password";
      }

      return "text";
    })();

    const readOnly = contextReadOnly || readOnlyProp || submitting;

    const startAdornment = (() => {
      if (type === "tel") {
        return (
          <>
            <InputAdornment position="start">
              {startAdornmentProp}
            </InputAdornment>
            <InputAdornment position="start">+1</InputAdornment>
          </>
        );
      }

      if (type === "search") {
        return (
          <InputAdornment
            position="start"
            css={css`
              max-height: initial;
              height: initial;
            `}
          >
            <SearchIcon
              css={(theme: AppTheme) => css`
                margin-right: ${theme.spacing(0.5)};
                font-size: 20px;
                color: ${alpha(theme.palette.text.primary, 0.9)};
              `}
            />
          </InputAdornment>
        );
      }

      if (startAdornmentProp) {
        return (
          <InputAdornment
            position="start"
            css={css`
              max-height: initial;
              height: initial;
            `}
          >
            {startAdornmentProp}
          </InputAdornment>
        );
      }
    })();

    return (
      <ClassNames>
        {({ css, cx, theme }) => (
          <MuiTextField
            id={name}
            name={name}
            value={value}
            onClick={onClick}
            autoFocus={autoFocus}
            multiline={inputType === "textarea"}
            className={cx(
              css`
                width: 100%;
                margin-bottom: ${marginBottom ?? theme.spacing(1)};
              `,
              className
            )}
            placeholder={placeholder}
            minRows={minRows}
            maxRows={maxRows}
            label={hideLabel ? undefined : label}
            inputProps={{
              ...inputProps,
              "data-cy": cypressId,
              "data-testid": testId,
              "data-hj-allow": exposeToHotjar ? true : undefined,
              className: cx(
                css`
                  ${size === "large" &&
                  inputType !== "textarea" &&
                  css`
                    padding-top: 22.5px;
                    padding-bottom: 22.5px;
                  `}

                  ${topPadding &&
                  css`
                    margin-top: ${topPadding}px;
                  `}

                  ${size !== "small" &&
                  inputType !== "textarea" &&
                  css`
                    height: ${fixedHeight ? fixedHeight + "px" : "0.8em"};
                  `}
                  ${fixedHeight &&
                  css`
                    box-sizing: border-box;
                  `}

                  ${size === "small" &&
                  inputType !== "textarea" &&
                  css`
                    padding-top: 10px;
                    padding-bottom: 10px;
                  `}

                  &::placeholder {
                    opacity: 1;
                    color: ${theme.palette.text.secondary};
                  }
                `,
                (inputProps as any)?.className
              ),
              readOnly,
              "aria-label": label,
              autoComplete: disableAutocomplete ? "off" : autocomplete,
            }}
            type={inputType}
            variant="outlined"
            InputProps={{
              ...InputProps,
              onPaste,
              onKeyDown,
              onWheel: (e) => {
                if (type === "number") {
                  // prevent wheel scroll from incrementing / decrementing numeric input
                  (e.target as any).blur?.();
                }
              },
              inputComponent: type === "tel" ? TelMask : undefined,
              classes: {
                root: "custom-root",
                focused: "custom-focused",
                error: "custom-error",
                notchedOutline: "custom-notched-outline",
                disabled: "custom-disabled",
              },
              className: cx(
                InputProps?.className,
                css`
                  border-radius: ${type === "search"
                    ? size === "small"
                      ? theme.spacing(1)
                      : theme.spacing(1.5)
                    : theme.spacing(1)};

                  background-color: ${theme.palette.background.paper};

                  &:hover:not(.custom-focused):not(.custom-error)
                    .custom-notched-outline {
                    border-color: ${darken(theme.palette.divider, 0.15)};
                  }
                  &.custom-root.custom-disabled .custom-notched-outline,
                  .custom-notched-outline {
                    border-color: ${styleType === "filled"
                      ? theme.palette.divider
                      : alpha("#C4C4C4", 0.3)};
                  }

                  ${styleType === "outlined" &&
                  css`
                    &.custom-error .custom-notched-outline {
                      border-color: #df5454;
                      border-width: 1px;
                    }
                  `}

                  &.custom-focused .custom-notched-outline {
                    border-width: 1px;
                  }

                  ${readOnly &&
                  greyReadOnly &&
                  css`
                    &.Mui-disabled {
                      background-color: #eaeaea;
                      color: #636666;
                      input {
                        cursor: initial;
                      }
                    }
                  `}

                  ${styleType === "outlined" &&
                  css`
                    font-family: ${headerFontFamily};
                    font-size: 1em;
                    font-weight: bold;
                    &.MuiOutlinedInput-root.Mui-disabled {
                      background-color: ${alpha("#C4C4C4", 0.3)};
                      color: ${alpha("#ffffff", 0.5)};
                    }
                    &.Mui-error {
                      color: #df5454;
                      border-color: #df5454;
                    }
                  `}
                `,

                // apply disabled styles if field is read-only
                readOnly ? "Mui-disabled custom-disabled" : null
              ),
              startAdornment,
              endAdornment: hideEndAdornment ? null : (
                <InputAdornment
                  position="end"
                  css={css`
                    height: initial;
                    max-height: initial;
                  `}
                >
                  {loading ? (
                    <CircularProgress
                      css={css`
                        margin-right: 4px;
                      `}
                      color="inherit"
                      size={20}
                    />
                  ) : null}
                  {type === "password" ? (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      role="option"
                    >
                      {passwordHidden ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ) : null}
                  {endAdornmentProp ?? null}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              ...InputLabelProps,
              shrink: fixLabelToTop ?? undefined,
              className: css`
                ${size === "large" &&
                css`
                  transform: translate(14px, 22px) scale(1);
                `}

                ${size === "small" &&
                css`
                  transform: translate(14px, 12px) scale(1);
                `}

                ${notchedLabelBackgroundColor &&
                value &&
                css`
                  background-color: ${notchedLabelBackgroundColor};
                  border-radius: 2px;
                `}
              `,
            }}
            defaultValue={defaultValue}
            onChange={onChange}
            onFocus={onFocus}
            disabled={disabled || readOnly}
            onBlur={onBlur}
            inputRef={ref}
            helperText={
              error?.message ||
              helperText ||
              (hideSpaceForErrorText ? undefined : " ") // ensure helper text stays rendered so height doesn't jump around:
            }
            FormHelperTextProps={{
              ...FormHelperTextProps,
              classes: { root: "custom-root" },
              className: css`
                &.custom-root {
                  white-space: normal;
                  ${styleType === "outlined" &&
                  error?.message &&
                  css`
                    background-color: #df5454;
                    margin-top: 0;
                    border-radius: 0 0 10px 10px;
                    padding: 1px ${theme.spacing(1.5)};
                    color: ${theme.palette.common.white} !important;
                    font-family: ${headerFontFamily};
                    font-size: 0.7em;
                  `}
                }
              `,
            }}
            error={Boolean(error)}
          />
        )}
      </ClassNames>
    );
  }
);
