import { subYears } from "date-fns";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { DateField } from "@rewards-web/shared/components/date-field";

interface EmployeeDateOfBirthFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  greyReadOnly?: boolean;
}

export function EmployeeDateOfBirthField<T extends FieldValues>({
  control,
  name,
  greyReadOnly = false,
}: EmployeeDateOfBirthFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <DateField
          {...field}
          label="Date of birth"
          variant="date"
          maxDate={subYears(new Date(), 14)}
          greyReadOnly={greyReadOnly}
          dontDisplayDayOfWeek
        />
      )}
    />
  );
}
