/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { ReactNode } from "react";

import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";

interface SurveyFormRowProps {
  title: ReactNode;
  subtitle: ReactNode;
  right: ReactNode;
  divider?: boolean;
}

export function SurveyFormRow({
  title,
  subtitle,
  right,
  divider,
}: SurveyFormRowProps) {
  const theme = useTheme();
  return (
    <div>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
        `}
      >
        <div
          css={css`
            margin-bottom: ${theme.spacing(3)};
            margin-right: ${theme.spacing(4)};
          `}
        >
          <Typography variant="h5" component="h1" gutterBottom>
            {title}
          </Typography>
          <Typography variant="footnote" color={theme.palette.grey[800]}>
            {subtitle}
          </Typography>
        </div>
        <div>{right}</div>
      </div>
      {divider && <Divider />}
    </div>
  );
}
