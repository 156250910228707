import { format, parseISO } from "date-fns";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { useTrackScreenRecordingEvent } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";

import { DataTable } from "../../../../shared/components/data-table";
import { RecognitionPointsModal } from "../recognition-points-modal";
import { useMyScheduledPointsTableQuery } from "./my-scheduled-points-table.generated";

const PER_PAGE = 10;

export function MyScheduledPointsPage() {
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingMessageForPoints, setEditingMessageForPoints] = useState<{
    recognitionPointsId: string;
    userId: string;
    pointValue: number;
    message: string | null;
    defaultMessage: string | null;
    categoryId: string | null;
    from: string | null;
  }>();
  const query = useMyScheduledPointsTableQuery({
    onError: reportError,
    variables: {
      limit: PER_PAGE,
      offset: currentPageIndex * PER_PAGE,
    },
  });

  const handleClickEditMessage = (
    item: Pick<
      GraphQLTypes.RecognitionPoints,
      | "id"
      | "pointValue"
      | "scheduledToSendOn"
      | "message"
      | "defaultMessage"
      | "from"
    > & {
      employee: { __typename?: "RewardsUser" } & Pick<
        GraphQLTypes.RewardsUser,
        "id" | "firstName" | "lastName"
      >;
      category?: GraphQLTypes.Maybe<
        { __typename?: "RecognitionCategory" } & Pick<
          GraphQLTypes.RecognitionCategory,
          "id" | "name"
        >
      >;
    }
  ) => {
    trackScreenRecordingEvent("clicked_edit_recognition_message_button");
    setEditingMessageForPoints({
      recognitionPointsId: item.id,
      userId: item.employee.id,
      pointValue: item.pointValue,
      message: item.message ?? null,
      defaultMessage: item.defaultMessage ?? null,
      categoryId: item.category?.id ?? null,
      from: item.from ?? null,
    });
    setEditModalOpen(true);
  };

  const handleCloseEditMessage = () => {
    setEditModalOpen(false);
  };

  const handleAddMessageModalExited = () => {
    setEditingMessageForPoints(undefined);
  };

  return (
    <>
      <DataTable
        itemsPerPage={PER_PAGE}
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={setCurrentPageIndex}
        emptyText="There are no scheduled points." // this shouldn't really happen
        loading={query.loading}
        items={query.data?.listMyManagerScheduledRecognitionPoints.items}
        total={query.data?.listMyManagerScheduledRecognitionPoints.total}
        tableHeaders={
          <TableRow>
            <TableHeader>
              <Typography variant="subtitle">Employee Name</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle">Points To Be Awarded</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle">Reward Date</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle">Category</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle">Add Message</Typography>
            </TableHeader>
          </TableRow>
        }
        tableBody={query.data?.listMyManagerScheduledRecognitionPoints.items.map(
          (item) => {
            return (
              <TableRow key={item.id}>
                <TableCell divider>
                  <ObscureRecordedText>
                    <Typography variant="body" color="textSecondary">
                      {item.employee.firstName} {item.employee.lastName}
                    </Typography>
                  </ObscureRecordedText>
                </TableCell>
                <TableCell divider>
                  <Typography variant="body" color="textSecondary">
                    {numberWithCommas(item.pointValue)}
                  </Typography>
                </TableCell>
                <TableCell divider>
                  <Typography variant="body" color="textSecondary">
                    {item.scheduledToSendOn
                      ? format(parseISO(item.scheduledToSendOn), "LLL d, yyyy")
                      : "-"}
                  </Typography>
                </TableCell>
                <TableCell divider>
                  <Typography variant="body" color="textSecondary">
                    {item.category?.name || "-"}
                  </Typography>
                </TableCell>
                <TableCell divider>
                  <Typography variant="body" color="textSecondary">
                    <Button
                      {...(item.message
                        ? {
                            label: "Edit Message",
                            color: "primary",
                          }
                        : {
                            label: "Add Message",
                            color: "secondary",
                          })}
                      size="small"
                      width="auto"
                      onClick={() => handleClickEditMessage(item)}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            );
          }
        )}
      />

      <RecognitionPointsModal
        open={editModalOpen}
        onClose={handleCloseEditMessage}
        onExited={handleAddMessageModalExited}
        editingMessageForRecognitionPoints={editingMessageForPoints}
      />
    </>
  );
}
