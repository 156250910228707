import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateBranchDataQueryVariables = Types.Exact<{
  branchId: Types.Scalars['String'];
}>;


export type UpdateBranchDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'shortName'>
  ), getMyRewardsOrganizationBranch: (
    { __typename?: 'RewardsOrganizationBranch' }
    & Pick<Types.RewardsOrganizationBranch, 'id' | 'name' | 'timezone'>
    & { autoMembershipTagCriteria?: Types.Maybe<(
      { __typename?: 'RewardsGroupTagsConfigSchemaV1' }
      & { or: Array<(
        { __typename?: 'RewardsGroupTagsConfigSchemaV1AndSchema' }
        & { and: Array<(
          { __typename?: 'RewardsTag' }
          & Pick<Types.RewardsTag, 'id' | 'externalId' | 'displayName' | 'activeUserCount'>
        )> }
      )> }
    )> }
  ) }
);


export const UpdateBranchDataDocument = gql`
    query UpdateBranchData($branchId: String!) {
  getMyRewardsOrganization {
    id
    shortName
  }
  getMyRewardsOrganizationBranch(branchId: $branchId) {
    id
    name
    timezone
    autoMembershipTagCriteria {
      ... on RewardsGroupTagsConfigSchemaV1 {
        or {
          and {
            id
            externalId
            displayName
            activeUserCount
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUpdateBranchDataQuery__
 *
 * To run a query within a React component, call `useUpdateBranchDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateBranchDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateBranchDataQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useUpdateBranchDataQuery(baseOptions: Apollo.QueryHookOptions<UpdateBranchDataQuery, UpdateBranchDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateBranchDataQuery, UpdateBranchDataQueryVariables>(UpdateBranchDataDocument, options);
      }
export function useUpdateBranchDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateBranchDataQuery, UpdateBranchDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateBranchDataQuery, UpdateBranchDataQueryVariables>(UpdateBranchDataDocument, options);
        }
export type UpdateBranchDataQueryHookResult = ReturnType<typeof useUpdateBranchDataQuery>;
export type UpdateBranchDataLazyQueryHookResult = ReturnType<typeof useUpdateBranchDataLazyQuery>;
export type UpdateBranchDataQueryResult = Apollo.QueryResult<UpdateBranchDataQuery, UpdateBranchDataQueryVariables>;