/** @jsxImportSource @emotion/react */
import { addDays, format, subDays } from "date-fns";
import { Navigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { AdminGoalType } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import {
  AdminGoalFormValues,
  BaseGoalConfigurationForm,
} from "../../base-goal-configuration-form";
import { useFirstMonthLoginGoalFormDataQuery } from "./first-month-login-goal-form-data.generated";

const DEFAULT_REWARD_AMOUNT = 20;
const DEFAULT_TARGET_PERCENTAGE = 70;

export function First30DayLoginGoalForm(): JSX.Element {
  const formDataQuery = useFirstMonthLoginGoalFormDataQuery({
    onError: reportError,
  });

  if (formDataQuery.error) {
    return (
      <Alert
        severity="error"
        message="Error configuring goal. Please try again."
      />
    );
  }

  if (!formDataQuery.data) {
    return <PageLoadingState />;
  }

  const launchedAt = formDataQuery.data?.getMyRewardsOrganization?.launchedAt;

  if (!launchedAt) {
    return <Navigate to="/settings/office-goals" />;
  }

  const startDate = new Date(launchedAt);
  // End date is not inclusive, goal is open for 30 days
  const endDate = addDays(new Date(launchedAt), 31);

  const defaultValues: AdminGoalFormValues = {
    adminIds: [],
    rewardAmount: DEFAULT_REWARD_AMOUNT,
    targetPercentage: DEFAULT_TARGET_PERCENTAGE,
    startDate,
    endDate,
    recurring: "false",
  };

  return (
    <BaseGoalConfigurationForm
      goalType={AdminGoalType.LoginAfterLaunch}
      goalTitle="First 30-day login goal"
      goalPeriod={`${format(startDate, "MMM d")} - ${format(
        subDays(endDate, 1),
        "MMM d, yyyy"
      )}`}
      defaultValues={defaultValues}
    />
  );
}
