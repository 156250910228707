/** @jsxImportSource @emotion/react */
import { FieldValues, Control, Controller, Path } from "react-hook-form";

import { CheckboxField } from "@rewards-web/shared/components/checkbox-field";

interface PulseSurveySendInitialSurveyFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  disabled: boolean;
}

export function PulseSurveySendInitialSurveyField<T extends FieldValues>({
  control,
  name,
  disabled,
}: PulseSurveySendInitialSurveyFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <CheckboxField
          label="Launch survey on program start date"
          {...field}
          value={field.value ?? false}
          disabled={disabled}
        />
      )}
    />
  );
}
