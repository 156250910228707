import { endOfMonth, format, parseISO } from "date-fns";

export const formatMonthPeriod = (date: Date): string => {
  // Parse the date as UTC, taking just the date portion
  const utcDate = parseISO(date.toISOString().split("T")[0]);

  const periodStart = format(utcDate, "MMMM d");
  const periodEnd = format(endOfMonth(utcDate), "MMMM d");
  const month = format(utcDate, "MMMM");
  const year = format(utcDate, "yyyy");

  return `${month} (${periodStart} - ${periodEnd}) ${year}`;
};
