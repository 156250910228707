/** @jsxImportSource @emotion/react */
import { FieldValues, Control, Controller, Path } from "react-hook-form";

import { SelectField } from "@rewards-web/shared/components/select-field";

interface SurveySettingsDurationFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  disabled: boolean;
  options: { label: string; value: string }[];
}

export function SurveySettingsDurationField<T extends FieldValues>({
  control,
  name,
  disabled,
  options,
}: SurveySettingsDurationFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <SelectField
          {...field}
          label="Survey duration"
          error={fieldState.error}
          width="full"
          disabled={disabled}
          readOnly={disabled}
          greyReadOnly
          options={options}
        />
      )}
    />
  );
}
