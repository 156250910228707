/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Form } from "@rewards-web/shared/components/form";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsOrganizationOnboardingStepName } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import {
  jobShareStructureFromApiToFormValue,
  referralStructureFormValuesToMutationVariables,
  referralStructureFromApiToFormValue,
} from "../../../settings/pages/referral-structure/shared/referral-structure-form/lib";
import { ReferralStructureTable } from "../../../settings/pages/referral-structure/shared/referral-structure-form/referral-structure-table";
import { ReferralStructureFormStep } from "../../../settings/pages/referral-structure/shared/referral-structure-form/types";
import { AdminOnboardingCard } from "../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../components/step-actions";
import { useNextOnboardingStepPath } from "../../hooks/use-next-step-path";
import { useOnboardingState } from "../../hooks/use-onboarding-state";
import { useOnboardingEditReferralStructureMutation } from "./onboarding-edit-referral-structure.generated";
import { useOnboardingReferralStructureDataQuery } from "./onboarding-referral-structure-data.generated";

interface OnboardingReferralStructureFormValues {
  steps: ReferralStructureFormStep[];
  name: string;
  jobSharePointValue: string;
}

export function OnboardingReferralsPage() {
  const track = useTrack();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const nextOnboardingStepPath = useNextOnboardingStepPath();
  const onboardingState = useOnboardingState({ loadFreshData: true });

  const hasPreviouslyCompleted = onboardingState.completedSteps.has(
    RewardsOrganizationOnboardingStepName.ReferralStructure
  );

  const query = useOnboardingReferralStructureDataQuery({
    onError: reportError,
  });
  const [
    editReferralStructure,
    { loading: submitting },
  ] = useOnboardingEditReferralStructureMutation();

  const form = useForm<OnboardingReferralStructureFormValues>({
    defaultValues: {
      name: "",
      steps: [],
      jobSharePointValue: "",
    },
  });

  const defaultReferralStructure = query.data?.getAllReferralStructures.find(
    (structure) => structure.id.startsWith("ORGANIZATION_DEFAULT:")
  );

  // initialize form with the default referral structure values,
  // after it loads
  useEffect(() => {
    if (query.data) {
      if (!defaultReferralStructure) {
        return;
      }

      const jobShareStructure =
        query.data.getMyRewardsOrganization.referralRewardStructure
          .jobShareStructure;

      form.reset({
        name: defaultReferralStructure.name,
        jobSharePointValue: jobShareStructureFromApiToFormValue(
          jobShareStructure
        ),
        steps: referralStructureFromApiToFormValue(
          defaultReferralStructure.items
        ),
      });
    }
  }, [query.data, form, defaultReferralStructure]);

  const handleSubmit = async (
    values: OnboardingReferralStructureFormValues
  ) => {
    try {
      const { structure } = referralStructureFormValuesToMutationVariables(
        values
      );

      if (!defaultReferralStructure) {
        throw new Error("Default referral structure not found");
      }

      await editReferralStructure({
        variables: {
          referralStructureId: defaultReferralStructure.id,
          structure,
        },
      });

      track("Updated onboarding referral structure", {
        referralStructureId: defaultReferralStructure.id,
      });

      snackbar.show({
        severity: "success",
        message: "Updated Referral Structure",
      });

      await onboardingState.recordCompletedOnboardingStep(
        RewardsOrganizationOnboardingStepName.ReferralStructure
      );

      if (!nextOnboardingStepPath) {
        throw new Error("No next onboarding step path");
      }

      navigate(nextOnboardingStepPath);
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message:
          "An unexpected error occurred while saving the referral structure. Please try again later.",
      });
    }
  };

  const content = (() => {
    if (query.error) {
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred while loading the referral structure. Please try again later."
        />
      );
    }

    if (!query.data) {
      return <PageLoadingState />;
    }

    if (!defaultReferralStructure) {
      // this should not happen
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred while loading the referral structure. Please try again later."
        />
      );
    }

    return (
      <>
        <ReferralStructureTable
          control={form.control}
          name="steps"
          jobShareFieldName="jobSharePointValue"
          jobShareStructure={
            query.data.getMyRewardsOrganization.referralRewardStructure
              .jobShareStructure
          }
          pointsPerDollar={query.data.getMyRewardsOrganization.pointsPerDollar}
          totalsVariant="withinTable"
        />

        <Alert
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(1)};
          `}
          severity="info"
          customIcon={
            <FontAwesomeIcon
              icon={faQuestionCircle}
              color="#444"
              size="xs"
              css={css`
                margin-top: 3px;
              `}
            />
          }
          title="Can I add or edit a referral structure later?"
          message={
            <>
              Referral structures are locked to ensure accurate statistics once
              you've launched with Caribou. However, you can{" "}
              <Link
                to="/settings/referral-structure"
                css={(theme: AppTheme) => css`
                  color: ${theme.palette.primary.main};
                `}
              >
                add more
              </Link>{" "}
              additional referral structures to better align with your agency's
              needs.
            </>
          }
          messageTypographyVariant="footnote"
        />
      </>
    );
  })();

  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)} submitting={submitting}>
      <AdminOnboardingCard
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <AdminOnboardingCardHeader title="☎️ Referral Structure" />
        <AdminOnboardingCardContent>
          <div
            css={(appTheme: AppTheme) => css`
              margin-bottom: ${appTheme.spacing(1)};
            `}
          >
            <Typography
              color="textSecondary"
              variant="body"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              Strong referrals matter! The secret to our referral program and
              helping you hire the best caregivers is that caregivers get
              rewarded every step of the way, even just for sharing a job
              posting!
            </Typography>

            <Typography color="textSecondary" variant="body">
              Here's our recommended referral payout structure to the referring
              caregiver. Please note that the budget you select here is not
              included in the current subscription and will be an additional
              charge.
            </Typography>
          </div>

          {content}
        </AdminOnboardingCardContent>
      </AdminOnboardingCard>

      <AdminOnboardingStepActions
        disableSkip={hasPreviouslyCompleted}
        submitButtonLabel={"Save and Continue"}
      />
    </Form>
  );
}
