
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "AdminBulkCreateRewardsUserResult": [
      "AdminBulkCreateRewardsUserError",
      "AdminBulkCreateRewardsUserSuccess"
    ],
    "AdminGoalRewards": [
      "AdminGoalRewardsGiftCard",
      "AdminGoalRewardsPayroll"
    ],
    "AdminGoalTarget": [
      "AdminGoalTargetPercentage",
      "AdminGoalTargetTimeToFirstShift"
    ],
    "AdminGoalTracker": [
      "BudgetUtilizationAdminGoalTracker",
      "LoginAdminGoalTracker",
      "TimeToFirstShiftAdminGoalTracker"
    ],
    "BaseDraw": [
      "CaribouSponsoredDraw",
      "Draw"
    ],
    "BaseDrawPrize": [
      "DrawPrizePoints",
      "DrawTieredPrizePoints"
    ],
    "BaseDrawPrizeStructure": [
      "DrawPrizeStructureSinglePrizeMultipleWinners",
      "DrawPrizeStructureTieredPrizesMultipleWinners"
    ],
    "CandidateNextStepFilterOption": [
      "CandidateRecruitmentStepFilterOption",
      "CandidateMonthlyRetentionStepFilterOption",
      "CandidateHourlyRetentionStepFilterOption"
    ],
    "CandidateStep": [
      "CandidateRecruitmentStep",
      "CandidateRetentionStep"
    ],
    "CandidateStepV2": [
      "CandidateRecruitmentStep",
      "CandidateMonthlyRetentionStep",
      "CandidateHourlyRetentionStep"
    ],
    "CategorizedTransactionMetadata": [
      "TransactionMetadataBillableHoursIncrease",
      "TransactionMetadataDrawWinner",
      "TransactionMetadataEVVPunchCard",
      "TransactionMetadataEVVStreakGoal",
      "TransactionMetadataLastMinuteShift",
      "TransactionMetadataMilestoneRewards",
      "TransactionMetadataMobileAppAdoption",
      "TransactionMetadataRecognition",
      "TransactionMetadataRedemption",
      "TransactionMetadataRedemptionRejected",
      "TransactionMetadataReferralAssignedAsReferringEmployee",
      "TransactionMetadataReferralCorrection",
      "TransactionMetadataReferralJobShare",
      "TransactionMetadataReferralRecruitmentStep",
      "TransactionMetadataReferralRetentionMilestone",
      "TransactionMetadataSurvey",
      "TransactionMetadataTrainingProgram",
      "TransactionMetadataUnknown",
      "TransactionMetadataWatchedVideo",
      "TransactionMetadataWeekendShift"
    ],
    "DrawPrize": [
      "DrawPrizePoints",
      "DrawTieredPrizePoints"
    ],
    "DrawWayToEarn": [
      "DrawWayToEarnRewardOffer",
      "DrawWayToEarnShareJobs"
    ],
    "GoalDetails": [
      "SurveyGoalDetails",
      "WatchVideoGoalDetails",
      "CompleteTrainingGoalDetails"
    ],
    "GoalTracker": [
      "JobShareGoalTracker",
      "SurveyGoalTracker",
      "EVVStreakGoalTracker",
      "WatchVideoGoalTracker",
      "HHAXLoginGoalTracker",
      "LastMinuteShiftGoalTracker",
      "CompleteTrainingGoalTracker",
      "WeekendShiftGoalTracker",
      "BillableHoursGoalTracker"
    ],
    "GoalUnlockCriteria": [
      "DependingGoalUnlockCriteria",
      "TimeBasedlUnlockCriteria"
    ],
    "HHAXLoginGoalTrackerLoginCompletionData": [
      "HHAXLoginGoalTrackerLoginCompletionDataV1"
    ],
    "InsightDateRanges": [
      "LaunchedInsightDateRanges",
      "NotLaunchedInsightDateRanges"
    ],
    "JobPostingApplicationField": [
      "JobPostingSystemRequiredField",
      "JobPostingSystemMaybeOptionalField",
      "JobPostingYesNoField"
    ],
    "JobPostingFieldInterface": [
      "JobPostingSystemMaybeOptionalField",
      "JobPostingSystemRequiredField",
      "JobPostingYesNoField"
    ],
    "MailingAddress": [
      "CAMailingAddress",
      "USMailingAddress"
    ],
    "MilestoneGoal": [
      "VisitMilestoneGoal",
      "HoursMilestoneGoal"
    ],
    "NumActiveCandidatesByJobPostingAndNextStepItemTotal": [
      "NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment",
      "NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention"
    ],
    "NumActiveCandidatesByJobPostingAndStatusItemTotal": [
      "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment",
      "NumActiveCandidatesByJobPostingAndStatusItemTotalRetention"
    ],
    "PointTransactionReason": [
      "PointTransactionAwardedAssignedAsCandidateReferrerReason",
      "PointTransactionAwardedCustomPointsAwardedReason",
      "PointTransactionAwardedDrawPrizeWinnerReason",
      "PointTransactionAwardedJobPostingSharedReason",
      "PointTransactionAwardedOptInReason",
      "PointTransactionAwardedRecognitionPointsReason",
      "PointTransactionAwardedReferralAppliedReason",
      "PointTransactionAwardedReferralCompletedFirstShiftReason",
      "PointTransactionAwardedReferralCompletedOrientationReason",
      "PointTransactionAwardedReferralHiredReason",
      "PointTransactionAwardedReferralPassedInterviewReason",
      "PointTransactionAwardedReferralReachedHourlyRetentionMilestoneReason",
      "PointTransactionAwardedReferralReachedRetentionMilestoneReason",
      "PointTransactionAwardedReferralStartedOrientationReason",
      "PointTransactionAwardedRewardOfferCompletedReason",
      "PointTransactionAwardedShiftMilestoneCompletedReason",
      "PointTransactionAwardedWelcomeOfferCompletedReason",
      "PointTransactionDeductedCustomPointsDeductedReason",
      "PointTransactionDeductedDuplicateReferralReason",
      "PointTransactionDeductedHourlyRetentionMilestoneReversedReason",
      "PointTransactionDeductedRecruitmentStepReversedReason",
      "PointTransactionDeductedRetentionMilestoneReversedReason",
      "PointTransactionDeductedUnassignedAsCandidateReferrerReason",
      "PointTransactionFraudulentCandidateArchivedReason",
      "PointTransactionGoalAchievedReason"
    ],
    "PowerHoursHourCondition": [
      "PowerHoursWeekendHoursCondition"
    ],
    "RecordedCandidateStep": [
      "RecordedCandidateRecruitmentStep",
      "RecordedCandidateRetentionStep"
    ],
    "RecordedCandidateStepV2": [
      "RecordedCandidateRecruitmentStep",
      "RecordedCandidateMonthlyRetentionStep",
      "RecordedCandidateHourlyRetentionStep"
    ],
    "ReferralHoursWorkedInsightMetric": [
      "EligibleReferralHoursWorkedInsightMetric",
      "IneligibleReferralHoursWorkedInsightMetric"
    ],
    "ReferralRewardStructureItem": [
      "ReferralRewardStructureJobShareItem",
      "ReferralRewardStructureReferralRecruitmentItem",
      "ReferralRewardStructureReferralRetentionItem"
    ],
    "ReferralRewardStructureReward": [
      "ReferralRewardStructurePointReward",
      "ReferralRewardStructureDrawTicketReward",
      "ReferralRewardStructureManualMonthlyRaffleReward"
    ],
    "ReferralRewardStructureStepItem": [
      "ReferralRewardStructureReferralRecruitmentItem",
      "ReferralRewardStructureReferralRetentionItem"
    ],
    "RewardOfferAction": [
      "RewardOfferModalAction",
      "RewardOfferInAppLinkAction",
      "RewardOfferExternalLinkAction"
    ],
    "RewardOfferStructure": [
      "RewardOfferStructureUnlimited",
      "RewardOfferStructureOneTime",
      "RewardOfferStructurePeriodic",
      "RewardOfferStructureDiscreteProgress",
      "RewardOfferStructurePunchCard"
    ],
    "RewardOfferStructureReward": [
      "RewardOfferNextReward",
      "RewardOfferStructureDiscreteProgressStep",
      "RewardOfferStructureOneTime",
      "RewardOfferStructurePeriodic",
      "RewardOfferStructurePunchCard",
      "RewardOfferStructureUnlimited"
    ],
    "RewardVideoFile": [
      "RewardVideoUrlFile",
      "RewardVideoManagedFile"
    ],
    "RewardsAdminPermissions": [
      "RewardsAdminFullAccessPermissions",
      "RewardsAdminRestrictedAccessPermissions"
    ],
    "RewardsGroupTagsConfig": [
      "RewardsGroupTagsConfigSchemaV1"
    ],
    "RewardsUserIdentity": [
      "RewardsAdminUser",
      "RewardsUser"
    ],
    "RewardsUserPersonalContactInfo": [
      "RewardsUserPersonalContactInfoData",
      "RewardsUserPersonalContactInfoSuppressed"
    ],
    "SocialPostDetails": [
      "CustomSocialPostDetails",
      "BirthdaySocialPostDetails",
      "WorkMilestoneSocialPostDetails",
      "RedemptionSocialPostDetails"
    ],
    "TransactionMetadata": [
      "TransactionMetadataUnknown",
      "TransactionMetadataReferralJobShare",
      "TransactionMetadataReferralRecruitmentStep",
      "TransactionMetadataReferralRetentionMilestone",
      "TransactionMetadataReferralAssignedAsReferringEmployee",
      "TransactionMetadataReferralCorrection",
      "TransactionMetadataRecognition",
      "TransactionMetadataEVVStreakGoal",
      "TransactionMetadataEVVPunchCard",
      "TransactionMetadataMobileAppAdoption",
      "TransactionMetadataMilestoneRewards",
      "TransactionMetadataSurvey",
      "TransactionMetadataWeekendShift",
      "TransactionMetadataLastMinuteShift",
      "TransactionMetadataWatchedVideo",
      "TransactionMetadataTrainingProgram",
      "TransactionMetadataBillableHoursIncrease",
      "TransactionMetadataRedemption",
      "TransactionMetadataRedemptionRejected",
      "TransactionMetadataDrawWinner"
    ],
    "WorkMilestoneSocialPostMilestone": [
      "WorkMilestoneSocialPostMilestoneVisit",
      "WorkMilestoneSocialPostMilestoneHours"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AdminBulkCreateRewardsUserResult": [
      "AdminBulkCreateRewardsUserError",
      "AdminBulkCreateRewardsUserSuccess"
    ],
    "AdminGoalRewards": [
      "AdminGoalRewardsGiftCard",
      "AdminGoalRewardsPayroll"
    ],
    "AdminGoalTarget": [
      "AdminGoalTargetPercentage",
      "AdminGoalTargetTimeToFirstShift"
    ],
    "AdminGoalTracker": [
      "BudgetUtilizationAdminGoalTracker",
      "LoginAdminGoalTracker",
      "TimeToFirstShiftAdminGoalTracker"
    ],
    "BaseDraw": [
      "CaribouSponsoredDraw",
      "Draw"
    ],
    "BaseDrawPrize": [
      "DrawPrizePoints",
      "DrawTieredPrizePoints"
    ],
    "BaseDrawPrizeStructure": [
      "DrawPrizeStructureSinglePrizeMultipleWinners",
      "DrawPrizeStructureTieredPrizesMultipleWinners"
    ],
    "CandidateNextStepFilterOption": [
      "CandidateRecruitmentStepFilterOption",
      "CandidateMonthlyRetentionStepFilterOption",
      "CandidateHourlyRetentionStepFilterOption"
    ],
    "CandidateStep": [
      "CandidateRecruitmentStep",
      "CandidateRetentionStep"
    ],
    "CandidateStepV2": [
      "CandidateRecruitmentStep",
      "CandidateMonthlyRetentionStep",
      "CandidateHourlyRetentionStep"
    ],
    "CategorizedTransactionMetadata": [
      "TransactionMetadataBillableHoursIncrease",
      "TransactionMetadataDrawWinner",
      "TransactionMetadataEVVPunchCard",
      "TransactionMetadataEVVStreakGoal",
      "TransactionMetadataLastMinuteShift",
      "TransactionMetadataMilestoneRewards",
      "TransactionMetadataMobileAppAdoption",
      "TransactionMetadataRecognition",
      "TransactionMetadataRedemption",
      "TransactionMetadataRedemptionRejected",
      "TransactionMetadataReferralAssignedAsReferringEmployee",
      "TransactionMetadataReferralCorrection",
      "TransactionMetadataReferralJobShare",
      "TransactionMetadataReferralRecruitmentStep",
      "TransactionMetadataReferralRetentionMilestone",
      "TransactionMetadataSurvey",
      "TransactionMetadataTrainingProgram",
      "TransactionMetadataUnknown",
      "TransactionMetadataWatchedVideo",
      "TransactionMetadataWeekendShift"
    ],
    "DrawPrize": [
      "DrawPrizePoints",
      "DrawTieredPrizePoints"
    ],
    "DrawWayToEarn": [
      "DrawWayToEarnRewardOffer",
      "DrawWayToEarnShareJobs"
    ],
    "GoalDetails": [
      "SurveyGoalDetails",
      "WatchVideoGoalDetails",
      "CompleteTrainingGoalDetails"
    ],
    "GoalTracker": [
      "JobShareGoalTracker",
      "SurveyGoalTracker",
      "EVVStreakGoalTracker",
      "WatchVideoGoalTracker",
      "HHAXLoginGoalTracker",
      "LastMinuteShiftGoalTracker",
      "CompleteTrainingGoalTracker",
      "WeekendShiftGoalTracker",
      "BillableHoursGoalTracker"
    ],
    "GoalUnlockCriteria": [
      "DependingGoalUnlockCriteria",
      "TimeBasedlUnlockCriteria"
    ],
    "HHAXLoginGoalTrackerLoginCompletionData": [
      "HHAXLoginGoalTrackerLoginCompletionDataV1"
    ],
    "InsightDateRanges": [
      "LaunchedInsightDateRanges",
      "NotLaunchedInsightDateRanges"
    ],
    "JobPostingApplicationField": [
      "JobPostingSystemRequiredField",
      "JobPostingSystemMaybeOptionalField",
      "JobPostingYesNoField"
    ],
    "JobPostingFieldInterface": [
      "JobPostingSystemMaybeOptionalField",
      "JobPostingSystemRequiredField",
      "JobPostingYesNoField"
    ],
    "MailingAddress": [
      "CAMailingAddress",
      "USMailingAddress"
    ],
    "MilestoneGoal": [
      "VisitMilestoneGoal",
      "HoursMilestoneGoal"
    ],
    "NumActiveCandidatesByJobPostingAndNextStepItemTotal": [
      "NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment",
      "NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention"
    ],
    "NumActiveCandidatesByJobPostingAndStatusItemTotal": [
      "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment",
      "NumActiveCandidatesByJobPostingAndStatusItemTotalRetention"
    ],
    "PointTransactionReason": [
      "PointTransactionAwardedAssignedAsCandidateReferrerReason",
      "PointTransactionAwardedCustomPointsAwardedReason",
      "PointTransactionAwardedDrawPrizeWinnerReason",
      "PointTransactionAwardedJobPostingSharedReason",
      "PointTransactionAwardedOptInReason",
      "PointTransactionAwardedRecognitionPointsReason",
      "PointTransactionAwardedReferralAppliedReason",
      "PointTransactionAwardedReferralCompletedFirstShiftReason",
      "PointTransactionAwardedReferralCompletedOrientationReason",
      "PointTransactionAwardedReferralHiredReason",
      "PointTransactionAwardedReferralPassedInterviewReason",
      "PointTransactionAwardedReferralReachedHourlyRetentionMilestoneReason",
      "PointTransactionAwardedReferralReachedRetentionMilestoneReason",
      "PointTransactionAwardedReferralStartedOrientationReason",
      "PointTransactionAwardedRewardOfferCompletedReason",
      "PointTransactionAwardedShiftMilestoneCompletedReason",
      "PointTransactionAwardedWelcomeOfferCompletedReason",
      "PointTransactionDeductedCustomPointsDeductedReason",
      "PointTransactionDeductedDuplicateReferralReason",
      "PointTransactionDeductedHourlyRetentionMilestoneReversedReason",
      "PointTransactionDeductedRecruitmentStepReversedReason",
      "PointTransactionDeductedRetentionMilestoneReversedReason",
      "PointTransactionDeductedUnassignedAsCandidateReferrerReason",
      "PointTransactionFraudulentCandidateArchivedReason",
      "PointTransactionGoalAchievedReason"
    ],
    "PowerHoursHourCondition": [
      "PowerHoursWeekendHoursCondition"
    ],
    "RecordedCandidateStep": [
      "RecordedCandidateRecruitmentStep",
      "RecordedCandidateRetentionStep"
    ],
    "RecordedCandidateStepV2": [
      "RecordedCandidateRecruitmentStep",
      "RecordedCandidateMonthlyRetentionStep",
      "RecordedCandidateHourlyRetentionStep"
    ],
    "ReferralHoursWorkedInsightMetric": [
      "EligibleReferralHoursWorkedInsightMetric",
      "IneligibleReferralHoursWorkedInsightMetric"
    ],
    "ReferralRewardStructureItem": [
      "ReferralRewardStructureJobShareItem",
      "ReferralRewardStructureReferralRecruitmentItem",
      "ReferralRewardStructureReferralRetentionItem"
    ],
    "ReferralRewardStructureReward": [
      "ReferralRewardStructurePointReward",
      "ReferralRewardStructureDrawTicketReward",
      "ReferralRewardStructureManualMonthlyRaffleReward"
    ],
    "ReferralRewardStructureStepItem": [
      "ReferralRewardStructureReferralRecruitmentItem",
      "ReferralRewardStructureReferralRetentionItem"
    ],
    "RewardOfferAction": [
      "RewardOfferModalAction",
      "RewardOfferInAppLinkAction",
      "RewardOfferExternalLinkAction"
    ],
    "RewardOfferStructure": [
      "RewardOfferStructureUnlimited",
      "RewardOfferStructureOneTime",
      "RewardOfferStructurePeriodic",
      "RewardOfferStructureDiscreteProgress",
      "RewardOfferStructurePunchCard"
    ],
    "RewardOfferStructureReward": [
      "RewardOfferNextReward",
      "RewardOfferStructureDiscreteProgressStep",
      "RewardOfferStructureOneTime",
      "RewardOfferStructurePeriodic",
      "RewardOfferStructurePunchCard",
      "RewardOfferStructureUnlimited"
    ],
    "RewardVideoFile": [
      "RewardVideoUrlFile",
      "RewardVideoManagedFile"
    ],
    "RewardsAdminPermissions": [
      "RewardsAdminFullAccessPermissions",
      "RewardsAdminRestrictedAccessPermissions"
    ],
    "RewardsGroupTagsConfig": [
      "RewardsGroupTagsConfigSchemaV1"
    ],
    "RewardsUserIdentity": [
      "RewardsAdminUser",
      "RewardsUser"
    ],
    "RewardsUserPersonalContactInfo": [
      "RewardsUserPersonalContactInfoData",
      "RewardsUserPersonalContactInfoSuppressed"
    ],
    "SocialPostDetails": [
      "CustomSocialPostDetails",
      "BirthdaySocialPostDetails",
      "WorkMilestoneSocialPostDetails",
      "RedemptionSocialPostDetails"
    ],
    "TransactionMetadata": [
      "TransactionMetadataUnknown",
      "TransactionMetadataReferralJobShare",
      "TransactionMetadataReferralRecruitmentStep",
      "TransactionMetadataReferralRetentionMilestone",
      "TransactionMetadataReferralAssignedAsReferringEmployee",
      "TransactionMetadataReferralCorrection",
      "TransactionMetadataRecognition",
      "TransactionMetadataEVVStreakGoal",
      "TransactionMetadataEVVPunchCard",
      "TransactionMetadataMobileAppAdoption",
      "TransactionMetadataMilestoneRewards",
      "TransactionMetadataSurvey",
      "TransactionMetadataWeekendShift",
      "TransactionMetadataLastMinuteShift",
      "TransactionMetadataWatchedVideo",
      "TransactionMetadataTrainingProgram",
      "TransactionMetadataBillableHoursIncrease",
      "TransactionMetadataRedemption",
      "TransactionMetadataRedemptionRejected",
      "TransactionMetadataDrawWinner"
    ],
    "WorkMilestoneSocialPostMilestone": [
      "WorkMilestoneSocialPostMilestoneVisit",
      "WorkMilestoneSocialPostMilestoneHours"
    ]
  }
};
      export default result;
    