/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import {
  Control,
  FieldValues,
  get,
  Path,
  useFormState,
  useWatch,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";

interface ReferralRewardPointValueFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  pointsPerDollar: number | undefined;
  min: number;
  max: number;
  showExplanationTooltip: boolean;
  hideSpaceForErrorText?: boolean;
  size?: "small" | "medium";
  readOnly?: boolean;
  helperText?: ReactNode;
}

export function ReferralRewardPointValueField<T extends FieldValues>({
  control,
  name,
  pointsPerDollar,
  min,
  max,
  showExplanationTooltip,
  hideSpaceForErrorText,
  readOnly,
  size = "small",
  helperText,
}: ReferralRewardPointValueFieldProps<T>) {
  const formState = useFormState({ control });
  const value = useWatch({ control, name });

  const theme = useTheme();
  return (
    <TextField
      hideSpaceForErrorText={hideSpaceForErrorText}
      css={css`
        width: 290px;
        margin-bottom: auto;
      `}
      {...control.register(name, {
        required: "This field is required",
        validate: (value) => {
          if (value) {
            const valueConvertedToNumber = extractNumber(value);
            if (valueConvertedToNumber < min || valueConvertedToNumber > max) {
              return `Value should be in the range of ${min} and ${max} points`;
            }
          }
        },
      })}
      label="Point value"
      hideLabel
      placeholder="Enter point value"
      helperText={helperText}
      startAdornment={
        showExplanationTooltip && (
          <Tooltip
            title={`The minimum number of points is ${min}, with a max limit of ${max}`}
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              color={theme.palette.grey[600]}
              width={18}
            />
          </Tooltip>
        )
      }
      endAdornment={
        typeof pointsPerDollar === "number" && (
          <Typography variant="body" fontWeight={600} color="textPrimary">
            {formatDollars(extractNumber(value) / pointsPerDollar)}
          </Typography>
        )
      }
      error={get(formState.errors, name)}
      size={size}
      readOnly={readOnly}
      greyReadOnly
    />
  );
}
