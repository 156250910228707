/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { InsightMetric } from "../../../../../shared/components/insight-metric";
import { NavigationTabs } from "../../../../../shared/components/navigation-tabs";
import { Pagination } from "../../../../../shared/components/pagination";
import dollarCoinImageUrl from "./dollar-coin.png";
import drawTicketImageUrl from "./draw-ticket.png";
import { useEmployeeDetailAwardedTicketTransactionsQuery } from "./employee-detail-awarded-ticket-transactions.generated";
import { useEmployeeDetailPointTransactionsQuery } from "./employee-detail-point-transactions.generated";
import { EmployeeDetailPointsTransactionsTable } from "./employee-detail-points-transactions-table";
import { useEmployeeDetailRewardsCardDataQuery } from "./employee-detail-rewards-card-data.generated";
import { EmployeeDetailTicketsTransactionsTable } from "./employee-detail-tickets-transactions-table";

const ITEMS_PER_PAGE = 6;

type EmployeeDetailPageTransactionTableTab = "points" | "tickets";

export interface EmployeeDetailRewardsCardProps {
  userId: string;
  initialSelectedTab?: EmployeeDetailPageTransactionTableTab;
  userHasLoaded: boolean;
}

export function EmployeeDetailRewardsCard({
  userId,
  initialSelectedTab = "points",
  userHasLoaded,
}: EmployeeDetailRewardsCardProps) {
  const track = useTrack();
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<EmployeeDetailPageTransactionTableTab>(initialSelectedTab);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const rewardsCardDataQuery = useEmployeeDetailRewardsCardDataQuery({
    variables: { userId },
    onError: reportError,
    skip: !userHasLoaded,
  });

  const drawsEnabled =
    rewardsCardDataQuery.data?.getMyRewardsOrganization.drawsEnabled;

  const pointTransactionQuery = useEmployeeDetailPointTransactionsQuery({
    skip: !userHasLoaded || selectedTab !== "points",
    variables: {
      userId,
      limit: ITEMS_PER_PAGE,
      offset: currentPageIndex * ITEMS_PER_PAGE,
    },
    onError: reportError,
  });
  const pointTransactionsData =
    pointTransactionQuery.data ?? pointTransactionQuery.previousData;

  const ticketTransactionsQuery = useEmployeeDetailAwardedTicketTransactionsQuery(
    {
      skip: !userHasLoaded || selectedTab !== "tickets" || !drawsEnabled,
      variables: {
        userId,
        limit: ITEMS_PER_PAGE,
        offset: currentPageIndex * ITEMS_PER_PAGE,
      },
      onError: reportError,
    }
  );
  const ticketTransactionsData =
    ticketTransactionsQuery.data ?? ticketTransactionsQuery.previousData;

  const total = ((): number | undefined => {
    switch (selectedTab) {
      case "points":
        return pointTransactionsData?.transactions.total;
      case "tickets":
        return ticketTransactionsData?.transactions.total;
      default:
        return assertNever(selectedTab);
    }
  })();

  if (
    rewardsCardDataQuery.data &&
    !rewardsCardDataQuery.data.getRewardsUserById
  ) {
    // don't even displaty the card if the user doesn't exist
    return null;
  }

  const tableContent = (() => {
    if (
      rewardsCardDataQuery.error ||
      pointTransactionQuery.error ||
      ticketTransactionsQuery.error
    ) {
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
      );
    }

    return (
      <>
        <div
          css={(theme: AppTheme) => css`
            border: 1px solid ${theme.palette.grey[400]};
            border-radius: 10px;
            padding-top: ${theme.spacing(1)};
          `}
        >
          <NavigationTabs
            ariaLabel="Rewards card navigation"
            value={selectedTab}
            onChange={(value) => {
              // change back to first page when changing tabs
              setCurrentPageIndex(0);
              setSelectedTab(value);

              track("Clicked employee detail rewards card tab", {
                employeeId: userId,
                clickedTab: value,
              });
            }}
            tabs={[
              { label: "Points", value: "points", minWidth: "154px" },
              {
                label: "Tickets",
                value: "tickets",
                minWidth: "154px",
              },
            ]}
            disableBottomMargin
          />

          <div
            css={(theme: AppTheme) => css`
              padding: 0 ${theme.spacing(2)};
            `}
          >
            {selectedTab === "points" && (
              <EmployeeDetailPointsTransactionsTable
                items={pointTransactionsData?.transactions.items}
                total={pointTransactionsData?.transactions.total}
                itemsPerPage={ITEMS_PER_PAGE}
                loading={pointTransactionQuery.loading}
                rewardsProgramShortName={
                  rewardsCardDataQuery.data?.getMyRewardsOrganization
                    .whiteLabelConfig?.rewardsProgramShortName
                }
                launched={
                  rewardsCardDataQuery.data?.getMyRewardsOrganization.launched
                }
              />
            )}
            {selectedTab === "tickets" && (
              <EmployeeDetailTicketsTransactionsTable
                items={ticketTransactionsData?.transactions.items}
                total={ticketTransactionsData?.transactions.total}
                itemsPerPage={ITEMS_PER_PAGE}
                loading={ticketTransactionsQuery.loading}
                drawsEnabled={drawsEnabled}
                rewardsProgramShortName={
                  rewardsCardDataQuery.data?.getMyRewardsOrganization
                    .whiteLabelConfig?.rewardsProgramShortName
                }
                launched={
                  rewardsCardDataQuery.data?.getMyRewardsOrganization.launched
                }
              />
            )}
          </div>
        </div>

        {typeof total === "number" && total > 0 && (
          <Pagination
            disableMargin
            total={total}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPageIndex={currentPageIndex}
            onChange={(_, page) => {
              track("Updated employee detail rewards card pagination", {
                employeeId: userId,
                pageIndex: page,
              });

              setCurrentPageIndex(page - 1);
            }}
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2.5)};
            `}
          />
        )}
      </>
    );
  })();

  return (
    <Card>
      <CardContent
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
        `}
      >
        <Typography
          component="h2"
          variant="h5"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          Rewards
        </Typography>

        <div
          css={(theme: AppTheme) =>
            css`
              display: flex;
              gap: ${theme.spacing(2)};
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
              margin-bottom: ${theme.spacing(2.5)};
            `
          }
        >
          <InsightMetric
            labelStyle="grey"
            label="Current points balance"
            valueVariant="h5"
            topRightPillText={
              typeof rewardsCardDataQuery.data?.getRewardsUserById
                ?.pointsRedeemed === "number"
                ? `Total redeemed: ${numberWithCommas(
                    rewardsCardDataQuery.data.getRewardsUserById.pointsRedeemed
                  )}`
                : ""
            }
            icon={
              <img
                src={dollarCoinImageUrl}
                alt="Dollar coin"
                css={css`
                  width: 18px;
                  margin-top: 4px;
                `}
              />
            }
            value={
              typeof rewardsCardDataQuery.data?.getRewardsUserById
                ?.pointsAvailableToRedeem === "number"
                ? {
                    type: "number",
                    number:
                      rewardsCardDataQuery.data.getRewardsUserById
                        .pointsAvailableToRedeem,
                  }
                : {
                    type: "loading",
                  }
            }
          />
          <InsightMetric
            labelStyle="grey"
            label="Ticket balance for current draw"
            valueVariant="h5"
            icon={
              <img
                src={drawTicketImageUrl}
                alt="Draw ticket"
                css={css`
                  width: 18px;
                  margin-top: 4px;
                `}
              />
            }
            value={(() => {
              if (!rewardsCardDataQuery.data?.getRewardsUserById) {
                return { type: "loading" };
              }

              const currentDrawTicketCount =
                rewardsCardDataQuery.data.getRewardsUserById
                  .currentDrawTicketCount ?? 0;

              return { type: "number", number: currentDrawTicketCount };
            })()}
          />
        </div>

        {tableContent}
      </CardContent>
    </Card>
  );
}
