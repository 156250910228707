/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface MarkAsGiftCardSentModalProps {
  open: boolean;
  loading: boolean;
  numRedemptions: number;
  onClose(): void;
  onConfirm(): void;
}

export function MarkAsGiftCardSentModal({
  open,
  loading,
  numRedemptions,
  onClose,
  onConfirm,
}: MarkAsGiftCardSentModalProps): JSX.Element {
  return (
    <Modal width="500px" open={open} onClose={onClose}>
      <ModalTitle>Mark as "Gift card sent"?</ModalTitle>
      <ModalContent>
        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          This will change the status of {numRedemptions} redemption request(s)
          from "requested" to "Gift card sent".
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          whiteBackground
          variant="outlined"
          onClick={onClose}
          disabled={loading}
          label="Cancel"
        />
        <Button
          type="submit"
          color="primary"
          label="Yes"
          loading={loading}
          onClick={onConfirm}
        />
      </ModalActions>
    </Modal>
  );
}
