import { ApolloError } from "@apollo/client";

import { ErrorCode } from "../graphql-types";

/**
 * Returns whether a given error is an apollo error containing the given error code.
 */
export function hasGraphqlError(error: unknown, errorCode: ErrorCode) {
  if (!(error instanceof ApolloError)) {
    return false;
  }
  return error.graphQLErrors.some(
    (error) => error.extensions?.errorCode === errorCode
  );
}
