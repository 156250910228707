import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateJobPostingMutationVariables = Types.Exact<{
  jobPostingId: Types.Scalars['ID'];
  referralStructureId?: Types.Maybe<Types.Scalars['ID']>;
  branchId?: Types.Maybe<Types.Scalars['ID']>;
  title: Types.Scalars['String'];
  hoursRequired: Types.Scalars['String'];
  ratesOfPay?: Types.Maybe<Types.Scalars['String']>;
  geography: Types.Scalars['String'];
  descriptionHTML: Types.Scalars['String'];
  screenerQuestions?: Types.Maybe<Array<Types.JobPostingScreenerQuestionInput> | Types.JobPostingScreenerQuestionInput>;
  candidateAlertEmail?: Types.Maybe<Types.Scalars['String']>;
  customizedApplicantEmailMessage?: Types.Maybe<Types.Scalars['String']>;
  customizedApplicantSMSMessage?: Types.Maybe<Types.Scalars['String']>;
  interviewBookingLink?: Types.Maybe<Types.InterviewBookingLink>;
}>;


export type UpdateJobPostingMutation = (
  { __typename?: 'Mutation' }
  & { updateJobPosting: (
    { __typename?: 'JobPosting' }
    & Pick<Types.JobPosting, 'id'>
  ) }
);


export const UpdateJobPostingDocument = gql`
    mutation UpdateJobPosting($jobPostingId: ID!, $referralStructureId: ID, $branchId: ID, $title: String!, $hoursRequired: String!, $ratesOfPay: String, $geography: String!, $descriptionHTML: String!, $screenerQuestions: [JobPostingScreenerQuestionInput!], $candidateAlertEmail: String, $customizedApplicantEmailMessage: String, $customizedApplicantSMSMessage: String, $interviewBookingLink: InterviewBookingLink) {
  updateJobPosting(
    title: $title
    jobPostingId: $jobPostingId
    branchId: $branchId
    referralStructureId: $referralStructureId
    hoursRequired: $hoursRequired
    ratesOfPay: $ratesOfPay
    geography: $geography
    descriptionHTML: $descriptionHTML
    screenerQuestions: $screenerQuestions
    candidateAlertEmail: $candidateAlertEmail
    customizedApplicantEmailMessage: $customizedApplicantEmailMessage
    customizedApplicantSMSMessage: $customizedApplicantSMSMessage
    interviewBookingLink: $interviewBookingLink
  ) {
    id
  }
}
    `;
export type UpdateJobPostingMutationFn = Apollo.MutationFunction<UpdateJobPostingMutation, UpdateJobPostingMutationVariables>;

/**
 * __useUpdateJobPostingMutation__
 *
 * To run a mutation, you first call `useUpdateJobPostingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobPostingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobPostingMutation, { data, loading, error }] = useUpdateJobPostingMutation({
 *   variables: {
 *      jobPostingId: // value for 'jobPostingId'
 *      referralStructureId: // value for 'referralStructureId'
 *      branchId: // value for 'branchId'
 *      title: // value for 'title'
 *      hoursRequired: // value for 'hoursRequired'
 *      ratesOfPay: // value for 'ratesOfPay'
 *      geography: // value for 'geography'
 *      descriptionHTML: // value for 'descriptionHTML'
 *      screenerQuestions: // value for 'screenerQuestions'
 *      candidateAlertEmail: // value for 'candidateAlertEmail'
 *      customizedApplicantEmailMessage: // value for 'customizedApplicantEmailMessage'
 *      customizedApplicantSMSMessage: // value for 'customizedApplicantSMSMessage'
 *      interviewBookingLink: // value for 'interviewBookingLink'
 *   },
 * });
 */
export function useUpdateJobPostingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobPostingMutation, UpdateJobPostingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobPostingMutation, UpdateJobPostingMutationVariables>(UpdateJobPostingDocument, options);
      }
export type UpdateJobPostingMutationHookResult = ReturnType<typeof useUpdateJobPostingMutation>;
export type UpdateJobPostingMutationResult = Apollo.MutationResult<UpdateJobPostingMutation>;
export type UpdateJobPostingMutationOptions = Apollo.BaseMutationOptions<UpdateJobPostingMutation, UpdateJobPostingMutationVariables>;