import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type JobPostingEditDetailsFragment = (
  { __typename?: 'JobPosting' }
  & Pick<Types.JobPosting, 'id' | 'importedFromIntegration' | 'publishedAt' | 'title' | 'hoursRequired' | 'ratesOfPay' | 'geography' | 'descriptionHTML' | 'candidateAlertEmail' | 'customizedApplicantEmailMessage' | 'customizedApplicantSMSMessage' | 'interviewBookingLink' | 'mustUploadResumeBeforeBookingInterview'>
  & { referralStructure: (
    { __typename?: 'ReferralStructure' }
    & Pick<Types.ReferralStructure, 'id'>
  ), branch?: Types.Maybe<(
    { __typename?: 'RewardsOrganizationBranch' }
    & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
  )>, screenerQuestions: Array<(
    { __typename?: 'JobPostingScreenerQuestion' }
    & Pick<Types.JobPostingScreenerQuestion, 'id' | 'question' | 'autoRejectResponses'>
  )> }
);

export const JobPostingEditDetailsFragmentDoc = gql`
    fragment JobPostingEditDetails on JobPosting {
  id
  importedFromIntegration
  publishedAt
  referralStructure {
    id
  }
  branch {
    id
    name
  }
  title
  hoursRequired
  ratesOfPay
  geography
  descriptionHTML
  screenerQuestions {
    id
    question
    autoRejectResponses
  }
  candidateAlertEmail
  customizedApplicantEmailMessage
  customizedApplicantSMSMessage
  interviewBookingLink
  mustUploadResumeBeforeBookingInterview
}
    `;