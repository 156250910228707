import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RecognizedEmployeesInsightMetric as RecognizedEmployeesInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../../../../shared/components/insight-metric";
import { getDifferenceOfPercentages } from "../../../../utils";

export interface RecognizedEmployeesInsightMetricProps {
  currentRecognizedEmployeesInsightMetric:
    | RecognizedEmployeesInsightMetricType
    | undefined;
  previousRecognizedEmployeesInsightMetric:
    | RecognizedEmployeesInsightMetricType
    | undefined;
}

const getRecognizedEmployeesFraction = ({
  recognizedEmployeeCount,
  activeUserCount,
}: RecognizedEmployeesInsightMetricType) => ({
  numerator: Math.min(recognizedEmployeeCount, activeUserCount), // capped at 100%
  denominator: activeUserCount,
});

export function RecognizedEmployeesInsightMetric({
  currentRecognizedEmployeesInsightMetric,
  previousRecognizedEmployeesInsightMetric,
}: RecognizedEmployeesInsightMetricProps) {
  const percentPointChange = (() => {
    if (
      currentRecognizedEmployeesInsightMetric === undefined ||
      previousRecognizedEmployeesInsightMetric === undefined
    ) {
      return null;
    }
    return getDifferenceOfPercentages({
      prev: getRecognizedEmployeesFraction(
        previousRecognizedEmployeesInsightMetric
      ),
      curr: getRecognizedEmployeesFraction(
        currentRecognizedEmployeesInsightMetric
      ),
    });
  })();

  return (
    <InsightMetric
      label="Recognized employees"
      icon={<FontAwesomeIcon icon={faUsers} />}
      tooltipText="Percentage of employees who received recognition."
      value={
        currentRecognizedEmployeesInsightMetric !== undefined
          ? {
              type: "percent",
              ...getRecognizedEmployeesFraction(
                currentRecognizedEmployeesInsightMetric
              ),
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
