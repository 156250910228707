import {
  ReferralRewardStructureDrawTicketReward,
  ReferralRewardStructureJobShareItem,
  ReferralRewardStructurePointReward,
  ReferralRewardStructureReferralRecruitmentItem,
  ReferralRewardStructureReferralRetentionItemDuration,
} from "@rewards-web/shared/graphql-types";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";

import {
  ReferralStructureFormRecruitmentStep,
  ReferralStructureFormRetentionMilestone,
  ReferralStructureFormStep,
  ReferralStructureFormValues,
} from "./types";

export function referralStructureFormValuesToMutationVariables(
  values: ReferralStructureFormValues
) {
  return {
    name: values.name,
    structure: {
      recruitmentSteps: values.steps
        .filter(
          (step): step is ReferralStructureFormRecruitmentStep =>
            step.type === "recruitment"
        )
        .map((step) => ({
          step: step.step,
          pointValue: extractNumber(step.pointValue),
        })),
      retentionMilestones: values.steps
        .filter(
          (step): step is ReferralStructureFormRetentionMilestone =>
            step.type === "retention"
        )
        .map((step) => ({
          durationMonths: step.unit === "months" ? step.amount : undefined,
          durationHours: step.unit === "hours" ? step.amount : undefined,
          pointValue: extractNumber(step.pointValue),
        })),
    },
  };
}

export function referralStructureFromApiToFormValue(
  items: Array<
    | {
        __typename?: "ReferralRewardStructureJobShareItem";
      }
    | ({
        __typename?: "ReferralRewardStructureReferralRecruitmentItem";
      } & Pick<ReferralRewardStructureReferralRecruitmentItem, "step"> & {
          reward:
            | ({
                __typename?: "ReferralRewardStructurePointReward";
              } & Pick<ReferralRewardStructurePointReward, "pointValue">)
            | {
                __typename?: "ReferralRewardStructureDrawTicketReward";
              }
            | {
                __typename?: "ReferralRewardStructureManualMonthlyRaffleReward";
              };
        })
    | ({
        __typename?: "ReferralRewardStructureReferralRetentionItem";
      } & {
        duration: {
          __typename?: "ReferralRewardStructureReferralRetentionItemDuration";
        } & Pick<
          ReferralRewardStructureReferralRetentionItemDuration,
          "unit" | "amount"
        >;
        reward:
          | ({
              __typename?: "ReferralRewardStructurePointReward";
            } & Pick<ReferralRewardStructurePointReward, "pointValue">)
          | {
              __typename?: "ReferralRewardStructureDrawTicketReward";
            }
          | {
              __typename?: "ReferralRewardStructureManualMonthlyRaffleReward";
            };
      })
  >
) {
  return items.map(
    (item): ReferralStructureFormStep => {
      switch (item.__typename) {
        case "ReferralRewardStructureReferralRecruitmentItem":
          return {
            type: "recruitment",
            step: item.step,
            pointValue:
              item.reward.__typename === "ReferralRewardStructurePointReward"
                ? String(item.reward.pointValue)
                : // shouldn't happen
                  "",
          };
        case "ReferralRewardStructureReferralRetentionItem":
          return {
            type: "retention",
            unit: (() => {
              switch (item.duration.unit) {
                case "HOURS":
                  return "hours" as const;
                case "MONTHS":
                  return "months" as const;
                default:
                  throw new Error(
                    `Unexpected retention unit: ${item.duration.unit}`
                  );
              }
            })(),
            amount: item.duration.amount,
            // shouldn't happen
            pointValue:
              item.reward.__typename === "ReferralRewardStructurePointReward"
                ? String(item.reward.pointValue)
                : // shouldn't happen
                  "",
          };
        default:
          throw new Error(
            `Unexpected referral structure item type: ${item.__typename}`
          );
      }
    }
  );
}

export function jobShareStructureFromApiToFormValue(
  jobShareStructure: {
    __typename?: "ReferralRewardStructureJobShareItem";
  } & Pick<
    ReferralRewardStructureJobShareItem,
    "maxAwardableJobSharesPerMonth"
  > & {
      reward:
        | ({ __typename?: "ReferralRewardStructurePointReward" } & Pick<
            ReferralRewardStructurePointReward,
            "pointValue"
          >)
        | ({ __typename?: "ReferralRewardStructureDrawTicketReward" } & Pick<
            ReferralRewardStructureDrawTicketReward,
            "numTickets"
          >)
        | { __typename?: "ReferralRewardStructureManualMonthlyRaffleReward" };
    }
): string {
  switch (jobShareStructure.reward.__typename) {
    case "ReferralRewardStructurePointReward":
      return String(jobShareStructure.reward.pointValue);
    case "ReferralRewardStructureDrawTicketReward":
      return String(jobShareStructure.reward.numTickets);
    default:
      return "";
  }
}
