import Add from "@material-ui/icons/Add";
import { useState } from "react";
import { useNavigate } from "react-router";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { reportError } from "@rewards-web/shared/modules/error";

import { AddNewJobModal } from "../../../../../../shared/modules/jobs/list/components/add-new-job-modal";
import { AdminJobsDataTable } from "../../../../../../shared/modules/jobs/list/components/jobs-data-table";
import { useAdminJobsTableQuery } from "../../../../../../shared/modules/jobs/list/hooks/use-jobs-table-query";
import { AdminOnboardingCard } from "../../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../../components/step-actions";
import { useNextOnboardingStepPath } from "../../../hooks/use-next-step-path";
import { useOnboardingListJobsDataQuery } from "./onboarding-list-jobs-data.generated";

const ITEMS_PER_PAGE = 10;

export function OnboardingListJobs(): JSX.Element {
  const navigate = useNavigate();
  const nextOnboardingStepPath = useNextOnboardingStepPath();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [addNewJobModalOpen, setAddNewJobModalOpen] = useState(false);
  const [editingJobId, setEditingJobId] = useState<string | undefined>(
    undefined
  );

  const { items, total, loading, refetch } = useAdminJobsTableQuery({
    currentPageIndex,
    perPage: ITEMS_PER_PAGE,
  });
  const query = useOnboardingListJobsDataQuery({
    onError: reportError,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleAddJobPosting = () => {
    query.refetch(); // refetch num open jobs
  };

  return (
    <>
      <AdminOnboardingCard>
        <AdminOnboardingCardHeader
          title="💼️ Create job postings"
          action={
            <Button
              size="small"
              width="auto"
              color="primary"
              variant="outlined"
              startIcon={<Add />}
              label="Add Another Job"
              onClick={() => setAddNewJobModalOpen(true)}
            />
          }
        />

        <AdminOnboardingCardContent>
          <AdminJobsDataTable
            currentPageIndex={currentPageIndex}
            setCurrentPageIndex={setCurrentPageIndex}
            perPage={ITEMS_PER_PAGE}
            loading={loading}
            items={items}
            total={total}
            hideStats
            refreshList={refetch}
            editingJobId={editingJobId}
            onCloseEditJob={() => setEditingJobId(undefined)}
            onOpenEditJob={setEditingJobId}
            disableBorderRadius
            duplicateJobConfirmationDescription="You'll be directed to a duplicate of the job posting you selected. You can edit this duplicate before adding it."
            duplicateJobSubmitButtonLabel="Add Job"
            duplicateJobSuccessSnackbarMessage="The job posting was added"
            editJobDescription="Press “Save” to update the job posting."
            getCloseJobConfirmationDescription={(jobPostingTitle) =>
              `The job posting "${jobPostingTitle}" will not be shareable by employees.`
            }
            onAddJobPosting={() => {
              handleAddJobPosting();
            }}
            pointsPerDollar={
              query.data?.getMyRewardsOrganization.pointsPerDollar
            }
          />

          <AddNewJobModal
            open={addNewJobModalOpen}
            onClose={() => {
              setAddNewJobModalOpen(false);
            }}
            onAdded={() => {
              refetch();
              handleAddJobPosting();
            }}
            duplicatingJobPostingId={null}
            submitButtonLabel="Add Job"
            successSnackbarMessage="The job posting was added"
          />
        </AdminOnboardingCardContent>
      </AdminOnboardingCard>
      <Form
        onSubmit={() => {
          if (nextOnboardingStepPath) {
            navigate(nextOnboardingStepPath);
          }
        }}
      >
        <AdminOnboardingStepActions submitButtonLabel="Continue" disableSkip />
      </Form>
    </>
  );
}
