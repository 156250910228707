import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SurveySettingsDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SurveySettingsDataQuery = (
  { __typename?: 'Query' }
  & { getMyOrganizationGoalConfig?: Types.Maybe<(
    { __typename?: 'OrganizationGoalConfig' }
    & Pick<Types.OrganizationGoalConfig, 'id' | 'enabled'>
    & { pulseCheckSurveyGoalDistributionConfig?: Types.Maybe<(
      { __typename?: 'PulseCheckSurveyGoalDistributionConfig' }
      & Pick<Types.PulseCheckSurveyGoalDistributionConfig, 'id' | 'enabled'>
    )>, quickCheckSurveyGoalDistributionConfig?: Types.Maybe<(
      { __typename?: 'QuickCheckSurveyGoalDistributionConfig' }
      & Pick<Types.QuickCheckSurveyGoalDistributionConfig, 'id' | 'enabled'>
    )> }
  )> }
);


export const SurveySettingsDataDocument = gql`
    query SurveySettingsData {
  getMyOrganizationGoalConfig {
    id
    enabled
    pulseCheckSurveyGoalDistributionConfig {
      id
      enabled
    }
    quickCheckSurveyGoalDistributionConfig {
      id
      enabled
    }
  }
}
    `;

/**
 * __useSurveySettingsDataQuery__
 *
 * To run a query within a React component, call `useSurveySettingsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveySettingsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveySettingsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSurveySettingsDataQuery(baseOptions?: Apollo.QueryHookOptions<SurveySettingsDataQuery, SurveySettingsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveySettingsDataQuery, SurveySettingsDataQueryVariables>(SurveySettingsDataDocument, options);
      }
export function useSurveySettingsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveySettingsDataQuery, SurveySettingsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveySettingsDataQuery, SurveySettingsDataQueryVariables>(SurveySettingsDataDocument, options);
        }
export type SurveySettingsDataQueryHookResult = ReturnType<typeof useSurveySettingsDataQuery>;
export type SurveySettingsDataLazyQueryHookResult = ReturnType<typeof useSurveySettingsDataLazyQuery>;
export type SurveySettingsDataQueryResult = Apollo.QueryResult<SurveySettingsDataQuery, SurveySettingsDataQueryVariables>;