export function formatPhoneNumber(phoneNumber: string) {
  const cleaned = ("" + phoneNumber).replace(/[^*\d]/g, "");
  const match = cleaned.match(/^(1|)?([\d*]{3})([\d*]{3})([\d*]{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumber;
}

export function formatPhoneNumberSimplified(phoneNumber: string) {
  const cleaned = ("" + phoneNumber).replace(/[^*\d]/g, "");
  const match = cleaned.match(/^(1|)?([\d*]{3})([\d*]{3})([\d*]{4})$/);

  if (match) {
    return [match[2], "-", match[3], "-", match[4]].join("");
  }
  return phoneNumber;
}
