/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { isEmpty } from "lodash";
import { useState } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  SurveyCommentsFiltersInput,
  SurveyInsightsDateRangeInput,
} from "@rewards-web/shared/graphql-types";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DataTable } from "../../../../../../shared/components/data-table";
import { QuestionCommentText } from "../../../components/question-comment-text";
import { ScoreText } from "../../../components/score-text";
import { SurveyQuestionTypeSupportedByUI } from "../../../constants";
import {
  EmployeeFeedbackTableDataQueryVariables,
  useEmployeeFeedbackTableDataQuery,
} from "./employee-feedback-table-data.generated";
import { DEFAULT_VALUE, ScoreSelectField } from "./score-select-field";

export interface EmployeeFeedbackTableProps {
  questionType: SurveyQuestionTypeSupportedByUI;
  dateRangeInput?: SurveyInsightsDateRangeInput;
}

const ITEMS_PER_PAGE = 10;

export function EmployeeFeedbackTable({
  questionType,
  dateRangeInput,
}: EmployeeFeedbackTableProps) {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [selectedScore, setSelectedScore] = useState<string>("all");

  const variables = (() => {
    const variables: EmployeeFeedbackTableDataQueryVariables = {
      questionType,
      limit: ITEMS_PER_PAGE,
      offset: currentPageIndex * ITEMS_PER_PAGE,
    };

    const filtersInput: SurveyCommentsFiltersInput = {};

    if (selectedScore !== DEFAULT_VALUE) {
      filtersInput.score = Number(selectedScore);
    }

    if (dateRangeInput) {
      filtersInput.dateRange = dateRangeInput;
    }

    if (!isEmpty(filtersInput)) {
      variables.filter = filtersInput;
    }

    return variables;
  })();

  const { data, error, loading } = useEmployeeFeedbackTableDataQuery({
    variables,
    onError: reportError,
  });

  const anonymousSubmissionEnabled = useFeatureFlag(
    "admin-app-anonymous-survey-submission-temp"
  );

  if (error) {
    return null;
  }

  const total = data?.listCommentsForSurveyQuestion.total;
  const paginatedItems = data?.listCommentsForSurveyQuestion.items;

  const phoneNumberToDisplay = (phoneNumber?: string | null) => {
    if (!phoneNumber) {
      return "-";
    }
    return formatPhoneNumber(phoneNumber);
  };
  return (
    <Card
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(3)};
          margin-top: ${theme.spacing(3)};
        `
      }
    >
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        <Typography variant="subtitle" fontWeight={700}>
          Follow up question
        </Typography>
        <ScoreSelectField
          value={selectedScore}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setSelectedScore(e.target.value);
            setCurrentPageIndex(0);
          }}
        />
      </div>
      <QuestionCommentText questionType={questionType} variant="h3" />
      <DataTable
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={setCurrentPageIndex}
        items={paginatedItems}
        total={total}
        itemsPerPage={ITEMS_PER_PAGE}
        loading={loading}
        paddingDisabled={true}
        emptyText="No feedback available."
        tableHeaders={
          <TableRow>
            <TableHeader>
              <Typography
                variant="footnote"
                color="textPrimary"
                fontWeight={700}
              >
                Answer
              </Typography>
            </TableHeader>
            <TableHeader>
              <Typography
                variant="footnote"
                color="textPrimary"
                fontWeight={700}
              >
                Follow up response
              </Typography>
            </TableHeader>
            {anonymousSubmissionEnabled && (
              <>
                <TableHeader>
                  <Typography
                    variant="footnote"
                    color="textPrimary"
                    fontWeight={700}
                  >
                    Name
                  </Typography>
                </TableHeader>
                <TableHeader>
                  <Typography
                    variant="footnote"
                    color="textPrimary"
                    fontWeight={700}
                  >
                    Phone number
                  </Typography>
                </TableHeader>
              </>
            )}
          </TableRow>
        }
        tableBody={paginatedItems?.map((response) => {
          return (
            <TableRow key={response.surveySubmissionId}>
              <TableCell divider>
                <ScoreText score={response.score} questionType={questionType} />
              </TableCell>
              <TableCell divider>
                <Typography variant="body" color="textPrimary">
                  {response.comment}
                </Typography>
              </TableCell>
              {anonymousSubmissionEnabled && (
                <>
                  <TableCell divider>
                    <Typography variant="body" color="textPrimary">
                      {response.user
                        ? `${response.user.firstName} ${response.user.lastName}`
                        : "Anonymous"}
                    </Typography>
                  </TableCell>
                  <TableCell divider>
                    <Typography variant="body" color="textPrimary">
                      {phoneNumberToDisplay(
                        (response.user?.personalContactInfo?.__typename ===
                          "RewardsUserPersonalContactInfoData" &&
                          response.user?.personalContactInfo.phoneNumber) ||
                          response.user?.workPhoneNumber
                      )}
                    </Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
      />
    </Card>
  );
}
