/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

import { RadioButton } from "@rewards-web/shared/components/radio-buttons";
import { RadioGroup } from "@rewards-web/shared/components/radio-buttons/radio-group";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

interface SurveySettingsResponseFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  disabled?: boolean;
}

export function SurveySettingsResponseTypeField<T extends FieldValues>({
  control,
  name,
  disabled,
}: SurveySettingsResponseFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <RadioGroup
          ariaLabel="anonymous submission"
          error={fieldState.error}
          value={field.value ? "anonymous" : "identifiable"}
          onChange={(value) => {
            field.onChange(value === "anonymous");
          }}
          row
        >
          <RadioButton
            value="anonymous"
            label={
              <Typography variant="body" color="grey.800">
                <span
                  css={(theme: AppTheme) => css`
                    font-weight: 700;
                    color: ${theme.palette.grey[800]};
                  `}
                >
                  Anonymous
                </span>{" "}
                - Responses won't be tied to participants.
              </Typography>
            }
            disabled={disabled}
          />
          <RadioButton
            value="identifiable"
            label={
              <Typography variant="body" color="grey.800">
                <span
                  css={(theme: AppTheme) => css`
                    font-weight: 700;
                    color: ${theme.palette.grey[800]};
                  `}
                >
                  Identifiable
                </span>{" "}
                - Responses will be linked to participants.
              </Typography>
            }
            disabled={disabled}
          />
        </RadioGroup>
      )}
    />
  );
}
