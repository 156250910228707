/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { alpha } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { omit } from "lodash";
import { forwardRef, ReactNode, useState } from "react";

import { headerFontFamily } from "@rewards-web/shared/style/theme";
import {
  ControlledFormFieldProps,
  StylableProps,
} from "@rewards-web/shared/types";

import { useFormControlContext } from "../form/form-control";
import { ObscureRecordedText } from "../obscure-recorded-text";
import { TextField } from "../text-field";
import { Typography } from "../typography";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: ValueLabel) =>
    `${option.label}${
      (option.subLabels ?? []).length > 0 ? option.subLabels!.join(" ") : ""
    }`,
});

export interface ValueLabel {
  value: string;
  label: string;
  subLabels?: string[];
  groupName?: string;
}

export interface SearchTextFieldProps
  extends StylableProps,
    ControlledFormFieldProps {
  label: string;
  disabled?: boolean;
  options: ValueLabel[];
  loadingOptions?: boolean;
  onInputChange?(value: string): void;
  size?: "small" | "medium" | "large";
  /**
   * If passed, the `SearchTextField` will be considered "loading" if the debounced
   * search text field is different from the controlled input value
   */
  debouncedInputValue?: string;
  /**
   * Allows the user to enter any arbitrary value
   */
  allowCustomValues?: boolean;
  helperText?: string;
  /**
   * Keeps the dropdown menu closed when the user hasn't typed anything in.
   * This will also hide the arrow button to open the search dialog
   */
  closedWhenInputTextEmpty?: boolean;
  /**
   * Placeholder text passed to `TextField`
   */
  placeholder?: string;

  /**
   * Adds a search icon
   */
  searchIcon?: boolean;

  /**
   * `fixLabelToTop` passed to `TextField`
   */
  fixLabelToTop?: boolean;

  /**
   * `hideLabel` passed to `TextField`
   */
  hideLabel?: boolean;

  /**
   * `hideSpaceForErrorText` passed to `TextField`
   */
  hideSpaceForErrorText?: boolean;

  getCustomEndAdornmentNode?(context: {
    open: boolean;
    setOpen(open: boolean | ((prevOpen: boolean) => boolean)): void;
  }): ReactNode;

  /**
   * Enlarges the search field and displays as a title instead of a regular input
   */
  titleVariant?: boolean;

  obscureOptionsFromRecordings?: boolean;

  tooltipText?: string;
}

export const SearchTextField = forwardRef(
  (
    {
      className,
      label,
      disabled,
      options,
      name,
      onChange,
      onBlur,
      onInputChange,
      debouncedInputValue,
      loadingOptions,
      value,
      error,
      allowCustomValues,
      size = "large",
      helperText,
      closedWhenInputTextEmpty,
      placeholder,
      fixLabelToTop,
      hideLabel,
      searchIcon,
      hideSpaceForErrorText,
      getCustomEndAdornmentNode,
      titleVariant = false,
      obscureOptionsFromRecordings,
      tooltipText = "",
    }: SearchTextFieldProps,
    ref: any
  ): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [inputText, setInputText] = useState("");
    const { submitting, readOnly } = useFormControlContext();

    const selectedValue = (() => {
      if (!value) {
        return null;
      }

      const matchingOption = options.find((option) => option.value === value);

      if (matchingOption) {
        return matchingOption;
      }

      if (allowCustomValues) {
        return value as string;
      }

      return null;
    })();

    const loading =
      (loadingOptions && !selectedValue) ||
      (typeof debouncedInputValue === "string" &&
        debouncedInputValue !== inputText &&
        typeof selectedValue === "object" &&
        selectedValue?.label !== inputText);

    const hasSubLabels = options.some(
      (option) => (option.subLabels ?? []).length > 0
    );

    return (
      <ClassNames>
        {({ css, theme }) => {
          const inputWrapperClassName = css`
            position: relative;
          `;

          const inputBaseClassName = css`
            align-items: stretch;
            padding-right: 0;
          `;

          const subLabelClassName = css`
            max-width: 50%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            position: absolute;
            font-size: 0.8em;
            left: 14px;
            top: ${titleVariant ? "44px" : "36px"};
            ${theme.breakpoints.down("sm")} {
              top: 38px;
            }
          `;

          const inputClassName = css`
            ${titleVariant &&
            css`
              font-family: ${headerFontFamily};
              font-size: 1.5em;
              font-weight: 500;
              height: 1em;
            `}
            ${hasSubLabels &&
            selectedValue &&
            typeof selectedValue === "object" &&
            (selectedValue.subLabels ?? []).length > 0 &&
            css`
              padding-bottom: 39px;
            `}
          `;

          const searchIconElement = (
            <SearchIcon
              className={css`
                font-size: 20px;
                color: ${alpha(theme.palette.text.primary, 0.9)};
              `}
            />
          );

          return (
            <Autocomplete
              title={tooltipText}
              className={className}
              filterOptions={filterOptions}
              classes={{
                listbox: css`
                  // force scrollbars to show

                  &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 7px;
                  }
                  &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background-color: rgba(0, 0, 0, 0.5);
                    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                  }
                `,
                input: inputClassName,
                endAdornment: css`
                  right: ${theme.spacing(1)};
                  position: initial;
                  padding-right: 14px;
                `,
                option: css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: space-around;
                  ${hasSubLabels &&
                  css`
                    &:not(:last-of-type) {
                      border-bottom: 1px solid rgba(223, 228, 235, 0.6);
                    }
                  `}
                `,
              }}
              freeSolo={allowCustomValues}
              inputValue={inputText}
              onInputChange={(e, newInputValue, reason) => {
                if (
                  // don't change the input if it's cleared as a result of a reset,
                  // since the user may have explicitly typed or pasted a value,
                  // and we don't want to override that
                  newInputValue !== "" ||
                  reason === "input" ||
                  reason === "clear"
                ) {
                  setInputText(newInputValue);
                }

                onInputChange?.(newInputValue);
                if (
                  selectedValue &&
                  typeof selectedValue === "object" &&
                  newInputValue !== selectedValue.label
                ) {
                  onChange?.(null); // set to whatever is coming in
                } else if (allowCustomValues) {
                  // call onChange as well if we allow custom input values
                  onChange?.(newInputValue);
                }
              }}
              onChange={(_, value) => {
                onChange?.(
                  (typeof value === "object" ? value?.value : value) ?? null
                );
              }}
              onBlur={() => onBlur?.()}
              value={selectedValue}
              ref={ref}
              options={allowCustomValues && value ? [] : options}
              disabled={disabled || submitting || readOnly}
              disableClearable={disabled || submitting || readOnly}
              groupBy={
                options.some((option) => option.groupName)
                  ? (option) => option.groupName!
                  : undefined
              }
              getOptionLabel={(option: ValueLabel | string) => {
                if (typeof option === "object") {
                  return option.label;
                }

                if (allowCustomValues) {
                  return option;
                }

                return "";
              }}
              popupIcon={closedWhenInputTextEmpty ? null : undefined}
              open={closedWhenInputTextEmpty && inputText === "" ? false : open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              loading={loading}
              renderOption={(option: ValueLabel) => {
                const element = (
                  <>
                    <span>{option.label}</span>
                    {option.subLabels?.map((subLabel) => (
                      <span
                        key={subLabel}
                        style={{
                          display: "block",
                          opacity: 0.6,
                          fontSize: "0.7rem",
                          lineHeight: "15px",
                          marginBottom: "4px",
                        }}
                      >
                        {subLabel}
                      </span>
                    ))}
                  </>
                );

                return obscureOptionsFromRecordings ? (
                  <ObscureRecordedText>{element}</ObscureRecordedText>
                ) : (
                  element
                );
              }}
              renderInput={(params) => (
                <div className={inputWrapperClassName}>
                  <TextField
                    disableAutocomplete
                    {...omit(params, "size")}
                    value={params.inputProps}
                    label={label}
                    name={name}
                    error={error}
                    helperText={helperText}
                    loading={loading}
                    placeholder={placeholder}
                    fixLabelToTop={fixLabelToTop}
                    size={size}
                    hideLabel={hideLabel}
                    hideSpaceForErrorText={hideSpaceForErrorText}
                    startAdornment={searchIcon ? searchIconElement : undefined}
                    endAdornment={
                      getCustomEndAdornmentNode
                        ? getCustomEndAdornmentNode({
                            open,
                            setOpen,
                          })
                        : undefined
                    }
                    InputProps={{
                      ...params.InputProps,
                      className: inputBaseClassName,
                    }}
                  />
                  {selectedValue &&
                    typeof selectedValue === "object" &&
                    (selectedValue?.subLabels ?? []).length > 0 && (
                      <Typography
                        display="block"
                        variant="body"
                        color="textSecondary"
                        className={subLabelClassName}
                      >
                        {selectedValue?.subLabels![0]}
                      </Typography>
                    )}
                </div>
              )}
            />
          );
        }}
      </ClassNames>
    );
  }
);
