import * as GraphQLTypes from "@rewards-web/shared/graphql-types";

import { JobPostingEditDetailsFragment } from "../graphql/job-posting-fragment.generated";
import {
  JobScreenerQuestionPresets,
  JobScreenerQuestionValue,
  SCREENER_QUESTION_TYPE,
} from "../typings";

export function getJobPostingDefaultValues(
  jobPosting: JobPostingEditDetailsFragment
): {
  jobTitle: string;
  referralStructureId: string;
  branchId: string | null;
  rateOfPay: string;
  location: string;
  hoursRequired: string;
  jobDescription: string;
  screenerQuestions: JobScreenerQuestionValue[];
  candidateAlertEmail: string;
  customizedEmailMessage: string;
  customizedSMSMessage: string;
  interviewBookingLink: string;
  customizedSMSMessageChecked: boolean;
  customizedEmailMessageChecked: boolean;
  interviewBookingLinkChecked: boolean;
  mustUploadResumeBeforeBookingInterview: "YES" | "NO";
} {
  return {
    jobTitle: jobPosting.title,
    referralStructureId: jobPosting.referralStructure.id,
    branchId: jobPosting.branch?.id ?? null,
    rateOfPay: jobPosting.ratesOfPay ?? "",
    location: jobPosting.geography,
    hoursRequired: jobPosting.hoursRequired,
    jobDescription: jobPosting.descriptionHTML,
    candidateAlertEmail: jobPosting.candidateAlertEmail ?? "",
    customizedEmailMessage: jobPosting.customizedApplicantEmailMessage ?? "",
    customizedSMSMessage: jobPosting.customizedApplicantSMSMessage ?? "",
    interviewBookingLink: jobPosting.interviewBookingLink ?? "",
    mustUploadResumeBeforeBookingInterview: jobPosting.mustUploadResumeBeforeBookingInterview
      ? "YES"
      : "NO",
    customizedEmailMessageChecked: Boolean(
      jobPosting.customizedApplicantEmailMessage
    ),
    customizedSMSMessageChecked: Boolean(
      jobPosting.customizedApplicantSMSMessage
    ),
    interviewBookingLinkChecked: Boolean(jobPosting.interviewBookingLink),
    screenerQuestions: jobPosting.screenerQuestions.map((question) => {
      const autoReject = question?.autoRejectResponses ?? undefined;
      if (
        question.id === SCREENER_QUESTION_TYPE.hasDriversLicenseAndAccessToCar
      ) {
        return {
          type:
            JobScreenerQuestionPresets.HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR,
          id: question.id,
          ...(autoReject ? { autoReject: [autoReject[0]] } : {}),
        };
      }

      return {
        id: question.id,
        type: "YES_OR_NO",
        question: (question as GraphQLTypes.JobPostingYesNoField).question,
        ...(autoReject ? { autoReject: [autoReject[0]] } : {}),
      };
    }),
  };
}
