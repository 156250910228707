import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EnableAdminGoalConfigMutationVariables = Types.Exact<{
  adminIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  rewards: Types.AdminGoalRewardsInput;
  target: Types.AdminGoalTargetInput;
  startDate: Types.Scalars['Date'];
  endDate: Types.Scalars['Date'];
  goalType: Types.AdminGoalType;
  recurring: Types.Scalars['Boolean'];
}>;


export type EnableAdminGoalConfigMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'enableNewOrganizationAdminGoalConfig'>
);


export const EnableAdminGoalConfigDocument = gql`
    mutation EnableAdminGoalConfig($adminIds: [ID!]!, $rewards: AdminGoalRewardsInput!, $target: AdminGoalTargetInput!, $startDate: Date!, $endDate: Date!, $goalType: AdminGoalType!, $recurring: Boolean!) {
  enableNewOrganizationAdminGoalConfig(
    adminIds: $adminIds
    goalType: $goalType
    rewards: $rewards
    target: $target
    startDate: $startDate
    endDate: $endDate
    recurring: $recurring
  )
}
    `;
export type EnableAdminGoalConfigMutationFn = Apollo.MutationFunction<EnableAdminGoalConfigMutation, EnableAdminGoalConfigMutationVariables>;

/**
 * __useEnableAdminGoalConfigMutation__
 *
 * To run a mutation, you first call `useEnableAdminGoalConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAdminGoalConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAdminGoalConfigMutation, { data, loading, error }] = useEnableAdminGoalConfigMutation({
 *   variables: {
 *      adminIds: // value for 'adminIds'
 *      rewards: // value for 'rewards'
 *      target: // value for 'target'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      goalType: // value for 'goalType'
 *      recurring: // value for 'recurring'
 *   },
 * });
 */
export function useEnableAdminGoalConfigMutation(baseOptions?: Apollo.MutationHookOptions<EnableAdminGoalConfigMutation, EnableAdminGoalConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableAdminGoalConfigMutation, EnableAdminGoalConfigMutationVariables>(EnableAdminGoalConfigDocument, options);
      }
export type EnableAdminGoalConfigMutationHookResult = ReturnType<typeof useEnableAdminGoalConfigMutation>;
export type EnableAdminGoalConfigMutationResult = Apollo.MutationResult<EnableAdminGoalConfigMutation>;
export type EnableAdminGoalConfigMutationOptions = Apollo.BaseMutationOptions<EnableAdminGoalConfigMutation, EnableAdminGoalConfigMutationVariables>;