import { ReactNode } from "react";

import { useTrack } from "@rewards-web/shared/modules/analytics";

export function useTrackInsightMetricTooltip() {
  const track = useTrack();

  return (props: { label: string; tooltipText: ReactNode }) =>
    track("Viewed insight metric tooltip", {
      insightMetric: props.label,
      tooltipText: props.tooltipText,
    });
}

export function useTrackInsightMetricComparisonTooltip() {
  const track = useTrack();

  return (props: { label: string }) =>
    track("Viewed insight metric comparison tooltip", {
      insightMetric: props.label,
    });
}
