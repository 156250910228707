import { values as valuesFromObject } from "lodash";

import { RewardsGroupTagsConfigSchemaV1Input } from "@rewards-web/shared/graphql-types";

import { TagsCombinationsValue } from "../../../employees/groups/tags-selector-field";

export interface BranchFormValues {
  name: string;
  tagsCombinations: TagsCombinationsValue[][];
  timezone: string;
}

export const tagConfigFormValueToGraphQLInput = (
  tagCombinations: BranchFormValues["tagsCombinations"]
): RewardsGroupTagsConfigSchemaV1Input | null => {
  if (tagCombinations.length === 0) {
    return null;
  }
  const andCombinations = tagCombinations.map((combination) => {
    const tags = valuesFromObject(
      combination
    ).filter((tag): tag is TagsCombinationsValue =>
      Object.hasOwn(tag, "value")
    );
    return {
      and: tags.map((tag) => ({ tagId: tag.value })),
    };
  });

  if (andCombinations.length === 1 && andCombinations[0].and.length === 0) {
    return null;
  }
  return {
    or: andCombinations,
  };
};
