import { Controller, Control, FieldValues, Path } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { ProgressSpinner } from "@rewards-web/shared/components/progress-spinner";
import { SelectField } from "@rewards-web/shared/components/select-field";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { reportError } from "@rewards-web/shared/modules/error";

import { useReferralStructureOptionsQuery } from "./job-referral-structure-options.generated";

interface JobReferralStructureFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  readOnly?: boolean;
}

export function JobReferralStructureField<T extends FieldValues>({
  control,
  name,
  readOnly,
}: JobReferralStructureFieldProps<T>) {
  const referralStructuresQuery = useReferralStructureOptionsQuery({
    onError: reportError,
  });

  if (referralStructuresQuery.error) {
    return (
      <Alert severity="error" message="Error loading referral structures" />
    );
  }

  if (!referralStructuresQuery.data) {
    // don't render until we have data
    return <ProgressSpinner />;
  }

  const defaultStructure = referralStructuresQuery.data?.getAllReferralStructures.find(
    (structure) => structure.id.startsWith("ORGANIZATION_DEFAULT")
  );

  if (!defaultStructure) {
    throw new Error("No default referral structure found");
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <SelectField
          {...field}
          readOnly={readOnly}
          greyReadOnly
          value={
            // if `null` in the form, show the default structure
            (field.value ?? null) === null
              ? defaultStructure.id ?? null
              : field.value
          }
          hideSpaceForErrorText
          width="full"
          label="Referral"
          name={name}
          options={(
            referralStructuresQuery.data?.getAllReferralStructures ?? []
          ).map((referralStructure) => ({
            value: referralStructure.id,
            label: `${referralStructure.name}${
              typeof referralStructuresQuery.data?.getMyRewardsOrganization
                ?.pointsPerDollar === "number"
                ? ` (${formatDollars(
                    referralStructure.totalAwardablePointValue /
                      referralStructuresQuery.data?.getMyRewardsOrganization
                        ?.pointsPerDollar
                  )})`
                : ""
            }`,
          }))}
        />
      )}
    />
  );
}
