/** @jsxImportSource @emotion/react */
import { ApolloError } from "@apollo/client";
import { css } from "@emotion/react";
import { ReactNode, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  CandidateRecruitmentStepName,
  ErrorCode,
  ReferralRewardStructureDrawTicketReward,
  ReferralRewardStructureJobShareItem,
  ReferralRewardStructurePointReward,
} from "@rewards-web/shared/graphql-types";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { jobShareStructureFromApiToFormValue } from "./lib";
import { ReferralStructureTable } from "./referral-structure-table";
import { ReferralStructureFormValues } from "./types";

const NAME_MAX_LENGTH = 80;

interface ReferralStructureFormProps {
  submitting: boolean;
  pointsPerDollar: number;
  jobShareStructure: {
    __typename?: "ReferralRewardStructureJobShareItem";
  } & Pick<
    ReferralRewardStructureJobShareItem,
    "maxAwardableJobSharesPerMonth"
  > & {
      reward:
        | ({ __typename?: "ReferralRewardStructurePointReward" } & Pick<
            ReferralRewardStructurePointReward,
            "pointValue"
          >)
        | ({ __typename?: "ReferralRewardStructureDrawTicketReward" } & Pick<
            ReferralRewardStructureDrawTicketReward,
            "numTickets"
          >)
        | { __typename?: "ReferralRewardStructureManualMonthlyRaffleReward" };
    };

  onSubmit: (values: ReferralStructureFormValues) => Promise<void>;

  viewMode?: boolean;
  initialValues?: Pick<ReferralStructureFormValues, "name" | "steps">;
  launched?: boolean;
  isDefaultReferralStructure?: boolean;
}

export function ReferralStructureForm({
  jobShareStructure,
  pointsPerDollar,
  onSubmit,
  submitting,
  initialValues,
  viewMode = false,
  launched = false,
  isDefaultReferralStructure = false,
}: ReferralStructureFormProps) {
  const snackbar = useSnackbar();
  const [confirming, setConfirming] = useState(false);

  const form = useForm<ReferralStructureFormValues>({
    defaultValues: {
      ...(initialValues ?? {
        name: "",
        steps: [
          {
            type: "recruitment",
            step: CandidateRecruitmentStepName.ApplicationSubmitted,
            pointValue: "",
          },
          {
            type: "recruitment",
            step: CandidateRecruitmentStepName.CompletedOrientation,
            pointValue: "",
          },
          {
            type: "recruitment",
            step: CandidateRecruitmentStepName.CompletedFirstShift,
            pointValue: "",
          },
          {
            type: "retention",
            unit: "hours",
            amount: 100,
            pointValue: "",
          },
          {
            type: "retention",
            unit: "hours",
            amount: 300,
            pointValue: "",
          },
        ],
      }),

      jobSharePointValue: "", // not used for submission; just here to hook up the read-only text field
    },
  });

  useEffect(() => {
    if (jobShareStructure) {
      const value = jobShareStructureFromApiToFormValue(jobShareStructure);
      form.setValue("jobSharePointValue", value);
    }
  }, [form, jobShareStructure]);

  const name = useWatch({ control: form.control, name: "name" });

  const handleSubmit = async () => {
    setConfirming(true);
  };

  const handleErrors = () => {
    snackbar.show({
      severity: "error",
      message: "There are errors in the form. Please fix them and try again.",
    });
  };

  const handleConfirm = async () => {
    const values = form.getValues();

    try {
      await onSubmit(values);
      snackbar.show({
        severity: "success",
        message: `Success! "${values.name}" referral structure ${
          initialValues ? "updated" : "created"
        }.`,
      });
      setConfirming(false);
    } catch (error) {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors.some(
          (error) =>
            error.extensions?.errorCode ===
            ErrorCode.ReferralStructureNameAlreadyExists
        )
      ) {
        setConfirming(false);
        form.setError("name", {
          message: "A referral structure with this name already exists.",
        });
        snackbar.show({
          severity: "error",
          message:
            "A referral structure with this name already exists - please choose a different name.",
        });
        form.reset();
      } else {
        snackbar.show({
          severity: "error",
          message:
            "There was an error creating the referral structure. Please try again later.",
        });
        reportError(error);
      }
    }
  };

  const referralStructureTableDescription = ((): ReactNode | null => {
    if (!viewMode) {
      return (
        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          Modify the referral reward values to match your agency's budget.
        </Typography>
      );
    }

    if (launched) {
      return (
        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          Referral structures are locked to maintain accurate statistics when a
          job posting is open.
        </Typography>
      );
    }

    return null;
  })();

  return (
    <Form
      onSubmit={form.handleSubmit(handleSubmit, handleErrors)}
      submitting={submitting}
    >
      <Card
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
          padding-bottom: ${theme.spacing(1)};
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        <Typography
          variant="h5"
          component="h2"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          Internal details
        </Typography>

        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          This name will be used solely for organizing your referrals and will
          not be visible to caregivers.
        </Typography>

        <TextField
          label="Name"
          disableAutocomplete
          fixLabelToTop
          placeholder="E.g. Nurses"
          helperText={getCharactersRemainingText(name, NAME_MAX_LENGTH)}
          error={form.formState.errors.name}
          {...form.register("name", {
            required: "Name is required",
            validate: (value) => {
              if (value && value.length >= NAME_MAX_LENGTH) {
                return getCharactersRemainingText(value, NAME_MAX_LENGTH);
              }
            },
          })}
          disabled={viewMode || isDefaultReferralStructure}
        />
      </Card>

      <Card
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        <Typography
          variant="h5"
          component="h2"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          Reward value
        </Typography>

        {referralStructureTableDescription}

        <ReferralStructureTable
          control={form.control}
          name="steps"
          jobShareFieldName="jobSharePointValue"
          pointsPerDollar={pointsPerDollar}
          viewMode={viewMode}
          jobShareStructure={jobShareStructure}
          totalsVariant={viewMode ? "withinTable" : "alert"}
        />
      </Card>

      {!viewMode && (
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            gap: ${theme.spacing(1)};
            float: right;
            padding-bottom: ${theme.spacing(4)};
          `}
        >
          <Button
            label="Cancel"
            size="large"
            variant="outlined"
            width="auto"
            css={css`
              min-width: 150px;
            `}
          />
          <Button
            label="Save"
            size="large"
            variant="contained"
            color="primary"
            width="auto"
            type="submit"
            css={css`
              min-width: 150px;
            `}
          />
        </div>
      )}

      {confirming && (
        <Modal
          width="550px"
          open={confirming}
          onClose={() => setConfirming(false)}
        >
          <ModalTitle>
            Confirm {initialValues ? "updated" : "new"} referral structure?
          </ModalTitle>
          <ModalContent>
            <Typography variant="body" color="grey.800">
              {launched
                ? "You're almost done! This referral will be locked to ensure a seamless caregiver experience and accurate data (you may create new structures at any time)."
                : "You're almost done! Once your agency has launched the rewards program, this referral will be locked to ensure a seamless caregiver experience and accurate data (you may create new structures at any time)."}
            </Typography>
          </ModalContent>
          <ModalActions>
            <Button
              variant="outlined"
              onClick={() => setConfirming(false)}
              label="Cancel"
              size="large"
            />
            <Button
              onClick={handleConfirm}
              color={"primary"}
              label="Confirm"
              size="large"
              loading={submitting}
            />
          </ModalActions>
        </Modal>
      )}
    </Form>
  );
}
