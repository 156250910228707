/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHelperText } from "@material-ui/core";
import { Control, Controller, Path } from "react-hook-form";

import { SwitchField } from "@rewards-web/shared/components/switch";
import { Typography } from "@rewards-web/shared/components/typography";

import { getSurveyQuestionMetadata } from "../../../../../../../../shared/constants/survey-question-metadata";
import { PULSE_SURVEY_QUESTIONS } from "../../../../../constants";

export type PulseSurveyFormQuestions = {
  [key in typeof PULSE_SURVEY_QUESTIONS[0]]: boolean;
};

type FieldValuesWithQuestions = {
  questions: PulseSurveyFormQuestions;
};

interface PulseSurveyQuestionsFieldProps<T extends FieldValuesWithQuestions> {
  control: Control<T, object>;
  disabled?: boolean;
}

export function PulseSurveyQuestionsField<T extends FieldValuesWithQuestions>({
  control,
  disabled,
}: PulseSurveyQuestionsFieldProps<T>) {
  const theme = useTheme();

  return (
    <Controller
      control={control}
      name={"questions" as Path<T>}
      rules={{
        validate: (questions) => {
          if (
            !disabled &&
            Object.values(questions).every((enabled) => !enabled)
          ) {
            return "At least one question must be enabled";
          }
        },
      }}
      render={({ fieldState: { error } }) => (
        <div>
          <div
            css={css`
              background-color: #f5f9fa;
              ${error &&
              css`
                outline: solid 1px ${theme.palette.error.main};
              `}
              padding: ${theme.spacing(2, 3)};
              border-radius: 8px;
              width: 100%;
              display: grid;
              margin-top: ${theme.spacing(-4)};
              grid-template-columns: 180px 1fr 125px;
              > * {
                padding: ${theme.spacing(2.5, 0)};
                &:nth-child(n + 4) {
                  border-top: solid 1px ${theme.palette.divider};
                }
              }
            `}
          >
            {PULSE_SURVEY_QUESTIONS.map((question) => {
              const {
                icon,
                category,
                questionText,
              } = getSurveyQuestionMetadata(question);
              return [
                <div
                  key={`category:${question}`}
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: ${theme.spacing(1)};
                    width: 180px;
                    color: ${theme.palette.grey[800]};
                  `}
                >
                  <FontAwesomeIcon icon={icon} />
                  <Typography variant="caption">
                    {category.toUpperCase()}
                  </Typography>
                </div>,
                <Typography key={`questionText:${question}`}>
                  {questionText}
                </Typography>,
                <Controller
                  key={`form:${question}`}
                  control={control}
                  name={`questions.${question}` as Path<T>}
                  render={({ field, fieldState }) => {
                    return (
                      <SwitchField
                        {...field}
                        label={{ onLabel: "Enabled", offLabel: "Disabled" }}
                        error={fieldState.error}
                        labelVariant="highlight"
                        disabled={disabled}
                      />
                    );
                  }}
                />,
              ];
            })}
          </div>
          <FormHelperText
            error={!!error}
            css={css`
              font-size: 16px;
            `}
          >
            {error?.message ?? " "}
          </FormHelperText>
        </div>
      )}
    />
  );
}
