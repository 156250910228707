/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faArrowDown, faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { Sort } from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useSortableTableContext } from "./context";

export interface SortableTableHeaderProps<TField extends string> {
  text: string;
  sortField?: TField;
  getActiveTooltipText?: (direction: Sort) => string;
  smallerFont?: boolean;
}

export function SortableTableHeader<TField extends string>({
  text,
  sortField,
  getActiveTooltipText,
  smallerFont = false,
}: SortableTableHeaderProps<TField>) {
  const [sortState, setSortState] = useSortableTableContext<TField>();
  const sortingByThisField = sortField ? sortState.field === sortField : false;

  return (
    <Tooltip
      title={
        sortingByThisField && getActiveTooltipText
          ? getActiveTooltipText(sortState.direction)
          : ""
      }
      disabled={!getActiveTooltipText}
    >
      <Typography
        variant="subtitle"
        color="textPrimary"
        fontSize={smallerFont ? "16px" : undefined}
        component="button"
        className={sortingByThisField ? "is-sorting-by-this-field" : ""}
        css={(theme: AppTheme) => css`
          /* Button reset */
          border: none;
          background: none;
          padding: 0;
          margin: 0;

          ${sortField &&
          css`
            cursor: pointer;

            .sort-arrow-icon {
              color: ${theme.palette.text.primary};
            }

            /* Show darker background on hover */
            &:hover .sort-arrow-icon {
              border-radius: 4px;
              background-color: ${theme.palette.grey[100]};
            }

            /* On hover, OR if we're sorting by this field, show background */
            &.is-sorting-by-this-field .sort-arrow-icon {
              border-radius: 4px;
              background-color: ${theme.palette.grey[200]};
            }
          `}
        `}
        onClick={() => {
          if (sortField) {
            setSortState({
              field: sortField,
              direction: (() => {
                if (sortingByThisField) {
                  // already sorting this field; switch to opposite direction
                  return sortState.direction === Sort.Asc
                    ? Sort.Desc
                    : Sort.Asc;
                }

                // use ascending direction
                return Sort.Asc;
              })(),
            });
          }
        }}
      >
        {text}{" "}
        <div
          className="sort-arrow-icon"
          css={css`
            width: 18px;
            height: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <FontAwesomeIcon
            size="xs"
            icon={
              sortingByThisField
                ? sortState.direction === Sort.Asc
                  ? faArrowDown
                  : faArrowUp
                : faArrowDown
            }
          />
        </div>
      </Typography>
    </Tooltip>
  );
}
