/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { camelCase, upperFirst } from "lodash";
import { useEffect, useState } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  SurveyInsightsDateRange,
  SurveyInsightsDateRangeInput,
  SurveyQuestionType,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DateRangeSelectField } from "../../components/date-range-select-field";
import { QuestionCategory } from "../../components/question-category";
import { QuestionText } from "../../components/question-text";
import {
  convertDateRangeToValue,
  convertValueToDateRange,
  isSurveyTypeSupportedByUI,
} from "../../utils";
import { usePulseSurveyAvailableDateRangesQuery } from "../pulse-survey-available-date-ranges.generated";
import { NpsQuestionDetailsPage } from "./nps-question-details-page";
import { ScoreQuestionDetailsPage } from "./score-question-details-page";

export function SurveyQuestionDetailsPageContents() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const questionType =
    SurveyQuestionType[
      // converts "question_type" to "QuestionType"
      upperFirst(
        camelCase(params.question_type!)
      ) as keyof typeof SurveyQuestionType
    ];

  const validSurveyType = isSurveyTypeSupportedByUI(questionType);

  const {
    data: dateRangeData,
    error: dateRangeError,
  } = usePulseSurveyAvailableDateRangesQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const [selectedDateRange, setSelectedDateRange] = useState<
    SurveyInsightsDateRange | undefined
  >();

  const dateRangeInput = ((): SurveyInsightsDateRangeInput | undefined => {
    if (!selectedDateRange) {
      return undefined;
    }

    return {
      label: selectedDateRange.label,
      year: selectedDateRange.year,
    };
  })();

  useEffect(() => {
    // set initial date range on load
    if (dateRangeData && !selectedDateRange) {
      const searchedDateRange = searchParams.get("dateRange");

      // initialize with searched date range, or else most recent
      const dateRange = searchedDateRange
        ? convertValueToDateRange(
            searchedDateRange,
            dateRangeData.getAvailableSurveyDateRanges
          )
        : dateRangeData.getAvailableSurveyDateRanges[0];

      return setSelectedDateRange(dateRange);
    }
  }, [dateRangeData, selectedDateRange, searchParams]);

  if (!validSurveyType) {
    return <Navigate to="/surveys/pulse" replace />;
  }

  if (dateRangeError) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!dateRangeData) {
    return <PageLoadingState />;
  }

  return (
    <>
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        linkTo={`/surveys/pulse?dateRange=${convertDateRangeToValue(
          selectedDateRange
        )}`}
      />
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            margin: ${theme.spacing(3)} 0;
          `}
        >
          <QuestionCategory questionType={questionType} variant="h6" />
          <Typography
            variant="subtitle"
            fontWeight={700}
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(1)};
              `
            }
          >
            Question
          </Typography>
          <QuestionText questionType={questionType} variant="h3" />
        </div>
        <div
          css={css`
            align-self: center;
          `}
        >
          <DateRangeSelectField
            value={selectedDateRange}
            options={dateRangeData?.getAvailableSurveyDateRanges ?? []}
            onChange={(dateRange: SurveyInsightsDateRange) =>
              setSelectedDateRange(dateRange)
            }
          />
        </div>
      </div>
      {questionType === SurveyQuestionType.Nps ? (
        <NpsQuestionDetailsPage dateRangeInput={dateRangeInput} />
      ) : (
        <ScoreQuestionDetailsPage
          questionType={questionType}
          dateRangeInput={dateRangeInput}
        />
      )}
    </>
  );
}
