/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { startCase } from "lodash";
import { Controller, useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { SelectField } from "@rewards-web/shared/components/select-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useAdminAppAuthenticatedOrganizationConfigQuery } from "../../../../../../../pages/authenticated/admin-app-authenticated-organization-config.generated";
import { FormStepper } from "../../../../../../components/form-stepper";
import { useMyBranches } from "../../../../../branches/use-my-branches";
import {
  AddEditEmployeeAdminConflictError,
  AddEditEmployeeConflictError,
  AddEditEmployeeInvalidWorkDomainError,
} from "../../../../errors/errors";
import { EmployeeBranchField } from "../../../../fields/employee-branch";
import { EmployeeDateOfBirthField } from "../../../../fields/employee-date-of-birth";
import { EmployeeEmploymentStartDateField } from "../../../../fields/employee-employment-start-date";
import { EmployeeNameField } from "../../../../fields/employee-first-name";
import { EmployeeLastNameField } from "../../../../fields/employee-last-name";
import { EmployeeManagerField } from "../../../../fields/employee-manager";
import { EmployeePersonalCellField } from "../../../../fields/employee-personal-cell-field";
import { EmployeePersonalEmailField } from "../../../../fields/employee-personal-email-field";
import { EmployeeWorkCellField } from "../../../../fields/employee-work-cell-phone";
import { EmployeeWorkEmailField } from "../../../../fields/employee-work-email-field";
import { useListUserGroupsQuery } from "../get-rewards-user-groups.generated";

interface AddEmployeeFormValues {
  firstName: string;
  lastName: string;
  workEmail: string;
  workPhoneNumber: string;
  personalEmail: string;
  personalPhoneNumber: string;
  assignedToUserGroupId: string;
  branchId: string;
  managerId: string;
  employmentStartDate: string;
  dateOfBirth: string;
}

interface AddEmployeeFormProps {
  onClose(): void;
  onSubmit(values: AddEmployeeFormValues): Promise<void>;
  managersEnabled: boolean;
  usesWorkDevices: boolean;
  withGroupIds?: boolean;
}

export function AddEmployeeForm({
  onClose,
  onSubmit,
  usesWorkDevices,
  managersEnabled,
  withGroupIds,
}: AddEmployeeFormProps) {
  const organizationWhiteLabelConfigQuery = useAdminAppAuthenticatedOrganizationConfigQuery();
  const rewardsProgramShortName =
    organizationWhiteLabelConfigQuery.data?.getMyRewardsOrganization
      ?.whiteLabelConfig?.rewardsProgramShortName ?? "Caribou Rewards";

  const branchesQuery = useMyBranches();

  const rewardsUserGroupsListQuery = useListUserGroupsQuery({
    fetchPolicy: "network-only",
    skip: !withGroupIds,
    nextFetchPolicy: "network-only", // prevent from reloading query automatically after a mutation
    onError: reportError,
  });

  const rewardsUserGroups =
    rewardsUserGroupsListQuery.data?.listRewardsUserGroups.items;

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<AddEmployeeFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      workEmail: "",
      workPhoneNumber: "",
      personalEmail: "",
      personalPhoneNumber: "",
      assignedToUserGroupId: "",
      branchId: "",
      managerId: "",
      employmentStartDate: "",
      dateOfBirth: "",
    },
  });

  return (
    <Form
      css={css`
        display: contents; // Modal child components must be direct DOM descendents of Modal
      `}
      onSubmit={handleSubmit(async (values) => {
        try {
          await onSubmit(values);
        } catch (error) {
          if (error instanceof AddEditEmployeeConflictError) {
            for (const { name, userActive } of error.fieldsAndUsers) {
              setError(name, {
                type: "unique",
                message: `${
                  userActive ? "An active" : "A de-activated"
                } user exists with this ${startCase(name).toLowerCase()}`,
              });
            }
          } else if (error instanceof AddEditEmployeeAdminConflictError) {
            if (error.fields.includes("email")) {
              setError("personalEmail", {
                type: "unique",
                message: "An admin exists with this email",
              });
            }
          } else if (error instanceof AddEditEmployeeInvalidWorkDomainError) {
            setError("workEmail", {
              type: "invalidWorkDomain",
              message: `Work emails must match domain @${error.requiredDomain}`,
            });
          }
        }
      })}
    >
      <ModalTitle>Create Employee</ModalTitle>
      <ModalContent>
        <FormStepper
          steps={[
            {
              title: "Add employee details",
              content: (
                <div
                  css={(theme: AppTheme) => css`
                    display: grid;
                    grid-template-columns: calc(50% - ${theme.spacing(1)}) calc(
                        50% - ${theme.spacing(1)}
                      );
                    grid-column-gap: ${theme.spacing(1)};
                  `}
                >
                  <EmployeeNameField control={control} name="firstName" />

                  <EmployeeLastNameField control={control} name="lastName" />

                  {managersEnabled && (
                    <EmployeeManagerField
                      control={control}
                      name="managerId"
                      workEmailFieldName="workEmail"
                      personalEmailFieldName="personalEmail"
                    />
                  )}

                  {(branchesQuery.data?.getMyRewardsOrganization.branches
                    .length ?? 0) > 0 && (
                    <EmployeeBranchField control={control} name="branchId" />
                  )}

                  {rewardsUserGroups &&
                    withGroupIds &&
                    rewardsUserGroups.length > 0 && (
                      <Controller
                        control={control}
                        name="assignedToUserGroupId"
                        render={({ field }) => (
                          <SelectField
                            {...field}
                            label="Group (optional)"
                            disabled={isSubmitting}
                            options={rewardsUserGroups.map(({ name, id }) => ({
                              label: name,
                              value: id,
                            }))}
                          />
                        )}
                      />
                    )}
                </div>
              ),
            },
            {
              title: "Add work details",
              content: (
                <div
                  css={(theme: AppTheme) => css`
                    display: grid;
                    grid-template-columns: calc(50% - ${theme.spacing(1)}) calc(
                        50% - ${theme.spacing(1)}
                      );
                    grid-column-gap: ${theme.spacing(1)};
                  `}
                >
                  {usesWorkDevices && (
                    <>
                      <EmployeeWorkCellField
                        control={control}
                        name="workPhoneNumber"
                      />
                      <EmployeeWorkEmailField
                        control={control}
                        name="workEmail"
                        personalPhoneNumberName="personalPhoneNumber"
                        personalEmailName="personalEmail"
                      />
                    </>
                  )}
                  <EmployeeEmploymentStartDateField
                    control={control}
                    name="employmentStartDate"
                  />
                  <EmployeeDateOfBirthField
                    control={control}
                    name="dateOfBirth"
                  />
                </div>
              ),
            },

            {
              title: usesWorkDevices
                ? "Add personal contact details"
                : "Add contact details",
              content: (
                <div
                  css={(theme: AppTheme) => css`
                    display: grid;
                    grid-template-columns: calc(50% - ${theme.spacing(1)}) calc(
                        50% - ${theme.spacing(1)}
                      );
                    grid-column-gap: ${theme.spacing(1)};
                  `}
                >
                  <EmployeePersonalCellField
                    control={control}
                    name="personalPhoneNumber"
                    parsonalPhoneNumberName="personalPhoneNumber"
                    personalEmailName="personalEmail"
                    usesWorkDevices={usesWorkDevices}
                  />

                  <EmployeePersonalEmailField
                    control={control}
                    name="personalEmail"
                    parsonalPhoneNumberName="personalPhoneNumber"
                    personalEmailName="personalEmail"
                    usesWorkDevices={usesWorkDevices}
                  />
                </div>
              ),
            },
            {
              title: "Confirm account creation",
              content: (
                <>
                  <Typography
                    css={(theme: AppTheme) => css`
                      margin-bottom: ${theme.spacing(2)};
                    `}
                  >
                    {`Press “Confirm” to create a ${rewardsProgramShortName} account for this
                    employee. They will be sent an invitation to sign up for
                    ${rewardsProgramShortName}.`}
                  </Typography>
                  {usesWorkDevices && (
                    <Typography>
                      {`Note: If personal contact details have been provided, the
                      employee will be automatically signed up and sent their
                      unique link to access ${rewardsProgramShortName}.`}
                    </Typography>
                  )}
                </>
              ),
            },
          ]}
        />
      </ModalContent>
      <ModalActions>
        <Button variant="outlined" onClick={onClose} label="Cancel" />
        <Button
          type="submit"
          color="primary"
          label="Confirm"
          loading={isSubmitting}
        />
      </ModalActions>
    </Form>
  );
}
