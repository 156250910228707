/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faArrowsRotate,
  faCalendar,
  faDollar,
  faWallet,
} from "@fortawesome/pro-regular-svg-icons";
import { faDollarCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addMonths, differenceInDays, format } from "date-fns";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Form } from "@rewards-web/shared/components/form";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { SelectField } from "@rewards-web/shared/components/select-field";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  AdminGoalRewardsType,
  AdminGoalTargetType,
  AdminGoalType,
  AdminGoalUpdateTarget,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { usePartialUpdateAdminGoalConfigMutation } from "../partial-update-admin-goal-config.generated";
import { useAdminGoalAdminListQuery } from "./admin-goal-admin-list-data.generated";
import { AdminGoalConfigurationAdminList } from "./admin-goal-configuration-admin-list";
import { EditGoalConfirmationModal } from "./edit-goal-confirmation-modal";
import { useEnableAdminGoalConfigMutation } from "./enable-admin-goal-config.generated";
import { useLaunchAdminGoalConfigMutation } from "./launch-admin-goal-config.generated";
import { LaunchGoalConfirmationModal } from "./launch-goal-confirmation-modal";

export type AdminGoalFormValues = {
  adminIds: string[];
  rewardAmount: number;
  targetPercentage: number;
  startDate: Date;
  endDate: Date;
  recurring: "true" | "false";
};

type BaseGoalConfigurationFormProps = {
  goalType: AdminGoalType;
  goalTitle: string;
  goalPeriod: string;
  nextMonthGoalPeriod?: string;
  defaultValues: AdminGoalFormValues;
  rewardAmountDisabled?: boolean;
  targetPercentageDisabled?: boolean;
  editMode?: boolean;
  editTarget?: "current" | "current-and-recurring" | "recurring";
  goalConfigId?: string;
};

export function BaseGoalConfigurationForm({
  goalType,
  goalTitle,
  goalPeriod,
  nextMonthGoalPeriod,
  defaultValues,
  rewardAmountDisabled,
  targetPercentageDisabled,
  editMode = false,
  editTarget,
  goalConfigId,
}: BaseGoalConfigurationFormProps): JSX.Element {
  const track = useTrack();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const recurringGoalConfigEnabled = useFeatureFlag(
    "admin-app-recurring-goal-config-temp"
  );

  const [startConfirmationModalOpen, setStartConfirmationModalOpen] = useState(
    false
  );
  const [editConfirmationModalOpen, setEditConfirmationModalOpen] = useState(
    false
  );
  const [editChangesApplyTo, setEditChangesApplyTo] = useState<
    "recurring" | "currentAndRecurring"
  >("recurring");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedPeriod, setSelectedPeriod] = useState<"current" | "next">(
    "current"
  );
  const [changesSubmitting, setChangesSubmitting] = useState(false);
  const [launchAdminGoal] = useLaunchAdminGoalConfigMutation();
  const [enableAdminGoalConfig] = useEnableAdminGoalConfigMutation();
  const [
    partialUpdateAdminGoalConfig,
  ] = usePartialUpdateAdminGoalConfigMutation();
  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
  };

  const form = useForm<AdminGoalFormValues>({
    mode: "onChange",
    defaultValues,
  });

  const { control, formState, handleSubmit, setValue } = form;

  const [adminIds, rewardAmount, endDate] = useWatch({
    control,
    name: ["adminIds", "rewardAmount", "endDate"],
  });

  const adminsListQuery = useAdminGoalAdminListQuery({
    variables: {
      budgetsEnabled:
        goalType === AdminGoalType.BudgetUtilization ? true : undefined,
    },
    onError: reportError,
    onCompleted: (data) => {
      // all admins should be selected by default
      if (adminIds.length === 0) {
        setValue(
          "adminIds",
          data.listRewardsAdmins.items.map((admin) => admin.id)
        );
      }
    },
  });

  const filteredAdmins = useMemo(() => {
    if (!searchTerm.trim()) {
      return adminsListQuery.data?.listRewardsAdmins.items ?? [];
    }

    const searchTermLower = searchTerm.toLowerCase();
    return (
      adminsListQuery.data?.listRewardsAdmins.items.filter((admin) => {
        const firstNameMatch = admin.firstName
          ?.toLowerCase()
          .includes(searchTermLower);
        const lastNameMatch = admin.lastName
          ?.toLowerCase()
          .includes(searchTermLower);

        const fullName = `${admin.firstName} ${admin.lastName}`.toLowerCase();
        const fullNameReversed = `${admin.lastName}, ${admin.firstName}`.toLowerCase();
        const fullNameMatch =
          fullName.includes(searchTermLower) ||
          fullNameReversed.includes(searchTermLower);

        return firstNameMatch || lastNameMatch || fullNameMatch;
      }) ?? []
    );
  }, [adminsListQuery.data?.listRewardsAdmins.items, searchTerm]);

  const totalBudget = (() => adminIds.length * rewardAmount)();

  // Ensure we always show at least 1 day left in the period
  const daysLeftInPeriod = Math.max(differenceInDays(endDate, new Date()), 1);

  // If there are 5 days or less left in the goal period we consider this a "late" launch,
  // and show a warning to the user when confirming.
  const isLateLaunch = daysLeftInPeriod <= 5;

  const handlePartialUpdate = async (
    configId: string,
    values: AdminGoalFormValues,
    updateTargets: AdminGoalUpdateTarget[]
  ) => {
    setChangesSubmitting(true);
    return partialUpdateAdminGoalConfig({
      variables: {
        configId,
        recurring: values.recurring === "true",
        updateTargets,
        props: {
          adminIds: values.adminIds,
          rewards: {
            type: AdminGoalRewardsType.OrganizationDefault,
            dollarValue: Number(values.rewardAmount),
          },
          target: {
            type: AdminGoalTargetType.Percentage,
            percentageValue: Number(values.targetPercentage),
          },
        },
      },
      onError: (error) => {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "Your goal could not be updated. Please try again.",
        });
        setChangesSubmitting(false);
      },
      onCompleted: () => {
        snackbar.show({
          severity: "success",
          message: "Your goal has been updated successfully.",
        });
        navigate("/settings/office-goals");
      },
    });
  };

  const onConfirm = async (values: AdminGoalFormValues) => {
    if (editMode && goalConfigId) {
      const updateTargets = (() => {
        if (editChangesApplyTo === "recurring") {
          return [AdminGoalUpdateTarget.Recurring];
        }
        if (editChangesApplyTo === "currentAndRecurring") {
          return [
            AdminGoalUpdateTarget.Current,
            AdminGoalUpdateTarget.Recurring,
          ];
        }
        return [];
      })();

      await handlePartialUpdate(goalConfigId, values, updateTargets);
    } else if (recurringGoalConfigEnabled) {
      await enableAdminGoalConfig({
        variables: {
          adminIds: values.adminIds,
          rewards: {
            // Currently, all admin goals should use the organization default reward type
            // which is determined by their redemption settings.
            type: AdminGoalRewardsType.OrganizationDefault,
            dollarValue: Number(values.rewardAmount),
          },
          target: {
            type: AdminGoalTargetType.Percentage,
            percentageValue: Number(values.targetPercentage),
          },
          startDate: format(values.startDate, "yyyy-MM-dd"),
          endDate: format(values.endDate, "yyyy-MM-dd"),
          goalType,
          recurring: values.recurring === "true",
        },
        onError: (error) => {
          reportError(error);
          snackbar.show({
            severity: "error",
            message: "Your goal could not be enabled. Please try again.",
          });
        },
        onCompleted: () => {
          snackbar.show({
            severity: "success",
            message: "Your goal has successfully configured!",
          });
          navigate("/settings/office-goals");
        },
      });
    } else {
      await launchAdminGoal({
        variables: {
          adminIds: values.adminIds,
          rewards: {
            // Currently, all admin goals should use the organization default reward type
            // which is determined by their redemption settings.
            type: AdminGoalRewardsType.OrganizationDefault,
            dollarValue: Number(values.rewardAmount),
          },
          target: {
            type: AdminGoalTargetType.Percentage,
            percentageValue: Number(values.targetPercentage),
          },
          startDate: format(values.startDate, "yyyy-MM-dd"),
          endDate: format(values.endDate, "yyyy-MM-dd"),
          goalType,
        },
        onError: (error) => {
          reportError(error);
          snackbar.show({
            severity: "error",
            message: "Your goal could not be launched. Please try again.",
          });
        },
        onCompleted: () => {
          snackbar.show({
            severity: "success",
            message: "Your office staff goal has successfully launched!",
          });
          navigate("/settings/office-goals");
        },
      });
    }
  };

  if (adminsListQuery.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (!adminsListQuery.data) {
    return (
      <div
        css={css`
          height: 200px;
        `}
      >
        <PageLoadingState />
      </div>
    );
  }

  return (
    <Form
      submitting={formState.isSubmitting}
      onSubmit={handleSubmit(async (values: AdminGoalFormValues) => {
        if (editMode && goalConfigId) {
          if (editTarget === "current-and-recurring") {
            // submit is handled by confirmation modal
            setEditConfirmationModalOpen(true);
          } else {
            // No modal for individual edit mode for current or recurring goal props
            const updateTargets = (() => {
              if (editTarget === "recurring") {
                return [AdminGoalUpdateTarget.Recurring];
              }
              if (editTarget === "current") {
                if (values.recurring === "true") {
                  return [
                    AdminGoalUpdateTarget.Current,
                    AdminGoalUpdateTarget.Recurring,
                  ];
                } else {
                  return [AdminGoalUpdateTarget.Current];
                }
              }
              return [];
            })();

            await handlePartialUpdate(goalConfigId, values, updateTargets);
          }
        } else {
          // submit is handled by confirmation modal
          setStartConfirmationModalOpen(true);
        }
      })}
    >
      <Typography
        variant="h5"
        color="textPrimary"
        fontWeight={700}
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(1)};
          `
        }
      >
        Select admins who will receive rewards for accomplishing the{" "}
        {goalTitle.toLowerCase()}
      </Typography>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: space-between;
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        <TextField
          css={(theme: AppTheme) => css`
            width: 312px;
            margin: ${theme.spacing(0.75)} 0;
          `}
          disableAutocomplete
          hideLabel
          hideSpaceForErrorText
          label="Search"
          placeholder="Search employee"
          size="small"
          type="search"
          value={searchTerm}
          onChange={(e) => {
            handleSearchTermChange(e.target.value);
          }}
        />
        <Card
          variant="flat"
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
            align-content: center;
            margin-left: ${theme.spacing(1)};
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <FontAwesomeIcon icon={faDollarCircle} width={16} />
            <Typography
              variant="footnote"
              color="textPrimary"
              fontWeight={400}
              css={(theme: AppTheme) => css`
                font-style: italic;
                margin-left: ${theme.spacing(1)};
                margin-right: ${theme.spacing(0.5)};
              `}
            >
              Total budget for reward
            </Typography>
            <Typography
              variant="footnote"
              color="textPrimary"
              fontWeight={700}
              css={css`
                font-style: italic;
              `}
            >
              ${totalBudget}
            </Typography>
          </div>
        </Card>
      </div>
      <Typography
        variant="footnote"
        color="grey.800"
        fontWeight={600}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
          text-transform: uppercase;
        `}
      >
        {adminIds.length} employee{adminIds.length === 1 ? "" : "s"} selected
      </Typography>
      <AdminGoalConfigurationAdminList
        loading={adminsListQuery.loading}
        allAdmins={filteredAdmins}
        selectedAdminIds={new Set(adminIds)}
        onAdminSelectionChange={(selectedAdminIds) =>
          setValue("adminIds", Array.from(selectedAdminIds))
        }
      />
      <Card
        variant="flat"
        css={(theme: AppTheme) =>
          css`
            padding: ${theme.spacing(2)};
            margin-top: ${theme.spacing(4)};
          `
        }
      >
        <Typography
          variant="h6"
          color="textPrimary"
          fontWeight={700}
          css={css`
            margin-bottom: 21px;
          `}
        >
          Goal configuration
        </Typography>
        {!recurringGoalConfigEnabled && (
          <>
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                margin-bottom: ${theme.spacing(4)};
              `}
            >
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  align-items: top;
                  gap: ${theme.spacing(2)};
                  flex: 1;
                `}
              >
                <FontAwesomeIcon
                  icon={faCalendar}
                  width={18}
                  css={css`
                    margin-top: 4px;
                  `}
                />
                <div>
                  <Typography variant="body" color="textPrimary">
                    Goal Period
                  </Typography>
                  <Typography
                    variant="footnote"
                    color="grey.800"
                    css={(theme: AppTheme) => css`
                      margin-top: ${theme.spacing(1)};
                      max-width: 350px;
                    `}
                  >
                    Goals include data from the start of the period, regardless
                    of launch date
                  </Typography>
                </div>
              </div>
              <div
                css={css`
                  flex: 1;
                `}
              >
                <TextField
                  label="Goal period"
                  value={goalPeriod}
                  disabled
                  hideSpaceForErrorText
                  css={css`
                    max-width: 400px;
                  `}
                />
              </div>
            </div>
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                margin-bottom: ${theme.spacing(4)};
              `}
            >
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  align-items: center;
                  gap: ${theme.spacing(2)};
                  flex: 1;
                `}
              >
                <FontAwesomeIcon icon={faArrowsRotate} width={18} />
                <Typography variant="body" color="textPrimary">
                  Recurrence
                </Typography>
              </div>
              <div
                css={css`
                  flex: 1;
                `}
              >
                <TextField
                  label="Recurrence"
                  value="One-time"
                  disabled
                  hideSpaceForErrorText
                  css={css`
                    max-width: 400px;
                  `}
                />
              </div>
            </div>
          </>
        )}

        {recurringGoalConfigEnabled && (
          <>
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                margin-bottom: ${theme.spacing(4)};
              `}
            >
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  align-items: top;
                  gap: ${theme.spacing(2)};
                  flex: 1;
                `}
              >
                <FontAwesomeIcon
                  icon={faArrowsRotate}
                  width={18}
                  css={css`
                    margin-top: 5px;
                  `}
                />
                <div>
                  <Typography variant="body" color="textPrimary">
                    Frequency
                  </Typography>
                  <Typography
                    variant="footnote"
                    color="grey.800"
                    css={(theme: AppTheme) => css`
                      margin-top: ${theme.spacing(1)};
                      max-width: 350px;
                    `}
                  >
                    Choose whether this goal repeats every month or runs only
                    once
                  </Typography>
                </div>
              </div>
              <div
                css={css`
                  flex: 1;
                `}
              >
                <Controller
                  control={control}
                  name="recurring"
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      label="Frequency"
                      options={[
                        ...(goalType === AdminGoalType.BudgetUtilization
                          ? [
                              {
                                label: "Repeats monthly",
                                value: "true",
                              },
                            ]
                          : []),
                        {
                          label: "One-time",
                          value: "false",
                        },
                      ]}
                      disabled={
                        editMode &&
                        ((editTarget === "current" &&
                          defaultValues.recurring === "true") ||
                          editTarget !== "current")
                      }
                      width="full"
                      css={css`
                        max-width: 400px;
                      `}
                    />
                  )}
                />
              </div>
            </div>
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                margin-bottom: ${theme.spacing(4)};
              `}
            >
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  align-items: top;
                  gap: ${theme.spacing(2)};
                  flex: 1;
                `}
              >
                <FontAwesomeIcon
                  icon={faCalendar}
                  width={18}
                  css={css`
                    margin-top: 5px;
                  `}
                />
                <div>
                  <Typography variant="body" color="textPrimary">
                    Goal Period
                  </Typography>
                  <Typography
                    variant="footnote"
                    color="grey.800"
                    css={(theme: AppTheme) => css`
                      margin-top: ${theme.spacing(1)};
                      max-width: 350px;
                    `}
                  >
                    Choose current month to start now, including data from the
                    beginning of the month, or next month to start fresh.
                  </Typography>
                </div>
              </div>
              <div
                css={css`
                  flex: 1;
                `}
              >
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <SelectField
                      label="Goal period"
                      options={[
                        {
                          label: goalPeriod,
                          value: "current",
                        },
                        ...(nextMonthGoalPeriod
                          ? [
                              {
                                label: nextMonthGoalPeriod,
                                value: "next",
                              },
                            ]
                          : []),
                      ]}
                      disabled={editMode}
                      value={selectedPeriod}
                      onChange={(value) => {
                        // Extract the value from the event or use the value directly
                        const periodValue = value?.target?.value || value;

                        // Only update if we have a valid period value
                        if (
                          periodValue === "current" ||
                          periodValue === "next"
                        ) {
                          setSelectedPeriod(periodValue);

                          if (periodValue === "current") {
                            // Set start date to the current month
                            field.onChange(new Date(defaultValues.startDate));
                            setValue(
                              "endDate",
                              new Date(defaultValues.endDate)
                            );
                          } else if (
                            periodValue === "next" &&
                            nextMonthGoalPeriod
                          ) {
                            // Set start date to the next month
                            field.onChange(new Date(defaultValues.endDate));
                            setValue(
                              "endDate",
                              addMonths(new Date(defaultValues.endDate), 1)
                            );
                          }
                        }
                      }}
                      width="full"
                      css={css`
                        max-width: 400px;
                      `}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )}
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(2)};
              flex: 1;
            `}
          >
            <div
              css={css`
                width: 18px;
              `}
            >
              <FontAwesomeIcon icon={faDollar} />
            </div>
            <Typography variant="body" color="textPrimary">
              Reward amount per admin
            </Typography>
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <Controller
              control={control}
              name="rewardAmount"
              rules={{
                required: "Reward amount is required",
                min: {
                  value: 1,
                  message: "Reward amount must be greater than $0",
                },
                max: {
                  value: 999,
                  message: "Reward amount must be less than $1000",
                },
              }}
              render={({ fieldState }) => (
                <TextField
                  label="Reward amount"
                  type="number"
                  {...control.register("rewardAmount", {
                    validate: (value) => {
                      const numValue = Number(value);
                      if (isNaN(numValue)) {
                        return "Reward amount must be a number";
                      }

                      if (numValue > 999) {
                        return "Reward amount must be less than $1000";
                      }

                      if (Math.floor(numValue) !== numValue) {
                        return "Reward amount cannot have decimals";
                      }

                      return true;
                    },
                  })}
                  error={fieldState.error}
                  startAdornment="$"
                  disabled={rewardAmountDisabled ?? false}
                  css={css`
                    max-width: 400px;
                  `}
                />
              )}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(2)};
              flex: 1;
            `}
          >
            <FontAwesomeIcon icon={faWallet} width={18} />
            <Typography variant="body" color="textPrimary">
              {goalTitle}
            </Typography>
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <Controller
              control={control}
              name="targetPercentage"
              rules={{
                required: "Goal target is required",
                min: {
                  value: 1,
                  message: "Goal target must be greater than 0",
                },
                max: {
                  value: 100,
                  message: "Goal target must be 100 or less",
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  label="Goal"
                  type="number"
                  {...control.register("targetPercentage")}
                  error={fieldState.error}
                  endAdornment={`${field.value}%`}
                  disabled={targetPercentageDisabled ?? false}
                  css={css`
                    max-width: 400px;
                  `}
                />
              )}
            />
          </div>
        </div>
      </Card>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: right;
          margin-top: ${theme.spacing(4)};
        `}
      >
        <Button
          variant="outlined"
          size="medium"
          label="Cancel"
          linkTo="/settings/office-goals"
          onClick={() =>
            track("Cancelled configuring admin goal", {
              goalType,
            })
          }
          css={(theme: AppTheme) => css`
            width: 170px;
            margin-right: ${theme.spacing(2)};
          `}
        />
        <Button
          type="submit"
          size="medium"
          color="primary"
          label="Save"
          onClick={() => {
            track("Viewed confirmation modal to launch admin goal", {
              goalType,
              lateLaunch: isLateLaunch,
            });
          }}
          disabled={adminIds.length === 0 || !isEmpty(formState.errors)}
          css={(theme: AppTheme) => css`
            width: 170px;
          `}
        />
      </div>
      <LaunchGoalConfirmationModal
        open={startConfirmationModalOpen}
        onClose={() => {
          track("Closed confirmation modal to launch admin goal", {
            goalType,
          });
          setStartConfirmationModalOpen(false);
        }}
        onConfirm={handleSubmit(onConfirm)}
        daysLeftInPeriod={daysLeftInPeriod}
        lateLaunch={isLateLaunch}
        loading={formState.isSubmitting}
      />
      {editMode && goalConfigId && (
        <EditGoalConfirmationModal
          open={editConfirmationModalOpen}
          onClose={() => {
            track("Closed confirmation modal to edit admin goal", {
              goalType,
            });
            setEditConfirmationModalOpen(false);
          }}
          onConfirm={handleSubmit(onConfirm)}
          recurring={form.getValues("recurring") === "true"}
          setEditChangesApplyTo={setEditChangesApplyTo}
          changesSubmitting={changesSubmitting}
        />
      )}
    </Form>
  );
}
