/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { lighten } from "@material-ui/core";
import { useMatch } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { ProgressBar } from "@rewards-web/shared/components/progress-bar";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useOnboardingState } from "../../../../../pages/authenticated/onboarding/hooks/use-onboarding-state";
import { useHasPermissionQuery } from "../../../../modules/permissions/hooks/use-has-permission-query";
import ExclamationIcon from "./exclamation-icon";
import { calculateStepProgressPercentage } from "./lib/calculate-step-progress";
import RightArrow from "./right-arrow";

interface OnboardingProgressProps {
  launched: boolean | undefined;
}

export function OnboardingProgress({
  launched,
}: OnboardingProgressProps): JSX.Element | null {
  const onboardingState = useOnboardingState();
  const onOnboardingPage = !!useMatch("/onboarding/*");
  const theme = useTheme();

  const hasFullPermissionsQuery = useHasPermissionQuery(
    "full",
    "administrators"
  );

  if (launched || onboardingState.disabled) {
    return null;
  }

  const percentageCompleted = calculateStepProgressPercentage(
    Array.from(onboardingState.completedSteps),
    onboardingState.orderedSteps
  );

  if (hasFullPermissionsQuery.hasPermission === false) {
    return (
      <div
        css={(theme: AppTheme) => css`
          margin-left: ${theme.spacing(3)};
          border: 1px solid rgba(255, 255, 255, 0.3);
          text-align: center;
          height: 30px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
        `}
      >
        <ExclamationIcon
          css={(theme: AppTheme) => css`
            margin-right: ${theme.spacing(1)};
          `}
        />
        <Typography
          variant="footnote"
          align="center"
          css={css`
            font-weight: 600;
            opacity: 0.6;
          `}
        >
          Organization has not been launched yet
        </Typography>
      </div>
    );
  }

  return (
    <div
      css={(theme: AppTheme) => css`
        margin-left: ${theme.spacing(3)};
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        & > * {
          margin-left: ${theme.spacing(3)};
        }
      `}
    >
      <div
        css={css`
          width: 120px;
        `}
      >
        <Typography
          variant="body"
          css={(theme: AppTheme) => css`
            color: ${lighten(theme.palette.primary.main, 0.9)};
            font-size: 0.8rem;
            font-weight: 600;
            opacity: 0.5;
          `}
        >
          {percentageCompleted}% Complete
        </Typography>
        <ProgressBar
          barColor="background.default"
          value={percentageCompleted}
          borderRadius={2}
        />
      </div>
      {!onOnboardingPage && (
        <Button
          css={css`
            justify-content: space-between;
          `}
          minWidthPx={140}
          whiteBackground
          textColor="primary"
          endIcon={<RightArrow color={theme.palette.primary.main} />}
          linkTo="/onboarding"
          label="Complete Onboarding"
          size="small"
          width="auto"
        />
      )}
    </div>
  );
}
