/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "@rewards-web/shared/components/icon-button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

interface DownloadReportSuccessModalProps {
  open: boolean;
  onClose(): void;

  minutesToDownloadEstimation: number;
}

export function DownloadReportSuccessModal({
  open,
  onClose,
  minutesToDownloadEstimation,
}: DownloadReportSuccessModalProps) {
  const theme = useTheme();

  return (
    <Modal open={open} onClose={onClose} width="634px" disablePadding>
      <ModalContent disablePadding>
        <div
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(8)} ${theme.spacing(5)};
          `}
        >
          <IconButton
            onClick={onClose}
            aria-label="Cancel"
            css={(theme: AppTheme) => css`
              position: absolute;
              left: ${theme.spacing(1)};
              top: ${theme.spacing(1)};
            `}
          >
            <FontAwesomeIcon icon={faClose} width="20px" height="20px" />
          </IconButton>

          <FontAwesomeIcon
            icon={faCheckCircle}
            color={theme.palette.success.main}
            size="4x"
            css={(theme: AppTheme) => css`
              display: block;
              margin: 0 auto;
              margin-bottom: ${theme.spacing(3)};
            `}
          />
          <Typography
            variant="h2"
            color="textPrimary"
            align="center"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            Success!
          </Typography>
          <Typography variant="body" color="grey.800" align="center">
            Your report(s) are on their way to your email as a CSV file. Please
            allow up to {minutesToDownloadEstimation}{" "}
            {minutesToDownloadEstimation === 1 ? "minute" : "minutes"} for
            larger reports to be ready.
          </Typography>
        </div>
      </ModalContent>
    </Modal>
  );
}
