/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useForm, useWatch } from "react-hook-form";

import { Form } from "@rewards-web/shared/components/form";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  JobPostingLocationField,
  RewardsOrganization,
} from "@rewards-web/shared/graphql-types";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { PageCardActions } from "../../../../../../../shared/components/page-card/page-card-actions";
import { PageCardContent } from "../../../../../../../shared/components/page-card/page-card-content";
import { JobPostingPreview } from "../../../../../../../shared/modules/settings/components/job-posting-preview";
import { SettingsJobPostingBannerImageField } from "../../../../../../../shared/modules/settings/fields/settings-job-posting-banner-image-field";
import { SettingsJobPostingLocationField } from "../../../../../../../shared/modules/settings/fields/settings-job-posting-location-field";
import { SettingsJobPostingLogoImageField } from "../../../../../../../shared/modules/settings/fields/settings-job-posting-logo-image";
import { SettingsJobPostingThemeColorField } from "../../../../../../../shared/modules/settings/fields/settings-job-posting-theme-color-field";
import { useUpdateJobPostingSettingsMutation } from "./update-job-posting-settings.generated";

export interface JobPostingFormValues {
  themeColor: string;
  jobPostingLocationField: JobPostingLocationField;
  bannerImage: File | string | null;
  logoImage: File | string | null;
}

interface JobPostingCardContentProps {
  organizationInfo: {
    __typename?: "RewardsOrganization" | undefined;
  } & Pick<
    RewardsOrganization,
    | "themeJobPostingBannerImageUrl"
    | "themeSquareLogoUrl"
    | "themePrimaryColor"
    | "jobPostingLocationField"
  >;
}

export function JobPostingCardContent({
  organizationInfo,
}: JobPostingCardContentProps): JSX.Element {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const track = useTrack();
  const [updateJobPostingSettings] = useUpdateJobPostingSettingsMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<JobPostingFormValues>({
    defaultValues: {
      themeColor: (
        organizationInfo.themePrimaryColor ?? theme.palette.primary.main
      ).replaceAll("#", ""),
      bannerImage: organizationInfo.themeJobPostingBannerImageUrl,
      logoImage: organizationInfo.themeSquareLogoUrl,
      jobPostingLocationField: organizationInfo.jobPostingLocationField,
    },
  });

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    isDirty
  );

  const themeColor = useWatch({ control, name: "themeColor" });
  const logoImage = useWatch({ control, name: "logoImage" });
  const bannerImage = useWatch({ control, name: "bannerImage" });
  const jobPostingLocationField = useWatch({
    control,
    name: "jobPostingLocationField",
  });

  const onSubmit = async (values: JobPostingFormValues) => {
    try {
      const res = await updateJobPostingSettings({
        variables: {
          jobPostingBannerImage:
            values.bannerImage instanceof File ? values.bannerImage : undefined,
          jobPostingLogoImage:
            values.logoImage instanceof File ? values.logoImage : undefined,
          jobPostingThemeColor: `#${values.themeColor}`,
          jobPostingLocationField: values.jobPostingLocationField,
        },
      });
      track("Job posting settings updated", {
        settingsCard: "Job Postings",
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
      });
      snackbar.show({
        severity: "success",
        message: "Job postings settings have been updated",
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} submitting={isSubmitting}>
      <PageCardContent maxWidth="1250px">
        <div
          css={css`
            display: grid;
            grid-template-columns: 45% 55%;
            grid-column-gap: ${theme.spacing(1)};
          `}
        >
          <div>
            <Typography
              css={css`
                padding: ${theme.spacing(2)};
                margin-bottom: ${theme.spacing(2)};
              `}
              variant="subtitle"
            >
              JOB POSTING PAGE
            </Typography>
            <div
              css={(theme: AppTheme) => css`
                padding: 0 ${theme.spacing(2)};
              `}
            >
              <SettingsJobPostingBannerImageField
                control={control}
                name="bannerImage"
              />
              <SettingsJobPostingLogoImageField
                control={control}
                name="logoImage"
              />
              <SettingsJobPostingLocationField
                control={control}
                name="jobPostingLocationField"
              />
              <SettingsJobPostingThemeColorField
                control={control}
                name="themeColor"
              />
            </div>
          </div>
          <div>
            <Typography
              css={css`
                padding: ${theme.spacing(2)};
              `}
              variant="subtitle"
            >
              PREVIEW
            </Typography>

            <div
              css={css`
                padding: ${theme.spacing(2)};
              `}
            >
              <JobPostingPreview
                themeColor={themeColor}
                logoImage={logoImage}
                bannerImage={bannerImage}
                locationField={jobPostingLocationField}
              />
            </div>
          </div>
        </div>
        <PageCardActions disabled={isSubmitting} />
      </PageCardContent>
    </Form>
  );
}
