import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PulseSurveyFormCreateUpdateMutationVariables = Types.Exact<{
  pulseCheckSurveyGoalDistributionConfig: Types.PulseCheckSurveyGoalDistributionConfigAdminInput;
  updateRewardOverrideGoalUpdatePolicy?: Types.Maybe<Types.GoalRewardUpdatePolicy>;
}>;


export type PulseSurveyFormCreateUpdateMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateMyOrganizationPulseCheckSurveyGoalsConfig: (
    { __typename?: 'PulseCheckSurveyGoalDistributionConfig' }
    & Pick<Types.PulseCheckSurveyGoalDistributionConfig, 'id' | 'surveyDaysUntilExpirationOverride'>
    & { surveyCadenceOverride?: Types.Maybe<(
      { __typename?: 'PulseCheckSurveyCadenceOverride' }
      & Pick<Types.PulseCheckSurveyCadenceOverride, 'frequency' | 'startDate'>
    )> }
  ) }
);


export const PulseSurveyFormCreateUpdateDocument = gql`
    mutation PulseSurveyFormCreateUpdate($pulseCheckSurveyGoalDistributionConfig: PulseCheckSurveyGoalDistributionConfigAdminInput!, $updateRewardOverrideGoalUpdatePolicy: GoalRewardUpdatePolicy) {
  partialUpdateMyOrganizationPulseCheckSurveyGoalsConfig(
    pulseSurveyConfig: $pulseCheckSurveyGoalDistributionConfig
    updateRewardOverrideGoalUpdatePolicy: $updateRewardOverrideGoalUpdatePolicy
  ) {
    id
    surveyCadenceOverride {
      frequency
      startDate
    }
    surveyDaysUntilExpirationOverride
  }
}
    `;
export type PulseSurveyFormCreateUpdateMutationFn = Apollo.MutationFunction<PulseSurveyFormCreateUpdateMutation, PulseSurveyFormCreateUpdateMutationVariables>;

/**
 * __usePulseSurveyFormCreateUpdateMutation__
 *
 * To run a mutation, you first call `usePulseSurveyFormCreateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePulseSurveyFormCreateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pulseSurveyFormCreateUpdateMutation, { data, loading, error }] = usePulseSurveyFormCreateUpdateMutation({
 *   variables: {
 *      pulseCheckSurveyGoalDistributionConfig: // value for 'pulseCheckSurveyGoalDistributionConfig'
 *      updateRewardOverrideGoalUpdatePolicy: // value for 'updateRewardOverrideGoalUpdatePolicy'
 *   },
 * });
 */
export function usePulseSurveyFormCreateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PulseSurveyFormCreateUpdateMutation, PulseSurveyFormCreateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PulseSurveyFormCreateUpdateMutation, PulseSurveyFormCreateUpdateMutationVariables>(PulseSurveyFormCreateUpdateDocument, options);
      }
export type PulseSurveyFormCreateUpdateMutationHookResult = ReturnType<typeof usePulseSurveyFormCreateUpdateMutation>;
export type PulseSurveyFormCreateUpdateMutationResult = Apollo.MutationResult<PulseSurveyFormCreateUpdateMutation>;
export type PulseSurveyFormCreateUpdateMutationOptions = Apollo.BaseMutationOptions<PulseSurveyFormCreateUpdateMutation, PulseSurveyFormCreateUpdateMutationVariables>;