import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";

import { PageLayout } from "../../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { ForbiddenErrorPage } from "../../../../../shared/modules/permissions/components/forbidden-error-page";
import { useSurveySettingsEnabled } from "../shared/hooks/use-survey-settings-enabled";
import { PulseSurveyForm } from "./pulse-survey-form";

const MAX_CONTENT_WIDTH = 1400;

export function PulseSurveySettingsPage() {
  const {
    surveySettingsEnabled,
    surveySettingsLoading,
  } = useSurveySettingsEnabled();

  if (surveySettingsLoading) {
    return <PageLoadingState />;
  }

  if (!surveySettingsEnabled) {
    return <ForbiddenErrorPage />;
  }

  return (
    <AuthorizedAdminPageWrapper resource="surveys" level="full">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <PulseSurveyForm />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
