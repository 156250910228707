import { useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";

import { PageLayout } from "../../../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { UpdateBranchPageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1400;

export function UpdateBranchPage(): JSX.Element {
  const params = useParams<"branch_id">();

  const branchId = params.branch_id;

  if (!branchId) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  return (
    <AuthorizedAdminPageWrapper resource="branches">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <UpdateBranchPageContents branchId={params.branch_id} />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
