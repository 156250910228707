/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";

interface NavigationBarOrganizationNameProps {
  shortName: string;
}

export function NavigationBarOrganizationName({
  shortName,
}: NavigationBarOrganizationNameProps) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <Typography display="inline" variant="h6" component="span">
        {shortName}
      </Typography>
    </div>
  );
}
