/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Control, FieldValues, Path } from "react-hook-form";

import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { EVVRuleTimeField } from "../evv-rule-time-field";
import { EVVRuleName } from "../evv-rules-form";

interface EVVRuleRowProps<T extends FieldValues> {
  icon: JSX.Element;
  title: string;
  control: Control<T>;
  name: EVVRuleName;
  tooltipText: string;
  fieldNames: {
    minutesLessThanScheduledStart: Path<T>;
    minutesGreaterThanScheduledStart: Path<T>;
    minutesLessThanScheduledEnd: Path<T>;
    minutesGreaterThanScheduledEnd: Path<T>;
    minutesLessThanScheduledDuration: Path<T>;
    minutesGreaterThanScheduledDuration: Path<T>;
    selectedClockMethods: Path<T>;
  };
}

export function EVVRuleRow<T extends FieldValues>({
  icon,
  title,
  control,
  name,
  tooltipText,
  fieldNames,
}: EVVRuleRowProps<T>) {
  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: ${theme.spacing(1)};
      `}
    >
      <Typography
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
          align-items: center;
          flex-basis: 35%;
          display: flex;
        `}
        color="textSecondary"
        variant="body"
      >
        {icon}
        {title}
        <div
          css={(theme: AppTheme) => css`
            margin-left: ${theme.spacing(1)};
          `}
        >
          <Tooltip title={tooltipText} placement="top">
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        </div>
      </Typography>
      <div
        css={(theme: AppTheme) => css`
          flex-grow: 1;
          flex-basis: 0;
          min-width: 80px;
        `}
      >
        <EVVRuleTimeField
          control={control}
          name={fieldNames[`minutesLessThan${name}`]}
        />
      </div>
      <div
        css={(theme: AppTheme) => css`
          flex-grow: 1;
          flex-basis: 0;
          min-width: 80px;
        `}
      >
        <EVVRuleTimeField
          control={control}
          name={fieldNames[`minutesGreaterThan${name}`]}
        />
      </div>
    </div>
  );
}
