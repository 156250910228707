/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { AppTheme } from "@rewards-web/shared/style/theme";

interface EditAdminNavigationTabPanelProps {
  enabled: boolean;
  children?: React.ReactNode;
}

export function EditAdminNavigationTabPanel({
  enabled,
  children,
}: EditAdminNavigationTabPanelProps): JSX.Element {
  return (
    <div role="tabpanel" hidden={!enabled}>
      {enabled && (
        <div
          css={(theme: AppTheme) => css`
            max-width: 861px;
            padding: ${theme.spacing(3)};
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          {children}
        </div>
      )}
    </div>
  );
}
