import { ReactNode, useEffect } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import { PermissionLevel } from "../hooks/lib";
import { useHasPermissionQuery } from "../hooks/use-has-permission-query";
import { AdminPermissionType } from "../types";
import { ForbiddenErrorPage } from "./forbidden-error-page";

interface AdminAuthorizationWrapperProps {
  resource: AdminPermissionType;
  children: ReactNode;
  level?: PermissionLevel;
}

export function AuthorizedAdminPageWrapper({
  resource,
  children,
  level = "read",
}: AdminAuthorizationWrapperProps): JSX.Element {
  const track = useTrack();
  const hasPermission = useHasPermissionQuery(level, resource);

  const showForbiddenPage = hasPermission.hasPermission === false;

  useEffect(() => {
    if (showForbiddenPage) {
      track("Viewed forbidden page", { resource });
    }
  }, [track, showForbiddenPage, resource]);

  if (hasPermission.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later"
      />
    );
  }

  if (hasPermission.hasPermission === undefined) {
    return <PageLoadingState />;
  }

  if (showForbiddenPage) {
    return <ForbiddenErrorPage />;
  }

  return <>{children}</>;
}
