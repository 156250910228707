/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FormControlLabel, Radio } from "@material-ui/core";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

export interface ModalRadioOptionProps {
  value: string;
  title: string;
  description: string;
  selected: boolean;
  onClick?: (value: string) => void;
}

export function ModalRadioOption({
  value,
  title,
  description,
  selected,
  onClick,
}: ModalRadioOptionProps) {
  const cardStyles = (theme: AppTheme) => css`
    margin: 0;
    align-items: flex-start;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    border-color: ${selected
      ? theme.palette.primary.main
      : theme.palette.grey[400]};
    padding: 16px 8px;
    margin-bottom: ${theme.spacing(2)};
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
  `;

  return (
    <FormControlLabel
      value={value}
      css={cardStyles}
      aria-label={`Select ${title.toLowerCase()}`}
      onClick={() => onClick?.(value)}
      control={
        <Radio
          color="primary"
          checked={selected}
          css={css`
            top: -8px;
          `}
        />
      }
      label={
        <div>
          <Typography
            css={css`
              font-weight: bold;
            `}
          >
            {title}
          </Typography>
          <Typography>{description}</Typography>
        </div>
      }
    />
  );
}
