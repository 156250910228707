/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

export function JobsHeader() {
  return (
    <Typography
      variant="h3"
      color="textPrimary"
      css={(theme: AppTheme) => css`
        margin-bottom: ${theme.spacing(4)};
      `}
    >
      Jobs
    </Typography>
  );
}
