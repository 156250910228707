import {
  CandidateRecruitmentStepName,
  NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment,
  NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention,
  CandidateRecruitmentStepFilterOption,
  CandidateMonthlyRetentionStepFilterOption,
  CandidateHourlyRetentionStepFilterOption,
} from "@rewards-web/shared/graphql-types";

export function getStepLabel(
  step:
    | ({ __typename?: "CandidateRecruitmentStepFilterOption" } & Pick<
        CandidateRecruitmentStepFilterOption,
        "stepName"
      >)
    | ({ __typename?: "CandidateMonthlyRetentionStepFilterOption" } & Pick<
        CandidateMonthlyRetentionStepFilterOption,
        "durationMonths"
      >)
    | ({ __typename?: "CandidateHourlyRetentionStepFilterOption" } & Pick<
        CandidateHourlyRetentionStepFilterOption,
        "durationHours"
      >)
) {
  switch (step.__typename) {
    case "CandidateRecruitmentStepFilterOption": {
      switch (step.stepName) {
        case CandidateRecruitmentStepName.Contacted:
          return "Contacted?";
        case CandidateRecruitmentStepName.InterviewScheduled:
          return "Scheduled?";
        case CandidateRecruitmentStepName.InterviewSuccessful:
          return "Offer extended?";
        case CandidateRecruitmentStepName.Hired:
          return "Hired?";
        case CandidateRecruitmentStepName.StartedWork:
          return "Started working?";
        case CandidateRecruitmentStepName.StartedOrientation:
          return "Started orientation?";
        case CandidateRecruitmentStepName.CompletedOrientation:
          return "Completed orientation?";
        case CandidateRecruitmentStepName.CompletedFirstShift:
          return "Completed first shift?";
        default:
          return `${step.stepName}`;
      }
    }
    case "CandidateMonthlyRetentionStepFilterOption":
      return `Worked for ${step.durationMonths}mos?`;
    case "CandidateHourlyRetentionStepFilterOption":
      return `Worked for ${step.durationHours}hrs?`;
    default: {
      throw new Error("Could not recognize step type");
    }
  }
}

export function getCandidatesFilterPathFromStep(
  step:
    | ({
        __typename: "NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment";
      } & Pick<
        NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment,
        "recruitmentStepName"
      >)
    | ({
        __typename: "NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention";
      } & Pick<
        NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention,
        "retentionDurationMonths"
      >),
  jobPostingId: string
) {
  switch (step.__typename) {
    case "NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment": {
      return `/candidates?${new URLSearchParams({
        nextStepIn: step.recruitmentStepName,
        archived: "false",
        jobs: jobPostingId,
      })}`;
    }
    case "NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention": {
      return `/candidates?${new URLSearchParams({
        nextStepIn: `${step.retentionDurationMonths}_months`,
        archived: "false",
        jobs: jobPostingId,
      })}`;
    }
    default: {
      throw new Error("Could not recognize step type");
    }
  }
}
