/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDays, format } from "date-fns";
import { useForm } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextButton } from "@rewards-web/shared/components/text-button";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  // JobPostingScreenerQuestion,
  RewardsOrganizationBranch,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { FormFieldOutline } from "../../../../../../components/content-container";
import {
  RightDrawerActions,
  RightDrawerContent,
} from "../../../../../../components/right-drawer";
import { RightDrawerBaseCard } from "../../../../../../components/right-drawer/right-drawer-base-card";
import { usePermissionLimitedBranchIds } from "../../../../../branches/use-permission-limited-branch-ids";
import { JobBranchField } from "../../../../fields/job-branch-field";
import { JobCandidateAlertEmailField } from "../../../../fields/job-candidate-alert-email-field";
import { JobCustomEmailField } from "../../../../fields/job-custom-email-field";
import { JobCustomSMSField } from "../../../../fields/job-custom-sms-field";
import { JobDescriptionField } from "../../../../fields/job-description-field";
import { JobHoursRequiredField } from "../../../../fields/job-hours-required-field";
import { JobInterviewBookingLinkField } from "../../../../fields/job-interview-booking-link-field";
import { JobLocationField } from "../../../../fields/job-location-field";
import { JobRateOfPayField } from "../../../../fields/job-rate-of-pay-field";
import { JobReferralStructureField } from "../../../../fields/job-referral-structure-field";
import { JobScreenerQuestionsField } from "../../../../fields/job-screener-questions";
import { JobTitleField } from "../../../../fields/job-title-field";
import { JobPostingEditDetailsFragment } from "../../../../graphql/job-posting-fragment.generated";
import { getJobPostingDefaultValues } from "../../../../lib/get-job-posting-default-values";
import { JobScreenerQuestionValue } from "../../../../typings";

export interface EditJobFormValues {
  branchId: string | null;
  referralStructureId: string;
  rateOfPay: string;
  location: string;
  hoursRequired: string;
  jobTitle: string;
  jobDescription: string;
  screenerQuestions: JobScreenerQuestionValue[];

  candidateAlertEmail: string;
  customizedEmailMessage: string;
  customizedSMSMessage: string;
  customizedSMSMessageChecked: boolean;
  customizedEmailMessageChecked: boolean;
  interviewBookingLink: string;
  interviewBookingLinkChecked: boolean;
  mustUploadResumeBeforeBookingInterview: "YES" | "NO";
}

interface EditJobFormProps {
  onSubmit(values: EditJobFormValues): Promise<void>;
  onClose(): void;
  onDuplicateJobClicked(): void;
  jobPosting: JobPostingEditDetailsFragment;
  adminEmailOptions: string[];
  readOnly: boolean;
  saveDescription: string;
  organizationBranches: Pick<RewardsOrganizationBranch, "id" | "name">[];
}

export function EditJobForm({
  onSubmit,
  onClose,
  onDuplicateJobClicked,
  jobPosting,
  adminEmailOptions,
  readOnly,
  saveDescription,
  organizationBranches,
}: EditJobFormProps) {
  const track = useTrack();
  const { data } = usePermissionLimitedBranchIds();
  const form = useForm<EditJobFormValues>({
    defaultValues: getJobPostingDefaultValues(jobPosting),
  });
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const organizationHasBranches =
    organizationBranches.length > 0 && !data?.canOnlySeeBranchIds;

  // referral structure can only be edited when it was imported from an integration,
  // and it has been less than 1 week since the job posting was published
  const referralStructureEditDeadline = addDays(
    new Date(jobPosting.publishedAt),
    7
  );
  const canEditReferralStructure =
    jobPosting.importedFromIntegration &&
    new Date() < referralStructureEditDeadline;

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      submitting={isSubmitting}
      readOnly={readOnly}
      css={css`
        display: contents;
      `}
    >
      <RightDrawerContent>
        <div
          css={(theme: AppTheme) => css`
            padding-top: ${theme.spacing(1.5)};
            padding-left: ${theme.spacing(1.5)};
            padding-right: ${theme.spacing(1.5)};
          `}
        >
          <JobTitleField control={form.control} name="jobTitle" />
          {organizationHasBranches && (
            <JobBranchField control={form.control} name="branchId" />
          )}
          <JobLocationField control={form.control} name="location" />
          <JobRateOfPayField control={form.control} name="rateOfPay" />
          <JobHoursRequiredField control={form.control} name="hoursRequired" />
          <FormFieldOutline
            label="Description"
            name="job-description"
            divider={false}
          >
            <JobDescriptionField control={control} name="jobDescription" />
          </FormFieldOutline>
          <FormFieldOutline
            label="Referral structure"
            name="referral-structure"
            divider={false}
          >
            <JobReferralStructureField
              control={control}
              name="referralStructureId"
              readOnly={!canEditReferralStructure}
            />
            <Alert
              severity="info"
              title="Can I add or edit a referral structure later?"
              message={
                canEditReferralStructure ? (
                  <>
                    You can edit this job's referral structure until{" "}
                    {format(
                      new Date(referralStructureEditDeadline),
                      "MMM d, yyyy"
                    )}
                    . After this date, the referral structure will be locked to
                    maintain a seamless experience and ensure data accuracy.
                  </>
                ) : (
                  <>
                    This job is locked with your choice of referral structure to
                    maintain a seamless experience and ensure data accuracy. To
                    make changes to the referral amounts, you can{" "}
                    <TextButton
                      type="button"
                      css={(theme: AppTheme) => css`
                        color: ${theme.palette.primary.main};
                        text-decoration: none;
                      `}
                      onClick={() => {
                        onDuplicateJobClicked();
                        track("Edit job referral structure duplicate clicked", {
                          jobPostingId: jobPosting.id,
                        });
                      }}
                    >
                      duplicate
                    </TextButton>{" "}
                    this job and add a different referral structure.
                  </>
                )
              }
              messageTypographyVariant="footnote"
              customIcon={
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  color="#444"
                  size="xs"
                  css={css`
                    margin-top: 3px;
                  `}
                />
              }
              css={(theme: AppTheme) => css`
                margin-top: ${theme.spacing(1)};
                margin-bottom: ${theme.spacing(2)};
              `}
            />
          </FormFieldOutline>

          <FormFieldOutline
            label="Screening Questions"
            name="screener-questions"
            divider={false}
            description={`Feel free to add your own custom questions. All questions must be formatted as yes-no questions, as answers are not customizable.`}
          >
            <JobScreenerQuestionsField
              control={control}
              name="screenerQuestions"
            />
          </FormFieldOutline>

          <FormFieldOutline
            label="Notification preferences"
            name="alert-preferences"
            divider={false}
            description="Who should receive email alerts about new candidates?"
          >
            <JobCandidateAlertEmailField
              control={control}
              name="candidateAlertEmail"
              emailAutocompleteOptions={adminEmailOptions}
            />
          </FormFieldOutline>

          <FormFieldOutline
            label="Customization"
            name="customization"
            divider={false}
          >
            <JobCustomEmailField
              name="customizedEmailMessage"
              checkBoxName="customizedEmailMessageChecked"
              control={control}
            />
            <JobCustomSMSField
              name="customizedSMSMessage"
              checkBoxName="customizedSMSMessageChecked"
              control={form.control}
            />

            <JobInterviewBookingLinkField
              name="interviewBookingLink"
              checkBoxName="interviewBookingLinkChecked"
              mustUploadResumeFieldName="mustUploadResumeBeforeBookingInterview"
              control={control}
            />
          </FormFieldOutline>
        </div>

        {!readOnly && (
          <RightDrawerBaseCard>
            <Typography variant="body">{saveDescription}</Typography>
          </RightDrawerBaseCard>
        )}
      </RightDrawerContent>

      <RightDrawerActions>
        <Button onClick={onClose} variant="outlined" label="Cancel" />
        <Button type="submit" color="primary" label="Save" />
      </RightDrawerActions>
    </Form>
  );
}
