/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";
import { Control, FieldValues, get, Path, useFormState } from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";

interface ReferralRewardNumDrawTicketsFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  min: number;
  max: number;
  hideSpaceForErrorText?: boolean;
  size?: "small" | "medium";
  readOnly?: boolean;
  helperText?: ReactNode;
}

export function ReferralRewardNumDrawTicketsField<T extends FieldValues>({
  control,
  name,
  min,
  max,
  hideSpaceForErrorText,
  size = "small",
  readOnly,
  helperText,
}: ReferralRewardNumDrawTicketsFieldProps<T>) {
  const formState = useFormState({ control });

  return (
    <TextField
      css={css`
        width: 290px;
      `}
      {...control.register(name, {
        required: "This field is required",
        validate: (value) => {
          if (value) {
            const valueConvertedToNumber = extractNumber(value);
            if (valueConvertedToNumber < min || valueConvertedToNumber > max) {
              return `Value should be in the range of ${min} and ${max} draw tickets`;
            }
          }
        },
      })}
      label="Draw tickets"
      hideLabel
      endAdornment={
        <Typography variant="body" fontWeight={600} color="textPrimary">
          Draw ticket(s)
        </Typography>
      }
      helperText={helperText}
      error={get(formState.errors, name)}
      size={size}
      hideSpaceForErrorText={hideSpaceForErrorText}
      readOnly={readOnly}
      greyReadOnly
    />
  );
}
