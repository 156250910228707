import { ApolloError } from "@apollo/client";

import { AdminPermissionType } from "../../types";
import { PermissionLevel } from "../lib";
import { usePermissionsQuery } from "../use-permissions-query";

export function useHasPermissionQuery(
  level: PermissionLevel,
  resource: AdminPermissionType
): {
  hasPermission: boolean | undefined;
  loading?: boolean;
  error?: ApolloError;
} {
  const { hasPermission, ...query } = usePermissionsQuery();

  return {
    ...query,
    hasPermission: hasPermission(level, resource),
  };
}
