/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faPlus,
  faPeopleArrows,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { keys } from "lodash";
import {
  ArrayPath,
  Control,
  Controller,
  FieldValues,
  useFieldArray,
} from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { AppTheme } from "@rewards-web/shared/style/types";

import { TagsSelectorField } from "./tags-selector-field";

export const DEFAULT_VALUE = [{}];

const MAX_ALLOWED_ADD_TAG_COMBINATIONS = 10;
export const TAG_CONFIG_RIGHT_ADORNMENT_WIDTH = 52; // px

interface TagsConfigFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: ArrayPath<T>;
}

export function TagsConfigField<T extends FieldValues>({
  control,
  name,
}: TagsConfigFieldProps<T>): JSX.Element {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {fields.map((field, index) => (
        <div
          key={field.id}
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {index > 0 && (
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                margin: ${theme.spacing(3.5, 0)};
                &::before,
                &::after {
                  flex: 1;
                  content: "";
                  background-color: ${theme.palette.divider};
                  padding: ${theme.spacing(0.1)};
                  margin: ${theme.spacing(0.2)};
                }
              `}
            >
              <div
                css={(theme: AppTheme) =>
                  css`
                    padding: ${theme.spacing(0, 5)};
                  `
                }
              >
                <FontAwesomeIcon icon={faPeopleArrows} />
                &nbsp;
                <span>
                  <b>OR</b>
                </span>
              </div>
            </div>
          )}

          <div
            css={css`
              display: flex;
            `}
          >
            <Controller
              control={control}
              name={`${name}.${index}` as any}
              rules={{
                validate: (value: any[]) => {
                  const isEmptyObj = (obj: any) => keys(obj).length === 0;

                  const isEmpty =
                    (Array.isArray(value) ? value : [value]).filter(
                      (obj) => !isEmptyObj(obj)
                    ).length === 0;

                  return isEmpty
                    ? "Combination must have at least one tag"
                    : undefined;
                },
              }}
              render={({ field, fieldState }) => (
                <TagsSelectorField
                  {...field}
                  css={css`
                    flex-grow: 1;
                  `}
                  error={fieldState.error}
                />
              )}
            />
            <div
              css={css`
                height: 56px;
                display: flex;
                align-items: center;
                width: ${TAG_CONFIG_RIGHT_ADORNMENT_WIDTH}px;
                justify-content: flex-end;
              `}
            >
              <IconButton
                aria-label="delete condition"
                onClick={() => remove(index)}
                css={(theme: AppTheme) =>
                  css`
                    color: ${theme.palette.grey[800]};
                  `
                }
              >
                <FontAwesomeIcon icon={faTrash} size="xs" />
              </IconButton>
            </div>
          </div>
        </div>
      ))}

      {fields.length < MAX_ALLOWED_ADD_TAG_COMBINATIONS && (
        <div
          css={css`
            padding-right: ${TAG_CONFIG_RIGHT_ADORNMENT_WIDTH}px;
          `}
        >
          <Button
            css={(theme: AppTheme) => css`
              margin: ${theme.spacing(2, 0)};
              padding: ${theme.spacing(1.25, 0)};
            `}
            variant="outlined"
            width="full"
            size="medium"
            label="Add condition"
            type="button"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => append(DEFAULT_VALUE as any)}
          />
        </div>
      )}
    </>
  );
}
