/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import {
  AdminGoal,
  AdminGoalStatus,
  AdminGoalType,
} from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

import { BaseGoalCard } from "../base-goal-card";
import coinsIcon from "../icons/coins.png";

export interface BudgetUtilizationGoalCardProps {
  goal: Pick<
    AdminGoal,
    "type" | "status" | "rewards" | "startDate" | "endDate" | "tracker"
  >;
  pointsPerDollar: number;
}

export function BudgetUtilizationGoalCard({
  goal: { type, status, rewards, startDate, endDate, tracker },
  pointsPerDollar,
}: BudgetUtilizationGoalCardProps) {
  const track = useTrack();

  if (
    type !== AdminGoalType.BudgetUtilization ||
    tracker.__typename !== "BudgetUtilizationAdminGoalTracker"
  ) {
    return null;
  }

  const {
    targetPercentage,
    totalPointsBudget,
    totalPointsSent: pointsSentProp,
    progressUpdatedAt,
  } = tracker;

  const reward = (() => {
    switch (rewards.__typename) {
      case "AdminGoalRewardsGiftCard":
        return {
          type: "gift_card" as const,
          dollarValue: rewards.dollarValue,
        };
      case "AdminGoalRewardsPayroll":
        return {
          type: "payroll" as const,
          dollarValue: rewards.dollarValue,
        };
      default:
        return null;
    }
  })();

  // Handle when `pointsSentProp` is `null` after the goal is initially created,
  // by setting progress to 0
  const totalPointsSent = pointsSentProp ?? 0;

  const progressPercentage =
    typeof totalPointsBudget === "number" && totalPointsBudget > 0
      ? (totalPointsSent / totalPointsBudget) * 100
      : null;

  const progressText = (() => {
    switch (status) {
      case AdminGoalStatus.Available: {
        if (typeof totalPointsBudget !== "number" || totalPointsBudget <= 0) {
          return null;
        }

        const targetPointsSent = (targetPercentage / 100) * totalPointsBudget;
        const pointsRemaining = targetPointsSent - totalPointsSent;

        if (pointsRemaining <= 0) {
          return "You've reached your target goal!";
        }

        return `Use ${formatDollars(
          pointsRemaining / pointsPerDollar
        )} more to reach your target goal`;
      }
      case AdminGoalStatus.Achieved: {
        const defaultText =
          "You accomplished this month's recognition budget utilization goal!";

        if (!reward) {
          return defaultText;
        }

        switch (reward.type) {
          case "gift_card":
            return "What's next: you will receive an email from Tango (gift card provider) within 48 hours with the link to redeem your gift card.";
          case "payroll":
            return "What's next: your reward will be applied to your next paycheck.";
          default:
            return defaultText;
        }
      }
      default:
        return null;
    }
  })();

  return (
    <>
      <BaseGoalCard
        icon={<img src={coinsIcon} alt="coins" width={66} />}
        title="Recognition budget utilization goal"
        startDate={new Date(startDate)}
        endDate={new Date(endDate)}
        reward={reward}
        goalStatus={status}
        goalType={type}
        goalProgress={{
          targetPercentage,
          progressPercentage,
          progressLastUpdated: progressUpdatedAt
            ? new Date(progressUpdatedAt)
            : null,
          progressText,
          progressTooltip: null, // no tooltip for budget utilization goal
          targetType: "individual", // recognition goals are always individual
        }}
        tipsContent={{
          title: "Tips for hitting your recognition goals",
          action: (
            <Button
              variant="outlined"
              label="Give recognition"
              linkTo="/recognition/give-points"
              linkState={{ backTo: "/goals" }}
              size="small"
              width="auto"
              css={(theme: AppTheme) => css`
                border-color: ${theme.palette.grey[600]};
              `}
              onClick={() => {
                track("Clicked to give recognition from admin goal card");
              }}
            />
          ),
          tips: [
            "Make it a daily habit to use your recognition budget - integrate it into your morning or end-of-day routine.",
            "Recognize employees who model the behaviours you want to see more of on the team; for example, properly documenting client notes during shifts.",
            "Use recognition to share positive feedback you receive from clients or families for great care.",
          ],
        }}
      />
    </>
  );
}
