/** @jsxImportSource @emotion/react */
import {
  FieldValues,
  Control,
  Controller,
  Path,
  useWatch,
} from "react-hook-form";

import { SelectField } from "@rewards-web/shared/components/select-field";

export type SurveySettingsRewardType = "points" | "tickets" | null;

interface SurveySettingsRewardTypeProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  disabled?: boolean;
}

export function SurveySettingsRewardTypeField<T extends FieldValues>({
  control,
  name,
  disabled,
}: SurveySettingsRewardTypeProps<T>) {
  const value = useWatch({ control, name });
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: () => {
          if (!disabled && !value) {
            return "Survey reward is required";
          }
        },
      }}
      render={({ field, fieldState }) => {
        const options: {
          label: string;
          value: NonNullable<SurveySettingsRewardType>;
        }[] = [
          {
            label: "Points",
            value: "points",
          },
          {
            label: "Tickets",
            value: "tickets",
          },
        ];

        return (
          <SelectField
            {...field}
            label="Survey reward"
            error={fieldState.error}
            width="full"
            options={options}
            disabled={disabled}
          />
        );
      }}
    />
  );
}
