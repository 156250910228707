/** @jsxImportSource @emotion/react */
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { EVVRulesFormValues } from "../../../onboarding/pages/evv";
import { EVVRulesForm } from "../../../onboarding/pages/evv/evv-rules-form";
import {
  convertValidTimeToNumber,
  mapSelectedClockMethods,
} from "../../../onboarding/pages/evv/lib";
import { useUpdateOrganizationEvvRulesMutation } from "./update-evv-rules.generated";

export function SettingsEVVRulesPage() {
  const snackbar = useSnackbar();
  const track = useTrack();

  const [updateOrganizationEVVRules] = useUpdateOrganizationEvvRulesMutation();

  const onSubmit = async (values: EVVRulesFormValues) => {
    const newRules = values.enabledEVVRules
      ? {
          minutesLessThanScheduledStart: convertValidTimeToNumber(
            values.minutesLessThanScheduledStart
          ),
          minutesGreaterThanScheduledStart: convertValidTimeToNumber(
            values.minutesGreaterThanScheduledStart
          ),
          minutesLessThanScheduledEnd: convertValidTimeToNumber(
            values.minutesLessThanScheduledEnd
          ),
          minutesGreaterThanScheduledEnd: convertValidTimeToNumber(
            values.minutesGreaterThanScheduledEnd
          ),
          minutesLessThanScheduledDuration: convertValidTimeToNumber(
            values.minutesLessThanScheduledDuration
          ),
          minutesGreaterThanScheduledDuration: convertValidTimeToNumber(
            values.minutesGreaterThanScheduledDuration
          ),
          validClockMethods: mapSelectedClockMethods(
            values.selectedClockMethods
          ),
        }
      : {
          minutesLessThanScheduledStart: null,
          minutesGreaterThanScheduledStart: null,
          minutesLessThanScheduledEnd: null,
          minutesGreaterThanScheduledEnd: null,
          minutesLessThanScheduledDuration: null,
          minutesGreaterThanScheduledDuration: null,
          validClockMethods: null,
        };

    try {
      const res = await updateOrganizationEVVRules({
        variables: { newRules },
      });

      snackbar.show({
        severity: "success",
        message: "Clock-In/Out compliance has been updated.",
      });

      track("Organization settings saved", {
        settingsCard: "EVV Compliance",
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
    }
  };

  return (
    <>
      <EVVRulesForm onSubmit={onSubmit} />
    </>
  );
}
