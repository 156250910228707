import { useMediaQuery } from "@material-ui/core";

import { Tooltip } from "@rewards-web/shared/components/tooltip";

const TRUNCATE_AT_CHAR_COUNT_MOBILE = 100;
const TRUNCATE_AT_CHAR_COUNT = 150;

interface TransactionDescriptionTextProps {
  description: string;
}

export function TransactionDescriptionText({
  description,
}: TransactionDescriptionTextProps) {
  const smallerScreen = useMediaQuery("(max-width: 1200px)");
  const truncateAtCharCount = smallerScreen
    ? TRUNCATE_AT_CHAR_COUNT_MOBILE
    : TRUNCATE_AT_CHAR_COUNT;

  const shouldTruncate = description.length > truncateAtCharCount;
  const truncatedDescription = shouldTruncate
    ? `${description.slice(0, truncateAtCharCount)}...`
    : description;

  return (
    <Tooltip title={description} disabled={!shouldTruncate}>
      {truncatedDescription}
    </Tooltip>
  );
}
