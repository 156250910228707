/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useLocation, useMatch } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { Role } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useGiveRecognitionPageDataQuery } from "./give-recognition-page-data.generated";
import { GiveRecognitionPaths } from "./give-recognition-tabs";
import {
  RecognitionCategoriesDataQueryVariables,
  useRecognitionCategoriesDataQuery,
} from "./recognition-categories-data.generated";
import { SearchEmployeesRecognitionForm } from "./search-employees-recognition-form";
import { UploadBulkRecognitionFileForm } from "./upload-bulk-recognition-file-form";

export function GiveRecognitionPointsPageContents(): JSX.Element {
  const bulkUploadMatch = useMatch(GiveRecognitionPaths.UploadFile);
  const { state } = useLocation();

  const query = useGiveRecognitionPageDataQuery({
    onError: reportError,
  });

  // ensure this is only set when the page is loaded
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const backTo = useMemo(() => state?.backTo ?? "/recognition", []);

  const isFullAccessAdmin =
    query.data?.myAdmin?.permissions.__typename ===
    "RewardsAdminFullAccessPermissions";

  const isSuperuser = query.data?.myIdentity
    ? query.data.myIdentity.roles.some((role: { roleName: Role }) =>
        [Role.Superuser, Role.RewardsSuperuser].includes(role.roleName)
      )
    : false;

  const recognitionCategoriesQueryVariables: RecognitionCategoriesDataQueryVariables = !isSuperuser
    ? { disableSendFromManagers: false }
    : {};
  const recognitionCategoriesQuery = useRecognitionCategoriesDataQuery({
    variables: recognitionCategoriesQueryVariables,
    onError: reportError,
  });

  const showBulkUploadForm =
    bulkUploadMatch && (isFullAccessAdmin || isSuperuser);

  if (query.error || recognitionCategoriesQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!query.data || !recognitionCategoriesQuery.data) {
    return <PageLoadingState />;
  }

  return (
    <>
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        linkTo={backTo}
        width="auto"
        typographyVariant="body"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />
      <Typography
        variant="h3"
        component="h2"
        color="textPrimary"
        fontWeight={700}
      >
        Give recognition points
      </Typography>
      <Typography
        variant="body"
        color="textPrimary"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        Send a message and recognition points to your superstar employee(s)!
      </Typography>
      {showBulkUploadForm ? (
        <UploadBulkRecognitionFileForm
          data={query.data}
          categoriesData={recognitionCategoriesQuery.data}
        />
      ) : (
        <SearchEmployeesRecognitionForm
          data={query.data}
          categoriesData={recognitionCategoriesQuery.data}
          isFullAccessAdmin={isFullAccessAdmin}
          isSuperuser={isSuperuser}
        />
      )}
    </>
  );
}
