/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faArrowTurnRight,
  faClock,
  faCheckToSlot,
  faChalkboardUser,
  faBriefcase,
  faCalendarCheck,
  faUsers,
  faIdBadge,
  faCircleDollar,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Control,
  Path,
  FieldValues,
  useFieldArray,
  ArrayPath,
  useWatch,
} from "react-hook-form";

import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableHeaders } from "@rewards-web/shared/components/table-components/table-headers";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  CandidateRecruitmentStepName,
  ReferralRewardStructureDrawTicketReward,
  ReferralRewardStructureJobShareItem,
  ReferralRewardStructurePointReward,
} from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ReferralRewardNumDrawTicketsField } from "../../../../../../../../shared/components/edit-referral-structure-form-content/referral-reward-num-draw-ticket-field";
import { ReferralRewardPointValueField } from "../../../../../../../../shared/components/edit-referral-structure-form-content/referral-reward-point-value-field";
import { InfoIcon } from "../../../../../../admins/icons/info-icon";
import { ReferralStructureFormStep } from "../types";

function getMinMaxPointValue(step: ReferralStructureFormStep) {
  switch (step.type) {
    case "recruitment": {
      switch (step.step) {
        case CandidateRecruitmentStepName.ApplicationSubmitted:
          return { min: 10, max: 99_999 };
        case CandidateRecruitmentStepName.InterviewSuccessful:
          return { min: 10, max: 99_999 };
        case CandidateRecruitmentStepName.StartedWork:
          return { min: 200, max: 99_999 };
        case CandidateRecruitmentStepName.StartedOrientation:
          return { min: 200, max: 99_999 };
        case CandidateRecruitmentStepName.CompletedOrientation:
          return { min: 200, max: 99_999 };
        case CandidateRecruitmentStepName.CompletedFirstShift:
          return { min: 1_000, max: 99_999 };
        default:
          return { min: 1_000, max: 99_999 };
      }
    }

    case "retention": {
      switch (step.unit) {
        case "hours":
          return { min: 1_000, max: 99_999 };
        case "months":
          return { min: 1_000, max: 99_999 };
        default:
          assertNever(step.unit);
      }
      break;
    }

    default:
      assertNever(step);
  }
}

interface ReferralStructureTableProps<TFormValues extends FieldValues> {
  control: Control<TFormValues>;
  name: ArrayPath<TFormValues>;
  jobShareFieldName: Path<TFormValues>;
  totalsVariant: "withinTable" | "alert";
  jobShareStructure: {
    __typename?: "ReferralRewardStructureJobShareItem";
  } & Pick<
    ReferralRewardStructureJobShareItem,
    "maxAwardableJobSharesPerMonth"
  > & {
      reward:
        | ({ __typename?: "ReferralRewardStructurePointReward" } & Pick<
            ReferralRewardStructurePointReward,
            "pointValue"
          >)
        | ({ __typename?: "ReferralRewardStructureDrawTicketReward" } & Pick<
            ReferralRewardStructureDrawTicketReward,
            "numTickets"
          >)
        | { __typename?: "ReferralRewardStructureManualMonthlyRaffleReward" };
    };
  pointsPerDollar: number;
  viewMode?: boolean;
  shouldShowJobShareStructure?: boolean;
}

export function ReferralStructureTable<TFormValues extends FieldValues>({
  control,
  name,
  jobShareFieldName,
  pointsPerDollar,
  viewMode = false,
  jobShareStructure,
  totalsVariant,
}: ReferralStructureTableProps<TFormValues>) {
  const stepsArray = useFieldArray({ control, name }) as {
    fields: (ReferralStructureFormStep & { id: string })[];
  };
  const steps = useWatch<any>({ control, name }) as ReferralStructureFormStep[];

  const tableTextColor = viewMode ? "textPrimary" : "grey.800";

  const shouldShowJobShareStructure = (() => {
    if (!jobShareStructure) {
      return false;
    }

    switch (jobShareStructure.reward.__typename) {
      case "ReferralRewardStructurePointReward":
        return jobShareStructure.reward.pointValue > 0;
      case "ReferralRewardStructureDrawTicketReward":
        return true;
      default:
        return false;
    }
  })();

  const totalRewards =
    steps.reduce((acc, step) => acc + extractNumber(step.pointValue), 0) +
    (jobShareStructure.reward.__typename ===
    "ReferralRewardStructurePointReward"
      ? jobShareStructure.reward.pointValue
      : 0);

  const totalRewardsInDollars = totalRewards / pointsPerDollar;

  const totalRewardsToReferringEmployee = (
    <Typography
      variant={viewMode ? "subtitle" : "body"}
      color={
        viewMode || totalsVariant === "alert" ? "text.primary" : "grey.800"
      }
    >
      <FontAwesomeIcon
        icon={faCircleDollar}
        css={(theme: AppTheme) => css`
          margin-right: ${theme.spacing(1)};
        `}
      />{" "}
      Total rewards to referring employee
    </Typography>
  );

  const totalRewardsToReferringEmployeeValue = (
    <Typography variant="subtitle" color="text.primary">
      {numberWithCommas(totalRewards)} points (
      {formatDollars(totalRewardsInDollars)})
    </Typography>
  );

  return (
    <>
      <Table border={viewMode}>
        <TableHeaders>
          <TableRow>
            <TableHeader
              rightDivider={viewMode}
              disableStartAndEndPadding={viewMode}
            >
              <Typography variant="subtitle" color="textPrimary">
                Step of new hire
              </Typography>
            </TableHeader>
            <TableHeader disableStartAndEndPadding={viewMode}>
              <Typography variant="subtitle" color="textPrimary">
                Rewards given
              </Typography>
            </TableHeader>
          </TableRow>
        </TableHeaders>
        <TableBody>
          {/* Global job share structure is not editable, but we show it */}
          {shouldShowJobShareStructure && (
            <TableRow>
              <TableCell
                divider
                rightDivider={viewMode}
                disableStartAndEndPadding={viewMode}
              >
                <div
                  css={(theme: AppTheme) => css`
                    display: flex;
                    gap: ${theme.spacing(1)};
                    align-items: center;
                  `}
                >
                  <FontAwesomeIcon
                    css={(theme: AppTheme) => css`
                      color: ${viewMode
                        ? theme.palette.text.primary
                        : theme.palette.grey[800]};
                      margin-left: 4px;
                      margin-right: 6px;
                    `}
                    size="1x"
                    icon={faArrowTurnRight}
                  />

                  <div>
                    <Typography variant="body" color={tableTextColor}>
                      Job shared
                    </Typography>
                    {!viewMode && (
                      <Typography variant="footnote" color="grey.800">
                        Reward value for this step is a global setting.{" "}
                        <a href="mailto:help@caribou.care">Contact us</a> to
                        change it.
                      </Typography>
                    )}
                  </div>
                </div>
              </TableCell>
              <TableCell divider disableStartAndEndPadding={viewMode}>
                {viewMode && (
                  <>
                    <Typography variant="body" color="textPrimary">
                      {jobShareStructure.reward.__typename ===
                        "ReferralRewardStructurePointReward" &&
                        `${numberWithCommas(
                          jobShareStructure.reward.pointValue
                        )} points`}
                      {jobShareStructure.reward.__typename ===
                        "ReferralRewardStructureDrawTicketReward" &&
                        `${numberWithCommas(
                          jobShareStructure.reward.numTickets
                        )} Draw ticket(s)`}{" "}
                      (Limit of{" "}
                      {jobShareStructure.maxAwardableJobSharesPerMonth} per
                      month)
                    </Typography>
                  </>
                )}
                {!viewMode && (
                  <>
                    {jobShareStructure.reward.__typename ===
                      "ReferralRewardStructurePointReward" && (
                      <ReferralRewardPointValueField
                        control={control}
                        name={jobShareFieldName}
                        pointsPerDollar={pointsPerDollar}
                        min={1}
                        max={1000}
                        showExplanationTooltip={false}
                        hideSpaceForErrorText
                        size="medium"
                        readOnly
                        helperText={
                          <div
                            css={(theme: AppTheme) => css`
                              display: flex;
                              align-items: center;
                              margin-left: ${theme.spacing(-1.8)};
                              gap: ${theme.spacing(1)};
                            `}
                          >
                            <InfoIcon />
                            <Typography
                              variant="body"
                              fontWeight={400}
                              color="grey.800"
                            >
                              Limit of{" "}
                              {jobShareStructure.maxAwardableJobSharesPerMonth}{" "}
                              per month
                            </Typography>
                          </div>
                        }
                      />
                    )}
                    {jobShareStructure.reward.__typename ===
                      "ReferralRewardStructureDrawTicketReward" && (
                      <ReferralRewardNumDrawTicketsField
                        control={control}
                        name={jobShareFieldName}
                        min={1}
                        max={1000}
                        hideSpaceForErrorText
                        size="medium"
                        readOnly
                        helperText={
                          <div
                            css={(theme: AppTheme) => css`
                              display: flex;
                              align-items: center;
                              margin-left: ${theme.spacing(-1.8)};
                              gap: ${theme.spacing(1)};
                            `}
                          >
                            <InfoIcon />
                            <Typography
                              variant="body"
                              fontWeight={400}
                              color="grey.800"
                            >
                              Limit of{" "}
                              {jobShareStructure.maxAwardableJobSharesPerMonth}{" "}
                              per month
                            </Typography>
                          </div>
                        }
                      />
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
          )}

          {stepsArray.fields.map((field, index) => (
            <TableRow key={field.id}>
              <TableCell
                divider
                rightDivider={viewMode}
                disableStartAndEndPadding={viewMode}
              >
                <Typography variant="body" color={tableTextColor}>
                  <FontAwesomeIcon
                    css={(theme: AppTheme) => css`
                      margin-right: ${theme.spacing(1)};
                    `}
                    icon={(() => {
                      switch (field.type) {
                        case "recruitment": {
                          switch (field.step) {
                            case CandidateRecruitmentStepName.ApplicationSubmitted:
                              return faUsers;
                            case CandidateRecruitmentStepName.InterviewSuccessful:
                              return faCalendarCheck;
                            case CandidateRecruitmentStepName.StartedWork:
                              return faBriefcase;
                            case CandidateRecruitmentStepName.StartedOrientation:
                              return faChalkboardUser;
                            case CandidateRecruitmentStepName.CompletedOrientation:
                              return faCheckToSlot;
                            case CandidateRecruitmentStepName.CompletedFirstShift:
                              return faClock;
                            default:
                              return faCheckToSlot;
                          }
                        }

                        case "retention": {
                          return faIdBadge;
                        }

                        default:
                          assertNever(field);
                      }
                    })()}
                  />{" "}
                  {(() => {
                    switch (field.type) {
                      case "recruitment": {
                        switch (field.step) {
                          case CandidateRecruitmentStepName.ApplicationSubmitted:
                            return "Candidate expresses interest";
                          case CandidateRecruitmentStepName.InterviewSuccessful:
                            return "Interview successful";
                          case CandidateRecruitmentStepName.StartedWork:
                            return "Started work";
                          case CandidateRecruitmentStepName.StartedOrientation:
                            return "Started orientation";
                          case CandidateRecruitmentStepName.CompletedOrientation:
                            return "Completed orientation";
                          case CandidateRecruitmentStepName.CompletedFirstShift:
                            return "Completed first shift";
                          default:
                            return field.step;
                        }
                      }

                      case "retention": {
                        switch (field.unit) {
                          case "hours":
                            return `${numberWithCommas(
                              field.amount
                            )} hours worked`;
                          case "months":
                            return `${numberWithCommas(
                              field.amount
                            )} months worked`;
                          default:
                            return field.unit;
                        }
                      }
                    }
                  })()}
                </Typography>
              </TableCell>
              <TableCell
                divider
                disableStartAndEndPadding={viewMode}
                css={
                  !viewMode &&
                  css`
                    width: 0.1%;
                    white-space: nowrap;
                  `
                }
              >
                {viewMode && (
                  <Typography variant="body" color={tableTextColor}>
                    {field.pointValue} points (
                    {formatDollars(
                      extractNumber(field.pointValue) / pointsPerDollar
                    )}
                    )
                  </Typography>
                )}
                {!viewMode && (
                  <ReferralRewardPointValueField
                    name={`${name}.${index}.pointValue` as any}
                    control={control}
                    pointsPerDollar={pointsPerDollar}
                    min={getMinMaxPointValue(field).min}
                    max={getMinMaxPointValue(field).max}
                    showExplanationTooltip={false}
                    hideSpaceForErrorText
                    size="medium"
                  />
                )}
              </TableCell>
            </TableRow>
          ))}

          {totalsVariant === "withinTable" && (
            <TableRow backgroundColor={viewMode ? "#f5f9fa" : undefined}>
              <TableCell
                divider={viewMode}
                rightDivider={viewMode}
                disableStartAndEndPadding={viewMode}
              >
                {totalRewardsToReferringEmployee}
              </TableCell>
              <TableCell
                divider={viewMode}
                disableStartAndEndPadding={viewMode}
                align={viewMode ? undefined : "right"}
              >
                {totalRewardsToReferringEmployeeValue}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {totalsVariant === "alert" && (
        <div
          css={(theme: AppTheme) => css`
            background-color: #f5f9fa;
            border-radius: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: ${theme.spacing(1.5)};
            margin-top: ${theme.spacing(2)};
          `}
        >
          {totalRewardsToReferringEmployee}
          {totalRewardsToReferringEmployeeValue}
        </div>
      )}
    </>
  );
}
