import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OnboardingListJobsDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OnboardingListJobsDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar'>
  ), listJobPostings: (
    { __typename?: 'JobPostingListResponse' }
    & Pick<Types.JobPostingListResponse, 'total'>
  ) }
);


export const OnboardingListJobsDataDocument = gql`
    query OnboardingListJobsData {
  getMyRewardsOrganization {
    id
    pointsPerDollar
  }
  listJobPostings(limit: 0, offset: 0, filter: {closed: false}) {
    total
  }
}
    `;

/**
 * __useOnboardingListJobsDataQuery__
 *
 * To run a query within a React component, call `useOnboardingListJobsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingListJobsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingListJobsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingListJobsDataQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingListJobsDataQuery, OnboardingListJobsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingListJobsDataQuery, OnboardingListJobsDataQueryVariables>(OnboardingListJobsDataDocument, options);
      }
export function useOnboardingListJobsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingListJobsDataQuery, OnboardingListJobsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingListJobsDataQuery, OnboardingListJobsDataQueryVariables>(OnboardingListJobsDataDocument, options);
        }
export type OnboardingListJobsDataQueryHookResult = ReturnType<typeof useOnboardingListJobsDataQuery>;
export type OnboardingListJobsDataLazyQueryHookResult = ReturnType<typeof useOnboardingListJobsDataLazyQuery>;
export type OnboardingListJobsDataQueryResult = Apollo.QueryResult<OnboardingListJobsDataQuery, OnboardingListJobsDataQueryVariables>;