import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type BonusPointsUserSearchQueryVariables = Types.Exact<{
  searchQuery?: Types.Maybe<Types.Scalars['String']>;
  filter?: Types.Maybe<Types.SearchForRewardsUsersFilterInput>;
}>;


export type BonusPointsUserSearchQuery = (
  { __typename?: 'Query' }
  & { searchForRewardsUsers: Array<(
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName' | 'workEmail' | 'workPhoneNumber' | 'active'>
    & { personalContactInfo?: Types.Maybe<(
      { __typename: 'RewardsUserPersonalContactInfoData' }
      & Pick<Types.RewardsUserPersonalContactInfoData, 'id' | 'email' | 'phoneNumber'>
    ) | (
      { __typename: 'RewardsUserPersonalContactInfoSuppressed' }
      & Pick<Types.RewardsUserPersonalContactInfoSuppressed, 'id'>
    )>, branch?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationBranch' }
      & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
    )> }
  )> }
);


export const BonusPointsUserSearchDocument = gql`
    query BonusPointsUserSearch($searchQuery: String, $filter: SearchForRewardsUsersFilterInput) {
  searchForRewardsUsers(searchQuery: $searchQuery, limit: 30, filter: $filter) {
    id
    firstName
    lastName
    personalContactInfo {
      __typename
      ... on RewardsUserPersonalContactInfoData {
        id
        email
        phoneNumber
      }
      ... on RewardsUserPersonalContactInfoSuppressed {
        id
      }
    }
    workEmail
    workPhoneNumber
    active
    branch {
      id
      name
    }
  }
}
    `;

/**
 * __useBonusPointsUserSearchQuery__
 *
 * To run a query within a React component, call `useBonusPointsUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusPointsUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonusPointsUserSearchQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBonusPointsUserSearchQuery(baseOptions?: Apollo.QueryHookOptions<BonusPointsUserSearchQuery, BonusPointsUserSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BonusPointsUserSearchQuery, BonusPointsUserSearchQueryVariables>(BonusPointsUserSearchDocument, options);
      }
export function useBonusPointsUserSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BonusPointsUserSearchQuery, BonusPointsUserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BonusPointsUserSearchQuery, BonusPointsUserSearchQueryVariables>(BonusPointsUserSearchDocument, options);
        }
export type BonusPointsUserSearchQueryHookResult = ReturnType<typeof useBonusPointsUserSearchQuery>;
export type BonusPointsUserSearchLazyQueryHookResult = ReturnType<typeof useBonusPointsUserSearchLazyQuery>;
export type BonusPointsUserSearchQueryResult = Apollo.QueryResult<BonusPointsUserSearchQuery, BonusPointsUserSearchQueryVariables>;