/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { AdminOnboardingCard } from "../../../components/onboarding-card/onboarding-card";

export interface BookKickoffCompletedProps {
  someStepsNotCompleted: boolean;
}

export function BookKickoffCompleted({
  someStepsNotCompleted,
}: BookKickoffCompletedProps) {
  return (
    <AdminOnboardingCard
      css={(theme: AppTheme) => css`
        width: 100%;
        padding: ${theme.spacing(4)} ${theme.spacing(5)};
        ${theme.breakpoints.up("lg")} {
          padding: ${theme.spacing(10)} ${theme.spacing(12)};
        }
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Typography
        css={(appTheme: AppTheme) =>
          css`
            margin-bottom: ${appTheme.spacing(1)};
            text-align: center;
          `
        }
        variant="h3"
        component="h1"
      >
        {someStepsNotCompleted ? "Almost there!" : "Yay, all done! 🥳"}
      </Typography>
      <Typography
        variant="footnote"
        color="text.secondary"
        css={(appTheme: AppTheme) =>
          css`
            text-align: center;
            margin-bottom: ${appTheme.spacing(4)};
          `
        }
      >
        {someStepsNotCompleted
          ? "Please ensure you've completed all required steps to avoid delaying your launch"
          : "Thank you for completing your onboarding steps"}
      </Typography>
      <Card
        backgroundColor="grey"
        css={(appTheme: AppTheme) =>
          css`
            padding: ${appTheme.spacing(3.75)};
            display: flex;
            flex-direction: column;
            align-items: start;
          `
        }
        variant="flat"
      >
        <Typography
          css={(appTheme: AppTheme) => css`
            margin-bottom: ${appTheme.spacing(2)};
            font-weight: 600;
          `}
          variant="subtitle"
        >
          What's next:
        </Typography>
        <Typography
          variant="body"
          color="text.primary"
          css={(appTheme: AppTheme) => css`
            margin-bottom: ${appTheme.spacing(2)};
          `}
        >
          Our Customer Success team will reach out to confirm your launch date
          and next steps.
        </Typography>
        <Typography variant="body" color="text.primary">
          We are excited to get your rewards program launched!
        </Typography>
      </Card>
    </AdminOnboardingCard>
  );
}
