/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Chip } from "@material-ui/core";
import { sortBy } from "lodash";
import { useEffect, useMemo } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsTag } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";
import { ControlledFormFieldProps } from "@rewards-web/shared/types";

import {
  MultiSelectAutocomplete,
  MultiSelectAutocompleteOption,
} from "./multi-select-autocomplete";
import { useTagsSelectorListQuery } from "./tags-selector-list.generated";

const TAGS_LIMIT = 2000;

export type TagsCombinationsValue = {
  label: string;
  value: string;
};

export const getTagOption = (
  tag: Pick<RewardsTag, "id" | "externalId" | "activeUserCount" | "displayName">
): TagsCombinationsValue => ({
  value: tag.id,
  label: `${tag.displayName ?? tag.externalId} (${tag.activeUserCount})`,
});

export function TagsSelectorField(props: ControlledFormFieldProps) {
  const tagsQuery = useTagsSelectorListQuery({
    onError: reportError,
    variables: {
      offset: 0,
      limit: TAGS_LIMIT,
    },
  });

  const totalTags = tagsQuery.data?.listRewardsTags.total;
  useEffect(() => {
    if (typeof totalTags === "number" && totalTags > TAGS_LIMIT) {
      reportError(
        new Error(
          `Warning -- organization with > ${TAGS_LIMIT} tags; only top ${TAGS_LIMIT} tags shown in TagsSelectorField`
        )
      );
    }
  }, [totalTags]);

  const options = useMemo(
    () =>
      sortBy(
        tagsQuery.data?.listRewardsTags.items.map((tag) => getTagOption(tag)) ??
          [],
        (option) => option.label
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagsQuery.loading]
  );

  if (tagsQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!tagsQuery.data) {
    return <PageLoadingState />;
  }

  return (
    <MultiSelectAutocomplete
      label="Tags"
      size="medium"
      options={options}
      css={css`
        border-radius: 8px;
      `}
      placeholder="Type to search tags"
      renderTags={(value: readonly unknown[], getTagProps) => {
        const tags = (value as MultiSelectAutocompleteOption[][]).flatMap(
          (options) => options
        );
        return (
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              column-gap: ${theme.spacing(0.5)};
              flex-wrap: wrap;
            `}
          >
            {tags.map((tag: MultiSelectAutocompleteOption, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              const isLastElement = index === tags.length - 1;
              return [
                <Chip
                  label={<Typography>{tag.label}</Typography>}
                  key={key}
                  {...tagProps}
                />,
                ...(isLastElement
                  ? []
                  : [
                      <div
                        css={css`
                          align-self: center;
                        `}
                      >
                        <Typography>and</Typography>
                      </div>,
                    ]),
              ];
            })}
          </div>
        );
      }}
      {...props}
    />
  );
}
