/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";

import {
  EvvRulesClockMethod,
  RewardsOrganizationOnboardingStepName,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { useMarkOnboardingStepCompletedMutation } from "../../hooks/mark-step-completed.generated";
import { useNextOnboardingStepPath } from "../../hooks/use-next-step-path";
import { EVVRulesForm } from "./evv-rules-form";
import { convertValidTimeToNumber, mapSelectedClockMethods } from "./lib";
import { useUpdateOrganizationEvvRulesMutation } from "./update-evv-rules.generated";

export interface EVVRules {
  minutesLessThanScheduledStart: string;
  minutesGreaterThanScheduledStart: string;
  minutesLessThanScheduledEnd: string;
  minutesGreaterThanScheduledEnd: string;
  minutesLessThanScheduledDuration: string;
  minutesGreaterThanScheduledDuration: string;
}

export type EVVRulesClockMethods = Exclude<
  EvvRulesClockMethod,
  EvvRulesClockMethod.SystemAutoClock | EvvRulesClockMethod.Unknown
>;

export interface EVVRulesFormValues extends EVVRules {
  selectedClockMethods: {
    [key in EVVRulesClockMethods]: boolean;
  };
  enabledEVVRules: boolean;
}

export function OnboardingEVVRulesPage() {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const track = useTrack();

  const nextStepPath = useNextOnboardingStepPath();

  const nextOnboardingStepPath = useNextOnboardingStepPath();

  const [markStepCompleted] = useMarkOnboardingStepCompletedMutation({
    onError: (e) => {
      reportError(e);
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    },
    onCompleted: () => {
      if (nextStepPath) {
        navigate(nextStepPath);
      }
    },
  });

  const [updateOrganizationEVVRules] = useUpdateOrganizationEvvRulesMutation();

  const onSubmit = async (values: EVVRulesFormValues) => {
    const newRules = values.enabledEVVRules
      ? {
          minutesLessThanScheduledStart: convertValidTimeToNumber(
            values.minutesLessThanScheduledStart
          ),
          minutesGreaterThanScheduledStart: convertValidTimeToNumber(
            values.minutesGreaterThanScheduledStart
          ),
          minutesLessThanScheduledEnd: convertValidTimeToNumber(
            values.minutesLessThanScheduledEnd
          ),
          minutesGreaterThanScheduledEnd: convertValidTimeToNumber(
            values.minutesGreaterThanScheduledEnd
          ),
          minutesLessThanScheduledDuration: convertValidTimeToNumber(
            values.minutesLessThanScheduledDuration
          ),
          minutesGreaterThanScheduledDuration: convertValidTimeToNumber(
            values.minutesGreaterThanScheduledDuration
          ),
          validClockMethods: mapSelectedClockMethods(
            values.selectedClockMethods
          ),
        }
      : {
          minutesLessThanScheduledStart: null,
          minutesGreaterThanScheduledStart: null,
          minutesLessThanScheduledEnd: null,
          minutesGreaterThanScheduledEnd: null,
          minutesLessThanScheduledDuration: null,
          minutesGreaterThanScheduledDuration: null,
          validClockMethods: null,
        };

    try {
      const res = await updateOrganizationEVVRules({
        variables: { newRules },
      });
      track("Organization settings saved", {
        settingsCard: "EVV Compliance",
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
      });
      await markStepCompleted({
        variables: {
          step: RewardsOrganizationOnboardingStepName.EvvCompliance,
        },
      });

      if (!nextOnboardingStepPath) {
        throw new Error("No next onboarding step path");
      }

      navigate(nextOnboardingStepPath);
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
    }
  };

  return (
    <>
      <EVVRulesForm onSubmit={onSubmit} />
    </>
  );
}
