/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { getSurveyQuestionMetadata } from "../../../../shared/constants/survey-question-metadata";
import { SurveyQuestionTypeSupportedByUI } from "../constants";

export function QuestionCategory({
  questionType,
  variant,
}: {
  questionType: SurveyQuestionTypeSupportedByUI;
  variant?: Parameters<typeof Typography>[0]["variant"];
}) {
  const theme = useTheme();

  const { category, icon } = getSurveyQuestionMetadata(questionType);

  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing(0.5)};
        margin-bottom: ${theme.spacing(0.5)};
      `}
    >
      <FontAwesomeIcon icon={icon} color={theme.palette.grey[800]} />
      <Typography
        variant={variant ?? "caption"}
        color={theme.palette.grey[800]}
        fontWeight={600}
      >
        {category.toUpperCase()}
      </Typography>
    </div>
  );
}
