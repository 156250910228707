import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { reportError } from "@rewards-web/shared/modules/error";

import { useTimezoneFieldOptionsQuery } from "./timezone-field-options.generated";

interface SettingsOrganizationTimezoneFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  isRequired?: boolean;
  loading?: boolean;
}

export function SettingsOrganizationTimezoneField<T extends FieldValues>({
  control,
  name,
  isRequired,
  loading,
}: SettingsOrganizationTimezoneFieldProps<T>) {
  const optionsQuery = useTimezoneFieldOptionsQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: isRequired ? "Time zone is required" : false,
      }}
      render={({ field, fieldState }) => (
        <SearchTextField
          {...field}
          error={fieldState.error}
          label="Time zone"
          options={(optionsQuery.data?.getTimezoneOptions ?? []).map(
            (timezone) => ({
              value: timezone.id,
              label: timezone.currentTimeFormat,
            })
          )}
          loadingOptions={loading || optionsQuery.loading}
        />
      )}
    />
  );
}
