/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import assert from "assert";
import { weeksToDays } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { compact } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  ErrorCode,
  GoalDistributionUpdatePolicy,
  GoalRewardUpdatePolicy,
  QuickCheckSurveyCadenceFrequency,
  QuickCheckSurveyGoalDistributionConfigAdminInput,
  SurveyQuestionType,
  SurveyType,
} from "@rewards-web/shared/graphql-types";
import { useDrawerControl } from "@rewards-web/shared/hooks/use-drawer-control";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { hasGraphqlError } from "@rewards-web/shared/lib/has-graphql-error";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageCard } from "../../../../../../shared/components/page-card";
import { CHECK_IN_SURVEY_QUESTIONS } from "../../../constants";
import { SurveySettingsErrorModal } from "../../shared/components/error-modal";
import { SurveySettingsActiveField } from "../../shared/components/fields/active";
import { SurveySettingsFrequencyField } from "../../shared/components/fields/frequency";
import { SurveySettingsResponseTypeField } from "../../shared/components/fields/response-type";
import {
  SurveySettingsRewardType,
  SurveySettingsRewardTypeField,
} from "../../shared/components/fields/reward-type";
import { SurveySettingsRewardValueField } from "../../shared/components/fields/reward-value";
import { SurveySettingsStartDateField } from "../../shared/components/fields/start-date";
import { SurveySettingsDurationField } from "../../shared/components/fields/survey-duration";
import { SurveyFormRow } from "../../shared/components/survey-form-row";
import {
  SurveySettingsUpdateModal,
  SurveySettingsUpdatePolicy,
} from "../../shared/components/update-modal";
import {
  CheckInSurveyFormQuestions,
  CheckInSurveyQuestionsField,
} from "../components/fields/questions";
import { CheckInSurveyLengthField } from "../components/fields/survey-length";
import {
  CheckInSurveyFormCreateUpdateMutationVariables,
  useCheckInSurveyFormCreateUpdateMutation,
} from "./check-in-survey-form-create-update.generated";
import { useCheckInSurveyFormDataQuery } from "./check-in-survey-form-data.generated";
import { useCheckInSurveyFormDisableMutation } from "./check-in-survey-form-disable.generated";

const MAX_WIDTH = 1400; //px

export type CheckInSurveyFormType = {
  active: boolean;
  frequency: QuickCheckSurveyCadenceFrequency;
  startDate: string | null;
  duration: number;
  rewardType: SurveySettingsRewardType | "";
  rewardValue: string;
  anonymousSubmission: boolean;
  numQuestionsPerSurvey: number;
  questions: CheckInSurveyFormQuestions;
  // use this for both the reward update policy and cancellation policy
  updatePolicy: SurveySettingsUpdatePolicy | undefined;
};

const DEFAULT_NUM_QUESTIONS_PER_SURVEY = 4;

const DEFAULT_WEEKLY_SURVEY_DURATION_DAYS = weeksToDays(1);
const DEFAULT_BIWEEKLY_SURVEY_DURATION_DAYS = weeksToDays(2);

const DEFAULT_REQUIRED_SURVEY_QUESTIONS = [
  SurveyQuestionType.QuickCheckWeeklySentiment,
];

const DEFAULT_VALUES: CheckInSurveyFormType = {
  active: false,
  frequency: QuickCheckSurveyCadenceFrequency.Biweekly,
  startDate: null,
  duration: DEFAULT_BIWEEKLY_SURVEY_DURATION_DAYS,
  rewardType: "" as const,
  rewardValue: "",
  updatePolicy: undefined,
  anonymousSubmission: false,
  numQuestionsPerSurvey: DEFAULT_NUM_QUESTIONS_PER_SURVEY,
  questions: Object.fromEntries(
    // By default, one required question and all others are rotating
    CHECK_IN_SURVEY_QUESTIONS.map((question) =>
      DEFAULT_REQUIRED_SURVEY_QUESTIONS.includes(question)
        ? [question, "required"]
        : [question, "rotating"]
    )
  ) as CheckInSurveyFormQuestions,
};

export function CheckInSurveyForm(): JSX.Element {
  const {
    control,
    reset,
    watch,
    getValues,
    clearErrors,
    setValue,
    formState: { isSubmitting, isDirty },
    handleSubmit,
    trigger: triggerValidation,
  } = useForm<CheckInSurveyFormType>({
    defaultValues: DEFAULT_VALUES,
  });

  const {
    active: isActive,
    frequency,
    duration,
    numQuestionsPerSurvey,
  } = watch();

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    isDirty
  );

  const navigate = useNavigate();
  const { state } = useLocation();
  // ensure this is only set when the page is loaded
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const backTo = useMemo(() => state?.backTo ?? "/survey-settings", []);

  /**
   * Clear field validation errors when isActive changes,
   * those errors probably no longer apply when isActive is changed
   */
  useEffect(() => {
    clearErrors();
  }, [clearErrors, isActive]);

  /**
   * Ensure duration is always set to 7 days for weekly surveys,
   * since that is the only option.
   */
  useEffect(() => {
    if (
      frequency === QuickCheckSurveyCadenceFrequency.Weekly &&
      duration !== DEFAULT_WEEKLY_SURVEY_DURATION_DAYS
    ) {
      setValue("duration", DEFAULT_WEEKLY_SURVEY_DURATION_DAYS);
    }
  }, [frequency, duration, setValue]);

  const [policyModalOpen, setPolicyModalOpen] = useState(false);
  const [_errorModalState, setErrorModalState] = useState<
    { title: string; subtitle: string } | undefined
  >(undefined);
  const [errorModalState, errorModalStateControl] = useDrawerControl(
    _errorModalState
  );
  const { data: queryData, error } = useCheckInSurveyFormDataQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const goalsEnabled = !!data.getMyOrganizationGoalConfig?.enabled;
      const config =
        data.getMyOrganizationGoalConfig
          ?.quickCheckSurveyGoalDistributionConfig;

      if (config) {
        const frequency =
          config.surveyCadenceOverride?.frequency ?? DEFAULT_VALUES.frequency;
        // default duration is 7 days for weekly surveys and 14 days for bi-weekly surveys
        const defaultDuration =
          frequency === QuickCheckSurveyCadenceFrequency.Weekly
            ? DEFAULT_WEEKLY_SURVEY_DURATION_DAYS
            : DEFAULT_BIWEEKLY_SURVEY_DURATION_DAYS;

        const formValues: CheckInSurveyFormType = {
          active: goalsEnabled && config.enabled,
          frequency,
          startDate: config.surveyCadenceOverride?.startDate
            ? formatInTimeZone(
                config.surveyCadenceOverride.startDate,
                "UTC",
                "yyyy-MM-dd"
              )
            : null,
          anonymousSubmission:
            config.anonymousSubmission ?? DEFAULT_VALUES.anonymousSubmission,
          duration: config.surveyDaysUntilExpirationOverride ?? defaultDuration,
          /**
           * Get reward type & reward value if populated, otherwise return default
           */
          ...((): Pick<CheckInSurveyFormType, "rewardType" | "rewardValue"> => {
            const { numOrganizationDrawTickets: numTickets, numPoints } =
              config.surveyRewardOverride ?? {};

            if (numTickets) {
              return {
                rewardType: "tickets",
                rewardValue: numTickets.toString(),
              };
            }
            if (numPoints) {
              return {
                rewardType: "points",
                rewardValue: numPoints.toString(),
              };
            }
            return {
              rewardType: DEFAULT_VALUES.rewardType,
              rewardValue: DEFAULT_VALUES.rewardValue,
            };
          })(),
          numQuestionsPerSurvey: DEFAULT_NUM_QUESTIONS_PER_SURVEY, // this is unchangeable for now
          questions: config.surveyQuestionsConfig
            ? (Object.fromEntries(
                CHECK_IN_SURVEY_QUESTIONS.map((question) => {
                  if (
                    config.surveyQuestionsConfig.requiredSurveyQuestions.includes(
                      question
                    )
                  ) {
                    return [question, "required"];
                  } else if (
                    config.surveyQuestionsConfig.rotatingSurveyQuestions.includes(
                      question
                    )
                  ) {
                    return [question, "rotating"];
                  }
                  return [question, "off"];
                })
              ) as CheckInSurveyFormQuestions)
            : DEFAULT_VALUES.questions,
          // make users explicitly select update policy, also to avoid backfilling unselectable values like NO_UPDATE
          updatePolicy: undefined,
        };

        reset(formValues);
      }
    },
    onError: reportError,
  });

  const theme = useTheme();
  const snackbar = useSnackbar();
  const track = useTrack();

  const [createUpdateMutation] = useCheckInSurveyFormCreateUpdateMutation();
  const [deactivateMutation] = useCheckInSurveyFormDisableMutation();

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!queryData) {
    return <PageLoadingState />;
  }

  const isQuickCheckSurveyAlreadyEnabled = Boolean(
    queryData.getMyOrganizationGoalConfig?.enabled &&
      queryData.getMyOrganizationGoalConfig
        ?.quickCheckSurveyGoalDistributionConfig?.enabled
  );

  const hasAvailableQuickCheckSurveyGoals = Boolean(
    queryData.getMyOrganizationHasAvailableGoals.hasAvailableGoals
  );

  /**
   * To be called when submitting an active check-in survey config
   */
  const onActiveSubmit = async (data: CheckInSurveyFormType) => {
    assert(data.active === true);

    try {
      await createUpdateMutation({
        variables: mapFormDataToUpdateMutationVariables({
          formData: data,
          isQuickCheckSurveyAlreadyEnabled,
        }),
      });
      track("Check-in survey settings updated", {
        values: JSON.stringify(data),
      });

      snackbar.show({
        severity: "success",
        message: `Success! Check-in survey settings ${
          isQuickCheckSurveyAlreadyEnabled ? "updated" : "enabled"
        }`,
      });

      reset(data);
      navigate(backTo);
    } catch (e) {
      const errorModalProps = ((): {
        title: string;
        subtitle: string;
      } | null => {
        if (hasGraphqlError(e, ErrorCode.GoalConfigRewardTicketWithoutDraws)) {
          return {
            title: "Draws aren't set up yet",
            subtitle:
              "You've selected draw tickets as a reward, but there are no active draws set up yet. Please set up a draw or adjust the reward type.",
          };
        } else if (hasGraphqlError(e, ErrorCode.GoalConfigUpdateConcurrency)) {
          return {
            title: "Update currently in progress",
            subtitle:
              "We're in the process of applying other updates at the moment. Please wait and try again.",
          };
        }
        return null;
      })();

      // show an error modal for handled errors, otherwise default to unexpected error alert
      if (errorModalProps) {
        setErrorModalState({
          title: errorModalProps.title,
          subtitle: errorModalProps.subtitle,
        });
      } else {
        // only report unexpected errors
        reportError(e);

        snackbar.show({
          message: "An unexpected error occurred. Please try again later.",
          severity: "error",
        });
      }
    }
  };

  /**
   * To be called when de-activating the check-in survey config
   */
  const onInactiveSubmit = async (data: CheckInSurveyFormType) => {
    assert(data.active === false);

    try {
      await deactivateMutation({
        variables: {
          disableDistributionGoalUpdatePolicy: (() => {
            switch (data.updatePolicy) {
              case "availableAndLocked":
                return GoalDistributionUpdatePolicy.CancelLockedAndAvailableGoals;
              case "lockedOnly":
              case undefined:
                return GoalDistributionUpdatePolicy.CancelLockedGoals;
              default:
                assertNever(data.updatePolicy);
            }
          })(),
        },
      });
      track("Check-in survey settings deactivated");

      snackbar.show({
        severity: "success",
        message: "Success! Check-in survey has been deactivated",
      });
      reset(data);
      navigate(backTo);
    } catch (e) {
      reportError(e);
      snackbar.show({
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    }
  };

  const onSubmitValid = async (data: CheckInSurveyFormType) => {
    setPolicyModalOpen(false);

    if (hasAvailableQuickCheckSurveyGoals) {
      assert(
        data.updatePolicy,
        "Expected update policy when organization has available survey goals"
      );
    }

    if (data.active) {
      return onActiveSubmit(data);
    }

    return onInactiveSubmit(data);
  };

  const onSubmitInvalid: Parameters<typeof handleSubmit>[1] = (errors) => {
    track("Check-in survey setting form submitted but user input had errors", {
      errors,
    });
    snackbar.show({
      message:
        "Oops! There are errors with your check-in survey configuration.",
      severity: "error",
    });
    setPolicyModalOpen(false);
  };

  const onSubmit = handleSubmit(onSubmitValid, onSubmitInvalid);

  return (
    <Form onSubmit={onSubmit} submitting={isSubmitting}>
      <SurveySettingsUpdateModal
        control={control}
        name="updatePolicy"
        open={policyModalOpen}
        onClose={() => setPolicyModalOpen(false)}
        onSubmit={onSubmit}
      />
      <SurveySettingsErrorModal
        open={errorModalState.open}
        title={errorModalState.state?.title ?? ""}
        subtitle={errorModalState.state?.subtitle ?? ""}
        onClose={() => setErrorModalState(undefined)}
        onExited={errorModalStateControl.onExited}
        surveyType={SurveyType.QuickCheck}
      />
      <div
        css={css`
          max-width: ${MAX_WIDTH}px;
        `}
      >
        <Button
          variant="text"
          startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          label="Back"
          width="auto"
          typographyVariant="body"
          linkTo={backTo}
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        />
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>
            <Typography variant="h3">Check-in survey</Typography>
            <Typography
              color="textSecondary"
              variant="footnote"
              css={(theme: AppTheme) =>
                css`
                  margin-top: ${theme.spacing(0.5)};
                `
              }
            >
              Customize survey settings to fit your agency's needs.
            </Typography>
          </div>
          <div
            css={css`
              margin-top: ${theme.spacing(3)};
            `}
          >
            <SurveySettingsActiveField control={control} name="active" />
          </div>
        </div>

        <PageCard
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(3)};
            padding: ${theme.spacing(3)};
            display: ${isActive
              ? "flex"
              : "none"}; // Only show form when survey is active, but do not unmount
            flex-direction: column;
            gap: ${theme.spacing(4)};
          `}
        >
          <SurveyFormRow
            title="Survey frequency"
            subtitle="Choose how often surveys are sent out."
            right={
              <SurveySettingsFrequencyField
                control={control}
                name="frequency"
                options={[
                  {
                    label: "Bi-weekly",
                    value: QuickCheckSurveyCadenceFrequency.Biweekly,
                  },
                  {
                    label: "Weekly",
                    value: QuickCheckSurveyCadenceFrequency.Weekly,
                  },
                ]}
                disabled={!isActive || isQuickCheckSurveyAlreadyEnabled}
              />
            }
            divider
          />
          <SurveyFormRow
            title="Survey start date"
            subtitle="Set a start date for your survey."
            right={
              /** Disabled when surveys are already enabled */
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: ${theme.spacing(1.5)};
                  margin-bottom: ${theme.spacing(1)};
                `}
              >
                <SurveySettingsStartDateField
                  control={control}
                  name="startDate"
                  // for quick check surveys this is required
                  required
                  // for quick check surveys, can select a start date today or later
                  minDate={new Date()}
                  disabled={!isActive || isQuickCheckSurveyAlreadyEnabled}
                />
              </div>
            }
            divider
          />
          <SurveyFormRow
            title="Survey duration"
            subtitle="Choose how long your survey will be open for responses."
            right={
              /** Disabled when surveys are already enabled */
              <SurveySettingsDurationField
                control={control}
                name="duration"
                options={compact([
                  {
                    label: "1 week",
                    value: weeksToDays(1).toString(),
                  },
                  // only show 2 week option if frequency is bi-weekly
                  frequency === QuickCheckSurveyCadenceFrequency.Biweekly && {
                    label: "2 weeks",
                    value: weeksToDays(2).toString(),
                  },
                ])}
                disabled={!isActive || isQuickCheckSurveyAlreadyEnabled}
              />
            }
            divider
          />
          <SurveyFormRow
            title="Responses"
            subtitle="Choose whether responses are anonymous or identiable."
            right={
              <SurveySettingsResponseTypeField
                control={control}
                name="anonymousSubmission"
                disabled={!isActive}
              />
            }
            divider
          />
          <SurveyFormRow
            title="Reward Type"
            subtitle="Choose the reward type and value when a survey is completed"
            right={
              <div
                css={css`
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  column-gap: ${theme.spacing(3)};
                `}
              >
                <SurveySettingsRewardTypeField
                  control={control}
                  name="rewardType"
                  disabled={!isActive}
                />
                <SurveySettingsRewardValueField
                  control={control}
                  name="rewardValue"
                  pointsPerDollar={
                    queryData.getMyRewardsOrganization.pointsPerDollar
                  }
                  getRewardType={() => getValues("rewardType") || null}
                  disabled={!isActive}
                />
              </div>
            }
            divider
          />
          <SurveyFormRow
            title="Survey length and questions"
            subtitle="Choose how many questions you want to ask per survey round and which questions you want to include."
            right={
              <CheckInSurveyLengthField
                control={control}
                name="numQuestionsPerSurvey"
              />
            }
          />
          <CheckInSurveyQuestionsField
            control={control}
            disabled={!isActive}
            agencyName={queryData.getMyRewardsOrganization.shortName}
            numQuestionsPerSurvey={numQuestionsPerSurvey}
          />
        </PageCard>
        <div
          css={(theme: AppTheme) => css`
            float: right;
            display: flex;
            gap: ${theme.spacing(1)};
            margin-left: auto;
            padding: ${theme.spacing(3, 0)};
          `}
        >
          <Button
            label="Cancel"
            css={css`
              width: 150px;
            `}
            variant="outlined"
            linkTo={backTo}
          />
          <Button
            label="Save"
            css={css`
              width: 150px;
            `}
            disabled={!isDirty}
            color="primary"
            {...(hasAvailableQuickCheckSurveyGoals
              ? {
                  type: "button",
                  onClick: async () => {
                    const isFormValid = await triggerValidation(undefined, {
                      shouldFocus: true,
                    });

                    if (isFormValid) {
                      setPolicyModalOpen(true);
                    } else {
                      // Call form submission logic to get all form errors
                      handleSubmit(
                        // no validation errors callback (should not occur)
                        () => {
                          track(
                            "no validation errors when submitting errors despite `triggerValidation` failure"
                          );
                          onSubmitInvalid({}); // invoke the invalid callback anyway
                        },
                        // validation errors callback (expected)
                        onSubmitInvalid
                      )();
                    }
                  },
                }
              : { type: "submit" })}
            loading={isSubmitting}
          />
        </div>
      </div>
    </Form>
  );
}

const mapFormDataToUpdateMutationVariables = ({
  formData: data,
  isQuickCheckSurveyAlreadyEnabled,
}: {
  formData: CheckInSurveyFormType;
  isQuickCheckSurveyAlreadyEnabled: boolean;
}): CheckInSurveyFormCreateUpdateMutationVariables => {
  const enableOnlyFields: Pick<
    QuickCheckSurveyGoalDistributionConfigAdminInput,
    "surveyCadenceOverride" | "surveyDaysUntilExpirationOverride"
  > = {
    surveyCadenceOverride: data.startDate
      ? {
          frequency: data.frequency,
          startDate: data.startDate,
        }
      : undefined, // NOTE: you can only update frequency with start date currently
    surveyDaysUntilExpirationOverride: Number(data.duration),
  };

  return {
    updateRewardOverrideGoalUpdatePolicy: (() => {
      switch (data.updatePolicy) {
        case "availableAndLocked":
          return GoalRewardUpdatePolicy.UpdateLockedAndAvailableGoals;
        case "lockedOnly":
        case undefined:
          return GoalRewardUpdatePolicy.UpdateLockedGoals;
        default:
          assertNever(data.updatePolicy);
      }
    })(),
    quickCheckSurveyGoalDistributionConfig: {
      surveyRewardOverride: (() => {
        assert(
          !!data.rewardType,
          "Expected reward type to be defined when submitting form"
        );
        switch (data.rewardType) {
          case "points":
            return { numPoints: Number(data.rewardValue) };
          case "tickets":
            return {
              numOrganizationDrawTickets: Number(data.rewardValue),
            };
          default:
            assertNever(data.rewardType);
        }
      })(),
      anonymousSubmission: data.anonymousSubmission,
      surveyQuestionsConfig: {
        numQuestionsPerSurvey: data.numQuestionsPerSurvey,
        requiredSurveyQuestions: Object.entries(data.questions)
          .filter(([_, value]) => value === "required")
          .map(([question]) => question as keyof CheckInSurveyFormQuestions),
        rotatingSurveyQuestions: Object.entries(data.questions)
          .filter(([_, value]) => value === "rotating")
          .map(([question]) => question as keyof CheckInSurveyFormQuestions),
      },
      ...(isQuickCheckSurveyAlreadyEnabled ? {} : enableOnlyFields), // only include these fields if disabled
    },
  };
};
