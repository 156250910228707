/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faPenToSquare, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DataTable } from "../../../../../../shared/components/data-table";
import { PageCard } from "../../../../../../shared/components/page-card";
import { useListBranchQuery } from "./list-branch-query.generated";

const ITEMS_PER_PAGE = 10;

export function BranchList(): JSX.Element {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();

  const { data, loading, error } = useListBranchQuery({
    onError: reportError,
  });

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const branches = data?.getMyRewardsOrganization.branches;
  const total = branches?.length;

  const paginatedBranches = branches?.slice(
    currentPageIndex * ITEMS_PER_PAGE,
    (currentPageIndex + 1) * ITEMS_PER_PAGE
  );

  return (
    <PageCard
      css={(theme: AppTheme) => css`
        padding: ${theme.spacing(3, 0.75)};
      `}
    >
      <div
        css={(theme: AppTheme) =>
          css`
            margin: 0 ${theme.spacing(3)};
          `
        }
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          `}
        >
          <div>
            <Typography
              variant="h5"
              component="h1"
              color="textPrimary"
              gutterBottom
            >
              Branches {loading ? "" : `(${total})`}
            </Typography>
          </div>
          <Button
            color="primary"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            label="Create a branch"
            size="medium"
            onClick={() => {
              navigate("/settings/branches/create");
            }}
            width="auto"
            css={css`
              height: fit-content;
              margin-left: auto;
            `}
          />
        </div>
        <Divider
          css={(theme: AppTheme) => css`
            margin: ${theme.spacing(4)} 0 ${theme.spacing(2)} 0;
          `}
        />
        {total === 0 && (
          <Typography
            color="grey.800"
            css={(theme: AppTheme) =>
              css`
                margin-top: ${theme.spacing(3)};
              `
            }
          >
            Create a branch to help you manage operations and reporting in a way
            that is specific to your team. Add as many branches as needed.
          </Typography>
        )}
      </div>
      {!!total && (
        <DataTable
          itemsPerPage={ITEMS_PER_PAGE}
          currentPageIndex={currentPageIndex}
          onCurrentPageIndexChange={setCurrentPageIndex}
          loading={loading}
          items={paginatedBranches}
          total={total}
          tableHeaders={
            <TableRow>
              {
                <TableHeader size="normal">
                  <Typography
                    variant="body"
                    color="textPrimary"
                    css={css`
                      font-weight: inherit;
                      display: inline-block;
                    `}
                  >
                    Branch name
                  </Typography>
                </TableHeader>
              }

              <TableHeader>
                <Typography
                  variant="body"
                  color="textPrimary"
                  css={css`
                    font-weight: inherit;
                    display: inline-block;
                  `}
                >
                  Tags
                </Typography>
              </TableHeader>
              <TableHeader width={170}>
                <Typography
                  variant="body"
                  color="textPrimary"
                  css={css`
                    font-weight: inherit;
                    display: inline-block;
                  `}
                >
                  Total employees
                </Typography>
              </TableHeader>
              <TableHeader size="small"></TableHeader>
            </TableRow>
          }
          tableBody={paginatedBranches?.map((branch) => {
            return (
              <TableRow key={branch.id}>
                <TableCell divider>
                  <Typography
                    variant="body"
                    color={theme.palette.grey[800]}
                    fontWeight={700}
                  >
                    {branch.name}
                  </Typography>
                </TableCell>

                <TableCell divider>
                  <div
                    css={(theme: AppTheme) => ({
                      display: "flex",
                      gap: theme.spacing(2),
                    })}
                  >
                    <Typography variant="body" color="textSecondary">
                      {(() => {
                        if (branch.tags.length === 0) {
                          return "No tags added";
                        }
                        let tagMsg = "";
                        for (let i = 0; i < branch.tags.length; i++) {
                          const tag = branch.tags[i];
                          if (tagMsg.length > 150) {
                            tagMsg += ` + ${branch.tags.length - i} more`;
                            break;
                          }
                          if (i > 0) {
                            tagMsg += ", ";
                          }
                          tagMsg += `${tag.displayName ?? tag.externalId}`;
                        }
                        return tagMsg;
                      })()}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell divider>{branch.activeUserCount}</TableCell>
                <TableCell divider>
                  <IconButton
                    onClick={() => navigate(`/branches/edit/${branch.id}`)}
                    aria-label="Edit branch"
                  >
                    <FontAwesomeIcon icon={faPenToSquare} size="xs" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        />
      )}
    </PageCard>
  );
}
