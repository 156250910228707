import { ClassNames } from "@emotion/react";
import { Stepper, Step, StepLabel, StepConnector } from "@material-ui/core";
import { Fragment } from "react";

import { useDrawerControl } from "@rewards-web/shared/hooks/use-drawer-control";
import { usePathParamToggle } from "@rewards-web/shared/hooks/use-path-param-toggle";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";

import { CandidateDetailsDrawer } from "../../../../shared/modules/candidates/candidate-details-drawer";
import { CandidateDrawerAccordionState } from "../../../../shared/modules/candidates/candidate-details-drawer/use-accordion-state";
import { CandidateListDetailsFragment } from "../candidate-fragment.generated";
import { CandidateStep } from "./candidate-step";
import { ActionableCandidateStepProps } from "./candidate-step/actionable-candidate-step";
import { CandidateSummary } from "./candidate-summary";
import { DisplayStep, getActionableStepsToDisplay } from "./lib";
import { useArchiveCandidateMutation } from "./mutations/archive.generated";
import { useRecordHourlyRetentionStepMutation } from "./mutations/record-hourly-retention-milestone.generated";
import { useRecordRecruitmentStepMutation } from "./mutations/record-recruitment-step.generated";
import { useRecordRetentionStepMutation } from "./mutations/record-retention-milestone.generated";
import { useReopenCandidateMutation } from "./mutations/reopen.generated";

interface CandidateListProps {
  candidates: CandidateListDetailsFragment[];
  onRefreshList(): void;
  candidateDrawerAccordionState: CandidateDrawerAccordionState;
  onChangeCandidateDrawerAccordionState: (
    setter: (
      prev: CandidateDrawerAccordionState
    ) => CandidateDrawerAccordionState
  ) => void;
  resumesEnabled: boolean;
}

function getKeyForCandidateStep(step: DisplayStep): string {
  switch (step.type) {
    case "CandidateRecruitmentStep":
      return `${step.type}-${step.stepName}`;
    case "CandidateMonthlyRetentionStep":
      return `${step.type}-${step.durationMonths}`;
    case "CandidateHourlyRetentionStep":
      return `${step.type}-${step.durationHours}`;
    default:
      assertNever(step);
  }
}

export function CandidateList({
  candidates,
  onRefreshList,
  candidateDrawerAccordionState,
  onChangeCandidateDrawerAccordionState,
  resumesEnabled,
}: CandidateListProps): JSX.Element {
  const track = useTrack();

  const [viewingCandidateId, candidateDetailsActions] = usePathParamToggle({
    param: "id",
    onPath: "/candidates/:id/details",
    offPath: "/candidates",
  });
  const [
    candidateDetailsDrawer,
    candidateDetailsDrawerActions,
  ] = useDrawerControl(viewingCandidateId);

  const [recordRecruitmentStep] = useRecordRecruitmentStepMutation();
  const [recordRetentionStep] = useRecordRetentionStepMutation();
  const [recordHourlyRetentionStep] = useRecordHourlyRetentionStepMutation();
  const [archiveCandidate] = useArchiveCandidateMutation();
  const [reopenCandidate] = useReopenCandidateMutation();

  const archive = async (
    candidate: CandidateListDetailsFragment,
    updatedAdminNotes: string
  ) => {
    try {
      await archiveCandidate({
        variables: {
          candidateId: candidate.id,
          updatedAdminNotes,
        },
        optimisticResponse: {
          archiveCandidate: {
            ...candidate,
            archived: true,
            archivedAt: new Date().getTime(),
          },
        },
      });
      track("Candidate archived", { candidateId: candidate.id });
    } catch (error) {
      reportError(error);
      alert(
        "An unexpected error occurred. Please refresh the page and try again."
      );
    }
  };

  const handleArchive = (candidate: CandidateListDetailsFragment) => (
    updatedAdminNotes: string
  ): void => {
    archive(candidate, updatedAdminNotes);
  };

  const handleReopen = (
    candidate: CandidateListDetailsFragment
  ) => async () => {
    try {
      await reopenCandidate({
        variables: {
          candidateId: candidate.id,
        },
        optimisticResponse: {
          reopenCandidate: {
            ...candidate,
            archived: false,
          },
        },
      });
      track("Candidate re-opened", { candidateId: candidate.id });
    } catch (error) {
      reportError(error);
      alert(
        "An unexpected error occurred. Please refresh the page and try again."
      );
    }
  };

  const handleComplete = (
    candidate: CandidateListDetailsFragment,
    step: DisplayStep
  ) => async () => {
    try {
      switch (step.type) {
        case "CandidateRecruitmentStep": {
          await recordRecruitmentStep({
            variables: {
              candidateId: candidate.id,
              step: step.stepName,
            },
            optimisticResponse: {
              recordCandidateRecruitmentStep: {
                ...candidate,
                completedStepsV2: [
                  ...candidate.completedStepsV2,
                  {
                    __typename: "RecordedCandidateRecruitmentStep",
                    stepName: step.stepName,
                    recordedAt: new Date().getTime(),
                  },
                ],
              },
            },
          });

          track("Candidate recruitment step recorded", {
            candidateId: candidate.id,
            step: step.stepName,
          });
          break;
        }
        case "CandidateMonthlyRetentionStep": {
          await recordRetentionStep({
            variables: {
              candidateId: candidate.id,
              durationMonths: step.durationMonths,
            },
            optimisticResponse: {
              recordCandidateRetentionStep: {
                ...candidate,
                completedStepsV2: [
                  ...candidate.completedStepsV2,
                  {
                    __typename: "RecordedCandidateMonthlyRetentionStep",
                    durationMonths: step.durationMonths,
                    recordedAt: new Date().getTime(),
                  },
                ],
              },
            },
          });

          track("Candidate retention milestone recorded", {
            candidateId: candidate.id,
            durationMonths: step.durationMonths,
          });
          break;
        }

        case "CandidateHourlyRetentionStep": {
          await recordHourlyRetentionStep({
            variables: {
              candidateId: candidate.id,
              durationHours: step.durationHours,
            },
            optimisticResponse: {
              recordCandidateHourlyRetentionStep: {
                ...candidate,
                completedStepsV2: [
                  ...candidate.completedStepsV2,
                  {
                    __typename: "RecordedCandidateHourlyRetentionStep",
                    durationHours: step.durationHours,
                    recordedAt: new Date().getTime(),
                  },
                ],
              },
            },
          });

          track("Candidate hourly retention milestone recorded", {
            candidateId: candidate.id,
            durationHours: step.durationHours,
          });
          break;
        }
        default:
          assertNever(step, "Unexpected step type");
      }
    } catch (error) {
      reportError(error);
      alert(
        "An unexpected error occurred. Please refresh the page and try again."
      );
    }
  };

  return (
    <>
      <CandidateDetailsDrawer
        open={candidateDetailsDrawer.open}
        candidateId={candidateDetailsDrawer.state ?? null}
        onClose={candidateDetailsActions.navigateToOffPath}
        onExited={candidateDetailsDrawerActions.onExited}
        onCandidateRemoved={onRefreshList}
        accordionState={candidateDrawerAccordionState}
        onChangeAccordionState={onChangeCandidateDrawerAccordionState}
        resumesEnabled={resumesEnabled}
      />

      <ClassNames>
        {({ css, theme }) => (
          <div
            className={css`
              display: grid;
              grid-template-columns: fit-content(100%) auto;
              grid-row-gap: ${theme.spacing(1)};
              grid-column-gap: ${theme.spacing(3)};
              align-items: flex-start;
            `}
          >
            {candidates.map((candidate) => {
              const stepsToDisplay = getActionableStepsToDisplay(candidate);

              return (
                <Fragment key={candidate.id}>
                  <div
                    className={css`
                      min-width: 190px;
                      max-width: 450px;
                    `}
                  >
                    <CandidateSummary
                      candidate={candidate}
                      viewDetailsLink={candidateDetailsActions.getOnLink(
                        candidate.id
                      )}
                      resumesEnabled={resumesEnabled}
                    />
                  </div>
                  <div>
                    <Stepper
                      connector={
                        <StepConnector
                          classes={{
                            line: css`
                              border-color: #dfe4eb;
                              margin-top: ${theme.spacing(5)};
                              margin-right: ${theme.spacing(1)};
                            `,
                          }}
                        />
                      }
                      className={css`
                        background-color: transparent;
                        padding: 0;
                        align-items: flex-start;
                      `}
                      activeStep={stepsToDisplay.findIndex(
                        (step) =>
                          step.status === "actionable" ||
                          step.status === "archived"
                      )}
                    >
                      {stepsToDisplay.map((step) => {
                        const stepIconProps: Omit<
                          ActionableCandidateStepProps,
                          "icon"
                        > = {
                          finalStep:
                            stepsToDisplay.filter(
                              (lastStep) => lastStep.status === "completed"
                            ).length ===
                            stepsToDisplay.length - 1,
                          candidateId: candidate.id,
                          assignedToAdmin: candidate.assignedToAdmin,
                          candidateName: `${candidate.firstName} ${candidate.lastName}`,
                          referredBy: `${candidate.referredByUser.firstName} ${candidate.referredByUser.lastName}`,
                          step,
                          onArchive: handleArchive(candidate),
                          onReopen: handleReopen(candidate),
                          onComplete: handleComplete(candidate, step),
                        };

                        return (
                          <Step
                            className={css`
                              padding: 0;
                            `}
                            key={getKeyForCandidateStep(step)}
                          >
                            <StepLabel
                              StepIconComponent={CandidateStep}
                              StepIconProps={stepIconProps as any}
                              classes={{
                                iconContainer: "custom-icon-container",
                                label: "custom-label",
                              }}
                              className={css`
                                & .custom-icon-container {
                                  padding-right: 0;
                                }

                                & .custom-label,
                                & .custom-label.MuiStepLabel-completed {
                                  color: ${theme.palette.text.secondary};
                                  font-size: 0.8em;
                                }
                              `}
                            />
                          </Step>
                        );
                      })}
                    </Stepper>
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </ClassNames>
    </>
  );
}
