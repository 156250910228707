import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CheckInSurveyFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckInSurveyFormDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'shortName' | 'pointsPerDollar'>
  ), getMyOrganizationGoalConfig?: Types.Maybe<(
    { __typename?: 'OrganizationGoalConfig' }
    & Pick<Types.OrganizationGoalConfig, 'id' | 'enabled' | 'updateRewardOverrideGoalUpdatePolicy' | 'disableDistributionGoalUpdatePolicy'>
    & { quickCheckSurveyGoalDistributionConfig?: Types.Maybe<(
      { __typename?: 'QuickCheckSurveyGoalDistributionConfig' }
      & Pick<Types.QuickCheckSurveyGoalDistributionConfig, 'enabled' | 'surveyDaysUntilExpirationOverride' | 'anonymousSubmission'>
      & { surveyRewardOverride?: Types.Maybe<(
        { __typename?: 'GoalConfigRewardOverride' }
        & Pick<Types.GoalConfigRewardOverride, 'numPoints' | 'numOrganizationDrawTickets'>
      )>, surveyCadenceOverride?: Types.Maybe<(
        { __typename?: 'QuickCheckSurveyCadenceOverride' }
        & Pick<Types.QuickCheckSurveyCadenceOverride, 'frequency' | 'startDate'>
      )>, surveyQuestionsConfig: (
        { __typename?: 'RotatingSurveyQuestionsConfig' }
        & Pick<Types.RotatingSurveyQuestionsConfig, 'requiredSurveyQuestions' | 'rotatingSurveyQuestions' | 'numQuestionsPerSurvey'>
      ) }
    )> }
  )>, getMyOrganizationHasAvailableGoals: (
    { __typename?: 'HasAvailableGoalsResponse' }
    & Pick<Types.HasAvailableGoalsResponse, 'hasAvailableGoals'>
  ) }
);


export const CheckInSurveyFormDataDocument = gql`
    query CheckInSurveyFormData {
  getMyRewardsOrganization {
    id
    shortName
    pointsPerDollar
  }
  getMyOrganizationGoalConfig {
    id
    enabled
    updateRewardOverrideGoalUpdatePolicy
    disableDistributionGoalUpdatePolicy
    quickCheckSurveyGoalDistributionConfig {
      enabled
      surveyRewardOverride {
        numPoints
        numOrganizationDrawTickets
      }
      surveyCadenceOverride {
        frequency
        startDate
      }
      surveyDaysUntilExpirationOverride
      anonymousSubmission
      surveyQuestionsConfig {
        requiredSurveyQuestions
        rotatingSurveyQuestions
        numQuestionsPerSurvey
      }
    }
  }
  getMyOrganizationHasAvailableGoals(
    goalType: SURVEY
    filter: {surveyType: QUICK_CHECK}
  ) {
    hasAvailableGoals
  }
}
    `;

/**
 * __useCheckInSurveyFormDataQuery__
 *
 * To run a query within a React component, call `useCheckInSurveyFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInSurveyFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInSurveyFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckInSurveyFormDataQuery(baseOptions?: Apollo.QueryHookOptions<CheckInSurveyFormDataQuery, CheckInSurveyFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckInSurveyFormDataQuery, CheckInSurveyFormDataQueryVariables>(CheckInSurveyFormDataDocument, options);
      }
export function useCheckInSurveyFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckInSurveyFormDataQuery, CheckInSurveyFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckInSurveyFormDataQuery, CheckInSurveyFormDataQueryVariables>(CheckInSurveyFormDataDocument, options);
        }
export type CheckInSurveyFormDataQueryHookResult = ReturnType<typeof useCheckInSurveyFormDataQuery>;
export type CheckInSurveyFormDataLazyQueryHookResult = ReturnType<typeof useCheckInSurveyFormDataLazyQuery>;
export type CheckInSurveyFormDataQueryResult = Apollo.QueryResult<CheckInSurveyFormDataQuery, CheckInSurveyFormDataQueryVariables>;