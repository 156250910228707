/** @jsxImportSource @emotion/react */
import { QueryResult } from "@apollo/client";
import { css } from "@emotion/react";
import {
  faBirthdayCake,
  faBriefcase,
  faDollarCircle,
  faEdit,
  faEnvelope,
  faEye,
  faHandHoldingHeart,
  faLocationDot,
  faMobile,
  faPaperPlane,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseISO, differenceInDays } from "date-fns";
import { compact } from "lodash";
import { Fragment, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { CardContent } from "@rewards-web/shared/components/card";
import { Card } from "@rewards-web/shared/components/card/card";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { useDrawerControl } from "@rewards-web/shared/hooks/use-drawer-control";
import { usePathParamToggle } from "@rewards-web/shared/hooks/use-path-param-toggle";
import { formatPhoneNumberSimplified } from "@rewards-web/shared/lib/phone-number-format";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

import { InsightMetric } from "../../../../../shared/components/insight-metric";
import { useSendLoginLinkToEmployee } from "../../../../../shared/modules/employees/hooks/use-send-login-links";
import { EditEmployeeDrawer } from "../../../../../shared/modules/employees/list/components/edit-employee-modal";
import { useHasPermissionQuery } from "../../../../../shared/modules/permissions/hooks/use-has-permission-query";
import { useRole } from "../../../../../shared/modules/role";
import { EmployeeDetailDataQuery } from "../employee-detail-data.generated";

export interface EmployeeDetailTopCardProps {
  userId: string;
  query: Pick<
    QueryResult<EmployeeDetailDataQuery>,
    "data" | "error" | "refetch"
  >;
}

export function EmployeeDetailTopCard({
  userId,
  query,
}: EmployeeDetailTopCardProps): JSX.Element {
  const track = useTrack();
  const navigate = useNavigate();

  const [viewingEmployeeId, employeeDetailsActions] = usePathParamToggle({
    param: "id",
    onPath: "/employees/:id/edit",
    offPath: `/employees/${userId}`,
  });
  const [viewEmployeeDrawer, viewEmployeeDrawerActions] = useDrawerControl(
    viewingEmployeeId === userId
  );

  const roleQuery = useRole();
  const fullEmployeePermissionsQuery = useHasPermissionQuery(
    "full",
    "employees"
  );

  const [
    sendLoginLink,
    { loading: sendLoginLinkLoading },
  ] = useSendLoginLinkToEmployee({
    rewardsProgramShortName:
      query.data?.organization?.whiteLabelConfig?.rewardsProgramShortName ??
      "Caribou Rewards",
    sentFrom: "employee_detail_page_top_card",
  });

  // Should be read-only if the user has an external employee ID,
  // because they're not managed by Caribou
  const employeeManagedBySchedulingSystem = Boolean(
    query.data?.user?.externalEmployeeId
  );
  const employeeIsReadOnly =
    query.data?.user &&
    typeof fullEmployeePermissionsQuery.hasPermission === "boolean" &&
    roleQuery.data
      ? roleQuery.data.role === "admin" &&
        (employeeManagedBySchedulingSystem ||
          !fullEmployeePermissionsQuery.hasPermission)
      : undefined;

  const content = (() => {
    if (query.error) {
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred loading this employee's details. Please try again later."
        />
      );
    }

    if (query.data && !query.data.user) {
      return <Alert severity="warning" message="This user does not exist" />;
    }

    const user = query.data?.user;

    const emailToDisplay: {
      type: "work" | "personal";
      email: string;
    } | null = (() => {
      if (!user) {
        return null;
      }

      if (user.workEmail && !user.workEmail.endsWith("@example.com")) {
        return { type: "work", email: user.workEmail };
      }

      if (
        user.personalContactInfo?.__typename ===
          "RewardsUserPersonalContactInfoData" &&
        user.personalContactInfo.email &&
        !user.personalContactInfo.email.endsWith("@example.com")
      ) {
        return { type: "personal", email: user.personalContactInfo.email };
      }

      return null;
    })();

    const phoneToDisplay: {
      type: "work" | "personal";
      phone: string;
    } | null = (() => {
      if (!user) {
        return null;
      }

      if (user.workPhoneNumber) {
        return { type: "work", phone: user.workPhoneNumber };
      }

      if (
        user?.personalContactInfo?.__typename ===
          "RewardsUserPersonalContactInfoData" &&
        user.personalContactInfo.phoneNumber
      ) {
        return {
          type: "personal",
          phone: user.personalContactInfo.phoneNumber,
        };
      }

      return null;
    })();

    const launchedAndUserActive =
      query.data?.organization?.launched && user?.active;

    const insightMetrics: ReactNode[] = [];

    if (
      !user ||
      (user?.employmentStartDate &&
        new Date(user.employmentStartDate) < new Date())
    ) {
      insightMetrics.push(
        <InsightMetric
          labelStyle="grey"
          label="Tenure"
          valueVariant="h5"
          icon={<FontAwesomeIcon icon={faBriefcase} />}
          tooltipText="Time since the employee's start date, which is automatically pulled from your scheduling system"
          value={
            user
              ? {
                  type: "duration_in_days",
                  days: differenceInDays(
                    new Date(),
                    new Date(user.employmentStartDate)
                  ),
                }
              : {
                  type: "loading",
                }
          }
        />
      );
    }

    insightMetrics.push(
      <InsightMetric
        labelStyle="grey"
        label="Last login"
        valueVariant="h5"
        icon={<FontAwesomeIcon icon={faMobile} />}
        action={
          user?.active
            ? {
                icon: <FontAwesomeIcon icon={faPaperPlane} size="xs" />,
                ariaLabel: "Send login link",
                tooltipText: "Send login link",
                onClick: () => {
                  sendLoginLink({
                    id: user.id,
                    shouldSendInvitation: !user.personalContactInfo,
                  });
                },
                loading: sendLoginLinkLoading,
              }
            : undefined
        }
        value={(() => {
          if (!user) {
            return { type: "loading" };
          }

          if (user.lastLoginDate) {
            return {
              type: "date_only",
              date: parseISO(user.lastLoginDate),
            };
          }

          return {
            type: "empty",
            content: (
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  gap: ${theme.spacing(1.5)};
                `}
              >
                <Typography variant="body" color="grey.800" textAlign="center">
                  {launchedAndUserActive
                    ? "Has not logged in yet."
                    : "Has not logged in."}
                </Typography>
                {launchedAndUserActive && (
                  <Button
                    label="Send link"
                    startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                    variant="outlined"
                    width="auto"
                    size="small"
                    loading={sendLoginLinkLoading}
                    onClick={() => {
                      sendLoginLink({
                        id: user.id,
                        shouldSendInvitation: !user.personalContactInfo,
                      });
                    }}
                    css={css`
                      margin: 0 auto;
                    `}
                  />
                )}
              </div>
            ),
            style: "active",
          };
        })()}
      />
    );

    insightMetrics.push(
      <InsightMetric
        labelStyle="grey"
        label="Last recognition"
        valueVariant="h5"
        icon={<FontAwesomeIcon icon={faHandHoldingHeart} />}
        action={
          user
            ? {
                icon: <FontAwesomeIcon icon={faDollarCircle} size="xs" />,
                ariaLabel: "Send recognition",
                tooltipText: "Send recognition",
                onClick: () => {
                  track("Clicked send recognition from employee detail card", {
                    userId,
                  });
                  navigate(
                    `/recognition/give-points?${new URLSearchParams({
                      userId,
                    })}`,
                    {
                      state: {
                        backTo: `/employees/${userId}`,
                      },
                    }
                  );
                },
                loading: false,
              }
            : undefined
        }
        value={(() => {
          if (!user) {
            return { type: "loading" };
          }

          if (user.lastRecognitionPointsReceivedDate) {
            return {
              type: "date_only",
              date: new Date(user.lastRecognitionPointsReceivedDate),
            };
          }

          return {
            type: "empty",
            content: (
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  gap: ${theme.spacing(1.5)};
                `}
              >
                <Typography variant="body" color="grey.800" textAlign="center">
                  {user.active
                    ? "No recognition sent yet."
                    : "No recognition sent."}
                </Typography>
                {launchedAndUserActive && (
                  <Button
                    label="Send recognition"
                    startIcon={<FontAwesomeIcon icon={faDollarCircle} />}
                    variant="outlined"
                    width="auto"
                    size="small"
                    linkTo={`/recognition/give-points?${new URLSearchParams({
                      userId,
                    })}`}
                    linkState={{ backTo: `/employees/${userId}` }}
                    onClick={() => {
                      track(
                        "Clicked send recognition from employee detail card",
                        {
                          userId,
                        }
                      );
                    }}
                    css={css`
                      margin: 0 auto;
                    `}
                  />
                )}
              </div>
            ),
            style: "active",
          };
        })()}
      />
    );

    const headerElements = compact([
      <Typography
        variant="body"
        color={user?.active ? "success.main" : "grey.800"}
      >
        {
          <>
            <div
              css={(theme: AppTheme) => css`
                content: "";
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 1000px;
                background-color: ${user?.active
                  ? theme.palette.success.main
                  : theme.palette.error.main};
                margin-left: 3px;
                margin-right: 3px;
              `}
            />{" "}
            {user?.active ? "Active" : "Deactivated"}
          </>
        }
      </Typography>,
      emailToDisplay && (
        <Tooltip
          title={
            emailToDisplay.type === "work"
              ? "Work email is automatically pulled from your scheduling system"
              : "Personal email is automatically pulled from your scheduling system"
          }
          disabled={!employeeManagedBySchedulingSystem}
        >
          <Typography variant="body" color="grey.800">
            <FontAwesomeIcon
              icon={faEnvelope}
              size="lg"
              css={css`
                margin-right: 2px;
              `}
            />{" "}
            {emailToDisplay.email}
          </Typography>
        </Tooltip>
      ),
      phoneToDisplay && (
        <Tooltip
          title={
            phoneToDisplay.type === "work"
              ? "Work phone number is automatically pulled from your scheduling system"
              : "Personal phone number is automatically pulled from your scheduling system"
          }
          disabled={!employeeManagedBySchedulingSystem}
        >
          <Typography variant="body" color="grey.800">
            <FontAwesomeIcon
              size="lg"
              icon={faPhone}
              css={css`
                margin-right: 2px;
              `}
            />{" "}
            {formatPhoneNumberSimplified(phoneToDisplay.phone)}
          </Typography>
        </Tooltip>
      ),
      user?.branch && (
        <Tooltip
          title="Branch is automatically pulled from your scheduling system"
          disabled={!employeeManagedBySchedulingSystem}
        >
          <Typography variant="body" color="grey.800">
            <FontAwesomeIcon
              size="lg"
              icon={faLocationDot}
              css={css`
                margin-right: 2px;
              `}
            />{" "}
            {user.branch.name}
          </Typography>
        </Tooltip>
      ),
      user?.dateOfBirth && (
        <Tooltip
          title="Birthday is automatically pulled from your scheduling system"
          disabled={!employeeManagedBySchedulingSystem}
        >
          <Typography variant="body" color="grey.800">
            <FontAwesomeIcon
              size="lg"
              icon={faBirthdayCake}
              css={css`
                margin-right: 2px;
              `}
            />{" "}
            {parseISO(user.dateOfBirth).toLocaleDateString(undefined, {
              month: "long",
              day: "numeric",
            })}
          </Typography>
        </Tooltip>
      ),
    ]);

    return (
      <>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
          `}
        >
          <Typography
            component="h1"
            variant="h3"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            {user ? (
              <>
                {user.firstName} {user.lastName}
              </>
            ) : (
              <Skeleton
                animated
                width={300}
                height={60}
                css={css`
                  margin-top: -12px;
                `}
              />
            )}
          </Typography>
          {typeof employeeIsReadOnly === "boolean" ? (
            <Button
              label={employeeIsReadOnly ? "View details" : "Edit details"}
              loading={!query.data}
              startIcon={
                <FontAwesomeIcon icon={employeeIsReadOnly ? faEye : faEdit} />
              }
              variant="outlined"
              width="auto"
              size="small"
              linkTo={`/employees/${userId}/edit`}
              onClick={() => {
                track("Clicked view employee from employee detail card", {
                  userId,
                  employeeIsReadOnly,
                });
              }}
            />
          ) : (
            <Skeleton width={120} height={40} />
          )}
        </div>
        {user ? (
          <div>
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: ${theme.spacing(1)} 0; /* Add vertical gap between wrapped lines */
              `}
            >
              {headerElements.map((element, idx) => (
                // This is a wrapper div to ensure that the dot is always between the last element and the next element,
                // and so that they wrap together.
                <div
                  key={idx}
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  {element}
                  {idx < headerElements.length - 1 && (
                    <div
                      css={(theme: AppTheme) => css`
                        content: "";
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        margin-left: ${theme.spacing(2)};
                        margin-right: ${theme.spacing(2)};
                        margin-bottom: 2px;
                        border-radius: 1000px;
                        background-color: ${theme.palette.grey[800]};
                      `}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Skeleton width={400} height={20} animated />
        )}

        {/* Only show tenure if the employee has been employed for more than 0 days */}
        {insightMetrics.length > 0 && (
          <div
            css={(theme: AppTheme) =>
              css`
                margin-top: ${theme.spacing(3)};
                ${theme.breakpoints.up("md")} {
                  margin-top: ${theme.spacing(6)};
                }
                display: flex;
                gap: ${theme.spacing(2)};
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
              `
            }
          >
            {insightMetrics.map((metric, idx) => (
              <Fragment key={idx}>{metric}</Fragment>
            ))}
          </div>
        )}
      </>
    );
  })();

  return (
    <>
      <EditEmployeeDrawer
        open={viewEmployeeDrawer.open}
        userId={userId}
        onClose={employeeDetailsActions.navigateToOffPath}
        onUpdated={query.refetch}
        onExited={viewEmployeeDrawerActions.onExited}
        usesWorkDevices={query.data?.organization?.usesWorkDevices ?? false}
        integratedWithAlayaCare={
          query.data?.organization?.integratedWithAlayaCare ?? false
        }
        managersEnabled={query.data?.organization?.managersEnabled ?? false}
        referralsEnabled={query.data?.organization?.referralsEnabled ?? false}
        readOnly={employeeIsReadOnly}
      />
      <Card>
        <CardContent
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(4)};
          `}
        >
          {content}
        </CardContent>
      </Card>
    </>
  );
}
