/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";

interface CreateBranchSuccessModalProps {
  open: boolean;
}

export function CreateBranchSuccessModal({
  open,
}: CreateBranchSuccessModalProps) {
  const theme = useTheme();

  return (
    <Modal width="500px" open={open}>
      <ModalTitle>
        <div
          css={css`
            text-align: center;
            width: 100%;
          `}
        >
          <FontAwesomeIcon
            color={theme.palette.primary.main}
            icon={faCheckCircle}
            size="xl"
          />
          <Typography
            align="center"
            variant="h6"
            css={css`
              margin-top: ${theme.spacing(2.5)};
              margin-bottom: -${theme.spacing(2)};
            `}
          >
            Branch successfully created
          </Typography>
        </div>
      </ModalTitle>
      <ModalContent>
        <Typography align="center" variant="footnote">
          Heads up! While branches can't be deleted, they can be edited anytime
          to fit your needs.
        </Typography>
      </ModalContent>
      <ModalActions>
        <div
          css={css`
            text-align: center;
            width: 100%;
            margin-top: ${theme.spacing(1)};
          `}
        >
          <Button
            css={css`
              min-width: 120px;
              height: 40px;
            `}
            color="primary"
            linkTo="/settings/branches"
            label="Okay"
            size="small"
            width="auto"
          />
        </div>
      </ModalActions>
    </Modal>
  );
}
