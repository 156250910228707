/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compact } from "lodash";
import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Link } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsOrganizationBranch } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { FormStepper } from "../../../../../../components/form-stepper";
import { usePermissionLimitedBranchIds } from "../../../../../branches/use-permission-limited-branch-ids";
import { JobBranchField } from "../../../../fields/job-branch-field";
import { JobCandidateAlertEmailField } from "../../../../fields/job-candidate-alert-email-field";
import { JobCustomEmailField } from "../../../../fields/job-custom-email-field";
import { JobCustomSMSField } from "../../../../fields/job-custom-sms-field";
import { JobDescriptionField } from "../../../../fields/job-description-field";
import { JobHoursRequiredField } from "../../../../fields/job-hours-required-field";
import { JobInterviewBookingLinkField } from "../../../../fields/job-interview-booking-link-field";
import { JobLocationField } from "../../../../fields/job-location-field";
import { JobRateOfPayField } from "../../../../fields/job-rate-of-pay-field";
import { JobReferralStructureField } from "../../../../fields/job-referral-structure-field";
import { JobScreenerQuestionsField } from "../../../../fields/job-screener-questions";
import { JobTitleField } from "../../../../fields/job-title-field";
import { JobPostingEditDetailsFragment } from "../../../../graphql/job-posting-fragment.generated";
import { getJobPostingDefaultValues } from "../../../../lib/get-job-posting-default-values";
import { JobScreenerQuestionValue } from "../../../../typings";

export interface AddJobFormValues {
  jobTitle: string;
  branchId: string | null;

  /**
   * If `null`, it will use the organization's default referral structure.
   */
  referralStructureId: string | null;
  rateOfPay: string;
  location: string;
  hoursRequired: string;
  jobDescription: string;
  screenerQuestions: JobScreenerQuestionValue[];
  candidateAlertEmail: string;
  customizedEmailMessage: string;
  customizedSMSMessage: string;
  interviewBookingLink: string;
  customizedSMSMessageChecked: boolean;
  customizedEmailMessageChecked: boolean;
  interviewBookingLinkChecked: boolean;
  mustUploadResumeBeforeBookingInterview: "YES" | "NO";
}

interface AddNewJobFormProps {
  open: boolean;
  onClose(): void;
  onSubmit(values: AddJobFormValues): Promise<void>;
  adminEmailOptions: string[];
  jobPosting: JobPostingEditDetailsFragment | null;
  submitButtonLabel: string;
  myBranches: Pick<RewardsOrganizationBranch, "id" | "name">[];
  organizationBranches: Pick<RewardsOrganizationBranch, "id" | "name">[];
}

export function AddNewJobForm({
  open,
  onClose,
  onSubmit,
  adminEmailOptions,
  jobPosting,
  submitButtonLabel,
  myBranches,
  organizationBranches,
}: AddNewJobFormProps): JSX.Element {
  const track = useTrack();
  const { data: permissionLimitedBranchIds } = usePermissionLimitedBranchIds();

  const form = useForm<AddJobFormValues>({
    defaultValues: jobPosting
      ? // default values populate when duplicating a job posting
        getJobPostingDefaultValues(jobPosting)
      : {
          branchId: myBranches.length === 1 ? myBranches[0].id : null,
          referralStructureId: null, // defaults to organization default referral structure
          jobTitle: "",
          rateOfPay: "",
          location: "",
          hoursRequired: "",
          screenerQuestions: [],
          jobDescription: "",
          candidateAlertEmail: "",
          customizedEmailMessage: "",
          customizedSMSMessage: "",
          interviewBookingLink: "",
          customizedSMSMessageChecked: false,
          customizedEmailMessageChecked: false,
          interviewBookingLinkChecked: false,
          mustUploadResumeBeforeBookingInterview: "NO",
        },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form;
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const organizationHasBranches =
    organizationBranches.length > 0 &&
    permissionLimitedBranchIds?.showBranchFilter;

  return (
    <FormProvider {...form}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        submitting={isSubmitting}
        css={css`
          display: contents;
        `}
      >
        <ModalTitle>Add Job Posting</ModalTitle>
        <ModalContent>
          <FormStepper
            steps={compact([
              {
                title: "Add summary",
                content: (
                  <div
                    css={(theme: AppTheme) => css`
                      padding-top: ${theme.spacing(1)};
                    `}
                  >
                    {organizationHasBranches && (
                      <JobTitleField control={form.control} name="jobTitle" />
                    )}
                    <div
                      css={(theme: AppTheme) => css`
                        display: grid;
                        grid-template-columns:
                          calc(50% - ${theme.spacing(1)})
                          calc(50% - ${theme.spacing(1)});
                        grid-column-gap: ${theme.spacing(2)};
                      `}
                    >
                      {organizationHasBranches ? (
                        <JobBranchField
                          control={form.control}
                          name="branchId"
                        />
                      ) : (
                        <JobTitleField control={form.control} name="jobTitle" />
                      )}
                      <JobLocationField
                        control={form.control}
                        name="location"
                      />
                      <JobRateOfPayField
                        control={form.control}
                        name="rateOfPay"
                      />
                      <JobHoursRequiredField
                        control={form.control}
                        name="hoursRequired"
                      />
                    </div>
                  </div>
                ),
              },
              {
                title: "Add description",
                content: (
                  <JobDescriptionField
                    control={control}
                    name="jobDescription"
                  />
                ),
              },
              {
                title: "Referral structure",
                content: (
                  <>
                    <JobReferralStructureField
                      control={control}
                      name="referralStructureId"
                    />
                    <Alert
                      severity="info"
                      title="Can I add or edit a referral structure later?"
                      message={
                        <>
                          Once this job is published, your choice of referral
                          structure will be locked to maintain a seamless
                          experience and ensure data accuracy. To make changes
                          to the referral amounts, you can add additional
                          referral structures in your{" "}
                          <Link
                            to="/settings/referral-structure"
                            css={(theme: AppTheme) => css`
                              color: ${theme.palette.primary.main};
                              text-decoration: none;
                            `}
                            onClick={() => {
                              track(
                                "Clicked link to referral structure settings from job form",
                                { fromPage: "add-new-job" }
                              );
                            }}
                          >
                            Settings
                          </Link>
                          .
                        </>
                      }
                      messageTypographyVariant="footnote"
                      customIcon={
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          color="#444"
                          size="xs"
                          css={css`
                            margin-top: 3px;
                          `}
                        />
                      }
                      css={(theme: AppTheme) => css`
                        margin-top: ${theme.spacing(1)};
                        margin-bottom: ${theme.spacing(2)};
                      `}
                    />
                  </>
                ),
              },
              {
                title: "Select screening questions",
                content: (
                  <>
                    <Typography
                      variant="body"
                      css={(theme: AppTheme) => css`
                        margin-bottom: ${theme.spacing(3)};
                      `}
                    >
                      Feel free to add your own custom questions. All questions
                      must be formatted as yes-no questions, as answers are not
                      customizable.
                    </Typography>
                    <JobScreenerQuestionsField
                      control={control}
                      name="screenerQuestions"
                    />
                  </>
                ),
              },
              {
                title: "Notification preferences",
                content: (
                  <>
                    <Typography
                      variant="body"
                      css={(theme: AppTheme) => css`
                        margin-bottom: ${theme.spacing(3)};
                      `}
                    >
                      Who should receive email alerts about new candidates?
                    </Typography>
                    <JobCandidateAlertEmailField
                      control={control}
                      name="candidateAlertEmail"
                      emailAutocompleteOptions={adminEmailOptions}
                    />
                  </>
                ),
              },
              {
                title: "Customize",
                content: (
                  <div>
                    <JobCustomEmailField
                      name="customizedEmailMessage"
                      checkBoxName="customizedEmailMessageChecked"
                      control={control}
                    />
                    <JobCustomSMSField
                      name="customizedSMSMessage"
                      checkBoxName="customizedSMSMessageChecked"
                      control={control}
                    />
                    <JobInterviewBookingLinkField
                      name="interviewBookingLink"
                      checkBoxName="interviewBookingLinkChecked"
                      mustUploadResumeFieldName="mustUploadResumeBeforeBookingInterview"
                      control={control}
                    />
                  </div>
                ),
              },
              {
                title: "Publish this job",
                skip: submitButtonLabel !== "Publish",
                content: (
                  <Typography variant="body">
                    Press “Publish” to finish adding this job posting.
                  </Typography>
                ),
              },
            ])}
          />
        </ModalContent>

        <ModalActions>
          <Button onClick={onClose} variant="outlined" label="Cancel" />
          <Button type="submit" color="primary" label={submitButtonLabel} />
        </ModalActions>
      </Form>
    </FormProvider>
  );
}
