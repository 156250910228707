import { last, split } from "lodash";

import {
  CandidateRecruitmentStepName,
  TransactionMetadataCategory,
} from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";

import { TransactionMetadataFragmentFragment } from "./transaction-metadata-fragment.generated";

export function formatTransactionCategory(
  category: TransactionMetadataCategory
) {
  switch (category) {
    case TransactionMetadataCategory.Referral:
      return "Referral";
    case TransactionMetadataCategory.Recognition:
      return "Recognition";
    case TransactionMetadataCategory.ClockInOut:
      return "Clock in/out";
    case TransactionMetadataCategory.Milestone:
      return "Milestone";
    case TransactionMetadataCategory.Survey:
      return "Survey";
    case TransactionMetadataCategory.Weekend:
      return "Weekend";
    case TransactionMetadataCategory.LastMinute:
      return "Last Minute";
    case TransactionMetadataCategory.Video:
      return "Video";
    case TransactionMetadataCategory.Training:
      return "Training";
    case TransactionMetadataCategory.HoursIncrease:
      return "Hours Increase";
    case TransactionMetadataCategory.Redemption:
      return "Redemption";
    case TransactionMetadataCategory.Draw:
      return "Draw";
    case TransactionMetadataCategory.Other:
    default:
      return "Other";
  }
}

export function formatTransactionDescription(
  metadata: TransactionMetadataFragmentFragment,
  signedPoints?: number
): string {
  switch (metadata.__typename) {
    case "TransactionMetadataReferralJobShare":
      return `Job shared for the ${metadata.jobPosting.title} role`;

    case "TransactionMetadataReferralRecruitmentStep": {
      const candidate = `${metadata.candidate.firstName} ${metadata.candidate.lastName}`;
      const forTheRole = metadata.candidate.assignedToJobPosting
        ? ` for the ${metadata.candidate.assignedToJobPosting.title} role`
        : "";
      return `${candidate} ${formatRecruitmentStepName(
        metadata.stepName
      )}${forTheRole}`;
    }

    case "TransactionMetadataReferralRetentionMilestone": {
      const candidate = `${metadata.candidate.firstName} ${metadata.candidate.lastName}`;
      const forTheRole = metadata.candidate.assignedToJobPosting
        ? ` for the ${metadata.candidate.assignedToJobPosting.title} role`
        : "";
      const amount = metadata.durationHours ?? metadata.durationMonths;
      const unit =
        typeof metadata.durationHours === "number" ? "hour" : "month";
      return `${candidate} worked ${amount} ${unit}${
        amount === 1 ? "" : "s"
      }${forTheRole}`;
    }

    case "TransactionMetadataReferralCorrection":
    case "TransactionMetadataReferralAssignedAsReferringEmployee":
      return "Referral correction";

    case "TransactionMetadataRecognition":
      return `Received a recognition from ${metadata.recognitionPoints.from}. "${metadata.recognitionPoints.message}"`;

    case "TransactionMetadataMobileAppAdoption":
      return "Logged into the mobile app for the first time";

    case "TransactionMetadataEVVStreakGoal": {
      const tracker = metadata.goal.goalTracker;
      if (tracker.__typename === "EVVStreakGoalTracker") {
        if (tracker.targetStreak === 1) {
          return "Clocked in and out once (streak)";
        }

        return `Clocked in and out ${tracker.targetStreak} times (streak)`;
      }

      return "Clocked in and out (streak)";
    }

    case "TransactionMetadataEVVPunchCard": {
      const structure = metadata.punchCardOffer.structure;
      if (structure.__typename === "RewardOfferStructurePunchCard") {
        return `Clocked in and out ${structure.numPunchesPerCard} times (punch card)`;
      }
      return "Clocked in and out (punch card)";
    }

    case "TransactionMetadataMilestoneRewards": {
      const goal = metadata.milestoneRewardStep.goal;
      if (goal.__typename === "VisitMilestoneGoal") {
        return `Completed ${goal.visitNumber}${getOrdinalSuffix(
          goal.visitNumber
        )} visit milestone`;
      } else if (goal.__typename === "HoursMilestoneGoal") {
        return `Completed ${goal.numHours} hours milestone`;
      }
      return "Completed milestone";
    }

    case "TransactionMetadataSurvey":
      return `Completed ${
        metadata.surveyType
          ? // display in title case
            split(metadata.surveyType.toLowerCase(), "_").join(" ")
          : ""
      } survey`;

    case "TransactionMetadataWeekendShift": {
      const tracker = metadata.goal.goalTracker;
      if (tracker.__typename === "WeekendShiftGoalTracker") {
        const date = tracker.trackedVisit?.rangeStart;
        return `Completed weekend shift${
          date ? ` on ${formatDate(date)}` : ""
        }`;
      }
      return "Completed weekend shift";
    }

    case "TransactionMetadataLastMinuteShift": {
      const tracker = metadata.goal.goalTracker;
      if (tracker.__typename === "LastMinuteShiftGoalTracker") {
        const date = tracker.trackedVisit?.rangeStart;
        return `Completed last minute shift${
          date ? ` on ${formatDate(date)}` : ""
        }`;
      }
      return "Completed last minute shift";
    }

    case "TransactionMetadataWatchedVideo":
      return `Watched ${metadata.video.title} video`;

    case "TransactionMetadataTrainingProgram": {
      const details = metadata.goal.goalDetails;
      if (details?.__typename === "CompleteTrainingGoalDetails") {
        return `Completed ${details.trainingProgramName} training program`;
      }
      return "Completed training program";
    }

    case "TransactionMetadataBillableHoursIncrease": {
      const tracker = metadata.goal.goalTracker;
      if (
        tracker.__typename === "BillableHoursGoalTracker" &&
        tracker.trackedVisits.length > 0
      ) {
        return `Completed ${
          tracker.targetBillableHours
        } hours goal on ${formatDate(last(tracker.trackedVisits)!.rangeStart)}`;
      }
      return "Completed hours goal";
    }

    case "TransactionMetadataRedemption": {
      if (!metadata.redemption) {
        return "Redeemed reward";
      }

      const redemptionDollars = formatDollars(metadata.redemption.dollars);

      if (metadata.redemption.method === "PAYROLL") {
        return `Redeemed ${redemptionDollars} through payroll`;
      }

      if (metadata.redemption.method === "GENERIC_GIFT_CARD_LINK") {
        return `Redeemed a ${redemptionDollars} gift card`;
      }

      if (metadata.redemption.method === "HSA") {
        return `Redeemed ${redemptionDollars} through HSA`;
      }

      if (
        metadata.redemption.method === "CATALOG" &&
        (metadata.redemption.redeemedItems ?? []).length > 0
      ) {
        const items = metadata.redemption.redeemedItems!.map(
          (item) => item.catalogItem.displayName
        );
        return `Redeemed ${items.join(", ")}`;
      }

      return `Redeemed ${redemptionDollars}`;
    }

    case "TransactionMetadataDrawWinner": {
      const prizeNames = ["first", "second", "third"];
      const prizeTierIndex = metadata.drawWinner.prizeTierIndex;
      const prizeName =
        typeof prizeTierIndex === "number" &&
        prizeTierIndex >= 0 &&
        prizeTierIndex < prizeNames.length
          ? prizeNames[prizeTierIndex]
          : "prize";
      const date = metadata.drawWinner.wonAt;
      return `Won ${prizeName} prize${date ? ` on ${formatDate(date)}` : ""}`;
    }

    case "TransactionMetadataRedemptionRejected":
      return "Redemption total re-credited";

    default:
      return signedPoints && signedPoints < 0 ? "Points removed" : "Adjustment";
  }
}

function getOrdinalSuffix(number: number): string {
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return "th";
  }

  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function formatDate(dateString: number): string {
  return new Date(dateString).toLocaleDateString(undefined, {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

function formatRecruitmentStepName(
  recruitmentStepName: CandidateRecruitmentStepName
): string {
  switch (recruitmentStepName) {
    case CandidateRecruitmentStepName.ApplicationSubmitted:
      return "expressed interest";
    case CandidateRecruitmentStepName.InterviewSuccessful:
      return "passed interview";
    case CandidateRecruitmentStepName.StartedWork:
      return "started work";
    case CandidateRecruitmentStepName.StartedOrientation:
      return "started orientation";
    case CandidateRecruitmentStepName.CompletedOrientation:
      return "completed orientation";
    case CandidateRecruitmentStepName.CompletedFirstShift:
      return "completed first shift";
    default:
      return "step";
  }
}
