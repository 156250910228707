import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";

import { PageLayout } from "../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { ForbiddenErrorPage } from "../../../../shared/modules/permissions/components/forbidden-error-page";
import { SurveySettingsPageContents } from "./page-contents";
import { useSurveySettingsEnabled } from "./shared/hooks/use-survey-settings-enabled";

const MAX_CONTENT_WIDTH = 1400;

export function SurveySettingsPage(): JSX.Element {
  const {
    surveySettingsEnabled,
    surveySettingsLoading,
  } = useSurveySettingsEnabled();

  if (surveySettingsLoading) {
    return <PageLoadingState />;
  }

  if (!surveySettingsEnabled) {
    return <ForbiddenErrorPage />;
  }
  return (
    <AuthorizedAdminPageWrapper resource="surveys">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <SurveySettingsPageContents />
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
