/** @jsxImportSource @emotion/react */
import { BaseGoalConfigurationPage } from "../base-goal-configuration-page";
import { First30DayLoginGoalForm } from "./first-30-day-login-goal-form";

export function First30DayLoginGoalConfigurationPage(): JSX.Element {
  return (
    <BaseGoalConfigurationPage>
      <First30DayLoginGoalForm />
    </BaseGoalConfigurationPage>
  );
}
