/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Tab, Tabs } from "@mui/material";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import {
  RewardsAdminRestrictedAccessPermissionLevel,
  RewardsOrganizationAutomaticRecognitionBudgetFrequency,
} from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { AdminAccountDetails } from "../../admin-account-details";
import { AdminBudget } from "../../admin-budget";
import { AdminPermissions } from "../../admin-permissions";
import { EditAdminFormValues } from "../edit-admin-form";
import { EditAdminNavigationTabPanel } from "../edit-admin-navigation-tab-panel";

enum EditAdminPageTabName {
  AccountDetails = "Account details",
  Permissions = "Permissions",
  Budget = "Budget",
}

const EDIT_ADMIN_PAGE_TABS = [
  EditAdminPageTabName.AccountDetails,
  EditAdminPageTabName.Permissions,
  EditAdminPageTabName.Budget,
];

interface EditAdminNavigationTabsProps {
  recognitionBudgetsEnabled?: boolean;
  pointsPerDollar?: number;
  recognitionBudgetsFrequency?: RewardsOrganizationAutomaticRecognitionBudgetFrequency;
  currentTab: number;
  handleTabChange(event: React.SyntheticEvent, tab: number): void;
  control: Control<EditAdminFormValues, object>;
  setValue: UseFormSetValue<EditAdminFormValues>;
  accessLevelRestricted: boolean;
  setAccessLevelRestricted(value: boolean): void;
  setAdminEditedConfirmationModalOpen(value: boolean): void;
  currentAdminId: string;
  editingAdminId: string;
  hasFullAccessLevel: boolean;
}

export function EditAdminNavigationTabs({
  recognitionBudgetsEnabled,
  pointsPerDollar,
  recognitionBudgetsFrequency,
  currentTab,
  handleTabChange,
  control,
  setValue,
  accessLevelRestricted,
  setAccessLevelRestricted,
  setAdminEditedConfirmationModalOpen,
  currentAdminId,
  editingAdminId,
  hasFullAccessLevel,
}: EditAdminNavigationTabsProps): JSX.Element {
  const firstName = useWatch({ control, name: "firstName" });
  const addedPermissions = useWatch({
    name: "permissions",
    control,
  });

  const budgetSectionEnabled = useFeatureFlag(
    "admin-app-add-edit-admin-page-budget-enabled-temp"
  );

  const enabledTabs = EDIT_ADMIN_PAGE_TABS.filter((tabName) => {
    switch (tabName) {
      case EditAdminPageTabName.AccountDetails:
        return true;
      case EditAdminPageTabName.Permissions:
        return currentAdminId !== editingAdminId;
      case EditAdminPageTabName.Budget:
        return (
          budgetSectionEnabled &&
          hasFullAccessLevel &&
          recognitionBudgetsEnabled &&
          recognitionBudgetsFrequency !==
            RewardsOrganizationAutomaticRecognitionBudgetFrequency.None &&
          (!accessLevelRestricted ||
            addedPermissions.recognitionPoints ===
              RewardsAdminRestrictedAccessPermissionLevel.FullAccess)
        );
      default:
        return false;
    }
  });

  const currentTabName = enabledTabs[currentTab];

  const handleGoToPermissionsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    handleTabChange(
      event,
      enabledTabs.indexOf(EditAdminPageTabName.Permissions)
    );
  };

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 25% 75%;
      `}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={currentTab}
        onChange={handleTabChange}
        aria-label="Edit administrator tabs"
        classes={{
          indicator: "custom-indicator",
        }}
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(3)};
          & .custom-indicator {
            background-color: #96a2b0;
            left: 0px;
          }
        `}
      >
        {enabledTabs.map((tabName, idx) => (
          <Tab
            label={
              <div
                css={(theme: AppTheme) => css`
                  margin-left: ${theme.spacing(2)};
                `}
              >
                {tabName}
              </div>
            }
            key={`edit-admin-tab-${tabName}-${idx}`}
            aria-controls={`edit-admin-tabpanel-${tabName}`}
            css={(theme: AppTheme) => css`
              color: #8795a5;
              text-align: left;
              border-left: 1px solid ${theme.palette.divider};
              min-height: 46px;
              opacity: 1;
              padding-top: 6px;
              text-transform: none;

              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              font-weight: 500;
              > *:first-of-type {
                margin-bottom: 0px;
                margin-left: ${theme.spacing(1)};
              }
            `}
          />
        ))}
      </Tabs>

      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(0)};
        `}
      >
        <EditAdminNavigationTabPanel
          enabled={currentTabName === EditAdminPageTabName.AccountDetails}
        >
          <AdminAccountDetails
            control={control}
            fieldNames={{
              firstName: "firstName",
              lastName: "lastName",
              branchIds: "branchIds",
              active: "active",
              jobTitle: "jobTitle",
              role: "role",
              email: "email",
            }}
            readOnly={!hasFullAccessLevel}
            currentAdminId={currentAdminId}
            editingAdminId={editingAdminId}
          />
        </EditAdminNavigationTabPanel>

        <EditAdminNavigationTabPanel
          enabled={currentTabName === EditAdminPageTabName.Permissions}
        >
          <AdminPermissions
            control={control}
            fieldNames={{
              permissions: "permissions",
            }}
            accessLevelRestricted={accessLevelRestricted}
            setAccessLevelRestricted={setAccessLevelRestricted}
            readOnly={!hasFullAccessLevel}
          />
        </EditAdminNavigationTabPanel>

        {budgetSectionEnabled && hasFullAccessLevel && (
          <EditAdminNavigationTabPanel
            enabled={currentTabName === EditAdminPageTabName.Budget}
          >
            <AdminBudget
              firstName={firstName}
              control={control}
              fieldNames={{
                defaultBudgetPoints: "defaultBudgetPoints",
                budgetPointsAccessEnabled: "budgetPointsAccessEnabled",
              }}
              setValue={setValue}
              canSendRecognition={
                !accessLevelRestricted ||
                addedPermissions.recognitionPoints ===
                  RewardsAdminRestrictedAccessPermissionLevel.FullAccess
              }
              canOverrideBudget={
                !accessLevelRestricted ||
                addedPermissions["canOverrideBudget"] === true
              }
              handleGoToPermissionsClick={handleGoToPermissionsClick}
              recognitionBudgetsEnabled={recognitionBudgetsEnabled}
              pointsPerDollar={pointsPerDollar}
              recognitionBudgetsFrequency={recognitionBudgetsFrequency}
            />
          </EditAdminNavigationTabPanel>
        )}

        {hasFullAccessLevel && (
          <div
            css={css`
              max-width: 861px;
              text-align: right;
            `}
          >
            <div
              css={(theme: AppTheme) => css`
                float: right;
                display: flex;
                gap: ${theme.spacing(1)};
                padding-bottom: ${theme.spacing(3)};
                padding-right: ${theme.spacing(3)};
                margin-top: ${theme.spacing(-6)};
              `}
            >
              <Button
                label="Cancel"
                css={css`
                  width: 150px;
                `}
                variant="outlined"
                linkTo="/admins"
              />
              <Button
                label="Save"
                css={css`
                  width: 150px;
                `}
                color="primary"
                type="button"
                onClick={() => setAdminEditedConfirmationModalOpen(true)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
