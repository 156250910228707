/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faMessageLines } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compact, last } from "lodash";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { SurveyInsightsDateRangeInput } from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/types";

import { QuestionCategory } from "../../components/question-category";
import { QuestionText } from "../../components/question-text";
import { SurveyScore } from "../../components/survey-score";
import { SurveyQuestionTypeSupportedByUI } from "../../constants";
import { convertDateRangeToValue } from "../../utils";
import { ScoreWithDateRange } from "../utils";
import { SurveyQuestionScoreComparison } from "./survey-question-score-comparison";

interface BaseQuestionScoreCardProps {
  questionType: SurveyQuestionTypeSupportedByUI;
  dateRangeInput?: SurveyInsightsDateRangeInput;
  score?: number;
  previousScores: ScoreWithDateRange[];
  currentScoreWithDateRange: ScoreWithDateRange | null;
  maxScore?: 5;
  numComments?: number;
}

export function BaseQuestionScoreCard({
  questionType,
  dateRangeInput,
  score,
  previousScores,
  currentScoreWithDateRange,
  maxScore,
  numComments,
}: BaseQuestionScoreCardProps) {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(2)};
          box-sizing: border-box;
          // ensure 3 cards per row, at the most
          min-width: 300px;
        `
      }
    >
      <QuestionCategory questionType={questionType} />
      <QuestionText questionType={questionType} />
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin: ${theme.spacing(1)} 0;
        `}
      >
        <SurveyScore
          score={score}
          previousScore={(() => {
            if (previousScores?.length) {
              const lastScore = last(previousScores)!.score;
              return lastScore ?? undefined;
            }
            return undefined;
          })()}
          maxScore={maxScore}
        />
        {previousScores?.length ? (
          <SurveyQuestionScoreComparison
            questionType={questionType}
            previousScores={compact([
              ...previousScores,
              currentScoreWithDateRange,
            ])}
          />
        ) : null}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1)};
        `}
      >
        <Button
          variant="outlined"
          size="small"
          width="auto"
          linkTo={`${questionType.toLowerCase()}?dateRange=${convertDateRangeToValue(
            dateRangeInput
          )}`}
          label="View Details"
        />
        {typeof numComments === "number" && (
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(0.5)};
            `}
          >
            <FontAwesomeIcon
              icon={faMessageLines}
              color={theme.palette.grey[800]}
            />
            <Typography variant="body" color={theme.palette.grey[800]}>
              {numComments}
            </Typography>
          </div>
        )}
      </div>
    </Card>
  );
}
