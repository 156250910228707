import { PageLayout } from "../../../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { SettingsCreateReferralStructurePageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1060;

export function SettingsCreateReferralStructurePage() {
  return (
    <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
      <AuthorizedAdminPageWrapper resource="settingsReferralStructure">
        <SettingsCreateReferralStructurePageContents />
      </AuthorizedAdminPageWrapper>
    </PageLayout>
  );
}
