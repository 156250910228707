import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PulseSurveyDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PulseSurveyDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'timezone' | 'launched'>
  ), allTimeSurveyEnagement: (
    { __typename?: 'SurveyEngagementResponse' }
    & Pick<Types.SurveyEngagementResponse, 'numSent'>
  ), getMyOrganizationGoalConfig?: Types.Maybe<(
    { __typename?: 'OrganizationGoalConfig' }
    & Pick<Types.OrganizationGoalConfig, 'id' | 'enabled'>
    & { pulseCheckSurveyGoalDistributionConfig?: Types.Maybe<(
      { __typename?: 'PulseCheckSurveyGoalDistributionConfig' }
      & Pick<Types.PulseCheckSurveyGoalDistributionConfig, 'id' | 'enabled'>
    )> }
  )> }
);


export const PulseSurveyDataDocument = gql`
    query PulseSurveyData {
  getMyRewardsOrganization {
    id
    timezone
    launched
  }
  allTimeSurveyEnagement: getSurveyEngagement(surveyType: PULSE_CHECK) {
    numSent
  }
  getMyOrganizationGoalConfig {
    id
    enabled
    pulseCheckSurveyGoalDistributionConfig {
      id
      enabled
    }
  }
}
    `;

/**
 * __usePulseSurveyDataQuery__
 *
 * To run a query within a React component, call `usePulseSurveyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePulseSurveyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePulseSurveyDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePulseSurveyDataQuery(baseOptions?: Apollo.QueryHookOptions<PulseSurveyDataQuery, PulseSurveyDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PulseSurveyDataQuery, PulseSurveyDataQueryVariables>(PulseSurveyDataDocument, options);
      }
export function usePulseSurveyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PulseSurveyDataQuery, PulseSurveyDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PulseSurveyDataQuery, PulseSurveyDataQueryVariables>(PulseSurveyDataDocument, options);
        }
export type PulseSurveyDataQueryHookResult = ReturnType<typeof usePulseSurveyDataQuery>;
export type PulseSurveyDataLazyQueryHookResult = ReturnType<typeof usePulseSurveyDataLazyQuery>;
export type PulseSurveyDataQueryResult = Apollo.QueryResult<PulseSurveyDataQuery, PulseSurveyDataQueryVariables>;