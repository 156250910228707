/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button as MuiButton, Popover } from "@material-ui/core";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useCognitoAuth } from "../../../../modules/cognito-auth";
import ProfileIcon from "../profile-icon";

interface UserMenuProps {
  name: string;
}

export function UserMenu({ name }: UserMenuProps): JSX.Element | null {
  const { signOut } = useCognitoAuth();
  const snackbar = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Popover
        id="user-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          classes: {
            root: "user-menu-popover-root",
          },
        }}
        css={css`
          & .user-menu-popover-root {
            border-radius: 5px;
            padding: 20px 11px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          }
        `}
      >
        <Button
          size="small"
          color="primary"
          css={css`
            min-width: 116px;
            text-wrap: nowrap;
          `}
          onClick={async () => {
            try {
              await signOut();
              window.location.href = "/login";
            } catch (error) {
              reportError(error);
              snackbar.show({
                severity: "error",
                message: "Could not log out; try again later.",
              });
            }
          }}
          label="Log Out"
        />
      </Popover>
      <MuiButton
        startIcon={
          <ProfileIcon
            css={css`
              font-size: 24px;
            `}
          />
        }
        onClick={handleClick}
        css={(theme: AppTheme) => css`
          flex-shrink: 0;
          margin-left: ${theme.spacing(2)};
          margin-right: ${theme.spacing(2)};
        `}
      >
        <Typography
          css={css`
            color: white;
            white-space: nowrap;
            text-transform: none;
          `}
          variant="body"
        >
          {name}
        </Typography>
      </MuiButton>
    </>
  );
}
