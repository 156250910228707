/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CheckCircleRounded } from "@material-ui/icons";
import { pickBy } from "lodash";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { isNullOrUndefined } from "@rewards-web/shared/lib/is-null-or-undefined";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { EmployeeStatusFilterState } from "../hooks/use-employee-status-filter-state";
import { useGenerateEmployeeExportMutation } from "./generate-employee-export.generated";

interface EmployeeStatusFilterContentProps {
  disabled: boolean;
  children: JSX.Element;
}

function EmployeeStatusFilterContent(
  props: EmployeeStatusFilterContentProps
): JSX.Element | null {
  if (props.disabled) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <CheckCircleRounded
        css={(theme: AppTheme) => css`
          color: ${theme.palette.grey[500]};
          margin-right: ${theme.spacing(1)};
        `}
      />
      {props.children}
    </div>
  );
}

export interface ExportEmployeesModalProps {
  open: boolean;
  filters: EmployeeStatusFilterState;
  exportCount: number;
  onClose(): void;
  branchIds?: (string | null)[];
}

export function ExportEmployeesModal({
  open,
  filters,
  exportCount,
  onClose,
  branchIds,
}: ExportEmployeesModalProps): JSX.Element {
  const track = useTrack();

  const [
    generateEmployeeExportMutation,
    {
      data: exportData,
      loading: exportLoading,
      error: exportError,
      reset: resetExport,
    },
  ] = useGenerateEmployeeExportMutation();

  const downloadLink = exportData?.generateRewardsUserExport?.downloadLink;

  if (exportError) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const appliedFiltersCount = Object.keys(
    pickBy(filters, (value, _) => {
      return !isNullOrUndefined(value);
    })
  ).length;

  const handleSubmit = () => {
    if (!!downloadLink) {
      track("Downloaded employee export with filters", {
        filters,
        exportCount,
      });
      handleClose();
    } else {
      generateEmployeeExportMutation({
        variables: {
          filter: {
            ...filters,
            branchIds: branchIds,
          },
        },
      });
      track("Requested employee export with filters", {
        filters,
        exportCount,
      });
    }
  };

  const handleClose = () => {
    resetExport();
    onClose();
  };

  return (
    <Modal width="500px" open={open} onClose={handleClose}>
      <ModalTitle>Export Employee CSV</ModalTitle>
      <ModalContent>
        <Typography
          variant="body"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          {appliedFiltersCount > 0
            ? `You are exporting ${exportCount} employees with the following (${appliedFiltersCount}) filters.`
            : "You are exporting all employees."}
        </Typography>

        {appliedFiltersCount > 0 && (
          <Card
            css={css`
              background-color: transparent;
            `}
            variant="outlined"
            borderRadius={5}
          >
            <CardContent>
              <EmployeeStatusFilterContent
                disabled={isNullOrUndefined(filters.active)}
              >
                <Typography component="span">
                  {`Employees who are `}
                  <Typography
                    component="span"
                    css={(theme: AppTheme) => css`
                      color: ${theme.palette.primary.main};
                    `}
                  >
                    {filters.active ? "Active" : "Inactive"}
                  </Typography>
                </Typography>
              </EmployeeStatusFilterContent>

              <EmployeeStatusFilterContent
                disabled={isNullOrUndefined(filters.providedPersonalInfo)}
              >
                <Typography component="span">
                  {`Employees who have ${
                    filters.providedPersonalInfo ? "" : "not "
                  }`}
                  <Typography
                    component="span"
                    css={(theme: AppTheme) => css`
                      color: ${theme.palette.primary.main};
                    `}
                  >
                    Signed Up
                  </Typography>
                </Typography>
              </EmployeeStatusFilterContent>
            </CardContent>
          </Card>
        )}
      </ModalContent>

      <ModalActions>
        <Button
          whiteBackground
          variant="outlined"
          onClick={handleClose}
          label="Cancel"
        />

        <Button
          type="submit"
          color={!!downloadLink ? "primary" : "secondary"}
          label={!!downloadLink ? "Download .CSV" : "Generate .CSV"}
          loading={exportLoading}
          download={!!downloadLink}
          linkTo={downloadLink ?? undefined}
          onClick={handleSubmit}
        />
      </ModalActions>
    </Modal>
  );
}
