import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PulseSurveyFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PulseSurveyFormDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar' | 'launched'>
  ), getMyOrganizationGoalConfig?: Types.Maybe<(
    { __typename?: 'OrganizationGoalConfig' }
    & Pick<Types.OrganizationGoalConfig, 'id' | 'enabled' | 'updateRewardOverrideGoalUpdatePolicy' | 'disableDistributionGoalUpdatePolicy'>
    & { pulseCheckSurveyGoalDistributionConfig?: Types.Maybe<(
      { __typename?: 'PulseCheckSurveyGoalDistributionConfig' }
      & Pick<Types.PulseCheckSurveyGoalDistributionConfig, 'enabled' | 'anonymousSubmission' | 'sendInitialSurveyOnLaunch' | 'surveyDaysUntilExpirationOverride' | 'selectedSurveyQuestions'>
      & { surveyRewardOverride?: Types.Maybe<(
        { __typename?: 'GoalConfigRewardOverride' }
        & Pick<Types.GoalConfigRewardOverride, 'numPoints' | 'numOrganizationDrawTickets'>
      )>, surveyCadenceOverride?: Types.Maybe<(
        { __typename?: 'PulseCheckSurveyCadenceOverride' }
        & Pick<Types.PulseCheckSurveyCadenceOverride, 'frequency' | 'startDate'>
      )> }
    )> }
  )>, getMyOrganizationHasAvailableGoals: (
    { __typename?: 'HasAvailableGoalsResponse' }
    & Pick<Types.HasAvailableGoalsResponse, 'hasAvailableGoals'>
  ) }
);


export const PulseSurveyFormDataDocument = gql`
    query PulseSurveyFormData {
  getMyRewardsOrganization {
    id
    pointsPerDollar
    launched
  }
  getMyOrganizationGoalConfig {
    id
    enabled
    updateRewardOverrideGoalUpdatePolicy
    disableDistributionGoalUpdatePolicy
    pulseCheckSurveyGoalDistributionConfig {
      enabled
      anonymousSubmission
      sendInitialSurveyOnLaunch
      surveyRewardOverride {
        numPoints
        numOrganizationDrawTickets
      }
      surveyCadenceOverride {
        frequency
        startDate
      }
      surveyDaysUntilExpirationOverride
      selectedSurveyQuestions
    }
  }
  getMyOrganizationHasAvailableGoals(
    goalType: SURVEY
    filter: {surveyType: PULSE_CHECK}
  ) {
    hasAvailableGoals
  }
}
    `;

/**
 * __usePulseSurveyFormDataQuery__
 *
 * To run a query within a React component, call `usePulseSurveyFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePulseSurveyFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePulseSurveyFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePulseSurveyFormDataQuery(baseOptions?: Apollo.QueryHookOptions<PulseSurveyFormDataQuery, PulseSurveyFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PulseSurveyFormDataQuery, PulseSurveyFormDataQueryVariables>(PulseSurveyFormDataDocument, options);
      }
export function usePulseSurveyFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PulseSurveyFormDataQuery, PulseSurveyFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PulseSurveyFormDataQuery, PulseSurveyFormDataQueryVariables>(PulseSurveyFormDataDocument, options);
        }
export type PulseSurveyFormDataQueryHookResult = ReturnType<typeof usePulseSurveyFormDataQuery>;
export type PulseSurveyFormDataLazyQueryHookResult = ReturnType<typeof usePulseSurveyFormDataLazyQuery>;
export type PulseSurveyFormDataQueryResult = Apollo.QueryResult<PulseSurveyFormDataQuery, PulseSurveyFormDataQueryVariables>;