import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateReferralsEnabledForUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  referralLinkDisabled: Types.Scalars['Boolean'];
}>;


export type UpdateReferralsEnabledForUserMutation = (
  { __typename?: 'Mutation' }
  & { updateRewardsUserInformation: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'userReferralLinkDisabled'>
  ) }
);


export const UpdateReferralsEnabledForUserDocument = gql`
    mutation UpdateReferralsEnabledForUser($userId: ID!, $referralLinkDisabled: Boolean!) {
  updateRewardsUserInformation(
    userId: $userId
    referralLinkDisabled: $referralLinkDisabled
  ) {
    id
    userReferralLinkDisabled
  }
}
    `;
export type UpdateReferralsEnabledForUserMutationFn = Apollo.MutationFunction<UpdateReferralsEnabledForUserMutation, UpdateReferralsEnabledForUserMutationVariables>;

/**
 * __useUpdateReferralsEnabledForUserMutation__
 *
 * To run a mutation, you first call `useUpdateReferralsEnabledForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralsEnabledForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralsEnabledForUserMutation, { data, loading, error }] = useUpdateReferralsEnabledForUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      referralLinkDisabled: // value for 'referralLinkDisabled'
 *   },
 * });
 */
export function useUpdateReferralsEnabledForUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReferralsEnabledForUserMutation, UpdateReferralsEnabledForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReferralsEnabledForUserMutation, UpdateReferralsEnabledForUserMutationVariables>(UpdateReferralsEnabledForUserDocument, options);
      }
export type UpdateReferralsEnabledForUserMutationHookResult = ReturnType<typeof useUpdateReferralsEnabledForUserMutation>;
export type UpdateReferralsEnabledForUserMutationResult = Apollo.MutationResult<UpdateReferralsEnabledForUserMutation>;
export type UpdateReferralsEnabledForUserMutationOptions = Apollo.BaseMutationOptions<UpdateReferralsEnabledForUserMutation, UpdateReferralsEnabledForUserMutationVariables>;