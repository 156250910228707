/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import { CandidateDetailsTable } from "../candidate-details-table";
import { ChangeCandidateReferringEmployeeForm } from "./change-candidate-referring-employee-form";

interface CandidateReferringEmployeeProps {
  candidate: Pick<GraphQLTypes.Candidate, "id"> & {
    referredByUser: Pick<
      GraphQLTypes.RewardsUser,
      "id" | "firstName" | "lastName"
    >;
  };
  hasEditPermission: boolean;
  onChangedReferringEmployee(): void;
}

export function CandidateReferringEmployee({
  candidate,
  hasEditPermission,
  onChangedReferringEmployee,
}: CandidateReferringEmployeeProps) {
  const track = useTrack();
  const [editingReferringEmployee, setEditingReferringEmployee] = useState(
    false
  );

  return (
    <>
      {editingReferringEmployee ? (
        <ChangeCandidateReferringEmployeeForm
          candidate={candidate}
          onChangedReferringEmployee={() => {
            setEditingReferringEmployee(false);
            onChangedReferringEmployee();
          }}
          onCancel={() => {
            setEditingReferringEmployee(false);
          }}
        />
      ) : (
        <>
          <ObscureRecordedText>
            <CandidateDetailsTable
              items={[
                {
                  label: "Referring employee",
                  value: `${candidate.referredByUser.firstName} ${candidate.referredByUser.lastName}`,
                },
              ]}
            />
          </ObscureRecordedText>

          <Button
            css={css`
              display: block;
              margin-left: auto;
            `}
            color="primary"
            label="Change referring employee"
            disabled={!hasEditPermission}
            onClick={() => {
              setEditingReferringEmployee(true);
              track("Candidate change referring employee clicked", {
                candidateId: candidate.id,
              });
            }}
          />
        </>
      )}
    </>
  );
}
