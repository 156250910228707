import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PartialUpdateAdminGoalConfigMutationVariables = Types.Exact<{
  configId: Types.Scalars['ID'];
  recurring?: Types.Maybe<Types.Scalars['Boolean']>;
  updateTargets: Array<Types.AdminGoalUpdateTarget> | Types.AdminGoalUpdateTarget;
  props: Types.AdminGoalUpdateInput;
}>;


export type PartialUpdateAdminGoalConfigMutation = (
  { __typename?: 'Mutation' }
  & { partialUpdateOrganizationAdminGoalConfig: (
    { __typename?: 'AdminGoalConfig' }
    & Pick<Types.AdminGoalConfig, 'id'>
  ) }
);


export const PartialUpdateAdminGoalConfigDocument = gql`
    mutation PartialUpdateAdminGoalConfig($configId: ID!, $recurring: Boolean, $updateTargets: [AdminGoalUpdateTarget!]!, $props: AdminGoalUpdateInput!) {
  partialUpdateOrganizationAdminGoalConfig(
    configId: $configId
    recurring: $recurring
    updateTargets: $updateTargets
    props: $props
  ) {
    id
  }
}
    `;
export type PartialUpdateAdminGoalConfigMutationFn = Apollo.MutationFunction<PartialUpdateAdminGoalConfigMutation, PartialUpdateAdminGoalConfigMutationVariables>;

/**
 * __usePartialUpdateAdminGoalConfigMutation__
 *
 * To run a mutation, you first call `usePartialUpdateAdminGoalConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePartialUpdateAdminGoalConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [partialUpdateAdminGoalConfigMutation, { data, loading, error }] = usePartialUpdateAdminGoalConfigMutation({
 *   variables: {
 *      configId: // value for 'configId'
 *      recurring: // value for 'recurring'
 *      updateTargets: // value for 'updateTargets'
 *      props: // value for 'props'
 *   },
 * });
 */
export function usePartialUpdateAdminGoalConfigMutation(baseOptions?: Apollo.MutationHookOptions<PartialUpdateAdminGoalConfigMutation, PartialUpdateAdminGoalConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PartialUpdateAdminGoalConfigMutation, PartialUpdateAdminGoalConfigMutationVariables>(PartialUpdateAdminGoalConfigDocument, options);
      }
export type PartialUpdateAdminGoalConfigMutationHookResult = ReturnType<typeof usePartialUpdateAdminGoalConfigMutation>;
export type PartialUpdateAdminGoalConfigMutationResult = Apollo.MutationResult<PartialUpdateAdminGoalConfigMutation>;
export type PartialUpdateAdminGoalConfigMutationOptions = Apollo.BaseMutationOptions<PartialUpdateAdminGoalConfigMutation, PartialUpdateAdminGoalConfigMutationVariables>;