// import { ThemeProvider as Mui5ThemeProvider } from "@mui/material";
import { ThemeProvider as EmotionProvider } from "@emotion/react";
import {
  CssBaseline,
  darken,
  lighten,
  MuiThemeProvider as Mui4ThemeProvider,
} from "@material-ui/core";
import { merge } from "lodash";
import { createContext, ReactNode, useContext, useMemo } from "react";

import { DeepPartial } from "../types";
import { useMui4Theme } from "./theme-mui4";
import { useMui5Theme } from "./theme-mui5";
import type { AppTheme, AppThemeProperties } from "./types";

export type { AppTheme, AppThemeProperties };

export const fontFamily = ["Inter", "Helvetica", "Arial", "sans-serif"].join(
  ","
);
const defaultFont = {
  fontFamily,
  fontWeight: 400,
  fontSize: "1.125rem", // 18px
  lineHeight: "1.688rem", // 27px,
};
export const headerFontFamily = `Lexend, sans-serif,${fontFamily}`;

export const DEFAULT_APP_THEME: AppThemeProperties = {
  defaultButtonSize: "medium",
  defaultTextFieldSize: "medium",
  buttonBorderRadius: 500,
  mediaQueryNoSSR: true,
  maxContentWidth: 790,
  typography: {
    allVariants: defaultFont,
    h1: {
      fontFamily: headerFontFamily,
      fontWeight: 700,
      fontSize: "2.5rem", // 40px
      lineHeight: "3.125rem", // 50px
      "@media (max-width:900px)": {
        fontSize: "2.125rem", // 34px
        lineHeight: "2.75rem", // 44px
      },
    },
    h2: {
      fontFamily: headerFontFamily,
      fontWeight: 700,
      fontSize: "2.25rem", // 36px
      lineHeight: "2.875rem", // 46px
      "@media (max-width:900px)": {
        fontSize: "1.875rem", // 30px
        lineHeight: "2.5rem", // 40px
      },
    },
    h3: {
      fontFamily: headerFontFamily,
      fontWeight: 700,
      fontSize: "2rem", // 32px
      lineHeight: "2.625rem", // 42px
      "@media (max-width:900px)": {
        fontSize: "1.625rem", // 26px
        lineHeight: "2.25rem", // 36px
        fontWeight: 700,
      },
    },
    h4: {
      fontFamily: headerFontFamily,
      fontWeight: 700,
      fontSize: "1.75rem", // 28px
      lineHeight: "2.375rem", // 38px
      "@media (max-width:900px)": {
        fontSize: "1.375rem", // 22px
        lineHeight: "2.063rem", // 33px
        fontWeight: 700,
      },
    },
    h5: {
      fontFamily: headerFontFamily,
      fontWeight: 700,
      fontSize: "1.5rem", // 24px
      lineHeight: "2.125rem", // 34px
      "@media (max-width:900px)": {
        fontSize: "1.375rem", // 22px
        lineHeight: "1.875rem", // 30px
        fontWeight: 700,
      },
    },
    h6: {
      fontFamily: headerFontFamily,
      fontWeight: 700,
      fontSize: "1.25rem", // 20px
      lineHeight: "1.875rem", // 30px
      "@media (max-width:900px)": {
        fontWeight: 700,
        fontSize: "1.25rem", // 20px
        lineHeight: "1.75rem", // 28px
      },
    },
    body: defaultFont,
    subtitle: {
      fontFamily: headerFontFamily,
      fontWeight: 600,
      fontSize: "1.125rem", // 18px
      lineHeight: "1.688rem", // 27px
    },
    caption: {
      fontFamily: headerFontFamily,
      fontWeight: 600,
      letterSpacing: "0.5px",
      fontSize: "0.75rem", // 12px
      lineHeight: "1.25rem", // 20px
    },
    footnote: {
      fontFamily,
      fontWeight: 400,
      fontSize: "0.875rem", // 14px
      lineHeight: "1.375rem", // 22px
    },
    buttonLarge: {
      fontFamily: headerFontFamily,
      fontWeight: 600,
      fontSize: "1.125rem", // 18px
      textTransform: "none",
    },
    buttonSmall: {
      fontFamily: headerFontFamily,
      fontWeight: 600,
      fontSize: "0.875rem", // 14px
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 12,
  },
  fonts: {
    main: fontFamily,
    headers: headerFontFamily,
  },
  palette: {
    primary: {
      main: "#1D51D6",
      contrastText: "#fff",
    },
    secondary: {
      main: "#EA2276",
      contrastText: "#FFFFFF",
    },
    gold: {
      light: "#FFC700",
      dark: "#FAA33D",
    },
    tertiary: {
      main: "#00C7C7",
      light: lighten("#00C7C7", 0.3),
      dark: darken("#00C7C7", 0.3),
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#EE3B3B",
    },
    warning: {
      main: "#FAA33D",
    },
    success: {
      main: "#1AB971",
    },
    background: {
      default: "#EDF4F6",
      paper: "#ffffff",
    },

    text: {
      primary: "#2C393F",
      secondary: "#747676",
    },
    grey: {
      200: "#F1F1F1",
      400: "#E5E5E5",
      600: "#B1B3B3",
      800: "#747676",
    },
    divider: "#dfe4eb",
  },
};

const AppThemeContext = createContext<AppThemeProperties>(DEFAULT_APP_THEME);

/**
 * Returns the app theme at the current point in the tree.
 */
export function useAppTheme() {
  return useContext(AppThemeContext);
}

interface AppThemeProviderProps {
  children: ReactNode;
  theme: DeepPartial<AppThemeProperties>;
  renderCssBaseline?: boolean;
}

/**
 * Provides theme overrides for the app.
 *
 * It applies the following:
 * - App theme context so we can use `useAppTheme()` to get the theme
 * - Replaces the emotion theme with the theme, so we can access it when styling
 * - Converts it to an MUI4 theme and applies the MUI provider for MUI components
 */
export function AppThemeProvider({
  children,
  theme: themeProp,
  renderCssBaseline,
}: AppThemeProviderProps) {
  const parentTheme = useAppTheme();
  const theme = useMemo(() => merge({}, parentTheme, themeProp), [
    parentTheme,
    themeProp,
  ]);
  const mui4Theme = useMui4Theme(theme);
  const mui5Theme = useMui5Theme(theme);

  return (
    <AppThemeContext.Provider value={theme}>
      <Mui4ThemeProvider theme={mui4Theme}>
        <EmotionProvider theme={mui5Theme}>
          {renderCssBaseline && <CssBaseline />}
          {children}
        </EmotionProvider>
      </Mui4ThemeProvider>
    </AppThemeContext.Provider>
  );
}
