import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListBranchQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListBranchQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'longName'>
    & { branches: Array<(
      { __typename?: 'RewardsOrganizationBranch' }
      & Pick<Types.RewardsOrganizationBranch, 'id' | 'name' | 'activeUserCount'>
      & { autoMembershipTagCriteria?: Types.Maybe<(
        { __typename?: 'RewardsGroupTagsConfigSchemaV1' }
        & Pick<Types.RewardsGroupTagsConfigSchemaV1, 'schema'>
        & { or: Array<(
          { __typename?: 'RewardsGroupTagsConfigSchemaV1AndSchema' }
          & { and: Array<(
            { __typename?: 'RewardsTag' }
            & Pick<Types.RewardsTag, 'id'>
          )> }
        )> }
      )>, tags: Array<(
        { __typename?: 'RewardsTag' }
        & Pick<Types.RewardsTag, 'id' | 'externalId' | 'displayName' | 'activeUserCount'>
      )> }
    )> }
  ) }
);


export const ListBranchDocument = gql`
    query ListBranch {
  getMyRewardsOrganization {
    id
    longName
    branches {
      ... on RewardsOrganizationBranch {
        id
        name
        autoMembershipTagCriteria {
          ... on RewardsGroupTagsConfigSchemaV1 {
            schema
            or {
              ... on RewardsGroupTagsConfigSchemaV1AndSchema {
                and {
                  ... on RewardsTag {
                    id
                  }
                }
              }
            }
          }
        }
        tags {
          ... on RewardsTag {
            id
            externalId
            displayName
            activeUserCount
          }
        }
        activeUserCount
      }
    }
  }
}
    `;

/**
 * __useListBranchQuery__
 *
 * To run a query within a React component, call `useListBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBranchQuery({
 *   variables: {
 *   },
 * });
 */
export function useListBranchQuery(baseOptions?: Apollo.QueryHookOptions<ListBranchQuery, ListBranchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBranchQuery, ListBranchQueryVariables>(ListBranchDocument, options);
      }
export function useListBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBranchQuery, ListBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBranchQuery, ListBranchQueryVariables>(ListBranchDocument, options);
        }
export type ListBranchQueryHookResult = ReturnType<typeof useListBranchQuery>;
export type ListBranchLazyQueryHookResult = ReturnType<typeof useListBranchLazyQuery>;
export type ListBranchQueryResult = Apollo.QueryResult<ListBranchQuery, ListBranchQueryVariables>;