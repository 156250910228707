/** @jsxImportSource @emotion/react */
import { useRef } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { reportError } from "@rewards-web/shared/modules/error";

import { InsightMetricGroup } from "../../components/insight-metric-group";
import chartIconUrl from "../../graphics/chart-icon.png";
import { useAllTimePerformanceInsightMetricGroupDataQuery } from "./all-time-performance-metric-group-query.generated";
import { AllTimeProjectedValueInsightMetric } from "./insight-metrics/projected-value";
import { AllTimeTotalHiresInsightMetric } from "./insight-metrics/total-hires";
import { AllTimeUserLoginsInsightMetric } from "./insight-metrics/user-logins";

export interface AllTimePerformanceInsightMetricGroupProps {
  launchDate: Date;
  pointsPerDollar: number;
}

export function AllTimePerformanceInsightMetricGroup({
  launchDate,
  pointsPerDollar,
}: AllTimePerformanceInsightMetricGroupProps) {
  const now = useRef(new Date());

  const allTimePerformanceQuery = useAllTimePerformanceInsightMetricGroupDataQuery(
    {
      fetchPolicy: "cache-first",
      variables: {
        startDate: launchDate.valueOf(),
        endDate: now.current,
      },
      onError: reportError,
    }
  );

  if (allTimePerformanceQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  return (
    <InsightMetricGroup
      label="All-time performance"
      icon={<img src={chartIconUrl} width={24} alt="chart" />}
      tooltipText="This section displays the total performance of logins, referral hires, and projected value since the start of the program."
      trackingProperties={{
        startDate: new Date(launchDate),
        endDate: now.current,
      }}
    >
      <AllTimeUserLoginsInsightMetric
        userLoginsInsightMetric={
          allTimePerformanceQuery.data?.getLoggedInUsersInsightMetric
        }
      />
      <AllTimeTotalHiresInsightMetric
        totalHiresInsightMetric={
          allTimePerformanceQuery.data?.getTotalHiresInsightMetric
        }
      />
      <AllTimeProjectedValueInsightMetric
        totalHiresInsightMetric={
          allTimePerformanceQuery.data?.getTotalHiresInsightMetric
        }
        pointsPerDollar={pointsPerDollar}
      />
    </InsightMetricGroup>
  );
}
