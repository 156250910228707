/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

interface TableProps extends StylableProps {
  children?: ReactNode;
  border?: boolean;
}

export function Table({
  className,
  children,
  border,
}: TableProps): JSX.Element {
  return (
    <table
      className={className}
      css={(theme: AppTheme) => css`
        border-collapse: collapse;
        width: 100%;
        ${border &&
        css`
          border-collapse: separate;
          border-radius: 8px;
          border-spacing: 0;
          border: 1px solid ${theme.palette.grey[200]};
        `}
      `}
    >
      {children}
    </table>
  );
}
