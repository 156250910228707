import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EmployeeDetailReferralCardDataQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type EmployeeDetailReferralCardDataQuery = (
  { __typename?: 'Query' }
  & { getRewardsUserById?: Types.Maybe<(
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'referralsEnabled' | 'userReferralLinkDisabled'>
  )>, getReferralStatsForUser: (
    { __typename?: 'RewardsUserReferralStats' }
    & Pick<Types.RewardsUserReferralStats, 'id' | 'totalNumberOfShares' | 'totalNumberOfApplicants' | 'totalNumberOfCandidatesHired'>
  ), getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'referralResumesEnabled' | 'launched' | 'maxPointsEarnedPerCandidate' | 'pointsPerDollar'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id' | 'rewardsProgramShortName'>
    )> }
  ) }
);


export const EmployeeDetailReferralCardDataDocument = gql`
    query EmployeeDetailReferralCardData($userId: ID!) {
  getRewardsUserById(userId: $userId) {
    id
    referralsEnabled
    userReferralLinkDisabled
  }
  getReferralStatsForUser(userId: $userId) {
    id
    totalNumberOfShares
    totalNumberOfApplicants
    totalNumberOfCandidatesHired
  }
  getMyRewardsOrganization {
    id
    referralResumesEnabled
    whiteLabelConfig {
      id
      rewardsProgramShortName
    }
    launched
    maxPointsEarnedPerCandidate
    pointsPerDollar
  }
}
    `;

/**
 * __useEmployeeDetailReferralCardDataQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailReferralCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailReferralCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailReferralCardDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEmployeeDetailReferralCardDataQuery(baseOptions: Apollo.QueryHookOptions<EmployeeDetailReferralCardDataQuery, EmployeeDetailReferralCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeDetailReferralCardDataQuery, EmployeeDetailReferralCardDataQueryVariables>(EmployeeDetailReferralCardDataDocument, options);
      }
export function useEmployeeDetailReferralCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDetailReferralCardDataQuery, EmployeeDetailReferralCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeDetailReferralCardDataQuery, EmployeeDetailReferralCardDataQueryVariables>(EmployeeDetailReferralCardDataDocument, options);
        }
export type EmployeeDetailReferralCardDataQueryHookResult = ReturnType<typeof useEmployeeDetailReferralCardDataQuery>;
export type EmployeeDetailReferralCardDataLazyQueryHookResult = ReturnType<typeof useEmployeeDetailReferralCardDataLazyQuery>;
export type EmployeeDetailReferralCardDataQueryResult = Apollo.QueryResult<EmployeeDetailReferralCardDataQuery, EmployeeDetailReferralCardDataQueryVariables>;