import { SurveyQuestionType } from "@rewards-web/shared/graphql-types";

export const SURVEY_QUESTION_TYPES_SUPPORTED_BY_UI = [
  SurveyQuestionType.MeaningfulRecognition,
  SurveyQuestionType.ManageableWorkload,
  SurveyQuestionType.ReliableTransportation,
  SurveyQuestionType.LeadershipSupport,
  SurveyQuestionType.Resources,
  SurveyQuestionType.Nps,
  SurveyQuestionType.QuickCheckWeeklySentiment,
  SurveyQuestionType.QuickCheckEmployeeValue,
  SurveyQuestionType.QuickCheckFeedbackEffectiveness,
  SurveyQuestionType.QuickCheckSupervisorCommunication,
  SurveyQuestionType.QuickCheckSupervisorReachability,
  SurveyQuestionType.QuickCheckNps,
  SurveyQuestionType.QuickCheckClientImpact,
  SurveyQuestionType.QuickCheckManageableWorkload,
  SurveyQuestionType.QuickCheckScheduleBalance,
  SurveyQuestionType.QuickCheckScheduleSatisfaction,
  SurveyQuestionType.QuickCheckRecognitionRegularity,
  SurveyQuestionType.QuickCheckRecognitionImpact,
  SurveyQuestionType.QuickCheckClientSatisfaction,
] as const;

export type SurveyQuestionTypeSupportedByUI = typeof SURVEY_QUESTION_TYPES_SUPPORTED_BY_UI[number];

export const PULSE_SURVEY_QUESTIONS: SurveyQuestionTypeSupportedByUI[] = [
  SurveyQuestionType.MeaningfulRecognition,
  SurveyQuestionType.ManageableWorkload,
  SurveyQuestionType.ReliableTransportation,
  SurveyQuestionType.LeadershipSupport,
  SurveyQuestionType.Resources,
  SurveyQuestionType.Nps,
];

export const CHECK_IN_SURVEY_QUESTIONS: SurveyQuestionTypeSupportedByUI[] = [
  SurveyQuestionType.QuickCheckWeeklySentiment,
  SurveyQuestionType.QuickCheckEmployeeValue,
  SurveyQuestionType.QuickCheckClientSatisfaction,
  SurveyQuestionType.QuickCheckClientImpact,
  SurveyQuestionType.QuickCheckSupervisorCommunication,
  SurveyQuestionType.QuickCheckFeedbackEffectiveness,
  SurveyQuestionType.QuickCheckSupervisorReachability,
  SurveyQuestionType.QuickCheckManageableWorkload,
  SurveyQuestionType.QuickCheckScheduleBalance,
  SurveyQuestionType.QuickCheckScheduleSatisfaction,
  SurveyQuestionType.QuickCheckRecognitionRegularity,
  SurveyQuestionType.QuickCheckRecognitionImpact,
  SurveyQuestionType.QuickCheckNps,
];
