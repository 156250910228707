/** @jsxImportSource @emotion/react */
import { css, ClassNames } from "@emotion/react";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, IconButton } from "@material-ui/core";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/types";

import { StylableProps } from "../../types";

interface ModalProps extends StylableProps {
  open: boolean;
  onClose?(): void;
  onExited?(): void;
  children: ReactNode;
  width?: string;
  minHeight?: string;
  disableBackdropClick?: boolean;
  disablePadding?: boolean;
  background?: "default" | "white";
  showCloseButton?: boolean;
}

export function Modal({
  className,
  width,
  open,
  onClose,
  onExited,
  children,
  disableBackdropClick,
  minHeight,
  disablePadding,
  background = "default",
  showCloseButton = false,
}: ModalProps) {
  const maybeCloseButton = showCloseButton && (
    <IconButton
      aria-label="close"
      onClick={() => onClose?.()}
      css={(theme: AppTheme) => css`
        position: absolute;
        right: 8px;
        top: 8px;
        color: ${theme.palette.grey[500]};
      `}
    >
      <FontAwesomeIcon icon={faClose} width={24} height={24} />
    </IconButton>
  );
  return (
    <ClassNames>
      {({ css, theme }) => (
        <Dialog
          classes={{
            paper: css`
              background-color: ${background === "default"
                ? "#f9f9fb"
                : "#ffffff"};
              padding: ${disablePadding ? 0 : theme.spacing(2)};
              width: ${width};
              max-width: initial;
              ${minHeight &&
              `${css`
                min-height: ${minHeight};
              `}`}
              border-radius: 20px;
            `,
          }}
          className={className}
          open={open}
          maxWidth="xs"
          onClose={(_, reason) => {
            if (disableBackdropClick && reason === "backdropClick") {
              return; // not disabling backdrop click
            }

            onClose?.();
          }}
          onExited={onExited}
        >
          {maybeCloseButton}
          {children}
        </Dialog>
      )}
    </ClassNames>
  );
}
