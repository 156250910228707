import { useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { useDebounce } from "use-debounce";

import { Alert } from "@rewards-web/shared/components/alert";
import { SearchTextField } from "@rewards-web/shared/components/search-text-field";

import { usePermissionLimitedBranchIds } from "../../../../shared/modules/branches/use-permission-limited-branch-ids";
import { useReferringUserSearchQuery } from "../add-candidate-modal/search-for-referring-user.generated";

const SEARCH_DEBOUNCE_MS = 300;

interface ReferredByEmployeeSearchFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  skip?: boolean;
}

export function ReferredByEmployeeSearchField<T extends FieldValues>({
  control,
  name,
  skip,
}: ReferredByEmployeeSearchFieldProps<T>) {
  const [referringUserSearchQuery, setReferringUserSearchQuery] = useState("");
  const [debouncedUserSearchQuery] = useDebounce(
    referringUserSearchQuery,
    SEARCH_DEBOUNCE_MS
  );
  const {
    data: permissionLimitedBranchIds,
    error: branchIdsError,
  } = usePermissionLimitedBranchIds();

  const referringUserSearch = useReferringUserSearchQuery({
    fetchPolicy: "cache-first",
    skip: !permissionLimitedBranchIds || skip,
    variables: {
      searchQuery: debouncedUserSearchQuery || null,
      filter: {
        branchIds: permissionLimitedBranchIds?.canOnlySeeBranchIds || undefined,
      },
    },
  });

  if (branchIdsError) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: "Referring employee is required",
      }}
      render={({ field, fieldState }) => (
        <SearchTextField
          {...field}
          error={fieldState.error}
          label="Referring employee"
          options={(
            (referringUserSearch.data ?? referringUserSearch.previousData)
              ?.searchForRewardsUsers ?? []
          ).map((user) => ({
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          }))}
          loadingOptions={referringUserSearch.loading}
          onInputChange={(text) => setReferringUserSearchQuery(text)}
          debouncedInputValue={debouncedUserSearchQuery}
          closedWhenInputTextEmpty
          placeholder="Type to search..."
          obscureOptionsFromRecordings
        />
      )}
    />
  );
}
