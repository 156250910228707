import { compact } from "lodash";
import { useState } from "react";
import {
  Controller,
  Control,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { useDebounce } from "use-debounce";

import {
  SearchTextField,
  ValueLabel,
} from "@rewards-web/shared/components/search-text-field";

import { usePermissionLimitedBranchIds } from "../../../../shared/modules/branches/use-permission-limited-branch-ids";
import { useJobPostingSearchOptionsQuery } from "./job-posting-search-options.generated";

const SEARCH_DEBOUNCE_MS = 300;

interface JobSearchFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  displayCandidateAlertEmail?: boolean;
  rules?: Omit<
    RegisterOptions<T, Path<T>>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  fixLabelToTop?: boolean;
  includeFromSiblingOrganizations?: boolean;
}

export function JobSearchField<T extends FieldValues>({
  control,
  name,
  label,
  displayCandidateAlertEmail,
  rules,
  fixLabelToTop,
  includeFromSiblingOrganizations,
}: JobSearchFieldProps<T>) {
  const [jobPostingSearchQuery, setJobPostingSearchQuery] = useState("");
  const [debouncedJobPostingSearchQuery] = useDebounce(
    jobPostingSearchQuery,
    SEARCH_DEBOUNCE_MS
  );

  const {
    data: permissionLimitedBranchIds,
    loading: permissionLimitedBranchIdsLoading,
  } = usePermissionLimitedBranchIds();

  const jobPostingSearchOptionsQuery = useJobPostingSearchOptionsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: {
      searchQuery: debouncedJobPostingSearchQuery,
      limit: debouncedJobPostingSearchQuery ? 30 : 200, // high limit for initial load
      includeFromSiblingOrganizations,
    },
    skip: !permissionLimitedBranchIds,
  });

  const branchIdSet = permissionLimitedBranchIds?.canOnlySeeBranchIds
    ? new Set(permissionLimitedBranchIds.canOnlySeeBranchIds)
    : null;

  const options = (
    (
      jobPostingSearchOptionsQuery.data ??
      jobPostingSearchOptionsQuery.previousData
    )?.listJobPostings.items ?? []
  )
    .filter((job) => {
      if (!branchIdSet) {
        return true;
      }

      return job.branch && branchIdSet.has(job.branch.id);
    })
    .map(
      (job): ValueLabel => ({
        value: job.id,
        label: job.title,
        subLabels: compact([
          job.geography,
          displayCandidateAlertEmail && job.candidateAlertEmail,
        ]),
        groupName: job.closedForSubmission ? "Closed Jobs" : "Open Jobs",
      })
    );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <SearchTextField
          {...field}
          error={fieldState.error}
          label={label}
          options={options}
          loadingOptions={
            jobPostingSearchOptionsQuery.loading ||
            permissionLimitedBranchIdsLoading
          }
          onInputChange={(text) => setJobPostingSearchQuery(text)}
          debouncedInputValue={debouncedJobPostingSearchQuery}
          fixLabelToTop={fixLabelToTop}
          placeholder="Search for a job..."
        />
      )}
    />
  );
}
