/** @jsxImportSource @emotion/react */
import { useTheme, css } from "@emotion/react";
import { FormState } from "react-hook-form/dist/types/form";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { EditAdminFormValues } from "../edit-admin-form";

interface AdminAddedConfirmationModalProps {
  open: boolean;
  formState: FormState<EditAdminFormValues>;
  setAdminEditedConfirmationModalOpen(value: boolean): void;
  onConfirm(): void;
}

export function AdminEditedConfirmationModal({
  open,
  formState,
  setAdminEditedConfirmationModalOpen,
  onConfirm,
}: AdminAddedConfirmationModalProps): JSX.Element {
  const theme = useTheme();

  if (Object.values(formState.errors).length > 0) {
    setAdminEditedConfirmationModalOpen(false);
  }

  return (
    <Modal
      width="500px"
      open={open}
      onClose={() => setAdminEditedConfirmationModalOpen(false)}
    >
      <ModalTitle>
        <div
          css={css`
            text-align: center;
            width: 100%;
          `}
        >
          <Typography
            variant="h4"
            textAlign="center"
            css={css`
              margin-bottom: -${theme.spacing(2.5)};
            `}
          >
            Confirm admin updates?
          </Typography>
        </div>
      </ModalTitle>
      <ModalContent>
        {(formState.dirtyFields.budgetPointsAccessEnabled ||
          formState.dirtyFields.defaultBudgetPoints) && (
          <Typography align="center" variant="body">
            Updating this Admin’s budget impacts how many recognition points
            they are able to give out to employees.
          </Typography>
        )}
      </ModalContent>
      <ModalActions>
        <div
          css={css`
            text-align: center;
            width: 100%;
          `}
        >
          <Button
            css={css`
              width: 140px;
            `}
            label="Cancel"
            variant="outlined"
            onClick={() => setAdminEditedConfirmationModalOpen(false)}
          />
          <Button
            css={(theme: AppTheme) => css`
              width: 140px;
              margin-left: ${theme.spacing(2)};
            `}
            label="Confirm"
            color="primary"
            onClick={onConfirm}
          />
        </div>
      </ModalActions>
    </Modal>
  );
}
