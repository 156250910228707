/** @jsxImportSource @emotion/react */
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";

import { SurveyInfoCard } from "../components/survey-info-card";
import { useCheckInSurveyDataQuery } from "./check-in-survey-data.generated";

export interface CheckInSurveysPageContentsProps {
  surveySettingsEnabled: boolean;
}

export function CheckInSurveysPageContents(
  props: CheckInSurveysPageContentsProps
) {
  const { data, loading } = useCheckInSurveyDataQuery();

  const checkInSurveysEnabled = (() => {
    if (data?.getMyOrganizationGoalConfig?.enabled) {
      return (
        data.getMyOrganizationGoalConfig.quickCheckSurveyGoalDistributionConfig
          ?.enabled ?? false
      );
    }

    return false;
  })();

  if (loading) {
    return <PageLoadingState />;
  }

  if (checkInSurveysEnabled) {
    return (
      <SurveyInfoCard
        title="Check-in surveys are active"
        content={
          <>
            <Typography variant="footnote" color="grey.800" textAlign="center">
              Results will be emailed to you at the end of each survey period.
            </Typography>
          </>
        }
      />
    );
  }

  if (props.surveySettingsEnabled) {
    return (
      <SurveyInfoCard
        title="Get started with check-in surveys"
        content={
          <>
            <Typography variant="footnote" color="grey.800" textAlign="center">
              Looks like there aren't any surveys yet. Head over to the settings
              page to create your first survey and start gathering employee
              feedback.
            </Typography>
            <Button
              label="Set up a survey"
              variant="contained"
              width="auto"
              color="primary"
              linkTo="/survey-settings/check-in"
              linkState={{ backTo: "/surveys/check-in" }}
            />
          </>
        }
      />
    );
  } else {
    return (
      <SurveyInfoCard
        title="Get started with check-in surveys"
        content={
          <Typography variant="footnote" color="grey.800" textAlign="center">
            Looks like there aren't any surveys yet. Create your first survey
            and start gathering employee feedback.
          </Typography>
        }
      />
    );
  }
}
