/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import {
  CandidateHourlyRetentionStepFilterOption,
  CandidateMonthlyRetentionStepFilterOption,
  CandidateRecruitmentStepFilterOption,
  JobPosting,
  NumActiveCandidatesByJobPostingAndNextStepItem,
  NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment,
  NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import { PageCard } from "../../../../shared/components/page-card";
import { PageCardContent } from "../../../../shared/components/page-card/page-card-content";
import { PageCardTitle } from "../../../../shared/components/page-card/page-card-title";
import { getStepLabel } from "../lib";
import { HiringPipelineEmptyState } from "./hiring-pipeline-empty-state";
import { HiringPipelineJobDetails } from "./hiring-pipeline-job-details";
import { PipelineTable } from "./pipeline-table";
import { PipelineTableCell } from "./pipeline-table/pipeline-table-cell";
import { PipelineTableHeaderCell } from "./pipeline-table/pipeline-table-header-cell";
import { StatusLink } from "./status-link";

export interface HiringPipelineProps {
  jobPostings: Array<
    { __typename?: "NumActiveCandidatesByJobPostingAndNextStepItem" } & Pick<
      NumActiveCandidatesByJobPostingAndNextStepItem,
      "total"
    > & {
        jobPosting: { __typename?: "JobPosting" } & Pick<
          JobPosting,
          "id" | "title" | "geography" | "closedForSubmission"
        >;
        totalsByNextStep: Array<
          | ({
              __typename: "NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment";
            } & Pick<
              NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment,
              "numActiveCandidates" | "recruitmentStepName"
            >)
          | ({
              __typename: "NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention";
            } & Pick<
              NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention,
              | "numActiveCandidates"
              | "retentionDurationMonths"
              | "retentionDurationHours"
            >)
        >;
      }
  >;
  candidateNextSteps: Array<
    | ({ __typename?: "CandidateRecruitmentStepFilterOption" } & Pick<
        CandidateRecruitmentStepFilterOption,
        "stepName"
      >)
    | ({ __typename?: "CandidateMonthlyRetentionStepFilterOption" } & Pick<
        CandidateMonthlyRetentionStepFilterOption,
        "durationMonths"
      >)
    | ({ __typename?: "CandidateHourlyRetentionStepFilterOption" } & Pick<
        CandidateHourlyRetentionStepFilterOption,
        "durationHours"
      >)
  >;
}

export function HiringPipeline({
  jobPostings,
  candidateNextSteps,
}: HiringPipelineProps): JSX.Element {
  const track = useTrack();

  const totalCandidates = jobPostings.reduce(
    (prev, job) => prev + job.total,
    0
  );

  useEffect(() => {
    track("Viewed hiring pipeline", {
      totalCandidates: totalCandidates,
    });
  }, [totalCandidates, track]);

  const cardContent = (() => {
    if (jobPostings.length === 0) {
      return <HiringPipelineEmptyState />;
    }

    const stepHeaders = [
      "Steps",
      ...candidateNextSteps.map((step) => {
        return getStepLabel(step);
      }),
    ];

    return (
      <PipelineTable>
        <thead>
          <tr>
            {stepHeaders.map((step, index) => {
              return (
                <PipelineTableHeaderCell key={`${step}-${index}`} leftAlign>
                  <Typography
                    css={css`
                      font-weight: bold;
                      font-size: 0.9em;
                    `}
                    variant="footnote"
                  >
                    {step}
                    {index === 1 && (
                      <>
                        <span
                          css={css`
                            display: block;
                            font-weight: initial;
                          `}
                        >
                          (New Applicants)
                        </span>
                      </>
                    )}
                  </Typography>
                </PipelineTableHeaderCell>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {jobPostings.map((row) => {
            return (
              <tr key={row.jobPosting.id}>
                <PipelineTableCell leftAlign>
                  <HiringPipelineJobDetails
                    jobPosting={row.jobPosting}
                    totalSubmissions={row.total}
                  />
                </PipelineTableCell>

                {candidateNextSteps.map((step, index) => {
                  const stepForJobPosting = row.totalsByNextStep.find((row) => {
                    switch (step.__typename) {
                      case "CandidateRecruitmentStepFilterOption":
                        return (
                          row.__typename ===
                            "NumActiveCandidatesByJobPostingAndNextStepItemTotalRecruitment" &&
                          row.recruitmentStepName === step.stepName
                        );
                      case "CandidateMonthlyRetentionStepFilterOption":
                        return (
                          row.__typename ===
                            "NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention" &&
                          row.retentionDurationMonths === step.durationMonths
                        );
                      case "CandidateHourlyRetentionStepFilterOption":
                        return (
                          row.__typename ===
                            "NumActiveCandidatesByJobPostingAndNextStepItemTotalRetention" &&
                          row.retentionDurationHours === step.durationHours
                        );
                      default:
                        // this may happen if the job posting's structure doesn't include this step
                        // (due to multiple referral structures)
                        return null;
                    }
                  });

                  if (stepForJobPosting) {
                    return (
                      <PipelineTableCell key={JSON.stringify(step)}>
                        <StatusLink
                          jobPostingId={row.jobPosting.id}
                          step={stepForJobPosting}
                        />
                      </PipelineTableCell>
                    );
                  }
                  return null;
                })}
              </tr>
            );
          })}
        </tbody>
      </PipelineTable>
    );
  })();

  return (
    <PageCard>
      <PageCardTitle title="Hiring Pipeline" />
      <PageCardContent maxWidth="1300px">{cardContent}</PageCardContent>
    </PageCard>
  );
}
