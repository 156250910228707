/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useFormContext } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { AuthErrorCode } from "../../../shared/modules/cognito-auth/constants";
import { LoginPageContainer } from "../login-page-container";
import { ForgotPasswordFormValues, LoginSubmissionError } from "../types";

export interface ForgotPasswordFormProps {
  onSubmit(values: ForgotPasswordFormValues): void;
  onCancel(): void;
  submissionError: LoginSubmissionError | null;
}

export function ForgotPasswordForm({
  onSubmit,
  onCancel,
  submissionError,
}: ForgotPasswordFormProps): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useFormContext<ForgotPasswordFormValues>();

  return (
    <LoginPageContainer>
      <Form onSubmit={handleSubmit(onSubmit)} submitting={isSubmitting}>
        <div
          css={css`
            max-width: 370px;
            margin: 0 auto;

            text-align: center;
          `}
        >
          <Typography
            variant="h5"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(1.5)};
            `}
          >
            Reset password
          </Typography>

          <Typography
            variant="body"
            color="textSecondary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(4)};
            `}
          >
            Enter the email associated with your account. We’ll email you a
            verification code.
          </Typography>

          {submissionError && (
            <Alert
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(3)};
              `}
              message={(() => {
                switch (submissionError.code) {
                  case AuthErrorCode.UserDoesNotExist:
                    return "We cannot find an account associated with this email address. Please contact your administrator.";
                  case AuthErrorCode.NotAuthorized:
                    return "Before you can set your password, you must navigate back to the login page, and enter the temporary password provided that was provided to you via email.";
                  case AuthErrorCode.TooManyRequests:
                    return "You have made too many requests. Please try again later.";
                  default:
                    return (
                      <>
                        An unexpected error occurred.
                        <br />
                        Please try again later.
                      </>
                    );
                }
              })()}
              severity={submissionError.severity}
            />
          )}

          <TextField
            label="Email"
            error={errors.email}
            {...register("email", { required: "Email is required" })}
          />

          <Button
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(2)};
              `
            }
            color="primary"
            label="Get started"
            type="submit"
            loading={isSubmitting}
            size="large"
          />
          <Button
            variant="outlined"
            onClick={onCancel}
            label="Cancel"
            disabled={isSubmitting}
            size="large"
          />
        </div>
      </Form>
    </LoginPageContainer>
  );
}
