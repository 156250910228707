/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import {
  AdminGoal,
  AdminGoalStatus,
  AdminGoalType,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useHasPermissionQuery } from "../../../../../shared/modules/permissions/hooks/use-has-permission-query";
import { BaseGoalCard } from "../base-goal-card";
import loginIcon from "../icons/login.png";

export interface First30DayLoginGoalCardProps {
  goal: Pick<
    AdminGoal,
    "type" | "status" | "rewards" | "startDate" | "endDate" | "tracker"
  >;
}

export function First30DayLoginGoalCard({
  goal: { type, status, rewards, startDate, endDate, tracker },
}: First30DayLoginGoalCardProps) {
  const track = useTrack();

  const { hasPermission: hasAccessToEmployees } = useHasPermissionQuery(
    "read",
    "employees"
  );

  if (
    type !== AdminGoalType.LoginAfterLaunch ||
    tracker.__typename !== "LoginAdminGoalTracker"
  ) {
    return null;
  }

  const {
    targetPercentage,
    totalUsersWhoCheckedIn: checkedInUsersProp,
    totalActiveUsers,
    progressUpdatedAt,
  } = tracker;

  const reward = (() => {
    switch (rewards.__typename) {
      case "AdminGoalRewardsGiftCard":
        return {
          type: "gift_card" as const,
          dollarValue: rewards.dollarValue,
        };
      case "AdminGoalRewardsPayroll":
        return {
          type: "payroll" as const,
          dollarValue: rewards.dollarValue,
        };
      default:
        return null;
    }
  })();

  // Handle when `totalUsersWhoCheckedIn` is `null` after the goal is initially created,
  // by setting progress to 0
  const totalUsersWhoCheckedIn = checkedInUsersProp ?? 0;

  const progressPercentage =
    typeof totalActiveUsers === "number" && totalActiveUsers > 0
      ? (totalUsersWhoCheckedIn / totalActiveUsers) * 100
      : null;

  const progressText = (() => {
    switch (status) {
      case AdminGoalStatus.Available: {
        if (typeof totalActiveUsers !== "number" || totalActiveUsers <= 0) {
          return null;
        }

        const targetNumberOfUsers = Math.ceil(
          totalActiveUsers * (targetPercentage / 100)
        );
        const usersRemaining = targetNumberOfUsers - totalUsersWhoCheckedIn;

        if (usersRemaining <= 0) {
          return "You've reached your target goal!";
        }

        return `Your team is just ${usersRemaining} logins away from achieving the ${targetPercentage}% goal`;
      }
      case AdminGoalStatus.Achieved: {
        const defaultText = "You accomplished the first 30-day login goal!";

        if (!reward) {
          return defaultText;
        }

        switch (reward.type) {
          case "gift_card":
            return "What's next: you will receive an email from Tango (gift card provider) within 48 hours with the link to redeem your gift card.";
          case "payroll":
            return "What's next: your reward will be applied to your next paycheck.";
          default:
            return defaultText;
        }
      }
      default:
        return null;
    }
  })();

  const progressTooltip = (() => {
    switch (status) {
      case AdminGoalStatus.Available:
        if (!progressText) {
          return null;
        }

        return "Login rate is calculated based on currently active users. Deactivated users are not included";
      default:
        return null;
    }
  })();

  return (
    <>
      <BaseGoalCard
        icon={<img src={loginIcon} alt="login" width={73} />}
        title="First 30-day login goal"
        startDate={new Date(startDate)}
        endDate={new Date(endDate)}
        reward={reward}
        goalStatus={status}
        goalType={type}
        goalProgress={{
          targetPercentage,
          progressPercentage,
          progressLastUpdated: progressUpdatedAt
            ? new Date(progressUpdatedAt)
            : null,
          progressText,
          progressTooltip,
          targetType: "team", // login goals are always team
        }}
        tipsContent={{
          title: "Tips to boost login engagement",
          action: (
            <Tooltip
              title={
                hasAccessToEmployees
                  ? ""
                  : "You do not have permission to view the Employees page. Ask your admin for permission."
              }
              placement="right"
            >
              <Button
                variant="outlined"
                label="Find out who did not log in"
                linkTo="/employees/accounts"
                size="small"
                width="auto"
                css={(theme: AppTheme) => css`
                  border-color: ${theme.palette.grey[600]};
                `}
                onClick={() => {
                  track("Clicked to view employees from admin goal card");
                }}
                disabled={!hasAccessToEmployees}
              />
            </Tooltip>
          ),
          tips: [
            "Introduce the rewards program during orientation to highlight your agency's unique benefits.",
            "Send reminders to encourage employees to explore the rewards app and earn rewards.",
            "Resend login links via the Employee page when employees have trouble accessing their rewards accounts.",
            "Send regular recognition to boost engagement and motivate employees to pursue app goals.",
          ],
        }}
      />
    </>
  );
}
