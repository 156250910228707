/** @jsxImportSource @emotion/react */
import { FieldValues, Control, Controller, Path } from "react-hook-form";

import { DateField } from "@rewards-web/shared/components/date-field";

interface SurveySettingsStartDateFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  disabled: boolean;
  minDate: Date;
  required?: boolean;
}

export function SurveySettingsStartDateField<T extends FieldValues>({
  control,
  name,
  disabled,
  minDate,
  required,
}: SurveySettingsStartDateFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={
        required && !disabled
          ? { required: "Survey start date is required" }
          : undefined
      }
      render={({ field, fieldState }) => {
        return (
          <DateField
            {...field}
            label="Survey start date"
            error={fieldState.error}
            disabled={disabled}
            minDate={minDate}
            // may show checkbox underneath:
            hideHelperText
          />
        );
      }}
    />
  );
}
