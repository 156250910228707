import { Autocomplete, TextField } from "@mui/material";
import { ComponentProps } from "react";

import {
  ControlledFormFieldProps,
  StylableProps,
} from "@rewards-web/shared/types";

export interface MultiSelectAutocompleteOption {
  /**
   * label for the given option
   */
  label: string;
  /**
   * value for the given option, should be unique
   */
  value: string;
}

export interface MultiSelectAutocompleteProps
  extends StylableProps,
    ControlledFormFieldProps {
  /**
   * label for the main component
   */
  label: string;
  size: "small" | "medium";
  options: Array<MultiSelectAutocompleteOption>;
  /**
   * placeholder text for the type-ahead search
   */
  placeholder?: string;
  renderTags?: ComponentProps<typeof Autocomplete>["renderTags"];
}

export function MultiSelectAutocomplete({
  label,
  size,
  options,
  value,
  placeholder,
  onChange,
  className,
  error,
  renderTags,
}: MultiSelectAutocompleteProps) {
  const chipProps = { color: Boolean(error) ? "error" : "primary" } as const;

  // chip props aren't passed to `renderTags`
  const renderTagsWithChipProps = ((): MultiSelectAutocompleteProps["renderTags"] => {
    if (!renderTags) {
      return undefined;
    }
    return (value, getTagProps, ownerState) => {
      return renderTags(
        value,
        ({ index }: { index: number }) => ({
          ...getTagProps({ index }),
          ...chipProps,
        }),
        ownerState
      );
    };
  })();
  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      className={className}
      size={size}
      options={options}
      onChange={(_event, value) => onChange?.(value)}
      defaultValue={Array.isArray(value) ? value : []}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            style: { borderRadius: "8px" },
          }}
          label={label}
          placeholder={placeholder}
          color={Boolean(error) ? "error" : "primary"}
          error={Boolean(error)}
          helperText={error?.message}
        />
      )}
      renderTags={renderTagsWithChipProps as any}
      ChipProps={chipProps}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
}
