/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ReferralStructureForm } from "../shared/referral-structure-form";
import { referralStructureFormValuesToMutationVariables } from "../shared/referral-structure-form/lib";
import { useCreateReferralStructureDataQuery } from "./create-referral-structure-data.generated";
import { useCreateReferralStructureMutation } from "./create-referral-structure.generated";

export function SettingsCreateReferralStructurePageContents() {
  const track = useTrack();
  const navigate = useNavigate();

  const query = useCreateReferralStructureDataQuery({
    onError: reportError,
  });

  const [
    createReferralStructure,
    { loading: submitting },
  ] = useCreateReferralStructureMutation();

  return (
    <>
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        linkTo="/settings/referral-structure"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />

      <Typography
        variant="h3"
        component="h1"
        color="textPrimary"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        Create referral structure
      </Typography>

      {query.data ? (
        <>
          <Typography
            variant="body"
            color="grey.800"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            {query.data.getMyRewardsOrganization.launched
              ? "This referral will be locked to ensure a seamless caregiver experience and accurate data (you may create new structures at any time)."
              : "Once your agency has launched the rewards program, referrals will be locked to ensure a seamless caregiver experience and accurate data (you may create new structures at any time)."}
          </Typography>
          <ReferralStructureForm
            onSubmit={async (values) => {
              const res = await createReferralStructure({
                variables: referralStructureFormValuesToMutationVariables(
                  values
                ),
              });
              navigate("/settings/referral-structure");
              track("Created referral structure", {
                referralStructureId: res.data?.createReferralStructure.id,
              });
            }}
            submitting={submitting}
            pointsPerDollar={
              query.data?.getMyRewardsOrganization.pointsPerDollar
            }
            jobShareStructure={
              query.data?.getMyRewardsOrganization.referralRewardStructure
                .jobShareStructure
            }
          />
        </>
      ) : (
        <PageLoadingState />
      )}
    </>
  );
}
