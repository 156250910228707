/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Control,
  FieldValues,
  Path,
  useFormState,
  useWatch,
  get,
} from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { AppTheme } from "@rewards-web/shared/style/types";

import { TAG_CONFIG_RIGHT_ADORNMENT_WIDTH } from "../../../../employees/groups/tags-config-field";

const MAX_BRANCH_NAME_LENGTH = 30;

interface BranchNameFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  autoFocus?: boolean;
}

export function BranchNameField<T extends FieldValues>({
  control,
  name,
  autoFocus,
}: BranchNameFieldProps<T>) {
  const formState = useFormState({ control, name });
  const value = useWatch({ control, name });

  return (
    <div
      css={css`
        padding-right: ${TAG_CONFIG_RIGHT_ADORNMENT_WIDTH}px;
      `}
    >
      <TextField
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
        fixLabelToTop
        label="Branch name"
        disableAutocomplete
        autoFocus={autoFocus}
        helperText={getCharactersRemainingText(value, MAX_BRANCH_NAME_LENGTH)}
        error={get(formState.errors, name)}
        {...control.register(name, {
          required: "Branch name is required",
          minLength: 2,
          validate: (value) => {
            if (value && value.length > MAX_BRANCH_NAME_LENGTH) {
              return getCharactersRemainingText(value, MAX_BRANCH_NAME_LENGTH);
            }
          },
        })}
      />
    </div>
  );
}
