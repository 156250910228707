import { GoalDistributionType } from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { useHasPermissionQuery } from "../../../../../../shared/modules/permissions/hooks/use-has-permission-query";
import { useSurveySettingsEnabledDataQuery } from "./use-survey-settings-enabled-data.generated";

export function useSurveySettingsEnabled() {
  const { data, loading } = useSurveySettingsEnabledDataQuery();

  const { hasPermission: hasFullSurveyPermission } = useHasPermissionQuery(
    "full",
    "surveys"
  );
  // 'pulse' is in the feature flag name here but in practice we will use it for all survey types (not just pulse check)
  const pulseSurveysFeatureFlag = useFeatureFlag(
    "admin-app-pulse-survey-settings-temp"
  );
  const surveySettingsEnabled = (() => {
    const isOnAutoGoalConfig =
      data?.getMyOrganizationGoalConfig?.distributionType ===
      GoalDistributionType.Auto;

    return (
      pulseSurveysFeatureFlag && !isOnAutoGoalConfig && hasFullSurveyPermission
    );
  })();

  return { surveySettingsEnabled, surveySettingsLoading: loading };
}
