import { faStars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ParticipationInsightMetric as ParticipationInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../../../../shared/components/insight-metric";
import {
  useTrackInsightMetricTooltip,
  useTrackInsightMetricComparisonTooltip,
} from "../../../../hooks";
import { getDifferenceOfPercentages } from "../../../../utils";

export type ParticipationInsightMetricProps = {
  currentParticipationInsightMetric: ParticipationInsightMetricType | undefined;
  previousParticipationInsightMetric:
    | ParticipationInsightMetricType
    | undefined;
};

const getParticipationFraction = ({
  numUsersWhoParticipated,
  activeUserCount,
}: ParticipationInsightMetricType) => ({
  numerator: Math.min(numUsersWhoParticipated, activeUserCount), // capped at 100%
  denominator: activeUserCount,
});

export function ParticipationInsightMetric({
  currentParticipationInsightMetric,
  previousParticipationInsightMetric,
}: ParticipationInsightMetricProps) {
  const trackInsightMetricGroupTooltip = useTrackInsightMetricTooltip();
  const trackInsightMetricComparisonTooltip = useTrackInsightMetricComparisonTooltip();

  const percentParticipationChange = (() => {
    if (
      previousParticipationInsightMetric === undefined ||
      currentParticipationInsightMetric === undefined
    ) {
      return null;
    }
    return getDifferenceOfPercentages({
      prev: getParticipationFraction(previousParticipationInsightMetric),
      curr: getParticipationFraction(currentParticipationInsightMetric),
    });
  })();

  return (
    <InsightMetric
      label="Participation"
      icon={<FontAwesomeIcon icon={faStars} />}
      tooltipText="This is the percentage of employees who have completed at least one goal."
      value={
        currentParticipationInsightMetric !== undefined
          ? {
              type: "percent",
              ...getParticipationFraction(currentParticipationInsightMetric),
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentParticipationChange}
      onTooltipOpen={trackInsightMetricGroupTooltip}
      onPercentPointChangeTooltipOpen={trackInsightMetricComparisonTooltip}
    />
  );
}
