/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { omit } from "lodash";
import { useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsAdminPermissionsType } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { usePermissionsQuery } from "../../../../../../shared/modules/permissions/hooks/use-permissions-query";
import { ReferralStructureForm } from "../shared/referral-structure-form";
import {
  referralStructureFormValuesToMutationVariables,
  referralStructureFromApiToFormValue,
} from "../shared/referral-structure-form/lib";
import { useEditReferralStructureMutation } from "./edit-referral-structure.generated";
import { useViewReferralStructureDataQuery } from "./view-referral-structure-data.generated";

export function SettingsViewReferralStructurePageContents() {
  const track = useTrack();
  const params = useParams();
  const referralStructureId = params
    .id!.toLowerCase()
    .startsWith("organization_default:")
    ? params.id!.toUpperCase()
    : params.id!;

  const isDefaultReferralStructure = referralStructureId.startsWith(
    "ORGANIZATION_DEFAULT:"
  );

  const query = useViewReferralStructureDataQuery({
    variables: { referralStructureId },
    onError: reportError,
  });

  const [
    editReferralStructure,
    { loading: submitting },
  ] = useEditReferralStructureMutation();

  const permissionsQuery = usePermissionsQuery();

  const content = (() => {
    if (query.error || permissionsQuery.error) {
      return (
        <Alert severity="error" message="Error loading referral structure" />
      );
    }

    if (!query.data || !permissionsQuery.data) {
      return <PageLoadingState />;
    }

    if (!query.data.getReferralStructureById) {
      return <Alert severity="error" message="Referral structure not found" />;
    }

    const hasFullAccess =
      permissionsQuery.data.getMyRewardsAdminUser.permissions.type ===
      RewardsAdminPermissionsType.FullAccess;

    // only full access admins can edit, and only during pre-launch
    const canEdit =
      hasFullAccess && !query.data.getMyRewardsOrganization.launched;

    return (
      <>
        <Typography
          variant="h3"
          component="h1"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {canEdit ? "Edit" : "View"} referral structure
        </Typography>

        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          {canEdit
            ? "Once your agency has launched the rewards program, this referral will be locked to ensure a seamless caregiver experience and accurate data (you may create new structures at any time)."
            : "This referral is locked to ensure a seamless caregiver experience and accurate data (you may create new structures at any time)."}
        </Typography>

        <ReferralStructureForm
          onSubmit={async (values) => {
            const mutationVariables = referralStructureFormValuesToMutationVariables(
              values
            );

            await editReferralStructure({
              variables: {
                referralStructureId,

                ...(isDefaultReferralStructure
                  ? // can't edit the name of the default referral structure
                    omit(mutationVariables, "name")
                  : mutationVariables),
              },
            });

            track("Updated referral structure", { referralStructureId });
          }}
          submitting={submitting} // TODO set when edit mode is implemented
          pointsPerDollar={query.data.getMyRewardsOrganization.pointsPerDollar}
          jobShareStructure={
            query.data.getMyRewardsOrganization.referralRewardStructure
              .jobShareStructure
          }
          initialValues={{
            name: query.data.getReferralStructureById.name,
            steps: referralStructureFromApiToFormValue(
              query.data.getReferralStructureById.items
            ),
          }}
          viewMode={!canEdit}
          launched={query.data.getMyRewardsOrganization.launched}
          isDefaultReferralStructure={isDefaultReferralStructure}
        />
      </>
    );
  })();

  return (
    <>
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        linkTo="/settings/referral-structure"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />

      {content}
    </>
  );
}
