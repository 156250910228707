/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { RadioGroup } from "@rewards-web/shared/components/radio-buttons/radio-group";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import { ModalRadioOption } from "../../../../../../../shared/components/modal-radio-option";

export interface EditGoalConfirmationModalProps {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  recurring: boolean;
  setEditChangesApplyTo: (value: "recurring" | "currentAndRecurring") => void;
  changesSubmitting: boolean;
}

interface EditGoalConfirmationModalFormValues {
  applyTo: "recurring" | "currentAndRecurring";
}

export function EditGoalConfirmationModal({
  open,
  onClose,
  onConfirm,
  recurring,
  setEditChangesApplyTo,
  changesSubmitting,
}: EditGoalConfirmationModalProps) {
  const track = useTrack();
  const form = useForm<EditGoalConfirmationModalFormValues>({
    defaultValues: {
      applyTo: "recurring",
    },
  });
  const { control, formState } = form;

  useEffect(() => {
    if (open) {
      form.reset({
        applyTo: "recurring",
      });
    }
  }, [open, form]);

  return (
    <Modal width="714px" open={open} onClose={onClose}>
      <Form
        css={css`
          display: contents;
        `}
        submitting={formState.isSubmitting}
        onSubmit={() => {
          onConfirm();
        }}
      >
        <ModalTitle>
          <Typography variant="h5">Apply changes to goals</Typography>
        </ModalTitle>
        <ModalContent>
          <Controller
            control={control}
            name="applyTo"
            rules={{
              required: "You must select one option to apply changes to goals",
            }}
            render={({ field, fieldState }) => (
              <RadioGroup
                ariaLabel="apply changes to goals"
                {...field}
                error={fieldState.error}
              >
                <ModalRadioOption
                  value="recurring"
                  title="Apply to next goal periods (recommended)"
                  description="The current goal period will remain unchanged, and updates will apply to the next goal periods."
                  selected={field.value === "recurring"}
                  onClick={() => {
                    setEditChangesApplyTo("recurring");
                  }}
                />
                <ModalRadioOption
                  value="currentAndRecurring"
                  title="Apply changes immediately"
                  description="The updates will take effect right away for the current and next goal periods."
                  selected={field.value === "currentAndRecurring"}
                  onClick={() => {
                    setEditChangesApplyTo("currentAndRecurring");
                  }}
                />
              </RadioGroup>
            )}
          />
        </ModalContent>
        <ModalActions>
          <Button
            variant="outlined"
            onClick={() => {
              track("Clicked to cancel edit admin goal", {
                recurring,
              });
              onClose();
            }}
            label="Cancel"
            size="medium"
            width="auto"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              track("Clicked confirm to edit admin goal", {
                recurring,
              });
            }}
            label="Apply changes"
            size="medium"
            width="auto"
            loading={changesSubmitting}
          />
        </ModalActions>
      </Form>
    </Modal>
  );
}
