/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faClock,
  faIdCardClip,
  faRightFromBracket,
  faRightToBracket,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHelperText } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, useWatch, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { CheckboxField } from "@rewards-web/shared/components/checkbox-field";
import { Divider } from "@rewards-web/shared/components/divider";
import { Form } from "@rewards-web/shared/components/form";
import { SwitchField } from "@rewards-web/shared/components/switch";
import { Typography } from "@rewards-web/shared/components/typography";
import { EvvRulesClockMethod } from "@rewards-web/shared/graphql-types";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";
import { formatMinutes } from "@rewards-web/shared/lib/format-evv-time";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { EVVRulesFormValues } from ".";
import { PageCardActions } from "../../../../../shared/components/page-card/page-card-actions";
import { AdminOnboardingCard } from "../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../components/step-actions";
import { EVVRuleRow } from "./evv-rule-row";
import { EVVTrackPageEVVRules } from "./evv-rules-details";
import { useEvvRulesDetailsPageDataQuery } from "./evv-rules-details-data.generated";
import { isEVVRulesEnabled } from "./lib";

export type EVVRuleName =
  | "ScheduledStart"
  | "ScheduledEnd"
  | "ScheduledDuration";

const initialValues = {
  minutesLessThanScheduledStart: "",
  minutesGreaterThanScheduledStart: "",
  minutesLessThanScheduledEnd: "",
  minutesGreaterThanScheduledEnd: "",
  minutesLessThanScheduledDuration: "",
  minutesGreaterThanScheduledDuration: "",
  selectedClockMethods: {
    [EvvRulesClockMethod.MobileApp]: true,
  },
  enabledEVVRules: false,
};

const defaultRules = {
  minutesLessThanScheduledStart: formatMinutes(7),
  minutesGreaterThanScheduledStart: formatMinutes(7),
  minutesLessThanScheduledEnd: formatMinutes(7),
  minutesGreaterThanScheduledEnd: formatMinutes(7),
  minutesLessThanScheduledDuration: formatMinutes(7),
  minutesGreaterThanScheduledDuration: formatMinutes(7),
  selectedClockMethods: {
    [EvvRulesClockMethod.MobileApp]: true,
    [EvvRulesClockMethod.Ivr]: false,
    [EvvRulesClockMethod.Fob]: false,
  },
  enabledEVVRules: true,
};

interface EVVRulesContainerProps {
  onSubmit: (values: EVVRulesFormValues) => void;
}

export function EVVRulesForm({ onSubmit }: EVVRulesContainerProps) {
  const form = useForm<EVVRulesFormValues>({
    defaultValues: initialValues,
  });
  const { reset, formState, control } = form;
  const { data, loading: dataLoading } = useEvvRulesDetailsPageDataQuery({
    onError: reportError,
  });
  const [error, setError] = useState<string | undefined>(undefined);

  const location = useLocation();
  const isOnbordingPage = location.pathname === "/onboarding/evv-compliance";

  const hasActiveAlayaCareIntegration =
    data?.getEnabledAlayaCareIntegrations &&
    data.getEnabledAlayaCareIntegrations.length > 0;

  useEffect(() => {
    if (!dataLoading) {
      if (data?.getMyRewardsOrganizationEVVRules.rules) {
        const {
          minutesLessThanScheduledStart,
          minutesGreaterThanScheduledStart,
          minutesLessThanScheduledEnd,
          minutesGreaterThanScheduledEnd,
          minutesLessThanScheduledDuration,
          minutesGreaterThanScheduledDuration,
          validClockMethods,
        } = data.getMyRewardsOrganizationEVVRules.rules;

        reset({
          minutesLessThanScheduledStart: formatMinutes(
            minutesLessThanScheduledStart
          ),
          minutesGreaterThanScheduledStart: formatMinutes(
            minutesGreaterThanScheduledStart
          ),
          minutesLessThanScheduledEnd: formatMinutes(
            minutesLessThanScheduledEnd
          ),
          minutesGreaterThanScheduledEnd: formatMinutes(
            minutesGreaterThanScheduledEnd
          ),
          minutesLessThanScheduledDuration: formatMinutes(
            minutesLessThanScheduledDuration
          ),
          minutesGreaterThanScheduledDuration: formatMinutes(
            minutesGreaterThanScheduledDuration
          ),
          selectedClockMethods: {
            [EvvRulesClockMethod.MobileApp]:
              validClockMethods?.includes(EvvRulesClockMethod.MobileApp) ||
              hasActiveAlayaCareIntegration,
            [EvvRulesClockMethod.Ivr]:
              validClockMethods?.includes(EvvRulesClockMethod.Ivr) ?? false,
            [EvvRulesClockMethod.Fob]:
              validClockMethods?.includes(EvvRulesClockMethod.Fob) ?? false,
          },
          enabledEVVRules: isEVVRulesEnabled(
            data.getMyRewardsOrganizationEVVRules.rules
          ),
        });
      } else {
        reset(defaultRules);
      }
    }
  }, [dataLoading, data, reset, hasActiveAlayaCareIntegration]);
  const {
    minutesLessThanScheduledStart,
    minutesGreaterThanScheduledStart,
    minutesLessThanScheduledEnd,
    minutesGreaterThanScheduledEnd,
    minutesLessThanScheduledDuration,
    minutesGreaterThanScheduledDuration,
    enabledEVVRules,
    selectedClockMethods,
  } = useWatch({ control });

  const fieldNames = {
    minutesLessThanScheduledStart: "minutesLessThanScheduledStart",
    minutesGreaterThanScheduledStart: "minutesGreaterThanScheduledStart",
    minutesLessThanScheduledEnd: "minutesLessThanScheduledEnd",
    minutesGreaterThanScheduledEnd: "minutesGreaterThanScheduledEnd",
    minutesLessThanScheduledDuration: "minutesLessThanScheduledDuration",
    minutesGreaterThanScheduledDuration: "minutesGreaterThanScheduledDuration",
    selectedClockMethods: "selectedClockMethods",
  } as const;

  const handleSubmit = async (values: EVVRulesFormValues) => {
    onSubmit(values);
    reset(values);
  };

  const validateSelectedClockMethods = () => {
    if (!enabledEVVRules) {
      // No need to check other fields if EVV is disabled
      // Let's us save the disabled state
      setError(undefined);
      return;
    }

    if (hasActiveAlayaCareIntegration) {
      setError(undefined);
      return;
    }

    if (
      !selectedClockMethods ||
      (!selectedClockMethods[EvvRulesClockMethod.MobileApp] &&
        !selectedClockMethods[EvvRulesClockMethod.Ivr] &&
        !selectedClockMethods[EvvRulesClockMethod.Fob])
    ) {
      setError("At least one clock in/out method is required");
    } else {
      setError(undefined);
    }
  };

  return (
    <>
      <Form
        submitting={formState.isSubmitting}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <AdminOnboardingCard
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          <AdminOnboardingCardHeader title="☎️ Rules" />
          <AdminOnboardingCardContent>
            <div
              css={(appTheme: AppTheme) => css`
                margin-bottom: ${appTheme.spacing(3)};
              `}
            >
              <Typography
                color="textSecondary"
                variant="body"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                Select the specific rules you'd like us to use when it comes to
                rewarding your employees for consistently clocking in and out.
              </Typography>
            </div>

            <Controller
              control={control}
              name="enabledEVVRules"
              render={({ field }) => (
                <SwitchField
                  label={{ onLabel: "On", offLabel: "Off" }}
                  {...field}
                  value={field.value ?? false}
                  onChange={(e, value) => {
                    field.onChange(e, value);
                    validateSelectedClockMethods();
                  }}
                />
              )}
            />
            {enabledEVVRules ? (
              <Typography
                color="textSecondary"
                variant="body"
                css={(theme: AppTheme) => css`
                  margin-top: ${theme.spacing(1)};
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                Employees must clock in and out according to the specified rule
                to earn rewards.
              </Typography>
            ) : (
              <Typography
                color="textSecondary"
                variant="body"
                css={(theme: AppTheme) => css`
                  margin-top: ${theme.spacing(1)};
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                Employees are not required to clock in and out for visits to
                earn rewards.
              </Typography>
            )}
          </AdminOnboardingCardContent>
        </AdminOnboardingCard>
        {enabledEVVRules && (
          <>
            <AdminOnboardingCard
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              <AdminOnboardingCardHeader title="Clock in/out rules" />
              <AdminOnboardingCardContent>
                <EVVRuleRow
                  fieldNames={fieldNames}
                  title="Clock-in"
                  tooltipText="Clock ins that are before and after the set delay (in minutes) will not be counted towards rewards."
                  icon={
                    <FontAwesomeIcon
                      css={(theme: AppTheme) => css`
                        margin-right: ${theme.spacing(1)};
                      `}
                      icon={faRightToBracket}
                    />
                  }
                  control={control}
                  name="ScheduledStart"
                />

                <EVVRuleRow
                  fieldNames={fieldNames}
                  title="Clock-out"
                  tooltipText="Clock outs that are before and after the set delay (in minutes) will not be counted towards rewards."
                  icon={
                    <FontAwesomeIcon
                      css={(theme: AppTheme) => css`
                        margin-right: ${theme.spacing(1)};
                      `}
                      icon={faRightFromBracket}
                    />
                  }
                  control={control}
                  name="ScheduledEnd"
                />
                <Divider
                  css={(theme: AppTheme) => css`
                    margin-bottom: ${theme.spacing(4)};
                  `}
                />
                <EVVRuleRow
                  fieldNames={fieldNames}
                  title="Visit duration threshold"
                  tooltipText="Actual visit duration can vary based on the time you set (in minutes)."
                  icon={
                    <FontAwesomeIcon
                      css={(theme: AppTheme) => css`
                        margin-right: ${theme.spacing(1)};
                      `}
                      icon={faClock}
                    />
                  }
                  control={control}
                  name="ScheduledDuration"
                />

                <div
                  css={(theme: AppTheme) => css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    gap: ${theme.spacing(1)};
                  `}
                >
                  <div
                    css={(theme: AppTheme) => css`
                      margin-bottom: ${theme.spacing(2)};
                      flex-basis: 35%;
                    `}
                  >
                    <Typography
                      css={(theme: AppTheme) => css`
                        margin-bottom: ${theme.spacing(1)};
                        align-items: center;
                      `}
                      color="textSecondary"
                      variant="body"
                    >
                      <FontAwesomeIcon
                        css={(theme: AppTheme) => css`
                          margin-right: ${theme.spacing(1)};
                        `}
                        icon={faIdCardClip}
                      />
                      Method
                    </Typography>

                    <Typography
                      css={css`
                        width: 75%;
                      `}
                      color="textSecondary"
                      variant="footnote"
                    >
                      Select one or more clock-in methods employees are required
                      to use
                    </Typography>
                  </div>

                  <div
                    css={css`
                      flex-grow: 1;
                      flex-basis: 0;
                      min-width: 80px;
                    `}
                  >
                    {hasActiveAlayaCareIntegration ? (
                      <Typography variant="footnote">
                        Mobile app only
                      </Typography>
                    ) : (
                      <>
                        <Controller
                          control={form.control}
                          name={
                            `${fieldNames.selectedClockMethods}.${EvvRulesClockMethod.MobileApp}` as any
                          }
                          render={({ field, fieldState }) => (
                            <CheckboxField
                              {...field}
                              error={fieldState.error}
                              label="Mobile app"
                              display="block"
                              onChange={(e, value) => {
                                field.onChange(e, value);
                                validateSelectedClockMethods();
                              }}
                            />
                          )}
                        />
                        <Controller
                          control={form.control}
                          name={
                            `${fieldNames.selectedClockMethods}.${EvvRulesClockMethod.Ivr}` as any
                          }
                          render={({ field, fieldState }) => (
                            <CheckboxField
                              {...field}
                              error={fieldState.error}
                              label="IVR/Telephony"
                              display="block"
                              onChange={(e, value) => {
                                field.onChange(e, value);
                                validateSelectedClockMethods();
                              }}
                            />
                          )}
                        />
                        <Controller
                          control={form.control}
                          name={`${fieldNames.selectedClockMethods}.${EvvRulesClockMethod.Fob}`}
                          render={({ field, fieldState }) => (
                            <CheckboxField
                              {...field}
                              error={fieldState.error}
                              label="FOB"
                              display="block"
                              onChange={(e, value) => {
                                field.onChange(e, value);
                                validateSelectedClockMethods();
                              }}
                            />
                          )}
                        />
                        {error && (
                          <FormHelperText error={!!error}>
                            {error}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </AdminOnboardingCardContent>
            </AdminOnboardingCard>
            <AdminOnboardingCard>
              <AdminOnboardingCardHeader title="☎️ How employees see these rules" />
              <AdminOnboardingCardContent>
                <Typography variant="footnote">
                  Based on the rules configured above, the instructions to
                  employees will be adjusted in the app and in our
                  communications. We recommend you keep the rules simple to
                  ensure they are easily understood by your staff!
                </Typography>
                <Typography
                  css={(theme: AppTheme) => css`
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: ${theme.spacing(1)};
                  `}
                >
                  Rules
                </Typography>
                <EVVTrackPageEVVRules
                  evvRulesLoading={false}
                  evvRules={{
                    minutesLessThanScheduledStart: minutesLessThanScheduledStart
                      ? extractNumber(minutesLessThanScheduledStart)
                      : null,
                    minutesGreaterThanScheduledStart: minutesGreaterThanScheduledStart
                      ? extractNumber(minutesGreaterThanScheduledStart)
                      : null,
                    minutesLessThanScheduledEnd: minutesLessThanScheduledEnd
                      ? extractNumber(minutesLessThanScheduledEnd)
                      : null,
                    minutesGreaterThanScheduledEnd: minutesGreaterThanScheduledEnd
                      ? extractNumber(minutesGreaterThanScheduledEnd)
                      : null,
                    minutesLessThanScheduledDuration: minutesLessThanScheduledDuration
                      ? extractNumber(minutesLessThanScheduledDuration)
                      : null,
                    minutesGreaterThanScheduledDuration: minutesGreaterThanScheduledDuration
                      ? extractNumber(minutesGreaterThanScheduledDuration)
                      : null,
                    selectedClockMethods: form.getValues().selectedClockMethods,
                  }}
                />
              </AdminOnboardingCardContent>
            </AdminOnboardingCard>
          </>
        )}
        {isOnbordingPage ? (
          <AdminOnboardingStepActions
            disableSkip={true}
            disableContinue={formState.isSubmitting || !!error}
            submitButtonLabel={"Save and Continue"}
          />
        ) : (
          <PageCardActions
            disabled={
              !form.formState.isDirty || formState.isSubmitting || !!error
            }
          />
        )}
      </Form>
    </>
  );
}
